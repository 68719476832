
import Vue from 'vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import UITable from 'shared/ui/tables/Tables.vue';
import { sourceString } from '../../../shared/Participant';
import PotentialHousee from '../../shared/PotentialHousee';
import FancySelect from 'shared/ui/forms/FancySelect';
import Modal from 'shared/ui/modal/Modal.vue';
import EditHouseForm from './components/EditHouseForm.vue';
import { addressFromHouse } from './shared/utils';
import RequestSheet from './components/RequestSheet.vue';
import SlottedTable from 'shared/ui/tables/SlotedTable.vue';
import HousingRequest from './components/HousingRequest.vue';
import sortBy from 'lodash/sortBy';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faVenus } from '@fortawesome/pro-regular-svg-icons/faVenus';
import { faMars } from '@fortawesome/pro-regular-svg-icons/faMars';
import AssignableEventStaff from '../../shared/AssignableEventStaff';
import { GetHousesForEventQuery, UpdateHouseRequestsMutationVariables } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>
type Registration = ArrayElement<House['Registrations']>
type EventStaff = ArrayElement<House['EventStaff']>

library.add(faTrash, faComment, faPencil, faHome, faVenus, faMars);

interface Data {
  editing: boolean;
  showRequestSheet: boolean;
  textarea: string | null;
  addingNote: boolean;
  confirmRemoval: boolean;
  editNotes: (notes: string) => void;
}

interface Computed {
  address: string;
  filteredPotentialHousees: PotentialHousee[];
  participants: PotentialHousee[];
  listDisplayHeaders: string[];
  participantsListDisplay: (string | null)[][];
}

interface Methods {
  updateHousingRequests: (newRequest: string,
      belongsTo: string,
      {
        housingRequest1,
        housingRequest2,
        housingRequest3,
        housingRequest4,
        registrationId
      }: any) => void;
  addHousingRequest: (request: string) => void;
  participantFromRequest: (request: string | null | undefined) => PotentialHousee | undefined;
  potentialHouseeDisplay: (p: PotentialHousee) => string;
  genderFromNumber: (n: number) => 'M' | 'F';
  genderClass: (s: string) => 'B' | 'G' | 'U';
  houseParticipant: (p: PotentialHousee) => Promise<void>;
  unhouseParticipant: (p: PotentialHousee) => Promise<void>;
}

interface Props {
  inView: boolean;
  eventId: number;
  house: House;
  gridView: boolean;
  potentialHousees: PotentialHousee[];
  setHouse: (id: number, house: House, type: string, p: PotentialHousee) => void;
  unsetHouse: (id: number, type: string, house: House, p: PotentialHousee) => void;
  removeHouse: (id: number) => void;
  editHouseNotes: (houseId: number, notes: string, house: House) => void;
  editHouse: (eventId: number, house: House, originalHouse: House) => void;
  updateHouseRequests: (UpdateHouseRequests: UpdateHouseRequestsMutationVariables, house: House) => void;
  eventStaff: AssignableEventStaff[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'House',
  components: {
    UIButton,
    UITable,
    UIInput,
    FancySelect,
    Modal,
    EditHouseForm,
    RequestSheet,
    SlottedTable,
    HousingRequest,
    UITextarea
  },
  data() {
    return {
      editing: false,
      showRequestSheet: false,
      textarea: null,
      addingNote: false,
      confirmRemoval: false,
      editNotes: (notes: string) => this.editHouseNotes(this.house.houseId, notes, this.house)
    };
  },
  props: {
    inView: {},
    eventId: {},
    house: {},
    gridView: {},
    potentialHousees: {},
    setHouse: {},
    unsetHouse: {},
    removeHouse: {},
    editHouseNotes: {},
    editHouse: {},
    updateHouseRequests: {},
    eventStaff: {}
  },
  computed: {
    address() {
      return addressFromHouse(this.house);
    },
    filteredPotentialHousees() {
      const genderPredicate = (p: PotentialHousee) => {
        if (!this.house.gender) {
          return true;
        }
        switch (p.source) {
          case 'registration':
            return (
              this.genderFromNumber((p.model as Registration).Teen.Person.gender) ===
              this.house.gender
            );
          case 'eventstaff':
            return (
              this.genderFromNumber((p.model as EventStaff).Staff.gender) === this.house.gender
            );
          default:
            return true;
        }
      };

      return this.potentialHousees.filter(genderPredicate).filter(
        (h) =>
          (
            h.model.House || {
              houseId: 0
            }
          ).houseId !== this.house.houseId
      );
    },
    participants() {
      return sortBy(
        [
          ...(this.house.Registrations || []).map(
            (r) => new PotentialHousee(r, 'registration' as sourceString)
          ),
          ...(this.house.EventStaff || []).map(
            (s) => new PotentialHousee(s, 'eventstaff' as sourceString)
          ),
          ...(this.house.EventGuests || []).map(
            (g) => new PotentialHousee(g, 'eventguests' as sourceString)
          )
        ],
        ['lastName']
      );
    },
    listDisplayHeaders() {
      return [
        `Particpants <span${
          this.house.registrationCount > Number(this.house.capacity) ? ' class="overbooked"' : ''
        }>(${this.house.registrationCount}/${this.house.capacity})</span>`,
        'Request 1',
        'Request 2',
        'Request 3',
        'Request 4'
      ];
    },
    participantsListDisplay() {
      return [
        ...this.participants.map((d) => [
          d.name,
          (d.model as Registration).housingRequest1,
          (d.model as Registration).housingRequest2,
          (d.model as Registration).housingRequest3,
          (d.model as Registration).housingRequest4
        ])
      ];
    }
  },
  methods: {
    updateHousingRequests(
      newRequest: string,
      belongsTo: string,
      {
        housingRequest1,
        housingRequest2,
        housingRequest3,
        housingRequest4,
        registrationID
      }: any
    ) {
      if (!newRequest) return;

      this.updateHouseRequests(
        {
          registrationId: registrationID,
          housingRequest1: housingRequest1 || '',
          housingRequest2: housingRequest2 || '',
          housingRequest3: housingRequest3 || '',
          housingRequest4: housingRequest4 || '',
          [belongsTo]: newRequest
        },
        this.house
      );
    },
    addHousingRequest(request) {
      const participant = this.participantFromRequest(request);
      if (!participant) return;
      this.setHouse(participant.entityId, this.house, participant.source, participant);
    },
    participantFromRequest(request) {
      if (!request) return;
      return this.filteredPotentialHousees.find(
        (x) => x.name.toLowerCase() === request.toLowerCase()
      );
    },
    potentialHouseeDisplay(p) {
      return p.name;
    },
    genderFromNumber(n) {
      if (n) {
        return 'F';
      }
      return 'M';
    },
    genderClass(s) {
      if (s === 'M') {
        return 'B';
      }
      if (s === 'F') {
        return 'G';
      }
      return 'U';
    },
    async houseParticipant(p) {
      await this.setHouse(p.entityId, this.house, p.source, p);
    },
    async unhouseParticipant(p) {
      if (!p.entityId) {
        return;
      }
      await this.unsetHouse(p.entityId, p.source, this.house, p);
    }
  }
});
