
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import NoteDisplay from './components/Note'
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UITextrea from 'shared/ui/forms/Textarea.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import { CompactAdvisorRegion } from '../../../shared/CompactAdvisorRegion';
import NoteViewModel from './shared/NoteViewModel';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import TeenViewModel from './shared/TeenViewModel';
import AddStaffPersonNoteArgs from './AddStaffPersonNoteArgs';
import TeenLookup from 'shared/components/TeenLookup';
import Loading from 'shared/components/Loading.vue';
import DatePicker from 'shared/components/DatePicker.vue';
import DateTimezone from 'date-timezone';
import startCase from 'lodash/startCase';
import { GetNotesByStaffQuery, NoteCategory } from "shared/generated/graphql-types";

interface AddStaffPersonNoteFormObject {
	teen: TeenViewModel;
	date: string | any;
	category: {
		name: string,
		value: NoteCategory
	},
	note: string;
}

@Component({
  name: "Notes",
  components: {
    BorderedList,
    DatePicker,
    NoteDisplay,
    Modal,
    UITextrea,
    UIButton,
    UIInput,
    FancySelect,
    SimpleForm,
    TeenLookup,
    Field,
    Error,
    Loading
  }
})
export default class extends Vue {
    @Prop() notes!: GetNotesByStaffQuery['notesByStaff'];
    @Prop() notesLoading!: boolean;

    @Prop() staff!: CompactAdvisorRegion;

    @Prop() addStaffPersonNote!: (vars: AddStaffPersonNoteArgs) => void;

    @Prop() regionId!: number;

    @Prop() addingNewNote!: boolean;


	created () {
    this.noteCategories = Object.keys(NoteCategory).map(x => ({
        name: startCase(x),
        value: x
    }))
	}
	dateFormat(date: string) {
		if (date) {
			return new Date(date).toISOString().split('T')[0]
		}
		return ''
	}

    get compactNotes (): NoteViewModel[] {
        return this.notes.map(x => ({
          teen: x.Person ? {
            Person: {
              firstName: x.Person.firstName || '',
              lastName: x.Person.lastName || '',
              personID: x.Person.personID
              }
            } : null,
          note:          x.note,
          date:   this.dateFormat(x.date),
          category:      x.category || '',
          chapterId: ((x.Person || { Teen: { chapterId: null }}).Teen || { chapterId: null }).chapterId,
          noteId: x.id
        }))
    }

    async addNoteHandler(callbackArgs: SubmitCallbackArgs<AddStaffPersonNoteFormObject>) {
      if('errors' in callbackArgs) return;

      const { teen: { Person: { personID, firstName, lastName } }, note, date, category } = callbackArgs.values;
		const { staffId, chapterId } = this.staff;

		const contactDate = new DateTimezone.DateTimezone(date.year, date.month - 1, date.day,0,0).toISOString()

        await this.addStaffPersonNote({
			firstName,
			lastName,
			chapterId,
			regionId: this.regionId,
                note,
                contactDate,
                staffId,
                personId: personID!,
				category: category!.value
        });
        this.$emit('addingNewNote', false);
	}

	validate (values: AddStaffPersonNoteFormObject) {
		let errors: { [key: string]: string} = {};

		if (!values.teen) {
			errors['teen'] = "A teen must be selected";
		}
		if(!values.category) {
			errors['category'] = "A category must be selected";
		}
		if(!values.date) {
			errors['date'] = "A date must be selected";
		}
		if(!values.note) {
			errors['note'] = "A note must be provided";
		}

		return errors;
	}
}

