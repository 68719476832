
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EditorContainer from './EditorContainer.vue';
import UITextArea from 'shared/ui/forms/Textarea.vue';
import { ApolloClient } from '@apollo/client';

@Component({
  name: 'RichTextArea',
  components: {
    EditorContainer,
    UITextArea
  }
})
export default class RichTextArea extends Vue {

  @Prop() value!: string;
  @Prop() client!: ApolloClient<any>;

  html: string = this.value || '';

  @Watch('html')
  htmlChange() {
    this.$emit('input', this.html);
  };
}
