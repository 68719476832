
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import Loading from 'shared/components/Loading.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import { ActiveFilters } from '../shared/types';
import Avatar from 'shared/components/avatar';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { Props as LayoutProps } from '../types';
import { ArrayElement } from 'shared/util/types';
import { GetFamiliesWithChildrenQuery } from 'shared/generated/graphql-types';

type Family = ArrayElement<GetFamiliesWithChildrenQuery['families']['families']>

interface Props {
  regionId: number;
  families: Family[];
  fetchMore: LayoutProps['fetchMore'];
  activeFilters: ActiveFilters;
  selectedFamily: number;
}
interface Data {
  limit: number;
  busy: boolean;
}
interface Methods {
  fetchMoreHandler: ($state: StateChanger) => Promise<void>;
}
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'FamilyList',
	components: {
    BorderedList,
    InfiniteLoading,
    Loading,
    RouterLink,
    Avatar
	},
  props: {
    regionId: {},
    families: {},
    fetchMore: {},
    activeFilters: {},
    selectedFamily: {},
  },
  data () {
    return {
      limit: 60,
      busy: false
    };
  },
  methods: {
    async fetchMoreHandler ($state: StateChanger) {
      await this.fetchMore({ limit: this.limit, offset: this.families.length }, $state);
			$state.reset();
      $state.loaded();
    }
  }
});
