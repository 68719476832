
import Vue, { CreateElement } from 'vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import StaffLookup from 'shared/components/StaffLookup';
import SchoolLookup from 'shared/components/SchoolLookup';
import Datepicker from 'shared/components/DatePicker.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
import StateLookup from 'shared/components/StateLookup.vue';
import TeenLookup from 'shared/components/TeenLookup';
import ImageSelector from 'shared/components/ImageSelector.vue';
import EventTrackLookup from 'shared/components/EventTrackLookup';
import TimezoneDropdown from 'shared/components/TimezoneDropdown.vue';
import ZoomUsersLookup from 'shared/components/ZoomUsersLookup';
import * as RadioGroup from 'shared/radio-group';
import Step from '../../shared/Step.vue';
import { Step as StepType, Steps } from '../../../shared/MultistepForm';
import WYSIWYG from 'shared/ui/forms/RichTextArea';
import UITicket from '../../../shared/Ticket.vue';
import FancyCheckbox from 'shared/ui/forms/FancyCheckbox.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIAdditionalEventItem from '../../../shared/AdditionalEventItem.vue';
import ShabbatHolidayLookup from '../../../shared/ShabbatHolidayLookup.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboardCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck';
import { faGraduationCap } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faHotel } from '@fortawesome/pro-regular-svg-icons/faHotel';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { regionsWithOutState, isFutureEvent, generateZoomPasscode } from '../../../shared/util';
import { AdditionalEventItem, EventTicket, GetRegionDataQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']

library.add(faClipboardCheck, faGraduationCap, faHotel, faPencil, faTimes)

interface Data {
  registrationRequired: boolean;
  hasAdditionalEventItems: boolean;
  shabbatTypes: {name: string; id: number}[];
  selectedShabbatHolidayType: any;
}

interface Props {
  steps: Steps;
  complete: (step: string) => void;
  toggleActive: (step: string) => void;
  activatable: (step: string) => boolean;
  finalStep: (step: string) => boolean;
  firstStep: (step: string) => boolean;
  setData: (step: string, key: string, value: any) => void;
  getData: (step: string, key: string) => any;
  region: Region;
}

interface Computed {
  showStateLookup: boolean;
  isFutureEvent: boolean;
  allowSplitPayments: boolean;
  allowPartialPayments: boolean;
  computedHideFromWebAndMobile: boolean;
}

interface Methods {
  getOuterData: (step: string, key: string) => any;
  hideFromHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  isCustomDatesHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  subTypeHandler: ($event: any, setData: (name: string, value: any) => void) => Promise<void>;
  registrationRequiredHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  repeatEventHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  hasAdditionalEventItemsHandler: (checked: boolean, setData: (name: string, value: any) => any) => Promise<void>;
  isVirtualHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  scheduleZoomHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  setTeenOption: (name: string, getData: (name: string) => any, setData: (name: string, value: any) => void) => Promise<void>;
  addNewTicket: (tickets: any[], setData: (name: string, value: any) => void) => Promise<void>;
  addNewAdditionalEventItem: (additionalEventItems: any[], setData: (name: string, value: any) => void) => Promise<void>;
  removeAdditionalEventItem: (index: number, additionalEventItems: AdditionalEventItem[], setData: (name: string, value: any) => void) => Promise<void>;
  removeTicket: (index: number, tickets: EventTicket[], setData: (name: string, value: any) => void) => Promise<void>;
  isHasErrors: (stepName: StepType, key: string) => boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ShabbatHolidayForm',
  components: {
    ChapterLookup,
    SchoolLookup,
    StaffLookup,
    TeenLookup,
    StateLookup,
    ZoomUsersLookup,
    ...RadioGroup,
    TimezoneDropdown,
    Timepicker,
    Datepicker,
    Divider,
    UIInput,
    UIRadio,
    Step,
    ImageSelector,
    EventTrackLookup,
    UISwitch,
    WYSIWYG,
    EventSubTypeLookup,
    UITicket,
    FancyCheckbox,
    UIAdditionalEventItem,
    ShabbatHolidayLookup,
    UISelect,
    UICheckbox
  },
  data() {
    return {
      registrationRequired: false,
      hasAdditionalEventItems: false,
      shabbatTypes: [
        { name: 'Shabbat Oneg', id: 295 },
        { name:'Shabbat Meal', id: 304 },
        { name:'Shabbat Minyan', id:305 }
      ],
      selectedShabbatHolidayType: null
    }
  },
  props: {
    steps: {},
    complete: {},
    toggleActive: {},
    activatable: {},
    finalStep: {},
    firstStep: {},
    setData: {},
    getData: {},
    region: {}
  },
  computed: {
    showStateLookup () {
      return !regionsWithOutState.includes(this.region.regionId)
    },
    isFutureEvent() {
      return isFutureEvent(this.getData('details', 'StartDate'), this.getData('details', 'StartTime'), this.getData('details', 'TimeZone'));
    },
    allowSplitPayments () {
      return this.region.regionId !== 30  && this.region.regionId !== 8
    },
    allowPartialPayments () {
      return this.region.regionId !== 8
    },
    computedHideFromWebAndMobile () {
      const eventSubType: { id: number, name: string } | null = this.getData('eventSubType', 'shabbatType');
      if (!eventSubType) {
        return this.getData('options', 'hideFromWebAndMobile')
      }
      else if (eventSubType.id === 294 || eventSubType.id === 321 || eventSubType.id === 304) {
        return true
      }
      else {
        return this.getData('options', 'hideFromWebAndMobile')
      }
    }
  },
  methods: {
    getOuterData(step, key) {
      return this.getData(step, key);
    },

    async subTypeHandler($event, setData) {
      setData('subType', $event);
      await Vue.nextTick();
      this.selectedShabbatHolidayType = $event;
      await Vue.nextTick();
      await Vue.nextTick();
      setData('shabbatType', null);
      await Vue.nextTick();
    },

    async registrationRequiredHandler(checked, setData) {
      this.registrationRequired = checked;
      setData('registrationRequired', this.registrationRequired);
      await Vue.nextTick();
      if(!this.registrationRequired) {
        // XTODO will we want to clear out all registration related props
      }
    },

    async hideFromHandler(checked, setData) {
      setData('hideFromWebAndMobile', checked);
      await Vue.nextTick();
      if (checked) {
        setData('sendPushNotification', false);
      }
    },

    async isCustomDatesHandler(checked, setData) {
      if (checked) {
        setData('Repeat', 'custom_dates')
      } else {
        setData('Repeat', 'custom_recurrence')
      }
    },

    async repeatEventHandler(checked, setData) {
      if (!checked) {
        setData('RepeatEvent', false);
        await Vue.nextTick();
        setData('Repeat', null);
        await Vue.nextTick();
      } else {
        setData('RepeatEvent', true);
        await Vue.nextTick();
        setData('Repeat', 'custom_recurrence');
      }
    },

    async hasAdditionalEventItemsHandler(checked, setData) {
      this.hasAdditionalEventItems = checked;
      if(!this.hasAdditionalEventItems) {
        setData('additionalEventItems', null);
        await Vue.nextTick();
      }
    },

    async scheduleZoomHandler(checked, setData) {
      setData('streamSchedule', checked);
      await Vue.nextTick();
      if (checked) {
        setData('zoomPasscode', generateZoomPasscode());
      } else {
        setData('zoomPasscode', null);
        await Vue.nextTick();
        setData('zoomUser', null);
      }
    },

    async isVirtualHandler(checked, setData) {
      setData('isVirtual', checked);
      await Vue.nextTick();
      if (checked) {
        if (this.getData('details', 'streamSchedule')) {
          setData('zoomPasscode', generateZoomPasscode());
        }
      } else {
        setData('zoomPasscode', null);
        await Vue.nextTick();
        setData('zoomUser', null);
      }
    },

    async setTeenOption(name, getData, setData) {
      setData(name, !(getData(name) || false));
      await Vue.nextTick();
    },

    async addNewTicket(tickets, setData) {
      setData(`tickets.${(tickets || []).length}`, {price: 0});
      await Vue.nextTick();
    },

    async addNewAdditionalEventItem(additionalEventItems, setData) {
      setData(`additionalEventItems.${(additionalEventItems || []).length}`, {});
      await Vue.nextTick();
    },

    async removeAdditionalEventItem(index, additionalEventItems, setData){
      additionalEventItems.splice(index, 1);
      setData('additionalEventItems', additionalEventItems);
      await Vue.nextTick();
    },

    async removeTicket(index, tickets, setData){
      tickets.splice(index, 1);
      setData('tickets', tickets);
      await Vue.nextTick();
    },
    isHasErrors(step, key) {
      return step.validations && step.validations[key];
    }
  }
})

