

	import { Vue, Prop, Component } from "vue-property-decorator";
	import VueApexCharts from 'vue-apexcharts'
	import { CompactDashboardData } from '../types';
	import Tickets from '../components/Tickets';
  import { formatDate } from 'shared/util';

	@Component({
    name: 'TicketsData',
		components: {
		Tickets,
		apexchart: VueApexCharts,
		}
	})
export default class extends Vue {
    @Prop() dashboardData!: CompactDashboardData;
    @Prop() fetchingDashboardData!: boolean;

    get ticketsData () {
      return !!this.seriesTicket.length
    }
    get groupedTickets() {
        if (!this.dashboardData) return null
        return this.dashboardData.ticketsData;
    }
    get timezone() {
        if (!this.dashboardData) return null;
        return this.dashboardData.timeZone;
    }
    get seriesTicket() {
        return this.groupedTickets && this.groupedTickets.seriesTicket || [];
    }
    get chartTicketOptions() {
        return {
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: this.groupedTickets && this.groupedTickets.categories || [],
                labels: {
                    formatter: this.formatterForTicketsChart
                }
            },
            tooltip: {
                x: {
                    type: 'datetime',
                    formatter: this.formatterForTicketsChart
                }
            }
        }
    }
    formatterForTicketsChart (value: number) {
      if (!this.timezone) return ''
      const result = new Date(value).toISOString();
      return formatDate(result, '%B, %Y', 'utc')
    }
};
