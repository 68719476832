
import { Route, MatchFirst } from 'vue-component-router';
import GoalsList from './List';
import SingleGoal from './Single';

export default {
  props: ['path', 'regionId'],
  name: 'Goals',
  components: { Route, GoalsList, SingleGoal, MatchFirst }
};
