
  import FancyRadio from 'shared/ui/forms/FancyRadio.vue';
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import * as RadioGroup from 'shared/radio-group'
  import JSU from './components/JSU'
  import LNL from './components/LNL';
  import Other from './components/Other';
  import ShabbatHoliday from './components/ShabbatHoliday';
  import { RouterLink } from 'vue-component-router';
  import Shabbaton from './components/Shabbaton';
  import Loading from 'shared/components/Loading.vue';
  import Modal from 'shared/ui/modal/Modal.vue';
  import UILock from 'shared/components/Lock-ui.vue';
  import UIButton from 'shared/ui/buttons/Button.vue';
  import { Steps } from '../shared/MultistepForm'
  import { FormState } from '../shared/types'
  import ScrollablePanel from 'shared/components/scrollable-panel.vue';
  import fullheight from 'shared/directives/fullheight';
  import { GetEventSubTypesQuery, GetRegionDataQuery } from 'shared/generated/graphql-types';
  import { ArrayElement } from 'shared/util/types';

  type Region = GetRegionDataQuery['region']
  type EventSubType = ArrayElement<GetEventSubTypesQuery['eventSubTypes']>

  @Component({
    components: {
      ...RadioGroup,
      FancyRadio,
      ScrollablePanel,
      JSU,
      LNL,
      Other,
      ShabbatHoliday,
      Shabbaton,
      Loading,
      Modal,
      RouterLink,
      UIButton,
      UILock
    },
      directives: {
        fullheight
      }
  })
  export default class EventScheduler extends Vue {
    @Prop() eventType!: string
    @Prop() eventSubTypes!: EventSubType[];
    @Prop({type: Object}) stepData!: Steps
    @Prop({type: String}) state!: FormState
    @Prop() region!: Region;
    @Prop() eventId!: number;
    @Prop() seriesId!: number;

    get eventPath () {
      if (this.seriesId && this.seriesId !== -1) {
        return `/events/series/${this.seriesId}/summary`
      }
      else {
        return `/events/${this.eventId}/dashboard`
      }
    }
    getEventTypeWithProgramLevel (type: string) {
      if (!this.eventSubTypes.length) return ''
      const description = type === 'LNL' ? 'Latte and Learning' : type;
      const eventSubType = this.eventSubTypes.find(est => est.description === description);
      return eventSubType ? `${type} (${eventSubType.programLevel})` : type;
    }
  }
