
import Vue from 'vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import Avatar from 'shared/components/avatar';
import VueMultiselect from 'vue-multiselect';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import { CompactParent, Data, Methods, Props, Computed } from './types';
import ParentInfo from './components/ParentInfo.vue';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ParentLookup',
  components: {
    FancySelect,
    Avatar,
    VueMultiselect,
    ParentInfo
  },
  props: {
    showParentInfo: {
      default: false,
      type: Boolean
    },
    parents: {
      type: Array
    },
    searching: { type: Boolean },
    term: { type: String },
    value: {},
    deselect: { default: false, type: Boolean },
    clearable: { default: true, type: Boolean },
    multipleWithInfo: { default: false, type: Boolean },
    placeholder: { default: 'Type to search parents', type: String },
    disabled: { default: false, type: Boolean },
    clearOnSelect: { default: false },
    regionId: {},
    gender: {}
  },
  data() {
    return {
      localValue: Array.isArray(this.value) ? this.value || [] : this.value || null,
      searchHandler: debounce((search) => {
        this.$emit('term', search);
      }, 500)
    }
  },
  computed: {
    noOptions () {
      return !!(this.parents.length === 0 && this.term)
    }
  },
  methods: {
    startCase,
    formatParent (parent: CompactParent) {
      return parent.fullName;
    },
    removeHandler (removeParent: CompactParent) {
      this.localValue = (this.localValue as CompactParent[] || []).filter((t: CompactParent) => t.personId !== removeParent.personId)
      this.$emit('input', this.localValue);
      this.$emit('blur', this.localValue);
    },
    handleInput (event) {
      this.$emit('input', event);
      this.$emit('blur', event);
    }
  },
  watch: {
    value(value: CompactParent[]) {
      if (value) {
        this.localValue = value
      }
    },
  }
})

