
	import { Vue, Prop, Component } from "vue-property-decorator";
  import ScrollablePanel from 'shared/components/scrollable-panel.vue';
  import UISelect from 'shared/ui/forms/FancySelect';
	import Loading from 'shared/components/Loading.vue';
	import fullheight from 'shared/directives/fullheight';
	import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
  import ChapterLookup from 'shared/components/ChapterLookup';
  import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
  import EventTypeLookup from 'shared/components/EventTypeLookup';
  import { getLocalStartDate } from '../Events/shared/util';
  import { RouterLink } from 'vue-component-router';
  import filter from 'lodash/filter';
  import { Events } from './types';
  import { EventSubType, EventType } from 'shared/generated/graphql-types';
  import { Chapter, Me } from "shared/types";

	@Component({
    name: 'Dashboard',
		components: {
      Loading,
      PanelsGrid,
      ScrollablePanel,
      ChapterLookup,
      EventSubTypeLookup,
      EventTypeLookup,
      UISelect,
      RouterLink
		},
		directives: {
			fullheight
    },
    methods: {
      getLocalStartDate
    }
	})
export default class extends Vue {
    @Prop() user!: Me;
    @Prop() regionId!: number;
    @Prop() upcomingEvents!: Events;
    chapter: Chapter | null = null;
    eventCategory: { label: string, value: string } | null = null;
    eventSubType: EventSubType | null = null;
    eventType: EventType | null = null;

    get myEvents () {
      return filter(this.upcomingEvents, (event => event.advisorID === this.user.staffID && !event.isRegionWide))
    }
    get regionWideEvents () {
      return filter(this.upcomingEvents, (event => event.isRegionWide))
    }
    get iCalLink ()  {
      const chapterId = this.chapter && this.chapter.chapterId ||  null;
      const eventCategory = this.eventCategory && this.eventCategory.value ||  null;
      const eventSubTypeId = this.eventSubType && this.eventSubType.eventSubTypeId ||  null;
      const eventTypeId = this.eventType && this.eventType.eventTypeId ||  null;

      let link = `https://api.ncsy.org/event/feed?regionID=${this.regionId}`
      if (chapterId) {
        link += '&chapterID=' + chapterId;
      }
      if (eventCategory) {
        link += '&eventCategory=' + eventCategory;
      }
      if (eventSubTypeId) {
        link += '&eventSubTypeID=' + eventSubTypeId;
      }
      if (eventTypeId) {
        link += '&eventTypeID=' + eventTypeId;
      }
      if (chapterId || eventCategory || eventSubTypeId) {
        return link
      }
      return ''
    }
};
