import get from 'lodash/get';
import merge from 'lodash/merge';
import { getOptions } from 'shared/util';
import { VNode, CreateElement } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

function injectProps (component: VNode, props: Object) {
  if (!component.componentOptions) {
    return component
  }

  const options = component.componentOptions

  const propsData = options.propsData = options.propsData || {}

  Object.assign(propsData, props)

  return component
};

@Component({
  name: 'RadioGroup'
})
export class RadioGroup extends Vue {
  @Prop({type: [String,Number,Boolean]}) value: any
  @Prop({type: Boolean}) multiple!: boolean

  render (h: CreateElement) {

    const vNodes = this.$slots.default || []

    const _injectProps = (vNode: VNode) => {
      const nextVNode = vNode.tag && vNode.tag.match(/RadioGroupItem/)
        ? injectProps(vNode, {
          set: () => {
            const value = get(vNode, 'componentOptions.propsData.value')
            if (this.multiple) {
              const mValue = this.value && this.value.split(',') || [];
              const mValueIdx = mValue.indexOf(value);
              if (mValueIdx > -1) {
                mValue.splice(mValueIdx, 1);
              } else {
                mValue.push(value);
              }
              this.$emit('input', mValue.join(','));
              this.$emit('blur', mValue.join(','));
              return;
            }

            this.$emit('input', value);
            this.$emit('blur', value);
          },
          selected: this.multiple
            ? (this.value || '').split(',').includes(get(vNode, 'componentOptions.propsData.value'))
            : this.value === get(vNode, 'componentOptions.propsData.value'),
          name: 'input_' + this._uid
        })
        : vNode

      if (nextVNode.children) {
        nextVNode.children = nextVNode.children.map(_injectProps)
      }

      return nextVNode
    }

    const injectedVNodes = vNodes.map(_injectProps)

    const focusout = () =>
      setTimeout(
        () => !this.$el.contains(document.activeElement),
        200
      )

    if (injectedVNodes.length === 1) {
      const rootNode = injectedVNodes[0]

      merge(rootNode, {
        data: {
          on: {
            focusout
          },
          nativeOn: {
            focusout
          }
        }
      })

      return rootNode
    }

    return h('div', {
      on: {
        focusout
      }
    }, injectedVNodes)
  }
}

interface RadioButtonData {
  props: {
    selected: boolean,
    name: string,
    set: () => void
  },
  data: {
    attrs: Object
  }
}
export const RadioButton = Vue.extend({
  functional: true,
  props: {
    selected: {
      required: true
    },
    name: {
      required: true
    },
    set: {
      required: true
    }
  },
  render: (h: CreateElement, { props: { selected, name, set }, data: { attrs } }: any) => h('input', {
    domProps: {
      checked: selected,
      name,
      type: 'radio',
      ...attrs
    },
    on: {
      click: set
    }
  })
})

@Component({ name: 'RadioGroupItem' })
export class RadioGroupItem extends Vue {
  @Prop() set!: () => void
  @Prop() selected!: boolean
  @Prop({type: [String,Boolean,Number]}) value!: any
  @Prop() label!: string
  @Prop() name!: string

  render (h: CreateElement) {
    const scopedSlot = this.$scopedSlots.default

    const $input = h('input', {
      attrs: { type: 'radio', name: this.name },
      domProps: { checked: this.selected },
      on: {
        click: this.set
      }
    })

    if (!scopedSlot) {
      return h('label', [$input, this.label])
    }

    const props = {
      selected: this.selected,
      name: this.name,
      set: this.set
    }

    const listeners = {
      click: this.set
    }

    const $vnode = scopedSlot({ props, listeners, label: this.label });

    if ($vnode) {
      return ($vnode as Array<VNode>).length > 1 ? h('div', $vnode) : $vnode[0]
    }
    return h('div')
  }
}
