import Vue, { computed, VueConstructor } from 'vue';
import Layout from './Layout.vue';
import { compose, acceptProps } from 'vue-compose';
import { withRouter } from 'vue-component-router';
import currentUser from 'shared/components/CurrentUserEnhancer';
import ActiveFilters from './shared/ActiveFilters';
import FetchMoreOptions from './shared/FetchMoreOptions';
import { getOptions, normalizeProps } from 'shared/util';
import { Props } from './shared/Props';
import { StateChanger } from 'vue-infinite-loading';
import { FetchResult } from '@apollo/client';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetFilterOptionsQuery,
  GetPaginatedStaffDocument,
  GetPaginatedStaffQuery,
  MergeStaffMutationVariables,
  SortType,
  useGetFilterOptionsQuery,
  useGetPaginatedStaffQuery,
  useMergeStaffMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type AdvisorRegion = ArrayElement<GetPaginatedStaffQuery['getPaginatedStaff']['staff']>;

type FilterOptionsProps = {
  filterOptions: GetFilterOptionsQuery;
};
export const filterOptionsEnhancer = wrapComponent<Props, FilterOptionsProps>((props) => {
  const { result } = useGetFilterOptionsQuery(
    computed(() => ({ regionId: props.regionId })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    filterOptions: result.value
  }));
});

interface TChildProps {
  staff: AdvisorRegion[];
  initialLoading: boolean;
  isLoading: boolean;
  fetchMore: (
    { limit, offset }: FetchMoreOptions,
    stateChanger: StateChanger
  ) => Promise<FetchResult<GetPaginatedStaffQuery>> | undefined;
}

export const getStaffEnhancer = wrapComponent<Props, TChildProps>((props) => {
  const { fetchMore, loading, result } = useGetPaginatedStaffQuery(
    computed(() => ({
      limit: 60,
      filter: {
        regionId: props.regionId,
        showInactive: props.activeFilters.showInactive,
        query: props.activeFilters.term,
        sortBy: props.activeFilters.sortBy,
        filters: props.activeFilters.filters
      }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    isLoading: loading.value,
    initialLoading: loading.value && !result.value?.getPaginatedStaff,
    staff: result.value?.getPaginatedStaff.staff || [],
    total: result.value?.getPaginatedStaff.total || 0,
    fetchMore: ({ limit, offset }, stateChanger) =>
      fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            regionId: props.regionId,
            showInactive: props.activeFilters && props.activeFilters.showInactive,
            query: props.activeFilters.term,
            sortBy: props.activeFilters.sortBy,
            filters: props.activeFilters.filters
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult) {
            return previousResult;
          }
          if (
            !fetchMoreResult.getPaginatedStaff.staff.length ||
            fetchMoreResult.getPaginatedStaff.staff.length < limit
          ) {
            stateChanger.complete();

            if (!fetchMoreResult.getPaginatedStaff.staff.length) {
              return previousResult;
            }
          }

          const ids = previousResult.getPaginatedStaff.staff!.map(
            (x: AdvisorRegion) => x.AdvisorRegionId
          );
          const newData = fetchMoreResult.getPaginatedStaff.staff!.filter(
            (x: AdvisorRegion) => !ids.includes(x.AdvisorRegionId)
          );

          return {
            getPaginatedStaff: {
              __typename: 'StaffPage',
              total: fetchMoreResult.getPaginatedStaff.total,
              staff: [...previousResult.getPaginatedStaff!.staff, ...newData]
            }
          };
        }
      })
  }));
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        activeFilters: {
          term: '',
          filters: {},
          showInactive: false,
          sortBy: { name: SortType.Name, ascending: true }
        }
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters
        },
        on: this.$listeners
      });
    }
  });
};

interface MergeStaffProps {
  mergeStaff: (vars: MergeStaffMutationVariables) => void;
}

export const mergeStaffEnhancer = wrapComponent<Props, MergeStaffProps>((props) => {
  const { mutate } = useMergeStaffMutation();

  return computed(() => ({
    mergeStaff: ({ remainingID, deleteIDs, regionId }) =>
      mutate(
        { deleteIDs, regionId, remainingID },
        {
          optimisticResponse: {
            mergeStaff: {
              Staff: {
                staffID: remainingID,
                __typename: 'Staff'
              },
              __typename: 'AdvisorRegion'
            }
          },
          update: (proxy, response) => {
            const data = proxy.readQuery<GetPaginatedStaffQuery>({
              query: GetPaginatedStaffDocument,
              variables: {
                limit: 60,
                filter: {
                  regionId,
                  showInactive: props.activeFilters && props.activeFilters.showInactive,
                  query: props.activeFilters.term,
                  sortBy: props.activeFilters.sortBy,
                  filters: props.activeFilters.filters
                }
              }
            });

            if (data?.getPaginatedStaff) {
              const staff = data.getPaginatedStaff.staff.slice() || [];
              const staffFiltered = staff.filter((x) => !deleteIDs.includes(x!.Staff!.staffID));

              proxy.writeQuery<GetPaginatedStaffQuery>({
                query: GetPaginatedStaffDocument,
                variables: {
                  limit: 60,
                  filter: {
                    regionId,
                    showInactive: props.activeFilters && props.activeFilters.showInactive,
                    query: props.activeFilters.term,
                    sortBy: props.activeFilters.sortBy,
                    filters: props.activeFilters.filters
                  }
                },
                data: {
                  getPaginatedStaff: {
                    ...data.getPaginatedStaff,
                    staff: staffFiltered,
                    total: data.getPaginatedStaff.total - (staff.length - staffFiltered.length)
                  }
                }
              });
            }
          }
        }
      )
  }));
});

export const enhancer = compose(
  withFilters,
  getStaffEnhancer,
  currentUser,
  mergeStaffEnhancer,
  filterOptionsEnhancer,
  compose(acceptProps(['currentStaffId'])),
  withRouter
);

export default enhancer(Layout);
