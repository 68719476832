<template>
	<div class="sidebar" :style="{padding: padding}">
		<header v-if="hasHeaderSlot">
			<slot name="header"></slot>
		</header>
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'triPanelSidebar',
  props: {
		padding: String
  },
  computed: {
	hasHeaderSlot() {
 		return !!this.$slots.header
	}
  }

};
</script>

<style scoped lang="scss">
.sidebar {
	border-right: 1px solid #dddddd;
	position: relative;
	background: white;
	padding: 30px 20px;
	max-width: 300px;
}
header {
	display: block;
	margin-bottom: 15px;
	font-weight: bold;
	font-size: 16px;
	
	position: relative;
}
</style>

