import { render, staticRenderFns } from "./EmailHistory.vue?vue&type=template&id=53fedd00&scoped=true&"
import script from "./EmailHistory.vue?vue&type=script&lang=ts&"
export * from "./EmailHistory.vue?vue&type=script&lang=ts&"
import style0 from "./EmailHistory.vue?vue&type=style&index=0&id=53fedd00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53fedd00",
  null
  
)

export default component.exports