
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import UISelect from 'shared/ui/forms/FancySelect';
import { Staff } from './'

@Component({
	name: "StaffLookup",
	components: {
	UISelect
	},
})
export default class extends Vue {

@Prop() regionId!: number;
@Prop({ default: false }) disabled!: boolean;
@Prop({ default: false }) clearable!: boolean;
@Prop() staff!: Staff[];
@Prop({ default: false }) loading!: boolean;
@Prop({type: [Object as () => number | Staff | null, Number, Array as () => Staff[] ]}) value!: Staff[] | Staff | number | null;
@Prop({ default: false }) multiple!: boolean;

get valueAsArray(): Staff[] {
    if (!this.value) return []
    const value: Array < number | Staff > = Array.isArray(this.value) ? this.value : [this.value];

    return value.map(staff => typeof staff === 'object' ?
        staff :
        this.staff.find(x => x.staffID === staff) !
    ).filter(s => s)
}
}
