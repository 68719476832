
import Vue from "vue";
import { Props, Data, Methods } from './types';
import { PaymentSource, PaymentType } from "shared/generated/graphql-types";
import SimpleForm, { Field, Error } from 'vue-simpleform';
import UISelect from 'shared/ui/forms/FancySelect';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import { asUTCWithZerotime, enumToList, enumToListOption } from "shared/util";
import omit from "lodash/omit";
import { Errors } from "types/helpers";
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faDollarSign);

export default Vue.extend<Data, Methods, {}, Props>({
  name: "EditJFEOrderPayment",
  components: {
    SimpleForm,
    Field,
    Error,
    UISelect,
    UIInput,
    UIButton
  },
  props: {
    payment: {},
    orderId: {},
    editPayment: {},
    editing: {},
  },
  data() {
    return {
      PaymentType: PaymentType,
      suppressEmail: false,
      paymentCopy: {
        ...omit(this.payment, ['type', '__typename']),
        type: enumToListOption(this.payment.type),
        source: enumToListOption(this.payment.source)
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.$emit('submit')
    },
    async submit(callbackArg) {
      if('errors' in callbackArg) return;
      const { values } = callbackArg;
      await this.editPayment({
        ...omit(values, ['orderId', 'id']),
        paymentId: this.payment.id,
        amount: +values.amount!,
        type: values.type && values.type.value || null,
        source: values.source ? values.source.value : null,
        paymentDate: asUTCWithZerotime(values.paymentDate)
      })
      this.handleSubmit();
    },
    validate(values) {
      const errors: Errors<typeof this.paymentCopy> = {};

      if (!values.amount || values.amount <= 0) {
        errors.amount = 'please set amount';
      }
      if (!values.type || !values.type.value) {
        errors.type = 'please set payment type';
      }
      if (values.type && values.type.value === PaymentType.Cc) {
        if (!(values.source && values.source.value)) {
          errors.source = 'please set source';
        }
      }
      if (values.type && values.type.value === PaymentType.Check) {
        if (!(values.checkNumber || '').trim().length) {
          errors.checkNumber = 'please set check number';
        }
      }
      return errors;
    }
  },
  computed: {
    paymentTypes() {
      return enumToList(PaymentType);
    },
    paymentSources() {
      return enumToList(PaymentSource)
    }
  }
});
