
import Vue from 'vue';
import { Data, Computed, Props, Methods, FormShape } from './types';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import TeenLookup from 'shared/components/TeenLookup';
import * as RadioGroup from 'shared/radio-group';
import QuickAddTeen from 'shared/components/QuickAddTeen';
import Modal from 'shared/ui/modal/Modal.vue';
import { RouterLink } from 'vue-component-router';
import UIRadio from 'shared/ui/forms/Radio.vue';
import Avatar from 'shared/components/avatar';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import AutoSaveField from 'shared/components/AutoSaveField';
import { ErrorBag } from 'shared/util/types';
import SingleParent from './components/SingleParent';
import AddParentToFamily from 'shared/components/AddParentToFamily';
import QuickAddParent from 'shared/components/QuickAddParent';
import { EntityUnionEnum, Gender, MaritalStatus } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SingleFamily',
  components: {
    Loading,
    CollapsibleSection,
    TeenLookup,
    ...RadioGroup,
    QuickAddTeen,
    RouterLink,
    Modal,
    SingleParent,
    AddParentToFamily,
    UIRadio,
    Avatar,
    UIToggle,
    UIButton,
    SimpleForm,
    Field,
    Error,
    AutoSaveField,
    QuickAddParent
  },
  directives: {
    fullheight
  },
  props: {
    regionId: {},
    familyId: {},
    family: {},
    familyLoading: {},
    setParentId: {},
    router: {},
    addToFamily: {},
    addParentToFamily: {},
    addingParentToFamily: {},
    removeChildFromFamily: {},
    addingNewChild: {},
    addingExistingChild: {},
    updateFamilyMaritalStatus: {},
    updateFamilyMaritalStatusLoading: {},
    removingChild: {},
    removeChildFromFamilyRunning: {},
    markFamilyAsPrimary: {},
    markingFamilyAsPrimaryRunning: {},
    markingFamilyAsPrimary: {}
  },
  data () {
    return {
      familyCopy: this.family || null,
      Gender,
      MaritalStatus,
      EntityUnionEnum,
      formShape: {
        child: null,
        addAsPrimary: true
      },
      selectedChild: null,
      creatingFather: false,
      creatingMother: false
    };
  },
  computed: {
    familyNotFound () {
      return !this.family && !this.familyLoading;
    },
    primaryChildren() {
      return (this.familyCopy && this.familyCopy.children || []).filter(c => c && c.primaryFamilyId === +this.familyId) || [];
    },
    nonPrimaryChildren() {
      return (this.familyCopy && this.familyCopy.children || []).filter(c => c && c.primaryFamilyId !== +this.familyId) || [];
    }
  },
  methods: {
    async updateMaritalStatus ({ value }) {
      await this.updateFamilyMaritalStatus(value);
    },
    async submitHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      try {
        await this.addToFamily({
          input: {
            familyId: +this.familyId,
            childId: args.values.child!.personID,
            markAsPrimary: args.values.addAsPrimary
          }
        }, this.family!.original);
      } catch (e) {
        console.log(e);
      }
      if (args.setSubmitted) args.setSubmitted();
      this.$emit('addingExistingChild', false);
    },
    validate (values) {
      let errors: ErrorBag<FormShape> = {};

      if(!values.child) {
        errors.child = 'Please select a child.';
      }

      return errors;
    },
    openRemoveChildModal(child) {
      this.selectedChild = child;
      this.$emit('removingChild', true);
    },
    openMarkAsPrimaryModal(child) {
      this.selectedChild = child;
      this.$emit('markingFamilyAsPrimary', true);
    },
    async markFamilyAsPrimaryHandler() {
      if(this.selectedChild) {
        try {
          await this.markFamilyAsPrimary(this.selectedChild.personID);
        } catch (e) {
          console.log(e);
        }
        this.$emit('markingFamilyAsPrimary', false);
      }
    },
    async removeChildHandler() {
      if(this.selectedChild) {
        try {
          await this.removeChildFromFamily({
              input: {
                childId: this.selectedChild.personID,
              familyId: +this.familyId
            }
          }, this.family!.original);
        } catch (e) {
          console.log(e);
        }
      }
      this.$emit('removingChild', false);
    },
    associateParentHandler (parent) {
      this.addParentToFamily({
        familyId: this.family!.id,
        personId: parent.id,
        gender: parent.gender}
      );
    },
    associateHandler (child, primary) {
      this.addToFamily({
        input: {
          familyId: this.family!.id,
          childId: child.id,
          markAsPrimary: primary
        }
      }, this.family!.original);
    }
  },
  watch: {
    family: {
      immediate: true,
      handler() {
        if (this.family) {
          this.familyCopy = Object.assign({}, {
            ...this.family
          });
        }
      }
    }
  }
});
