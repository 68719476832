
import Vue from 'vue';
import { Dates } from '../../types';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { formatDate } from 'shared/util';
import Loading from 'shared/components/Loading.vue';
import { RouterLink } from 'vue-component-router';
import EventItem from '../EventItem';
import fullheight from 'shared/directives/fullheight';
import Pagination from 'shared/components/Pagination';
import * as RadioGroup from 'shared/radio-group';
import UIRadio from 'shared/ui/forms/Radio.vue';
import { SeriesEventsFilterInput } from 'shared/generated/graphql-types';
import { Event } from '../../types'

interface EventWithHoliday extends Event {
  holidays: {date: string}[];
}

interface Holiday {
  date: string;
  title: string;
}

interface Props {
  events: EventWithHoliday[];
  filters: SeriesEventsFilterInput;
  dates: Dates;
  total: number;
  limit: number;
  setLimit: (limit: number) => void;
  totalPages: number;
  currentPage: number;
  loading: boolean;
  onPageChanged: (page: number) => void;
  isSeries: boolean;
  holidays: Holiday[];
  cancelEvent: (eventId: number, zoomMeetingId: string, occurrence_id: string) => Promise<void>;
}

interface Computed {
  eventsWithHolidays: EventWithHoliday[];
}

interface Methods {
  formatDate: (date: string, timezone: string, format: string) => string;
}

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'AgendaView',
  components: {
    ScrollablePanel,
    Loading,
    RouterLink,
    Pagination,
    UIRadio,
    ...RadioGroup,
    EventItem
  },
  methods: {
    formatDate,
  },
  directives: {
    fullheight
  },
  props: {
    events: {},
    setLimit: {},
    filters: {},
    limit: {},
    dates: {},
    total: {},
    totalPages: {},
    currentPage: {},
    loading: {},
    onPageChanged: {},
    isSeries: {},
    holidays: {},
    cancelEvent: {}
  },
  computed: {
    eventsWithHolidays() {
      return this.events.map((event: EventWithHoliday) => {
        return {
          ...event,
          holidays: this.holidays.filter((holiday: Holiday) => holiday.date === event.startDate.split('T')[0]) || []
        }
      })
    }
  }
})
