
import { Vue, Component, Prop } from 'vue-property-decorator'
import Step from '../../shared/Step.vue'
import { Steps } from '../../../shared/MultistepForm';
import School from '../shared/School.vue';
import Details from '../shared/Details.vue';
import StaffAndChapter from '../shared/StaffAndChapter.vue';
import Thumbnail from '../shared/Thumbnail.vue';
import Options from '../shared/Options.vue';
import { FormState } from '../../../shared/types';
import { diffDays } from '../../../shared/util';
import UIButton from 'shared/ui/buttons/Button.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { GetRegionDataQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']

@Component({
  components: {
    Step,
    School,
    Details,
    StaffAndChapter,
    Thumbnail,
    Options,
    UIButton,
    Modal
  },
  methods: { diffDays }
})
export default class JSU extends Vue {
  @Prop() steps!: Steps
  @Prop() complete!: (step: string) => void
  @Prop() toggleActive!: (step: string) => void
  @Prop() activatable!: (step: string) => boolean
  @Prop() finalStep!: (step: string) => boolean
  @Prop() firstStep!: (step: string) => boolean
  @Prop() setData!: (step: string, key: string, value: any) => void
  @Prop() getData!: (step: string, key: string) => any
  @Prop() region!: Region;
  @Prop() seriesId!: number;
  @Prop({type: String}) state!: FormState
  @Prop() openDescriptionTab!: () => void

  processDetailsConfirmation(confirmed: boolean, handler: () => void) {
    if (!confirmed) {
      this.setData('details', 'LastEvent', this.getData('details', 'FirstEvent'));
    }
    handler();
  }
}
