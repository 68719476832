
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import UICheckbox from 'shared/ui/forms/Checkbox.vue';
  import UIButton from 'shared/ui/buttons/Button.vue';
  import { RadioGroup, RadioGroupItem } from "shared/radio-group";
  import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
  import ChapterLookup from 'shared/components/ChapterLookup';
  import StaffLookup from 'shared/components/StaffLookup';
  import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
  import EventTrackLookup from 'shared/components/EventTrackLookup';
  import UIRadio from 'shared/ui/forms/Radio.vue';
  import UISearch from 'shared/ui/forms/Search.vue';
  import UISelect from 'shared/ui/forms/FancySelect';
  import Divider from 'shared/layout/divider/layout-divider.vue';
  import debounce from 'lodash/debounce';
  import { getCurrentSeason } from 'shared/util';
  import SeasonPicker from 'shared/components/SeasonPicker';
import { EventFilterInput, RegionWideFilter } from 'shared/generated/graphql-types';

  @Component({
    components: { UIRadio, Divider, UISearch, UICheckbox, UIButton, UISelect, FilterGroup, RadioGroupItem,
      RadioGroup, EventTrackLookup, EventSubTypeLookup, ChapterLookup, StaffLookup, SeasonPicker }
})
  export default class EventFilter extends Vue {
    @Prop() user!: number;
    @Prop() regionId!: number;
    @Prop() setFilterHandler!: (value: any, filter: string) => void;
    @Prop() filters!: EventFilterInput;
    @Prop() clearFilters!: (state?: object)=> void;

    seasons: string[] = [ getCurrentSeason() ];
    get isMyEventChecked () {
      return (this.filters.advisorID || []).includes(this.user)
    }
    created () {
      this.setTerm = debounce((e: string) => {
        this.setFilterHandler(e, 'term')
      }, 250)
    }
    filterData(event: object[], locationString: string, emitString: string){
      const arrayOfIds = event.map((e:any)=>e[locationString]);
      this.setFilterHandler(arrayOfIds, emitString);
    }
    regionWideHandler (filter: RegionWideFilter) {
      if (filter === RegionWideFilter.Only) {
        this.clearFilters({regionWide: RegionWideFilter.Only, advisorID: [...(this.isMyEventChecked ? [this.user] : [])]});
      }
      else {
        this.setFilterHandler(filter, 'regionWide')
      }
    }
    handleMyEventChange(event: boolean){
      if(event && !(this.filters.advisorID || []).includes(this.user)) {
        this.setFilterHandler([...(this.filters.advisorID || []), this.user], 'advisorID');
      } else if (!event) {
        this.setFilterHandler([...(this.filters.advisorID || []).filter(i => i !== this.user)], 'advisorID');
      }
    }
  };
