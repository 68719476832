<template>
  <textarea v-bind="$attrs" :value="value" v-on="listeners" :placeholder="placeholder" autocomplete="hack"/>
</template>
<script>
import { filterNonPrintable } from 'shared/util'

export default {
  name: 'UITextarea',
  props: {
    value: [String, Number],
    placeholder: {
      type: String,
      default: ''
    }
  },
  created () {
    this.listeners = {
      ...this.$listeners,
      input: e => this.$emit('input', filterNonPrintable(e.target.value)),
      blur: e => this.$emit('blur', filterNonPrintable(e.target.value))
    }
  }
};
</script>

<style lang="scss">
textarea {
  display: block;
  min-height: 60px;
  border: 1px solid #f3f3f3;
  background: #f3f3f3;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: none;
  width: 100%;
  transition: all .3s ease-in-out;

  &:hover {
    border-color: #c9d4bd;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 5px #d1ecda;
    border-color: #c9d4bd;
    background: white;
  }
}

textarea::-webkit-input-placeholder { color:#ccc;}
textarea::-moz-placeholder { color:#ccc;}
textarea:-ms-input-placeholder { color:#ccc;}
</style>
