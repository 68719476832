
import Vue from 'vue';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import SimpleForm from 'vue-simpleform'
import { Props } from './types';
import NoteForm from '../note-form.vue';
import { NoteFormObject } from '../../types';
import startCase from 'lodash/startCase';
import padStart from 'lodash/padStart';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { SimpleDate } from 'shared/util/types';
import { NoteCategory } from 'shared/generated/graphql-types';

library.add(faPencil, faTrash)

export default Vue.extend<{}, {}, {}, Props>({
    name: "Note",
    components: {
        SimpleForm,
        UIButton,
        UIInput,
        Modal,
        NoteForm
    },
    data () {
        return {
            editingNote: false,
            deleting: false,
            showNote: false
        }
    },
    props: {
        note: {},
    },
    methods: {
        formatDate (date: SimpleDate) {
            return `${date.year}-${padStart(String(date.month), 2, '0')}-${padStart(String(date.day), 2, '0')}`;
        },
        formatCategory (category: NoteCategory) {
            return startCase(category);
        }
    },
    computed: {
        noteFormModel (): NoteFormObject {
            return {
                staff: {
                    __typename: 'Staff',
                    staffID: this.note.staff.id,
                    firstName: this.note.staff.firstName,
                    lastName: this.note.staff.lastName,
                    fullName: this.note.staff.fullName
                },
                date: this.note.date,
                category: this.note.category ? {
                  value: this.note.category,
                  name: startCase(this.note.category)
                } : null,
                note: this.note.note
            }
        }
    }
})
