
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import Loading from 'shared/components/Loading.vue';
import Avatar from 'shared/components/avatar';
import ProfilePhoto from 'shared/components/ProfilePhoto/ProfilePhoto.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import UISwitch from 'shared/ui/forms/Switch.vue';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faMale } from '@fortawesome/pro-solid-svg-icons/faMale';
import { faFemale } from '@fortawesome/pro-solid-svg-icons/faFemale';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import copy from 'copy-text-to-clipboard';
import Address from 'shared/components/Address/Address.vue';
import UIPassword from 'shared/ui/forms/Password.vue';
import { RouterLink } from 'vue-component-router';
import UIInput from 'shared/ui/forms/Input.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { CommunityMemberFieldsEnum, CommunityMemberUpdateArgs, CommunityMemberViewModel } from './types';
import { validateEmail, phoneInternational, phoneFormat, validateNumber } from 'shared/util';
import EmailInput from 'shared/components/EmailInput';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import RequiresPermission from 'shared/components/RequiresPermission';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import isNull from 'lodash/isNull';
import { ConsentDataInput, CreateAddress, CreateEmail, CreatePhone, PermissionEnum, PhoneType, ResetPasswordMutationVariables, UpdateAddress, UpdateEmail, UpdatePhone } from 'shared/generated/graphql-types';
import { Me } from 'shared/types';

library.add(faPhone, faEnvelope, faMobileAlt, faGift, faHome,faMale, faFemale)

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

@Component({
  name: "CommunityMemberProfile",
  components: {
    ProfilePhoto,
    AutoSaveField,
    UICheckbox,
    PhoneInput,
    UIPassword,
    RequiresPermission,
    UIInput,
    Address,
    UITextarea,
    EmailInput,
    UISwitch,
    Loading,
    Avatar,
    UIButton,
    Modal,
    RouterLink,
    ScrollablePanel,
    FilterGroup
  },
  directives: {
    fullheight
  },
  methods: {
    validateEmail,
    validateNumber,
    phoneFormat,
    phoneInternational,
    isNull
  }
})
export default class extends Vue {
    @Prop() personID!: number;
    @Prop() regionId!: number;
    @Prop() me!: Me;
    @Prop() changingPassword!: boolean;
    @Prop() markingCommunityMemberConsent!: boolean;
    @Prop() markCommunityMemberConsent!: (communityMemberId: number, data: ConsentDataInput) => void;
    @Prop() deletePhone!: (id: number) => void;
    @Prop() showPasswordModal!: boolean;
    @Prop() communityMember!: CommunityMemberViewModel;
    @Prop() loadingCommunityMemberProfile!: boolean;
    @Prop() changePassword!: (args: ResetPasswordMutationVariables) => void;
    @Prop() updatePhone!: (args: UpdatePhone, optimisticResponseData ? : CommunityMemberUpdateArgs) => void;
    @Prop() createPhone!: (args: CreatePhone, optimisticResponseData ? : CommunityMemberUpdateArgs) => void;
    @Prop() updateEmail!: (args: UpdateEmail, optimisticResponseData ? : CommunityMemberUpdateArgs) => void;
    @Prop() createEmail!: (args: CreateEmail, optimisticResponseData ? : CommunityMemberUpdateArgs) => void;
    @Prop() updateAddress!: (args: UpdateAddress, optimisticResponseData ? : CommunityMemberUpdateArgs) => void;
    @Prop() createAddress!: (args: CreateAddress, optimisticResponseData ? : CommunityMemberUpdateArgs) => void;

    showDataOptOutConfirmation: boolean = false;
    PermissionEnum: typeof PermissionEnum = PermissionEnum;
    editingMode: boolean = false;
    communityMemberCopy = Object.assign({}, {
        ...this.communityMember,
        password: null
    });
    updateCommunityMemberField = CommunityMemberFieldsEnum
    password: string | null = null
    get fullName() {
        const {
            firstName,
            lastName
        } = this.communityMember;
        if (!firstName) return ''
        return `${firstName} ${lastName || ''}`
    }
    get currentAdvisorRegion() {
      return this.me && this.regionId &&
        this.me.AdvisorRegions.find(a => a.Region.regionId === this.regionId) || {
          claims: [],
          AdvisorRegionId: 0,
          Region: {
            regionName: '',
            regionId: 0,
            country: '',
            __typename: 'Region'
          },
          active: false,
          Role: null,
          __typename: 'AdvisorRegion'
        };
    }

    copyToClipboard(string: string) {
        if (this.editingMode || !string) return
        copy(string)
    }
    getGenderColor(gender: number) {
        if (!gender) {
            return '#00bcd4'
        } else {
            return '#cd8ece'
        }
    }
    async markCommunityMemberConsentHandler() {
      await this.markCommunityMemberConsent(this.personID, { dataOptOut: true });
      this.showDataOptOutConfirmation = false;
    }
    update(args: SaveEventArgs <{ fieldName: CommunityMemberFieldsEnum & { password: "password" }, value: string }> ) {
      // // TODO: dont save if there is no value atleast until this command will support that - https://app.asana.com/0/82580073031913/1134617948128938.
      //   if (!args.transformed.value) return
        const fieldName: CommunityMemberFieldsEnum = args.transformed.fieldName;
        const value: string = args.transformed.value;
        switch (fieldName) {
            case 'cellphone':
            case 'landline': {
                if (!value) {
                  const phoneId = this.communityMember[fieldName]!.id
                  this.deletePhoneHandler(phoneId, fieldName)
                }
                else if (this.communityMember[fieldName] && this.communityMember[fieldName]!.id) {
                    const phoneId = this.communityMember[fieldName] !.id
                    this.updatePhoneHandler({
                            id: phoneId,
                            phoneNumber: value,
                            type: (fieldName === 'landline' ? 'Landline' : 'Mobile') as PhoneType,
                            personId: this.personID,
                            isActive: true,
                            doNotCall: false
                        },
                        fieldName);
                } else {
                    this.createPhoneHandler({
                        phoneNumber: value,
                        personId: this.personID,
                        type: (fieldName === 'landline' ? 'Landline' : 'Mobile') as PhoneType,
                        doNotCall: false
                    }, fieldName);
                }
                break;
            }
            case 'email': {
                if (this.communityMember[fieldName] && this.communityMember.emailId) {
                    const emailId = this.communityMember.emailId
                    this.updateEmailHandler({
                            id: emailId,
                            email: value,
                            personId: this.personID,
                        },
                        fieldName);
                } else {
                    this.createEmailHandler({
                        email: value,
                        personId: this.personID
                    }, fieldName);
                }
                break;
            }
            case 'address': {
                if (this.communityMember[fieldName] && this.communityMember[fieldName] !.id) {
                    this.updateAddressHandler({
                            ...JSON.parse(value),
                            personId: this.personID,
                        },
                        fieldName);
                } else {
                    this.createAddressHandler({
                        ...JSON.parse(value),
                        personId: this.personID
                    }, fieldName);
                }
                break;
            }
        }
    }
    async passwordHandler() {
        if (!this.password) return
        await this.changePassword({
            password: this.password,
            personId: this.personID
        });
        this.$emit('showPasswordModal', false);
        this.password = null;
    }
    updateEmailHandler(email: UpdateEmail, fieldName: CommunityMemberFieldsEnum) {
        this.updateEmail(email, {
            personId: this.personID,
            fieldName,
            value: email.email
        });
    }
    createEmailHandler(email: CreateEmail, fieldName: CommunityMemberFieldsEnum) {
        this.createEmail(email, {
            personId: this.personID,
            fieldName,
            value: email.email,
        });
    }
    updateAddressHandler(address: UpdateAddress, fieldName: CommunityMemberFieldsEnum) {
        this.updateAddress(address, {
            personId: this.personID,
            fieldName,
            value: JSON.stringify(address)
        });
    }
    createAddressHandler(address: CreateAddress, fieldName: CommunityMemberFieldsEnum) {
        this.createAddress(address, {
            personId: this.personID,
            fieldName,
            value: JSON.stringify(address),
        });
    }
    updatePhoneHandler(phone: UpdatePhone, fieldName: CommunityMemberFieldsEnum) {
        this.updatePhone(phone, {
            personId: this.personID,
            fieldName,
            value: phone.phoneNumber
        });
    }
    deletePhoneHandler(id: number, fieldName: CommunityMemberFieldsEnum) {
        this.deletePhone(id);
    }
    createPhoneHandler(phone: CreatePhone, fieldName: CommunityMemberFieldsEnum) {
        this.createPhone(phone, {
            personId: this.personID,
            fieldName,
            value: phone.phoneNumber,
        });
    }
    @Watch('communityMember', {
        deep: true
    })
    communityMemberViewModelWatcher() {
        if (this.communityMember) this.communityMemberCopy = Object.assign({}, {
            ...this.communityMember,
            password: null
        });
    }
}

