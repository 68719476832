import { ActiveFiltersType } from './shared/ActiveFilters';
import { toCompactTeens } from './utils';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetFilterOptionsQuery,
  TeensMissingSchoolQuery,
  useGetFilterOptionsQuery,
  useTeensMissingSchoolQuery
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import CompactTeen from './shared/CompactTeen';
import { ApolloQueryResult } from '@apollo/client';

interface Props {
  regionId: number;
  activeFilters: ActiveFiltersType;
  limit: number;
}

type FilterOptionsProps = {
  filterOptions: GetFilterOptionsQuery;
};
export const filterOptionsEnhancer = wrapComponent<Props, FilterOptionsProps>((props) => {
  const { result } = useGetFilterOptionsQuery(
    computed(() => ({ regionId: props.regionId })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    filterOptions: result.value
  }));
});

type TeenMissingSchoolsProps = {
  teens: CompactTeen[];
  loading: boolean;
  initialLoading: boolean;
  total: number;
  fetchMore: (vars: {
    limit: number;
    offset: number;
  }) => Promise<ApolloQueryResult<TeensMissingSchoolQuery>> | undefined;
};
export const teensMissingSchoolEnhancer = wrapComponent<Props, TeenMissingSchoolsProps>((props) => {
  const { fetchMore, loading, result } = useTeensMissingSchoolQuery(
    computed(() => ({
      limit: props.limit,
      offset: 0,
      filter: {
        regionId: props.regionId,
        missingSchool: true,
        ...props.activeFilters
      },
      regionId: props.regionId
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    teens: toCompactTeens(result.value?.teens.teens || []),
    loading: loading.value,
    initialLoading: loading.value && !result.value?.teens,
    total: result.value?.teens.total || 0,
    fetchMore: ({ limit, offset }) =>
      fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            regionId: props.regionId,
            missingSchool: true,
            filters: props.activeFilters && props.activeFilters.filters
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          const newData = (fetchMoreResult?.teens && fetchMoreResult.teens.teens) || [];
          return {
            teens: {
              __typename: 'TeenPage',
              total: fetchMoreResult?.teens.total || fetchMoreResult?.teens.total || 0,
              teens: [...newData]
            }
          };
        }
      })
  }));
});
