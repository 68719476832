


import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import { ServerSideFilterOption } from 'shared/components/Filters/FilterOption';
import { getGrade } from 'shared/util';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';
import identity from 'lodash/identity';
import range from 'lodash/range';
import uniqBy from 'lodash/uniqBy';
import { ActiveFiltersType } from '../shared/ActiveFilters';
import unset from 'lodash/unset';
import { GetFilterOptionsQuery } from 'shared/generated/graphql-types';

@Component({
  components: {
    UICheckbox,
    UISelect
  },
	methods: {
		getGrade
	}
})
export default class FiltersPanel extends Vue {
  @Prop() activeFilters!: ActiveFiltersType;
  @Prop() filterOptions!: GetFilterOptionsQuery;

	filters: { [key: string]: ServerSideFilterOption[] } = {
    College: this.activeFilters.filters.College || [],
    Chapter: this.activeFilters.filters.Chapter || [],
    GapYearProgram: this.activeFilters.filters.GapYearProgram || [],
    Grade: this.activeFilters.filters.Grade || []
  }

  missingCollege: boolean = !!this.activeFilters.missingCollege
  missingIsraelProgram: boolean = !!this.activeFilters.missingIsraelProgram
  eventImpact: boolean = !!this.activeFilters.eventImpact;

	get localActivefilters () {
    return {
      filters: omitBy(pickBy(this.filters, identity), (x) => isArray(x) && !x.length),
      missingCollege: this.missingCollege,
      eventImpact: this.eventImpact,
      missingIsraelProgram: this.missingIsraelProgram
      }
	}
	get gradeOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.gradeOptions || [])
			.filter(go => go.key !== null && go.key.length === 4)
			.map(go => getGrade(+(go.key!)))
			.filter(go => go.grade !== null && go.grade.length > 0 )
			.map(go => ({
				key: go.year ? String(go.year) : '',
				displayValue: go.grade
			})), ['displayValue'], ['desc']), 'key');
  }
	get chapterOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.chapterOptions || [])
			.filter(co => co.key !== null && co.displayValue !== null)
			.map(co => ({
				key: co.key!,
				displayValue: co.displayValue!
			})), ['displayValue']), 'key');
  }
	get collegeOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.collegeOptions || [])
			.filter(so => so.key !== null && so.displayValue !== null)
			.map(so => ({
				key: so.key!,
				displayValue: so.displayValue!
			})), ['displayValue']), 'key');
  }
	get gapYearProgramOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.gapYearProgramOptions || [])
			.filter(so => so.key !== null && so.displayValue !== null)
			.map(so => ({
				key: so.key!,
				displayValue: so.displayValue!
			})), ['displayValue']), 'key');
	}
  get orderedGradutationOptions() {
      const grades: number[] = this.gradeOptions.map(x => +x.key)
      const date = new Date();
      const schoolYear = date.getMonth() > 5 ? date.getFullYear() + 1 : date.getFullYear();
      const highSchoolRange = range(schoolYear, schoolYear + 4).reverse()
      const highSchoolFiltered = grades.filter(x => x <= highSchoolRange[0] && x >= highSchoolRange[highSchoolRange.length - 1])
      const elementaryRange = range(schoolYear + 4, schoolYear + 13).reverse()
      const elementaryFiltered = grades.filter(x => x <= elementaryRange[0] && x >= elementaryRange[elementaryRange.length - 1])
      const alumniRange = range(schoolYear - grades[grades.length - 1], schoolYear).reverse()
      const alumniFiltered = grades.filter(x => x <= alumniRange[0] && x >= alumniRange[alumniRange.length - 1])
      return uniqBy(highSchoolFiltered.concat(elementaryFiltered).concat(alumniFiltered).map(key => ({
          key: String(key),
          displayValue: getGrade(key).grade
      })), 'key');
  }
	@Watch ('filters', { deep: true })
	filtersWatcher () {
    if (this.filters.College.length && this.missingCollege) this.missingCollege = false;
    if (this.filters.GapYearProgram.length && this.missingIsraelProgram) this.missingIsraelProgram = false;
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('missingIsraelProgram')
	missingIsraelProgramWatcher (missingIsraelProgram: boolean) {
    if (missingIsraelProgram) unset(this.filters, 'GapYearProgram')
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('missingCollege')
	missingCollegeProgramWatcher (missingCollege: boolean) {
    if (missingCollege) unset(this.filters, 'College')
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('eventImpact')
	eventImpactWatcher (eventImpact: boolean) {
    this.$emit('filters', this.localActivefilters)
  }

}
