
import Vue from 'vue';
import { Props } from './types';
import SchoolLookup from 'shared/components/SchoolLookup';

type ExtendedProps = Props & { hideChapter: boolean, regionId: number };

interface Methods {
  isHasErrors: (key: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, ExtendedProps>({
  name: 'School',
  components: {
    SchoolLookup
  },
  props: {
    getData: {},
    displayErrors: {},
    setData: {},
    step: {},
    steps: {},
    hideChapter: {},
    regionId: {}
  },
  methods: {
    isHasErrors(key) {
      return this.step.validations && this.step.validations[key];
    }
  }
})
