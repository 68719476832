

import { Vue, Prop, Component } from 'vue-property-decorator';
import camelCase from 'lodash/camelCase';
import difference from 'lodash/difference';
import omitBy from 'lodash/omitBy';
import UIButton from 'shared/ui/buttons/Button.vue';
import SeasonPicker from './components/seasonPicker.vue';
import CalendarYearPicker from './components/calendarYearPicker.vue';
import GraduationYearPicker from './components/graduationYearPicker.vue';
import StartDatePicker from './components/startDatePicker.vue';
import EndDatePicker from './components/endDatePicker.vue';
import ChapterIdPicker from './components/chapterIdPicker.vue';
import AdvisorIdPicker from './components/advisorIdPicker.vue';
import EventSubTypeIdPicker from './components/eventSubTypeIdPicker.vue';
import SeriesIdPicker from './components/seriesIdPicker.vue';
import EventIdPicker from './components/eventIdPicker.vue';
import IsraelProgramIdPicker from './components/israelProgramIdPicker.vue';
import CollegeIdPicker from './components/collegeIdPicker.vue';
import SchoolIdPicker from './components/schoolIdPicker.vue';
import SynagogueIdPicker from './components/synagogueIdPicker.vue';
import TorahHighSeriesIdPicker from './components/torahHighSeriesIdPicker.vue';
import GenericIdPicker from './components/genericIdPicker.vue';
import { Parameter } from 'shared/generated/graphql-types';

export type ReportFields = {[K in Parameter]: string | null }

@Component({
  components: {
    SeasonPicker,
    CalendarYearPicker,
    GraduationYearPicker,
    GenericIdPicker,
    StartDatePicker,
    EndDatePicker,
    ChapterIdPicker,
    AdvisorIdPicker,
    CollegeIdPicker,
    SchoolIdPicker,
    SynagogueIdPicker,
    EventSubTypeIdPicker,
    TorahHighSeriesIdPicker,
    SeriesIdPicker,
    IsraelProgramIdPicker,
    UIButton,
    EventIdPicker,
    SuperPersonIdPicker: Vue.extend({
      name: 'SuperPersonIdPicker',
      props: ['staffId'],
      mounted () {
        this.$emit('input', this.staffId)
      },
      render: h => h()
    }),
    RegionIdPicker: Vue.extend({
      name: 'RegionIdPicker',
      props: ['regionId'],
      mounted () {
        this.$emit('input', this.regionId)
      },
      render: h => h()
    })
  },
  methods: {
    omitBy,
    difference
  }
})
export default class ReportForm extends Vue {
  @Prop() value!: number;
  @Prop() report!: any;
  @Prop() regionId!: number;
  @Prop() staffId!: number;
  @Prop() exclude!: string[];

  newReport: any = Object.values(Parameter).reduce((parameters, key) => ({...parameters, [key]: null}), {});
  generating: boolean = false;
  selectedSeason: string | null = null;

  inputHandler (event: any, field: string) {
    if (!event || !field) return
    if (field === 'season') this.selectedSeason = event;
    this.$emit('input', {field, value: event})
    this.newReport[field] = event;
  }
  formatFieldName(name: string) {
    return `${camelCase(name).charAt(0).toUpperCase() + camelCase(name).slice(1)}Picker`
  }
}
