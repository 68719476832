
import Vue from 'vue';
import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

export default Vue.extend({
	name: 'ToggleButtonWrapper',
	props:['value', 'sync', 'disabled', 'color', 'labels', 'width', 'height', 'change', 'meta'],
	methods: {
		onChange(event: Event){
			this.change(event, this.meta);
		}
	}
});
