
import Vue from 'vue';
import UISearch from 'shared/ui/forms/Search.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons/faFileCsv';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import * as RadioGroup from 'shared/radio-group';
import UIRadio from 'shared/ui/forms/Radio.vue';

library.add(faPlusCircle, faFilePdf, faFileCsv, faFileExcel);

export default Vue.extend({
	name: 'Sidebar',
	props: {
    addingNewBus: Boolean,
    busesCount: Number,
    direction: String,
    capacity: String,
    term: String,
    stats: Object
	},
	components: {
		PanelsSidebar,
		UISearch,
		UIToggle,
		FilterGroup,
		UICheckbox,
		UIButton,
		ScrollablePanel,
		UIRadio,
		...RadioGroup
	},
	directives: {
		fullheight
	}
});
