
import Vue from 'vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import UIButton from 'shared/ui/buttons/Button.vue';
import fullheight from 'shared/directives/fullheight';
import UISwitch from 'shared/ui/forms/Switch.vue';
import { ActiveFilters } from '../../shared/types';

interface Data {
  filters: ActiveFilters;
  hasAllergyPlan: boolean;
  hasIEPPlan: boolean;
}

interface Methods {
  clearFilters: () => void;
  switcherChange: () => void;
}

interface Computed {
  localActiveFilters: any;
}

interface Props {
  activeFilters: any;
}

library.add(faSortAlphaDown,faSortAlphaUp);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "FiltersPanel",
  components: {
    UISwitch,
    ScrollablePanel,
    UIButton
  },
  directives: {
    fullheight
  },
  props: {
    activeFilters: {}
  },
  data() {
    return {
      filters: {
        ...this.activeFilters.filters
      },
      hasAllergyPlan: this.activeFilters.hasAllergyPlan,
      hasIEPPlan: this.activeFilters.hasIEPPlan,
    };
  },
  computed: {
    localActiveFilters () {
      return { ...this.filters, hasAllergyPlan: this.hasAllergyPlan, hasIEPPlan: this.hasIEPPlan  }
    }
  },
  methods: {
    clearFilters () {
      this.hasAllergyPlan = false;
      this.hasIEPPlan = false;
      this.$emit('filters', this.localActiveFilters);
    },
    switcherChange () {
      this.$emit('filters', this.localActiveFilters);
    }
  }
})
