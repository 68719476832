
import { Vue, Component, Prop } from 'vue-property-decorator';
import { toggleInArray } from 'shared/util';
import values from 'lodash/values';
import FilterTypeDisplay from './FilterTypeDisplay.vue';
import { FilterOption, FilterOptionGroup } from './FilterOption';
import set from 'lodash/fp/set';

@Component({
	name: 'Filters',
	components: {
		FilterTypeDisplay
	}
})
export default class extends Vue {

	@Prop()
	filtersOpen!: boolean;

	@Prop()
	activeFilters!: { [key: string]: FilterOption[] };

	@Prop()
	filters!: FilterOptionGroup[];

	selectedFilterGroup: FilterOptionGroup | null = null;

	get selectedFilters () {
		return this.activeFilters[(this.selectedFilterGroup || { name: '' }).name] || [];
	}

	get filtersSelected () {
		return values(this.activeFilters).reduce((f, o) => f.concat(o), []).length;
	}

	selectFilterType (filterGroup: FilterOptionGroup) {
		this.selectedFilterGroup = filterGroup;
	}

	deselectFilterType () {
		this.selectedFilterGroup = null;
	}

	selectionMade (filterOptions: FilterOption[]) {
		this.$emit('update:activeFilters', set(this.selectedFilterGroup!.name, filterOptions, this.activeFilters));
	}

	displayLiveFiltersForType (filterType: string) {
		return (this.activeFilters[filterType] || []).map(f => f.displayValue).join(', ');
	}

	clearAllFilters () {
		this.$emit('update:activeFilters', {});
	}

	close () {
		this.$emit('update:filtersOpen', false);
	}
}
