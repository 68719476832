
import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import FancySelect from 'shared/ui/forms/FancySelect';
import UIButton from 'shared/ui/buttons/Button.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import Loading from 'shared/components/Loading.vue';
import { Data, Methods, Computed, Props } from './types';
import { ErrorBag } from '../../util/types';
import FamilyLookup from 'shared/components/FamilyLookup';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FamilySelector',
  components: {
    FancySelect,
    SimpleForm,
    Field,
    Error,
    UIButton,
    UICheckbox,
    Loading,
    FamilyLookup
  },
  props: {
    regionId: {},
    childId: { required: true },
    addToFamily: {},
    modalMode: { default: false },
    markAsPrimary: { default: true }
  },
  data () {
    return {
      formValue: {
        family: null,
        markAsPrimary: this.markAsPrimary
      }
    };
  },
  methods: {
    validate (values) {
      let errors: ErrorBag<typeof values> = {};

      if (!values.family) {
        errors.family = 'Please select a family';
      }

      return errors;
    },
    async addToFamilyHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      await this.addToFamily({
        input: {
          familyId: args.values.family!.id,
          markAsPrimary: args.values.markAsPrimary,
          childId: this.childId
        }
      }, args.values.family!);

      if (args.setSubmitted) {
        args.setSubmitted();
      }
      if (this.modalMode) {
        this.$emit('requestClose');
      }
    }
  }
})
