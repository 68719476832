
import Root from './pages/Root';
import { Route, HistoryRouter } from 'vue-component-router';
import NotificationsContainer from 'shared/components/NotificationsContainer';
import Oidc from 'shared/oidc/oidc.vue';

export default {
	name: 'app',
	components: {
		Root,
		HistoryRouter,
		Route,
		Oidc,
		NotificationsContainer
	},
	methods: {
		getAuthState (authenticating: boolean, redirecting: boolean) {
			if (authenticating) return 'authenticating';
			if (redirecting) return 'redirecting';
		}
	}
};
