import { QuestionType } from "shared/generated/graphql-types";

export interface Question {
  id: string | undefined;
  order: number;
  type: QuestionType;
  text: string;
  required: boolean | null;
  timestamp: any | null;
  isSaved: boolean;
  config: {
    minLength: number | null;
    maxLength: number | null;
    isMultiSelect: boolean | null;
    options: string[];
  };
  original?: Question | null
}

export interface Configuration {
  displayName: string;
  name: string;
}

export type Validations = Partial<Record<keyof Question, string[]>>

export interface Props {
  value: Question;
  isDeleting: boolean;
  validations: Validations;
}

export interface Data {
  question: Question;
  types: Configuration[];
  editedProperties: Array<keyof Question>;
}

export interface Computed {
  isChanged: boolean;
}

export interface Methods {
  input: () => void;
  reset: () => void;
  remove: () => void;
  markProperty: (property: keyof Question) => void;
  displayErrors: (property: keyof Question) => boolean;
}

export function defaultQuestion (): Question {
  return {
    id: undefined,
    order: 0,
    text: '',
    type: QuestionType.TextString,
    required: true,
    timestamp: new Date(),
    isSaved: false,
    config: {
      minLength: null,
      maxLength: null,
      isMultiSelect: false,
      options: [],
    },
    original: null
  };
}

export type ValidationRule = {
  isValid: (value: any, question: Question) => boolean;
  message: string;
};

export interface ValidationRules {
  property: keyof Question;
  rules: ValidationRule[];
}
