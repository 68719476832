

import { CompactTeen } from './shared/CompactTeen'
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import Profile from "./pages/Profile";
import Emails from "./pages/Emails";
import Events from "./pages/Events/Events.vue";
import Notes from './pages/Notes';
import Interactions from './pages/Interactions';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { Route, RouterLink, MatchFirst } from 'vue-component-router';
import Loading from 'shared/components/Loading.vue';
import { Tabs } from './shared/types';
import { CurrentUserQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type AdvisorRegion = ArrayElement<CurrentUserQuery['me']['AdvisorRegions']>

@Component({
  name: "Single",
  components: {
	Profile,
	Events,
  Emails,
  Route,
  MatchFirst,
  RouterLink,
	ScrollablePanel,
	Loading,
  Notes,
  Interactions
  },
  directives: {
	fullheight
  }
})

export default class extends Vue {

  @Prop() personId!: number;
  @Prop() url!: string;
  @Prop() regionId!: number;
  @Prop() teen!: CompactTeen;
  @Prop() router!: { location: { pathname: string } };
  @Prop() currentTab!: string;
  @Prop() selectingTeen!: boolean;
  @Prop() currentUser!: AdvisorRegion;
  @Prop() country!: string;
  @Prop() loading!: boolean;

  Tabs = Tabs;

  isActive (tab: string): boolean {
    // @ts-ignore
    return Tabs[this.currentTab] === Tabs[tab]
  }
  get teenNotFound () {
    return !this.teen && !this.loading && !this.selectingTeen
  }
  get loadingData () {
    return this.selectingTeen || this.loading
  }
}


