

import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import TeenProfile from 'shared/components/TeenProfile';
import ScholarshipHistory from './components/ScholarshipHistory';
import Pagination from 'shared/components/Pagination';
import ScholarshipItem from './components/ScholarshipItem';
import Loading from 'shared/components/Loading.vue';
import Sidebar from './components/Sidebar';
import { ScholarshipRequest } from './types';
import Modal from 'shared/ui/modal/Modal.vue';
import ScholarshipForm from "./components/ScholarshipForm";
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faOutdent} from '@fortawesome/pro-regular-svg-icons/faOutdent';
import {faIndent} from '@fortawesome/pro-regular-svg-icons/faIndent';
import { ApolloClient } from '@apollo/client';
import { AddNewScholarshipRequestMutationVariables, ScholarshipFragmentFragment as Scholarship, ScholarshipGrantMutationVariables } from "shared/generated/graphql-types";
import { Chapter } from "shared/types";

library.add(faIndent, faOutdent)

@Component({
  components: {
    BorderedList,
    TeenProfile,
    Pagination,
    Loading,
    Sidebar,
    PanelsGrid,
    Modal,
    UIButton,
    UIInput,
    ScholarshipHistory,
    ScholarshipForm,
    ScholarshipItem,
    ScrollablePanel
	},
	directives: {
		fullheight
	},
})
export default class Scholarships extends Vue {
  @Prop() eventId!: number;
  @Prop() regionId!: number;
  @Prop() scholarshipsRequestedList!: Scholarship[];
  @Prop() eligibleForScholarshipsList!: Scholarship[];
  @Prop() addNewScholarshipRequest!: (args: AddNewScholarshipRequestMutationVariables ) => void;
  @Prop() approveScholarshipGrant!: (args: ScholarshipGrantMutationVariables) => void;
  @Prop() scholarshipDenied!: (args: { registrationId: number, disableTeenNotification: boolean}) => void;
  @Prop() scholarshipsApprovedSum!: number;
  @Prop({ type: Number, default: null }) selectedTeen!: number | null;
  @Prop() isLoading!: boolean;
  @Prop() addingScholarshipRequest!: boolean;
  @Prop() historyMode!: boolean;
  @Prop() currencySymbol!: string;
  @Prop() toShowContactInformation!: boolean;
  @Prop() client!: ApolloClient<any>
  @Prop(String) eventDate!: string | null;
  @Prop(String) eventTimezone!: string | null;

  currentPage: number = 1;
  total: number = 0;
  limit: number = 10;
  term: string = '';
  status: 'pending' | 'approved' | 'denied' | null = 'pending';
  chapterFilter: Chapter | null = null
  profileClosed = !!!this.selectedTeen;
  editingTeenProfile: boolean = false;

  toggleProfile () {
    this.profileClosed = !this.profileClosed;
  }

  get contactInformation () {
      const contactInformation = this.scholarshipsRequestedList.find(x => x.personID === this.selectedTeen)!;
      if (!contactInformation || !contactInformation.scholarshipConcatParent) return 'There is no contact information'
      return `<label><strong>${contactInformation.scholarshipConcatParent} -</strong></label> ${contactInformation.scholarshipConcatPhone}`
  }
  get offset () {
    return (this.currentPage -1) * this.limit
  }

  get totalPages () {
    if (this.limit === this.total) return this.total / this.limit
    return Math.ceil(this.total / this.limit)
  }

  get filteredScholarshipsRequestedList () {
        const { offset, limit } = this;
        let result = (this.scholarshipsRequestedList || []).filter(
            r =>
      `${r.Teen.Person.firstName} ${r.Teen.Person.lastName}`.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0
    );

    const statusFilters = {
      pending: (r: Scholarship) => r.scholarshipGrant === null,
      approved: (r: Scholarship) => r.scholarshipGrant! > 0,
      denied: (r: Scholarship) => r.scholarshipGrant === 0
    }
    const scholarshipPredicate = statusFilters[this.status!] || (() => true)

    let chapterPredicate: (r: Scholarship) => boolean;
    switch (this.chapterFilter) {
      case null:
        chapterPredicate = r => true;
        break;
      default:
        chapterPredicate =  r => !!(r.Teen!.Chapter && r.Teen!.Chapter.chapterId === this.chapterFilter?.chapterId)
        break;
    }

    const results = result.filter(scholarshipPredicate).filter(chapterPredicate);

    this.total = results.length;

    return results.slice(offset, (offset + limit));
  }
  clearFilters(){
    this.term = '';
    this.chapterFilter = null;
    this.status = 'pending';
  }
  showHistoryMode (id: number) {
    return this.selectedTeen === id && this.historyMode
  }

  scholarshipRequestHandler (newScholarship: ScholarshipRequest) {
    const  { registrationId, scholarshipRequest, scholarshipGrant, disableNotification, reasonForScholarship, disableTeenNotification } = newScholarship;
    if (scholarshipRequest && registrationId) {
        this.addNewScholarshipRequest({ registrationId, scholarshipRequest, disableNotification, reasonForScholarship, disableTeenNotification });
        if (scholarshipGrant) {
          this.approveScholarshipGrant({ registrationId, scholarshipGrant, disableNotification, disableTeenNotification });
        }
    }
    this.$emit('addingScholarshipRequest', false);
  }

  onPageChange(page: number) {
    this.currentPage = page;
  }

  @Watch('status')
  onfilter () {
    this.currentPage = 1;
  }

  @Watch('chapterFilter')
  onChapterFilter () {
    this.currentPage = 1;
    this.$emit('selectedTeen', null);
  }

  @Watch('selectedTeen')
  onSelectedTeen () {
    this.profileClosed = false;
  }
}
