
import Vue, { CreateElement, VNode } from 'vue'
import { mapActions, mapGetters } from 'vuex'

interface Data {
  redirecting: boolean
  error: boolean
}

interface Props {
  url: string
  changeLocation: (url: string) => void
}

interface Computed {
  isAuthenticated: boolean
  user: any
}

interface Methods {
  authenticate: (url: string) => Promise<void>
  signInCallback: () => Promise<string | null | undefined>
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Oidc',
  props: {
    url: {},
    changeLocation: {}
  },
  data() {
    return {
      redirecting: false,
      error: false
    }
  },
  async created() {
    if (this.url === '/callback') {
      this.redirecting = true
      try {
        const result: string | null | undefined = await this.signInCallback()
        this.changeLocation(result ?? '/')
      } catch (e) {
        this.error = true
      }
      this.redirecting = false
    }
    if (!this.isAuthenticated || !this.user) {
      await this.authenticate(this.url)
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'oidc/oidcIsAuthenticated',
      user: 'oidc/oidcUser'
    })
  },
  methods: {
    ...mapActions({
      authenticate: 'oidc/authenticateOidc',
      signInCallback: 'oidc/oidcSignInCallback'
    })
  },
  render(h: CreateElement) {
    const maybeWrap = (vnodes: string | VNode[]): VNode =>
      !vnodes || typeof vnodes === 'string' || vnodes.length > 1
        ? h('div', vnodes)
        : vnodes[0]

    if (!this.user) {
      return maybeWrap(
        this.$scopedSlots.default
          ? (this.$scopedSlots.default({ authenticating: true }) as
              | string
              | VNode[])
          : 'Authenticating'
      )
    }

    if (this.url === '/callback') {
      return maybeWrap(
        this.$scopedSlots.default
          ? (this.$scopedSlots.default({ redirecting: true }) as
              | string
              | VNode[])
          : 'Redirecting'
      )
    }

    return maybeWrap(
      this.$scopedSlots.default
        ? (this.$scopedSlots.default({}) as string | VNode[])
        : 'Error'
    )
  }
})
