
import ChapterLookup from 'shared/components/ChapterLookup';
import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import SchoolLookup from 'shared/components/SchoolLookup';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import * as RadioGroup from 'shared/radio-group';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import { ServerSideFilterOption } from 'shared/components/Filters/FilterOption';
import UISelect from 'shared/ui/forms/FancySelect';
import { getGrade } from 'shared/util';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';
import identity from 'lodash/identity';
import range from 'lodash/range';
import uniqBy from 'lodash/uniqBy';
import teenInterests from 'shared/teenInterests';
import UIButton from 'shared/ui/buttons/Button.vue';
import fullheight from 'shared/directives/fullheight';
import UISwitch from 'shared/ui/forms/Switch.vue';
import { GetFilterOptionsQuery, SortType } from 'shared/generated/graphql-types';

library.add(faSortAlphaDown,faSortAlphaUp)
interface Column {
	columnName: string,
	propertyName: string
}
@Component({
	name: "FiltersPanel",
	components: {
		ChapterLookup,
		SchoolLookup,
		UIRadio,
		UISwitch,
		UICheckbox,
		UIToggle,
		ScrollablePanel,
		UISelect,
		UIButton,
		...RadioGroup
	},
	 directives: {
      fullheight
    },
	methods: {
		getGrade
	}
})
export default class extends Vue {
	@Prop() filterOptions!: GetFilterOptionsQuery | undefined;
	@Prop() loading!: boolean;
	@Prop() activeFilters!: any;

	filters: { [key: string]: ServerSideFilterOption[] } = {
		...this.activeFilters.filters
	}
	ascending: boolean = this.activeFilters.sortBy.ascending;
	sortType = SortType
	filter: SortType = this.activeFilters.sortBy.name
	showAlumni: boolean = this.activeFilters.isAlumni
  interestsOptions = teenInterests;
  selectedInterests: string[] = this.activeFilters.interests || [];

	get localActivefilters () {
		return { filters: omitBy(pickBy(this.filters, identity), (x) => isArray(x) && !x.length), sortBy: { name: this.filter, ascending: this.ascending }, isAlumni: this.showAlumni, interests: this.selectedInterests  }
	}
	get genderOptions (): ServerSideFilterOption[] {
		return [
			{ key: '0', displayValue: 'Male' },
			{ key: '1', displayValue: 'Female' }
		]
  }

	get chapterOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.chapterOptions || [])
			.filter(co => co.key !== null && co.displayValue !== null)
			.map(co => ({
				key: co.key!,
				displayValue: co.displayValue!
			})), ['displayValue']), 'key');
	}
	get mediaConsentOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.mediaConsentOptions || [])
			.filter(co => co.key !== null && co.displayValue !== null)
			.map(co => ({
				key: co.key!,
				displayValue: co.displayValue!
			})), ['displayValue']), 'key');
	}
	get parentMediaConsentOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.parentMediaConsentOptions || [])
			.filter(co => co.key !== null && co.displayValue !== null)
			.map(co => ({
				key: co.key!,
				displayValue: co.displayValue!
			})), ['displayValue']), 'key');
	}

	get gradeOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.gradeOptions || [])
			.filter(go => go.key !== null && go.key.length === 4)
			.map(go => getGrade(+(go.key!)))
			.filter(go => go.grade !== null && go.grade.length > 0 )
			.map(go => ({
				key: go.year ? String(go.year) : '',
				displayValue: go.grade
			})), ['displayValue'], ['desc']), 'key');
	}

	get schoolOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.schoolOptions || [])
			.filter(so => so.key !== null && so.displayValue !== null)
			.map(so => ({
				key: so.key!,
				displayValue: so.displayValue!
			})), ['displayValue']), 'key');
	}

	get schoolTypeOptions(): ServerSideFilterOption[] {
		return uniqBy((this.filterOptions?.schoolTypeOptions || [])
			.filter(so => so.key !== null && so.displayValue !== null)
			.map(so => ({
				key: so.key!,
				displayValue: so.displayValue!
			})), 'key');
	}

  get orderedGradutationOptions() {
      const grades: number[] = this.gradeOptions.map(x => +x.key)
      const date = new Date();
      const schoolYear = date.getMonth() > 5 ? date.getFullYear() + 1 : date.getFullYear();
      const highSchoolRange = range(schoolYear, schoolYear + 4).reverse()
      const highSchoolFiltered = grades.filter(x => x <= highSchoolRange[0] && x >= highSchoolRange[highSchoolRange.length - 1])
      const elementaryRange = range(schoolYear + 4, schoolYear + 13).reverse()
      const elementaryFiltered = grades.filter(x => x <= elementaryRange[0] && x >= elementaryRange[elementaryRange.length - 1])
      const alumniRange = range(schoolYear - grades[grades.length - 1], schoolYear).reverse()
      const alumniFiltered = grades.filter(x => x <= alumniRange[0] && x >= alumniRange[alumniRange.length - 1])
      return uniqBy(highSchoolFiltered.concat(elementaryFiltered).concat(alumniFiltered).map(key => ({
          key: String(key),
          displayValue: getGrade(key).grade
      })), 'key');
  }
  clearFilters () {
    this.filters = {
      Gender: [],
		  School: [],
		  Grade: [],
		  Chapter: [],
      SchoolType: []
    }
    this.selectedInterests = [];
    this.showAlumni = false;
  }
	@Watch ('filters', { deep: true })
	filtersWatcher (filters: any) {
		this.showAlumni = !!(filters.Grade && filters.Grade.length > 0);
		this.$emit('filters', this.localActivefilters)
	}
	@Watch ('ascending')
	ascendingWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
	@Watch ('filter')
	filterWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
	@Watch ('showAlumni')
	showAlumniWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
	@Watch ('selectedInterests')
	interestsWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
}
