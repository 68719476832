

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import SeriesLookup from 'shared/components/SeriesLookup';

@Component({
  components: {
    SeriesLookup
  }
})
export default class seriesIdPicker extends Vue {
  @Prop() value!: number;
  @Prop() season!: string;
}
