import QuickAddParent from './QuickAddParent.vue';
import { Props } from './types';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { BaseFamilyFragment, BaseFamilyFragmentDoc, Gender, useAddParentMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';

export const addParentEnhancer = wrapComponent<Props, Pick<Props, 'addParent'>>(() => {
  const { mutate } = useAddParentMutation()

  return computed(() => ({
    addParent: (input) => mutate({ parent: input }, {
      update(client, { data: updateData }) {
        if (input.familyId) {
          const familyData = client.readFragment<BaseFamilyFragment>({
            id: `${input.familyId}Family`,
            fragment: BaseFamilyFragmentDoc,
            fragmentName: 'BaseFamily'
          });

          if (familyData && updateData?.addParent) {
            // Don't overwrite
            const data = {
              ...familyData,
              Mother: input.gender === Gender.Female ? updateData.addParent : familyData.Mother,
              Father: input.gender === Gender.Male ? updateData.addParent : familyData.Father,
            }

            client.writeFragment<BaseFamilyFragment>({
              id: `${input.familyId}Family`,
              fragment: BaseFamilyFragmentDoc,
              fragmentName: 'BaseFamily',
              data
            });
          }
        }
      }
    }),
  }))
})

export default compose(
  addParentEnhancer
)(QuickAddParent);
