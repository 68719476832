import Vue, { CreateElement } from 'vue';
import { Filters, BusStatus, ScholarshipStatus, WaiverStatus } from '../types';
import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';
import { getAge } from 'shared/util';
import isNull from 'lodash/isNull';
import filter from 'lodash/filter';
import { getParents } from 'shared/util/families';
import { EventWithRegistrationsQuery, SortType, StatusType } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = EventWithRegistrationsQuery['event']
type Registration = ArrayElement<Event['Registrations']>

interface Computed {
  filteredRegistrations: Registration[];
}
interface Props {
  query: string;
  registrations: Registration[];
  filters: Filters;
  event: Event;
  sortBy: SortType | null;
  sortDescending: boolean;
}

type RegistrationPredicate = (r: Registration) => boolean;
type RegistrationFilter<T> = (value: T, e: Event) => RegistrationPredicate;
const getLiabilityConsentProp = (teen: Registration['Teen']) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()

  const isInHighSchool = currentMonth < 7
    ? teen.graduationYear && teen.graduationYear >= currentYear
    : teen.graduationYear && teen.graduationYear >= currentYear + 1;
  const teenIsOver18 = teen.birthDate ? getAge(teen.birthDate) >= 18 : false;
  const condition = teen.graduationYear ? !isInHighSchool : teenIsOver18

  if (teenIsOver18 && teen.Person.liabilitySigned) {
    return true
  } else if (teenIsOver18 && isInHighSchool && !teen.Person.liabilitySigned) {
    return teen.Person.liabilityGuardianSigned
  } else {
    return condition ? teen.Person.liabilitySigned : teen.Person.liabilityGuardianSigned
  }
};

const getConsentProp = (
  teen: Registration['Teen'],
  field: 'mediaConsentSigned' | 'dataConsentSigned'
) => {
  const teenIsOver18 = teen.birthDate ? getAge(teen.birthDate) >= 18 : false;
  if (teenIsOver18) {
    return teen.Person[field];
  } else {
    const { father, mother } = getParents(teen.Person);
    const parentsConsents = [father, mother].map(p => p && p[field]);
    const consentSignedIsTrue = filter(parentsConsents, Boolean).length;
    const consentSignedIsFalse = filter(
      parentsConsents,
      (consentSigned) => consentSigned === false
    ).length;

    if (consentSignedIsTrue) {
      return true;
    } else if (consentSignedIsFalse) {
      return false;
    } else {
      return null;
    }
  }
};

export const predicates: { [K in keyof Filters]: RegistrationFilter<Filters[K]> } = {
  busStatus: (value) => (r) => {
    const busStatus: { [key: string]: string } = Object.keys(BusStatus).reduce(
      (collection, key) => ({ ...collection, [key]: toLower(key) }),
      {}
    );
    switch (toLower(value || '')) {
      case busStatus.AssignedOneWay:
        return (r.FromBus === null && r.ToBus !== null) || (r.FromBus !== null && r.ToBus === null);
      case busStatus.AssignedTwoWay:
        return r.FromBus !== null && r.ToBus !== null;
      case busStatus.Unassigned:
        return r.FromBus === null && r.ToBus === null;
      default:
        return false;
    }
  },
  chapter: (value) => (r) => r.Teen.Chapter ? r.Teen.Chapter.chapterId === value : false,
  gender: (value) => (r) => r.Teen.Person.gender === value,
  graduationYear: (value) => (r) => {
    if (Array.isArray(value)) {
      return value.includes(r.Teen.graduationYear);
    } else {
      return r.Teen.graduationYear === value;
    }
  },
  mediaConsent: (mediaConsentSigned) => (r) => {
    switch (mediaConsentSigned) {
      case 0:
        return getConsentProp(r.Teen, 'mediaConsentSigned') === true;
      case 1:
        return isNull(getConsentProp(r.Teen, 'mediaConsentSigned'));
      case 2:
        return getConsentProp(r.Teen, 'mediaConsentSigned') === false;
      default:
        return true;
    }
  },
  dataConsent: (dataConsentSigned) => (r) => {
    switch (dataConsentSigned) {
      case 0:
        return getConsentProp(r.Teen, 'dataConsentSigned') === true;
      case 1:
        return isNull(getConsentProp(r.Teen, 'dataConsentSigned'));
      case 2:
        return getConsentProp(r.Teen, 'dataConsentSigned') === false;
      default:
        return true;
    }
  },
  liabilityConsent: (liabilitySigned) => (r) => {
    switch (liabilitySigned) {
      case 0:
        return !!getLiabilityConsentProp(r.Teen);
      case 1:
        return !!!getLiabilityConsentProp(r.Teen);
      default:
        return true;
    }
  },
  eventWaiver: (value) => (r) => {
    switch (value) {
      case 0:
        return !!r.ncsyWaiverReceived;
      case 1:
        return !!!r.ncsyWaiverReceived;
      default:
        return true;
    }
  },
  paymentStatus: (value) => (r) => {
    if (toLower(value as string) === toLower('NoPaymentsMade')) {
      return r.Payments.length === 0
    }
    return r.paymentStatuses.map(toLower).includes(toLower(value || ''))
  },
  registrationStatus: (value) => (r) => toLower(r.status) === toLower(value || ''),
  scholarship: (value) => (r) => {
    const scholarshipStatus: { [key: string]: string } = Object.keys(ScholarshipStatus).reduce(
      (collection, key) => ({ ...collection, [key]: toLower(key) }),
      {}
    );
    switch (toLower(value || '')) {
      case scholarshipStatus.Approved:
        return r.scholarshipGrant !== null && r.scholarshipGrant > 0;
      case scholarshipStatus.Denied:
        return (
          r.scholarshipRequest !== null && r.scholarshipRequest > 0 && r.scholarshipGrant === 0
        );
      case scholarshipStatus.Pending:
        return (
          r.scholarshipRequest !== null && r.scholarshipRequest > 0 && r.scholarshipGrant === null
        );
      default:
        return false;
    }
  },
  school: (value) => (r) => r.Teen.School ? r.Teen.School.schoolID === value : false,
  schoolType: (value) => (r) => r.Teen.School ? r.Teen.School.secondaryType === value : false,
  ticketType: (value) => (r) => r.EventTicket.EventTicketID === value,
  waiverStatus: (value, event) => (r) => {
    const waiverStatus: { [key: string]: string } = Object.keys(WaiverStatus).reduce(
      (collection, key) => ({ ...collection, [key]: toLower(key) }),
      {}
    );
    switch (toLower(value || '')) {
      case waiverStatus.SignedAll:
        return (
          event.waivers.every((w) => r.submittedWaivers.map((r) => r.id).includes(w.id)) &&
          (!event.ncsyWaiverRequired || r.ncsyWaiverReceived)
        );
      case waiverStatus.SignedNone:
        return (
          (event.ncsyWaiverRequired && !r.ncsyWaiverReceived) ||
          r.submittedWaivers.length !== event.waivers.length
        );
      default:
        return false;
    }
  },
  registarAccountType: (value) => (r) =>
    value ? toLower(r.registarAccountType || undefined) === toLower(value) : false,
  parentalApprovalGiven: (value) => (r) => r.parentalApprovalGiven === !!value
};

const sortByFunctions: { [k: string]: (r: Registration) => any } = {
  [SortType.Balance]: (r) => r.balance,
  [SortType.Chapter]: (r) => r.Teen.Chapter && r.Teen.Chapter.chapterName,
  [SortType.GraduationYear]: (r) => r.Teen.graduationYear,
  [SortType.Id]: (r) => r.registrationID,
  [SortType.Name]: (r) => r.Teen.Person.lastName,
  [SortType.RegistrationDate]: (r) => r.registrationDate
};

export default Vue.extend<{}, {}, Computed, Props>({
  name: 'RegistrationsSearchableList',
  props: {
    query: String,
    registrations: {},
    filters: {},
    event: {},
    sortBy: {},
    sortDescending: {}
  },
  computed: {
    filteredRegistrations() {
      const filters: Array<RegistrationPredicate> = [];

      (Object.entries(this.filters) as [keyof Filters, any][]).map(([key, value]) => {
        if (value !== null) {
          const predicate = predicates[key] as RegistrationFilter<any>;
          filters.push(predicate(value, this.event));
        }
      });

      if (this.query) {
        filters.push(
          (r) =>
            (r.Teen.Person
              ? `${toLower(r.Teen.Person.firstName || '')} ${toLower(
                  r.Teen.Person.lastName || ''
                )}`.indexOf(toLower(this.query)) >= 0
              : false) ||
            r.Teen.Person.EmailAddresses.some((x) => toLower(x.email) === toLower(this.query))
        );
      }

      const filteredRegistrations = filters.reduce(
        (registrations, filter) => registrations.filter(filter),
        this.registrations
      );

      if (this.sortBy) {
        const func = sortByFunctions[this.sortBy];

        const registrations = sortBy<Registration>(filteredRegistrations, func);

        if (this.sortDescending) registrations.reverse();

        return registrations;
      }
      if (this.filters.paymentStatus) {
        return filteredRegistrations;
      } else {
        return filteredRegistrations.filter(
          (r) => toLower(r.status) !== toLower(StatusType.Cancelled)
        );
      }
    }
  },
  render(h: CreateElement) {
    const { filteredRegistrations } = this;
    const vNode = this.$scopedSlots.default && this.$scopedSlots.default({
      filteredRegistrations
    });

    return h('div', vNode);
  }
});
