import Vue, { VNode } from 'vue';
import get from 'lodash/get';
import without from 'lodash/without';
import injectProps from './injectProps';

const CheckboxArray = Vue.extend({
    name: 'CheckboxArray',
    props: {
      value: {
        type: Array,
        default: () => []
      }
    },
    render (h) {
      const vNodes: VNode[] = this.$slots.default || [];

      return h('div', vNodes.map(vNode => injectProps(vNode, {
        toggle: () => {
          const value = get(vNode, 'componentOptions.propsData.value');

          if ((this.value || []).includes(value)) {
            this.$emit('input', without(this.value, value));
          }
          else {
            this.$emit('input', [...this.value || [], value]);
          }
        },
        checked: (this.value || []).includes(get(vNode, 'componentOptions.propsData.value'))
      })));
    }
});

const CheckboxArrayItem = Vue.extend({
    name: 'CheckboxArrayItem',
    props: ['toggle', 'checked', 'value', 'label', 'name'],
    render (h) {
      const maybeWrap = (vnodes: string | VNode[]) =>
      !vnodes || typeof vnodes === 'string' || vnodes.length > 1 ?
        h('div', vnodes) :
          vnodes[0];

      const scopedSlot = this.$scopedSlots.default;

      const $input = h('input', {
        attrs: { type: 'checkbox', name: this.name },
        domProps: { checked: this.selected },
        on: {
          click: this.toggle
        }
      });

      if (!scopedSlot) {
        return h('label', [$input, this.label]);
      }

      const props = {
        checked: this.checked,
        name: this.name,
        value: this.checked
      };

      const listeners = {
        click: this.toggle
      };

      const $vnode = scopedSlot({ props, listeners, label: this.label });

      return maybeWrap($vnode as string | VNode[]);
    }
});

export { CheckboxArray, CheckboxArrayItem };
