<template>
	<div :class="boxType" :style="{height: gridHeight, gridTemplateColumns: columns}">
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: 'triPanelGrid',
  props: {
	  bottomOffset: String,
	  columns: String,
		boxType: {
			type: String,
			default: 'flex'
		}
  },
  computed: {
	gridHeight () {
	  if (this.bottomOffset) {
		  return `calc(100vh - ${this.bottomOffset}px)`;
	  }
	}
  }
};
</script>

<style scoped lang="scss">
.grid {
	display: grid;
	grid-template-columns: 300px auto auto;
	height: 100vh;

	> div {
		overflow: hidden;
	}

}
.flex {
	display: flex;
	height: 100%;
	position: relative;

	> div {
		flex: 1;
	}
}
</style>

