
import Vue from 'vue';
import Notification from './Notification.vue';

export default Vue.extend({
	name: 'NotificationsContainer',
	props: ['notifications', 'removeNotification'],
	components: {
		Notification
	}
});
