import Single from './Single.vue';
import { withApollo } from 'shared/components/graphql';
import { Props } from './types';
import { compose } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetJfeSingleParentQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

const getParentEnhancer = wrapComponent<{ parentId: number }, Pick<Props, 'parent' | 'loading'>>(
  (props) => {
    const { loading, result } = useGetJfeSingleParentQuery(
      computed(() => ({ parentId: props.parentId }))
    );

    return computed(() => ({
      loading: loading.value,
      parent: result.value?.jfeParent
    }));
  }
);

export default compose(getParentEnhancer, withApollo)(Single);
