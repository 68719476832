import { render, staticRenderFns } from "./Shabbaton.vue?vue&type=template&id=2ceb537d&scoped=true&"
import script from "./Shabbaton.vue?vue&type=script&lang=ts&"
export * from "./Shabbaton.vue?vue&type=script&lang=ts&"
import style0 from "./Shabbaton.vue?vue&type=style&index=0&id=2ceb537d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ceb537d",
  null
  
)

export default component.exports