
import Vue from 'vue';
import InteractionDisplay from './components/Interaction/Interaction.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { Props } from './types';
import InteractionForm from 'shared/components/InteractionForm';
import Loading from 'shared/components/Loading.vue';

export default Vue.extend<{}, {}, {}, Props>({
  name: 'Interactions',
  props: {
    addingNewInteraction: {},
    deleteInteraction: {},
    interactions: {},
    isLoading: {},
    personId: {}
  },
  components: {
    InteractionDisplay,
    Modal,
    Loading,
    InteractionForm
  }
})
