
import UISelect from 'shared/ui/forms/FancySelect';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { nonSelectableEventSubTypes } from './util';
import isUndefined from 'lodash/isUndefined';
import { GetEventSubTypesQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type EventSubType = ArrayElement<GetEventSubTypesQuery['eventSubTypes']>

@Component({
	components: { UISelect }
})
export default class EventSubTypeLookup extends Vue {
    @Prop() onlySelectable!: boolean;
    @Prop({default: true}) clearable!: boolean;
    @Prop() filterToTypes!: string[];
		@Prop() eventSubTypes!:  EventSubType[];
		@Prop() loading!:  boolean;
		@Prop({type: [Number, Object, Array]}) value!: number | EventSubType | EventSubType[];
		@Prop() multiple!: boolean;

    get selectableOptions() {
			return (this.eventSubTypes || []).filter(e => !nonSelectableEventSubTypes.includes(e.type || ''));
    }

    get options () {
      if (this.onlySelectable) {
        return this.selectableOptions;
      }
      if(this.filterToTypes && this.filterToTypes.length){
        return this.baseOptions.filter(e => this.filterToTypes.includes(e.type || ''));
      }
      return this.baseOptions;
    }

    get baseOptions() {
      return this.eventSubTypes || []
    }

		get valueAsObject () {
			if (typeof this.value === 'object') return this.value;

			return (this.eventSubTypes || []).find(x => x.eventSubTypeId === this.value);
		}
		 get valueAsArray (): EventSubType[] {
            if (!this.value) return []

            const value: Array<number | EventSubType> = Array.isArray(this.value) ? this.value : [this.value]

            return value.map(eventSubType => typeof eventSubType === 'object'
                ? eventSubType
                : this.options.find((x: EventSubType) => x.eventSubTypeId === eventSubType)!
            ).filter(v => v)
        }
     getOptionLabel ({ description, programLevel }: EventSubType) {
       if (isUndefined(programLevel)) return description
       return `${description} (${programLevel})`
     }
    getOptionKey (option: EventSubType) {
      if (typeof option === 'object' && option.eventSubTypeId) {
        return option.eventSubTypeId
      } else {
        try {
          return JSON.stringify(option)
        } catch(e) {
          console.warn(
            `[vue-select warn]: Could not stringify option ` +
            `to generate unique key. Please provide 'getOptionKey' prop ` +
            `to return a unique key for each option.\n` +
            'https://vue-select.org/api/props.html#getoptionkey'
          )
          return null
        }
      }
    }
};
