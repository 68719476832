import { getFieldOfParent, ParentViewModel, toFamilyViewModels } from 'shared/util/families';
import { EmailAddress, Phone, TeenViewModel } from './types';
import {
  getCurrentEmail,
  getFullName,
  getCurrentCellNumber,
  formatAddressToString,
  getCurrentLandlineNumber,
  getCurrentAddress,
  isJsonString,
  getAge,
  splitName
} from 'shared/util';
import { ApolloClient } from '@apollo/client';
import {
  EditTeenMutationVariables,
  ExtendedTeenSideBarFragmentDoc,
  FamilyParentFragmentFragment,
  GetTeenProfileQuery,
  LineItemType,
  PhoneType,
  School,
  UpdateTeenField
} from 'shared/generated/graphql-types';
import { ChapterSummary } from 'src/pages/Root/pages/Chapters/types';
import { ArrayElement } from 'shared/util/types';

type Teen = GetTeenProfileQuery['singleTeen'];
type Address = ArrayElement<GetTeenProfileQuery['singleTeen']['Person']['Addresses']>;
type ParentEmail = ArrayElement<FamilyParentFragmentFragment['EmailAddresses']>;
type ParentPhone = ArrayElement<FamilyParentFragmentFragment['Phones']>;

export function showParentNotificationAction(teen: TeenViewModel): boolean {
  if (!teen || !teen.primaryFamily) return false;
  const parents = [teen.primaryFamily.father, teen.primaryFamily.mother].filter(
    (p): p is ParentViewModel => p !== null && p !== undefined
  );
  if (!parents.length) {
    return false;
  }
  const birthDate = teen.birthDate;
  const parentsEmailExist = parents.filter((p) => p.email);
  const signedMedia = !!parents.find((parent) => !!parent.mediaConsentSigned);
  const signedData = !!parents.find((parent) => !!parent.dataConsentSigned);

  return (
    (!birthDate || getAge(birthDate) < 18) &&
    parentsEmailExist &&
    (!signedMedia || !signedData || !teen.liabilityGuardianSigned)
  );
}

export const toCompactTeen = (teen: Teen): TeenViewModel => {
  const currentAddressObject = getCurrentAddress((teen.Person && teen.Person.Addresses) || []);

  const families = toFamilyViewModels(teen.Person.ChildOf || [], teen.Person.primaryFamilyId);
  const primaryFamily = families.find((f) => f.primary) || families[0];
  const father = primaryFamily && primaryFamily.father;
  const mother = primaryFamily && primaryFamily.mother;

  return {
    address: currentAddressObject,
    country: currentAddressObject.country || '',
    landline: getCurrentLandlineNumber(teen.Person.Phones || [])
      ? getCurrentLandlineNumber(teen.Person.Phones)!.phoneNumber
      : null,
    cellphone: getCurrentCellNumber(teen.Person.Phones || [])
      ? getCurrentCellNumber(teen.Person.Phones)!.phoneNumber
      : null,
    email: getCurrentEmail(teen.Person.EmailAddresses || [])
      ? getCurrentEmail(teen.Person.EmailAddresses)!.email
      : null,
    emails: teen.Person.EmailAddresses,
    chapter: teen.Chapter ? teen.Chapter : null,
    chapterName: teen.Chapter ? teen.Chapter.chapterName : null,
    school: teen.School ? teen.School : null,
    middleSchool: teen.MiddleSchool ? teen.MiddleSchool : null,
    fullName: getFullName(teen.Person.firstName, teen.Person.lastName),
    birthDate: teen.birthDate || '',
    isOver18: teen.birthDate ? getAge(teen.birthDate) >= 18 : null,
    schoolName: teen.School ? teen.School.name : null,
    firstName: teen.Person.firstName || '',
    lastName: teen.Person.lastName || '',
    personId: teen.personID,
    gender: teen.Person.gender,
    regionName: teen.Region.regionName,
    regionId: teen.Region.regionId,
    graduationYear: teen.graduationYear,
    fatherName: father ? father.name : '',
    motherName: mother ? mother.name : '',
    motherHomePhone: mother ? mother.homePhone : null,
    motherCellPhone: mother ? mother.cellPhone : null,
    fatherHomePhone: father ? father.homePhone : null,
    fatherCellPhone: father ? father.cellPhone : null,
    fatherEmail: father ? father.email : null,
    motherEmail: mother ? mother.email : null,
    motherId: mother ? mother.parentId : null,
    fatherId: father ? father.parentId : null,
    notes: teen.Person.Notes,
    medicines: teen.medicines,
    formattedEmail: (
      getCurrentEmail(teen.Person && teen.Person.EmailAddresses) || {
        email: ''
      }
    ).email,
    formattedAddress: formatAddressToString(currentAddressObject),
    thumbnail: teen.thumbnail,
    currencySymbol: teen.Region.currencySymbol,
    mother,
    father,
    families,
    primaryFamily,
    outstandingEvents:
      (teen &&
        teen.outstandingEvents.length &&
        teen.outstandingEvents.map((x) => ({
          eventId: x && x.Event && x.Event.eventId,
          eventName: x && x.Event && x.Event.eventName,
          balance:
            x!.LineItems &&
            x!.LineItems.length &&
            (
              x!.LineItems.find(
                (l) => l.eventRegistrationID === x.registrationID && l.type === LineItemType.Balance
              ) || { amount: 0 }
            ).amount,
          registrationId: x!.registrationID
        }))) ||
      [],
    mediaConsentSigned: teen.Person.mediaConsentSigned,
    dataConsentSigned: teen.Person.dataConsentSigned,
    dataOptOut: teen.Person.dataOptOut,
    liabilitySigned: teen.Person.liabilitySigned,
    liabilityGuardianSigned: teen.Person.liabilityGuardianSigned,
    hasPhysicalLiabilityFile: teen.Person.hasPhysicalLiabilityFile,
    parentsSignedMediaConsent:
      (mother && mother.mediaConsentSigned) || (father && father.mediaConsentSigned),
    parentsDataConsentSigned:
      (mother && mother.dataConsentSigned) || (father && father.dataConsentSigned),
    original: teen,
    interactions: teen.Interactions.slice().sort((a, b) =>
      new Date(a.date) > new Date(b.date) ? -1 : 1
    ),
    lists: teen.Person.Lists || []
  };
};

function insert<T extends Array<any>>(value: T[0], array: T, index: number) {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}
function updateSelection<TFragment>(
  value: string | null | undefined,
  teen: Teen,
  client: ApolloClient<any>,
  field: keyof Teen,
  fragmentName: string,
  entity: string
) {
  if (!value) {
    return {
      ...teen,
      [field]: null
    };
  }

  const item = client.readFragment<TFragment>({
    fragment: ExtendedTeenSideBarFragmentDoc,
    fragmentName,
    id: `${value}${entity}`
  });

  return {
    ...teen,
    [field]: item
  };
}

function getNestedFieldUpdateValue<O>(
  update: EditTeenMutationVariables,
  action: (t: string) => O | string = (i) => i
) {
  if (!action) {
    action = (i) => i;
  }
  if (isJsonString(update.value!)) {
    const firstLevel = JSON.parse(update.value!);
    return isJsonString(firstLevel.value)
      ? JSON.parse(firstLevel.value!)
      : action(firstLevel.value!);
  } else {
    return action(update.value!);
  }
}

export function calcTeenUpdate(
  teen: Teen,
  update: EditTeenMutationVariables,
  client: ApolloClient<any>
): Teen {
  const vm = toCompactTeen(teen);

  switch (update.fieldName) {
    case UpdateTeenField.Address: {
      const currentAddressObject = getCurrentAddress(teen.Person.Addresses);

      const index = teen.Person.Addresses.findIndex((x) => x.id === currentAddressObject.id);
      const value: Address = JSON.parse(update.value!);

      (value.__typename = 'Address'), (value.dateCreated = new Date().toISOString());
      value.dateUpdated = new Date().toISOString();
      value.id = -1;
      value.primary = true;
      value.verified = false;

      if (index === -1) {
        return {
          ...teen,
          Person: {
            ...teen.Person,
            Addresses: [...teen.Person.Addresses, value]
          }
        };
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          Addresses: insert(
            {
              ...currentAddressObject,
              ...value
            },
            teen.Person.Addresses,
            index
          )
        }
      };
    }
    case UpdateTeenField.CellNumber:
      const cellPhone = getCurrentCellNumber(teen.Person.Phones);

      const newPhone: Teen = {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: [
            {
              __typename: 'Phone',
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              description: null,
              doNotCall: null,
              id: -1,
              invalid: false,
              phoneNumber: update.value!,
              primary: true,
              type: PhoneType.Mobile
            }
          ]
        }
      };

      if (!cellPhone) {
        return newPhone;
      }

      const index = teen.Person.Phones.findIndex((x) => x.id === cellPhone.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: insert(
            {
              ...cellPhone,
              phoneNumber: update.value!
            },
            teen.Person.Phones,
            index
          )
        }
      };
    case UpdateTeenField.HomeNumber: {
      const homeNumber = getCurrentLandlineNumber(teen.Person.Phones);

      const newPhone: Teen = {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: [
            {
              __typename: 'Phone',
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              description: null,
              doNotCall: null,
              id: -1,
              invalid: false,
              phoneNumber: update.value!,
              primary: true,
              type: PhoneType.Landline
            }
          ]
        }
      };

      if (!homeNumber) {
        return newPhone;
      }

      const index = teen.Person.Phones.findIndex((x) => x.id === homeNumber.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: insert(
            {
              ...homeNumber,
              phoneNumber: update.value!
            },
            teen.Person.Phones,
            index
          )
        }
      };
    }
    case UpdateTeenField.HighSchoolId:
      return updateSelection<School>(update.value!, teen, client, 'School', 'School', 'School');
    case UpdateTeenField.ChapterId:
      return updateSelection<ChapterSummary>(
        update.value!,
        teen,
        client,
        'Chapter',
        'ChapterSummary',
        'Chapter'
      );
    case UpdateTeenField.Email: {
      const currentEmail = toCompactTeen(teen).email;

      const newEmail: Teen = {
        ...teen,
        Person: {
          ...teen.Person,
          EmailAddresses: [
            {
              __typename: 'EmailAddress',
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              email: update.value!,
              id: -1,
              invalid: false,
              primary: true
            }
          ]
        }
      };

      if (!currentEmail) {
        return newEmail;
      }

      const email = teen.Person.EmailAddresses.find((x) => x.email === currentEmail)!;
      const index = teen.Person.EmailAddresses.indexOf(email);

      if (index === -1) {
        return newEmail;
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          EmailAddresses: insert(
            {
              ...email,
              email: update.value!
            },
            teen.Person.EmailAddresses,
            index
          )
        }
      };
    }
    // Taking a small risk with the parent fields, and not doing null checks
    // since the DOM should not be offering the option to run these commands without a primary family existing
    case UpdateTeenField.MotherCellPhone:
    case UpdateTeenField.MotherHomePhone: {
      const families = (teen.Person.ChildOf || []).slice();
      const primaryFamilyIndex = families!.findIndex((f) => f.id === vm.primaryFamily!.id);
      const motherPhones = getFieldOfParent<ParentPhone[]>(
        families,
        primaryFamilyIndex,
        'Mother',
        'Phones',
        []
      );
      const phoneType: PhoneType =
        UpdateTeenField.MotherHomePhone === update.fieldName
          ? PhoneType.Landline
          : PhoneType.Mobile;

      const phoneNumber =
        phoneType === PhoneType.Landline
          ? getCurrentLandlineNumber(motherPhones)
          : getCurrentCellNumber(motherPhones);
      if (!phoneNumber) {
        return teen;
      }

      let primaryFamily = { ...families[primaryFamilyIndex] };
      let phones: Phone[] = primaryFamily.Mother!.Phones.slice();
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = motherPhones.findIndex((x) => x.id === phoneNumber!.id);
      if (index === -1) {
        phones.push({
          __typename: 'Phone',
          dateCreated: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          description: null,
          doNotCall: null,
          id: -1,
          invalid: false,
          phoneNumber: parsedValue,
          primary: true,
          type: PhoneType[phoneType]
        });
      } else {
        phones = insert({ ...phoneNumber, phoneNumber: parsedValue }, phones, index);
      }

      families.splice(primaryFamilyIndex, 1, {
        ...primaryFamily,
        Mother: {
          ...primaryFamily.Mother!,
          Phones: phones
        }
      });

      return {
        ...teen,
        Person: {
          ...teen.Person,
          ChildOf: families
        }
      };
    }
    case UpdateTeenField.FatherCellPhone:
    case UpdateTeenField.FatherHomePhone: {
      const families = (teen.Person.ChildOf || []).slice();
      const primaryFamilyIndex = families!.findIndex((f) => f.id === vm.primaryFamily!.id);
      const fatherPhones = getFieldOfParent<ParentPhone[]>(
        families,
        primaryFamilyIndex,
        'Father',
        'Phones',
        []
      );
      const phoneType: PhoneType =
        UpdateTeenField.FatherHomePhone === update.fieldName
          ? PhoneType.Landline
          : PhoneType.Mobile;

      const phoneNumber =
        phoneType === PhoneType.Landline
          ? getCurrentLandlineNumber(fatherPhones)
          : getCurrentCellNumber(fatherPhones);
      if (!phoneNumber) {
        return teen;
      }

      let primaryFamily = { ...families[primaryFamilyIndex] };
      let phones: Phone[] = primaryFamily.Father!.Phones.slice();
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = fatherPhones.findIndex((x) => x.id === phoneNumber!.id);
      if (index === -1) {
        phones.push({
          __typename: 'Phone',
          dateCreated: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          description: null,
          doNotCall: null,
          id: -1,
          invalid: false,
          phoneNumber: parsedValue,
          primary: true,
          type: PhoneType[phoneType]
        });
      } else {
        phones = insert({ ...phoneNumber, phoneNumber: parsedValue }, phones, index);
      }

      families.splice(primaryFamilyIndex, 1, {
        ...primaryFamily,
        Father: {
          ...primaryFamily.Father!,
          Phones: phones
        }
      });

      return {
        ...teen,
        Person: {
          ...teen.Person,
          ChildOf: families
        }
      };
    }

    case UpdateTeenField.FatherEmail: {
      const families = (teen.Person.ChildOf || []).slice();
      const primaryFamilyIndex = families!.findIndex((f) => f.id === vm.primaryFamily!.id);
      const fatherEmailAddresses = getFieldOfParent<ParentEmail[]>(
        families,
        primaryFamilyIndex,
        'Father',
        'EmailAddresses',
        []
      );

      const currentEmail = getCurrentEmail(fatherEmailAddresses);
      if (!currentEmail) {
        return teen;
      }

      let primaryFamily = { ...families[primaryFamilyIndex] };
      let emails: EmailAddress[] = primaryFamily.Father!.EmailAddresses.slice();
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = fatherEmailAddresses.findIndex((x) => x.id === currentEmail.id);
      if (index === -1) {
        emails.push({
          __typename: 'EmailAddress',
          dateCreated: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          email: parsedValue,
          id: -1,
          invalid: false,
          primary: true
        });
      } else {
        emails = insert({ ...currentEmail, email: parsedValue }, emails, index);
      }

      families.splice(primaryFamilyIndex, 1, {
        ...primaryFamily,
        Father: {
          ...primaryFamily.Father!,
          EmailAddresses: emails
        }
      });

      return {
        ...teen,
        Person: {
          ...teen.Person,
          ChildOf: families
        }
      };
    }

    case UpdateTeenField.MotherEmail: {
      const families = (teen.Person.ChildOf || []).slice();
      const primaryFamilyIndex = families!.findIndex((f) => f.id === vm.primaryFamily!.id);
      const motherEmailAddresses = getFieldOfParent<ParentEmail[]>(
        families,
        primaryFamilyIndex,
        'Mother',
        'EmailAddresses',
        []
      );

      const currentEmail = getCurrentEmail(motherEmailAddresses);
      if (!currentEmail) {
        return teen;
      }

      let primaryFamily = { ...families[primaryFamilyIndex] };
      let emails: EmailAddress[] = primaryFamily.Mother!.EmailAddresses.slice();
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = motherEmailAddresses.findIndex((x) => x.id === currentEmail.id);
      if (index === -1) {
        emails.push({
          __typename: 'EmailAddress',
          dateCreated: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          email: parsedValue,
          id: -1,
          invalid: false,
          primary: true
        });
      } else {
        emails = insert({ ...currentEmail, email: parsedValue }, emails, index);
      }

      families.splice(primaryFamilyIndex, 1, {
        ...primaryFamily,
        Mother: {
          ...primaryFamily.Mother!,
          EmailAddresses: emails
        }
      });

      return {
        ...teen,
        Person: {
          ...teen.Person,
          ChildOf: families
        }
      };
    }

    case UpdateTeenField.MotherName: {
      const { firstName, lastName } = getNestedFieldUpdateValue(update, splitName);
      const families = (teen.Person.ChildOf || []).slice();
      const primaryFamilyIndex = families!.findIndex((f) => f.id === vm.primaryFamily!.id);
      const primaryFamily = {
        ...families[primaryFamilyIndex],
        Mother: {
          ...families[primaryFamilyIndex].Mother!,
          firstName,
          lastName
        }
      };

      families.splice(primaryFamilyIndex, 1, primaryFamily);

      return {
        ...teen,
        Person: {
          ...teen.Person,
          ChildOf: families
        }
      };
    }

    case UpdateTeenField.FatherName: {
      const { firstName, lastName } = getNestedFieldUpdateValue(update, splitName);
      const families = (teen.Person.ChildOf || []).slice();
      const primaryFamilyIndex = families!.findIndex((f) => f.id === vm.primaryFamily!.id);
      const primaryFamily = {
        ...families[primaryFamilyIndex],
        Father: {
          ...families[primaryFamilyIndex].Father!,
          firstName,
          lastName
        }
      };

      families.splice(primaryFamilyIndex, 1, primaryFamily);

      return {
        ...teen,
        Person: {
          ...teen.Person,
          ChildOf: families
        }
      };
    }
    default:
      return teen;
  }
}
