
import RegistrationsSearchableList from './RegistrationsSearchableList';
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import RegistrationInfo from './RegistrationInfo';
import TeenProfile from 'shared/components/TeenProfile';
import { b64toBlob, getAge } from 'shared/util';
import Vue from 'vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import FiltersPanel from './shared/FiltersPanel.vue';
import Loading from 'shared/components/Loading.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import AddRegistrationForm from './AddRegistrationForm';
import Avatar from 'shared/components/avatar';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import FileSaver from 'file-saver';
import { Route, RouterLink } from 'vue-component-router';
import { Filters } from './types';
import { Props as FilterPanelProps } from './shared/FilterOptions';
import { RecycleScroller } from 'vue-virtual-scroller';
import QueryString from 'query-string';
import camelCase from 'lodash/camelCase';
import omitBy from 'lodash/omitBy';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import isString from 'lodash/isString';
import toLower from 'lodash/toLower';
import { ApolloClient } from '@apollo/client';
import { formatDate } from 'shared/util';
import { getParentsArray } from 'shared/util/families';
import {
  BulkNotifyParentsMutationVariables,
  CancelRegistrationMutationVariables,
  EventWithRegistrationsQuery,
  MoveRegistrationToWaitListMutationVariables,
  PermissionEnum,
  ProduceInvoiceMutationVariables,
  PromoteRegistrationMutationVariables,
  ResendRegistrationEmailsMutationVariables,
  ResendWaiverEmailsMutationVariables,
  SortType,
  StatusType
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = EventWithRegistrationsQuery['event']
type Registration = ArrayElement<Event['Registrations']>

library.add(faIndent, faOutdent, faSlidersH, faPlusCircle, faFilter, faSearch);

interface Data {
  query: string;
  selectedRegistration: any;
  showFilters: boolean;
  isTeenProfileClosed: boolean;
  showAddRegistrationModal: boolean;
  checkedRegistrations: number[];
  cancelingRegistrations: boolean;
  movingToWaitList: boolean;
  promotingRegistration: boolean;
  resendingRegistrationEmails: boolean;
  resendingWaiverEmails: boolean;
  resendingConsentEmails: boolean;
  producingInvoices: boolean;
  editingTeenProfile: boolean;
  StatusType: typeof StatusType;
  filters: Filters & { [key: string]: any };
  sort: SortType | null;
  sortDescending: boolean;
}

interface Props {
  eventId: number;
  event: Event | undefined;
  addingNewTeen: boolean;
  selectedRegistrationId: string | undefined;
  cancelRegistration: (args: CancelRegistrationMutationVariables) => Promise<void>;
  moveToWaitList: (args: MoveRegistrationToWaitListMutationVariables) => Promise<void>;
  promoteRegistration: (args: PromoteRegistrationMutationVariables) => Promise<void>;
  resendRegistrationEmails: (registrationId: ResendRegistrationEmailsMutationVariables) => Promise<void>;
  resendWaiverEmails: (registrationId: ResendWaiverEmailsMutationVariables) => Promise<void>;
  bulkNotifyParents: (args: BulkNotifyParentsMutationVariables) => Promise<void>;
  produceInvoice: (registrationId: ProduceInvoiceMutationVariables) => Promise<string>;
  path: string;
  client: ApolloClient<any>;
  claims: PermissionEnum[];
  router: {
    history: { replace: (arg: any) => void };
    location: { pathname: string; search: string };
  };
}

interface Computed {
  computedFilters: Filters;
  queryStringFilters: string;
  parsedQueryStringFilters: any;
  toRecordRefund: boolean;
  showFilterPanel: boolean;
  checkedTeensForConsent: number[];
}

interface Methods {
  formatDate: (date: string, timezone: string, format: string) => string;
  setFilter: FilterPanelProps['setFilter'];
  setFilters: FilterPanelProps['setFilters'];
  toggleTeenProfile: () => void;
  selectAllHandler: (filteredRegistrations: Event['Registrations']) => void;
  cancelRegistrations: (suppressEmail: boolean) => Promise<void>;
  moveAllToWaitList: (suppressEmail: boolean) => Promise<void>;
  promoteAllRegistrations: (suppressEmail: boolean) => Promise<void>;
  resendRegistrationEmailsForAll: () => Promise<void>;
  resendWaiverEmailsForAll: () => Promise<void>;
  resendConsentEmailsForAll: () => Promise<void>;
  produceInvoices: () => Promise<void>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Registrations',
  components: {
    ActionBar,
    RegistrationsSearchableList,
    RegistrationInfo,
    TeenProfile,
    UIButton,
    UIInput,
    FiltersPanel,
    Loading,
    Modal,
    AddRegistrationForm,
    Avatar,
    PanelsSidebar,
    PanelsGrid,
    ScrollablePanel,
    BorderedList,
    Route,
    RouterLink,
    RecycleScroller
  },
  directives: {
    fullheight
  },
  data() {
    return {
      query: '',
      selectedRegistration: null,
      showFilters: false,
      isTeenProfileClosed: false,
      showAddRegistrationModal: false,
      checkedRegistrations: [],
      cancelingRegistrations: false,
      movingToWaitList: false,
      promotingRegistration: false,
      resendingRegistrationEmails: false,
      resendingWaiverEmails: false,
      resendingConsentEmails: false,
      producingInvoices: false,
      editingTeenProfile: false,
      StatusType: StatusType,
      filters: {
        paymentStatus: null,
        chapter: null,
        graduationYear: null,
        school: null,
        schoolType: null,
        ticketType: null,
        waiverStatus: null,
        busStatus: null,
        gender: null,
        scholarship: null,
        mediaConsent: null,
        dataConsent: null,
        liabilityConsent: null,
        eventWaiver: null,
        registrationStatus: null,
        registarAccountType: null,
        parentalApprovalGiven: null
      },
      sort: null,
      sortDescending: false
    };
  },
  props: {
    eventId: {},
    event: {},
    addingNewTeen: {},
    selectedRegistrationId: { type: String },
    cancelRegistration: {},
    moveToWaitList: {},
    promoteRegistration: {},
    resendRegistrationEmails: {},
    resendWaiverEmails: {},
    bulkNotifyParents: {},
    produceInvoice: {},
    path: {},
    client: {},
    claims: {},
    router: {}
  },
  computed: {
    computedFilters() {
      return merge(this.filters, this.parsedQueryStringFilters);
    },
    queryStringFilters() {
      return QueryString.stringify(omitBy(this.filters, isNil));
    },
    parsedQueryStringFilters() {
      const str = has(this.router, 'location.search') ? this.router.location.search : '';
      return Object.entries(QueryString.parse(str, { parseNumbers: true })).reduce(
        (collection, [key, value]) => ({
          ...collection,
          ...(has(this.filters, camelCase(key))
            ? { [camelCase(key)]: isString(value) ? toLower(value) : value }
            : {})
        }),
        {}
      );
    },
    toRecordRefund() {
      return (
        !!this.selectedRegistration &&
        this.selectedRegistration.status === StatusType.RefundRequired
      );
    },
    showFilterPanel() {
      return !this.selectedRegistrationId || this.showFilters;
    },
    checkedTeensForConsent() {
      return (this.event!.Registrations || [])
        .filter((r) => this.checkedRegistrations.find((cr) => r.registrationID === cr))
        .filter((r) => {
          if (!r.Teen) return false;
          const parents = getParentsArray(r.Teen.Person);
          if(!parents.length) return false;
          const birthDate = r.Teen.birthDate;
          const parentsEmailExist = !!parents.find(parent => !!parent.email);
          const signedMedia = !!parents.find(parent => !!parent.mediaConsentSigned);
          const signedData = !!parents.find(parent => !!parent.dataConsentSigned);
          const signedLiability = !!parents.find(parent => !!parent.liabilitySigned);

          return (
            (!birthDate || getAge(birthDate) < 18) &&
            parentsEmailExist &&
            (!signedMedia || !signedData || !signedLiability)
          );
        })
        .map((r) => r.Teen.personID);
    }
  },
  methods: {
    formatDate,
    setFilter(key, value) {
      this.filters[key] = value;
      this.router.history.replace({ search: this.queryStringFilters });
    },
    setFilters(filters) {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = filters[key as keyof Filters];
      });
      this.router.history.replace({ search: null });
    },

    toggleTeenProfile() {
      this.isTeenProfileClosed = !this.isTeenProfileClosed;
    },
    selectAllHandler(filteredRegistrations: Event['Registrations']) {
      this.checkedRegistrations = filteredRegistrations.map((r) => r.registrationID) || [];
    },
    async cancelRegistrations(suppressEmail: boolean) {
      this.cancelingRegistrations = false;
      await Promise.all(
        this.checkedRegistrations.map((r) =>
          this.cancelRegistration({ registrationId: r, suppressEmail })
        )
      );
      this.checkedRegistrations.splice(0);
    },

    async moveAllToWaitList(suppressEmail: boolean) {
      this.movingToWaitList = false;
      await Promise.all(
        this.checkedRegistrations.map((r) =>
          this.moveToWaitList({ registrationId: r, suppressEmail })
        )
      );
      this.checkedRegistrations.splice(0);
    },

    async promoteAllRegistrations(suppressEmail: boolean) {
      this.promotingRegistration = false;
      await Promise.all(
        this.checkedRegistrations.map((r) =>
          this.promoteRegistration({ registrationId: r, suppressEmail })
        )
      );
      this.checkedRegistrations.splice(0);
    },

    async resendRegistrationEmailsForAll() {
      this.resendingRegistrationEmails = false;
      await Promise.all(
        this.checkedRegistrations.map((r) => this.resendRegistrationEmails({ registrationId: r }))
      );
      this.checkedRegistrations.splice(0);
    },

    async resendWaiverEmailsForAll() {
      this.resendingWaiverEmails = false;
      await Promise.all(
        this.checkedRegistrations.map((r) => this.resendWaiverEmails({ registrationId: r }))
      );
      this.checkedRegistrations.splice(0);
    },

    async resendConsentEmailsForAll() {
      this.resendingConsentEmails = false;
      await this.bulkNotifyParents({ teenIds: this.checkedTeensForConsent, originSite: 0 });
      this.checkedRegistrations.splice(0);
    },

    async produceInvoices() {
      this.producingInvoices = true;
      const results = await Promise.all(
        this.checkedRegistrations.map(async (r) => ({
          id: r,
          invoice: await this.produceInvoice({ registrationId: r })
        }))
      );

      results.map((r) =>
        FileSaver.saveAs(
          b64toBlob(r.invoice, 'application/pdf', 'data:application/pdf;base64,'),
          `invoice-${r.id}.pdf`
        )
      );
      this.checkedRegistrations.splice(0);
      this.producingInvoices = false;
    }
  },
  watch: {
    selectedRegistrationId: {
      handler: function () {
        this.selectedRegistration = null;
      },
      immediate: true
    }
  }
});
