<template>
    <hr :class="classes" :style="{margin: margin, borderColor: color}"/>
</template>
<script>

export default {
    props: {
        lineStyle: {
            type: String,
            default: 'solid'
        },
        margin: {
            type: String,
            default: '20px 0'
        },
        color: {
            type: String,
            default: '#ccc'
        }
    },
    name: 'layoutDevider',
    computed: {
        classes () {
            const classes = []
            if (this.lineStyle) {
                classes.push(this.lineStyle)
            }

            return classes;
        }
    }
}
</script>

<style scoped>
    hr {
        border: 0;
        border-top: 1px solid #ccc;
        height:1px;
    }
    .dashed {
        border-style: dashed
    }
    .dotted {
        border-style: dotted
    }
</style>