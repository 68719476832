import { computed } from 'vue';
import { Props, ParentUpdateArgs } from 'shared/components/ParentProfile/types';
import { calcParentUpdate } from 'shared/components/ParentProfile/utils';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  CreateAddress,
  CreateEmail,
  CreatePhone,
  ParentProfileFragment,
  ParentProfileFragmentDoc,
  UpdateAddress,
  UpdateEmail,
  UpdatePerson,
  UpdatePhone,
  useCreateAddressMutation,
  useCreateEmailMutation,
  useCreatePhoneMutation,
  useDeletePhoneMutation,
  useUpdateAddressMutation,
  useUpdateEmailMutation,
  useUpdatePersonMutation,
  useUpdatePhoneMutation
} from 'shared/generated/graphql-types';

interface DeletePhoneProp {
  deletePhone: (id: number) => void;
}
export const deletePhoneEnhancer = wrapComponent<Props & { personId?: number }, DeletePhoneProp>(
  (props) => {
    const { mutate } = useDeletePhoneMutation();

    return computed(() => ({
      deletePhone: (id) =>
        mutate(
          { id },
          {
            optimisticResponse: {
              deletePhone: true
            },
            update: (client, { data }) => {
              const personId = props.personId || props.personID;
              const parent = client.readFragment<ParentProfileFragment>({
                fragment: ParentProfileFragmentDoc,
                id: `${personId}Person`,
                fragmentName: 'ParentProfile'
              });
              if (parent) {
                client.writeFragment<ParentProfileFragment>({
                  fragment: ParentProfileFragmentDoc,
                  id: `${personId}Person`,
                  fragmentName: 'ParentProfile',
                  data: {
                    ...parent,
                    Phones: parent.Phones.filter((p) => p.id !== id)
                  }
                });
              }
            }
          }
        )
    }));
  }
);

interface UpdatePersonProps {
  updatePerson: (args: UpdatePerson, optimisticResponseData?: ParentUpdateArgs) => void;
  updatingPerson: boolean;
}
export const updatePersonEnhancer = wrapComponent<Props, UpdatePersonProps>((props) => {
  const { loading, mutate } = useUpdatePersonMutation();

  return computed(() => ({
    updatePerson: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updatePerson: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    updatingPerson: loading.value
  }));
});

interface UpdateEmailProps {
  updateEmail: (args: UpdateEmail, optimisticResponseData?: ParentUpdateArgs) => void;
  updatingEmail: boolean;
}
export const updateEmailEnhancer = wrapComponent<Props, UpdateEmailProps>((props) => {
  const { loading, mutate } = useUpdateEmailMutation();

  return computed(() => ({
    updateEmail: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updateEmail: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    updatingEmail: loading.value
  }));
});

interface CreateEmailProps {
  createEmail: (args: CreateEmail, optimisticResponseData?: ParentUpdateArgs) => void;
  creatingEmail: boolean;
}
export const createEmailEnhancer = wrapComponent<Props, CreateEmailProps>((props) => {
  const { loading, mutate } = useCreateEmailMutation();

  return computed(() => ({
    createEmail: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                createEmail: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    creatingEmail: loading.value
  }));
});

interface UpdatePhoneProps {
  updatePhone: (args: UpdatePhone, optimisticResponseData?: ParentUpdateArgs) => void;
  updatingPhone: boolean;
}
export const updatePhoneEnhancer = wrapComponent<Props, UpdatePhoneProps>((props) => {
  const { loading, mutate } = useUpdatePhoneMutation();

  return computed(() => ({
    updatePhone: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updatePhone: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    updatingPhone: loading.value
  }));
});

interface CreatePhoneProps {
  createPhone: (args: CreatePhone, optimisticResponseData?: ParentUpdateArgs) => void;
  creatingPhone: boolean;
}
export const createPhoneEnhancer = wrapComponent<Props, CreatePhoneProps>((props) => {
  const { loading, mutate } = useCreatePhoneMutation();

  return computed(() => ({
    createPhone: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                createPhone: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    creatingPhone: loading.value
  }));
});

interface UpdateAddressProps {
  updateAddress: (args: UpdateAddress, optimisticResponseData?: ParentUpdateArgs) => void;
  updatingAddress: boolean;
}
export const updateAddressEnhancer = wrapComponent<Props, UpdateAddressProps>((props) => {
  const { loading, mutate } = useUpdateAddressMutation();

  return computed(() => ({
    updateAddress: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                updateAddress: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    updatingAddress: loading.value
  }));
});

interface CreateAddressProps {
  createAddress: (args: CreateAddress, optimisticResponseData?: ParentUpdateArgs) => void;
  creatingAddress: boolean;
}
export const createAddressEnhancer = wrapComponent<Props, CreateAddressProps>((props) => {
  const { loading, mutate } = useCreateAddressMutation();

  return computed(() => ({
    createAddress: (args, optimisticResponseData) =>
      mutate(
        { args },
        {
          optimisticResponse: optimisticResponseData
            ? {
                createAddress: {
                  ...calcParentUpdate(props.parent.original, optimisticResponseData)
                }
              }
            : undefined
        }
      ),
    creatingAddress: loading.value
  }));
});
