<template>
  <div class="lock-overlay" :class="{dependant :lockParent}">
    <Loading></Loading>
  </div>
</template>

<script>
import Loading from 'shared/components/Loading.vue';

export default {
  name: 'UILock',
  components: {
    Loading
  },
  props: {
    lockParent: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped lang="scss">
.lock-overlay {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);
  text-align: center;
  top: 0;
  left: 0;

  &.dependant {
    position: absolute;
  }
}
</style>
