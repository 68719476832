
import Vue from "vue";
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import { Route, RouterLink, MatchFirst } from 'vue-component-router';
import UIButton from "shared/ui/buttons/Button.vue";
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Loading.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import UISelect from 'shared/ui/forms/FancySelect';
import { Props } from './types';
import { elementaryGrades, getGrade, formatAddressToString, getCurrentAddress } from 'shared/util';
import FileSaver from 'file-saver';
import { csvForExpandedClassList } from './csvHelpers';
import { seasonOptions } from '../shared/seasonHelper';
import { GetExpandedClassListDocument, GetExpandedClassListQuery, GetMinimalClassListDocument, GetMinimalClassListQuery } from "shared/generated/graphql-types";

library.add(faIndent, faOutdent, faFilter, faPlusCircle,faDownload)

interface Data {
  schoolYears: Array<{schoolYear: number | null, label: string}>;
  schoolYear: number;
}

interface Methods {
  getMinimalClassList: () => Promise<void>;
  getExpandedClassList: () => Promise<void>;
  formatAddressToString: typeof formatAddressToString;
  getCurrentAddress: typeof getCurrentAddress;
  seasonChange: (season: number) => Promise<void>;
}

interface Computed {
  elementaryGrades: Array<{year: number | null, grade: string}>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
	name: 'JFEListWrapper',
	components: {
		PanelsGrid,
		PanelsSidebar,
		Loading,
		Route,
		RouterLink,
		MatchFirst,
		UIButton,
		UISelect,
		FilterGroup,
    ScrollablePanel,
	},
	directives: {
		fullheight
  },
  props: {
    teens: {},
    loading: {},
    graduationYear: {},
    path: {},
    client: {},
    season: {},
    setSeason: {}
  },
  data() {
    const currentDate = new Date();
    return {
      schoolYears : seasonOptions().map(s => {
        const parts = s.split('-');
        return {
          schoolYear: +parts[1],
          label: s
        }
      }),
      schoolYear: currentDate.getMonth() > 4 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
    };
  },
  computed: {
    elementaryGrades(){
      return elementaryGrades(false, this.season.schoolYear);
    }
  },
  methods: {
    formatAddressToString,
    getCurrentAddress,
    async getMinimalClassList() {
      const result = await this.client.query<GetMinimalClassListQuery>({
        query: GetMinimalClassListDocument,
        variables: {
          graduationYear: Number(this.graduationYear),
          filter: {
            season: this.season.label
          }
        }
      });
      if(result && result.data) {
        const data = result.data.jfeTeens.map(t => `${t.Person.firstName},${t.Person.lastName},${t.Person.gender ? 'Female' : 'Male'},${formatAddressToString(getCurrentAddress(t.Person.Addresses))},${t.medicines},${t.allergyInformation || ''}`).join('\n');
        FileSaver.saveAs(new Blob([`First Name,Last Name,Gender,Address,Medicines,Allergy Information\n${data}`], {type: "text/plain;charset=utf-8"}), `${getGrade(this.graduationYear!, false).grade} Class List.csv`);
      }
    },
    async getExpandedClassList() {
      const result = await this.client.query<GetExpandedClassListQuery>({
        query: GetExpandedClassListDocument,
        variables: {
          graduationYear: Number(this.graduationYear),
          filter: {
            season: this.season.label
          }
        }
      });
      if(result && result.data) {
        const data = csvForExpandedClassList(result.data.jfeTeens, this.season.label);
        FileSaver.saveAs(new Blob(data, {type: "text/plain;charset=utf-8"}), `${getGrade(this.graduationYear!, false).grade} Class List.csv`);
      }
    },
    async seasonChange (season: number) {
      await this.setSeason({ schoolYear: season, label: `${season-1}-${season}` });
    }
  }
})
