
import Vue from 'vue';
import { Props } from './types';
import Loading from 'shared/components/Loading.vue';
import NoData from 'shared/components/NoData.vue';
import EventsList from './events-list.vue';

export default Vue.extend<{}, {}, {}, Props>({
  name: 'AgendaView',
  components: {
    Loading,
    EventsList,
    NoData
  },
  props: {
    eventGroups: {},
    fetching: {},
    today: {},
    holidaysFetching: {}
  }
})
