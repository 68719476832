<template>
  <nav role="navigation" class="main-nav">
    <ScrollablePanel v-fullheight :class="{disabled : !menuOpen}">
      <ul>
        <RouterLink to="/dashboard" tag="li">
          <font-awesome-icon
            icon="tachometer-alt"
            fixed-width
            v-tooltip.right="{content: 'Dashboard', classes: tooltopClasses}"
          />
          <span>Dashboard</span>
        </RouterLink>        
        <RouterLink to="/events" tag="li">
          <font-awesome-icon
            icon="calendar-alt"
            fixed-width
            v-tooltip.right="{content: 'Events', classes: tooltopClasses}"
          />
          <span>Events</span>
        </RouterLink>
        <RouterLink to="/events-list" tag="li" :exact="true">
          <font-awesome-icon
            icon="th-list"
            fixed-width
            v-tooltip.right="{content: 'Events List', classes: tooltopClasses}"
          />
          <span>Events Table</span>
        </RouterLink>
        <RouterLink to="/goals" tag="li">
          <font-awesome-icon
            icon="bullseye-arrow"
            fixed-width
            v-tooltip.right="{content: 'Goals', classes: tooltopClasses}"
          />
          <span>Goals</span>
        </RouterLink>
        <RouterLink to="/mylists" tag="li">
          <font-awesome-icon
            icon="bullseye-arrow"
            fixed-width
            v-tooltip.right="{content: 'My List', classes: tooltopClasses}"
          />
          <span>My List</span>
        </RouterLink>
        <RouterLink to="/teens" tag="li">
          <font-awesome-icon
            icon="user"
            fixed-width
            v-tooltip.right="{content: 'Teens', classes: tooltopClasses}"
          />
          <span>Teens</span>
        </RouterLink>
        <RouterLink to="/families" tag="li">
          <font-awesome-icon
            icon="users"
            fixed-width
            v-tooltip.right="{content: 'Families', classes: tooltopClasses}"
          />
          <span>Families</span>
        </RouterLink>
        <!-- <RouterLink to="/CommunityMembers" tag="li">
          <font-awesome-icon
            icon="user"
            fixed-width
            v-tooltip.right="{content: 'Community Members', classes: tooltopClasses}"
          />
          <span>Community Members</span>
        </RouterLink> -->
        <RouterLink to="/reports" tag="li">
          <font-awesome-icon
            icon="newspaper"
            fixed-width
            v-tooltip.right="{content: 'Reports', classes: tooltopClasses}"
          />
          <span>Reports</span>
        </RouterLink>
        <RouterLink to="/staff" tag="li">
          <font-awesome-icon
            icon="user-tie"
            fixed-width
            v-tooltip.right="{content: 'Staff', classes: tooltopClasses}"
          />
          <span>Staff</span>
        </RouterLink>
        <RequiresPermission
          :permission="PermissionEnum.ManageGlaubachFellows"
          :claims="currentAdvisorRegion.claims"
          :showEmptyTag="true"
        >
          <RouterLink to="/fellows" tag="li">
            <font-awesome-icon
              icon="user-tie"
              fixed-width
              v-tooltip.right="{content: 'Glaubach Fellows', classes: tooltopClasses}"
            />
            <span>Fellows</span>
          </RouterLink>
        </RequiresPermission>
        <slot name="jfe"></slot>
        <slot name="admin"></slot>
        <RequiresPermission
          :permission="PermissionEnum.AccessAdvisorApplicationAdminSite"
          :claims="currentAdvisorRegion.claims"
          :showEmptyTag="true"
          class="advisor-admin"
        >
            <li><a target="_blank" href="https://advisoradmin.ncsy.org/"><font-awesome-icon :icon="['far','link']" class="icon"/> Advisor Admin</a></li>
        </RequiresPermission>
      </ul>
    </ScrollablePanel>
  </nav>
</template>
<script>
import { RouterLink } from 'vue-component-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';
import { faNewspaper } from '@fortawesome/pro-solid-svg-icons/faNewspaper';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons/faBullseyeArrow';
import { faTachometerAlt } from '@fortawesome/pro-solid-svg-icons/faTachometerAlt';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faThList } from '@fortawesome/pro-solid-svg-icons/faThList';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { PermissionEnum } from 'shared/generated/graphql-types';
import RequiresPermission from 'shared/components/RequiresPermission';

library.add(faCog, faUserTie, faNewspaper, faUser, faUsers, faTachometerAlt, faCalendarAlt, faThList, faLink, faBullseyeArrow)

export default {
	name: 'MainNav',
	components: {
		RouterLink,
    ScrollablePanel,
    RequiresPermission
	},
  directives: {
    fullheight
  },
	props: {
		menuOpen: {
			type: Boolean
		},
		staff: {},
		currentRegion: {}
  },
  data: () => {
    return {
      PermissionEnum
    }
  },
	computed: {
		tooltopClasses() {
			if (this.menuOpen) return ('hide-important')
    },
    currentAdvisorRegion() {
      return this.staff && this.currentRegion &&
        this.staff.AdvisorRegions.find(a => a.Region.regionId === this.currentRegion) || { claims: [] };
    }
	}
};
</script>

<style lang="scss" scoped>
nav.main-nav {
  background: white;
  border-right: 1px solid #e2e2e2;
  grid-area: main-nav;
  height: 100%;
  overflow: hidden;
  .fa-fw {
    margin-right: 5px;
    color: #999;
  }
  .active .fa-fw {
    color: #ee7c16;
  }
  .nav-icn {
    float: left;
  }
  ul li {
    border: 1px solid transparent;
    border-left-width: 0;
    font-size: 14px;
    overflow: hidden;
    display: flex;
    flex-flow: wrap;
    align-items: center;
  }
  ul a span,
  ul .dropdown > span {
    display: none;
  }

  li.active {
    border-right-color: transparent;
    border-left-color: #ee7c16;
    background: #fef5ee;
  }
  li ::v-deep a {
    color: #333;
    display: block;
    font-weight: 400;
    padding: 15px 28px;
    cursor: pointer;
  }
  li.active ::v-deep a,
  li.active .dropdown > span {
    color: #666;
  }

  .dropdown {
    border-left: none;
  }
  .dropdown-hook {
    display: none;
    color: #666;
    display: block;
    font-weight: 400;
    background: #f8f8f8;
    color: #4f4f55;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    text-align: center;
    font-weight: bold;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
    position: relative;
  }
  .submenu {
    display: none;
    width: 100%;
    margin-top: 0px;
    margin-left: 0;
  }

  .submenu ::v-deep a,
  .submenu .active ::v-deep a {
    font-size: 0.8em;
    padding: 10px 10px 10px 45px;
    display: block;
    color: #666;
    font-weight: 400;
  }
  .submenu a:hover,
  .submenu .active a:hover {
    color: #f99950;
  }
}
.advisor-admin {
  margin-left: 30px;
  padding: 15px 0px;
}
</style>
<style>
.menuOpen .main-nav .open .submenu {
  display: block !important;
}
.menuOpen .main-nav li a span,
.menuOpen .main-nav ul .dropdown-hook {
  display: inline-block !important;
}
</style>
