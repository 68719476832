
import Vue, { CreateElement } from 'vue';
import Step from '../../shared/Step.vue';
import SchoolLookup from 'shared/components/SchoolLookup';
import ChapterLookup from 'shared/components/ChapterLookup';
import TeenLookup from 'shared/components/TeenLookup';
import StaffLookup from 'shared/components/StaffLookup';
import StateLookup from 'shared/components/StateLookup.vue';
import SeriesLookup from 'shared/components/SeriesLookup';
import ZoomUsersLookup from 'shared/components/ZoomUsersLookup';
import * as RadioGroup from 'shared/radio-group';
import TimezoneDropdown from 'shared/components/TimezoneDropdown.vue';
import Datepicker from 'shared/components/DatePicker.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import { Step as StepType, Steps } from '../../../shared/MultistepForm';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import FormRow from 'shared/ui/forms/FormRow.vue';
import ImageSelector from 'shared/components/ImageSelector.vue';
import EventTrackLookup from 'shared/components/EventTrackLookup';
import WYSIWYG from 'shared/ui/forms/RichTextArea';
import Divider from 'shared/layout/divider/layout-divider.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { regionsWithOutState, isFutureEvent, generateZoomPasscode } from '../../../shared/util';
import { GetRegionDataQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']

library.add(faTimes)

interface Data {
  seriesEvent: boolean;
}

interface Props {
  steps: Steps;
  complete: (step: string) => void;
  toggleActive: (step: string) => void;
  activatable: (step: string) => boolean;
  finalStep: (step: string) => boolean;
  firstStep: (step: string) => boolean;
  setData: (step: string, key: string, value: any) => void;
  getData: (step: string, key: string) => any;
  region: Region;
}

interface Computed {
  showStateLookup: boolean;
  isFutureEvent: boolean;
}

interface Methods {
  seriesEventHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  hideFromHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  isCustomDatesHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  scheduleZoomHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  isVirtualHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  isHasErrors: (stepName: StepType, key: string) => boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'JSUForm',
  components: {
    SchoolLookup,
    ChapterLookup,
    StaffLookup,
    StateLookup,
    TeenLookup,
    SeriesLookup,
    ZoomUsersLookup,
    ...RadioGroup,
    TimezoneDropdown,
    Timepicker,
    Divider,
    Datepicker,
    UIInput,
    UIRadio,
    UISwitch,
    Step,
    ImageSelector,
    EventTrackLookup,
    WYSIWYG,
    FormRow,
    UICheckbox,
  },
  data() {
    return {
      seriesEvent: false
    }
  },
  props: {
    steps: {},
    complete: {},
    toggleActive: {},
    activatable: {},
    finalStep: {},
    firstStep: {},
    setData: {},
    getData: {},
    region: {}
  },
  computed: {
    showStateLookup () {
      return !regionsWithOutState.includes(this.region.regionId)
    },
    isFutureEvent() {
      return isFutureEvent(this.getData('details', 'FirstEvent'), this.getData('details', 'StartTime'), this.getData('details', 'TimeZone'));
    }
  },
  methods: {
    async seriesEventHandler(checked, setData) {
      this.seriesEvent = checked;
      if (!this.seriesEvent) {
        setData('seriesId', null);
      }
    },
    async hideFromHandler(checked, setData) {
      setData('hideFromWebAndMobile', checked);
      await Vue.nextTick();
      if (checked) {
        setData('sendPushNotification', false);
      }
    },
    async isCustomDatesHandler(checked, setData) {
      if (checked) {
        setData('Repeat', 'custom_dates')
      } else {
        setData('Repeat', 'custom_recurrence')
      }
    },
    async scheduleZoomHandler(checked, setData) {
      setData('streamSchedule', checked);
      await Vue.nextTick();
      if (checked) {
        setData('zoomPasscode', generateZoomPasscode());
      } else {
        setData('zoomPasscode', null);
        await Vue.nextTick();
        setData('zoomUser', null);
      }
    },
    async isVirtualHandler(checked, setData) {
      setData('isVirtual', checked);
      await Vue.nextTick();
      if (checked) {
        if (this.getData('details', 'streamSchedule')) {
          setData('zoomPasscode', generateZoomPasscode());
        }
      } else {
        setData('zoomPasscode', null);
        await Vue.nextTick();
        setData('zoomUser', null);
      }
    },
    isHasErrors(step, key) {
      return step.validations && step.validations[key];
    }
  }
})
