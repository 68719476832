
import Vue from 'vue'
import FancySelect from 'shared/ui/forms/FancySelect';
import provinces, { Provinces } from 'provinces';
import trim from 'lodash/trim';

interface Data {
	provinces: Provinces[]
}

interface Props {
	value: string | null
}

interface Computed {
	state: Omit<Provinces, 'short'> & { short: string | null } | undefined
}

interface Methods {
	trim: (string?: string, chars?: string) => string;
	emit: (state: Provinces) => void
}

export default Vue.extend<Data, Methods, Computed, Props>({
	data() {
		return {
			provinces: []
		}
	},
	props: {
    value: {
      default: null
    }
  },
	created () {
    this.provinces = provinces.filter(x => x.country === 'US' || x.country === 'CA');
	},
	computed: {
		state () {
			if (!trim(this.value || '')) return { short: null, name: 'N/A', country: 'N/A' }

			return this.provinces.find(x => x.short === this.value);
		}
	},
	methods: {
    trim,
		emit (state) {
			this.$emit('input', state && state.short || null);
		}
	},
	components: { FancySelect }
})
