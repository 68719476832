
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import Loading from 'shared/components/Loading.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { Props as LayoutProps } from '../types';

interface Props {
  phones: LayoutProps['phones']
  fetchMore: LayoutProps['fetchMore'];
  selectedPhone: number;
}
interface Data {
  limit: number;
  busy: boolean;
}
interface Methods {
  fetchMoreHandler: ($state: StateChanger) => Promise<void>;
}
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'PhoneList',
  components: {
    BorderedList,
    InfiniteLoading,
    Loading,
    RouterLink
  },
  props: {
    phones: {},
    fetchMore: {},
    selectedPhone: {},
  },
  data() {
    return {
      limit: 60,
      busy: false
    };
  },
  methods: {
    async fetchMoreHandler($state) {
      await this.fetchMore({ limit: this.limit, offset: this.phones.length }, $state);
      $state.loaded();
    }
  }
});
