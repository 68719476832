
import Vue from "vue";
import { Methods, Props } from './types'

export default Vue.extend<{}, Methods, {}, Props>({
  name: "Metadata",
  props: {
    metaID: {},
    metaKey: {},
    entityID: {},
    entityType: {},
    mutate: {}
  },
  methods: {
    async setValue(event) {
      return await this.mutate({
          entityType: this.entityType,
          entityID: this.entityID,
          metaKey: this.metaKey,
          metaValue: String((event.target as HTMLInputElement).value)
        }, {
        optimisticResponse: {
          __typename: "Mutation",
          updateOrCreateMetaData: {
            __typename: "MetaData",
            MetaID: this.metaID,
            EntityID: this.entityID,
            EntityType: this.entityType,
            MetaKey: this.metaKey,
            MetaValue: String((event.target as HTMLInputElement).value)
          }
        }
      });
    }
  }
});
