
import ChapterLookup from 'shared/components/ChapterLookup';
import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import * as RadioGroup from 'shared/radio-group';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import ActiveFilters from '../shared/ActiveFilters';
import { ServerSideFilterOption } from 'shared/components/Filters/FilterOption';
import UISelect from 'shared/ui/forms/FancySelect';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';
import identity from 'lodash/identity';
import UIButton from 'shared/ui/buttons/Button.vue';
import fullheight from 'shared/directives/fullheight';
import { GetFilterOptionsQuery, SortType } from 'shared/generated/graphql-types';

library.add(faSortAlphaDown,faSortAlphaUp)
interface Column {
	columnName: string,
	propertyName: string
}
@Component({
	name: "FiltersPanel",
	components: {
		ChapterLookup,
		UIRadio,
		UICheckbox,
		ScrollablePanel,
		UISelect,
		UIButton,
		...RadioGroup
	},
	 directives: {
      fullheight
    }
})
export default class extends Vue {
	@Prop() filterOptions!: GetFilterOptionsQuery;
	@Prop() activeFilters!: ActiveFilters;

  filters: { [key: string]: ServerSideFilterOption[] } = Object.assign({}, 	this.activeFilters && this.activeFilters.filters || {})
	sortType = SortType
  filter: SortType = this.activeFilters.sortBy.name;
	showDeceased: boolean = this.activeFilters.showDeceased || false
  ascending: boolean = !!this.activeFilters.sortBy.ascending;
	get localActivefilters () {
		return { filters: omitBy(pickBy(this.filters, identity), (x) => isArray(x) && !x.length), sortBy: { name: this.filter, ascending: this.ascending }, showDeceased: this.showDeceased  }
	}
	get genderOptions (): ServerSideFilterOption[] {
		return [
			{ key: '0', displayValue: 'Male' },
			{ key: '1', displayValue: 'Female' }
		]
	}

  clearFilters () {
    this.filters = {
      Gender: [],
		  Chapter: []
    }
    this.showDeceased = false;
  }
	@Watch ('filters', { deep: true })
	filtersWatcher () {
	this.$emit('filters', this.localActivefilters)
	}
	@Watch ('ascending')
	ascendingWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
	@Watch ('filter')
	filterWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
	@Watch ('showDeceased')
	showDeceasedWatcher () {
		this.$emit('filters', this.localActivefilters)
	}
}
