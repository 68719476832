
import { Vue, Prop, Component } from "vue-property-decorator";
import UIButton from 'shared/ui/buttons/Button.vue';
import { EntityUnionViewModel } from '../../shared/EntityUnionViewModel';
import PopupDetails from '../PopupDetails.vue';
import has from 'lodash/has';
import { RegionsMap } from '../../types'
import { AddStaffToRegionMutation, AddStaffToRegionMutationVariables, EntityUnionEnum } from "shared/generated/graphql-types";
import { MutateResult } from "@vue/apollo-composable";

@Component({
  name: 'StaffPopup',
	components: {
    PopupDetails,
    UIButton
  },
  inheritAttrs: false
})
export default class StaffPopup extends Vue {

    @Prop() duplicate!: EntityUnionViewModel;
    @Prop() owner!: EntityUnionViewModel;
    @Prop({ type: String as() => EntityUnionEnum, required: true }) ownerType!: EntityUnionEnum;
    @Prop({ default: false }) showAddStaffToUserRegion!: boolean;
    @Prop() left!: boolean;
    @Prop() narrow!: boolean;
    @Prop() router!: { history: { push: (location: string) => void } };
    @Prop() regionId!: number;
    @Prop({ default: false }) addingStaff!: boolean;
    @Prop() closePopup!: () => void;
    @Prop() addStaffToRegion!: (args: AddStaffToRegionMutationVariables) => MutateResult<AddStaffToRegionMutation>

    get differentRegion () {
      if (has(this.duplicate, 'advisorRegions[0]')) {
        return !this.duplicate.advisorRegions.map(ar => ar.regionId).includes(this.regionId)
      }
      else {
        return false;
      }
    }
    get potentionalAddStaffToRegion (): boolean {
      return (
          typeof this.regionId === 'number'
          &&
          !!(this.duplicate && this.duplicate.advisorRegions.length)
          &&
          this.differentRegion
      )
    }
    get populateOption() {
        return has(this.$listeners, 'populate')
    }
    get computedOwnerType() {
        return this.owner ? this.owner.type : this.ownerType
    }
    get regionName () {
      return RegionsMap[this.regionId]
    }
    get duplicateType() {
      return this.duplicate && this.duplicate.type;
    }
    async addStaffHandler () {
      try {
        const results = await this.addStaffToRegion({ staffId: this.duplicate.id, regionId: this.regionId, sourceRegionId: this.duplicate.mostRecentRegion })
        this.router.history.push(`/staff/${this.duplicate.id}?expand=permissions`);
      }
      catch (e) {
        this.closePopup();
      }
    }
    navigateToDuplicateProfile() {
      window.open(`/staff/${this.duplicate.id}`, '_blank');
    }
}
