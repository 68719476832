import { wrapComponent } from "shared/apollo-hoc";
import JFESingleOrder from "./Single.vue";
import { useDeleteJfeOrderPaymentMutation, useGetJfeSingleOrderQuery } from "shared/generated/graphql-types";
import { computed } from "vue";
import { compose } from "vue-compose";
import { withApollo } from "shared/components/graphql";
import { Props } from "./types";

const getOrderEnhancer = wrapComponent<
  { orderId: number },
  Pick<Props, "order" | "loading">
>((props) => {
  const { loading, result } = useGetJfeSingleOrderQuery(
    computed(() => ({ orderId: props.orderId }))
  );

  return computed(() => ({
    loading: loading.value,
    order: result.value?.getOrder,
  }));
});

const deletePaymentEnhancer = wrapComponent<Props, Pick<Props, "deletePayment" | "deletingPayment">>((props) => {
  const { loading, mutate } = useDeleteJfeOrderPaymentMutation();

  return computed(() => ({
    deletingPayment: loading.value,
    deletePayment: (paymentId: number) =>
      mutate({ paymentId, orderId: props.orderId })
  }));
});

export default compose(
  getOrderEnhancer,
  deletePaymentEnhancer,
  withApollo
)(JFESingleOrder);
