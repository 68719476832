import { render, staticRenderFns } from "./Impact.vue?vue&type=template&id=12a1b05c&scoped=true&"
import script from "./Impact.vue?vue&type=script&lang=ts&"
export * from "./Impact.vue?vue&type=script&lang=ts&"
import style0 from "./Impact.vue?vue&type=style&index=0&id=12a1b05c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a1b05c",
  null
  
)

export default component.exports