
import Vue from 'vue';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import { Data, Props } from './types';
import InteractionForm from 'shared/components/InteractionForm';
import padStart from 'lodash/padStart';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { SimpleDate } from 'shared/util/types';

library.add(faPencil, faTrash)

export default Vue.extend<Data, {}, {}, Props>({
  name: "Interaction",
  components: {
    UIButton,
    Modal,
    InteractionForm
  },
  data () {
    return {
      editingInteraction: false,
      deleting: false
    }
  },
  props: {
    interaction: {},
    personId: {}
  },
  methods: {
    formatDate (date: SimpleDate) {
      return `${date.year}-${padStart(String(date.month), 2, '0')}-${padStart(String(date.day), 2, '0')}`;
    }
  }
})
