
import Vue from 'vue';
import { Props as ParentProps } from '../types';
import { dateFormat } from 'shared/util';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';

interface Props {
  phone: ParentProps['holders']['0']['Phones']['0'];
  selectedNumber: string;
  deletePhone: ParentProps['deletePhone'];
  deletingPhone: ParentProps['deletingPhone'];
}
interface Methods {
  dateFormat: typeof dateFormat;
  deletePhoneHandler: () => Promise<void>;
}
interface Data {
  showDeletePhoneConfirm: boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'PhoneDisplay',
  components: {
    Modal,
    UIButton
  },
  props: {
    phone: {},
    selectedNumber: {},
    deletePhone: {},
    deletingPhone: {}
  },
  data() {
    return {
      showDeletePhoneConfirm: false
    }
  },
  methods: {
    dateFormat,
    async deletePhoneHandler() {
      await this.deletePhone(this.phone.id);
    }
  },
  watch: {
    deletingPhone(val) {
      if (!val) {
        this.showDeletePhoneConfirm = false;
      } else {
        this.showDeletePhoneConfirm = true;
      }
    }
  }
});
