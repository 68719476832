
import Vue from "vue";
import UIButton from "shared/ui/buttons/Button.vue";
import UIInput from "shared/ui/forms/Input.vue";
import UISearch from "shared/ui/forms/Search.vue";
import BorderedList from "shared/ui/lists/BorderedList.vue";
import PanelsSidebar from "shared/components/structure/panelsSidebar.vue";
import PanelsGrid from "shared/components/structure/panelsGrid.vue";
import ScrollablePanel from "shared/components/scrollable-panel.vue";
import fullheight from "shared/directives/fullheight";
import Modal from "shared/ui/modal/Modal.vue";
import Divider from "shared/layout/divider/layout-divider.vue";
import { splitName } from "shared/util";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { ArrayElement } from "shared/util/types";
import { GetEventQuery } from "shared/generated/graphql-types";

type EventGuest = ArrayElement<GetEventQuery['event']['EventGuests']>

library.add(faTrash);

interface Data {
  filter: string | null;
  name: string;
  confirmRemoval: boolean[];
}

interface Props {
  guests: EventGuest[];
  addNewGuest: (guest: Partial<EventGuest>) => void;
  removeGuest: (guest: EventGuest) => void;
  eventId: number;
}

interface Computed {
  filteredGuests: EventGuest[];
}

interface Methods {
  addGuestHandler: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Guests',
  components: {
    UIButton,
    UISearch,
    BorderedList,
    PanelsSidebar,
    PanelsGrid,
    UIInput,
    Divider,
    ScrollablePanel,
    Modal,
  },
  directives: {
    fullheight,
  },
  data() {
    return {
      filter: null,
      name: '',
      confirmRemoval: [],
    };
  },
  props: {
    guests: {},
    addNewGuest: { required: true },
    removeGuest: {},
    eventId: {},
  },
  computed: {
    filteredGuests() {
      const { filter } = this;

      if (!filter) return this.guests;

      return this.guests.filter(
        (guest: EventGuest) =>
          `${guest.firstName} ${guest.lastName}`
            .toLowerCase()
            .indexOf(filter.toLowerCase()) >= 0
      );
    },
  },
  methods: {
    addGuestHandler() {
      if (!this.name) return;
      const { firstName, lastName } = splitName(this.name);
      this.addNewGuest({
        eventId: this.eventId,
        firstName,
        lastName,
      });
      this.name = '';
    },
  },
});
