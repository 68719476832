import { getCurrentCellNumber, getCurrentAddress, getCurrentLandlineNumber, splitName, isJsonString, getCurrentEmail } from "shared/util";
import { ApolloClient } from '@apollo/client';
import { toCompactTeen } from "../../utils";
import { ChapterSummary } from "src/pages/Root/pages/Chapters/types";
import { getFieldOfParent } from "shared/util/families";
import { AdvisorSummaryFragment, FamilyParentFragmentFragment, PhoneType, SingleParentQuery, SingleTeenQuery, ExtendedTeenProfileFragmentDoc, UpdateTeenField, UpdateTeenMutationVariables, School } from "shared/generated/graphql-types";
import { ArrayElement } from "shared/util/types";

type Teen = SingleTeenQuery['singleTeen']
type Advisors = ArrayElement<SingleTeenQuery['singleTeen']['Advisors']>
type ParentEmail = ArrayElement<FamilyParentFragmentFragment['EmailAddresses']>
type ParentPhone = ArrayElement<FamilyParentFragmentFragment['Phones']>
type ParentAddress = ArrayElement<SingleParentQuery['singleParent']['Addresses']>

function insert<T extends Array<any>>(value: T[0], array: T, index: number) {
  return [
    ...array.slice(0, index),
    value,
    ...array.slice(index + 1)
  ];
}

function replace<T extends Array<any>>(value: T[0], array: T, index: number) {
  return [
    ...array.slice(0, index - 1),
    value,
    ...array.slice(index + 1)
  ];
}

function updateSelection<TFragment> (
  value: string | null | undefined,
  teen: Teen,
  client: ApolloClient<any>,
  field: keyof Teen,
  fragmentName: string,
  entity: string
) {
  if (!value) {
    return {
      ...teen,
      [field]: null
    };
  }
  try {
    const item = client.readFragment<TFragment>({
      fragment: ExtendedTeenProfileFragmentDoc,
      fragmentName,
      id: `${value}${entity}`
    });
    return {
      ...teen,
      [field]: item
    };
  }
  catch (e) {
    return {
      ...teen,
      [field]: null
    };
  }

}

function getNestedFieldUpdateValue<O>(update: UpdateTeenMutationVariables, action: (t: string) => O | string = (i) => i) {
  if(!action) {
    action = (i) => i;
  }
  if (isJsonString(update.value!)) {
    const firstLevel = JSON.parse(update.value!);
    return isJsonString(firstLevel.value) ? JSON.parse(firstLevel.value!) : action(firstLevel.value!);
  } else {
    return action(update.value!);
  }
}

export function calcTeenUpdate(teen: Teen, update: UpdateTeenMutationVariables, client: ApolloClient<any>): Teen {
  const compactTeen = toCompactTeen(teen);

  switch (update.fieldName) {
    case UpdateTeenField.Address: {
      const currentAddress = getCurrentAddress(teen.Person.Addresses);

      const index = teen.Person.Addresses.findIndex(x => x.id === currentAddress.id);
      const value: ParentAddress = JSON.parse(update.value!);

      value.__typename = 'Address',
        value.dateCreated = new Date().toISOString();
      value.dateUpdated = new Date().toISOString();
      value.id = -1;
      value.primary = true;
      // For now we assume that the address is verified
      value.verified = currentAddress.verified || true;

      if (index === -1) {
        return {
          ...teen,
          Person: {
            ...teen.Person,
            Addresses: [
              ...teen.Person.Addresses,
              value
            ]
          }
        };
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          Addresses: insert({ ...currentAddress, ...value }, teen.Person.Addresses, index)
        }
      };
    }
		case UpdateTeenField.CellNumber:
      const cellPhone = getCurrentCellNumber(teen.Person.Phones);
      const newPhone: Teen = {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: [
            {
              __typename: 'Phone',
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              description: null,
              doNotCall: null,
              id: -1,
              invalid: false,
              phoneNumber: update.value!,
              primary: true,
              type: PhoneType.Mobile
            }
          ]
        }
      };

      if (!cellPhone) {
        return newPhone;
      }

      const index = teen.Person.Phones.findIndex(x => x.id === cellPhone.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: insert({ ...cellPhone, phoneNumber: update.value! }, teen.Person.Phones, index)
        }
      };
    case UpdateTeenField.HomeNumber: {
      const homeNumber = getCurrentLandlineNumber(teen.Person.Phones);

      const newPhone: Teen = {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: [
            {
              __typename: 'Phone',
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              description: null,
              doNotCall: null,
              id: -1,
              invalid: false,
              phoneNumber: update.value!,
              primary: true,
              type: PhoneType.Landline
            }
          ]
        }
      };

      if (!homeNumber) {
        return newPhone;
      }

      const index = teen.Person.Phones.findIndex(x => x.id === homeNumber.id);

      if (index === -1) {
        return newPhone;
      }

      return {
        ...teen,
        Person: {
          ...teen.Person,
          Phones: insert({ ...homeNumber, phoneNumber: update.value! }, teen.Person.Phones, index)
        }
      };
    }

    case UpdateTeenField.ChapterId:
      return updateSelection<ChapterSummary>(
        update.value!,
        teen,
        client,
        'Chapter',
        'ChapterSummary',
        'Chapter'
      );
    case UpdateTeenField.CollegeId:
      return updateSelection<School>(
        update.value!,
        teen,
        client,
        'College',
        'School',
        'School'
      );
    case UpdateTeenField.GapYearProgramId:
      return updateSelection<School>(
        update.value!,
        teen,
        client,
        'GapYearProgram',
        'School',
        'School'
      );
    case UpdateTeenField.HighSchoolId:
      return updateSelection<School>(
        update.value!,
        teen,
        client,
        'School',
        'School',
        'School'
      );
    case UpdateTeenField.MiddleSchoolId:
      return updateSelection<School>(
        update.value!,
        teen,
        client,
        'MiddleSchool',
        'School',
        'School'
      );
    case UpdateTeenField.ShanaBetId:
      return updateSelection<School>(
        update.value!,
        teen,
        client,
        'ShanaBet',
        'School',
        'School'
      );
    case UpdateTeenField.SynagogueId:
      return updateSelection(
        update.value!,
        teen,
        client,
        'Synagogue',
        'SynagogueSummary',
        'Synagogue'
      );
    case UpdateTeenField.PrimaryAdvisorId: {
      const currentPrimaryAdvisor = compactTeen.primaryAdvisor;
      const advisor = client.readFragment<AdvisorSummaryFragment>({
        fragment: ExtendedTeenProfileFragmentDoc,
        fragmentName: 'AdvisorSummary',
        id: `${update.value!}Staff`
      });

      if (!currentPrimaryAdvisor) {
        const teenAdvisor: Advisors = {
          __typename: 'TeenAdvisor',
          Advisor: advisor!,
          advisorId: +update.value!,
          isPrimary: true,
          teenAdvisorId: -1
        };

        return  {
          ...teen,
          Advisors: [
            ...teen.Advisors,
            teenAdvisor
          ]
        };
      }

      const teenAdvisor = teen.Advisors.find(x => x.Advisor.staffID === currentPrimaryAdvisor.staffID)!;
      const index = teen.Advisors.indexOf(teenAdvisor);

      return {
        ...teen,
        Advisors: replace({
          ...teenAdvisor,
          Advisor: advisor!
        }, teen.Advisors, index)
      };
    }
    case UpdateTeenField.SecondaryAdvisorId: {
      const currentSecondaryAdvisor = compactTeen.secondaryAdvisor;
      const advisor = client.readFragment<AdvisorSummaryFragment>({
        fragment: ExtendedTeenProfileFragmentDoc,
        fragmentName: 'AdvisorSummary',
        id: `${update.value!}Staff`
      });

      if (!currentSecondaryAdvisor) {
        const teenAdvisor: Advisors = {
          __typename: 'TeenAdvisor',
          Advisor: advisor!,
          advisorId: +update.value!,
          isPrimary: false,
          teenAdvisorId: -1
        };

        return  {
          ...teen,
          Advisors: [
            ...teen.Advisors,
            teenAdvisor
          ]
        };
      }

      const teenAdvisor = teen.Advisors.find(x => x.Advisor.staffID === currentSecondaryAdvisor.staffID)!;
      const index = teen.Advisors.indexOf(teenAdvisor);

      return {
        ...teen,
        Advisors: replace({
          ...teenAdvisor,
          Advisor: advisor!
        }, teen.Advisors, index)
      };
    }
    case UpdateTeenField.Email: {
      const currentEmail = toCompactTeen(teen).email;

      const newEmail: Teen = {
        ...teen,
        Person: {
          ...teen.Person,
          EmailAddresses: [
            {
              __typename: 'EmailAddress',
              dateCreated: new Date().toISOString(),
              dateUpdated: new Date().toISOString(),
              email: update.value!,
              id: -1,
              invalid: false,
              primary: true
            }
          ]
        }
      };

      if (!currentEmail) {
        return newEmail;
      }

      const email = teen.Person.EmailAddresses.find(x => x.email === currentEmail)!;
      const index = teen.Person.EmailAddresses.indexOf(email);

      if (index === -1) {
        return newEmail;
      }

      return {
				...teen,
				Person: {
					...teen.Person,
					EmailAddresses: insert({...email, email: update.value!}, teen.Person.EmailAddresses, index)
				}
      };
    }
    case UpdateTeenField.FullName: {
      const { firstName, lastName } = isJsonString(update.value!) ? JSON.parse(update.value!) : splitName(update.value!);

      return {
        ...teen,
        Person: {
          ...teen.Person,
          firstName,
          lastName
        }
      };
    }

    // Taking a small risk with the parent fields, and not doing null checks
    // since the DOM should not be offering the option to run these commands without a primary family existing
    case UpdateTeenField.MotherCellPhone:
    case UpdateTeenField.MotherHomePhone: {
      const families = teen.Person.ChildOf!;
      const primaryFamilyIndex = families!.findIndex(f => f.id === compactTeen.primaryFamily!.id);
      const motherPhones = getFieldOfParent<ParentPhone[]>(families, primaryFamilyIndex, 'Mother', 'Phones', []);
      const phoneType: PhoneType = UpdateTeenField.MotherHomePhone === update.fieldName ? PhoneType.Landline : PhoneType.Mobile;

      const phoneNumber = phoneType === PhoneType.Landline ? getCurrentLandlineNumber(motherPhones) : getCurrentCellNumber(motherPhones);
      if (!phoneNumber) {
        return teen;
      }

      const primaryFamily = { ...families[primaryFamilyIndex] };
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = motherPhones.findIndex(x => x.id === phoneNumber!.id);
      if (index === -1) {
        primaryFamily.Mother!.Phones = [
          ...primaryFamily.Mother!.Phones,
          {
            __typename: 'Phone',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            description: null,
            doNotCall: null,
            id: -1,
            invalid: false,
            phoneNumber: parsedValue,
            primary: true,
            type: PhoneType[phoneType]
          }
        ];
      } else {
        primaryFamily.Mother!.Phones = insert({ ...phoneNumber, phoneNumber: parsedValue }, primaryFamily.Mother!.Phones, index);
      }

      return teen;
    }

    case UpdateTeenField.FatherCellPhone:
    case UpdateTeenField.FatherHomePhone: {
      const families = teen.Person.ChildOf!;
      const primaryFamilyIndex = families!.findIndex(f => f.id === compactTeen.primaryFamily!.id);
      const fatherPhones = getFieldOfParent<ParentPhone[]>(families, primaryFamilyIndex, 'Father', 'Phones', []);
      const phoneType: PhoneType = UpdateTeenField.FatherHomePhone === update.fieldName ? PhoneType.Landline : PhoneType.Mobile;

      const phoneNumber = phoneType === PhoneType.Landline ? getCurrentLandlineNumber(fatherPhones) : getCurrentCellNumber(fatherPhones);
      if (!phoneNumber) {
        return teen;
      }

      const primaryFamily = { ...families[primaryFamilyIndex] };
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = fatherPhones.findIndex(x => x.id === phoneNumber!.id);
      if (index === -1) {
        primaryFamily.Father!.Phones = [
          ...primaryFamily.Father!.Phones,
          {
            __typename: 'Phone',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            description: null,
            doNotCall: null,
            id: -1,
            invalid: false,
            phoneNumber: parsedValue,
            primary: true,
            type: PhoneType[phoneType]
          }
        ];
      } else {
        primaryFamily.Father!.Phones = insert({ ...phoneNumber, phoneNumber: parsedValue }, primaryFamily.Father!.Phones, index);
      }

      return teen;
    }

    case UpdateTeenField.FatherEmail: {
      const families = teen.Person.ChildOf!;
      const primaryFamilyIndex = families!.findIndex(f => f.id === compactTeen.primaryFamily!.id);
      const fatherEmailAddresses = getFieldOfParent<ParentEmail[]>(families, primaryFamilyIndex, 'Father', 'EmailAddresses', []);

      const currentEmail = getCurrentEmail(fatherEmailAddresses);
      if (!currentEmail) {
        return teen;
      }

      const primaryFamily = { ...families[primaryFamilyIndex] };
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = fatherEmailAddresses.findIndex(x => x.id === currentEmail.id);
      if (index === -1) {
        primaryFamily.Father!.EmailAddresses = [
          ...primaryFamily.Father!.EmailAddresses,
          {
            __typename: 'EmailAddress',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            email: parsedValue,
            id: -1,
            invalid: false,
            primary: true
          }
        ];
      } else {
        primaryFamily.Father!.EmailAddresses = insert({ ...currentEmail, email: parsedValue }, primaryFamily.Father!.EmailAddresses, index);
      }

      return teen;
    }

    case UpdateTeenField.MotherEmail: {
      const families = teen.Person.ChildOf!;
      const primaryFamilyIndex = families!.findIndex(f => f.id === compactTeen.primaryFamily!.id);
      const motherEmailAddresses = getFieldOfParent<ParentEmail[]>(families, primaryFamilyIndex, 'Mother', 'EmailAddresses', []);

      const currentEmail = getCurrentEmail(motherEmailAddresses);
      if (!currentEmail) {
        return teen;
      }

      const primaryFamily = { ...families[primaryFamilyIndex] };
      const parsedValue = getNestedFieldUpdateValue(update);

      const index = motherEmailAddresses.findIndex(x => x.id === currentEmail.id);
      if (index === -1) {
        primaryFamily.Mother!.EmailAddresses = [
          ...primaryFamily.Mother!.EmailAddresses,
          {
            __typename: 'EmailAddress',
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            email: parsedValue,
            id: -1,
            invalid: false,
            primary: true
          }
        ];
      } else {
        primaryFamily.Mother!.EmailAddresses = insert({ ...currentEmail, email: parsedValue }, primaryFamily.Mother!.EmailAddresses, index);
      }

      return teen;
    }

    case UpdateTeenField.MotherName: {
      const { firstName, lastName } = getNestedFieldUpdateValue(update, splitName);
      const families = teen.Person.ChildOf!;
      const primaryFamilyIndex = families!.findIndex(f => f.id === compactTeen.primaryFamily!.id);
      const primaryFamily = { ...families[primaryFamilyIndex] };

      primaryFamily.Mother = {
        ...primaryFamily!.Mother!,
        firstName,
        lastName
      };
      return teen;
    }

    case UpdateTeenField.FatherName: {
      const { firstName, lastName } = getNestedFieldUpdateValue(update, splitName);
      const families = teen.Person.ChildOf!;
      const primaryFamilyIndex = families!.findIndex(f => f.id === compactTeen.primaryFamily!.id);
      const primaryFamily = { ...families[primaryFamilyIndex] };

      primaryFamily.Father = {
        ...primaryFamily!.Father!,
        firstName,
        lastName
      };
      return teen;
    }

		case UpdateTeenField.CollegeStartYear:
		case UpdateTeenField.BirthDate:
		case UpdateTeenField.Gender:
		case UpdateTeenField.GraduationYear:
		case UpdateTeenField.Interests:
		case UpdateTeenField.IsAlumni:
		case UpdateTeenField.IsJewish:
		case UpdateTeenField.IsUnaffiliated:
		case UpdateTeenField.Medicines:
		case UpdateTeenField.PrimaryAdvisorId:
		case UpdateTeenField.RegionalBoardMember:
		case UpdateTeenField.ShanaBetStartYear:
		case UpdateTeenField.GapYearProgramStartYear:
		case UpdateTeenField.ShomerShabbat:
		case UpdateTeenField.ShomerTorahUMitzvos:
		case UpdateTeenField.SocialMediaHandles:
		case UpdateTeenField.Vegetarian:
		case UpdateTeenField.YouthGroups:
		case UpdateTeenField.TeudatZehut:
		case UpdateTeenField.AliyahDate:
		case UpdateTeenField.AttendedGapYearDueToNcsy:
			return {
				...teen,
				[update.fieldName]: update.value!
			};
		default:
			return teen;
	}
}
