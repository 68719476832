

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import SynagogueLookup from 'shared/components/SynagogueLookup';
import { getCurrentSeason } from 'shared/util';

@Component({
  components: {
    SynagogueLookup
  }
})
export default class SynagogueIdPicker extends Vue {
  @Prop() value!: string;
}
