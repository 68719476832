
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UIButton from 'shared/ui/buttons/Button.vue';

@Component({
  components: {
    UIButton
  },
  mounted() {
    this.currentPage = this.page;
  }
})
export default class Pagination extends Vue {

  @Prop({ default: 10 }) maxVisibleButtons!: number;
  @Prop() totalPages!: number;
  @Prop() total!: number;
  @Prop({ default: 1 }) page!: number;
  currentPage: number = 1;

  get startPage() {
      if (this.totalPages < this.maxVisibleButtons) {
          return this.totalPages - this.totalPages + 1
      }

      if (this.currentPage === 1) {
          return 1;
      }

      if (this.currentPage === this.totalPages) {
          return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;

  }
  get endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
  }
  get pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
          range.push({
              name: i,
              isDisabled: i === this.currentPage
          });
      }

      return range;
  }
  get isFirstPage() {
      return this.currentPage === 1;
  }
  get isLastPage() {
      return this.currentPage === this.totalPages;
  }
  firstPageHandler() {
      this.currentPage = 1;
      this.$emit('pagechanged', 1);
  }
  changePage(page: number) {
      this.currentPage = page;
      this.$emit('pagechanged', this.currentPage)
  }
  @Watch('page')
  pageWatcher() {
      this.currentPage = this.page;
  }
}
