import Families from './Layout.vue';
import Vue, { computed, VueConstructor } from 'vue';
import { compose } from 'vue-compose';
import { getOptions, normalizeProps } from 'shared/util';
import { ActiveFilters, FetchMoreOptions } from './shared/types';
import { withRouter } from 'vue-component-router';
import { Family, Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetFamiliesWithChildrenQuery, SortType, useGetFamiliesWithChildrenQuery } from 'shared/generated/graphql-types';
import { StateChanger } from 'vue-infinite-loading';
import { ApolloQueryResult } from '@apollo/client';

type EnhancerProps = {
  initialLoading: boolean;
  loading: boolean;
  families: Family[];
  fetchMore: (args: FetchMoreOptions, stateChanger: StateChanger) => Promise<ApolloQueryResult<GetFamiliesWithChildrenQuery>> | undefined;
  total: number;
}
export const getFamiliesEnhancer = wrapComponent<Props, EnhancerProps>((props) => {
  const { loading, result, fetchMore } = useGetFamiliesWithChildrenQuery(
    computed(() => ({
      limit: 60,
      filter: {
        regionId: props.regionId,
        query: props.activeFilters && props.activeFilters.term,
        sortBy: props.activeFilters && props.activeFilters.sortBy,
        maritalStatus: props.activeFilters && props.activeFilters.maritalStatus
      }
    })),
    {
      fetchPolicy: 'network-only'
    }
  );

  return computed(() => ({
    initialLoading: loading.value && !result.value?.families.families,
    loading: loading.value,
    families: result.value?.families.families || [],
    total: result.value?.families.total || 0,
    fetchMore({limit, offset}, stateChanger) {
      return fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            regionId: props.regionId,
            query: props.activeFilters.term,
            sortBy: props.activeFilters.sortBy
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult || !fetchMoreResult.families) {
            return previousResult;
          }
          if (!fetchMoreResult.families.families.length || fetchMoreResult.families.families.length < limit) {
            stateChanger.complete();

            if (!fetchMoreResult.families.families.length) {
              return previousResult;
            }
          }
          const ids = previousResult.families.families.map(x => x.id);
          const newData = fetchMoreResult.families.families.filter(x => !ids.includes(x.id));
          return {
            families: {
              __typename: 'FamiliesPage',
              total: fetchMoreResult.families.total,
              families: [
                ...previousResult.families!.families,
                ...newData
              ]
            }
          };
        }
      });
    },
  }));
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, ...propsToUse } = props;

  return Vue.extend<
    { activeFilters: ActiveFilters },
    { setFilters: (args: Partial<ActiveFilters>, cb?: () => void) => void },
    {},
    {}
  >({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        activeFilters: {
          term: '',
          sortBy: { name: SortType.Name, ascending: true },
          maritalStatus: null
        }
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters
        },
        on: this.$listeners
      });
    }
  });
};

export default compose(withFilters, getFamiliesEnhancer, withRouter)(Families);
