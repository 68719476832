
<template>
  <Popover class="event" name="event-popover" :class="{multiday : multiday}" @wheel.native.stop>
    <div slot="face" class="event-btn" v-tooltip.left="event.eventName" v-if="!inMenu">
      <span class="mini-time">{{timeFormat(normalizedEventTime(event).split(' - ')[0])}}</span>
      {{event.eventName}}
    </div>
    <div slot="face" class="event-list-item" v-else>
      <span class="mini-time">{{timeFormat(normalizedEventTime(event).split(' - ')[0])}}</span>
      {{event.eventName}}
    </div>
    <div slot="content">
      <Dimensions>
          <template #default="{dimensions}">
            <div class="event-popup" :style="getPosition(dimensions)" :class="getClass(dimensions)">
              <header>
                <span class="close-popup">
                  <font-awesome-icon icon="times"/>
                </span>
                <div>
                  <span class="name">{{event.eventName}}</span>
                  <span class="chapter">{{event.chapter && event.chapter.chapterName}}</span>
                </div>
              </header>
              <main>
                <span class="date"><font-awesome-icon :icon="['far','calendar-alt']" />{{timePeriodFormat(normalizedEventTime(event))}}</span>
                <span class="location"><font-awesome-icon :icon="['far','map-marker-alt']" />{{event.address1?event.address1:'No location has been provided'}}</span>
                <span v-if="event.address2" class="location"><font-awesome-icon :icon="['far','map-marker-alt']" />{{event.address2}}</span>
                <RouterLink :to="`/events/${event.eventId}/dashboard`" class="button-popup">
                  <UIButton class="manage">Manage Event</UIButton>
                </RouterLink>
                <RouterLink v-if="event.seriesId && !isSeries" :to="`/events/series/${event.seriesId}/summary`" class="button-popup">
                  <UIButton class="manage-series">Manage Series</UIButton>
                </RouterLink>
                <UIButton v-if="isSeries" class="manage-series" @click="cancelEvent">Cancel Event</UIButton>
              </main>
            </div>
          </template>
        </Dimensions>
    </div>
  </Popover>
</template>

<script>
import UIButton from 'shared/ui/buttons/Button.vue';
import { RouterLink } from 'vue-component-router';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { library } from '@fortawesome/fontawesome-svg-core';
import Popover from 'vue-popover';
import { getEventPopoverPosition, normalizedEventTime, getLocalTimeDate } from './util';

library.add(faMapMarkerAlt, faCalendarAlt, faTimes)

const Dimensions = {
  mounted () {
    this.dimensions = this.$el.getBoundingClientRect();
  },
  data () {
    return {
      dimensions: null
    }
  },
  render (h) {
    const $vnodes = this.$scopedSlots.default({
      dimensions: this.dimensions
    });

    return $vnodes[0];
  }
}
export default {
  name: 'Event',
  props: ['event', 'multiday', 'inMenu', 'isSeries', 'cancelEvent'],
  components: { UIButton, RouterLink, Popover, Dimensions },
  methods: {
    getLocalTimeDate,
    normalizedEventTime,
    timeFormat (time) {
      if (parseInt(time.split(':')[0]) > 12) {
        return `${(parseInt(time.split(':')[0])-12)}:${time.split(':')[1]}`;
      }
      else {
        if (time.charAt(0) === '0') time = time.slice(1);
        return time;
      }
    },
    timePeriodFormat (time) {
      let start = time.split(' - ')[0];
      let end = time.split(' - ')[1];
      if (parseInt(start.split(':')[0]) > 12) {
        start = `${(parseInt(start.split(':')[0])-12)}:${start.split(':')[1]}`;
      }
      if (parseInt(end.split(':')[0]) > 12) {
        end = `${(parseInt(end.split(':')[0])-12)}:${end.split(':')[1]}`;
      }
      return `${start} - ${end}`;

    },
    formatHour (time) {
      const date = new Date(time);

      let hours = date.getHours();
      let min = date.getMinutes();
      let ampm = hours > 11 ? 'pm' : 'am';

      hours = hours > 12 ? hours - 12 : hours;

      if (min > 0) {
        if (min < 10) {
          min = ':0' + min
        } else {
          min = ':' + min
        }
      } else {
        min = ''
      }

      let eventTime = hours + '' + min + '' + ampm;

      return eventTime
    },
    getClass (dimensions) {
      if (!dimensions) return 'under';

      const { right, bottom } = dimensions;

      const position = [];

      if ((dimensions.x + dimensions.width ) > window.innerWidth) {
        position.push('left');
      }
      if ((bottom + dimensions.height) > window.innerHeight) {
        position.push('over');
      }

      return position.length > 0 ? position.join(' ') : 'under';
    },
    getPosition (dimensions) {
      if (!dimensions) return {};

      const eventPos = this.$el.getBoundingClientRect();

      const klass = this.getClass(dimensions);

      return getEventPopoverPosition(eventPos, dimensions, klass)
    }
  }
}
</script>

<style>
  .agenda .popover.event-popover .popover__face {
    z-index: unset;
  }
  .popover.event-popover .popover__face {
    height: 100%;
    width: 100%;
    z-index: 1;
  }
</style>
<style scoped lang="scss">
.event {
  overflow: hidden;
  display: flex;
  color: #444;
  font-weight: 400;
}
.mini-time {
  margin-right: 5px;
  color: #666;
  font-weight: 200;
}
.event-list-item .mini-time {
    color: #999;
    font-size: 10px;
    font-weight: 200;
    white-space: nowrap;
    min-width: 45px;

    &:before {
      content:"";
      display: inline-block;
      border-radius: 50%;
      min-width: 6px;
      height: 6px;
      background: #ec7d00;
      margin-right: 5px;
    }
}
.event-btn {
  padding:0 7px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  display: flex;
  align-items: center;
  height: 100%;

  &:before {
    content:"";
    display: inline-block;
    border-radius: 50%;
    min-width: 6px;
    height: 6px;
    background: #ec7d00;
    margin-right: 5px;
  }
}
.event.multiday {
    background: #fdca98;
    border-bottom: 1px solid #f9ba7b;

  .event-btn:before {
    display: none;
  }
  .mini-time {
    color: #946d42;
  }
}

.event-popup {
    width: 320px;
    position: fixed;
    background: white;
    box-shadow: 2px 2px 15px rgba(0,0,0,.3);
    z-index: 1000;
}

.event-popup.under:after {
  content: "";
  position: absolute;
  top: -20px;
  border: 10px solid transparent;
  border-bottom-color: #ea7c1d;
  left: 50%;
  margin-left: -10px;
}

.event-popup.over:after {
  content: "";
  position: absolute;
  top: 100%;
  border: 10px solid transparent;
  border-top-color: white;
  left: 50%;
  margin-left: -10px;
}

.event-popup.left:after {
  content: "";
  position: absolute;
  top: 25%;
  border: 10px solid transparent;
  border-left-color: #ea7c1d;
  left: 100%;
  width: 0;
  height: 0;
}

.event-popup.over.left:after {
  content: "";
  position: absolute;
  top: 75%;
  border: 10px solid transparent;
  border-left-color: white;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.event-popup header {
  background-image: url('https://res.cloudinary.com/ncsy/image/upload/v1520403255/iceburg/profile-header.jpg');
  background-repeat: no-repeat;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  position: relative;
}
.name {
    display: block;
    color: white;
    font-weight: 600;
    font-size: 16px;
}
.chapter {
    display: block;
    font-weight: 600;
    color: #ffd5a3;
}
.img {
  width: 70px;
  height: 70px;
  display: block;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
}
.event-popup main {
    padding: 0 20px 20px;
}
.date,
.location {
  display: block;
  margin-bottom: 5px;
}
.date .svg-inline--fa,
.location .svg-inline--fa {
    margin-right: 7px;
    color: #999;
    font-size: 12px;
}
.manage {
  margin-top: 20px;
}
.manage-series {
  background: white;
  color: #ea7d1c;
  border: 1px solid #ea7d1c;
}
.manage-series:hover {
  background: white !important;
  color: #FE9940;
  border: 1px solid #FE9940;
}
.close-popup {
    color: white;
    position: absolute;
    top: 5px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    right: 10px;
}

</style>
