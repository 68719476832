

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import UISelect from 'shared/ui/forms/FancySelect';
import { getCurrentSeason } from 'shared/util';

@Component({
  components: {
    UISelect
  }
})
export default class SeasonPicker extends Vue {
  @Prop() value!: string;

  get seasons() {
      const currentSeason = Number(getCurrentSeason().split('-')[0]);
      return range(2011, currentSeason + 1).map(x => `${x}-${x + 1}`).reverse();
  }
}
