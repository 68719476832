
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FilterOption, FilterOptionGroup } from './FilterOption';
import { toggleInArray } from 'shared/util';

@Component({
	name: 'FilterTypeDisplay',
	components:{
	}
})
export default class extends Vue {

	@Prop()
	selectedFilters!: FilterOption[];

	@Prop()
	filterGroup!: FilterOptionGroup;

	get activeKeys() {
		return this.selectedFilters.map(f => f.key);
	}

	isActive (filter:FilterOption) {
		return this.activeKeys.includes(filter.key);
	}

	select (filter: FilterOption) {
		const selectedFilters = [...this.selectedFilters]
		const f = selectedFilters.find(x => x.key === filter.key) || filter;

		toggleInArray(selectedFilters, f);
		this.$emit('selectionMade', selectedFilters);
	}

	selectAll () {
		const selectedFilters = [...this.selectedFilters]

		for (const f of this.filterGroup.options) {
			if (!this.isActive(f)) {
				selectedFilters.push(f);
			}
		}
		this.$emit('selectionMade', selectedFilters);
	}

	clearAll () {
		const selectedFilters = [...this.selectedFilters]
		
		selectedFilters.splice(0);
		this.$emit('selectionMade', selectedFilters);
	}
}
