
  import Vue from "vue";
  import SimpleForm, { Error, Field, SubmitCallbackArgs } from "vue-simpleform";
  import UIButton from "shared/ui/buttons/Button.vue";
  import UIRadio from "shared/ui/forms/Radio.vue";
  import UICounter from "shared/ui/forms/Counter.vue";
  import * as RadioGroup from "shared/radio-group";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
  import UIInput from "shared/ui/forms/Input.vue";
  import UITextarea from "shared/ui/forms/Textarea.vue";
  import { Bus, Props as BusingProps } from "../../types";
import { EditBusInput } from "shared/generated/graphql-types";

  library.add(faTimes);

  type Errors<T> = { [k in keyof T]?: string };

  interface Data {
    localBus: EditBusInput;
  }
  interface Methods {
    validate: (value: EditBusInput) => Errors<EditBusInput>;
    edit: (callbackArg: SubmitCallbackArgs<EditBusInput>) => void;
    close: () => void;
  }
  interface Props {
    bus: Bus;
    editing: boolean;
    editBus: BusingProps["editBus"];
  }

  export default Vue.extend<Data, Methods, {}, Props>({
    name: "EditBus",
    components: {
      UIButton,
      UICounter,
      UIRadio,
      UITextarea,
      ...RadioGroup,
      SimpleForm,
      Error,
      Field,
      UIInput
    },
    props: {
      bus: {},
      editing: {},
      editBus: {}
    },
    data() {
      return {
        localBus: {
          busID: this.bus.busID,
          description: this.bus.description,
          cost: this.bus.cost,
          meetingAddress: this.bus.meetingAddress,
          capacity: this.bus.capacity,
          directionFlag: this.bus.directionFlag,
          time: this.bus.time
        }
      };
    },
    methods: {
      validate(value: EditBusInput) {
        const errors: Errors<EditBusInput> = {};

        if (!value.description || !value.description.trim().length) {
          errors.description = "Description is required";
        }

        if (!value.meetingAddress || !value.meetingAddress.trim().length) {
          errors.meetingAddress = "Meeting Address is required";
        }

        if (value.meetingAddress && value.meetingAddress.trim().length > 255) {
          errors.meetingAddress = 'Meeting Address should be less than 255 symbols';
        }

        return errors;
      },
      close() {
        this.$emit("update:editing", false);
      },
      async edit(callbackArg: SubmitCallbackArgs<EditBusInput>) {
        if ("errors" in callbackArg) return;
        this.close();

        await this.editBus(
          {
            ...callbackArg.values,
            cost:
              callbackArg.values.cost || callbackArg.values.cost === 0
                ? +callbackArg.values.cost
                : null,
            capacity:
              callbackArg.values.capacity || callbackArg.values.capacity === 0
                ? +callbackArg.values.capacity
                : null
          },
          this.bus
        );
      }
    }
  });
