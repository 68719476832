import { MutateResult } from '@vue/apollo-composable';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { computed, CreateElement } from 'vue';
import AddRegistrationForm from './AddRegistrationForm.vue';
import { compose, withData } from 'vue-compose';
import gql from 'graphql-tag';
import { isValueless } from 'shared/util';
import {
  AddParentToFamilyInput,
  AddRegistrationMutation,
  ConnectParentToTeenMutation,
  ConnectParentToTeenMutationVariables,
  EventWithRegistrationsDocument,
  EventWithRegistrationsQuery,
  GetEventForEditQuery,
  GetEventTicketsForAddRegistrationQuery,
  NotAvailableReason,
  RegisterTeenInput,
  useAddRegistrationMutation,
  useGetEventTicketsForAddRegistrationQuery,
  useGetTeenProfileQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';
import { toCompactTeen } from 'shared/components/TeenProfile/utils';
import { TeenViewModel } from 'shared/components/TeenProfile/types';
import { addParentToFamilyEnhancer } from 'shared/components/AddParentToFamily';
import { connectParentToTeenEnhancer } from 'shared/enhancers/connectParentToTeenEnhancer';
import { EntityUnionViewModel } from 'shared/components/EmailInput/shared/EntityUnionViewModel';

type EventTicket = ArrayElement<GetEventForEditQuery['event']['EventTickets']>;
type EventTickets = GetEventTicketsForAddRegistrationQuery['event']['EventTickets'];

interface Props {
  eventId: number;
  regionId: number;
  selectedTeenId: number | null;
  selectedTeenLoading: boolean;
  selectedTeen: TeenViewModel | null;
}

@Component
class AddRegistrationContainer extends Vue {
  @Prop() registeringTeen!: boolean;
  @Prop() registerTeen!: (input: RegisterTeenInput) => MutateResult<AddRegistrationMutation>;
  @Prop() eventId!: number;
  @Prop() regionId!: number;
  @Prop() eventTickets!: EventTickets;
  @Prop() selectedTeenId!: number | null;
  @Prop() selectedTeenLoading!: boolean;
  @Prop() selectedTeen!: TeenViewModel | null;
  @Prop() addingParentToFamily!: boolean;
  @Prop() addParentToFamily!: (
    args: AddParentToFamilyInput
  ) => MutateResult<ConnectParentToTeenMutation>;
  @Prop() connectingParentToTeen!: boolean;
  @Prop() connectParentToTeen!: (
    args: ConnectParentToTeenMutationVariables,
    optimisticResponse: EntityUnionViewModel
  ) => MutateResult<ConnectParentToTeenMutation>;

  overLimit = false;
  errorRegistering = false;

  render(h: CreateElement) {
    return h(AddRegistrationForm, {
      on: {
        ...this.$listeners
      },
      props: {
        ...this.$props,
        overLimit: this.overLimit,
        errorRegistering: this.errorRegistering,
        registeringTeen: this.registeringTeen,
        registerTeen: async (
          teenID: number,
          eventID: number,
          eventTicketID: number,
          SuppressEmail: boolean,
          force?: boolean
        ) => {
          this.overLimit = false;
          this.errorRegistering = false;

          const result = await this.registerTeen({
            teenID,
            eventID,
            eventTicketID,
            SuppressEmail,
            force
          });

          if (result?.data?.registerTeen!.notAvailableReason === NotAvailableReason.OverLimit) {
            this.overLimit = true;
          } else if (!isValueless(result?.data?.registerTeen!.notAvailableReason)) {
            this.errorRegistering = true;
          }

          return result;
        }
      }
    });
  }
}

interface AddRegistrationProps {
  registerTeen: (input: RegisterTeenInput) => any;
  registeringTeen: boolean;
}

interface EventTicketProps {
  eventTickets: EventTickets;
}

const getTicketsEnhancer = wrapComponent<Props, EventTicketProps>((props) => {
  const { result } = useGetEventTicketsForAddRegistrationQuery(
    computed(() => ({ eventID: props.eventId }))
  );

  return computed(() => ({
    eventTickets: result.value?.event.EventTickets || []
  }));
});

const getTeenProfileEnhancer = wrapComponent<Props, Pick<Props, 'selectedTeen' | 'selectedTeenLoading'>>((props) => {
  const { loading, result } = useGetTeenProfileQuery(
    computed(() => ({ personID: props.selectedTeenId! })),
    { enabled: computed(() => !!props.selectedTeenId) }
  );

  return computed(() => ({
    selectedTeen: result.value?.singleTeen ? toCompactTeen(result.value.singleTeen) : null,
    selectedTeenLoading: loading.value
  }));
});

const addEventRegistrationEnhancer = wrapComponent<Props, AddRegistrationProps>((props) => {
  const { loading, mutate } = useAddRegistrationMutation();

  return computed(() => ({
    registerTeen: (input) =>
      mutate(
        { input },
        {
          update(client, result) {
            const data = client.readQuery<EventWithRegistrationsQuery>({
              query: EventWithRegistrationsDocument,
              variables: {
                eventID: props.eventId
              }
            });

            if (
              data &&
              result.data &&
              result.data.registerTeen &&
              result.data.registerTeen.registration !== null
            ) {
              const registrations = data.event.Registrations.slice();
              registrations.push(result.data.registerTeen.registration);

              client.writeQuery<EventWithRegistrationsQuery>({
                query: EventWithRegistrationsDocument,
                variables: {
                  eventID: props.eventId
                },
                data: {
                  event: {
                    ...data.event,
                    Registrations: registrations
                  }
                }
              });
            }

            const eventForEditDataFragment = client.readFragment<{ EventTickets: EventTicket[] }>({
              id: `${props.eventId}Event`,
              fragment: gql`
                fragment Event on Event {
                  EventTickets {
                    EventTicketID
                    registrationsCount
                  }
                }
              `
            });

            if (
              eventForEditDataFragment &&
              eventForEditDataFragment.EventTickets.length &&
              result.data &&
              result.data.registerTeen &&
              result.data.registerTeen.registration !== null
            ) {
              const eventTickets = eventForEditDataFragment.EventTickets.slice();
              const ticketIndex = eventTickets.findIndex(
                (e) => e.EventTicketID === input.eventTicketID
              );
              if (ticketIndex > -1) {
                eventTickets[ticketIndex] = {
                  ...eventTickets[ticketIndex],
                  registrationsCount:
                    (eventForEditDataFragment.EventTickets[ticketIndex].registrationsCount || 0) + 1
                };
              }

              client.writeFragment<{ EventTickets: EventTicket[] }>({
                id: `${props.eventId}Event`,
                fragment: gql`
                  fragment Event on Event {
                    EventTickets {
                      EventTicketID
                      registrationsCount
                    }
                  }
                `,
                data: {
                  EventTickets: eventTickets
                }
              });
            }
          }
        }
      ),
    registeringTeen: loading.value
  }));
});

export default compose(
  withData({ selectedTeenId: { initialValue: null } }),
  addParentToFamilyEnhancer,
  connectParentToTeenEnhancer,
  addEventRegistrationEnhancer,
  getTicketsEnhancer,
  getTeenProfileEnhancer
)(AddRegistrationContainer);
