
import UIInput from 'shared/ui/forms/Input.vue'
import { UIFormRow } from 'shared/ui/forms/elements';
import UIButton from 'shared/ui/buttons/Button.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { RouterLink } from 'vue-component-router';

library.add(faPencil,faHome,faTimes)

export default {
	components: {  UIFormRow, AutoSaveField, UIInput, UIButton, RouterLink },
	data () {
		return {
			editing: false
		}
	},
	props: [
		'housingRequest1',
		'housingRequest2',
		'housingRequest3',
		'housingRequest4',
    'updateHousingRequest',
    'requestedBy',
	]
}
