
import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import Loading from 'shared/components/Loading.vue';
import Avatar from 'shared/components/avatar';
import ProfilePhoto from 'shared/components/ProfilePhoto/ProfilePhoto.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import UIPassword from 'shared/ui/forms/Password.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faMale } from '@fortawesome/pro-solid-svg-icons/faMale';
import { faFemale } from '@fortawesome/pro-solid-svg-icons/faFemale';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo';
import { faSchool } from '@fortawesome/pro-solid-svg-icons/faSchool';
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase';
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { Data, Methods, Computed, Props } from './types';
import UISwitch from 'shared/ui/forms/Switch.vue';
import copy from 'copy-text-to-clipboard';
import { RouterLink } from 'vue-component-router';
import UIInput from 'shared/ui/forms/Input.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import Address from 'shared/components/Address/Address.vue';
import SchoolLookup from 'shared/components/SchoolLookup';
import UISelect from 'shared/ui/forms/FancySelect';
import UIButton from 'shared/ui/buttons/Button.vue';
import EmailInput from 'shared/components/EmailInput';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import RequiresPermission from 'shared/components/RequiresPermission';
import { showParentNotificationAction } from './utils';
import config from '../../../config';
import FileSaver from 'file-saver';
import ClipboardCopy from 'shared/ui/clipboard/clipboard.vue';
import isNull from 'lodash/isNull';
import FileSelector from 'shared/components/FileSelector';
import AddInteraction from 'shared/components/AddInteraction';
import QuickAddParent from 'shared/components/QuickAddParent';
import {
  validateEmail,
  phoneInternational,
  dateFormat,
  getFullName,
  validateName,
  validateNumber,
  getGrade,
  splitName,
  getCurrentAddress,
  phoneFormat,
  graduationYears,
  asUTCWithZerotime,
  b64toBlob
} from 'shared/util';
import FamilySelector from 'shared/components/FamilySelector';
import { getParentsArray } from 'shared/util/families';
import { EntityUnionEnum, Gender, GetDigitalWaiverLinkDocument, GetDigitalWaiverLinkQuery, GetLiabilityFileDocument, GetLiabilityFileQuery, GetPermissionsLinkDocument, GetPermissionsLinkQuery, PermissionEnum, UpdateTeenField } from 'shared/generated/graphql-types';
import { useApolloClient } from '@vue/apollo-composable';

library.add(faUserFriends, faDatabase, faImages, faPhone, faEnvelope, faMobileAlt, faGift, faHome, faMale, faFemale, faKey, faRedo, faSchool, faGraduationCap, faPlusCircle, faDownload);

type thisType = CombinedVueInstance<Vue, Data, Methods, Computed, Props>;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "TeenProfile",
  components: {
    ProfilePhoto,
    Loading,
    Avatar,
    UIInput,
    UITextarea,
    ClipboardCopy,
    Modal,
    UIPassword,
    ScrollablePanel,
    RequiresPermission,
    PhoneInput,
    UICheckbox,
    EmailInput,
    UIButton,
    SchoolLookup,
    Address,
    AutoSaveField,
    UISelect,
    UISwitch,
    FilterGroup,
    RouterLink,
    FileSelector,
    AddInteraction,
    FamilySelector,
    QuickAddParent
  },
  directives: {
    fullheight
  },
  props: {
    teen: {},
    connectParentToTeen: {},
    connectingParentToTeen: {},
    showPasswordModal: {},
    me: {},
    personID: {},
    statistics: {},
    uploadTeenImageProfile: {},
    uploadLiabilityConsent: {},
    uploadingLiabilityConsent: {},
    updateTeen: {},
    changePassword: {},
    updatingTeen: {},
    changingPassword: {},
    regionId: { type: Number },
    markTeenConsent: {},
    deleteProfileImage: {},
    notifyParents: {},
    sendingParentsNotification: {},
    markingTeenConsent: {},
    deletingProfileImage: {},
    country: {},
    loadingTeenProfile: {},
    leadId: {},
    eventId: {},
    eventDate: {},
    eventWaiverRequired: { default: false },
    eventTimezone: {},
    registrationId: {},
    attendanceAppLinkForTeen: {},
    markFamilyAsPrimary: {},
    markFamilyAsPrimaryRunning: {},
    markingFamilyAsPrimary: {},
    addParentToFamily: {},
    addingParentToFamily: {}
  },
  data() {
    return {
      PermissionEnum,
      Gender,
      EntityUnionEnum,
      downloading: false,
      editingMode: false,
      signedByGuardian: !!!(this.regionIsAlumni || this.teen && this.teen.isOver18),
      uploadedImage: null,
      uploading: false,
      updateTeenField: UpdateTeenField,
      teenCopy: Object.assign({}, this.teen),
      password: null,
      showDataOptOutConfirmation: false,
      showParentalConsentNotificationModal: false,
      showUploadLiabilityConsentModal: false,
      uploadedLiabilityConsentFile: null,
      showMediaConsentConfirmation: false,
      permissionsLinks: [],
      loadingPermissionsLinks: false,
      showDeleteProfileImageModal: false,
      panelView: 'summary',
      selectedFamily: null,
      showAddFather: false,
      showAddMother: false
    };
  },
  computed: {
    liabilitySignedProp () {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()

      const isInHighSchool = currentMonth < 7
        ? this.teen.graduationYear && this.teen.graduationYear >= currentYear
        : this.teen.graduationYear && this.teen.graduationYear >= currentYear + 1;

      if (this.teen.isOver18 && this.teen.liabilitySigned) {
        return true
      } else if (this.teen.isOver18 && isInHighSchool && !this.teen.liabilitySigned) {
        return this.teen.liabilityGuardianSigned
      } else {
        const condition = this.teen.graduationYear ? !isInHighSchool : this.teen.isOver18
        return (this.regionIsAlumni || condition) ? this.teen.liabilitySigned : this.teen.liabilityGuardianSigned
      }
    },
    regionIsAlumni () {
      return this.regionId === config.alumniRegionId
    },
    showPermissionLinks() {
        if (!this.liabilitySignedProp) {
          return true
        }
        else if (!this.teen.isOver18 && (!this.teen.mediaConsentSigned || !this.teen.dataConsentSigned )){
          return true;
        }
        else if(this.showParentNotificationAction){
          return true;
        }
        return false;
    },
    isTeenHaveParentsInfo() {
      return Boolean(this.teen.mother || this.teen.father);
    },
    profileImage() {
      if (this.uploadedImage) {
        return `data:image/png;base64, ${this.uploadedImage}`;
      }
      else {
        this.uploading = true;
        if (this.teen.thumbnail) {
          this.uploading = false;
          return this.teen.thumbnail;
        }
        return '';
      }
    },
    currency() {
      return this.teen.currencySymbol;
    },
    totalOutstanding() {
      return +(this.statistics && this.statistics[0] || {}).value! || 0;
    },
    totalEvents() {
      return +(this.statistics && this.statistics[1] || {}).value! || 0;
    },
    totalInteractions() {
      return this.teen.interactions.length;
    },
    graduationYears() {
      return graduationYears().map(x => x.grade);
    },
    currentAdvisorRegion() {
      return this.me && this.regionId &&
        this.me.AdvisorRegions.find(a => a.Region.regionId === this.regionId) || {
          claims: [],
          AdvisorRegionId: 0,
          Region: {
            regionName: '',
            regionId: 0,
            country: '',
            __typename: 'Region'
          },
          active: false,
          Role: null,
          __typename: 'AdvisorRegion'
        };
    },
    showParentNotificationAction() {
      return showParentNotificationAction(this.teen);
    },
    nonPrimaryFamilies() {
      return (this.teen.primaryFamily ? this.teen.families.filter(f => f.id !== this.teen.primaryFamily!.id) : this.teen.families) || [];
    }
  },
  methods: {
    openMarkFamilyAsPrimaryModal(f) {
      this.selectedFamily = f;
      this.$emit('markingFamilyAsPrimary', true);
    },
    async markFamilyAsPrimaryHandler() {
      if(this.selectedFamily) {
        await this.markFamilyAsPrimary(this.personID, this.selectedFamily.id, this.teenCopy.original);
        this.$emit('markingFamilyAsPrimary', false);
      }
    },
    dateFormat,
    getFullName,
    validateName,
    validateNumber,
    getGrade,
    getCurrentAddress,
    asUTCWithZerotime,
    validateEmail,
    phoneInternational,
    isNull,
    async deleteProfileImageHandler () {
      await this.deleteProfileImage(this.personID, this.teenCopy);
      this.uploadedImage = '';
      this.showDeleteProfileImageModal = false;
    },
    async getLiabilityFile () {
      const { client } = useApolloClient();
      this.downloading = true;
      const { data: { liabilityFile: { data, contentType, fileName } } } = await client.query<GetLiabilityFileQuery>({
        query: GetLiabilityFileDocument,
        variables: {
          personId: this.personID
        }
      });
      this.downloading = false;
      FileSaver.saveAs(b64toBlob(data!, contentType!, `data:${contentType};base64,`), fileName!);
    },
    async uploadLiabilityConsentHandler () {
      const file =  this.uploadedLiabilityConsentFile;
      if (!file) {
        return;
      }
      try {
        const results: any = await this.getBase64(file);
        await this.uploadLiabilityConsent({ personId: this.personID, signedByGuardian: this.signedByGuardian, file: results.base64, staffId: this.me.staffID })
        this.showUploadLiabilityConsentModal = false;
        this.uploadedLiabilityConsentFile = null;
      }
      catch (e) {
        console.log(e)
        this.showUploadLiabilityConsentModal = false;
        this.uploadedLiabilityConsentFile = null;
        throw e
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader: any = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({ file, base64: reader.result!.split(',')[1] });
          };
          reader.onerror = (error: any) => {
            reject(error)
            console.error('Error: ', error);
          };
        }
        catch (e) {
          console.log(e)
          reject(e)
        }
      })
    },
    async connectParentToTeenHandler (parent) {
      if (parent.id) await this.connectParentToTeen({ parentId: parent.id, childId: this.personID }, parent);
    },
    async passwordHandler() {
      if (!this.password) return;
      await this.changePassword({
        password: this.password,
        personId: this.personID
      });
      this.$emit('showPasswordModal', false);
      this.password = null;
    },
    getGenderColor(gender: number) {
      if (!gender) {
        return '#00bcd4';
      } else {
        return '#cd8ece';
      }
    },
    phoneFormat(phoneNumber: string) {
      if (!phoneNumber) return;
      return phoneFormat(phoneNumber, this.teen.country || '');
    },
    splitName(name: string) {
      if (!name) {
        return { firstName: '', lastName: '' };
      }
      return splitName(name);
    },
    copyToClipboard(value: string) {
      if (!value || this.editingMode) return;
      copy(value);
    },
    async update(args: SaveEventArgs<{ fieldName: UpdateTeenField, value: string }>) {
      // TODO: dont save if there is no value atleast until this command will support that - https://app.asana.com/0/82580073031913/1134617948128938.
      if (!args.transformed.value) return;
      await this.updateTeen({
        personId: this.personID,
        fieldName: args.transformed.fieldName,
        value: String(args.transformed.value),
        viewModel: this.teenCopy
      });
    },
    async updateParentField (args: SaveEventArgs<{ fieldName: UpdateTeenField, value: string, parentId?: number }>) {
      const {parentId, value} = args.transformed;
      if(!parentId) {
        return;
      }
      args.transformed.value = JSON.stringify({ parentId, value});
      return await this.update(args);
    },
    async uploadedImageHandler(image: any[]) {
      const getImage = () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            this.uploadedImage = image && image.length ? image[0].base64 : null;
            if (!this.uploadedImage) reject('oyshh');
            resolve('yey');
          }, 2000);
        });
      };

      const getImageResults = await getImage();
      if (this.uploadedImage && getImageResults) {
        await this.uploadTeenImageProfile({
          personId: this.personID,
          image: this.uploadedImage
        });
      }
      this.uploading = false;
    },
    async sendParentalConsentNotification() {
      this.showParentalConsentNotificationModal = false;
      await this.notifyParents({ teenId: this.teen.personId, originSite: 0 });
    },
    async handleAssociate(parent) {
      if (!this.teen.fatherId && !this.teen.motherId) {
        await this.connectParentToTeenHandler(parent)
      } else {
        await this.addParentToFamily({
          familyId: this.teen.primaryFamily?.id || this.teen.families[0].id,
          personId: parent.id,
          gender: parent.gender
        })
      }
      this.showAddFather = false;
      this.showAddMother = false;
    },
    async getPermissionsLinkHandler() {
      const { client } = useApolloClient();
      if (this.teen) {
        this.teenCopy = Object.assign({}, this.teen);
        this.loadingPermissionsLinks = true;
        if (this.regionId === config.alumniRegionId || this.teen.isOver18) {
          const link = this.registrationId && this.eventWaiverRequired
            ? (await client.query<GetDigitalWaiverLinkQuery>({
                query: GetDigitalWaiverLinkDocument,
                variables: {
                  registrationId: this.registrationId,
                  personId: this.teen.personId
                }
              })).data.getDigitalWaiverLink
            : (await client.query<GetPermissionsLinkQuery>({
                query: GetPermissionsLinkDocument,
                variables: {
                  personId: this.teen.personId,
                  regionId: this.regionId
                }
              })).data.getPermissionsLink;

          this.permissionsLinks = [{
            text: `Permissions link for ${this.teen.fullName}`,
            link
          }];
        } else {
          const parents = getParentsArray(this.teen.original.Person);
          if (parents.length){
            this.loadingPermissionsLinks = true;
            this.permissionsLinks = await Promise.all(parents.map(
              async p => {
                return {
                  text: `Permissions link for ${p.name} (${p.gender === Gender.Male ? 'Father' : 'Mother'})`,
                  link: this.registrationId && this.eventWaiverRequired
                    ? (await client.query<GetDigitalWaiverLinkQuery>({
                      query: GetDigitalWaiverLinkDocument,
                      variables: {
                        registrationId: this.registrationId,
                        personId: p.parentId
                      }
                    })).data.getDigitalWaiverLink
                    : (await client.query<GetPermissionsLinkQuery>({
                      query: GetPermissionsLinkDocument,
                      variables: {
                        personId: p.parentId,
                        regionId: this.regionId
                      }
                    })).data.getPermissionsLink
                };
              }
            ));
          }
        }
        this.loadingPermissionsLinks = false;
      }
    }
  },
  mounted() {
    this.getPermissionsLinkHandler()
  }, 
  watch: {
    teen: {
      deep: true,
      async handler(this: thisType) {
        this.getPermissionsLinkHandler()
      }
    }
  }
});
