import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';

@Component
export default class Collapsible extends Vue {
	@Prop({
		default: undefined
	}) open!: boolean;

	state = {
		open: true
	};

	controlled: boolean | undefined = undefined;

	toggle() {
		this.state.open = !this.state.open
		if (this.controlled) {
      		this.$emit('update:open', this.state.open)
    	}
  }

  created () {
	  this.controlled = typeof this.open != 'undefined'
	  if (this.controlled) {
		  this.state.open = this.open;
	}

    this.open !== undefined ? this.open : true
  }
  @Watch('open')
  onOpenChange(val: boolean) {
    if (this.controlled) {
      this.state.open = this.open
    }
  }

  render (h: CreateElement) {
    const result = this.$scopedSlots.default && this.$scopedSlots.default({
      open: this.state.open,
      toggle: this.toggle
    })

    return !result || typeof result === 'string' || result.length > 0
      ? h('div', result)
      : result[0]
  }
}
