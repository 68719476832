
import { Vue, Component, Prop } from 'vue-property-decorator';
import { shortDate } from 'shared/util';
import Loading from 'shared/components/Loading.vue';
import { EventTicket } from '../../types';

@Component({
	components: {
    Loading
  },
  methods: {
    shortDate
  }
})
export default class Tickets extends Vue{
    @Prop() tickets!: EventTicket[];
    @Prop() timezone!: string;
    currentTicket: number = 0;
};
