
import Vue from 'vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Loading from 'shared/components/Loading.vue';
import NoData from 'shared/components/NoData.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { RouterLink } from 'vue-component-router';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import AddNewList from './AddNewList';
import UISelect from 'shared/ui/forms/FancySelect';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import Avatar from 'shared/components/avatar';
import TeenProfile from 'shared/components/TeenProfile';
import InteractionForm from 'shared/components/InteractionForm';
import TeenLookup from 'shared/components/TeenLookup';
import ChapterLookup from 'shared/components/ChapterLookup';
import RequestTeenSheet from 'shared/components/TeenSheet';
import GoalLookup from 'shared/components/GoalLookup';
import UIButton from 'shared/ui/buttons/Button.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import Popover from 'shared/ui/popover/Popover.vue';
import { Data, Methods, Computed, Props } from './types';

library.add(faUserPlus, faEllipsisH);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TeenLists',
  props: {
    filters: {},
    setFilter: {},
    clearFilters: {},
    currentPage: {},
    limit: {},
    me: {},
    regionId: {},
    teenList: {},
    teenListLoading: {},
    teenListsLoading: {},
    teenLists: {},
    teenListsTotal: {},
    teenListId: {},
    addToTeenList: {},
    addingToTeenList: {},
    markAsLead: {},
    removeFromTeenList: {},
    removingFromTeenList: {}
  },
  data() {
    return {
      orderBy: {
        column: 'name',
        ascending: true
      },
      addingList: false,
      addingNewTeen: false,
      showMarkAsLeadModal: false,
      addingNewInteraction: false,
      profileClose: true,
      teenSelected: null,
      addingTeenFailed: false,
      markingAsLead: false,
      teenIdForModal: null,
      showRemoveTeenModal: false,
      teenIdToRemove: null
    };
  },
  components: {
    Loading,
    Popover,
    TeenLookup,
    ChapterLookup,
    RequestTeenSheet,
    InteractionForm,
    NoData,
    Avatar,
    TeenProfile,
    FilterGroup,
    PanelsGrid,
    BorderedList,
    ScrollablePanel,
    RouterLink,
    PanelsSidebar,
    UISearch,
    UIButton,
    Modal,
    UISelect,
    AddNewList,
    UICheckbox,
    GoalLookup
  },
  directives: {
    fullheight,
  },
  computed: {
    listSelectedId() {
      return this.teenListId ? Number(this.teenListId) : null;
    },
    mappedTeens() {
      return this.teenList && this.teenList.Teens
        ? this.teenList.Teens.map((teen) => {
            return {
              ...teen,
              Teen: teen.Teen
                ? {
                    ...teen.Teen,
                    Interactions: teen.Teen.Interactions.slice().sort((a, b) => {
                      return new Date(a.date) < new Date(b.date) ? 1 : -1;
                    })
                  }
                : null
            }
          })
          .sort((a, b) => {
            const isDesc = this.orderBy.ascending ? 1 : -1
            if (this.orderBy.column === 'name') {
              return a.lastName! <= b.lastName! ? -1 * isDesc : 1 * isDesc
            } else if (this.orderBy.column === 'chapter') {
              return a.Teen?.Chapter?.chapterName! <= b.Teen?.Chapter?.chapterName! ? -1 * isDesc : 1 * isDesc
            } else if (this.orderBy.column === 'seasonEvents') {
              return Number(a.Teen?.statistics[2]?.value) <= Number(b.Teen?.statistics[2].value) ? -1 * isDesc : 1 * isDesc
            } else if (this.orderBy.column === 'totalEvents') {
              return Number(a.Teen?.statistics[1]?.value) <= Number(b.Teen?.statistics[1].value) ? -1 * isDesc : 1 * isDesc
            }
            // lastinteraction
            return (a.Teen?.Interactions[0] && a.Teen?.Interactions[0].date || 0) <= (b.Teen?.Interactions[0] && b.Teen?.Interactions[0].date || 0)
              ? -1 * isDesc
              : 1 * isDesc
          })
        : [];
    },
    teenIds() {
      return (this.teenList && this.teenList.Teens || []).map((t) => t.personID);
    }
  },
  methods: {
    closeProfile() {
      this.profileClose === true
        ? (this.profileClose = false)
        : (this.profileClose = true);
    },
    getGenderColor(gender) {
      return gender ? "#cd8ece" : "#00bcd4";
    },
    async addToTeenListHandler(personId) {
      await this.addToTeenList(this.listSelectedId as number, personId);
    },
    async quickAddToTeenListHandler(teen) {
      // TODO fix typings of TeenLookup...
      try {
        await this.addToTeenList(this.listSelectedId as number, teen.personID);
      } catch (e) {
        this.onAddingTeenFailed(true);
      }
    },
    async markAsLeadHandler(goal) {
      try {
        this.markingAsLead = true;
        await this.markAsLead(this.teenIdForModal as number, goal.id);
      } catch (e) {
        this.addingTeenFailed = true;
      }
      this.markingAsLead = false;
      this.showMarkAsLeadModal = false;
    },
    onAddingTeenFailed(flag) {
      this.addingTeenFailed = flag;
    },
    openInteractionModal(personId) {
      this.teenIdForModal = personId;
      this.addingNewInteraction = true;
    },
    closeInteractionModal() {
      this.teenIdForModal = null;
      this.addingNewInteraction = false;
    },
    openGoalLookupModal(personId) {
      this.teenIdForModal = personId;
      this.showMarkAsLeadModal = true;
    },
    closeGoalLookupModal() {
      this.teenIdForModal = null;
      this.showMarkAsLeadModal = false;
    },
    toggleRemoveTeenModal(teenId) {
      this.showRemoveTeenModal = !!teenId;
      this.teenIdToRemove = teenId;
    },
    async removeTeenHandler() {
      await this.removeFromTeenList(this.listSelectedId!, this.teenIdToRemove!);
      this.toggleRemoveTeenModal(null);
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    },
    setOrderBy(column) {
      this.orderBy.ascending = this.orderBy.column === column ? !this.orderBy.ascending : true
      this.orderBy.column = column
    }
  }
});
