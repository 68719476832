

	import { Vue, Prop, Watch, Component } from "vue-property-decorator";
  import ReportForm from 'shared/components/ReportForm';
  import Loading from 'shared/components/Loading.vue';
  import { Report } from '../types';
	import ReportItem from 'shared/components/ReportItem';
  import { Me } from "shared/types";

@Component({
  name: 'ReportsData',
  components: {
    ReportItem,
    ReportForm,
    Loading
  }
})
export default class extends Vue {
  @Prop() reports!: Report[];
  @Prop() starred!: string[];
  @Prop() recent!: string[];
  @Prop() user!: Me;
  @Prop() regionId!: number;
  @Prop() staffId!: number;
  @Prop() fetchingReports!: boolean;
  @Prop() fetchingMetaData!: boolean;
  @Prop() toggleStar!: (reportId: string) => void;

  get filteredReports() {
      if (!this.reports) return [];
      return this.reports.filter((report: Report) => (this.starred || []).includes(report.id) || (this.recent || []).includes(report.id))
  }
};
