import CommunityMembers from './Layout.vue';
import Vue, { computed, VueConstructor } from 'vue';
import { StateChanger } from 'vue-infinite-loading';
import { compose } from 'vue-compose';
import FetchMoreOptions from './shared/FetchMoreOptions';
import { getOptions, normalizeProps } from 'shared/util';
import ActiveFilters from './shared/ActiveFilters';
import { withRouter } from 'vue-component-router';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetCommunityMembersQuery,
  SortType,
  useGetCommunityMembersQuery
} from 'shared/generated/graphql-types';
import { ApolloQueryResult } from '@apollo/client';
import { CommunityMember } from './shared/types';

interface Props {
  regionId: number;
  activeFilters: ActiveFilters;
}

interface TChildProps {
  communityMembers: CommunityMember[];
  total: number;
  initialLoading: boolean;
  loading: boolean;
  fetchMore: (
    { limit, offset }: FetchMoreOptions,
    stateChanger: StateChanger
  ) => Promise<ApolloQueryResult<GetCommunityMembersQuery>> | undefined;
}

export const getCommunityMembersEnhancer = wrapComponent<Props, TChildProps>((props) => {
  const { loading, result, fetchMore } = useGetCommunityMembersQuery(
    computed(() => ({
      limit: 60,
      filter: {
        regionId: props.regionId,
        query: props.activeFilters && props.activeFilters.term,
        sortBy: props.activeFilters && props.activeFilters.sortBy,
        showDeceased: props.activeFilters && props.activeFilters.showDeceased,
        filters: props.activeFilters && props.activeFilters.filters
      },
      regionId: props.regionId
    })),
    {
      fetchPolicy: 'network-only'
    }
  );

  return computed(() => ({
    initialLoading: loading.value && !result.value?.communityMembers,
    loading: loading.value,
    communityMembers: result.value?.communityMembers.communityMembers || [],
    total: result.value?.communityMembers.total || 0,
    fetchMore: ({ limit, offset }, stateChanger) => {
      return fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            // regionId: props.regionId,
            showDeceased: props.activeFilters.showDeceased,
            query: props.activeFilters.term,
            filters: props.activeFilters.filters,
            sortBy: props.activeFilters.sortBy
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult || !fetchMoreResult.communityMembers) {
            return previousResult;
          }
          if (
            !fetchMoreResult.communityMembers.communityMembers.length ||
            fetchMoreResult.communityMembers.communityMembers.length < limit
          ) {
            stateChanger.complete();

            if (!fetchMoreResult.communityMembers.communityMembers.length) {
              return previousResult;
            }
          }
          const ids = previousResult.communityMembers.communityMembers.map((x) => x.personID);
          const newData = fetchMoreResult.communityMembers.communityMembers.filter(
            (x) => !ids.includes(x.personID)
          );

          return {
            communityMembers: {
              __typename: 'CommunityMemberPage',
              total: fetchMoreResult.communityMembers.total,
              communityMembers: [...previousResult.communityMembers!.communityMembers, ...newData]
            }
          };
        }
      });
    }
  }));
});

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, setFilters, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        activeFilters: {
          term: '',
          showDeceased: false,
          filters: {},
          sortBy: { name: SortType.Name, ascending: true }
        }
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters
        },
        on: this.$listeners
      });
    }
  });
};

export default compose(withFilters, getCommunityMembersEnhancer, withRouter)(CommunityMembers);
