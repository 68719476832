<template>
  <table  class="sloted-table">
    <thead>
      <slot name="thead">

      </slot>
    </thead>
    <tbody>
      <slot name="tbody">

      </slot>
    </tbody>
  </table>
</template>
<script>
export default {

};
</script>

<style scoped>
tbody {
  border-right: 1px solid #ddd;
}
  th {
    text-transform: uppercase;
  	color: #999999;
  	margin-bottom: 20px;
  	font-weight: 600;
  	
  	font-size: 12px;
    border-bottom:1px solid #ddd;
  }
  td {
    border-bottom:1px solid #ddd;
    border-left:1px solid #ddd;
    padding: 15px 15px;
  }
  th {
    padding: 15px 15px 10px;
  }
</style>
