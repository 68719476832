import { VNode } from "vue";

export default function injectProps (component: VNode, props: object) {
	const options = component.componentOptions;

	if (typeof options === 'undefined') {
		return component;
	}
	const propsData = options.propsData || {};

	Object.assign(propsData, props);

	return component;
}
