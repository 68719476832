import TeenLookupComponent from './TeenLookup.vue';
import { withData, compose, withProps } from 'vue-compose';
import { toCompactTeens } from './utils';
import { CompactTeen as CompactTeenType } from './shared/CompactTeen';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { useTeenLookupQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { Teen } from './utils'

interface StateToProps {
  regionId: number | null;
}

interface Props extends StateToProps {
  isAlumni: boolean;
  term: string;
  showTeenInfo: boolean;
  multipleWithInfo: boolean;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

interface TeenLookupProps {
  teens: CompactTeen[] | Teen[];
  searching: boolean;
}

const teenLookupEnhancer = wrapComponent<Props, TeenLookupProps>((props) => {
  const { loading, result } = useTeenLookupQuery(
    computed(() => ({
      regionID: props.regionId !== 33 ? props.regionId : null,
      showAlumni: props.isAlumni,
      term: props.term
    })),
    { fetchPolicy: 'network-only', enabled: computed(() => !!props.term) }
  );

  return computed(() => ({
    searching: loading.value,
    teens: result.value?.teens
      ? props.showTeenInfo || props.multipleWithInfo
        ? toCompactTeens(result.value.teens.teens)
        : result.value.teens.teens
      : []
  }));
});

export default compose(
  withData({
    term: {
      initialValue: null
    }
  }),
  withProps<Props, StateToProps>((props) => ({
    regionId: props.regionId || getCurrentRegion()
  })),
  teenLookupEnhancer
)(TeenLookupComponent);

export type CompactTeen = CompactTeenType;
