
import { Route, RouterLink } from 'vue-component-router';
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import Summary from './pages/Summary';
import EditSeries from './pages/EditSeries';
import Loading from 'shared/components/Loading.vue';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "Series",
  components: {
    Route,
    RouterLink,
    Summary,
    EditSeries,
    Loading,
  },
  props: {
    url: {},
    regionId: {},
    series: {},
    seriesId: {},
    loading: {},
  },
  data() {
    return {
      isDeleting: false
    }
  },
  computed: {
    error() {
      if (!(this.series.Events && this.series.Events.length)) {
        return 'There was an error displaying this series. Please Contact support with the series id.';
      }
      return null;
    }
  },
  methods: {
    onDeletingChange(event) {
      this.isDeleting = event;
    }
  }
})
