
import Vue from 'vue';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import { ErrorBag } from 'shared/util/types';
import * as RadioGroup from 'shared/radio-group';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import FormSection from 'shared/ui/forms/FormSection.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { EntityUnionEnum, Gender, MaritalStatus } from 'shared/generated/graphql-types';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { RouterLink } from 'vue-component-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import EmailInput from 'shared/components/EmailInput';
import TeenLookup, { CompactTeen } from 'shared/components/TeenLookup';
import QuickAddTeen from 'shared/components/QuickAddTeen';
import QuickAddParent from 'shared/components/QuickAddParent';
import fullheight from 'shared/directives/fullheight';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import ParentLookup from 'shared/components/ParentLookup';
import { Data, FormShape, Methods, Props } from './types';
import Avatar from 'shared/components/avatar';

library.add(faCheck);

export default Vue.extend<Data, Methods, {}, Props>({
  name: "AddNewFamily",
  components: {
    Avatar,
    ParentLookup,
    UIInput,
    ScrollablePanel,
    QuickAddTeen,
    TeenLookup,
    EmailInput,
    RouterLink,
    PhoneInput,
    UIRadio,
    UIButton,
    SimpleForm,
    Field,
    Divider,
    Error,
    UICheckbox,
    Modal,
    FormSection,
    CollapsibleSection,
    ...RadioGroup,
    QuickAddParent,
  },
  directives: {
      fullheight
  },
  props: {
    path: {},
    createFamily: {},
    regionId: {},
    returnUrl: {},
    router: {},
    redirectToProfile: {},
    basePath: {},
    client: {},
    addingChild: {},
    addingFather: {},
    addingMother: {}
  },
  data () {
    return {
      associatedChildren: [],
      createTeenInputs: [],
      MaritalStatus,
      Gender,
      EntityUnionEnum,
      formShape: {
        father: null,
        mother: null,
        maritalStatus: MaritalStatus.Married,
        children: []
      }
    }
  },
  methods: {
    async submitHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();

      try {
        const result = await this.createFamily({
          input: {
            fatherId: args.values.father ? args.values.father.personId : null,
            motherId: args.values.mother ? args.values.mother.personId : null,
            maritalStatus: args.values.maritalStatus,
            childrenIds: this.associatedChildren.map(c => c.personID),
            regionId: this.regionId,
            createTeens: (this.createTeenInputs || [])
          }
        });

        if (this.redirectToProfile) {
          this.router.history.push(`${this.basePath.split('/:')[0]}/${result?.data?.createFamily.id}`);
        } else {
          this.router.history.push(this.returnUrl);
        }
      } catch (e) {
        console.log(e);
        if (args.setSubmitted) args.setSubmitted();
      }
    },
    associateHandler (child) {
      const compactTeenShape: CompactTeen = {
        personID: child.id,
        gender: child.gender,
        firstName: child.firstName,
        lastName: child.lastName,
        fullName: child.fullName,
        birthDate: child.birthDate,
        address: null,
        formattedAddress: '',
        email: child.email,
        landline: null,
        cellphone: null,
        thumbnail: null
      }
      if (!this.associatedChildren.map(ac => ac.personID).includes(child.id)) {
        this.associatedChildren.push(compactTeenShape);
      }
    },
    removeCreateTeenInput(teen) {
      const index = this.createTeenInputs.indexOf(teen);
      this.createTeenInputs.splice(index, 1);
    },
    validate (values) {
      let errors: ErrorBag<FormShape> = {};

      if(!values.father && !values.mother) {
        errors.father = 'Please select at least one parent.';
        errors.mother = 'Please select at least one parent.';
      }

      return errors;
    },
    handleQuickAddTeen(teen) {
      this.createTeenInputs.push(teen);
    }
  }
});

