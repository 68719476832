
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCheck } from '@fortawesome/pro-regular-svg-icons/faUserCheck';
import ClipboardCopy from 'shared/ui/clipboard/clipboard.vue';
import { GetRegistrationQuery } from 'shared/generated/graphql-types';

type Registration = GetRegistrationQuery['registration']

library.add(faUserCheck);

interface Props {
  registration: Registration;
}

export default Vue.extend<{}, {}, {}, Props>({
  name: 'ParentApproval',
  components: { ClipboardCopy },
  props: {
    registration: {}
  }
});
