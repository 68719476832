
import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import FormSection from 'shared/ui/forms/FormSection.vue';
import RegionLookup from 'shared/components/RegionLookup';
import ChapterLookup from 'shared/components/ChapterLookup';
import UIInput from 'shared/ui/forms/Input.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import UISelect from 'shared/ui/forms/Select.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import { ArrayElement, ErrorBag } from 'shared/util/types';
import { GetRegionsQuery, PushNotificationInput } from 'shared/generated/graphql-types';
import { Chapter } from 'shared/types';

type Region = ArrayElement<GetRegionsQuery['regions']>

interface PushMessageObject {
  application: {id: string; label: string};
  region: Region | null;
  chapter: Chapter | null;
  topic: string | null;
  title: string;
  body: string;
  notificationType: string | null;
  [k: string]: any;
}

interface Props {
  sendPushNotifications: (input: PushNotificationInput) => boolean;
}

interface Data {
  applications: {id: string; label: string}[];
  message: PushMessageObject;
  regionId: number | null;
  notificationTypes: string[];
}

interface Methods {
  validate: (values: PushMessageObject) => ErrorBag<PushMessageObject>;
  sendPushNotificationHandler: (args: SubmitCallbackArgs<PushMessageObject>) => Promise<void>;
  handleRegionChange: (region: Region, setValue: <K extends keyof PushMessageObject>(key: K, value: PushMessageObject[K]) => void) => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: "PushNotifications",
  components: {
    ScrollablePanel,
    RegionLookup,
    ChapterLookup,
    SimpleForm,
    Field,
    Error,
    FormSection,
    UIInput,
    UITextarea,
    UISelect,
    UIButton
  },
  directives: {
    fullheight
  },
  props: {
    sendPushNotifications: {}
  },
  data() {
    return {
      regionId: null,
      notificationTypes: ['EventAdded', 'EventUpdated'],
      applications: [
        {id: 'ncsy', label: 'NCSY'},
        {id: 'trivia', label:'Shalom Trivia'}
      ],
      message: {
        application: {id: 'ncsy', label: 'NCSY'},
        region: null,
        chapter: null,
        topic: null,
        title: '',
        body: '',
        notificationType: null
      }
    }
  },
  methods: {
    validate(values) {
      let errors: ErrorBag<PushMessageObject> = {};
      if (!values.title) {
        errors.title = 'Please enter push notification message title';
      }
      if (!values.body) {
        errors.body = 'Please enter push notification message body';
      }
      if (!values.region && !values.chapter && !values.topic) {
        errors.topic = 'No push notification recipients selected. Please select Region or enter Topic';
      }
      if ((values.notificationType === 'EventAdded' || values.notificationType === 'EventUpdated') && !values.eventId) {
        errors.eventId = 'Please enter Event ID';
      }
      return errors;
    },
    async sendPushNotificationHandler(args) {
      if ('errors' in args) return;
      args.setSubmitting!();

      const {application, region, chapter, topic, title, body} = args.values;

      const input: PushNotificationInput = {
        application: application.id,
        regionId: region && region.regionId || null,
        chapterId: chapter && chapter.chapterId || null,
        topic,
        title,
        body,
        additionalData: {}
      };

      if (args.values.notificationType) {
        input.additionalData.notificationType = args.values.notificationType;
        if (args.values.notificationType === 'EventAdded' || args.values.notificationType === 'EventUpdated') {
          input.additionalData.eventId = args.values.eventId;
        }
      }

      const result = await this.sendPushNotifications(input);
      args.setSubmitted!();
    },
    handleRegionChange(region, setValue) {
      if (region && region.regionId && region.regionId !== this.regionId) {
        setValue('chapter', null);
      }
      this.regionId = region && region.regionId || null;
    },
  }
})
