import { PermissionEnum } from "shared/generated/graphql-types";
import Vue, { CreateElement } from "vue";

interface Props {
  permission: PermissionEnum;
  claims: PermissionEnum[];
  showEmptyTag?: boolean;
  tag?: string;
}
export default Vue.extend<{}, {}, {}, Props>({
  name: 'RequiresPermission',
  props: {
    permission: {},
    claims: {},
    showEmptyTag: {
      required: false
    },
    tag: {
      required: false
    }
  },
  render(h: CreateElement) {
    if (this.claims && this.claims.find(p => p === this.permission)) {
      return h('div', this.$scopedSlots.default && this.$scopedSlots.default({}));
    } else if (this.showEmptyTag) {
      return h('div', this.tag || '');
    }

    return h('div', 'Forbidden');
  }
});
