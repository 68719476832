<template>
  <label class="toggle" :style="{width:width + 'px'}">
    <input type="checkbox" :checked="value" @change="onChange" />

    <span class="slider" :class="[ value ? 'active' : '' ]"></span>

    <span class="off" :class="[ value ? '' : 'active' ]">
      {{offText}}
    </span>

    <span class="on" :class="[ value ? 'active' : '' ]">
      {{onText}}
    </span>

  </label>
</template>
<script>

export default {
	name: 'UIToggle',
  props: {
		value: {
			type: Boolean | Number
		},
    width: {
      type: String,
      default: '300'
    },
		onText: {
      default: 'Yes',
			type: String
		},
    offText: {
      default: 'No',
			type: String
		},
    onIcon: {
      type: String
    },
    offIcon: {
      type: String
    }
	},
	methods: {
		onChange ($event) {
			this.$emit('input', $event.target.checked);
			this.$emit('blur', $event.target.checked);
		}
	}
};
</script>

<style lang="scss" scoped>
  .toggle {
    position: relative;
    display: flex;
    background: #f3f3f3;
    border-radius: 6px;
    max-width: 100%;
    font-size: 13px;
    margin-bottom: 1rem;
  }

  .toggle input {
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
  .on,
  .off {
    color: #666;
    position: relative;
    cursor: pointer;
    
    padding: 9px;
    width: 50%;
    text-align: center;
    transition: all .05s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .on {
    margin-left: -5px;
  }
  .active {
    font-weight: 500;
    color: #222;
  }
  .icon {
    top: 3px;
    position: relative;
  }
  .icn-check {
    display: none;
  }
  .slider {
    background: white;
    border-radius: 6px;
    padding: 9px 30px;
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    transition: all .05s ease-in-out;
    left: 0;
    box-shadow: 1px 0px 3px #ccc;
  }
  .active.slider {
    left: 50%;
    box-shadow: -1px 0px 3px #ccc;
  }

  /* flat skin */
  .toggle.flat {
    background: white;
    border-radius: 0;
  }
  .flat .slider {
    display: none;  
  }
  .flat .on,
  .flat .off {
    width: auto;
    margin: 0 20px 0 0;
    text-align: left;
    white-space: nowrap;
    font-weight: normal;
    transition: none;
    color: #333;
  }
  .flat .active {
    background: none;
    color: #ed800d;
    border: none;
    border-bottom: 2px solid #ed800d;
    font-weight: bold;
  }
</style>
