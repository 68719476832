
import Vue from 'vue';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { library } from '@fortawesome/fontawesome-svg-core';
import DatePicker from 'vue2-datepicker';
import { convertTo24HourFormat } from 'shared/util';
import { SimpleDate, SimpleTime } from 'shared/util/types';

interface Data {
  internalValue: SimpleTime;
}

interface Props {
  value: SimpleTime;
  endTimePicker: boolean;
  startTime: SimpleTime;
  endTime: SimpleTime;
  startDate: SimpleDate;
  endDate: SimpleDate;
}

interface Computed {
  valueDate: Date
}

interface Methods {
  toDate: (inputTime: SimpleTime, inputDate?: SimpleDate) => Date;
  inputHandler: (event: Date) => void;
  notBeforeTime: (date: Date) => boolean;
}

library.add(faClock);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Timepicker',
  components: { DatePicker },
  data () {
    return {
      internalValue: this.value || {
        hours: 12,
        minutes: '00',
        ampm: 'am'
      }
    }
  },
  props: {
    value: {},
    endTimePicker: {},
    startTime: {},
    endTime: {},
    startDate: {},
    endDate: {}
  },
  computed: {
    valueDate() {
      return this.toDate(this.internalValue, this.startDate);
    }
  },
  methods: {
    toDate (inputTime, inputDate) {
      const date = new Date();
      if (inputDate) {
        date.setFullYear(inputDate.year);
        date.setMonth(inputDate.month - 1);
        date.setDate(inputDate.day);
      }
      date.setHours(convertTo24HourFormat(inputTime));
      date.setMinutes(Number(inputTime.minutes));
      date.setSeconds(0);
      return date;
    },
    inputHandler (event) {
      const time: SimpleTime = {
        hours: 12,
        minutes: event && event.getMinutes() || 0,
        ampm: 'am'
      };
      const hours = event && event.getHours() || 0;
      if (hours > 12) {
        time.hours = hours - 12;
        time.ampm = 'pm';
      } else if (hours > 0) {
        time.hours = hours;
        if (hours === 12) {
          time.ampm = 'pm';
        }
      }
      this.internalValue = time;
      this.$emit('input', time);
      this.$emit('blur', time);
      this.$emit('change', time);
    },
    notBeforeTime (date: Date) {
      if (this.endTimePicker && this.startDate && this.startTime) {
        return date < this.toDate(this.startTime, this.startDate);
      }
      if (this.endTimePicker && this.startTime) {
        return date < this.toDate(this.startTime);
      } else {
        return false;
      }
    },
  }
})
