<template>
	<div class="popover-container" :class="{ open: show, 'align-right': alignRight, 'align-left': alignLeft, 'align-bottom': alignBottom }">
		<span class="trigger" @click="()=> show === true ? show = false : show = true" v-if="hasTriggerSlot">
			<slot name="trigger" :close="() => show = false"></slot>
		</span>
		<font-awesome-icon :icon="['fas','plus-circle']" v-else @click="()=> show === true ? show = false : show = true"/>

		<div
			class="popover"
			 ref="popover"
			v-if="show"
			:style="{
				width: width,
				marginLeft: marginLeft + 'px',
				maxHeight: height ? `${height}px` : undefined
			}"
			v-on-clickaway="close" @click="() => stopToggeling ? show = true : show = !show"
			:class="getClass(dimensions)"
		>
			<slot :open="open" :close="close" name="content"></slot>
		</div>
	</div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { directive as onClickaway } from 'vue-clickaway2';

library.add(faPlusCircle)

export default {
	name: 'Popover',
	data () {
   	 	return {
		  show: false,
		  dimensions: null,
		  offsetHeight: this.height
    	}
	},
	mounted () {
		this.dimensions = this.$el.getBoundingClientRect();

		if (!this.offsetHeight) {
			//this part calculates the height of the hidden content by showing and quickly hidding again and adding 20px buffer
			this.show = true;
			this.$nextTick(() => {
				this.offsetHeight = this.$refs.popover.clientHeight + 20;
						this.show = false;
					});
			}
  	},
	directives: {
    	onClickaway: onClickaway,
	},
	props: {
		stopToggeling: {
      type: Boolean,
      default: false
		},
		height: {
			type: Number
		},
		width: {
			type: String
		},
		alignRight: {
			type: Boolean,
			default: false
		},
		alignLeft: {
			type: Boolean,
			default: false
		},
		alignBottom: {
			type: Boolean,
			default: false
		}
	},
	methods: {
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
		getClass (dimensions) {
			let posClass = 'under';
			const { bottom } = dimensions;
			const position = [];
			if ((bottom + this.offsetHeight) > window.innerHeight) {
				posClass = 'over'
			}
			return posClass;
		}
	},
	computed:{
		marginLeft () {
			return (0 - (parseInt(this.width) / 2))
		},
		hasTriggerSlot() {
			return !!this.$slots.trigger || this.$scopedSlots.trigger
		}
	}
};
</script>
<style scoped lang="scss">
	.popover-container {
		position: relative;
	}
	.fa-plus-circle {
		color: #ea7d1c;
		font-size: 28px;
		cursor: pointer;
		border: 3px solid transparent;
		border-radius: 50%;

		&:hover {
			border-color: #f5dfcc;
		}
	}
	.popover {
		position: absolute;
		min-width: 100px;
		background: white;
		box-shadow: 0px 1px 15px rgba(0,0,0,0.1);
		padding: 10px 20px;
		top: 100%;
		margin-top: 10px;
		left: 50%;
		margin-left: -50px;
		border: 1px solid #efefef;
		border-radius: 4px;
		z-index: 100;

		&:after {
			content:"";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			border: 10px solid transparent;
			border-bottom-color: white;
			top:-20px;
		}
		&:before {
			content:"";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			border: 10px solid transparent;
			border-bottom-color: #efefef;
			top:-21px;
		}
	}
	.popover.over {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 10px;
		&:after {
			content:"";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			border: 10px solid transparent;
			border-top-color: white;
			top: auto;
			bottom:-20px;
		}
		&:before {
			content:"";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			border: 10px solid transparent;
			border-top-color: #efefef;
			top:auto;
			bottom:-21px;
		}
	}

	.align-right .popover{
		left: auto !important;
		right: -12px;
		margin-left: 0 !important;

		&:after,
		&:before {
			left: auto !important;
			right: 6px
		}
	}
	.align-left .popover{
		left: calc(30px - 100%) !important;
		right: auto;

		&:after,
		&:before {
			left: auto !important;
			right: 4px
		}
	}
	.align-bottom .popover{
		bottom: auto !important;
		top: 100%;
		margin-bottom: 0 !important;
		margin-top: 10px;

		&:after {
			content:"";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			border-top-color: transparent;
			border-bottom-color: white;
			top:-20px;
			bottom: auto;
		}
		&:before {
			content:"";
			position: absolute;
			left: 50%;
			margin-left: -10px;
			border-top-color: transparent;
			border-bottom-color: #efefef;
			top:-21px;
			bottom: auto;
		}
	}

</style>
