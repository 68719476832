import Avatar from 'vue-avatar';
import Vue from 'vue';

const colors = ["#FFDB8E", "#8298D6", "#74C6CE"];

export default Vue.extend({
  name: 'Avatar',
  props: Avatar.props,
  render (h) {
    const color = this.$props.backgroundColor ? this.$props.backgroundColor : colors[Math.floor(Math.random() * colors.length)]

    return h(Avatar, {
      props: {
        ...this.$props,
        backgroundColor: color
      }
    })
  }
})
