
import Vue from 'vue';
import { Props } from './types';
import UISwitch from 'shared/ui/forms/Switch.vue';
import Datepicker from 'shared/components/DatePicker.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import FancyCheckbox from 'shared/ui/forms/FancyCheckbox.vue';
import UITicket from '../../../shared/Ticket.vue';
import UIAdditionalEventItem from '../../../shared/AdditionalEventItem.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboardCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck';
import { faGraduationCap } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faHotel } from '@fortawesome/pro-regular-svg-icons/faHotel';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import { AdditionalEventItem, EventTicket, GetRegionDataQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']

library.add(faClipboardCheck, faGraduationCap, faHotel, faPencil)

interface Data {
  registrationRequired: boolean;
  hasAdditionalEventItems: boolean;
}

interface Computed {
  allowSplitPayments: boolean;
  allowPartialPayments: boolean;
}

type ExtendedProps = Props & { region: Region };

export default Vue.extend<Data, {}, Computed, ExtendedProps>({
  name: 'RegistrationAndTickets',
  components: {
    UISwitch,
    Timepicker,
    Datepicker,
    FancyCheckbox,
    UITicket,
    UIAdditionalEventItem,
    UICheckbox,
    UIInput
  },
  props: {
    getData: {},
    displayErrors: {},
    setData: {},
    step: {},
    steps: {},
    region: {},
    getOuterData: {}
  },
  data () {
    return {
      registrationRequired: !!this.getData('registrationRequired'),
      hasAdditionalEventItems: !!(this.getData('additionalEventItems') || []).length
    };
  },
  computed: {
    allowSplitPayments () {
      return this.region.regionId !== 30  && this.region.regionId !== 8
    },
    allowPartialPayments () {
      return this.region.regionId !== 8
    }
  },
  methods: {
    async registrationRequiredHandler(checked: boolean) {
      this.registrationRequired = checked;
      this.setData('registrationRequired', this.registrationRequired);
      await Vue.nextTick();

      if(!this.registrationRequired) {
        // XTODO will we want to clear out all registration related props
      }
    },
    async hasAdditionalEventItemsHandler(checked: boolean) {
      this.hasAdditionalEventItems = checked;
      if(!this.hasAdditionalEventItems) {
        this.setData('additionalEventItems', null);
        await Vue.nextTick();
      }
    },
    async addNewTicket(tickets: any[]) {
      this.setData(`tickets.${(tickets || []).length}`, {});
    },
    async addNewAdditionalEventItem(additionalEventItems: any[]) {
      this.setData(`additionalEventItems.${(additionalEventItems || []).length}`, {});
      await Vue.nextTick();
    },
    async setTeenOption(name: string, getData: (name: string) => any){
      this.setData(name, !(getData(name) || false));
      await Vue.nextTick();
    },
    async removeAdditionalEventItem(index: number, additionalEventItems: AdditionalEventItem[], setData: (name: string, value: any) => void){
      const removed = additionalEventItems.splice(index, 1);
      if(removed[0].id) {
        this.$emit('additionalEventItemDeleted', removed[0].id);
      }
      setData('additionalEventItems', additionalEventItems);
      await Vue.nextTick();
    },
    async removeTicket(index: number, tickets: EventTicket[], setData: (name: string, value: any) => void){
      const removed = tickets.splice(index, 1);
      if(removed[0].EventTicketID) {
        this.$emit('ticketDeleted', removed[0].EventTicketID);
      }
      setData('tickets', tickets);
      await Vue.nextTick();
    },
    isHasErrors(key: string) {
      return this.step.validations && this.step.validations[key];
    }
  }
})
