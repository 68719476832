
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VueEditor, Quill } from 'vue2-editor';
import fileUploads from 'shared/util/fileUploads';
import { ApolloClient } from '@apollo/client';
import { ArrayElement } from 'shared/util/types';
import { GetTemplateTypesQuery } from 'shared/generated/graphql-types';

type TemplateType = ArrayElement<GetTemplateTypesQuery['templateTypes']>

@Component({
    components: {
      VueEditor
    }
})
export default class EditorContainer extends Vue {

  @Prop() value!: string;
  @Prop() templateType!: TemplateType;
  @Prop() client!: ApolloClient<any>;


  content: string = this.value || '';
  toolbarOptions: any = {
      modules: {
        toolbar: {
          container: [
            [{ Variables: this.templateType && this.templateType.variables && this.templateType.variables.split(',') || [] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ color: [] }, { background: [] }],
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ["link"],
            ["clean"],
          ],
          handlers: {
            Variables: (value?: string) => {
              if(value) {
                // @ts-ignore
                const cursorPosition = this.$refs.editor.quill.getSelection().index;
                // @ts-ignore
                this.$refs.editor.quill.insertText(cursorPosition, `{{${value}}}`);
                // @ts-ignore
                this.$refs.editor.quill.setSelection(cursorPosition + value.length);
              }
            }
          }
        }
      },
      theme: 'snow'
    }
  mounted() {
    const quill = Quill;
    const blockDiv = quill.import('blots/block');
    blockDiv.tagName = ['DIV'];
    quill.register(blockDiv, true);

    this.quill = (this.$refs.editor as any).quill;
    this.txtArea = document.createElement("textarea");
    this.txtArea.style.cssText =
      "width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;";

    this.txtArea.addEventListener("blur", () => {
      this.quill.pasteHTML(this.txtArea.value);
    })

    const htmlEditor = this.quill.addContainer("ql-custom");
    htmlEditor.appendChild(this.txtArea);

    this.quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
      var html = this.quill.getHTML();
      this.txtArea.value = html;
    });

    this.content = this.value;

    const variablesPicker = document.querySelectorAll('.ql-picker')[0];
    if (variablesPicker) {
      const placeholderPickerItems = Array.prototype.slice.call(variablesPicker.querySelectorAll('.ql-picker-item'));

      placeholderPickerItems.forEach((item: any) => item.textContent = item.dataset.value);
    }

    document.querySelector('.ql-picker .ql-picker-label')!.innerHTML = 'Variables' + document.querySelector('.ql-picker .ql-picker-label')!.innerHTML;
    // @ts-ignore
    document.querySelector('.ql-picker .ql-picker-label')!.style["width"] = "100px";
    // @ts-ignore
    document.querySelector('.ql-picker-options')!.style = 'overflow: scroll; height: 210px;'
  }
  beforeDestroy() {
    this.txtArea.removeEventListener("blur", () => {
      this.quill.pasteHTML(this.txtArea.value);
    })
  }
  async handleImageAdded(file: File, Editor: VueEditor, cursorLocation: { x: number; y: number; }, resetUploader: () => void) {
    fileUploads(this.client, file, Editor, cursorLocation);
    resetUploader();
}

  @Watch('content')
  contentUpdated() {
    this.$emit('change', this.content);
  }
}
