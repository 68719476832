
import axios from 'axios';
import trim from 'lodash/trim';
import addressit from 'addressit';
import parser from 'parse-address';
import { CreateElement } from 'vue';
import Countries from 'countries-list';
import { Vue, Prop, Component } from 'vue-property-decorator';
import { UpdateTeenMutation } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Address = ArrayElement<UpdateTeenMutation['updateTeen']['Person']['Addresses']>

const commonCountries = {
	"US": Countries.countries.US,
	"CA": Countries.countries.CA,
	"IL": Countries.countries.IL,
	"CL": Countries.countries.CL,
	"AR": Countries.countries.AR,
}
const restOfCountries = {
	"AU": Countries.countries.AU,
	"BR": Countries.countries.BR,
	"CH": Countries.countries.CH,
	"CO": Countries.countries.CO,
	"DE": Countries.countries.DE,
	"DO": Countries.countries.DO,
	"FR": Countries.countries.FR,
	"GB": Countries.countries.GB,
	"HU": Countries.countries.HU,
	"MX": Countries.countries.MX,
	"PL": Countries.countries.PL,
	"PR": Countries.countries.PR,
	"SG": Countries.countries.SG,
};
const common = Object.entries(commonCountries)
const rest =  Object.entries(restOfCountries)
const countries = common.concat(rest)
	.map(k =>({ code: k[0], name: k[1].name}))

const parseIntlAddress = (address: string, country: string | null) => {
	const attempt = addressit(address);

	return {
		street: attempt.extractStreet().street,
		city: attempt.regions[0],
		country: country
	};
};

const formatAddress = (address: Partial<Address>) =>
	trim(`${trim(`${address.street || ''} ${address.street2 || ''}`)} ${address.city || ''} ${address.state ? `, ${address.state || ''}` : ''} ${address.zipCode || ''}`);

const parseUSAddress = (address: string) => {
	const {
		city,
		zip,
		state,
		number = '',
		prefix = '',
		suffix = '',
		street,
		sec_unit_type: secUnitType = '',
		sec_unit_num: secUnitNum = '',
		type = ''
	} = parser.parseLocation(address);

	return {
		city,
		zipCode: zip,
		state: state,
		street: `${number} ${trim(`${prefix} ${street} ${suffix}`)} ${trim(`${secUnitType} ${secUnitNum}`)} ${type}`,
		country: 'US'
	};
};

const unverified = (response: any) => {
	return response.raw[0].analysis && !['Verified', 'Ambiguous'].includes(response.raw[0].analysis.verification_status);
};

@Component({
	name: 'AddressLookup',
})
export default class extends Vue {
	@Prop() value!: Address;
	@Prop() defaultCountry!: string;

	country: string | null =  (this.value && this.value.country) || this.defaultCountry;
	address: string =  formatAddress(this.value || {} as Address);
	verifying: boolean = false;

	async lookupAddress (country: string | null, address: string) {
		if (!country || !address) return;

		this.verifying = true;
		const response = (await axios.get(`https://address-api.schoolsapi.oustatic.com?country=${country}&street=${encodeURIComponent(address)}`)).data;

		this.verifying = false;

		let result: Partial<Address>;

		if (!response.result || (this.country !== 'US' && unverified(response))) {

			if (this.country === 'US') {
				result = parseUSAddress(this.address);
			}
			else if (response.success !== undefined && response.success === false) {
				result = parseIntlAddress(this.address, this.country);
			}
			else {
				result = {
					street: response.result.street,
					street2: response.result.street2,
					city: response.result.city,
					zipCode: response.result.zipCode,
					country: this.country
				};
			}
			result = { id: (this.value || {}).id, ...result, verified: false }
			this.$emit('input', result);
			this.$emit('blur', result);

			this.address = formatAddress(result);

			return;
		}

		result = {
			street: response.result.street,
			street2: response.result.street2,
			city: response.result.city,
			state: response.result.state,
			zipCode: response.result.zipCode,
			country: this.country,
			verified: true,
			id: (this.value || {}).id
		};

		this.$emit('input', result);
		this.$emit('blur', result);
		this.address = formatAddress(result);
	}

	render (h: CreateElement) {
		return h('div', [this.$scopedSlots.default && this.$scopedSlots.default({
			countries,
			setCountry: (country: string | null) => { this.country = country; },
			setAddress: (address: string) => { this.address = address; },
			country: this.country,
			address: this.address,
			lookupAddress: () => this.lookupAddress(this.country, this.address),
			verifying: this.verifying
		})]);
	}
};
