
import Vue from 'vue';
import { VueTelInput } from 'vue-tel-input';
import isEmpty from 'lodash/isEmpty';
import { validateNumber, phoneInternational } from 'shared/util';
import 'vue-tel-input/dist/vue-tel-input.css';

interface Props {
  value: string;
  type: string;
  countryISO: string;
  required: boolean;
  width: string;
  placeholder: string;
  showErrors: boolean;
  validateOnBlur: boolean;
  name: string;
}

interface Data {
  localValue: string;
  eventType: string | null;
  preferredCountries: string[];
}

interface Computed {
  initialValue: string;
  listeners: Record<string, Function>;
  phoneValidation: boolean;
  errors: Record<string, string>;
}

interface Methods {
  isEmpty: (value: any) => boolean;
  blurHandler: () => void;
  inputHandler: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'PhoneInput',
  components: {
    VueTelInput
  },
  props: {
    value: {},
    type: {},
    countryISO: {},
    required: {
      type: Boolean,
      default: false
    },
    width: {},
    placeholder: {},
    showErrors: { type: Boolean, default: true },
    validateOnBlur: { type: Boolean, default: false },
    name: { type: String, default: 'PhoneInput' }
  },
  data() {
    return {
      localValue: '',
      eventType: null,
      preferredCountries: [
        'US',
        'CA',
        'IL',
        'CL',
        'AR',
        'AU',
        'BR',
        'CH',
        'CO',
        'DE',
        'DO',
        'FR',
        'GB',
        'HU',
        'MX',
        'PL',
        'PR',
        'SG'
      ]
    };
  },
  created() {
    this.localValue = phoneInternational(this.value);
  },
  computed: {
    initialValue() {
      return this.value;
    },
    listeners() {
      return {
        input: this.inputHandler,
        blur: this.blurHandler
      };
    },
    phoneValidation() {
      return validateNumber(this.localValue.replace(/\D/g, ''));
    },
    errors() {
      let error: any = {};
      if (!this.localValue) {
        if (this.isRequiredField) {
          error = {
            [this.name]: 'Required field'
          };
        }
      } else if (!this.phoneValidation) {
        if (this.validateOnBlur) {
          if (this.eventType === 'blur') {
            error = {
              [this.name]: 'Phone Number must be a number'
            };
          }
        } else {
          error = {
            [this.name]: 'Phone Number must be a number'
          };
        }
      }
      return error;
    }
  },
  methods: {
    isEmpty,
    blurHandler() {
      this.eventType = 'blur';
      this.$emit('blur', phoneInternational(this.localValue));
    },
    inputHandler() {
      this.eventType = 'input';
      this.$emit('input', phoneInternational(this.localValue));
    }
  }
});
