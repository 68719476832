import Vue, { Component as VueComponent, computed, h } from 'vue';
import { compose, withProps } from 'vue-compose';
import { ApolloError } from '@apollo/client';
import { useApolloClient } from '@vue/apollo-composable';
import { DocumentNode } from 'graphql';
import { getOptions } from 'shared/util';
import Root from './Root.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { useNotificationsStore } from 'store/notifications/useNotificationsStore';
import { createStore } from 'store/index';
import {
  TeenRegisteredDocument,
  TeenRegisteredSubscription,
  useCurrentUserWithRegionsQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { Me } from 'shared/types';

type Teen = NonNullable<TeenRegisteredSubscription['teenRegistered']>['Teen'];
type Event = NonNullable<TeenRegisteredSubscription['teenRegistered']>['Event'];

const store = createStore();
const { addNotification } = useNotificationsStore(store);
const { getCurrentRegion, setCurrentRegion } = useRegionStore(store);

type ResultCallback<V, T = VueComponent> = (this: T, result: V) => void;

function subscribe<T extends Record<string, any>>(
  subscription: DocumentNode,
  result: (result: T) => void
) {
  return (WrappedComponent: VueComponent): typeof Vue => {
    const options = getOptions(WrappedComponent);

    return Vue.extend<{}, {}, {}, {}>({
      name: 'ApolloSubscription',
      props: options.props,
      setup(props) {
        const { client } = useApolloClient();
        const observer = client.subscribe<T>({
          query: subscription
        });

        observer.subscribe({
          next: ({ data }) => result.bind(props)(data as T)
        });

        return () => h(WrappedComponent, { props });
      }
    });
  };
}

interface Result {
  teenRegistered: {
    Teen: Teen;
    Event: Event;
  };
}

const callback: ResultCallback<Result, Root> = function ({
  teenRegistered: { Teen, Event }
}: Result) {
  const teen = `${Teen.Person.firstName} ${Teen.Person.lastName}`;
  const event = Event?.eventName;

  this.addNotification(`${teen} has registered for event ${event}`);
};

export const MainLayoutWithSubscription = subscribe<Result>(
  TeenRegisteredDocument,
  callback
)(Root);

interface Props {
  staffLoading: boolean;
  error: ApolloError | null;
  staff: Me | null;
}
const currentUserEnhancer = wrapComponent<{ authState: string }, Props>((props) => {
  const { error, loading, result } = useCurrentUserWithRegionsQuery({
    enabled: computed(() => !props.authState)
  });

  return computed(() => ({
    staffLoading: loading.value,
    staff: result.value?.me || null,
    error: error.value
  }));
});

export default compose(
  withProps(() => ({
    currentRegion: getCurrentRegion(),
    setCurrentRegion,
    addNotification
  })),
  subscribe<Result>(TeenRegisteredDocument, callback),
  currentUserEnhancer
)(Root);
