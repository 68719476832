

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import UIInput from 'shared/ui/forms/Input.vue';

@Component({
  components: {
    UIInput
  }
})
export default class IsraelProgramIdPicker extends Vue {
  @Prop() value!: number;
}
