
import { Vue, Component, Prop } from 'vue-property-decorator'
import MultistepForm, { StepData, Steps, ValidationResult } from '../../../shared/MultistepForm'
import LNLForm from './Form.vue';
import { ApolloClient } from '@apollo/client';
import set from 'lodash/set'
import DateTimezone from 'date-timezone';
import { ProgressList, ListItem } from 'shared/ui/lists/ProgressList'
import { FormState } from '../../../shared/types'
import { THUMBNAIL_SIZE_LIMIT, THUMBNAIL_SIZE_LIMIT_BASE64, startTimeBeforeEndTime } from '../../../shared/util'
import { convertTo24HourFormat } from 'shared/util'
import { lnl } from '../../../shared/DefaultEventImages';
import { CompactTeen } from 'shared/components/TeenLookup/shared/CompactTeen';
import Description from '../shared/Description.vue';
import { formatDate, formatTime, formatDateAndTime, isEventTYA } from '../shared/methods';
import EmailBuilder from 'shared/components/EmailBuilder'
import { EditEventPayload } from '../../types';
import { EditEventInput, GetRegionDataQuery, GetStaffQuery, SeriesOptionsInput } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']
type Region = GetRegionDataQuery['region']

@Component({
  components: {
    MultistepForm,
    EmailBuilder,
    LNLForm,
    ProgressList,
    ListItem,
    Description
  },
  methods: {
    formatDate,
    formatTime,
    formatDateAndTime
  }
})
export default class LNL extends Vue {
  @Prop() client!: ApolloClient<any>;
  @Prop({type: Object}) stepData!: Steps;
  @Prop({type: String}) state!: FormState;
  @Prop() region!: Region;
  @Prop() seriesId!: number;
  @Prop({ default: 'Details' }) main!: string;
  @Prop() openDescriptionTab!: () => void;

  validate (step: string, data: StepData): ValidationResult {
    const errors = {}

    switch (step) {
      case 'details': {
        if (!data || !data.name) {
          set(errors, 'details.name', 'Please enter a name for the event')
        }
        if (!data || !data.FirstEvent) {
          set(errors, 'details.FirstEvent', 'Please pick a start date');
        }
        if (!data || !data.LastEvent) {
          set(errors, 'details.LastEvent', 'Please pick a end date');
        }
        if (!data || !data.StartTime) {
          set(errors, 'details.StartTime', 'Please pick a starting time for the event');
        }
        if (!data || !data.EndTime) {
          set(errors, 'details.EndTime', 'Please pick an ending time for the event');
        }
        if(!data || !data.TimeZone) {
          set(errors, 'details.TimeZone', 'Please select a timezone for this event');
        }
        if (startTimeBeforeEndTime(data, true) !== -1) {
          set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
        }
        if (data && data.isVirtual) {
          if (data.streamSchedule) {
            if (!data.zoomMeetingId && !data.zoomUser) {
              set(errors, 'details.zoomUser', 'Please select Zoom User');
            }
            if ((data.zoomUser || data.zoomMeetingId) && !data.zoomPasscode) {
              set(errors, 'details.zoomPasscode', 'Please enter passcode for the Zoom event');
            }
          }
        }
        break;
      }
      case 'staffAndChapter': {
        if (!data || !data.primaryStaff) {
          set(errors, 'staffAndChapter.primaryStaff', 'Please select a primary staff member for the event');
        }
        break;
      }
      case 'thumbnailAndDescription': {
        if (!data || (data && !data.thumbnail)) {
          break;
        }
        if (data.thumbnail && data.thumbnail.length > THUMBNAIL_SIZE_LIMIT_BASE64) {
          set(errors, `${step}.thumbnail`, `Image should be less then ${THUMBNAIL_SIZE_LIMIT} Mb`)
        }
        break;
      }
    }

    return errors
  }

  submit (steps: {[x: string]: any}) {
    const {
      FirstEvent,
      LastEvent,
      StartTime,
      EndTime,
      TimeZone,
      Repeat,
    } = steps.details

    DateTimezone.setGlobalTimezone(TimeZone);

    const StartDate = new DateTimezone.DateTimezone(
      FirstEvent.year,
      FirstEvent.month - 1,
      FirstEvent.day,
      convertTo24HourFormat(StartTime),
      +StartTime.minutes,
    );

    const EndDate = new DateTimezone.DateTimezone(
      LastEvent.year,
      LastEvent.month - 1,
      LastEvent.day,
      convertTo24HourFormat(EndTime),
      +EndTime.minutes,
    );

    const event = this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString());

    const series: SeriesOptionsInput = {};
    if (steps.details.convertToSeries) {
      const EndDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(EndTime),
        +EndTime.minutes,
      );

      const LastDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );
      event.startDate = StartDate.toISOString();
      event.endDate = EndDate.toISOString();
      event.regionID = this.region.regionId;
      series.Repeat = Repeat;
      series.EndDate = LastDate.toISOString();
    }

    const editEventPayload: EditEventPayload = { event }
    if (steps.details.convertToSeries) {
      editEventPayload.series = series;
    }
    this.$emit('submit', editEventPayload);
  }

  getEventFields(steps: { [x:string]: any}, startDate: string, endDate: string) {
    const chapterID = (steps.staffAndChapter.chapter || {}).chapterId;
    const address = steps.details.Address || {};
    const fullStreamLink = steps.details.streamLink
      ? steps.details.streamLink.match(/^http(s?):\/\//) ? steps.details.streamLink : 'https://' + steps.details.streamLink
      : ''

    const eventFields: EditEventInput = {
      eventName: steps.details.name,
      eventSubTypeID: steps.eventSubType.subType.eventSubTypeId,
      chapterID,
      isRegionWide: !chapterID,
      schoolID: ((steps.school || {}).school || {}).schoolID,
      advisorID: steps.staffAndChapter.primaryStaff.staffID,
      AdvisorsParticipating: (steps.staffAndChapter.staff || []).map((s: Staff) => s.staffID),
      TeensParticipating: (steps.staffAndChapter.teens || []).map((t: CompactTeen) => t.personID),
      startDate: startDate,
      endDate: endDate,
      image: steps.thumbnailAndDescription.thumbnail !== lnl ? steps.thumbnailAndDescription.thumbnail.replace(/data:image\/.*?;base64,/, '') : null,
      capacity: steps.options.capacity ? Number(steps.options.capacity) : null,
      trackId: (steps.options.eventTrack || {}).id,
      timeZone: steps.details.TimeZone,
      subtitle: steps.details.subtitle,
      internal: steps.options.hideFromWebAndMobile,
      address1: address.address,
      city: address.city,
      state: address.state,
      zip: address.zipCode,
      NotificationEmails: steps.options.NotificationEmails,
      baseUrl: steps.options.isTYAEvent ? 2 : steps.options.isBGCubedEvent ? 4 : 0,
      isVirtual: !!steps.details.isVirtual,
      streamLink: fullStreamLink,
      zoomUserId: steps.details.zoomUser && steps.details.zoomUser.id || null,
      zoomMeetingId: (steps.details.zoomMeetingId || '').replace(/ /g, '').replace(/-/g, ''),
      zoomPasscode: steps.details.zoomPasscode,
      zoomPasscodeHashed: steps.details.zoomPasscodeHashed
    };

    if (!steps.details.isVirtual) {
      eventFields.streamLink = null;
      eventFields.zoomUserId = null;
      eventFields.zoomMeetingId = null;
      eventFields.zoomPasscode = null;
      eventFields.zoomPasscodeHashed = null;
    } else {
      if (steps.details.streamSchedule) {
        eventFields.streamLink = null;
      } else {
        eventFields.zoomUserId = null;
        eventFields.zoomPasscode = null;
        eventFields.zoomPasscodeHashed = null;
      }
      if (steps.details.zoomMeetingId) {
        eventFields.streamLink = null;
        eventFields.zoomUserId = null;
      }
    }

    return eventFields;
  }

  isTYA(props: { getData: (step: string, key: string) => any }) {
    return isEventTYA(props);
  }
}
