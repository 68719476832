
import { Vue, Component, Prop } from 'vue-property-decorator';
import WYSIWYG from 'shared/ui/forms/RichTextArea';
import UIButton from 'shared/ui/buttons/Button.vue';
import { FormState } from '../../../shared/types'

@Component({
  components: {
    WYSIWYG,
    UIButton
  }
})
export default class DescriptionEditor extends Vue {
  @Prop() description!: string;
  @Prop({type: String}) state!: FormState;

  value: string = this.description || '';
}
