
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';

library.add(faQuestionCircle)

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CustomQuestions',
  components: {
  },
  data() {
    return {
      mode: 'display',
    }
  },
  props: {
    answers: {}
  },
  computed: {
  },
  methods: {

  }
})
