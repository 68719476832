
import Vue from 'vue';
import Tables from 'shared/ui/tables/Tables.vue';
import Loading from 'shared/components/Loading.vue';
import { Props, Data } from './types';

export default Vue.extend<Data, {}, {}, Props>({
  name: "EmailHistory",
  components: {
    Tables,
    Loading
  },
  props: {
    parent: {},
    emailHistory: {},
    loading: {}
  },
  data () {
    return {
      activityLevelsMap:{
      '-1': 'None',
      '0': 'Processed',
      '1': 'Dropped',
      '2': 'Deferred',
      '3': 'Delivered',
      '4': 'Bounced',
      '5': 'Opened',
      '6': 'Clicked',
      '7': 'Unsubscribe',
      '8': 'Reported as Spam'
      }
    };
  }
});
