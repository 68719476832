<template>
  <div class="selected-bar">
	<div class="bar-actions">
    	<slot name="button">
    	</slot>
	</div>
	</div>
</template>
<script>
export default {
  name: 'ListToolip',
  props: ['label']
};
</script>

<style lang="scss" scoped>
  .selected-bar {
	background: #183346;
	color: white;
	padding: 10px 20px;
	width: 100%;
	top: 20px;
	left: 0;
	font-size: 16px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.bar-actions {
	display: flex;
	align-items: center;
}
.bar-actions > button{
	border: 1px solid white;
	font-size: 12px;
	font-weight: 600;
	padding: 5px 20px;
	cursor: pointer;
	margin-right: 5px;
	background: transparent;
	color: white;

	&:hover {
		background: white;
		color: #222;
	}
}
.bar-actions > .clear {
	border: none;
	font-size: 12px;
	font-weight: 600;
	padding: 5px 20px;
	cursor: pointer;
}

</style>
