<template>
  <div class="ui-counter">
    <span class="btn" @click="modify_qty(-1)">-</span>
    <input v-bind="$attrs" :value="value" @input="onInput"/>
    <span class="btn" data-test="counterAdd" @click="modify_qty(1)">+</span>
  </div>
</template>
<script>
export default {
  name: 'UIInput',
  props: {
    value: {
      type: Number
    }
  },
  methods: {
    modify_qty(amount) {
	  this.$emit("input", Number(this.value + amount));
	  this.$emit('blur');
	},
	onInput ($event) {
		this.$emit('input', Number($event.target.value));
		this.$emit('blur');
	}
  }
};
</script>

<style lang="scss" scoped>
.ui-counter {
  max-width:150px;
}
input {
  height: 35px;
  display: block;
  border: none;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  padding: 8px 12px;
  font-size: 14px;
  background: #f3f3f3;
  border-radius: 0;
  box-shadow: 0 0 0 #e8e8e8;
  width: 100%;
  transition: all .3s ease-in-out;
  text-align: center;
}

input::-webkit-input-placeholder { color: #ccc;}
input::-webkit-input-placeholder { color: #ccc;}
input::-moz-placeholder { color: #ccc;}
input:-ms-input-placeholder { color: #ccc;}

.ui-counter {
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 3px;
}
.ui-counter > * {
  flex: 1;
}
.btn {
  color: #ea7d1c;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
