

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import DatePicker from 'shared/components/DatePicker.vue';

@Component({
  components: {
    DatePicker
  }
})
export default class endDatePicker extends Vue {
  @Prop() value!: string;
  get date() {
      if (!this.value || !this.value.trim()) return null;
      const parts = this.value.split('-');
      return {
          year: parts[0],
          month: parts[1],
          day: parts[2]
      }
  }
}
