<template>
  <label class="radio" :class="color" :disabled="disabled">
    <input v-bind="this.$attrs" @input="input" v-on="listeners" :checked="selected" type="radio" :name="name"/>
    <span class="check"></span>
    <span class="text">{{labelText}}</span>
  </label>
</template>

<script>
export default {
  name: 'UIRadio',
  inheritAttrs: false,
  props: {
    color: String,
    set: Function,
    selected: Boolean,
    name: String,
    labelText: String,
    disabled: Boolean
  },
	methods: {
    input($event) {
      this.$emit('input', $event.target.checked);
    }
  },
  computed: {
      listeners() {
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }
  }
}
</script>

<style lang="scss" scoped>
  .radio {
    display: flex;
  }
  .radio input {
    position: absolute;
    left: -999px;   
  }
  .text {
    font-weight: 400;
    color: #333;
    line-height: 20px;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  .radio input:disabled ~ .text,
  .radio input:disabled ~ .check {
    cursor: not-allowed !important;
  }
  .check {
    display: inline-block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border: 1px solid #ccc;
    background: transparent;
    color: transparent;
    margin-right: 10px;
    margin-left: 2px;
    margin-top: 1px;
    border-radius: 50%;
    position: relative;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  .radio input:checked ~ .check:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
    font-size: 14px;
    background: #8bc34a;
  }
  .radio input:checked ~ .check {
      border-color: #8bc34a;
      background: white	;
      color: #8bc34a;
  }
  .radio input:focus ~ .check {
	  box-shadow: 0 0 5px #93cea6;
  }

    .radio.blue input:checked ~ .check {
      border-color: #3ba4db;
      background: #3ba4db	;
      color: #3ba4db;
    }

    .radio.pink input:checked ~ .check {
      border-color: #fa6fa3;
      background: #fa6fa3	;
      color: #fa6fa3;
    }

    .radio.black input:checked ~ .check {
      border-color: #000;
      background: #000	;
      color: #000;
    }
    .radio.blue input:checked ~ .text,
    .radio.pink input:checked ~ .text,
    .radio.black input:checked ~ .text {
      color: #333;
      font-weight: 400;
    }
</style>
