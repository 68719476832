import { computed } from 'vue';
import { compose } from 'vue-compose';
import CustomQuestionsCollectionItem from './CustomQuestionsCollectionItem.vue';
import { Props } from './types';
import { Question } from './components/Question/types';
import {
  GetCustomQuestionsCollectionQuery,
  useDeleteCustomQuestionsCollectionMutation,
  useDeleteQuestionMutation,
  useGetCustomQuestionsCollectionQuery,
  useSaveQuestionMutation
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { wrapComponent } from 'shared/apollo-hoc';

type QuestionResponse = ArrayElement<
  NonNullable<GetCustomQuestionsCollectionQuery['customQuestionsCollection']>['questions']
>;

const mapFields = (x: QuestionResponse) => ({
  ...x,
  config: {
    isMultiSelect: x.config?.isMultiSelect || null,
    maxLength: x.config?.maxLength || null,
    minLength: x.config?.minLength || null,
    options: x.config?.options || []
  },
  isSaved: true
});

type CustomQuestionsProps = {
  loadingCustomQuestionsCollection: boolean;
  eventName?: string | null;
  name?: string;
  timestamp?: any;
  questions: Question[];
};
const getCustomQuestionsCollectionEnhancer = wrapComponent<Props, CustomQuestionsProps>((props) => {
  const { loading, result } = useGetCustomQuestionsCollectionQuery(
    computed(() => ({ id: props.id })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => {
    const customQuestionsCollection = result.value?.customQuestionsCollection;

    return {
      loadingCustomQuestionsCollection: loading.value,
      eventName: customQuestionsCollection?.eventName || undefined,
      name: customQuestionsCollection?.name || undefined,
      timestamp: customQuestionsCollection?.timestamp || undefined,
      questions: (customQuestionsCollection?.questions || []).map(mapFields),
      questionsAmount: customQuestionsCollection?.questionsAmount
    };
  });
});

const saveQuestionEnhancer = wrapComponent<Props, Pick<Props, 'saveQuestion' | 'savingQuestion'>>(
  () => {
    const { loading, mutate } = useSaveQuestionMutation();

    return computed(() => ({
      saveQuestion(question, customQuestionsCollectionId) {
        const { order, type, text, required, config, id } = question;

        return mutate(
          { input: { id, customQuestionsCollectionId, order, type, text, required, config } },
          {
            optimisticResponse: {
              saveQuestion: {
                __typename: 'Question',
                order,
                type,
                text,
                required,
                id: '',
                timestamp: new Date(),
                config: {
                  __typename: 'QuestionConfig',
                  ...config
                }
              }
            }
          }
        ).then((result) => {
          const question = result?.data?.saveQuestion;
          if (!question) {
            throw new Error('No question');
          }
          return mapFields(question);
        });
      },
      savingQuestion: loading.value
    }));
  }
);

const deleteQuestionEnhancer = wrapComponent<
  Props,
  Pick<Props, 'deleteQuestion' | 'deletingQuestion'>
>(() => {
  const { loading, mutate } = useDeleteQuestionMutation();

  return computed(() => ({
    deleteQuestion: (questionId) => mutate({ id: questionId }),
    deletingQuestion: loading.value
  }));
});

const deleteCustomQuestionsCollectionEnhancer = wrapComponent<
  Props,
  Pick<Props, 'removeCustomQuestionsCollection'>
>((props) => {
  const { mutate } = useDeleteCustomQuestionsCollectionMutation();

  return computed(() => ({
    removeCustomQuestionsCollection: (id) =>
      mutate(
        { id },
        {
          update() {
            props.router.history.push(`${props.path}`);
          }
        }
      )
  }));
});

export default compose(
  getCustomQuestionsCollectionEnhancer,
  saveQuestionEnhancer,
  deleteQuestionEnhancer,
  deleteCustomQuestionsCollectionEnhancer
)(CustomQuestionsCollectionItem);
