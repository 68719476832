
import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import { ApolloClient } from '@apollo/client';
import Divider from 'shared/layout/divider/layout-divider.vue';
import UISelect from 'shared/ui/forms/Select.vue';
import { getGrade, splitName, validateNumber, validateName, validateEmail, phoneFormat, phoneInternational } from 'shared/util';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import UISwitch from 'shared/ui/forms/Switch.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import SchoolLookup from 'shared/components/SchoolLookup';
import PostHighSchoolLookup from 'shared/components/PostHighSchoolLookup';
import ChapterLookup from 'shared/components/ChapterLookup';
import FancyCheckbox from 'shared/ui/forms/FancyCheckbox.vue';
import SynagogueLookup from 'shared/components/SynagogueLookup';
import AutoSaveField from 'shared/components/AutoSaveField';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import { CompactCommunityMember, FieldInfo, FieldData } from '../../shared/CompactCommunityMember';
import QuickAddTeen from 'shared/components/QuickAddTeen';
import Modal from 'shared/ui/modal/Modal.vue';
import { RouterLink } from 'vue-component-router';
import Address from 'shared/components/Address/Address.vue';
import EmailInput from 'shared/components/EmailInput';
import { CommunityMemberUpdateArgs, CommunityMemberFieldsEnum } from 'shared/components/CommunityMemberProfile/types';
import { Phone, Email, Address as AddressType } from '../../../shared/types';
import { PersonType, PhoneType, UpdatePerson } from 'shared/generated/graphql-types';
import PhoneInput from 'shared/ui/forms/Phone.vue';

@Component({
    name: "Summary",
    methods: {
        getGrade,
        phoneFormat,
        phoneInternational,
        splitName,
        validateEmail,
        validateName,
        validateNumber
    },
    components: {
        PostHighSchoolLookup,
        CollapsibleSection,
        PhoneInput,
        SynagogueLookup,
        ...RadioGroup,
        ChapterLookup,
        AutoSaveField,
        FancyCheckbox,
        SchoolLookup,
        QuickAddTeen,
        SimpleForm,
        UITextarea,
        RouterLink,
        EmailInput,
        UIToggle,
        UISelect,
        UIButton,
        UISwitch,
        UIInput,
        Divider,
        UIRadio,
        Field,
        Modal,
        Error,
        Address
    }
})
export default class extends Vue {
    @Prop() communityMember!: CompactCommunityMember & { [x: string]: any };
    @Prop() personId!: number;
    @Prop() updatePerson!: (args: UpdatePerson, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() updateEmail!: (args: Email, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() updatePhone!: (args: Phone, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() deletePhone!: (id: number) => void;
    @Prop() updateAddress!: (args: AddressType, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() createEmail!: (args: Email, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() createPhone!: (args: Phone, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() createAddress!: (args: AddressType, optimisticResponse?: CommunityMemberUpdateArgs) => void;
    @Prop() regionId!: number;
    @Prop() client!: ApolloClient < any >
    @Prop() country!: string;

    communityMemberCopy: CompactCommunityMember = this.communityMember;
    updateCommunityMemberField = CommunityMemberFieldsEnum;
    phoneType = PhoneType;

    createOrUpdate(args: SaveEventArgs < { value: string, info: FieldInfo } > ) {
        const { value, info: { handler, data } } = args.transformed;
        handler(value, data);
    }
    updateEmailHandler(value: string, { fieldId, fieldName,  personId }: FieldData) {
        this.updateEmail({
            email: value,
            id: fieldId!,
            personId
        },
        { personId, fieldName, value });
    }
    createEmailHandler(value: string, { fieldName,  personId }: FieldData) {
        if (!value) return;
        this.createEmail({
            email: value,
            personId
        },
        { personId, fieldName, value }
        );
    }
    updateAddressHandler(value: string, { fieldName,  personId }: FieldData) {
        this.updateAddress({
            ...JSON.parse(value),
            personId,
        },
        {
          personId,
          fieldName,
          value
        }
        )
    }
    createAddressHandler(value: string, { fieldName,  personId }: FieldData) {
        if (!value) return;
        this.createAddress({
            ...JSON.parse(value),
            personId,
        },
        { personId, fieldName, value }
        );
    }
    updatePhoneHandler(value: string, { fieldId, fieldType, fieldName, personId }: FieldData) {
        if (!value) {
          this.deletePhone(fieldId || -1)
        }
        else {
          this.updatePhone({
              id: fieldId!,
              type: fieldType!,
              phoneNumber: value,
              isActive: true,
              personId,
              doNotCall: false
          },
          { fieldName, value, personId }
          );
        }
    }
    createPhoneHandler(value: string, { fieldType, fieldName,  personId }: FieldData) {
        if (!value) return;
        this.createPhone({
            type: fieldType!,
            phoneNumber: value,
            personId,
            doNotCall: false
        },
        { fieldName, value, personId }
        );
    }
    updatePersonHandler(value: string, { fieldType, fieldName,  personId }: FieldData) {
      const { fullName, gender, olamiId } = this.communityMemberCopy;
      const { firstName, lastName } = splitName(fullName);

      this.updatePerson({
          personId,
          firstName,
          lastName,
          olamiId,
          gender,
          type: PersonType.CommunityMember
      }, {
          personId,
          fieldName,
          value: JSON.stringify({
              firstName,
              lastName,
              olamiId,
              gender
          })
      })
    }
    get addressData (): FieldInfo {
      return {
         handler: this.communityMemberCopy.addressId ? this.updateAddressHandler : this.createAddressHandler,
         data: {
           fieldId: this.communityMemberCopy.addressId,
           personId: this.personId,
           fieldName: CommunityMemberFieldsEnum.address
         }
      }
    }
    get emailData (): FieldInfo {
      return {
         handler: this.communityMemberCopy.emailId ? this.updateEmailHandler : this.createEmailHandler,
         data: {
           fieldId: this.communityMemberCopy.emailId,
           personId: this.personId,
           fieldName: CommunityMemberFieldsEnum.email
         }
      }
    }
    get cellphoneData (): FieldInfo {
      return {
         handler: this.communityMemberCopy.cellphoneId ? this.updatePhoneHandler : this.createPhoneHandler,
         data: {
           fieldId: this.communityMemberCopy.cellphoneId,
           fieldType: PhoneType.Mobile,
           personId: this.personId,
           fieldName: CommunityMemberFieldsEnum.cellphone
         }
      }
    }
    get landlineData (): FieldInfo {
      return {
         handler: this.communityMemberCopy.landlineId ? this.updatePhoneHandler : this.createPhoneHandler,
         data: {
           fieldId: this.communityMemberCopy.landlineId,
           fieldType: PhoneType.Landline,
           personId: this.personId,
           fieldName: CommunityMemberFieldsEnum.landline
         }
      }
    }
    @Watch('communityMember', {
        immediate: true
    })
    CompactCommunityMemberWatcher() {
        if (this.communityMember) this.communityMemberCopy = Object.assign({}, {
            ...this.communityMember,
            password: null
        });
    }
}
