
import Vue from 'vue';
import { Props } from './types';
import ChapterLookup from 'shared/components/ChapterLookup';
import StaffLookup from 'shared/components/StaffLookup';
import TeenLookup from 'shared/components/TeenLookup';
import { isFutureEvent } from '../../../shared/util';

type ExtendedProps = Props & { hideChapter: boolean, regionId: number };

interface Computed {
  isFutureEvent: boolean;
  isRegistrationRequired: boolean;
}

interface Methods {
  isHasErrors: (key: string) => boolean;
}

export default Vue.extend<{}, Methods, Computed, ExtendedProps>({
  name: 'StaffAndChapter',
  components: {
    ChapterLookup,
    StaffLookup,
    TeenLookup
  },
  props: {
    getData: {},
    displayErrors: {},
    setData: {},
    step: {},
    steps: {},
    hideChapter: {},
    regionId: {}
  },
  computed: {
    isFutureEvent() {
      return !!this.steps.details.data && isFutureEvent(this.steps.details.data.FirstEvent, this.steps.details.data.StartTime, this.steps.details.data.TimeZone);
    },
    isRegistrationRequired() {
      return this.steps.registrationAndTickets && this.steps.registrationAndTickets.data && this.steps.registrationAndTickets.data.registrationRequired
    }
  },
  methods: {
    isHasErrors(key) {
      return this.step.validations && this.step.validations[key];
    }
  }
})
