
import Vue from 'vue';
import { FetchResult } from '@apollo/client';
import { RouterLink, Route } from 'vue-component-router';
import BorderedList from "shared/ui/lists/BorderedList.vue";
import UIRadio from "shared/ui/forms/Radio.vue";
import { RadioGroup, RadioGroupItem } from "shared/radio-group";
import UICheckbox from "shared/ui/forms/Checkbox.vue";
import UIButton from "shared/ui/buttons/Button.vue";
import UISearch from "shared/ui/forms/Search.vue";
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import { graduationYears } from 'shared/util';
import Loading from 'shared/components/Loading.vue';
import PromiseQueue from 'easy-promise-queue';
import RegionLookup from 'shared/components/RegionLookup';
import ChapterLookup from 'shared/components/ChapterLookup';
import SchoolLookup, { School } from 'shared/components/SchoolLookup';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { Filters } from './'
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import Avatar from 'shared/components/avatar';
import UIInput from 'shared/ui/forms/Input.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import QuickAddTeen, { CompactTeen } from 'shared/components/QuickAddTeen';
import isArray from 'lodash/isArray';
import toLower from 'lodash/toLower';
import { AttendanceStatus, GetEventQuery, GetTeensForAttendanceQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { Chapter, Me } from 'shared/types';

type Teen = ArrayElement<GetTeensForAttendanceQuery['teens']['teens']>
type Event = GetEventQuery['event']
type Attendance = ArrayElement<Event['Attendances']>

export interface FetchMore {
  limit?: number;
  offset?: number;
}

type SurveyFields = 'participantScreeningToolCompleted' | 'tempatureChecksConducted' | 'complianceWithNCSYAndOUGuidelines';

interface Data {
  addClose: boolean;
  limit: number;
  attending: {[key: number]: boolean};
  survey: {[key in SurveyFields]: boolean | null}
}

interface Props {
  user: Me;
  path: string;
  fetchMore: (args: FetchMore) =>  Promise<FetchResult<GetTeensForAttendanceQuery>> | undefined;
  loadingMore: boolean;
  removingAttende: boolean;
  addingAttende: boolean;
  regionId: number;
  teens: Teen[];
  event: Event;
  addAttendees: (teen: Partial<Teen>) => void;
  removeAttendees: (attendanceId: number) => void;
  bulkAdd: (filters: Filters) => void;
  bulkRemove: (filters: Filters) => void;
  router: { location: { pathname: string } };
  isLoading: boolean;
  filters: Filters;
  setFilters: (filters: Partial<Filters>, overwrite?: boolean) => void;
  bulkAdding: boolean;
  bulkRemoving: boolean;
  addingOrRemovingAll: boolean;
  showAddTeenForm: boolean;
  addOrRemove: string | null;
}

interface Methods {
  addOrRemoveHandler: (addOrRemove: 'add' | 'remove') => void;
  getAttending: () => {[key: number]: boolean};
  attendance: (teen: Teen) => Attendance;
  isAttending: (teen: Teen) => boolean;
  getQueueForTeen: (teen: Teen) => PromiseQueue;
  addTeenHandler: (teen: CompactTeen) => void;
  addOrRemoveAttendees: (teen: Teen) => void;
  fetchMoreHandler: ($state: StateChanger) => void;
  bulk: (action: 'add' | 'remove') => void;
  toggleAddNewTeenForm: () => void;
  getValueFor: (collection: any[], value: string, key: string) => any[];
  setChapters: (value: number[] | Chapter[]) => void;
  setSchools: (value: number[] | School[]) => void;
}

interface Computed {
  attendanceCount: number;
  graduationYears: any[];
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'RecordAttendees',
  components: {
    PanelsSidebar,
    PanelsGrid,
    UIInput,
    UISearch,
    UIButton,
    RadioGroup,
    RadioGroupItem,
    BorderedList,
    UIRadio,
    QuickAddTeen,
    RouterLink,
    Avatar,
    FilterGroup,
    Route,
    Loading,
    RegionLookup,
    ChapterLookup,
    SchoolLookup,
    InfiniteLoading,
    UICheckbox,
    Modal,
    ScrollablePanel,
    UISelect
  },
  directives: {
    fullheight
  },
  data() {
    return {
      addClose: true,
      limit: 60,
      attending: this.getAttending(),
      survey: {
        participantScreeningToolCompleted: null,
        tempatureChecksConducted: null,
        complianceWithNCSYAndOUGuidelines: null
      }
    }
  },
  props: {
    user: {},
    path: {},
    fetchMore: {},
    loadingMore: {},
    removingAttende: {},
    addingAttende: {},
    regionId: {},
    teens: {},
    event: {},
    addAttendees: {},
    removeAttendees: {},
    bulkAdd: {},
    bulkRemove: {},
    router: {},
    isLoading: {},
    filters: {},
    setFilters: {},
    bulkAdding: {},
    bulkRemoving: {},
    addingOrRemovingAll: {},
    showAddTeenForm: {},
    addOrRemove: {}
  },
  computed: {
    graduationYears() {
        return graduationYears().map(x => x.grade);
    },
    attendanceCount () {
      return Object.values(this.getAttending()).filter(x => x).length;
    }
  },
  methods: {
    getValueFor (collection: any[], value: string, key: string) {
      if (isArray(value)) {
        return collection.filter(c => value.includes(c[key] || toLower(c[key])))
      }
      return collection.find(c => toLower(c[key]) === toLower(value))
    },
    addOrRemoveHandler (addOrRemove) {
      this.$emit('addOrRemove', addOrRemove);
      this.$emit('addingOrRemovingAll', true);
    },
    getAttending() {
      return this.event.Attendances.filter(a => a.status === AttendanceStatus.Attended).reduce((attending, attendance) => ({
        ...attending,
        [attendance.Teen.personID]: true
      }), {})
    },
    attendance(teen: Teen) {
      return this.event.Attendances.find(x => x.Teen.personID === teen.personID)!;
    },
    isAttending (teen: Teen) {
      return !!this.attending[teen.personID];
    },
    getQueueForTeen (teen: Teen) {
      this.queues = this.queues || {};
      this.queues[teen.personID] = this.queues[teen.personID] || new PromiseQueue();

      return this.queues[teen.personID];
    },
    addTeenHandler(teen: CompactTeen) {
      if (teen.personID) {
        this.addAttendees({
          personID: teen.personID,
          thumbnail: teen.thumbnail,
          graduationYear: null,
          __typename: 'Teen',
          Person: {
            personID: teen.personID,
            firstName: teen.firstName,
            lastName: teen.lastName,
            __typename: 'Person'
          }
        })
        this.$emit('showAddTeenForm', false);
        this.attending[teen.personID] = true; // this.$set
      }
    },
    addOrRemoveAttendees (teen: Teen) {
      const queue = this.getQueueForTeen(teen)
      this.attending[teen.personID] = !this.isAttending(teen); // this.$set

      
      queue.add(async () => {
        const attendance = this.attendance(teen);

        if (!!!attendance || attendance.status !== AttendanceStatus.Attended) {
          return await this.addAttendees(teen);
        }
        else {
          return await this.removeAttendees(attendance.attendanceId);
        }
      });
    },
    async fetchMoreHandler ($state: StateChanger) {
      const result = await this.fetchMore({
        limit: this.limit,
        offset: this.teens.length
      })

      if (result?.data?.teens.teens && result.data.teens.teens.length < this.limit) {
        $state.complete();
      }
      else {
        $state.reset();
        $state.loaded();
      }
    },
    async bulk(action: 'add' | 'remove') {
      if (action === 'add') {
        await this.bulkAdd(this.filters);
      } else if (action === 'remove'){
        await this.bulkRemove(this.filters);
      }
      this.$emit('addingOrRemovingAll', false)
      this.attending = this.getAttending();
    },
    toggleAddNewTeenForm(){
      this.$emit('showAddTeenForm', !this.showAddTeenForm);
    },
    setChapters(value: number[] | Chapter[]) {
      this.setFilters({ chapterIds: value.map(c => typeof c === 'number' ? c : c.chapterId)})
    },
    setSchools(value: number[] | School[]) {
      this.setFilters({ schoolIds: value.map(s => typeof s === 'number' ? s : s.schoolID)})
    }
  }
})
