
import Vue from 'vue';
import VueSelect from 'vue-select';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { filterNonPrintable } from 'shared/util';

interface Data {
  onSearch: any;
}

export default Vue.extend<Data, {}, {}, {}>({
  name: 'FancySelect',
 	inheritAttrs: false,
  props: {
    options: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    value: {
      type: [Object,String,Number,Array]
    },
    clearable: {
      type: Boolean,
      default: true
    },
    resetAfter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    getOptionKey: {
      type: Function,
      default(option: any) {
        if (typeof option === 'object' && option.id) {
          return option.id
        } else {
          try {
            return JSON.stringify(option)
          } catch(e) {
            console.warn(
              `[vue-select warn]: Could not stringify option ` +
              `to generate unique key. Please provide 'getOptionKey' prop ` +
              `to return a unique key for each option.\n` +
              'https://vue-select.org/api/props.html#getoptionkey'
            )
            return null
          }
        }
      }
    }
  },
  data () {
    return {
      onSearch: null
    }
  },
  computed: {
		valueAsArray () {
            if (!this.value) return []
            const value: any = Array.isArray(this.value) ? this.value : [this.value]
            return value
    },
    props (): any {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.valueAsArray
      }
    }
  },
  methods: {
    async handleInput (value: any) {
      if (isEqual(this.value, value)) return

      this.$emit('input', value);
      this.$emit('blur', value);
      this.$emit('change', value);

      if (this.resetAfter) {
        //@ts-ignore
        this.$refs.multiselect.mutableValue = null;
      }
    },
  },
  created () {
    this.onSearch = this.debounce
      ? debounce((e: string) => this.$emit('search', filterNonPrintable(e)), this.debounce)
      : (e: string) => this.$emit('search', filterNonPrintable(e))
  },
  components: {
    VueSelect
  }
})
