import Vue from 'vue';
import Vuex, { Module, Store } from 'vuex'
import NotificationsModule, { NotificationsState } from './notifications/notifications'
import OidcModule, { OidcState } from './oidc/oidc'
import RegionModule, { RegionState } from './region/region'

export type RootState = {
  notifications: Module<NotificationsState, RootState>,
  oidc: Module<OidcState, RootState>,
  region: Module<RegionState, RootState>
}

type InitState = {
  notifications: NotificationsState,
  oidc: OidcState,
  region: RegionState
}

Vue.use(Vuex);

let store: Store<RootState>

export const createStore = (initState?: Partial<InitState> | undefined): Store<RootState> => {
  if (initState || !store) {
    store = new Vuex.Store<RootState>({
      modules: {
        notifications: {
          ...NotificationsModule,
          state: Object.assign(
            NotificationsModule.state as NotificationsState,
            initState && initState.notifications ? initState.notifications : {}
          )
        },
        oidc: OidcModule,
        region: {
          ...RegionModule,
          state: Object.assign(
            RegionModule.state as RegionState,
            initState && initState.region ? initState.region : {}
          )
        },
      }
    })
  }

  return store
}
