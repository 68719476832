import { render, staticRenderFns } from "./ScholarshipForm.vue?vue&type=template&id=71958972&scoped=true&"
import script from "./ScholarshipForm.vue?vue&type=script&lang=ts&"
export * from "./ScholarshipForm.vue?vue&type=script&lang=ts&"
import style0 from "./ScholarshipForm.vue?vue&type=style&index=0&id=71958972&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71958972",
  null
  
)

export default component.exports