
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import FileSelector from 'shared/components/FileSelector';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';

library.add(faTimes)

@Component({
	name: 'ProfilePhoto',
	components: {
		FileSelector
	}
})
export default class extends Vue {
	@Prop() uploading!: boolean;
	@Prop({ default: '80px' }) width!: string;
	@Prop({ default: '80px' }) height!: string;

	convertedFiles: { file: File, base64: any }[] = [];

	upload (files: File | File[]) {
		this.convertedFiles = [];
		if (!files) {
			return;
		}
		if (Array.isArray(files)) {
			files.map(this.getBase64);
		} else {
			this.getBase64(files);
		}
		this.$emit('input', this.convertedFiles);
		this.$emit('blur');
		this.$emit('update:uploading', true);
	}

	getBase64 (file: File) {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result) {
				this.convertedFiles.push({ file, base64: (reader.result as string).replace(/data:image\/.*?;base64,/, '') });
			}
		};
		reader.onerror = (error) => {
			console.error('Error: ', error);
		};
	}
}
