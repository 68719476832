
import EventItem from '../EventItem'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { RouterLink } from 'vue-component-router';
import { Day } from 'shared/components/Calendar';
import flatMap from 'lodash/flatMap';
import Popover from 'shared/ui/popover/Popover.vue';
import { eventOnDate, getLocalStartDate, splitEventsByWeek, placeEvents, EventWithStartAndEnd } from '../../../../../shared/util';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { library } from '@fortawesome/fontawesome-svg-core';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Loading from 'shared/components/Lock-ui.vue';
import draggable from "vuedraggable";
import { directive as onClickaway } from 'vue-clickaway2';
import { Dates } from '../../types';
import { GetEventsQuery, SeriesEventsFilterInput } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type IEvent = ArrayElement<GetEventsQuery['events']>

library.add(faAngleDown)

const weekDays = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thurs',
  'Fri',
  'Sat'
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

// TODO draggable messes up the presentation of the events on the calendar
@Component({
  components: {
    EventItem,
    Popover,
    RouterLink,
    ScrollablePanel,
    draggable,
    Loading
  },
  directives: {
    onClickaway
  },
  methods: {
    getLocalStartDate
  }
})
export default class MonthView extends Vue {
  @Prop({type: Date}) date!: Date
  @Prop() weeks!: Day[][]
  @Prop() events!: IEvent[]
  @Prop() loading!: boolean;
  @Prop() dates!: Dates;
  @Prop() filters!: SeriesEventsFilterInput;

  dragable: boolean = true;
  isDragging: boolean = false;
  delayedDragging: boolean = false;
  log (...args: any[]) {
   console.log(args)
  }
  created () {
    this.weekDays = weekDays;
  }
  clickAwayHandler() {
      // const selectedRow = this.content.find(x => x.rowId === this.selectedRowId);
      // const elementType = selectedRow && selectedRow.text;

      // if (elementType === 'Divider' && this.editingDivider) return
      // if (elementType === 'Button' && this.editingButton) return

      // else {
      //     this.editorMode = false;
      //     this.dragable = true;
      //     this.selectedRowId = null;
      // }

  }
  async draggingEndHandler({ added: { newIndex, element, element: { text } } }: any) {
      // this.indexOftheLastAddedElement = newIndex
      // if (text === 'Image') {
      //     this.content.splice(this.indexOftheLastAddedElement!, 1)
      //     // @ts-ignore
      //     this.$refs.file.click()
      //     return
      // }
      // if (text === 'Text' || text === 'Button') {
      //     this.editRowHandler(newIndex)
      // }
      // this.isDragging = false
  }
  onMove({ relatedContext, draggedContext }: any) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
  }
  get dragOptions() {
      return {
          disabled: !this.dragable,
          ghostClass: "ghost"
      };
  }
  get todayPosition () {
    if (!this.today) return

    const rs = (this.today.week) * 5 + 1;
    const cs = this.date.getDay() + 1;

    return `${rs} / ${cs} / ${rs + 5} / ${cs + 1}`;
  }

  get today () {
    const pad = (n: number) => n < 10 ? `0${n}` : n

    const today = `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1)}-${pad(new Date().getDate())}`;

    return flatMap(
      this.weeks.map((x, i) => [...x.map(x => ({ ...x, week: i }))])
    )
    .find(x => x.date === today);
  }

  get month () {
    return months[this.date.getMonth()]
  }

  get year () {
    return this.date.getFullYear();
  }

  get previousMonth () {
    return new Date(this.year, this.date.getMonth() - 1, 1);
  }

  get nextMonth () {
    return new Date(this.year, this.date.getMonth() + 1, 1);
  }

  eventsByWeek: ReadonlyArray<[EventWithStartAndEnd[], EventWithStartAndEnd[], EventWithStartAndEnd[], number[]]> = []

  @Watch('events', {
    immediate: true
  })
  setEventsByWeek () {
    this.eventsByWeek = []

    requestAnimationFrame(() => {
      this.eventsByWeek = Object.freeze(splitEventsByWeek(this.events, this.weeks)
      .map((events, week) => placeEvents(events, this.weeks[week])));
    })
  }
  headerToday(day: number) {
    if (!this.today) return false

    const rs = (this.today.week) * 5 + 1;
    const cs = this.date.getDay();

    if ((rs === 1) && (day === cs)) {
      return true
    } else {
      return false
    }

  }
  dayLabelPosition(day: Day, week: Day[], weekIndex: number) {
    const rs = weekIndex * 5 + 1;
    const cs = week.indexOf(day) + 1;
    const re = rs + 1;
    const ce = cs + 1;

    return `${rs} / ${cs} / ${re} / ${ce}`
  }

  getMoreEventPosition(day: number, week: Day[], weekIndex: number) {
    const rs = weekIndex * 5 + 5;
    const cs = week.findIndex(x => x.day === day) + 1;
    const re = rs + 1;
    const ce = cs + 1;

    return `${rs} / ${cs} / ${re} / ${ce}`
  }

  getEventPosition(event: EventWithStartAndEnd, weekIndex: number, row: number) {
    const rs = weekIndex * 5 + 2 + row;
    const cs = event.startDoW + 1;
    const re = rs + 1;
    const ce = cs + (event.endDoW - event.startDoW) + 1;

    return `${rs} / ${cs} / ${re} / ${ce}`
  }

  dayClass (day: Day) {
    if (!day.thisMonth || !this.today) return 'not-this-month';

    if (day.date === this.today.date) {
      return 'today'
    }
  }

  eventsOnDay (dayNumber: number, week: Day[]) {
    const day = week.find(x => x.day === dayNumber);

    if (day === undefined) return []

    return this.events.filter(x => eventOnDate(x, day.date));
  }

  formatHour (time: Date) {
      var date = new Date(time);

      let hours = date.getHours();
      let min = date.getMinutes();
      let ampm = hours > 11 ? 'pm' : 'am';
      let newMin : String;

      hours = hours > 12 ? hours - 12 : hours;

      if (min > 0) {
        if (min < 10) {
          newMin = ':0' + min
        } else {
          newMin = ':' + min
        }
      } else {
        newMin = ''
      }

      let eventTime = hours + '' + newMin + '' + ampm;

      return eventTime
    }
    @Watch('isDragging')
    isDraggingWatcher(newValue: boolean) {
        if (newValue) {
            this.delayedDragging = true;
            return;
        }
        this.$nextTick(() => {
            this.delayedDragging = false;
        });
    }
}
