import FetchMoreOptions from './shared/FetchMoreOptions';
import { toCompactTeens } from './utils';
import { compose } from 'vue-compose';
import Vue, { computed, VueConstructor } from 'vue';
import { StateChanger } from 'vue-infinite-loading';
import { ApolloQueryResult } from '@apollo/client';
import { getOptions, normalizeProps } from 'shared/util';
import Layout from './Layout.vue';
import ActiveFilters, { ActiveFiltersType } from './shared/ActiveFilters';
import { staffLookupEnhancer } from 'shared/components/StaffLookup';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetFilterOptionsQuery,
  TeensImpactQuery,
  TeensImpactStatisticsQuery,
  useGetFilterOptionsQuery,
  useTeensImpactQuery,
  useTeensImpactStatisticsQuery
} from 'shared/generated/graphql-types';
import CompactTeen from './shared/CompactTeen';

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { activeFilters, limit, setFilters, setLimit, totalPages, resetFilters, ...propsToUse } =
    props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: propsToUse,
    data() {
      return {
        limit: 50,
        activeFilters: new ActiveFilters()
      };
    },
    methods: {
      setFilters(args: Partial<ActiveFiltersType>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      },
      setLimit(limit: number) {
        this.limit = limit;
      },
      resetFilters() {
        this.activeFilters = new ActiveFilters();
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          activeFilters: this.activeFilters,
          setFilters: this.setFilters,
          limit: this.limit,
          setLimit: this.setLimit,
          resetFilters: this.resetFilters
        },
        on: this.$listeners
      });
    }
  });
};

interface Props {
  regionId: number;
  activeFilters: ActiveFiltersType;
  limit: number;
}

type FilterOptionsProps = {
  filterOptions: GetFilterOptionsQuery;
};

export const filterOptionsEnhancer = wrapComponent<Props, FilterOptionsProps>((props) => {
  const { result } = useGetFilterOptionsQuery(
    computed(() => ({ regionId: props.regionId })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    filterOptions: result.value
  }));
});

type TeensImpactStatisticsProps = {
  teensImpactStatistics: TeensImpactStatisticsQuery['teensImpactStatistics'] | null;
};
export const teensImpactStatisticsEnhancer = wrapComponent<Props, TeensImpactStatisticsProps>(
  (props) => {
    const { result } = useTeensImpactStatisticsQuery(
      computed(() => ({
        filter: {
          regionId: props.regionId,
          ...props.activeFilters
        }
      })),
      { fetchPolicy: 'network-only' }
    );

    return computed(() => ({
      teensImpactStatistics: result.value?.teensImpactStatistics || null
    }));
  }
);

type TeensImpactProps = {
  teens: CompactTeen[];
  initialLoading: boolean;
  loading: boolean;
  total: number;
  fetchMore: (
    { limit, offset }: FetchMoreOptions,
    stateChanger: StateChanger
  ) => Promise<ApolloQueryResult<TeensImpactQuery>> | undefined;
};
export const teensImpactEnhancer = wrapComponent<Props, TeensImpactProps>((props) => {
  const { fetchMore, loading, result } = useTeensImpactQuery(
    computed(() => ({
      limit: props.limit,
      offset: 0,
      filter: {
        regionId: props.regionId,
        ...props.activeFilters
      },
      regionId: props.regionId
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    teens: toCompactTeens(result.value?.teens.teens || [], props.activeFilters),
    initialLoading: loading.value && !result.value?.teens,
    loading: loading.value,
    total: result.value?.teens.total || 0,
    fetchMore({ limit, offset }: FetchMoreOptions) {
      return fetchMore({
        variables: {
          limit,
          offset,
          filter: {
            regionId: props.regionId,
            isAlumni: props.activeFilters && props.activeFilters.isAlumni,
            isJewish: true,
            query: props.activeFilters && props.activeFilters.query,
            sortBy: props.activeFilters && props.activeFilters.sortBy,
            filters: props.activeFilters && props.activeFilters.filters
          }
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          const newData =
            (fetchMoreResult && fetchMoreResult.teens && fetchMoreResult.teens.teens) || [];
          return {
            teens: {
              __typename: 'TeenPage',
              total: fetchMoreResult?.teens.total || previousResult.teens.total || 0,
              teens: [...newData]
            }
          };
        }
      });
    }
  }));
});

export default compose(
  withFilters,
  staffLookupEnhancer,
  teensImpactEnhancer,
  teensImpactStatisticsEnhancer,
  filterOptionsEnhancer
)(Layout);
