<template>
	<div class="pagination">
		<div class="text">
			{{current}} of {{total}}
		</div>
		<div class="btns">
			<button @click="$emit('prev')" class="prev btn" :disabled="current == 1" :class="{disabled: current == 1}"><span></span></button>
			<button @click="$emit('next')" class="next btn" :disabled="current == total" :class="{disabled: current == total}"><span></span></button>
		</div>
	</div>
</template>
<script>
export default {
  name: 'Pagination',
  props: ['current', 'total']
};
</script>

<style lang="scss" scoped>
.pagination {
	display: flex;
	align-items: center;
}
.text {
	margin-right: 20px;
	font-weight: 600;
	font-size: 16px;
}
.btn {
	margin: 0 1px;
	border: 1px solid #ddd;
	width: 28px;
	height: 26px;
	text-align: center;
	position: relative;

	span {
		border: 6px solid transparent;
		display: block;
		position: absolute;
    	top: 6px;
	}
	&.disabled {
		opacity: .4;
	}
	&.next span{
		border-left-color:#ee7c16;
		right: 3px;
	}
	&.prev span{
		border-right-color:#ee7c16;
		left: 3px;
	}

	&:hover:not(.disabled) {
		background: #ee7c162e;
	}
}
</style>
