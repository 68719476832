import { wrapComponent } from 'shared/apollo-hoc';
import { GetGoalDocument, GetGoalQuery, MarkAsLeadMutation, TeensForSheetQuery, useMarkAsLeadMutation } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { MutateResult } from '@vue/apollo-composable';
import { ArrayElement } from 'shared/util/types';

export interface Lead {
  teen: ArrayElement<TeensForSheetQuery['teens']['teens']>;
  goalId: number;
}

export interface Props {
  markAsLead: (personId: number, goalId: number) => MutateResult<MarkAsLeadMutation>;
  markingAsLead: boolean;
}

const mutation = wrapComponent<{}, Pick<Props, 'markAsLead' | 'markingAsLead'>>(() => {
  const { loading, mutate } = useMarkAsLeadMutation()

  return computed(() => ({
    markAsLead: (personId, goalId) => mutate({input: {goalId, personId}}, {
      update(proxy, { data: updateData }) {
        let goal: GetGoalQuery | null = null;
        try {
          goal = proxy.readQuery<GetGoalQuery>({
            query: GetGoalDocument,
            variables: { id: goalId }
          });
        } catch(e) {}
        if (goal?.goal && updateData?.markAsLead) {
          const leads = (goal.goal.Leads || []).slice();
          leads.push(updateData.markAsLead);
          proxy.writeQuery<GetGoalQuery>({
            query: GetGoalDocument,
            variables: { id: goalId },
            data: {
              goal: {
                ...goal.goal,
                Leads: leads
              }
            }
          });
        }
      }
    }),
    markingAsLead: loading.value
  }))
})

export const enhancer = mutation;
