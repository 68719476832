
import Vue from 'vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Note from './components/Note/Note.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { Props, NoteFormObject } from './types';
import NoteForm from './components/note-form.vue';
import Loading from 'shared/components/Loading.vue';

export default Vue.extend<{}, {}, {}, Props>({
    name: 'Notes',
    props: {
        addTeenNote: {},
        editTeenNote: {},
        addingNewNote: {},
        removeTeenNote: {},
        notes: {},
        isLoading: {},
        currentStaff: {}
    },
    components: {
        BorderedList,
        Note,
        Modal,
        Loading,
        NoteForm
    }
})
