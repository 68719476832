import { StaffViewModel, Address } from './types';
import { formatAddressToString, phoneInternational } from 'shared/util';
import { GetStaffProfileQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type AdvisorRegion = NonNullable<GetStaffProfileQuery['singleStaff']>
type EventStaff = ArrayElement<StaffType['EventStaff']>
type StaffType = NonNullable<GetStaffProfileQuery['singleStaff']>['Staff']

export const getStaffAddress = (staff: StaffType): Address | null => {
  if (!staff) return null;
  const { city, state, street, zipCode, country } = staff;
  return {city, state, street, zipCode, country };
}
export const toCompactStaff = (staff: AdvisorRegion): StaffViewModel => {
  const addressObject = getStaffAddress(staff.Staff);
  return {
      firstName:       staff.Staff.firstName,
      advisorRegionId: staff.AdvisorRegionId,
      lastName:        staff.Staff.lastName,
      address:         addressObject || null,
      formattedAddress: formatAddressToString(addressObject),
      staffId:         staff.Staff.staffID,
      gender:          staff.Staff.gender,
      street:          staff.Staff.street,
      city:            staff.Staff.city,
      state:           staff.Staff.state,
      zipCode:         staff.Staff.zipCode || null,
      country:         staff.Staff.country,
      email:           staff.Staff.email || null,
      primaryPhone:    staff && staff.Staff.primaryPhone,
      secondaryPhone:  staff && staff.Staff.secondaryPhone,
      regionName:      staff.Region && staff.Region.regionName,
      chapterId:       staff.Chapter ? staff.Chapter.chapterId : null,
      regionId:        staff.Region && staff.Region.regionId,
      chapterName:     staff.Chapter ? staff.Chapter.chapterName : null,
      fullName:        staff.Staff.fullName,
      birthDate:       staff.Staff.birthDate || null,
      thumbnail:       staff.Staff.thumbnail || '',
      permissions:     staff.Permissions || [],
      original:        staff
    };
};

export class Staff {
  staffID: number;
  firstName: string;
  lastName: string;
  fullName: string;
  gender: number;
  street: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  country: string | null;
  birthDate: Date | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  email: string | null;
  EventStaff: EventStaff[];

  constructor(args: StaffViewModel) {
    this.staffID = args.staffId;
    this.firstName = args.firstName;
    this.lastName = args.lastName;
    this.fullName = args.fullName;
    this.gender = args.gender;
    this.street = args.street;
    this.city = args.city;
    this.state = args.state;
    this.zipCode = args.zipCode;
    this.country = args.country;
    this.birthDate = args.birthDate;
    this.primaryPhone = args.primaryPhone ? phoneInternational(args.primaryPhone) : null;
    this.secondaryPhone = args.secondaryPhone ? phoneInternational(args.secondaryPhone) : null;
    this.email = args.email;
    this.EventStaff = args.original?.Staff.EventStaff || [];
    }
}

export function calcStaffUpdate(staff: StaffViewModel): AdvisorRegion {
  return {
      ...staff.original,
      Staff: {
        ...(new Staff(staff) as StaffType),
          __typename: 'Staff',
      }
  };
}
