
import Vue from 'vue';
import SimpleForm, { Error, Field, SubmitCallbackArgs } from 'vue-simpleform';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import UICounter from 'shared/ui/forms/Counter.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { Props as BusingProps } from '../types';
import { CreateBusInput } from 'shared/generated/graphql-types';

library.add(faTimes);

type Errors<T> = { [k in keyof T]?: string };

interface Data {
  bus: CreateBusInput;
}

interface Props {
  eventId: number;
  createBus: BusingProps['createBus'];
}

interface Methods {
  close: () => void;
  validate: (value: CreateBusInput) => Errors<CreateBusInput>;
  create: (callbackArg: SubmitCallbackArgs<CreateBusInput>) => Promise<void>;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddBusForm',
  components: {
    UIButton,
    UITextarea,
    UIInput,
    UICounter,
    UIRadio,
    ...RadioGroup,
    SimpleForm,
    Error,
    Field
  },
  data() {
    return {
      bus: {
        description: '',
        meetingAddress: '',
        directionFlag: 'B'
      }
    };
  },
  props: {
    eventId: {},
    createBus: {}
  },
  methods: {
    close() {
      this.$emit('addingNewBus', false);
    },
    validate(value: CreateBusInput) {
      const errors: Errors<CreateBusInput> = {};

      if (!value.description || !value.description.trim().length) {
        errors.description = 'Description is required';
      }

      if (!value.meetingAddress || !value.meetingAddress.trim().length) {
        errors.meetingAddress = 'Meeting Address is required';
      }

      if (value.meetingAddress && value.meetingAddress.trim().length > 255) {
        errors.meetingAddress = 'Meeting Address should be less than 255 symbols';
      }

      return errors;
    },
    async create(callbackArg: SubmitCallbackArgs<CreateBusInput>) {
      if ('errors' in callbackArg) return;
      if (callbackArg.setSubmitting) {
        callbackArg.setSubmitting();
      }
      await this.createBus({
        ...callbackArg.values,
        cost:
          callbackArg.values.cost || callbackArg.values.cost === 0
            ? +callbackArg.values.cost
            : null,
        capacity:
          callbackArg.values.capacity || callbackArg.values.capacity === 0
            ? +callbackArg.values.capacity
            : null
      });
      this.close();
    }
  }
});
