
import Vue from 'vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { RouterLink, Route, Redirect } from 'vue-component-router';
import EventsPage from './pages/Events';
import EventsTable from './pages/Events/EventTable';
import ScheduleEvent from './pages/Events/ScheduleEvent';
import StaffPage from './pages/Staff';
import TeensPage from './pages/Teens';
import Chapters from './pages/Chapters';
import FamiliesPage from './pages/Families';
import CommunityMembersPage from './pages/CommunityMembers';
import Duplicates from './pages/Duplicates';
import Financials from './pages/Financials';
import Fellows from './pages/Fellows';
import Dashboard from './pages/Dashboard';
import PostHS from './pages/PostHS';
import MissingSchools from './pages/MissingSchools';
import Impact from './pages/Impact';
import JFETeens from './pages/JFE/JFETeens';
import JFEParents from './pages/JFE/JFEParents';
import JFEOrders from './pages/JFE/JFEOrders';
import JFEExport from './pages/JFE/JFEExport';
import Reports from './pages/Reports';
import Goals from './pages/Goals';
import MyLists from './pages/MyLists';
import PushNotifications from './pages/PushNotifications';
import GlobalSearch from './components/GlobalSearch';
import EmailBuilder from 'shared/components/EmailBuilder';
import MainNav from './components/MainNav.vue';
import Loading from 'shared/components/Loading.vue';
import UISelect from 'shared/ui/forms/Select.vue';
import { directive as onClickaway } from 'vue-clickaway2';
import Popover from 'shared/ui/popover/Popover.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons/faClipboard';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import LogRocket from 'logrocket';
import sortBy from 'lodash/sortBy';
import fullheight from 'shared/directives/fullheight';
import RequiresPermission from 'shared/components/RequiresPermission';
import UIButton from 'shared/ui/buttons/Button.vue';
import { ApolloError } from '@apollo/client';
import { PermissionEnum } from 'shared/generated/graphql-types';
import { Me } from 'shared/types';
import Cleanup from './pages/Cleanup';
import FeatureRequests from './pages/FeatureRequests';

library.add(faPlusCircle, faClipboard)
library.add(faIndent,faOutdent,faAngleDown)

const Logrocket = Vue.extend({
	props: {
		me: {
			type: Object as () => Me
		}
	},
	mounted () {
		LogRocket.identify(String(this.me.staffID), {
      name: `${this.me.firstName} ${this.me.lastName}`,
      email: this.me.email || ''
		});
	},
	render: (h) => h()
})

@Component({
  components: {
    Logrocket, Route, RouterLink, EventsPage, EventsTable, GlobalSearch, ScheduleEvent, MainNav, StaffPage, Loading,
    TeensPage, Chapters, Duplicates, Financials, Reports, UISelect, Popover, CommunityMembersPage, ScrollablePanel, Fellows, FamiliesPage,
    RequiresPermission, EmailBuilder, PostHS, MissingSchools, Impact, JFETeens, JFEParents, JFEOrders, JFEExport, Dashboard, Redirect, PushNotifications,
    UIButton, Goals, MyLists, Cleanup, FeatureRequests
  },
  directives: {
    onClickaway: onClickaway,
		fullheight
	  },
	mounted() {
		window.addEventListener('resize', this.handleResize)
		this.handleResize()
	}
})
class MainLayout extends Vue {
	@Prop({ type: String }) authState!: 'authenticating' | 'redirecting'
	@Prop({ type: Number }) currentRegion!: Number;
  @Prop({ type: Function }) setCurrentRegion!: Function;
  @Prop() staffLoading!: boolean;
	@Prop() staff!: Me;
	@Prop({ type: Function }) addNotification!: Function;
  @Prop() error!: ApolloError | null;
  PermissionEnum = PermissionEnum;

	@Watch('availableRegions', {
		immediate: true
	})
	onAvailableRegionsChanged (regions: Array<{ regionId: number }>) {
		if (regions && regions.length === 1) {
			this.setCurrentRegion(regions[0].regionId);
		}
	}

	get availableRegions () {
		const regions = this.staff && (this.staff.AdvisorRegions || [])
			.filter(x => x.active && (x.claims || []).find(p => p === PermissionEnum.AccessSite))
			.map(x => x.Region)
			return sortBy(regions, ['regionName']);
  }
	get currentRegionName () {
		const Region = this.availableRegions.find(region => region.regionId==this.currentRegion);
		 return Region? Region['regionName']: 'No region selected';
	}
	get country () {
		const Region = this.availableRegions.find(region => region.regionId==this.currentRegion);
		return Region? Region['country']: 'Country not found';
  }

  get currentAdvisorRegion() {
    return this.staff && this.currentRegion &&
      this.staff.AdvisorRegions.find(a => a.Region.regionId === this.currentRegion) || { claims: [] };
  }

	width: number = 0

	menuOpen: boolean = false

	menuUserCtrl: boolean = false

	menuAdminOpen: boolean = false
	menuJFEOpen: boolean = false

	get tooltipClasses() {
		if (this.menuOpen) return 'hide-important'
	}

	menuAdminToggle (): void {
		this.menuOpen = true
		this.menuAdminOpen =  this.menuAdminOpen ? false : true;
	}
	menuJFEToggle (): void {
		this.menuOpen = true
		this.menuJFEOpen =  this.menuJFEOpen ? false : true;
	}
	menuToggle (): void {
		this.menuOpen =  this.menuOpen ? false : true;
		this.menuUserCtrl = true
	}

	logout () {
    if(process.env.NODE_ENV !== 'production') {
      (window as any).$chatwoot.reset();
    }
    this.$store.dispatch('oidc/signOutOidc')
	}

	handleResize (event: any) {
	  this.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
	  if (this.menuUserCtrl === false) {
		if (this.width > 1400) {
		  this.menuOpen = true
	  	}
	  	if (this.width < 1100){
		  this.menuOpen = false
	  	}
	  }
	}
	@Watch('staff', { deep: true })
	emailWatcher() {
		if (this.staff) {
			const { staffID, email, fullName } = this.staff;
      if (process.env.NODE_ENV === 'production') {
        this.$intercom.boot({
          user_id: staffID,
          name: fullName,
          email,
        });
      } else {
        // TODO get typings set up
        // TODO send through region info if we have it
        (window as any).$chatwoot.setUser(staffID, {
          email,
          name: fullName
        });
      }
		}
	}
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    }
}

export default MainLayout;
