

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import StaffLookup from 'shared/components/StaffLookup';

@Component({
  components: {
    StaffLookup
  }
})
export default class advisorIdPicker extends Vue {
  @Prop() value!: number;
}
