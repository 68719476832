
import { Vue, Prop, Component } from 'vue-property-decorator';
import Sidebar from './components/Sidebar';
import HouseDisplay from './components/House';
import AddHouseForm from './components/AddHouseForm.vue';
import Hotel from './shared/Hotel';
import AssignableEventStaff from './shared/AssignableEventStaff';
import flatten from 'lodash/flatten'
import orderBy from 'lodash/orderBy'
import Modal from 'shared/ui/modal/Modal.vue';
import PotentialHousee from './shared/PotentialHousee';
import { InView } from 'shared/components/InView';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import { EditHouseInput, GetEventQuery, GetHousesForEventQuery, StatusType, UpdateHouseRequestsMutationVariables } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = GetEventQuery['event']
type House = ArrayElement<GetHousesForEventQuery['houses']>

@Component({
	name: 'Housing',
	components: {
		PanelsGrid,
		Sidebar,
		HouseDisplay,
		AddHouseForm,
		Modal,
		Loading,
		InView,
		ScrollablePanel
	},
	directives: {
		fullheight
	},
})
export default class extends Vue {
  @Prop() eventId!: number;
  @Prop() event!: Event | null;
  @Prop() houses!: House[];
  @Prop() gridView!: boolean;
  @Prop() addingNewHouse!: boolean;
  @Prop({ required: true }) loadingHouses!: boolean;
  @Prop({ required: true }) addNewHouse!: (eventId: number, house: House, staff?: AssignableEventStaff) => void;
  @Prop() setHouse!: (id: number, house: House, type: string, p: PotentialHousee) => void;
  @Prop() unsetHouse!: (id: number, type: string, house: House) => void;
  @Prop() removeHouse!: (id: number) => void;
  @Prop() editHouseNotes!: (houseId: number, notes: string, house: House) => void;
  @Prop() editHouse!: (eventId: number, house: EditHouseInput, originalHouse: House) => void;
  @Prop() updateHouseRequests!: (UpdateHouseRequests: UpdateHouseRequestsMutationVariables, house: House) => void;
  @Prop({ required: true }) addHotel!: (eventId: number, hotel: Hotel) => void;
	@Prop({default: null, type: String}) gender!: string | null
	@Prop({default: null, type: String}) status!: string | null
	@Prop() term!: string

  get staffRegistered () {
    if (!this.event || !this.event.EventStaff) return 0;
    return this.event.EventStaff.length
  }
  get teensRegistered () {
    return this.relevantRegistrations.length
  }

  get eventStaff (): AssignableEventStaff[] {
    if (!this.event || !this.event.EventStaff) {
      return [];
    }
    return this.event.EventStaff.map(es => ({
      staffId: es.Staff.staffID,
      eventStaffId: es.eventStaffId,
      fullName: es.Staff.fullName,
      gender: es.Staff.gender,
      currentHouses: (this.houses || [])
        .filter(h => h.Staff && h.Staff.eventStaffId === es.eventStaffId)
        .map(h => h.houseId)
    }));
  }

  get relevantRegistrations() {
    if (!this.event || !this.event.Registrations) return [];
    return this.event.Registrations.filter(r => r.status === StatusType.Completed);
  }

  get guestsRegistered () {
    if (!this.event || !this.event.EventGuests) return 0;
    return this.event.EventGuests.length
  }
  get totalHoused (): number {
    var eventStaff = flatten(this.houses.map(x => x.EventStaff)).length
    var eventGuests = flatten(this.houses.map(x => x.EventGuests)).length
    var teensRegistered = flatten(this.houses.map(x => x.Registrations)).length
    return eventStaff + eventGuests + teensRegistered;
  }
  get stats () {
    if (!this.event) return null
    const { teensRegistered, staffRegistered, guestsRegistered, totalHoused } = this;
    return {
      teensRegistered,
      guestsRegistered,
      staffRegistered,
      totalHoused,
      malesNeedRooms: this.relevantRegistrations.filter(x => x.Teen.Person.gender === 0 && !x.House).length,
      femalesNeedRooms: this.relevantRegistrations.filter(x => x.Teen.Person.gender === 1 && !x.House).length,
      staffNeedRooms: this.event.EventStaff.length - flatten(this.houses.map(x => x.EventStaff)).map(x => x.Staff.staffID).length,
      guestsNeedRooms: this.event.EventGuests.length - flatten(this.houses.map(x => x.EventGuests)).map(x => x.eventGuestId).length,
      totalRooms: this.houses.length
      }
	}

	get filteredHouses(){
		let result = this.houses.filter(
						house =>
			`${house.description}`.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0
		);

		let genderPredicate: (h: House) => boolean;
		switch (this.gender) {
			case null:
				genderPredicate = h => true;
				break;
			case 'NS':
				genderPredicate = h => !!!h.gender;
				break;
			default:
				genderPredicate = h => h.gender === this.gender;
				break;
		}
		let statusPredicate: (h: House) => boolean;
		switch (this.status) {
			case 'empty':
				statusPredicate = (h: House) => !h.registrationCount;
				break;
			case 'overbooked':
				statusPredicate = (h: House) => !!h.capacity && h.registrationCount > h.capacity;
				break;
			case 'missing':
				statusPredicate = (h: House) => !(h.EventStaff || []).length;
				break;
			default:
				statusPredicate = (h: House) => true
				break;
    }
    result = result.filter(genderPredicate).filter(statusPredicate);
    const nonSpecified = orderBy(result.filter(h => !h.gender), [(h) => h.description!.toLowerCase()],['asc']);
    const males = orderBy(result.filter(h => h.gender === 'M'), [(h) => h.description!.toLowerCase()],['asc']);
    const females = orderBy(result.filter(h => h.gender === 'F'), [(h) => h.description!.toLowerCase()],['asc']);
    const array = males.concat(females).concat(nonSpecified);
		return array
	}

	get potentialHousees(): PotentialHousee[]{
    if (!this.event) return []
		return [
				...(this.relevantRegistrations || []).map(r => new PotentialHousee(r, "registration")),
				...(this.event.EventStaff || []).map(s => new PotentialHousee(s, "eventstaff")),
				...(this.event.EventGuests || []).map(g => new PotentialHousee(g, "eventguests"))
			];
	}
}

