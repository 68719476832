
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import fileUploads from 'shared/util/fileUploads';
import { ApolloClient } from '@apollo/client';

@Component({
  name: 'EditorContainer',
  components: {
    VueEditor
  },
  data() {
    let toolbarOptions = {
      bounds: '#quill-editor',
      modules: {
        toolbar: {
          container: [
            [{ font: [] }],
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ color: [] }, { background: [] }],
            ["link", "image", "video"],
            ["clean"],
            ["showHtml"]
          ],
          handlers: {
            handleImageAdded: this.handleImageAdded,
            showHtml: () => {
              if (this.txtArea.style.display === "") {
                const html = this.txtArea.value;
              if (html === '<p><br/></p>') {
                this.html = null;
              } else {
                this.html = html.replace(new RegExp('<p><br/>', 'g'), '<p>')
              }
                this.quill.pasteHTML(html);
              }
              this.txtArea.style.display =
                this.txtArea.style.display === "none" ? "" : "none";
            }
          }
        }
      }
    };
    return {
      toolbarOptions,
    };
  },
  mounted() {
    this.quill = (this.$refs.editor as any).quill;
    this.txtArea = document.createElement("textarea");
    this.txtArea.style.cssText =
      "width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;";

    this.txtArea.addEventListener("blur", () => {
      this.quill.pasteHTML(this.txtArea.value);
    })

    const htmlEditor = this.quill.addContainer("ql-custom");
    htmlEditor.appendChild(this.txtArea);

    this.quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
      var html = this.quill.getHTML();
      this.txtArea.value = html;
    });

    this.content = this.value;
  },
  beforeDestroy() {
    this.txtArea.removeEventListener("blur", () => {
      this.quill.pasteHTML(this.txtArea.value);
    })
  },
})
export default class EditorContainer extends Vue {

  @Prop() value!: string;
  @Prop() client!: ApolloClient<any>;

  content: string = this.value || '';

  async handleImageAdded(file: File, Editor: VueEditor, cursorLocation: { x: number; y: number; }, resetUploader: () => void) {
    if (!file) return
    this.uploading = true;
    fileUploads(this.client, file, Editor, cursorLocation);
    this.uploading = false;
    resetUploader();
}

  @Watch('content')
  contentUpdated() {
    this.$emit('update:value', this.content);
  }
}
