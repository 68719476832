<template>
  <label class="checkbox">
    <input v-bind="$attrs" v-on="listeners" type="checkbox" :checked="value" @change="onChange"/>
    <span class="check"><font-awesome-icon :icon="['far','check']" /></span>
    <span class="text">{{labelText}}</span>
  </label>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';

library.add(faCheck);

export default {
  name: 'UICheckbox',
  props: {
    value: {
      type: Boolean
    },
    labelText: {
      type: String
    },
    skipChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: $event => {
          this.$emit('input', $event.target.checked)
        },
        blur: $event => {
          this.$emit('blur', $event.target.checked)
        }
      };
    }
  },
  methods: {
    onChange ($event) {
      if (!this.skipChange) {
        this.$emit('input', $event.target.checked);
        this.$emit('blur', $event.target.checked);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .checkbox {
    display: flex;
    cursor: pointer;

    &[disabled="disabled"] {
      cursor: not-allowed;
    }
  }
  .checkbox input {
    position: absolute;
    left: -9999px;
    visibility: hidden
  }
  .text {
    font-weight: 400;
    color: #333;
    line-height: 20px;
    transition: all .2s ease-in-out;
  }
  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    min-width: 17px;
    font-size: 9px;
    margin-left: 2px;
    margin-top: 1px;
    border: 1px solid #ccc;
    background: transparent;
    color: transparent;
    margin-right: 10px;
    border-radius: 2px;
    position: relative;
    color: white;
  }
  .check .fa-check {
    display: none;
  }
  .checkbox input:checked ~ .check,
  .checkbox[checked="checked"] input ~ .check
   {
    border-color: #8bc34a;
    color: #8bc34a	;
  }
  .checkbox input:focus ~ .check {
    box-shadow: 0 0 5px #93cea6;
  }
  .checkbox input:checked ~ .check .fa-check,
  .checkbox[checked="checked"] input ~ .check .fa-check {
    display: block;
  }

</style>
