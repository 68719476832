
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { CompactTeen } from '../../shared/CompactTeen';
import Tables from 'shared/ui/tables/Tables.vue';
import Loading from 'shared/components/Loading.vue';
import { ArrayElement } from "shared/util/types";
import { GetEmailHistoryQuery } from "shared/generated/graphql-types";

type EmailHistory = ArrayElement<GetEmailHistoryQuery['emailHistory']>

@Component({
  name: "Emails",
  components: {
    Tables,
    Loading
  }
})

export default class extends Vue {

@Prop() emailHistory!: EmailHistory[]
@Prop() teen!: CompactTeen
@Prop() loading!: boolean;
@Prop() regionId!: number;

activityLevelsMap: any = {
    '-1': 'None',
    '0': 'Processed',
    '1': 'Dropped',
    '2': 'Deferred',
    '3': 'Delivered',
    '4': 'Bounced',
    '5': 'Opened',
    '6': 'Clicked',
    '7': 'Unsubscribe',
    '8': 'Reported as Spam'
  };
}

