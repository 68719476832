import Vue from "vue";

export default Vue.extend({
	name: 'FileSelector',
	props: ['multiple', 'accept'],
	methods: {
		handleClick () {
			(this.$refs.input as HTMLInputElement).click();
		},
		emitFile ({ target }: { target: { files: File[] } }) {
			if (this.multiple) this.$emit('fileSelected', target.files);
			else this.$emit('fileSelected', target.files[0]);
		}
	},
	render (h) {
		return h('div', { on: { click: this.handleClick } }, [
			...this.$slots.default || [],
			h('div', [
				h('input', {
          ref: 'input',
					on: {
						change: this.emitFile
					},
					style: { display: 'none' },
					attrs: {
            accept: this.accept || "*",
						type: 'file',
						multiple: this.multiple
					}
				})
			])
		]);
	}
});
