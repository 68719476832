
import  { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { RouterLink } from 'vue-component-router';
import { getLocalStartDate, getLocalTimeDate, normalizedEventTime, totalEventTime } from '../../shared/util';
import { ArrayElement, SimpleDate } from 'shared/util/types';
import { GetEventsQuery } from 'shared/generated/graphql-types';

type Event = ArrayElement<GetEventsQuery['events']>

const days = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thurs',
  'Fri',
  'Sat'
];

  interface EventModel extends Event {
    normalizedEventTime: string;
    totalTimeInHours: number;
    gridPosition: number;
    startTimeHours: string;
    isMultidayEvent: boolean;
  }

@Component({
  name: 'DayView',
  components: {
    RouterLink
  },
  methods: {
    normalizedEventTime
  }
})
export default class DayView extends Vue{
  @Prop() currentDay!: number;
  @Prop() currentDate!: SimpleDate
  @Prop() events!: Event[]

  created () {
    this.days = days;
    this.scrollHandler();
  }
  get multidayEvents () {
    return this.dayViewEvents.filter(x => x.isMultidayEvent)
  }
  get oneDayEvents () {
    return this.dayViewEvents.filter(x => !x.isMultidayEvent)
  }
  get dayViewEvents(): EventModel[] {
      const { month, year, day } = this.currentDate;
      const currentDate = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`

      let events = this.events.map(x => ({
              ...x,
              normalizedEventTime: normalizedEventTime(x) || '',
              totalTimeInHours: totalEventTime(x)!.timeDiff,
              localStartDate: getLocalTimeDate(x.startDate, x.TimeZone),
              localEndDate: getLocalTimeDate(x.endDate, x.TimeZone),
              gridPosition: 1
          })).map(x => ({
              ...x,
              startTimeHours: x.normalizedEventTime && x.normalizedEventTime.split(' - ')[0].split(':')[0],
              isMultidayEvent: (new Date(currentDate).getTime() >= new Date(x.localStartDate).getTime()) && (new Date(x.localStartDate).getTime() < new Date(x.localEndDate).getTime())
          }))
          .filter(x => (x.isMultidayEvent && new Date(x.localEndDate).getTime() >= new Date(currentDate).getTime()) || getLocalStartDate(x) === currentDate)

      const repeatedTime = filter(uniq(map(events, (item) => {
          if (filter(events, {
                  startTimeHours: item.startTimeHours
              }).length > 1) {
              return item.startTimeHours;
          }
          return false;
      })))

      for (let i = 0; i <= repeatedTime.length; i++) {
          const eventsOnTheSameTime = events.filter(x => x.startTimeHours === repeatedTime[i])
          if (eventsOnTheSameTime.length) {
              for (let j = 0; j <= eventsOnTheSameTime.length; j++) {
                  const index = events.findIndex(x => (eventsOnTheSameTime[j] && eventsOnTheSameTime[j].eventId) === x.eventId)
                  if (index > -1) {
                      events[index].gridPosition = j + 1
                  }
              }
          }
      }
      return events;
  }
  async scrollHandler () {
        await Vue.nextTick();
        const eventElement = document.querySelector('div.events-container > div');
        const container = document.querySelector('.day-view');
        if (eventElement) {
          eventElement.scrollIntoView({
            block: "nearest",
            inline: "center",
            behavior: 'smooth'
          });
        }
        else {
          // @ts-ignore
          container.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
  }
  getEventPosition(event: EventModel) {
      const [start, end] = event.normalizedEventTime.split(' - ') !;
      const startTimeHours = +`${start}`.split(':')[0]
      const startTimeMinutes = +`${start}`.slice(3, 5)
      const ampm = start.split(':')[1].slice(2)

      const rs = (startTimeHours * 4) + Math.round(startTimeMinutes / 15) + 1;
      const re = Math.round(rs + event.totalTimeInHours * 4);
      const cs = event.gridPosition;
      const ce = cs + 1;

      return `${rs} / ${cs} / ${re} / ${ce}`
  }
  get weekDay() {
      return this.days[new Date(this.currentDate.year, this.currentDate.month - 1, this.currentDate.day).getDay()]
  }

    dateFormat(date: String) {
      let dateArr = date.split('-');
      let newdate = dateArr.slice(1,3).join('/');
      return newdate;
    }
    timeFormat(time: String) {
      let start = time.split(' - ')[0];
      let end = time.split(' - ')[1];
      if(parseInt(start.split(':')[0]) > 12) {
        start = `${(parseInt(start.split(':')[0])-12)}:${start.split(':')[1]}`;
      }
      if(parseInt(end.split(':')[0]) > 12) {
        end = `${(parseInt(end.split(':')[0])-12)}:${end.split(':')[1]}`;
      }
      return `${start} - ${end}`;

    }
  @Watch('oneDayEvents')
  oneDayEventsWatcher () {
    this.scrollHandler();
  }
}
