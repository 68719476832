
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faAngleUp,faAngleDown)

export default {
  name: 'ExpandableList',
  props: [
    'trigger', 'list'
  ],
  data () {
    return {
      open: false
    }
  }
}
