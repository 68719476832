
	import { Vue, Prop, Component } from 'vue-property-decorator';
	import PotentialHousee from '../../../shared/PotentialHousee';
	import UITable from 'shared/ui/tables/Tables.vue';
	import SlottedTable from 'shared/ui/tables/SlotedTable.vue';
	import UISwitch from 'shared/ui/forms/Switch.vue';
  import UISearch from 'shared/ui/forms/Search.vue';
	import Modal from 'shared/ui/modal/Modal.vue';
	import Filters from 'shared/components/Filters/Filters.vue';
	import { FilterOption, FilterOptionGroup } from 'shared/components/Filters/FilterOption';
  import concat from 'lodash/concat';
  import toLower from 'lodash/toLower';
  import uniqBy from 'lodash/uniqBy';
  import compact from 'lodash/compact';
  import partition from 'lodash/partition';
  import orderBy from 'lodash/orderBy';
	import { getGrade, toggleInArray } from 'shared/util';
	import ActionBar from 'shared/ui/tooltip/ListActions.vue'
	import { library } from '@fortawesome/fontawesome-svg-core';
  import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
  import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
	import { GetHousesForEventQuery, StatusType } from 'shared/generated/graphql-types';
	import { ArrayElement } from 'shared/util/types';

	type House = ArrayElement<GetHousesForEventQuery['houses']>
	type EventStaff = ArrayElement<House['EventStaff']>
	type Registration = ArrayElement<House['Registrations']>

	library.add(faTimes, faFilter)

	@Component({
		name: 'RequestSheet',
		components: {
			UITable,
			UISwitch,
			UISearch,
			Modal,
			SlottedTable,
			Filters,
			ActionBar
		}
	})
	export default class extends Vue {
		@Prop() showRequestSheet!: boolean;
    @Prop() potentialHousees!: PotentialHousee[];
    @Prop() participants!: PotentialHousee[]
		@Prop() house!: House;
    @Prop() houseParticipant!: (p:PotentialHousee) => void;
    @Prop() unhouseParticipant!: (p:PotentialHousee) => void;

		showUnhousedOnly: boolean = true;
		searchFilter: string = '';
		displayFilters: boolean = false;

		tableHeaders = [
			'Name',
			'Housed',
			'Grade',
			'Chapter',
			'School',
			'Request 1',
			'Request 2',
			'Request 3',
			'Request 4'
		];

		activeFilters: {[key: string]: FilterOption[]} = {}

		genderOptions: FilterOption[] = [
			{ key: 'male', displayValue: 'Male', predicate: (p: PotentialHousee) => this.potentialHouseeGender(p) === 0 },
			{ key: 'female', displayValue: 'Female', predicate: (p: PotentialHousee) => this.potentialHouseeGender(p) === 1 }
		];

		typeOptions: FilterOption[] = [
			{ key: 'eventguests', displayValue: 'Event Guests', predicate: (p: PotentialHousee) => p.source === 'eventguests'},
			{ key: 'eventstaff', displayValue: 'Event Staff', predicate: (p: PotentialHousee) => p.source === 'eventstaff'},
			{ key: 'registration', displayValue: 'Registrations', predicate: (p: PotentialHousee) => p.source === 'registration'}
		];

    selectedParticipants: PotentialHousee[] = [];

    get filterPredicateGroups () {
      return Object.keys(this.activeFilters)
              .map(f => ({ filterType: f, predicates: this.activeFilters[f].map(t => t.predicate) }))
              .filter(fpg => fpg.predicates.length)
    }
		get filteredParticipants () {

      const activefilters =      (participant: PotentialHousee) => !this.filterPredicateGroups.length || this.filterPredicateGroups.some(fpg => fpg.predicates.some(p => p(participant)))
      const unhousedFilter =     (p: PotentialHousee) => !!this.showUnhousedOnly ? !!!p.housed : true;
      const withdrawsFilter =    (p: PotentialHousee) =>  p.registrationStatus ? ![StatusType.RefundRequired, StatusType.Refunded, StatusType.Cancelled].includes(p.registrationStatus) : true;
      const searchByReq1Filter = (p: PotentialHousee) => `${toLower((p.model as Registration).housingRequest1 || '')}`.startsWith(toLower(this.searchFilter))
      const searchByReq2Filter = (p: PotentialHousee) => `${toLower((p.model as Registration).housingRequest2 || '')}`.startsWith(toLower(this.searchFilter))
      const searchByReq3Filter = (p: PotentialHousee) => `${toLower((p.model as Registration).housingRequest3 || '')}`.startsWith(toLower(this.searchFilter))
      const searchByReq4Filter = (p: PotentialHousee) => `${toLower((p.model as Registration).housingRequest4 || '')}`.startsWith(toLower(this.searchFilter))
      const searchByNameFilter = (p: PotentialHousee) => `${toLower(p.name)}`.indexOf(toLower(this.searchFilter)) > -1;
      const searchByHousingRequestFilter = (p: PotentialHousee) => [searchByReq1Filter, searchByReq2Filter, searchByReq3Filter, searchByReq4Filter].some((predicate) => predicate(p));

      const all = concat(concat(this.participants, this.potentialHousees));
      const [searchByNameResults, rest] = partition(all, searchByNameFilter);
      const [searchByHousingRequestResults] = partition(rest, searchByHousingRequestFilter);

      return orderBy((
          concat(searchByNameResults, searchByHousingRequestResults)
          .filter(unhousedFilter)
          .filter(activefilters)
          .filter(withdrawsFilter)
      ), ['lastName', 'asc'])
		}

		get participantsDisplay () {
        return [
            ...this.filteredParticipants
            .map(p => [
              `<span class="participant-name ${p.source}">${p.name}</span> &nbsp ${p.registrationStatus === StatusType.Waiting ? `<span id="label-info">Waiting List</span>` : ''}`,
              `<span>${p.housed ? p.House.description : ''}</span>`,
              getGrade(((p.model as Registration).Teen || {} as any).graduationYear).grade,
              (((p.model as Registration).Teen || { Chapter: {} as any }).Chapter || { chapterName: '' }).chapterName,
              (((p.model as Registration).Teen || { School: { name: '' }}).School || { name: '' }).name,
              (p.model as Registration).housingRequest1,
              (p.model as Registration).housingRequest2,
              (p.model as Registration).housingRequest3,
              (p.model as Registration).housingRequest4
            ])
        ];
		}

		get registrationsInPotentialHoussees (): Registration[] {
			return this.potentialHousees.filter(p => (p.model as Registration).Teen).map(p => p.model as Registration);
		}

		get chapterOptions (): FilterOption[] {
			return uniqBy(compact(this.registrationsInPotentialHoussees.map(p => p.Teen.Chapter)), 'chapterName')
				.map(c => ({
					key: String(c.chapterId),
					displayValue: c.chapterName || '',
					predicate: (p: PotentialHousee) => (((p.model as Registration).Teen || { Chapter: {} as any }).Chapter || {} as any).chapterId === c.chapterId
			}));
		}

		get gradeOptions (): FilterOption[] {
			const grades: FilterOption[] = uniqBy(compact(this.registrationsInPotentialHoussees.map(r => r.Teen.graduationYear))
																			.map(y => getGrade(y)), 'year')
											.filter(g => g.grade.indexOf('Alumnus') === -1)
											.map(g => ({
												key: g.year ? String(g.year) : '',
												displayValue: g.grade,
												predicate: (p: PotentialHousee) => ((p.model as Registration).Teen || {} as any).graduationYear === g.year
											}));
			grades.push({
				key: 'alumnus',
				displayValue: 'Alumnus',
				predicate: (p: PotentialHousee) => getGrade(((p.model as Registration).Teen || {} as any).graduationYear).grade.indexOf('Alumnus') > -1
			});
			return grades;
		}

		get schoolOptions (): FilterOption[] {
			return uniqBy(compact(this.registrationsInPotentialHoussees.filter(r => r.Teen.School).map(r => r.Teen.School)), 'name')
					.map(s => ({
						key: String(s.schoolID),
						displayValue: s.name || '',
						predicate: (p: PotentialHousee) => (((p.model as Registration).Teen || { School: { name: '' } }).School || { name: '' }).name === s.name
					}));
		}

		get filters(): FilterOptionGroup[] {
			return [
				{ name: 'Chapter', displayName: 'Chapter', options: this.chapterOptions },
				{ name: 'Gender', displayName: 'Gender', options: this.genderOptions },
				{ name: 'Grade', displayName: 'Grade', options: this.gradeOptions },
				{ name: 'School', displayName: 'School', options: this.schoolOptions },
				{ name: 'Type', displayName: 'Type', options: this.typeOptions }
			];
		}

		potentialHouseeGender(p: PotentialHousee) {
			if (p.source === 'eventguests') return null;
			if (p.source === 'eventstaff') return (p.model as EventStaff).Staff.gender;
			if (p.source === 'registration') return (p.model as Registration).Teen.Person.gender;
		}

		toggleParticipant(index: number) {
			toggleInArray(this.selectedParticipants, this.filteredParticipants[index]);
		}

		isParticipantSelected(index: number) {
			return this.selectedParticipants.includes(this.filteredParticipants[index]);
		}

		async addSelected(){
			await Promise.all(this.selectedParticipants.map(p => this.houseParticipant(p)));
			this.selectedParticipants.splice(0);
		}

		close () {
			this.$emit('update:showRequestSheet', false);
		}
	}
