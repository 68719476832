
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { FetchResult } from "@apollo/client";
import UIInput from 'shared/ui/forms/Input.vue';
import UIButton from "shared/ui/buttons/Button.vue";
import { ErrorEnum } from './types';
import StaffPopup from './components/StaffPopup';
import PersonPopup from './components/PersonPopup';
import { EntityUnionViewModel } from './shared/EntityUnionViewModel';
import { validateEmail } from 'shared/util';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import has from 'lodash/has';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Modal from 'shared/ui/modal/Modal.vue';
import { directive as onClickaway } from 'vue-clickaway2';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { EntityUnionEnum, SearchDuplicateEmailsQuery } from "shared/generated/graphql-types";
library.add(faTrash)

@Component({
  name: 'EmailInput',
	components: {
    PersonPopup,
    StaffPopup,
    UIButton,
    UIInput,
    Modal
  },
  directives: {
    onClickaway
  }
})
export default class EmailInput extends Vue {
    @Prop() owner!: EntityUnionViewModel;
    @Prop() ownerId!: number;
    @Prop() value!: string;
    @Prop() regionId!: number;
    @Prop() searching!: boolean;
    @Prop({ default: 'EmailInput' }) name!: string;
    @Prop({ type: String as () => EntityUnionEnum }) ownerType!: EntityUnionEnum;
    @Prop() duplicate!: EntityUnionViewModel;
    @Prop() searchDuplicates!: (email: string) => Promise<FetchResult<SearchDuplicateEmailsQuery>>;
    @Prop() deleteEmail!: (id: number) => void;
    @Prop() deletingEmail!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop() left!: boolean;
    @Prop() narrow!: boolean;
    @Prop() sidebar!: boolean;
    @Prop() width!: string;
    @Prop() placeholder!: string;
    @Prop({ default: false }) showErrors!: boolean;
    @Prop({ default: false }) validateOnBlur!: boolean;
    @Prop() externalErrors!: Record<string, string>;

    localValue: string = trim(this.value);
    eventType: string | null = null;
    injectedEmail: string | null = null;
    excludedValidation: number[] = [];
    showDeleteEmailModal: boolean = false;

    get listeners() {
        return {
            input: this.inputHandler,
            blur: this.blurHandler
        }
    }
    get popupListeners () {
      return {
        ...(this.$listeners.populate ? { populate: this.setAsDuplicateHandler } : {}),
        ...(this.$listeners.associate ? { associate: this.associateHandler } : {}),
      }
    }
    get deleteEmailAvailable () {
      return this.owner && (this.owner.email === this.localValue) && this.owner.emailId
    }
    async deleteEmailHandler () {
      if (this.deleteEmailAvailable) {
        this.deleteEmail(this.owner.emailId!)
        this.showDeleteEmailModal = false;
        this.localValue = ''
      }
    }
    setAsDuplicateHandler () {
      this.excludedValidation.push(this.duplicate.id);
      this.$emit('populate', { fieldName: this.name, values: this.duplicate });
      this.$emit('blur', this.localValue);
      this.$emit('input', this.localValue);
    }
    /**
     * This is used via PersonPop for consumers to support use cases like
     * adding a family/parent and associating the child to the family
     */
    associateHandler (primary?: boolean) {
      this.$emit('associate', this.duplicate, primary);
      this.closePopup();
      this.$emit('blur', this.localValue);
      this.$emit('input', this.localValue);
    }
    get showDuplicatePopup () {
        return this.errors[this.name] === ErrorEnum.DuplicateFound
    }
    get popupType () {
      return this.duplicate && this.duplicate.isStaff ? 'StaffPopup' : 'PersonPopup'
    }
    get validateEmail () {
      return validateEmail(this.localValue || '');
    }
    get isRequiredField () {
      return this.required
    }
    get isDuplicateFound () {
      return !!(this.duplicate && !this.isDuplicateEmailBelongsToOwner && toLower(this.duplicate.email) === toLower(this.localValue))
    }
    get isDuplicateEmailBelongsToOwner () {
      if (!this.duplicate) return false;
      if (this.owner && this.owner.isStaff) {
        return toLower(this.owner.email) === toLower(this.localValue)
      }
      else if (has(this.owner, 'emails[0]')) {
        return find(this.owner.emails, e => toLower(e.email) === toLower(this.localValue))
      }
      return false;
    }
    get searchValidation () {
      const condition1 = this.validateEmail;
      const condition2 = toLower(this.initialEmail) !== toLower(this.localValue)
      const condition3 = !this.isDuplicateEmailBelongsToOwner;
      const condition4 = (toLower(this.duplicate && this.duplicate.email || '') !== toLower(this.localValue));
      const condition5 = this.injectedEmail ? this.injectedEmail !== this.localValue : true

      return condition1 && condition2 && condition3 && condition4 && condition5;
    }
    get errors () {
        let error: Record<string, string> = {};
        if (!this.localValue) {
            if (this.isRequiredField) {
                error = {
                    [this.name]: ErrorEnum.RequiredField
                }
            }
        } else if (!this.validateEmail) {
            if (this.validateOnBlur) {
                if (this.eventType === 'blur') {
                    error = {
                        [this.name]: ErrorEnum.EmailValidationFaild
                    }
                }
            } else {
                error = {
                    [this.name]: ErrorEnum.EmailValidationFaild
                }
            }
        } else if (this.isDuplicateFound) {
            if (!this.excludedValidation.includes(this.duplicate.id)) {
              error = {
                  [this.name]: ErrorEnum.DuplicateFound
              }
            }
        }

        if (!isEqual({ ...this.externalErrors }, error)) {
          this.$emit('update:externalErrors', error)
        }
        return error
    }
    get initialEmail () {
      if (this.owner && this.owner.type !== EntityUnionEnum.Teen) {
        return this.owner.email || ''
      }
      return ''
    }
    closePopup () {
      this.localValue = this.initialEmail;
      this.$emit('input', this.localValue || null)
    }
    async blurHandler (event: any) {
        this.eventType = 'blur';
        if (this.searchValidation) {
          await this.searchDuplicates(this.localValue);
          this.excludedValidation = [];
          if (isEmpty(this.errors)) {
            this.$emit('blur', this.localValue)
          }
        }
        else if (this.validateEmail && toLower(this.initialEmail) !== toLower(this.localValue) && isEmpty(this.errors)) {
          this.$emit('blur', this.localValue)
        }
        else {
          if (this.duplicate && this.duplicate.email === this.localValue) return;
          if (this.initialEmail) {
            this.localValue = this.initialEmail;
          }
        }
    }
    inputHandler (event: any) {
      this.eventType = 'input';
      this.$emit('input', this.localValue)
    }
    @Watch('errors')
    errorsWatcher (errors: { [key:string]: string }) {
      this.$emit('update:errors', errors);
    }
    @Watch('value')
    valueWatcher (value: string) {
      if (value && !this.eventType) {
        this.injectedEmail = trim(value)
        this.localValue = trim(value)
      }
    }
}
