
import { CreateElement } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import UIInput from 'shared/ui/forms/Input.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import StaffLookup from 'shared/components/StaffLookup';
import SchoolLookup from 'shared/components/SchoolLookup';
import Datepicker from 'shared/components/DatePicker.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
import StateLookup from 'shared/components/StateLookup.vue';
import TeenLookup from 'shared/components/TeenLookup';
import ImageSelector from 'shared/components/ImageSelector.vue';
import EventTrackLookup from 'shared/components/EventTrackLookup';
import TimezoneDropdown from 'shared/components/TimezoneDropdown.vue';
import ZoomUsersLookup from 'shared/components/ZoomUsersLookup';
import Step from '../../shared/Step.vue';
import { Step as StepType, Steps } from '../../../shared/MultistepForm';
import WYSIWYG from 'shared/ui/forms/RichTextArea';
import UITicket from '../../../shared/Ticket.vue';
import FancyCheckbox from 'shared/ui/forms/FancyCheckbox.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIAdditionalEventItem from '../../../shared/AdditionalEventItem.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboardCheck } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck';
import { faGraduationCap } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faHotel } from '@fortawesome/pro-regular-svg-icons/faHotel';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { regionsWithOutState, isFutureEvent, generateZoomPasscode } from '../../../shared/util';
import { AdditionalEventItem, EventTicket, GetRegionDataQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']

library.add(faClipboardCheck, faGraduationCap, faHotel, faPencil, faTimes)

@Component({
  components: {
		ChapterLookup,
    Divider,
		StaffLookup,
    SchoolLookup,
		StateLookup,
    TeenLookup,
    ZoomUsersLookup,
		TimezoneDropdown,
		Timepicker,
    Datepicker,
    UIInput,
    Step,
    ImageSelector,
    EventTrackLookup,
    UISwitch,
    WYSIWYG,
    EventSubTypeLookup,
    UITicket,
    FancyCheckbox,
    UIAdditionalEventItem,
    UISelect,
    UICheckbox
  }
})
export default class Shabbaton extends Vue {
  @Prop() steps!: Steps
  @Prop() complete!: (step: string) => void
  @Prop() toggleActive!: (step: string) => void
  @Prop() activatable!: (step: string) => boolean
  @Prop() finalStep!: (step: string) => boolean
  @Prop() firstStep!: (step: string) => boolean
  @Prop() setData!: (step: string, key: string, value: any) => void
  @Prop() getData!: (step: string, key: string) => any
  @Prop() region!: Region;

  registrationRequired: boolean = false;
  hasAdditionalEventItems: boolean = false;
  get showStateLookup (): boolean {
    return !regionsWithOutState.includes(this.region.regionId)
  }
  get computedHideFromWebAndMobile () {
      const eventSubType: { id: number, name: string } | null = this.getData('eventSubType', 'shabbatType');
      if (!eventSubType) {
        return this.getData('options', 'hideFromWebAndMobile')
      }
      else if (eventSubType.id === 294 || eventSubType.id === 321 || eventSubType.id === 304) {
        return true
      }
      else {
        return this.getData('options', 'hideFromWebAndMobile')
      }
  }
  get isFutureEvent(): boolean {
    return isFutureEvent(this.getData('details', 'FirstEvent'), this.getData('details', 'StartTime'), this.getData('details', 'TimeZone'));
  }
  get allowSplitPayments () {
    return this.region.regionId !== 30  && this.region.regionId !== 8
  }
  get allowPartialPayments () {
    return this.region.regionId !== 8
  }
  getOuterData(step: string, key: string) {
    return this.getData(step, key);
  }

  async subTypeHandler($event: any, setData: (name: string, value: any) => void) {
    setData('subType', $event);
    await Vue.nextTick();
    // this.selectedShabbatonType = $event;
    // await Vue.nextTick();
    // await Vue.nextTick();
    // setData('shabbatType', null);
  }

  async hideFromHandler(checked: boolean, setData: (name: string, value: any) => void) {
    setData('hideFromWebAndMobile', checked);
    await Vue.nextTick();
    if (checked) {
      setData('sendPushNotification', false);
    }
  }

  async registrationRequiredHandler(checked: boolean, setData: (name: string, value: any) => void) {
    this.registrationRequired = checked;
    setData('registrationRequired', this.registrationRequired);
    await Vue.nextTick();
    setData('requiresParentalApproval', true);
    if(!this.registrationRequired) {
      // XTODO will we want to clear out all registration related props
    }
  }

  async hasAdditionalEventItemsHandler(checked: boolean, setData: (name: string, value: any) => any) {
    this.hasAdditionalEventItems = checked;
    if(!this.hasAdditionalEventItems) {
      setData('additionalEventItems', null);
      await Vue.nextTick();
    }
  }

  async scheduleZoomHandler(checked: boolean, setData: (name: string, value: any) => any) {
    setData('streamSchedule', checked);
    await Vue.nextTick();
    if (checked) {
      setData('zoomPasscode', generateZoomPasscode());
    } else {
      setData('zoomPasscode', null);
      await Vue.nextTick();
      setData('zoomUser', null);
    }
  }

  async isVirtualHandler(checked: boolean, setData: (name: string, value: any) => any) {
    setData('isVirtual', checked);
    await Vue.nextTick();
    if (checked) {
      if (this.getData('details', 'streamSchedule')) {
        setData('zoomPasscode', generateZoomPasscode());
      }
    } else {
      setData('zoomPasscode', null);
      await Vue.nextTick();
      setData('zoomUser', null);
    }
  }

  async setTeenOption(name: string, getData: (name: string) => any, setData: (name: string, value: any) => void){
    setData(name, !(getData(name) || false));
    await Vue.nextTick();
  }

  async addNewTicket(tickets: any[], setData: (name: string, value: any) => void) {
    setData(`tickets.${(tickets || []).length}`, {});
    await Vue.nextTick();
  }

  async addNewAdditionalEventItem(additionalEventItems: any[], setData: (name: string, value: any) => void) {
    setData(`additionalEventItems.${(additionalEventItems || []).length}`, {});
    await Vue.nextTick();
  }

  async removeAdditionalEventItem(index: number, additionalEventItems: AdditionalEventItem[], setData: (name: string, value: any) => void){
    additionalEventItems.splice(index, 1);
    setData('additionalEventItems', additionalEventItems);
    await Vue.nextTick();
  }

  async removeTicket(index: number, tickets: EventTicket[], setData: (name: string, value: any) => void){
    tickets.splice(index, 1);
    setData('tickets', tickets);
    await Vue.nextTick();
  }

  isHasErrors(step: StepType, key: string): boolean {
    return step.validations && step.validations[key];
  }
}
