import { render, staticRenderFns } from "./TeenSheet.vue?vue&type=template&id=dbdfc33a&scoped=true&"
import script from "./TeenSheet.vue?vue&type=script&lang=ts&"
export * from "./TeenSheet.vue?vue&type=script&lang=ts&"
import style0 from "./TeenSheet.vue?vue&type=style&index=0&id=dbdfc33a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbdfc33a",
  null
  
)

export default component.exports