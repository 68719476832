
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import UISwitch from 'shared/ui/forms/Switch.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import { Chapter, Staff } from './types';
import AutoSaveField from 'shared/components/AutoSaveField';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import cloneDeep from 'lodash/cloneDeep';
import SaveEventArgs from "shared/components/AutoSaveField/SaveEventArgs";
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import Loading from 'shared/components/Loading.vue';
import { validateEmail, validateNumber } from 'shared/util';


@Component({
  name: "Chapter",
  components: {
    CollapsibleSection,
    PhoneInput,
    UIInput,
    UIToggle,
    UITextarea,
    UIRadio,
    UIButton,
    AutoSaveField,
    UISwitch,
    ...RadioGroup,
    UISelect,
    Loading
  },
  methods: {
    validateEmail,
    validateNumber
  }
})

export default class extends Vue {
  @Prop() chapter!: Chapter;
  @Prop() isLoading!: boolean;
  @Prop() staff!: Staff[];

  chapterClone: Chapter | null = null

  @Watch('chapter', {
    immediate: true
  })
  onChapterChange () {
    this.chapterClone = cloneDeep(this.chapter)
  }

  update (args: SaveEventArgs) {
    this.$emit('chapterUpdated', {field: args.name, value: args.value});
  }
  /*validate (values: Chapter) {
    let errors: { [key: string]: string } = {}

	  const { validateEmail, validateNumber } = this
    //const { email, phone } = values;

	if(email && !this.validateEmail(email)) {
		errors['email'] = 'The Email is invalid';
	}
	if(phone && !this.validateNumber(phone)) {
		errors['phone'] = 'The Phone Number is invalid';
	}

      return errors
  }*/
}
