
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { AddEventStaffExtendedVariables } from './types';
import BorderedList from "shared/ui/lists/BorderedList.vue";
import Modal from "shared/ui/modal/Modal.vue";
import FancySelect from "shared/ui/forms/FancySelect";
import uniq from "lodash/uniq";
import UIRadio from "shared/ui/forms/Radio.vue";
import { RadioGroup, RadioGroupItem } from "shared/radio-group";
import UIButton from "shared/ui/buttons/Button.vue";
import UISearch from "shared/ui/forms/Search.vue";
import UIToggle from 'shared/ui/forms/Toggle.vue';
import { getCurrentEmail } from 'shared/util';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import sortBy from 'lodash/sortBy'
import { ArrayElement } from "shared/util/types";
import { AddJuniorEventStaffMutationVariables, GetEventQuery, GetEventStaffQuery, GetStaffQuery, GetTeensQuery } from "shared/generated/graphql-types";

type Event = GetEventQuery['event']
type Teen = ArrayElement<GetTeensQuery['teens']['teens']>
type Staff = ArrayElement<GetStaffQuery['staff']>
type EventStaff = ArrayElement<GetEventStaffQuery['eventStaff']>

library.add(faTrash, faTimes)

interface PotentialEventStaff {
  trackingId: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  staffId?: number;
  gender?: number;
  graduationYear?: number;
  personId?: number;
  email?: any;
  chapterId?: number;
  staffType: string | null;
  source: "teen" | "staff";
}

interface CompactEventStaff {
  staffId: number;
  eventStaffId: number;
  firstName: string;
  lastName: string;
  regionId: number;
  staffType: string | null;
  teenId: number | null;
}

@Component({
  name: "Staff",
  components: {
    PanelsSidebar,
    Loading,
    PanelsGrid,
    Modal,
    BorderedList,
    FancySelect,
    RadioGroup,
    RadioGroupItem,
    UIRadio,
    UIButton,
    UISearch,
    FilterGroup,
    UIToggle,
    ScrollablePanel
  },
  directives: {
    fullheight
  }
})
export default class extends Vue {
  @Prop() event!: Event;
  @Prop() addingNewStaff!: boolean;
  @Prop() teens!: Teen[];
  @Prop() regionId!: number;
  @Prop() staff!: Staff[];
  @Prop() eventStaff!: EventStaff[];
  @Prop() loadingStaff!: boolean;
  @Prop() loadingTeens!: boolean;
  @Prop() loadingEventStaff!: boolean;
  @Prop() addJuniorEventStaff!: (vars: AddJuniorEventStaffMutationVariables) => EventStaff;
  @Prop() addEventStaff!: (vars: AddEventStaffExtendedVariables) => EventStaff;
  @Prop() removeEventStaff!: (eventStaffId: number) => void;

  selectedStaffToAdd: PotentialEventStaff[] = [];
  selectedTable: string = 'Staff';
  selectedStaffType: string | null = null;
  show: boolean = false;
  filter: string = "";

  get noEventStaff () {
    return !!!this.eventStaff.length && !this.loading;
  }
  get loading () {
    return this.loadingEventStaff;
  }

  get potentialEventStaff(): PotentialEventStaff[] {
    if (this.selectedTable === "Teens") {
      const teenStaffIds = uniq(this.compactEventStaff.map(e => e.teenId));
      return this.compactTeens.map(x => ({ ...x, fullName: `${x.firstName} ${x.lastName}`})).filter(t => !teenStaffIds.includes(t.personId!));
    } else {
      const staffIds = uniq(this.compactEventStaff.map(e => e.staffId));
      return this.staff
        .filter(e => !staffIds.includes(e.Staff.staffID))
        .map(s => ({
			trackingId: s.Staff.staffID + 'staff',
          firstName: s.Staff.firstName,
          lastName: s.Staff.lastName,
          fullName: `${s.Staff.firstName} ${s.Staff.lastName}`,
          staffId: s.Staff.staffID,
          staffType: s.staffType,
          source: "staff" as "teen" | "staff"
        }));
    }
  }
  get compactEventStaff(): CompactEventStaff[] {
    if (!this.eventStaff.length) return [];
    return this.eventStaff.map(x => {
      const advisorRegion = x.Staff.AdvisorRegions.find(
        ar => ar.Region.regionId === this.regionId
      ) || x.Staff.AdvisorRegions[0];
      return {
        staffId: x.Staff.staffID,
        eventStaffId: x.eventStaffId,
        firstName: x.Staff.firstName,
        lastName: x.Staff.lastName,
        regionId: advisorRegion.Region.regionId,
        staffType: advisorRegion.staffType,
        teenId: x.Staff.teenId
      };
    });
  }

  get compactTeens(): PotentialEventStaff[] {
    return this.teens
      .map(x => {
        return {
          trackingId: x.personID + 'teen',
          firstName: x.Person.firstName || '',
          lastName: x.Person.lastName || '',
          gender: x.Person.gender,
          graduationYear: x.graduationYear || undefined,
          personId: x.personID,
          email: getCurrentEmail(x.Person.EmailAddresses || []) || null,
          chapterId: x.Chapter ? x.Chapter.chapterId : undefined,
          source: "teen" as "teen" | "staff",
          staffType: 'juniorStaff'
        };
      })
      .filter(t => t.email);
  }

  get filteredEventStaff() {
    const filterPredicate = (x: CompactEventStaff) => this.selectedStaffType ? x.staffType === this.selectedStaffType : true
    const eventStaffFiltered = this.compactEventStaff.filter(
      x => `${x.firstName.toLowerCase()} ${x.lastName.toLowerCase()}`.indexOf(this.filter.toLowerCase()) >= 0
    );
    return sortBy(eventStaffFiltered.filter(filterPredicate), 'firstName');
  }

  addNewStaffHandler() {
    try {
      const teens = this.selectedStaffToAdd.filter(t => t.source === "teen");
      const staff = this.selectedStaffToAdd.filter(s => s.source === "staff");
      const regionId = this.regionId;

      if (staff) {
        staff.map(s => {
          const { firstName, lastName, staffId, staffType } = s;
          this.addEventStaff({
            eventId: this.event.eventId,
            regionId,
            staffId: staffId!,
            firstName,
            lastName,
            staffType: staffType || ''
          });
        });
      }

      if (teens) {
        teens.map(t => {
          const { firstName, lastName, gender, personId, chapterId, email } = t;
          this.addJuniorEventStaff({
            firstName,
            lastName,
            regionId,
            gender: gender!,
            email: email!.email,
            teenId: personId!,
            chapterId: chapterId,
            eventId: this.event.eventId
          });
        });
      }
      this.selectedStaffToAdd = [];
      this.show = false;
    } catch (err) {
      console.log(err);
    }
  }
}
