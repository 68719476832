

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import { graduationYears } from 'shared/util';

@Component({
  components: {
    UISelect
  }
})
export default class graduationYearPicker extends Vue {
  @Prop() value!: number;

  get graduationYears() {
      return graduationYears().map(x => x.grade);
  }
  get formatValue () {
    if (!this.value) return null
    return graduationYears().find(x => x && x.year === this.value)!.grade || null
  }
}
