import inView from 'in-view'
import Vue, { VNode } from 'vue';

export const InView = Vue.extend({
  name: 'WhenInView',
  props: ['height'],
  data: () => ({
    inView: false
  }),
  mounted () {
    const checkIfInView = () => {
      this.inView = inView.is(this.$el)
      window.requestAnimationFrame(checkIfInView)
    }

    checkIfInView()
  },
  render (h) {
    const $vnodes = this.$scopedSlots.default && this.$scopedSlots.default({ inView: this.inView })

    if ($vnodes) {
      return $vnodes[0];
    }
    return h('div');
  }
})