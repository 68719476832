
import { Vue, Component, Prop } from 'vue-property-decorator';
import UIButton from "shared/ui/buttons/Button.vue";
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import UIInput from 'shared/ui/forms/Input.vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import Avatar from 'shared/components/avatar';
import { ScholarshipRequest } from '../../types';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { ScholarshipFragmentFragment as Scholarship } from 'shared/generated/graphql-types';

interface Form {
  scholarshipRequest: number;
  selectedTeen: Scholarship;
  scholarshipGrant: number | null;
  reasonForScholarship: string;
  disableNotification: boolean;
  disableTeenNotification: boolean;
}

@Component({
  components: {
    UIButton,
    UICheckbox,
    FancySelect,
    UITextarea,
    SimpleForm,
    UIInput,
    Field,
    Avatar,
    Error
  }
})
export default class ScholarshipForm extends Vue {
  @Prop() eligibleForScholarshipsList!: Scholarship[];

  newScholarship: ScholarshipRequest = {
    registrationId: null,
    scholarshipRequest: null,
    scholarshipGrant: null,
    reasonForScholarship: '',
    disableNotification: false,
    disableTeenNotification: false,
    selectedTeen: null
  }

  customLabel(obj: Scholarship) {
    return `${obj.Teen.Person.firstName} ${obj.Teen.Person.lastName}`;
  }

  validate (values: Form) {
    const errors = {} as Partial<{[P in keyof Form]: string}>
    if (!values.selectedTeen) errors.selectedTeen = 'Please select a teen';
    if (!values.scholarshipRequest) errors.scholarshipRequest = 'Please set scholarship amount';

    return errors;
  }

  handleSubmit (callbackArgs: SubmitCallbackArgs<Form>) {
    if ('errors' in callbackArgs) return;

    const {
      selectedTeen, scholarshipRequest, scholarshipGrant, disableNotification, reasonForScholarship, disableTeenNotification
    } = callbackArgs.values;

    if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();

    this.$emit('newScholarship', {
      registrationId: selectedTeen.registrationID,
      scholarshipRequest: Number(scholarshipRequest),
      scholarshipGrant: scholarshipGrant ? Number(scholarshipGrant) : null,
      disableNotification,
      reasonForScholarship,
      disableTeenNotification: disableNotification === true ? true : disableTeenNotification
    });
  }
}
