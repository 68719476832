
import Vue from 'vue'
import EventLookup from './EventLookup';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';

interface Event { eventName: string, eventId: number, startDate: string }

export default Vue.extend({
  props: {
    staffId: {
      type: Number
    },
    value: {
      type: Number
    }
  },
  components: {
    EventLookup,
    Modal,
    UIButton
  },
  data () {
    return {
      selectedEvent: null as Event | null,
      showModal: false
    }
  },
  computed: {
    buttonText(): string {
      if (!this.selectedEvent) return 'Select an Event'

      const eventName = this.selectedEvent.eventName;
      const startDate = this.selectedEvent.startDate;

      return `${eventName} - ${startDate}`
    }
  },
  methods: {
    onEventLookup (e: Event) {
      this.showModal = false;
      this.$emit('input', e.eventId);
      this.selectedEvent = e;
    }
  }
})
