
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faFileMedical } from '@fortawesome/pro-regular-svg-icons/faFileMedical';
import { library } from '@fortawesome/fontawesome-svg-core';
import UIButton from 'shared/ui/buttons/Button.vue';


library.add(faPencil, faFileMedical)

export default {
	components: { UITextarea, UIButton },
	props: ['registrationMedicalNotes', 'teenMedicalNotes','updateTeen','teenId', 'updateMedicalNote', 'registrationId'],
	data(){
		return {
			toggle: false
		}
	},

}
