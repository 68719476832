
import Vue from 'vue';
import UISearch from 'shared/ui/forms/Search.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
library.add(faPlusCircle);

export default Vue.extend({
	name: 'Sidebar',
	props: {
		housesLength: Number,
		gridView: Boolean,
		gender: String,
		search: String,
		status: String,
    stats: Object,
		addingNewHouse: Boolean
	},
	components: {
		PanelsSidebar,
		UISearch,
		UIToggle,
		FilterGroup,
		UIButton,
		UIRadio,
		...RadioGroup,
		ScrollablePanel
	},
	directives: {
		fullheight
	}
});
