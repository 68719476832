

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import UISelect from 'shared/ui/forms/FancySelect';
import { getCurrentSeason } from 'shared/util';

@Component({
  components: {
    UISelect
  }
})
export default class calendarYearPicker extends Vue {
  @Prop() value!: number;
  get calendarYear() {
      const currentSeason = Number(getCurrentSeason().split('-')[0]);
      return range(2007, currentSeason + 2).reverse();
  }
}
