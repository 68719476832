
import Vue from "vue";
import { PersonType } from "shared/generated/graphql-types";
import Modal from "shared/components/modal/Modal.vue";
import Loading from "shared/components/Loading.vue";
import { Props, Methods, Data } from "./types";
import PhoneDisplay from "./components/PhoneDisplay.vue";
import FamilyDisplay from "./components/FamilyDisplay.vue";
import UIButton from 'shared/ui/buttons/Button.vue';
import AddPhone from './components/AddPhone';

export default Vue.extend<Data, Methods, {}, Props>({
  name: "Single",
  components: {
    Loading,
    PhoneDisplay,
    FamilyDisplay,
    Modal,
    UIButton,
    AddPhone,
  },
  props: {
    phoneNumber: {},
    loading: {},
    holders: {},
    setParentId: {},
    setTeenId: {},
    deletePhone: {},
    deletingPhone: {},
  },
  data() {
    return {
      PersonType,
      showAddPhoneForm: false,
      selectedPersonId: null
    };
  },
  methods: {
    viewPersonProfile(personId, type) {
      if (type === PersonType.Parent) {
        this.setParentId(personId);
      } else {
        this.setTeenId(personId);
      }
    },
    requestClose() {
      this.showAddPhoneForm = false;
      this.selectedPersonId = null;
    },
    openAddPhoneForm(personId) {
      this.selectedPersonId = personId;
      this.showAddPhoneForm = true;
    },
  },
});
