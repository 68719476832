
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import { getGrade, phoneFormat, dateFormat, b64toBlob } from 'shared/util';
import FileSaver from 'file-saver';
import { Methods, Computed, Props } from './types';
import UIButton from 'shared/ui/buttons/Button.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';
import { GetAllergyActionPlanDocument, GetAllergyActionPlanQuery, GetIepPlanDocument, GetIepPlanQuery } from 'shared/generated/graphql-types';

library.add(faFileDownload)

export default Vue.extend<{}, Methods, Computed, Props>({
  name: "Single",
  components: {
    ScrollablePanel,
    Loading,
    UIInput,
    CollapsibleSection,
    RouterLink,
    UIButton
  },
  directives: {
    fullheight
  },
  props: {
    teen: {},
    teenId: {},
    loading: {},
    client: {},
    season: {}
  },
  methods: {
    formatTeen({ Person: { firstName, lastName, hebrewFirstName }}) {
      return `${firstName} ${lastName}${hebrewFirstName ? ` (${hebrewFirstName})` : ''}`;
    },
    getGrade (graduationYear: number | null) {
      if (!graduationYear) return null;
      return getGrade(graduationYear).grade
    },
    formatGender ({ Person: { gender }}) {
      if(!gender) {
        return 'Male';
      }
      return 'Female';
    },
    phoneFormat,
    dateFormat,
    async getAllergyActionPlan() {
      const { data: { allergyActionPlan: { data, contentType, fileName } } } = await this.client.query<GetAllergyActionPlanQuery>({
        query: GetAllergyActionPlanDocument,
        variables: {
          personId: this.teen.personID
        }
      });
      FileSaver.saveAs(b64toBlob(data!, contentType!, `data:${contentType};base64,`), fileName!);
    },
    async getIEPPlan() {
      const { data: { iepPlan: { data, contentType, fileName } } } = await this.client.query<GetIepPlanQuery>({
        query: GetIepPlanDocument,
        variables: {
          personId: this.teen.personID
        }
      });
      FileSaver.saveAs(b64toBlob(data!, contentType!, `data:${contentType};base64,`), fileName!);
    }
  },
  computed: {
    currentPlan() {
      const currentPlan = (this.teen.Person.Memberships || []).find(m => m.season === this.season);
      if(currentPlan) {
        return currentPlan.Plan.name;
      }
      return '';
    }
  }
})

