
import Vue from 'vue'
import FancySelect from 'shared/ui/forms/FancySelect';
import { Computed, Props } from './types';

export default Vue.extend<{}, {}, Computed, Props>({
  name: 'SchoolTypeLookup',
  components: {
    FancySelect
  },
  props: {
    disabled: {},
    value: {},
    filterOutTypes: {},
    schoolTypes: {},
    loading: {}
  },
  computed: {
    filteredSchoolTypes () {
      if (this.filterOutTypes.length > 0) {
        return this.schoolTypes.filter((t) => !this.filterOutTypes.includes(t.value));
      }
      return this.schoolTypes;
    }
  }
})
