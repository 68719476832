<template>
  <div class="text-truncate">
    <span v-if="!show">{{shortText}}</span>
    <span v-else>{{targetText}}</span>
    <span class="action" v-if="show" @click="show = false">Less</span>
    <span class="action" v-else @click="show = true">More</span>
  </div>
</template>

<script>
import truncate from 'lodash/truncate';
export default {
  name: 'TextTruncate',
  data () {
    return {
      show: false
    }
  },
  props: {
    targetText: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    shortText () {
      return truncate(this.targetText, {length: this.limit, separator: ' '})
    }
  }
}
</script>

<style scoped lang="scss">
  .action {
    color: #ee7c17;
    font-size: 10px;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
  }
</style>
