<template>
	<select id="timezone" :value="value"
	    @change="$emit('input', $event.target.value)">
		<option :value=null>Choose a timezone</option>
		<option value="America/Anchorage">(GMT-09:00) Alaska</option>
		<option value="America/Ensenada">(GMT-08:00) Tijuana, Baja California</option>
		<option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US &
			Canada)
		</option>
		<option value="America/Denver">(GMT-07:00) Mountain Time (US
			& Canada)</option>
		<option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz,
			Mazatlan
		</option>
		<option value="America/Dawson_Creek">(GMT-07:00) Arizona</option>
		<option value="America/Belize">(GMT-06:00) Saskatchewan, Central
			America
		</option>
		<option value="America/Cancun">(GMT-06:00) Guadalajara, Mexico
			City Monterrey</option>
		<option value="America/Chicago">(GMT-06:00) Central Time (US &
			Canada)
		</option>
		<option value="America/New_York">(GMT-05:00) Eastern Time (US &
			Canada)
		</option>
		<option value="America/Argentina/Buenos_Aires">(GMT-03:00) Argentina</option>
		<option value="Chile/Continental">(GMT-04:00) Continental Chile</option>
		<option value="Chile/EasterIsland">(GMT-06:00) Easter Island</option>
		<option value="Asia/Jerusalem">(GMT+02:00) Israel</option>
	</select>
</template>
<script>
export default {
	name: 'TimezoneSelector',
	props: ['value']
};
</script>
