
import UIInput from 'shared/ui/forms/Input.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import UITable from 'shared/ui/tables/Tables.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import { Vue, Prop, Component } from 'vue-property-decorator';
import Modal from 'shared/ui/modal/Modal.vue';
import uniqBy from 'lodash/uniqBy';
import EditBusForm from './components/EditBusForm.vue';
import SlottedTable from 'shared/ui/tables/SlotedTable.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faBus } from '@fortawesome/pro-solid-svg-icons/faBus';
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange';
import { faLongArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faLongArrowAltRight';
import { faLongArrowAltLeft } from '@fortawesome/pro-solid-svg-icons/faLongArrowAltLeft';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import PotentialPassenger from '../shared/PotentialPassenger';
import { Bus, Props as BusingProps } from '../types';
library.add(faTrash, faComment, faPencil, faBus,faHome, faUsers, faExchange, faLongArrowAltRight, faLongArrowAltLeft, faQuestion)

@Component({
	name: 'Bus',
	components: {
		UIButton, UITable, UIInput, FancySelect, Modal, EditBusForm, SlottedTable, UITextarea
    },
    data() {
        return {
            editing: false
        }
    }
})
export default class extends Vue {
  @Prop() bus!: Bus;
  @Prop({ type: Function }) editBus!: BusingProps['editBus'];
  @Prop() potentialPassengers!: PotentialPassenger[];
  @Prop({ type: Function }) addToBus!: BusingProps['addToBus'];
  @Prop({ type: Function }) removeFromBus!: BusingProps['removeFromBus'];
  @Prop({ default: null }) selectedPassenger!: PotentialPassenger;
    textarea = null
    showPassengers: boolean = false
    addingNote: boolean = false
    confirmRemoval: boolean = false
    get listDisplayHeaders() {
        return [
            `Passengers (${this.passengers.length || 0}${this.bus.capacity ? ` / ${this.bus.capacity}` : ''})`,
            'Type',
        ];
    }

    get passengers(): PotentialPassenger[] {
        return uniqBy([
            ...(this.bus.TravelToEventRegistrations || []).map(r => new PotentialPassenger(r, "registration")),
            ...(this.bus.ReturnFromEventRegistrations || []).map(r => new PotentialPassenger(r, "registration")),
            ...(this.bus.TravelToEventEventStaff || []).map(s => new PotentialPassenger(s, "eventstaff")),
            ...(this.bus.ReturnFromEventEventStaff || []).map(s => new PotentialPassenger(s, "eventstaff")),
            ...(this.bus.TravelToEventEventGuests || []).map(g => new PotentialPassenger(g, "eventguests")),
            ...(this.bus.ReturnFromEventEventGuests || []).map(g => new PotentialPassenger(g, "eventguests"))
        ], 'id');
    }

    get filteredPotentialPassengers(): PotentialPassenger[] {
      return this.potentialPassengers.filter(p => {
        const condition = (p.model.ToBus || {busID: 0}).busID !== this.bus.busID && (p.model.FromBus || {busID: 0}).busID !== this.bus.busID;
        if (this.bus.directionFlag === 'B') {
          return condition || (!p.model.FromBus || !p.model.ToBus);
        } else {
          return condition;
        }
      });
    }

    handleDelete(close: () => {}) {
      this.$emit('deleteBus', this.bus.busID);
      close();
    }

    updateShowPassengers() {
       this.showPassengers = !this.showPassengers
    }

    async potentialPassengerAddToBusHandler(p: PotentialPassenger | null) {
      if(!p) {
        return;
      }
      await this.addToBus(p.entityId, this.bus, p.source, p);
    }

    async potentialPassengerRemoveFromBusHandler(p: PotentialPassenger | null) {
      if(!p) {
        return;
      }
      await this.removeFromBus(p.entityId, this.bus, p.source, p);
    }
};

