import SelectDuplicatesFields from './SelectDuplicatesFields.vue';
import { compose, withData } from 'vue-compose';
import { mergeTeenDuplicatesEnhancer } from './mergeTeenDuplicatesEnhancer';

export default compose(
  mergeTeenDuplicatesEnhancer,
  withData({
    showMergeScreen: {
      initialValue: false
    }
  })
)(SelectDuplicatesFields);
