
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import VueApexCharts from 'vue-apexcharts'
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { CompactDashboardData } from '../types';
import Loading from 'shared/components/Loading.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { RouterLink } from 'vue-component-router';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import { Registration } from '../types'

@Component({
  name: 'PaymentData',
  components: {
  Loading,
  PanelsGrid,
  ScrollablePanel,
  RouterLink,
  apexchart: VueApexCharts,
  },
  directives: {
    fullheight
  }
})
export default class extends Vue {
    @Prop() eventId!: number;
    @Prop() dashboardData!: CompactDashboardData | null;
    @Prop() router!: { history: { push: (location: string) => void } };
    @Prop() fetchingDashboardData!: boolean;

    get seriesScholarship () {
        return [this.scholarshipsStatusPending, this.scholarshipsStatusCompleted, this.scholarshipsStatusDenied];
    }
    get scholarshipsStatusCompleted () {
      return this.scholarshipsStatus && this.scholarshipsStatus.approved
    }
    get scholarshipsStatusPending () {
      return this.scholarshipsStatus && this.scholarshipsStatus.pending
    }
    get scholarshipsStatusDenied () {
      return this.scholarshipsStatus && this.scholarshipsStatus.denied
    }
    get seriesPay () {
      return [this.paymentStatusesCompleted, this.paymentStatusesPending];
    }
    get paymentStatusesCompleted () {
      return this.paymentsStatus && this.paymentsStatus.completed
    }
    get paymentStatusesPending () {
      return this.paymentsStatus && this.paymentsStatus.pending
    }
    get paymentData () {
      return !!this.seriesReg.length
      ||
      this.paymentStatusesCompleted
      ||
      this.paymentStatusesPending
      ||
      this.scholarshipsStatusPending
      ||
      this.scholarshipsStatusCompleted
    }
    get groupsOfRegistrationsStatus() {
        if (!this.dashboardData) return null
        return this.dashboardData.registrationGroupedByStatus;
    }
    get groupsScholarshipsStatus() {
        if (!this.dashboardData) return null
        return this.dashboardData.scholarshipsStatus;
    }
    get timezone() {
        if (!this.dashboardData) return null;
        return this.dashboardData.timeZone;
    }
    get paymentsStatus() {
        if (!this.dashboardData) return null;
        return this.dashboardData.paymentsStatus
    }
    get scholarshipsStatus() {
        if (!this.dashboardData) return null;
        return this.dashboardData.scholarshipsStatus
    }
    get seriesReg() {
        if (!this.groupsOfRegistrationsStatus || !Object.keys(this.groupsOfRegistrationsStatus).length) return []
        return Object.keys(this.groupsOfRegistrationsStatus).map(x => this.groupsOfRegistrationsStatus![x as Registration['status']].length)
    }
    get chartScholarshipOptions () {
      const labels = this.groupsScholarshipsStatus ? map(Object.keys(this.groupsScholarshipsStatus), capitalize) : [];
      const that = this;
      return {
        labels,
            chart: {
              allowMultipleDataPointsSelection: true,
              events: {
                dataPointSelection (event: any, chartContext: any, { dataPointIndex }: any) {
                  const filter = labels[dataPointIndex];
                  // if (labels[dataPointIndex] === 'Pending')
                  // prevent Apexcharts to throw error on route change
                  setTimeout(() => {
                    that.router.history.push(`/events/${that.eventId}/registrations?scholarship=${filter}`)
                  })
                }
              },
            },
        legend: {
            show: true,
            position: 'bottom'
        },
        colors: ["#FFDB8E", "#8298D6", "#74C6CE", "#ea7d1c"],
        dataLabels: {
            formatter: function(val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex]
            },
        }
      }
    }
    get chartPayOptions () {
      const labels = ['Completed', 'Unpaid'];
      const that = this;
      return {
        labels,
            chart: {
              allowMultipleDataPointsSelection: true,
              events: {
                dataPointSelection (event: any, chartContext: any, { dataPointIndex }: any) {
                  const filter = labels[dataPointIndex] === 'RefundRequired' ? 'RefundNeeded' : labels[dataPointIndex];
                  // if (labels[dataPointIndex] === 'Pending')
                  // prevent Apexcharts to throw error on route change
                  setTimeout(() => {
                    that.router.history.push(`/events/${that.eventId}/registrations?paymentStatus=${filter}`)
                  })
                }
              },
            },
        legend: {
            show: true,
            position: 'bottom'
        },
        colors: ["#FFDB8E", "#8298D6", "#74C6CE", "#ea7d1c"],
        dataLabels: {
            formatter: function(val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex]
            },
        }
      }
    }
    get chartRegOptions() {
        const labels = this.groupsOfRegistrationsStatus ? Object.keys(this.groupsOfRegistrationsStatus) : [];
        const that = this;
        return {
            labels,
            chart: {
              allowMultipleDataPointsSelection: true,
              events: {
                dataPointSelection (event: any, chartContext: any, { dataPointIndex }: any) {
                  const filter = labels[dataPointIndex] === 'RefundNeeded' ? 'RefundRequired' : labels[dataPointIndex];
                  // if (labels[dataPointIndex] === 'Pending')
                  // prevent Apexcharts to throw error on route change
                  setTimeout(() => {
                    that.router.history.push(`/events/${that.eventId}/registrations?registrationStatus=${filter}`)
                  })
                }
              },
            },
            legend: {
                show: true,
                position: 'bottom'
            },
            colors: ["#FFDB8E", "#8298D6", "#74C6CE", "#ea7d1c"],
            dataLabels: {
                formatter: function(val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex]
                }
            }
        }
    }
};
