
import UISelect from 'shared/ui/forms/FancySelect';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GetRegionsQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Region = ArrayElement<GetRegionsQuery['regions']>

@Component({
	components: { UISelect }
})
export default class RegionLookup extends Vue {
		@Prop({default: true}) clearable!: boolean
		@Prop() loading!: boolean
		@Prop() disabled!: boolean
		@Prop({default: () => []}) regions!:  Region[];
		@Prop({type: [Number, Object]}) value!: number | Region;

		get valueAsObject () {
			if (typeof this.value === 'object') {
				return this.value
			}

			return this.regions.find(x => x.regionId === this.value)
		}
};
