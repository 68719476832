

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import SchoolLookup from 'shared/components/SchoolLookup';
import { getCurrentSeason } from 'shared/util';

@Component({
  components: {
    SchoolLookup
  }
})
export default class SchoolIdPicker extends Vue {
  @Prop() value!: number;
}
