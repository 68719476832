import { Component, ComputedRef, defineComponent, h, PropOptions } from 'vue';
import { getOptions, normalizeProps } from 'shared/util';
import { ComponentObjectPropsOptions } from 'vue/types/v3-component-props';

// Remove undefined props to get a warning in case prop is required
const prepareChildProps = (props: Record<string, any>) => {
  return Object.keys(props).reduce((acc, curr) => {
    if (props[curr] !== undefined) {
      acc[curr] = props[curr]
    }
    return acc
  }, {} as Record<string, any>)
}

export const wrapComponent = <
  InnerProps extends Record<string, any>,
  OuterProps extends Record<string, any>
>(
  outerPropsFn: (props: InnerProps) => ComputedRef<Partial<OuterProps>>
) => {
  return (WrappedComponent: Component) => {
    type PropKey = keyof InnerProps
    const normalizedChildProps: Record<PropKey, PropOptions> = normalizeProps(
      getOptions(WrappedComponent).props
    );

    // Do not require values for HOC's since they can be undefined at the top level
    const childProps = Object.keys(normalizedChildProps).reduce((acc, curr) => {
      if (normalizedChildProps[curr].required) {
        acc[curr as PropKey] = {
          ...normalizedChildProps[curr as PropKey],
          required: false
        }
      } else {
        acc[curr as PropKey] = normalizedChildProps[curr as PropKey]
      }
      return acc
    }, {} as ComponentObjectPropsOptions<InnerProps>)
    const name = getOptions(WrappedComponent).name;

    return defineComponent({
      name: `${name}WithApollo`,
      props: childProps,
      setup(props, { attrs, listeners, slots }) {
        // @ts-expect-error ExtractPropTypes infer required props as undefined
        const outerProps = outerPropsFn(props);

        return () =>
          h(WrappedComponent, {
            on: listeners,
            attrs,
            props: prepareChildProps({
              ...props,
              ...outerProps.value
            }),
            scopedSlots: slots
          });
      }
    });
  };
};
