
import { Route, MatchFirst } from "vue-component-router";
import CustomQuestionsCollectionList from "./List";
import CustomQuestionsCollectionItem from "./CustomQuestionsCollectionItem";

export default {
  props: ["path", "user", "regionId", "eventId"],
  name: "CustomQuestionsCollectionPage",
  components: {
    Route,
    MatchFirst,
    CustomQuestionsCollectionList,
    CustomQuestionsCollectionItem,
  }
};
