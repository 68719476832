
import Vue from 'vue';
import { Props, Data, Methods, Computed } from './types';
import provinces from 'provinces';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import Divider from 'shared/layout/divider/layout-divider.vue';
import teenInterests from 'shared/teenInterests';
import UISelect from 'shared/ui/forms/FancySelect';
import StaffLookup from 'shared/components/StaffLookup';
import { asUTCWithZerotime, dateFormat, validateEmail, validateNumber, validateURL, splitName, phoneFormat, validateName } from 'shared/util';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import { RadioGroup, RadioGroupItem } from 'shared/radio-group';
import UISwitch from 'shared/ui/forms/Switch.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import SchoolLookup from 'shared/components/SchoolLookup';
import PostHighSchoolLookup from 'shared/components/PostHighSchoolLookup';
import ChapterLookup from 'shared/components/ChapterLookup';
import FancyCheckbox from 'shared/ui/forms/FancyCheckbox.vue';
import SynagogueLookup from 'shared/components/SynagogueLookup';
import AutoSaveField from 'shared/components/AutoSaveField';
import EmailInput from 'shared/components/EmailInput';
import EmergencyContacts from 'shared/components/EmergencyContacts';
import QuickAddParent from 'shared/components/QuickAddParent';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import Address from 'shared/components/Address/Address.vue';
import range from 'lodash/range';
import { graduationYears, getGrade, getCurrentSeason, toEntity, phoneInternational } from 'shared/util';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import RequiresPermission from 'shared/components/RequiresPermission';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import cloneDeep from 'lodash/cloneDeep';
import AddToFamily from 'shared/components/AddToFamily';
import AddParentToFamily from 'shared/components/AddParentToFamily';
import { RouterLink } from 'vue-component-router';
import Modal from 'shared/ui/modal/Modal.vue';
import FamilySelector from 'shared/components/FamilySelector';
import { EntityUnionEnum, Gender, PermissionEnum, UpdateTeenField } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Profile',
	components: {
    CollapsibleSection,
    SynagogueLookup,
    UISelect,
    ChapterLookup,
    FancyCheckbox,
    SchoolLookup,
    PostHighSchoolLookup,
    PhoneInput,
    EmailInput,
    SimpleForm,
    RadioGroup,
    RadioGroupItem,
    UITextarea,
    UIToggle,
    UICheckbox,
    UIButton,
    UISwitch,
    UIInput,
    Divider,
    UIRadio,
    Field,
    Error,
    AutoSaveField,
    Address,
    StaffLookup,
    EmergencyContacts,
    RequiresPermission,
    AddToFamily,
    AddParentToFamily,
    RouterLink,
    Modal,
    FamilySelector,
    QuickAddParent
	},
  props: {
    teen: {},
    personId: {},
    regionId: {},
    addingNote: {},
    connectingParentToTeen: {},
    claims: {},
    updateTeen: {},
    connectParentToTeen: {},
    country: {},
    markFamilyAsPrimary: {},
    markFamilyAsPrimaryRunning: {},
    markingFamilyAsPrimary: {},
    addingToAdditionalFamily: {},
    addParentToFamily: {},
    addingParentToFamily: {}
  },
  data() {
    return {
      PermissionEnum,
      Gender,
      EntityUnionEnum,
      teenCopy: Object.assign({}, this.teen),
      updateTeenField: UpdateTeenField,
      interests: teenInterests,
      addingNewEmergencyContact: false,
      youthGroups: ['USY', 'BBYO', 'Bnei Akiva', 'C-Teen', 'NFTY'],
      selectedFamily: null,
      showAddFather: false,
      showAddMother: false
    };
  },
  computed: {
    graduationYears() {
        return graduationYears().map(x => x.grade);
    },
    calendarYears() {
        const currentSeason = Number(getCurrentSeason().split('-')[0]);
        return range(2007, currentSeason + 6).reverse();
    },
    states() {
        return provinces.filter(x => ['CA', 'US'].includes(x.country)).map(x => x.name).sort();
    },
    nonPrimaryFamilies() {
      return (this.teen.primaryFamily ? this.teen.families.filter(f => f.id !== this.teen.primaryFamily!.id) : this.teen.families) || [];
    }
  },
  methods: {
    openMarkFamilyAsPrimaryModal(f) {
      this.selectedFamily = f;
      this.$emit('markingFamilyAsPrimary', true);
    },
    async markFamilyAsPrimaryHandler() {
      if(this.selectedFamily) {
        await this.markFamilyAsPrimary(this.personId, this.selectedFamily.id, this.teenCopy.original);
        this.$emit('markingFamilyAsPrimary', false);
      }
    },
    getGrade,
    toEntity,
    phoneInternational,
    validateName,
    validateEmail,
    validateURL,
    asUTCWithZerotime,
    dateFormat,
    validateNumber,
    async updateParentField (args) {
      const {parentId, value} = args.transformed;
      if(!parentId) {
        return;
      }
      args.transformed.value = JSON.stringify({ parentId, value});
      return await this.update(args);
    },
    async update (args) {
      await this.updateTeen({
          personId: this.personId,
          fieldName: args.transformed.fieldName,
          value: args.transformed.value,
          viewModel: this.teenCopy
      });
    },
    async connectParentToTeenHandler (parent) {
      if (parent.id) await this.connectParentToTeen({ parentId: parent.id, childId: this.personId }, parent);
    },
    splitName(name) {
        if (!name) {
            return {
                firstName: '',
                lastName: ''
            }
        }
        return splitName(name);
    },
    phoneFormat(number) {
        if (!number) return '';
        return phoneFormat(number, this.teen.country || '');
    },
    interestsToEntity(v, existing) {
        if (!v) {
            return existing;
        }
        if (v instanceof Array) {
            v = v.join(',');
        }
        return v.split(',').filter(i => i).map(i => i.trim()).join(',');
    },
    transformSocialMedia(value, social) {
        const socialMedia = this.teenCopy.socialMediaHandles || {};
        socialMedia[social] = value;
        return {
            value: JSON.stringify(socialMedia),
            fieldName: this.updateTeenField.SocialMediaHandles
        }
    },
    async handleAssociate(parent) {
      if (!this.teen.father && !this.teen.mother) {
         await this.connectParentToTeenHandler(parent)
      } else {
         await this.addParentToFamily({
            familyId: this.teen.primaryFamily?.id || this.teen.families[0].id,
            personId: parent.id,
            gender: parent.gender
         })
      }
      this.showAddFather = false;
      this.showAddMother = false;
    }
  },
  watch: {
    teen: {
      deep: true,
      handler() {
        this.teenCopy = cloneDeep(this.teen)
      }
    }
  }
});
