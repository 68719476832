
import { RouterLink } from 'vue-component-router';
import Vue from "vue";
import Loading from 'shared/components/Loading.vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Avatar from 'shared/components/avatar';
import { Props as LayoutProps, Parent } from '../types';

interface Data {
  limit: number;
}

interface Methods {
  getGenderColor: (gender: number) => string;
  formatParent: (parent: Parent) => string;
  fetchMoreHandler: (state: StateChanger) => Promise<void>;
}

interface Props {
  parents: LayoutProps['parents'];
  fetchMore: LayoutProps['fetchMore'];
  activeFilters: LayoutProps['activeFilters'];
  selectedParent: number;
}

export default Vue.extend<{}, Methods, {}, Props>({
	name: 'JFEParentsList',
	components: {
		BorderedList,
		InfiniteLoading,
		Loading,
		RouterLink,
		Avatar
  },
  data () {
    return {
      limit: 60
    }
  },
  props: {
    parents: {},
    fetchMore: {},
    selectedParent: {},
    activeFilters: {}
  },
  methods: {
    getGenderColor (gender) {
      if (!gender) {
        return '#00bcd4'
      } else {
        return '#cd8ece'
      }
    },
    formatParent({ firstName, lastName }){
      return `${firstName} ${lastName}`;
    },
    async fetchMoreHandler (state: StateChanger) {
      await this.fetchMore({limit: this.limit, offset: this.parents.length}, state);
			state.reset();
			state.loaded();
    }
  }
})

