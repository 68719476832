
import Vue from 'vue';
import { Props } from './types';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { formatDate, dayOfWeekAndDate } from 'shared/util';
import Loading from 'shared/components/Loading.vue';
import { RouterLink } from 'vue-component-router';
import fullheight from 'shared/directives/fullheight';
import { SimpleDate } from 'shared/util/types';

export default Vue.extend<{},{},{}, Pick<Props, 'eventGroups' | 'today' >>({
  name: 'EventsList',
  props: {
    eventGroups: {},
    today: {}
  },
  directives: {
      fullheight
    },
  mounted () {
    const pad = (num: number) => num < 10 ? `0${num}` : num;

    const scrollToFirstRef = (date: SimpleDate) => {
      if (date.day === 1) return;

      const today = `${date.year}-${pad(date.month)}-${pad(date.day)}`

      const ref: any = this.$refs[today]

      if (ref) {
        (ref[0] as Element).scrollIntoView({ block: 'nearest' })
      }
      else {
        scrollToFirstRef({year: date.year, month: date.month, day: date.day - 1})
      }
    }

    scrollToFirstRef(this.today)

  },
  components: {
    ScrollablePanel,
    Loading,
    RouterLink
  },
  methods: {
    dayOfWeekAndDate,
    formatDate
  }
})
