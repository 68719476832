
import Vue from 'vue';
import Loading from 'shared/components/Loading.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import SimpleForm from 'vue-simpleform';
import FormSection from 'shared/ui/forms/FormSection.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { Props, Methods } from './types';

export default Vue.extend<{}, Methods, {}, Props>({
    name: 'DeleteCustomQuestionsCollectionModal',
    components: {
        UIButton,
        SimpleForm,
        FormSection,
        Loading,
        Modal
    },
    props: {
        collectionToDelete: {},
        countersLoading: {},
        counters: {},
    }
});
