
import { RouterLink } from 'vue-component-router';
import Vue from "vue";
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { DocumentNode } from 'graphql';
import Loading from 'shared/components/Loading.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import FetchMoreOptions from '../shared/FetchMoreOptions';
import ActiveFilters from '../shared/ActiveFilters';
import Avatar from 'shared/components/avatar';
import { getGrade, getFullName } from 'shared/util';
import { FetchResult } from '@apollo/client';
import { TeensByRegionDocument, TeensListQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Teen = ArrayElement<TeensListQuery['teens']['teens']>

interface Data {
	limit: number;
	teensByRegionQuery: DocumentNode;
	localCheckedTeens: number[];
}

interface Props {
	regionId: number;
	teens: Teen[];
	fetchMore: (args: FetchMoreOptions, stateChanger: StateChanger) => Promise<FetchResult<TeensListQuery>>;
	activeFilters: ActiveFilters;
	selectedTeen: number | null;
	checkedTeens: number[];
}

interface Methods {
	fetchMoreHandler: ($state: StateChanger) => Promise<void>;
	formatTeen: (teen: Teen) => string;
	getGrade: (graduationYear: number | null) => string | null;
	getGenderColor: (gender: number) => string;
}

export default Vue.extend<Data, Methods, {}, Props>({
	name: 'TeenList',
	components: {
		BorderedList,
		InfiniteLoading,
		Loading,
		RouterLink,
		Avatar
	},
	props: {
		regionId: {},
		teens: {},
		fetchMore: {},
		activeFilters: {},
		selectedTeen: {},
		checkedTeens: {}
	},
	data() {
		return {
			limit: 60,
			teensByRegionQuery: TeensByRegionDocument,
			localCheckedTeens: this.checkedTeens
		}
	},
	methods: {
		async fetchMoreHandler ($state) {
			const { data } = await this.fetchMore({limit: this.limit, offset: this.teens.length}, $state);
			if (data?.teens.teens && data?.teens.teens.length < this.limit) {
				$state.complete()
			} else {
				$state.reset()
				$state.loaded()
			}
		},
		formatTeen({ Person: { firstName, lastName }}) {
			return getFullName(firstName, lastName)
		},
		getGrade (graduationYear: number | null) {
			if (!graduationYear) return null;
			return getGrade(graduationYear).grade
		},
		getGenderColor (gender: number) {
			if (!gender) {
				return '#00bcd4'
			} else {
				return '#cd8ece'
			}
		}
	}
})
