
import Vue from 'vue';
import FamilySelector from 'shared/components/FamilySelector';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import ParentLookup from 'shared/components/ParentLookup';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import { ErrorBag } from 'shared/util/types';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import { Data, Methods, Props } from './types';
import { Gender, MaritalStatus } from 'shared/generated/graphql-types';

// d m c p
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddToFamily',
  components: {
    FamilySelector,
    UIToggle,
    ParentLookup,
    SimpleForm,
    Field,
    Error,
    UICheckbox,
    UIButton,
    UIRadio,
    ...RadioGroup,
  },
  props: {
    regionId: {},
    childId: {},
    createFamily: {},
    addToFamily: {},
  },
  data () {
    return {
      creatingFamily: true,
      Gender,
      MaritalStatus,
      formShape: {
        father: null,
        mother: null,
        markAsPrimary: true,
        maritalStatus: MaritalStatus.Married
      }
    };
  },
  methods: {
    async submitHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      await this.createFamily({
        input: {
          fatherId: args.values.father ? args.values.father.personId : null,
          motherId: args.values.mother ? args.values.mother.personId : null,
          maritalStatus: args.values.maritalStatus,
          childrenIds: [this.childId],
          childId: this.childId,
          regionId: this.regionId
        }
      }, args.values.father, args.values.mother);
    },
    validate (values) {
      let errors: ErrorBag<typeof values> = {};

      if(!values.father && !values.mother) {
        errors.father = 'Please select at least one parent.';
        errors.mother = 'Please select at least one parent.';
      }

      return errors;
    }
  }
});
