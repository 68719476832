
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import Avatar from 'shared/components/avatar';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { EntityUnionViewModel } from '../shared/EntityUnionViewModel';
import { library } from '@fortawesome/fontawesome-svg-core';
import { EntityUnionEnum } from "shared/generated/graphql-types";

library.add(faPhone);

@Component({
  name: 'PopupDetails',
	components: {
    Avatar,
  }
})
export default class PopupDetails extends Vue {

    @Prop() duplicate!: EntityUnionViewModel;
    @Prop() left!: boolean;
    @Prop() narrow!: boolean;
    @Prop() sidebar!: boolean;
    EntityUnionEnum: typeof EntityUnionEnum = EntityUnionEnum
    get thumbnail() {
        return this.duplicate && this.duplicate.thumbnail || '';
    }
    get duplicateType() {
      return this.duplicate && this.duplicate.type;
    }
}
