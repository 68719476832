
import { Vue } from 'vue-property-decorator';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import UISelect from 'shared/ui/forms/FancySelect';
import UIButton from 'shared/ui/buttons/Button.vue';
import fullheight from 'shared/directives/fullheight';
import { Computed, Methods, Props } from './FilterOptions';
import { WaiverStatus, BusStatus, ScholarshipStatus } from '../types';
import * as RadioGroup from 'shared/radio-group';
import UIRadio from 'shared/ui/forms/Radio.vue';
import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import startCase from 'lodash/startCase';
import { getGrade } from 'shared/util';
import toLower from 'lodash/toLower';
import orderBy from 'lodash/orderBy';
import { isArray } from 'util';
import isNull from 'lodash/isNull';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { PermissionEnum, RegistarAccountType, RegistrationPaymentStatus, SecondaryInstitutionType, SortType, StatusType } from 'shared/generated/graphql-types';

const schoolTypeMappings = {
  [SecondaryInstitutionType.CommunitySchool]: 'Community School',
  [SecondaryInstitutionType.FourYear]: 'Four Year',
  [SecondaryInstitutionType.GapYearIsrael]: 'Gap Year Israel',
  [SecondaryInstitutionType.GapYearNonIsrael]: 'Gap Year Non-Israel',
  [SecondaryInstitutionType.Idf]: 'IDF',
  [SecondaryInstitutionType.JewishDaySchool]: 'Jewish Day School',
  [SecondaryInstitutionType.Other]: 'Other',
  [SecondaryInstitutionType.Program]: 'Program',
  [SecondaryInstitutionType.PublicSchool]: 'Public School',
  [SecondaryInstitutionType.Seminary]: 'Seminary',
  [SecondaryInstitutionType.TwoYear]: 'Two Year',
  [SecondaryInstitutionType.UsMilitary]: 'US Military',
  [SecondaryInstitutionType.YeshivaDaySchool]: 'Yeshiva Day School',
}

library.add(faSortAlphaDown, faSortAlphaUp);

function enumToList(_enum: object, filter: (x: any) => boolean = (x: any) => true) {
  return Object.keys(_enum)
    .filter((x) => Number.isNaN(+x) && filter(x))
    .map((x) => ({
      label: startCase(x),
      value: x
    }));
}

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'FiltersPanel',
  components: {
    ScrollablePanel,
    UISelect,
    Divider,
    ...RadioGroup,
    UIRadio,
    UIButton
  },
  directives: {
    fullheight
  },
  props: {
    registrations: { type: Array },
    filters: {},
    eventTypeId: { type: Number, default: 0 },
    setFilter: {},
    setFilters: {},
    eventTickets: { type: Array },
    setSortField: {},
    setSortDescending: {},
    sortDescending: { type: Boolean },
    sortBy: {},
    claims: {},
    isParentalApprovedRequired: {},
    ncsyWaiverRequired: {},
    additionalWaivers: {}
  },
  data() {
    return {
      PermissionEnum: PermissionEnum
    };
  },
  computed: {
    sorts() {
      return enumToList(SortType);
    },
    genders() {
      return [
        {
          label: 'Male',
          value: 0
        },
        {
          label: 'Female',
          value: 1
        }
      ];
    },
    mediaConsents() {
      return [
        {
          label: 'Signed',
          value: 0
        },
        {
          label: 'Not Signed',
          value: 1
        },
        {
          label: 'Does not agree to sharing',
          value: 2
        }
      ];
    },
    dataConsents() {
      return [
        {
          label: 'Signed',
          value: 0
        },
        {
          label: 'Not Signed',
          value: 1
        },
        {
          label: 'Does not agree to sharing',
          value: 2
        }
      ];
    },
    liabilityConsents() {
      return [
        {
          label: 'Signed',
          value: 0
        },
        {
          label: 'Not Signed',
          value: 1
        }
      ];
    },
    eventWaivers() {
      return [
        {
          label: 'Signed',
          value: 0
        },
        {
          label: 'Not Signed',
          value: 1
        }
      ];
    },
    chapters() {
      return uniqBy(
        compact(
          this.registrations.map((x) =>
            x.Teen.Chapter
              ? { value: x.Teen.Chapter.chapterId, label: x.Teen.Chapter.chapterName }
              : null
          )
        ),
        'value'
      );
    },
    registrationStatuses() {
      return enumToList(StatusType).filter(
        (item) => ![StatusType.Cancelled, StatusType.Refunded].includes(item.value as StatusType)
      );
    },
    paymentStatuses() {
      const RegPaymentLabelsToSummerProgramLabels: { [key: string]: string } = {
        Cancelled: 'Cancelled',
        Completed: 'Accepted',
        HasScholarship: 'HasScholarship',
        PendingApproval: 'Incomplete Application',
        RefundNeeded: 'Refund Needed',
        Unpaid: 'Unpaid',
        WaitingForDeposit: 'WaitingForDeposit',
        WaitingList: 'Applied'
      }

      let list = enumToList(
        RegistrationPaymentStatus,
        (r: RegistrationPaymentStatus) =>
          !!this.claims.find((c) => c === PermissionEnum.ViewScholarship) ||
          r !== RegistrationPaymentStatus.HasScholarship
      )

      if (this.eventTypeId === 28) {
        list = list.map((x: any) => ({
          label: RegPaymentLabelsToSummerProgramLabels[x.value],
          value: x.value
        }));
      }

      list.push({label: 'No Payments Made', value: 'NoPaymentsMade'})

      return list
    },
    graduationYears() {
      return orderBy(
        uniqBy(
          compact(
            this.registrations.map((x) =>
              x.Teen.graduationYear
                ? { value: x.Teen.graduationYear, label: getGrade(x.Teen.graduationYear).grade }
                : null
            )
          ),
          'value'
        ),
        ['label'],
        ['desc']
      );
    },
    schools() {
      return uniqBy(
        compact(
          this.registrations.map((x) =>
            x.Teen.School ? { value: x.Teen.School.schoolID, label: x.Teen.School.name } : null
          )
        ),
        'value'
      );
    },
    schoolTypes() {
      return orderBy(
        uniqBy(
          compact(
            this.registrations
              .filter(r => r.Teen.School?.secondaryType)
              .map((r) =>
                r.Teen.School
                  ? { value: r.Teen.School.secondaryType, label: schoolTypeMappings[r.Teen.School.secondaryType!] }
                  : null
              )
          ),
          'value'
        ),
        ['label'],
        ['asc']
      )
    },
    ticketTypes() {
      return this.eventTickets.map((x) => ({ label: x.Description as string, value: x.EventTicketID }));
    },
    waiverStatuses() {
      return enumToList(WaiverStatus);
    },
    scholarshipStatuses() {
      return enumToList(ScholarshipStatus);
    },
    busStatuses() {
      return enumToList(BusStatus);
    },
    registarAccountTypes() {
      return enumToList(RegistarAccountType);
    },
    parentalApproval() {
      return [
        {
          label: 'No',
          value: 0
        },
        {
          label: 'Yes',
          value: 1
        }
      ];
    }
  },
  methods: {
    extractGraduationYears(graduations) {
      if (typeof graduations === 'object' && !Array.isArray(graduations)) {
        return graduations.value;
      } else if (graduations && graduations.length) {
        return graduations.map((x) => x.value);
      } else {
        return null;
      }
    },
    getConsentsValue(collection, value) {
      if (isNull(value)) {
        return collection.find((x) => isNull(x.value) === isNull(value));
      } else {
        return collection.find((x) => x.value === value);
      }
    },
    getValueFor(collection, value) {
      if (isArray(value)) {
        return collection.filter((c) => value.includes(c.value || toLower(c.value!)));
      }
      return collection.find((x) => toLower(x.value!) === toLower(value));
    },
    clearFilters() {
      const defaultFilters = {
        busStatus: null,
        chapter: null,
        gender: null,
        graduationYear: null,
        paymentStatus: null,
        registrationStatus: null,
        scholarship: null,
        school: null,
        schoolType: null,
        ticketType: null,
        waiverStatus: null,
        mediaConsent: null,
        dataConsent: null,
        liabilityConsent: null,
        eventWaiver: null,
        registarAccountType: null,
        parentalApprovalGiven: null
      };
      this.setFilters(defaultFilters);
    }
  }
});
