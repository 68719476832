<template>
<ul>
  <slot>
  <li v-for="item in items" v-html="item.content" :style="{padding: padding}">
  </li>
  </slot>
</ul>
</template>
<script>
export default {
	name: 'BorderedList',
	props: {
		items: {
			type: Array
		},
		padding: {
			type: String,
			default: '20px 30px'
		}
	}
};
</script>
<style lang="scss" scoped>
  li {
	margin-bottom: 15px;
	border: 1px solid #efefef;
	background:  #fff;
	transition: border .2s linear;
	padding: 10px 20px;
  }
  li:hover {
	  background: #fdf5ee;
  }
</style>
