
import { Vue, Component, Prop } from 'vue-property-decorator';
import UIButton from 'shared/ui/buttons/Button.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import { GetEventTicketsQuery } from 'shared/generated/graphql-types';

type EventTickets = GetEventTicketsQuery['event']['EventTickets']

@Component({
  components: {
    UIButton,
    Modal,
    UISelect
  }
})
export default class TicketChanger extends Vue {
  @Prop() ticketID!: number;
  @Prop() currencySymbol!: string;
  @Prop() eventID!: number; // XTODO: graphql function should allow for adding props to container so we don't have to do this
  @Prop() changeEventTicket!: (ticketID: number, force?: boolean) => any
  @Prop() eventTickets!: EventTickets;
  @Prop() updatingTicket!: boolean;
  @Prop() needsApproval!: boolean;

  value = this.eventTickets.find(e => e.EventTicketID === this.ticketID);
  async changeTicket(force?: boolean) {
    const result = await this.changeEventTicket(this.value!.EventTicketID, force);

    if (result.data.changeEventTicket.registration) {
      this.$emit('done');
    }
  }
};
