

import { Vue, Component, Prop } from 'vue-property-decorator';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Avatar from 'shared/components/avatar';
import { library } from '@fortawesome/fontawesome-svg-core';
import Loading from 'shared/components/Lock-ui.vue'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import TeenUpdateArgs from './shared/TeenUpdateArgs';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import CompactTeen from '../../shared/CompactTeen';
import StaffLookup from 'shared/components/StaffLookup';
import { ActiveFiltersType } from '../../shared/ActiveFilters';
import { getGrade } from 'shared/util';
import { TeenField } from './types';
import { DeleteRelationshipImpactMutationVariables, GetStaffQuery, RelationshipImpactInput, RelationshipImpactNoteInput, UpdateTeenField } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']

library.add(faCheck)

@Component({
  components: {
    AutoSaveField,
    BorderedList,
    StaffLookup,
    UITextarea,
    UICheckbox,
    UISelect,
    Avatar,
    Loading
  }
})
export default class Impact extends Vue {
  @Prop() teen!: CompactTeen;
  @Prop() staff!: Staff[];
  @Prop() updatingTeen!: boolean;
  @Prop() selectedTeen!: number;
  @Prop() activeFilters!: ActiveFiltersType;
  @Prop() regionId!: number;
  @Prop() user!: number;
  @Prop() addRelationshipImpact!: (args: RelationshipImpactInput) => void;
  @Prop() deleteRelationshipImpact!: (args: DeleteRelationshipImpactMutationVariables) => void;
  @Prop() deleteRelationshipImpactNote!: (noteId: number) => void;
  @Prop() setRelationshipImpactNote!: (args: RelationshipImpactNoteInput) => void;
  @Prop() updateTeen!: (args: TeenUpdateArgs) => void;

  teenCopy: CompactTeen = Object.assign({}, this.teen);
  TeenField: typeof TeenField = TeenField;

async update(args: SaveEventArgs <{ fieldName: UpdateTeenField | TeenField, value: string }> ) {
    const { transformed: { fieldName, value } } = args;
    switch (fieldName) {
        case (TeenField.relationshipImpacted): {
            value ?
                this.addRelationshipImpact({
                    personId: this.teenCopy.personId,
                    staffId: this.user,
                    season: this.activeFilters.season,
                    date: new Date()
                }) :
                this.deleteRelationshipImpact({
                    personId: this.teenCopy.personId,
                    staffId: this.user,
                    date: this.teen.relationshipImpact && this.teen.relationshipImpact.date
                })
            break;
        }
        case (TeenField.relationshipImpactNote): {
          if (value) {
              this.setRelationshipImpactNote({
                  personId: this.teen.personId,
                  staffId: this.user,
                  relationshipImpactId: this.teen.relationshipImpact!.id,
                  content: value,
                  date: (this.teen.relationshipImpact || { date: new Date()}).date
              })
          }
          else if (this.teen.relationshipImpactNote) {
            this.deleteRelationshipImpactNote(this.teen.relationshipImpactNote.id)
          }
          break;
        }
        default:
            this.updateTeen({
                personId: this.teen.personId,
                fieldName: args.transformed.fieldName as UpdateTeenField,
                value: args.transformed.value
            });
    }
}
  getGenderColor (gender: number) {
		if (!gender) {
			return '#00bcd4'
		} else {
			return '#cd8ece'
		}
  }
	getGrade (graduationYear: number | null) {
		if (!graduationYear) return null;
		return getGrade(graduationYear).grade
	}
}
