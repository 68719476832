import Single from './Single.vue';
import { computed } from 'vue';
import { compose, acceptProps } from 'vue-compose';
import { withRouter } from 'vue-component-router';
import { CompactAdvisorRegion } from '../shared/CompactAdvisorRegion';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { StaffQuery, useStaffQuery } from 'shared/generated/graphql-types';

function staffViewModel(staff: StaffQuery['singleStaff']): CompactAdvisorRegion | null {
  if (!staff) return null;
  return {
    firstName: staff.Staff.firstName,
    lastName: staff.Staff.lastName,
    bio: staff.Staff.bio || '',
    staffId: staff.Staff.staffID,
    street: staff.Staff.street,
    city: staff.Staff.city,
    state: staff.Staff.state,
    zipCode: staff.Staff.zipCode,
    country: staff.Staff.country,
    email: staff.Staff.email,
    primaryPhone: staff.Staff.primaryPhone,
    secondaryPhone: staff.Staff.secondaryPhone,
    staffType: staff.staffType,
    advisorRegionId: staff.AdvisorRegionId,
    roleId: staff.roleId,
    active: staff.active,
    regionName: staff.Region.regionName,
    chapterId: staff.Chapter ? staff.Chapter.chapterId : null,
    chapterName: staff.Chapter ? staff.Chapter.chapterName : null,
    chapter: staff.Chapter || null,
    fullName: staff.Staff.fullName,
    birthDate: staff.Staff.birthDate || null,
    gender: staff.Staff && staff.Staff.gender,
    eventStaff: staff.Staff && staff.Staff.EventStaff,
    MetaData: staff.MetaData || [],
    permissions: staff.Permissions,
    addressObjectFormat: {
      street: staff.Staff.street,
      city: staff.Staff.city,
      state: staff.Staff.state,
      zipCode: staff.Staff.zipCode,
      country: staff.Staff.country
    },
    glaubachFellows: staff.Staff.GlaubachFellows,
    original: staff
  };
}

type StaffProps = Pick<Props, 'staff' | 'loading'>;

const getSingleStaff = wrapComponent<Props, StaffProps>((props) => {
  const { loading, result } = useStaffQuery(
    computed(() => ({ staffID: +props.staffId, regionId: props.regionId }))
  );

  return computed(() => ({
    loading: loading.value,
    staff: result.value?.singleStaff ? staffViewModel(result.value.singleStaff) : null
  }));
});

export const enhancer = compose(getSingleStaff, acceptProps(['currentStaffId']), withRouter);

export default enhancer(Single);
