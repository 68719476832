

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import UISelect from 'shared/ui/forms/FancySelect';
import { getCurrentSeason } from 'shared/util';
import { seasonsByRange } from './util';

@Component({
  components: {
    UISelect
  }
})
export default class SeasonPicker extends Vue {
  @Prop() value!: string;
  @Prop({ default: 5 }) rangeMin!: number;
  @Prop({ default: 2 }) rangeMax!: number;

  get seasons() {
    return seasonsByRange(this.rangeMin, this.rangeMax)
  }
}
