
import Event from '../../shared/Event.vue'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { RouterLink } from 'vue-component-router';
import { Day } from 'shared/components/Calendar';
import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import { getLocalStartDate, splitEventsByWeek, placeEvents, EventWithStartAndEnd, normalizedDate, eventOnDate } from '../../shared/util';
import Popover from 'shared/ui/popover/Popover.vue';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { library } from '@fortawesome/fontawesome-svg-core';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Loading from 'shared/components/Lock-ui.vue'
import { GetEventsQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type IEvent = ArrayElement<GetEventsQuery['events']>

library.add(faAngleDown)

const weekDays = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thurs',
  'Fri',
  'Sat'
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

@Component({
  components: {
    Event,
    Popover,
    RouterLink,
    ScrollablePanel,
    Loading
  },
  methods: {
    getLocalStartDate,
  }
})
export default class MonthView extends Vue {
  @Prop({type: Date}) date!: Date
  @Prop() weeks!: Day[][]
  @Prop() events!: IEvent[]
  @Prop() loading!: boolean;

  created () {
    this.weekDays = weekDays;
  }

  get todayPosition () {
    if (!this.today) return

    const rs = (this.today.week) * 5 + 1;
    const cs = this.date.getDay() + 1;

    return `${rs} / ${cs} / ${rs + 5} / ${cs + 1}`;
  }

  get today () {
    const pad = (n: number) => n < 10 ? `0${n}` : n

    const today = `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1)}-${pad(new Date().getDate())}`;

    return find(flatMap(
      this.weeks.map((x, i) => [...x.map(x => ({ ...x, week: i }))])
    ), x => x.date === today)
  }

  get month () {
    return months[this.date.getMonth()]
  }

  get year () {
    return this.date.getFullYear();
  }

  get previousMonth () {
    return new Date(this.year, this.date.getMonth() - 1, 1);
  }

  get nextMonth () {
    return new Date(this.year, this.date.getMonth() + 1, 1);
  }

  eventsByWeek: ReadonlyArray<[EventWithStartAndEnd[], EventWithStartAndEnd[], EventWithStartAndEnd[], number[]]> = []

  @Watch('events', {
    immediate: true
  })
  setEventsByWeek () {
    this.eventsByWeek = []

    const events = [...this.events].sort((a,b) => {
      if (a.startDate < b.startDate) return 1

      if (b.startDate < a.startDate) return -1

      return 0
    })

    requestAnimationFrame(() => {
      this.eventsByWeek = Object.freeze(splitEventsByWeek(events, this.weeks)
      .map((events, week) => placeEvents(events, this.weeks[week])));
    })
  }
  headerToday(day: number) {
    if (!this.today) return false

    const rs = (this.today.week) * 5 + 1;
    const cs = this.date.getDay();

    if ((rs === 1) && (day === cs)) {
      return true
    } else {
      return false
    }

  }
  dayLabelPosition(day: Day, week: Day[], weekIndex: number) {
    const rs = weekIndex * 5 + 1;
    const cs = week.indexOf(day) + 1;
    const re = rs + 1;
    const ce = cs + 1;

    return `${rs} / ${cs} / ${re} / ${ce}`
  }

  getMoreEventPosition(day: number, week: Day[], weekIndex: number) {
    const rs = weekIndex * 5 + 5;
    const cs = week.findIndex(x => x.day === day) + 1;
    const re = rs + 1;
    const ce = cs + 1;

    return `${rs} / ${cs} / ${re} / ${ce}`
  }

  getEventPosition(event: EventWithStartAndEnd, weekIndex: number, row: number) {
    const rs = weekIndex * 5 + 2 + row;
    const cs = event.startDoW + 1;
    const re = rs + 1;
    const ce = cs + (event.endDoW - event.startDoW) + 1;

    return `${rs} / ${cs} / ${re} / ${ce}`
  }

  selectDay(day: Day) {
    const daySelected = new Date(day.date);
    this.$emit('selectedDay', {
      year: daySelected.getUTCFullYear(),
      month: daySelected.getUTCMonth() + 1,
      day: daySelected.getUTCDate()
    });
  }

  dayClass (day: Day) {
    if (!day.thisMonth || !this.today) return 'not-this-month';

    if (day.date === this.today.date) {
      return 'today'
    }
  }

  eventsOnDay (dayNumber: number, week: Day[], except?: IEvent[]) {
    const day = find(week, x => x.day === dayNumber);

    if (day === undefined) return [];
    return this.events.filter(x => {
      return eventOnDate(x, day.date) && (except && !find(except, e => (e.eventId === x.eventId)))
    });
  }

  isMultiday(event: EventWithStartAndEnd) {
    const start = normalizedDate(event.startDate, event.TimeZone).split('-')[2]
    const end = normalizedDate(event.endDate, event.TimeZone).split('-')[2]

    return start !== end
  }

  formatHour (time: Date) {
      var date = new Date(time);

      let hours = date.getHours();
      let min = date.getMinutes();
      let ampm = hours > 11 ? 'pm' : 'am';
      let newMin : String;

      hours = hours > 12 ? hours - 12 : hours;

      if (min > 0) {
        if (min < 10) {
          newMin = ':0' + min
        } else {
          newMin = ':' + min
        }
      } else {
        newMin = ''
      }

      let eventTime = hours + '' + newMin + '' + ampm;

      return eventTime
    }
}
