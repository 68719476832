

import BorderedList from 'shared/ui/lists/BorderedList.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import Avatar from 'shared/components/avatar';
import { getCurrentSeason } from 'shared/util';
import AutoSaveField from 'shared/components/AutoSaveField';
import PostHighSchoolLookup from 'shared/components/PostHighSchoolLookup';
import range from 'lodash/range';
import TeenUpdateArgs from './shared/TeenUpdateArgs';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import CompactTeen from '../../shared/CompactTeen';
import { validateNumber } from 'shared/util';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { library } from '@fortawesome/fontawesome-svg-core';
import { UpdateTeenField } from 'shared/generated/graphql-types';

library.add(faCheck)

@Component({
  components: {
    PostHighSchoolLookup,
    AutoSaveField,
    BorderedList,
    Avatar,
    UISelect
  },
  methods: {
    validateNumber
  }
})
export default class PostHS extends Vue {
  @Prop() teen!: CompactTeen;
  @Prop() updatingTeen!: boolean;
  @Prop() selectedTeen!: number;
  @Prop() updateTeen!: (args: TeenUpdateArgs) => Promise<void>;

  teenCopy: any = Object.assign({}, this.teen);
  updateTeenField = UpdateTeenField;
  get calendarYears() {
      const currentSeason = Number(getCurrentSeason().split('-')[0]);
      const yearsRange = range(2007, currentSeason + 6).reverse();
      const currentYear = new Date().getFullYear();
      return [currentYear, ...yearsRange.filter(x => x > currentYear), ...yearsRange.filter(x => x < currentYear)]
  }
  async update (args: SaveEventArgs<{ fieldName: UpdateTeenField, value: string }>) {
    await this.updateTeen({
        personId: this.teen.personId,
        fieldName: args.transformed.fieldName,
        value: args.transformed.value
    });
  }
  getGenderColor (gender: number) {
		if (!gender) {
			return '#00bcd4'
		} else {
			return '#cd8ece'
		}
  }
}
