

import BorderedList from 'shared/ui/lists/BorderedList.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import Avatar from 'shared/components/avatar';
import AutoSaveField from 'shared/components/AutoSaveField';
import TeenUpdateArgs from './shared/TeenUpdateArgs';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import CompactTeen from '../../shared/CompactTeen';
import { validateNumber } from 'shared/util';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { library } from '@fortawesome/fontawesome-svg-core';
import SchoolLookup from 'shared/components/SchoolLookup';
import { UpdateTeenField } from 'shared/generated/graphql-types';

library.add(faCheck)

@Component({
  components: {
    SchoolLookup,
    AutoSaveField,
    BorderedList,
    Avatar,
    UISelect
  },
  methods: {
    validateNumber
  }
})
export default class MissingSchools extends Vue {
  @Prop() teen!: CompactTeen;
  @Prop() updatingTeen!: boolean;
  @Prop() selectedTeen!: number;
  @Prop() updateTeen!: (args: TeenUpdateArgs) => void;

  teenCopy: any = Object.assign({}, this.teen);
  updateTeenField = UpdateTeenField;

  async update (args: SaveEventArgs<{ fieldName: UpdateTeenField, value: string }>) {
    this.updateTeen({
        personId: this.teen.personId,
        fieldName: args.transformed.fieldName,
        value: args.transformed.value
    });
  }
  getGenderColor (gender: number) {
		if (!gender) {
			return '#00bcd4'
		} else {
			return '#cd8ece'
		}
  }
}
