
import Vue from 'vue';
import UITable from 'shared/ui/tables/Tables.vue';
import SlottedTable from 'shared/ui/tables/SlotedTable.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import Loading from 'shared/components/Loading.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import ServerSideFilters from 'shared/components/ServerSideFilters';
import set from 'lodash/fp/set';
import ActionBar from 'shared/ui/tooltip/ListActions.vue'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { Props, Data, Methods, Teen } from './types';
import InfiniteLoading from 'vue-infinite-loading';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import UIButton from "shared/ui/buttons/Button.vue";
import { TeensForSheetQuery } from 'shared/generated/graphql-types';

library.add(faTimes, faFilter, faCheck);

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'RequestSheet',
  components: {
    UITable,
    UIButton,
    UISwitch,
    UISearch,
    Modal,
    SlottedTable,
    ActionBar,
    Loading,
    ScrollablePanel,
    ServerSideFilters,
    InfiniteLoading
  },
  props: {
    title: {},
    capacity: {},
    regionId: {},
    isLoading: {},
    fetchMore: {},
    teens: {},
    teenIds: {},
    markTeen: {},
    markingTeen: {},
    activeFilters: {},
    setFilters: {},
    clearAllFilters: {},
    limit: {},
    total: {}
  },
  data() {
    return {
      searchFilter: this.activeFilters['term'] && (this.activeFilters['term'][0].key as string) || '',
      displayFilters: false,
      tableHeaders: [
        'Name',
        'Chapter',
        'School',
        'Attended Events'
      ],
      marking: this.getMarkingTeens()
    }
  },
  methods: {
    close() {
      this.$emit('cancel', false);
    },
    setSearchFilter(term: string) {
      this.setFilters(set('term', [{
        key: term
      }], this.activeFilters))
    },
    getMarkingTeens() {
      return (this.teenIds || []).reduce((marking, teenId) => {
        return {
          ...marking,
          [teenId]: true
        }
      }, {});
    },
    isTeenMarking(personId) {
      return this.marking[personId]
    },
    async markTeenHandler(personId) {
      this.$set(this.marking, personId, true);
      try {
        await this.markTeen(personId);
      } catch (e) {
        console.log('error', e)
        this.$set(this.marking, personId, false);
        this.$emit('addingTeenFailed', true);
      }
    },
    alreadyMarked(personId) {
      return !!(this.teenIds || []).find(teenId => teenId === personId)
    },
    async fetchMoreHandler($state) {
      await this.fetchMore({limit: this.limit, offset: (this.teens || []).length}, $state);
      $state.reset();
      $state.loaded();
    },
    mapResults: (data: TeensForSheetQuery['teens']['teens']): Teen[] => (data || []).map(t => ({
      personId: t.personID,
      thumbnail: t.thumbnail,
      firstName: t.Person.firstName || '',
      lastName: t.Person.lastName || '',
      gender: t.Person.gender,
      chapterName: (t.Chapter || { chapterName: '' }).chapterName || '',
      schoolName: (t.School || { name: ''}).name || '',
      eventCount: t.statistics && t.statistics[1].value || 0
    }))
  }
});
