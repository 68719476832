
import Vue from 'vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import Sidebar from './components/Sidebar.vue';
import BusDisplay from './components/Bus.vue';
import AddBusForm from './components/AddBusForm.vue'
import { Props, Computed, Methods, defaultStats, Bus } from './types';
import PotentialPassenger from './shared/PotentialPassenger';
import TeenProfile from 'shared/components/TeenProfile';
import StaffProfile from 'shared/components/StaffProfile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { StatusType } from 'shared/generated/graphql-types';

library.add(faIndent, faOutdent, faSlidersH, faPlusCircle, faFilter)

export default Vue.extend<{}, Methods, Computed, Props>({
	name: 'Busing',
	components: {
		PanelsGrid,
		AddBusForm,
    ScrollablePanel,
    StaffProfile,
    TeenProfile,
		Loading,
		Modal,
		BusDisplay,
		Sidebar
	},
	directives: {
		fullheight
	},
  props: {
    addingNewBus: {},
    loadingBuses: {},
    buses: {},
    event: {},
    eventId: {},
    editBus: {},
    createBus: {},
    deleteBus: {},
    direction: {},
    capacity: {},
    term: {},
    addToBus: {},
    removeFromBus: {}
  },
  data () {
    return {
      selectedPassenger: null,
      showProfile: true
    }
  },
  computed: {
    showTeenProfile () {
      return !!this.selectedPassenger && !!this.selectedPassenger.teenId
    },
    showStaffProfile () {
      return !!this.selectedPassenger && !!this.selectedPassenger.staffId
    },
    potentialPassengers() {
      if(!this.event) {
        return [];
      }
      return [
        ...(this.event && this.event.Registrations || []).map(r => new PotentialPassenger(r, "registration")),
        ...(this.event.EventStaff || []).map(s => new PotentialPassenger(s, "eventstaff")),
        ...(this.event.EventGuests || []).map(g => new PotentialPassenger(g, "eventguests"))
      ]
    },
    filteredBuses() {
      if(!this.buses) return [];
      const result = this.buses.filter(b =>
        b.description.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0 ||
        b.meetingAddress.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0
      );

      let directionPredicate: (b: Bus) => boolean;
      switch(this.direction){
        case 'R':
        case 'T':
        case 'B':
          directionPredicate = b => b.directionFlag === this.direction;
          break;
        default:
          directionPredicate = b => true;
          break;
      }

      let capacityPredicate: (b: Bus) => boolean;
      switch(this.capacity) {
        case 'full':
          capacityPredicate = b => b.passengerCount === b.capacity;
          break;
        case 'partial':
          capacityPredicate = b => b.passengerCount > 0 && b.passengerCount < (b.capacity || 0);
          break;
        case 'empty':
          capacityPredicate = b => b.passengerCount === 0;
          break;
        default:
          capacityPredicate = b => true;
          break;
      }
      return result.filter(directionPredicate).filter(capacityPredicate);
    },
    teensRegistered(){
      return this.event && this.event.Registrations.filter(r => r.status !== StatusType.Cancelled).length || 0;
    },
    guestsRegistered(){
      return this.event && this.event.EventGuests.length || 0;
    },
    staffRegistered(){
      return this.event && this.event.EventStaff.length || 0;
    },
    totalWithBuses(){
      return this.buses.map(b => b.passengerCount).reduce((s, a) => s + a, 0);
    },
    stats() {
      if(!this.event) {
        return defaultStats;
      }
      return {
        teensRegistered: this.teensRegistered,
        guestsRegistered: this.guestsRegistered,
        staffRegistered: this.staffRegistered,
        totalWithBuses: this.totalWithBuses,
        registrationsNeedBusToEvent: this.event!.Registrations.filter(r => r.ToBus === null).length,
        staffNeedBusToEvent: this.event!.EventStaff.filter(s => s.ToBus === null).length,
        guestsNeedBusToEvent: this.event!.EventGuests.filter(g => g.ToBus === null).length,
        registrationsNeedBusFromEvent: this.event!.Registrations.filter(r => r.FromBus === null).length,
        staffNeedBusFromEvent: this.event!.EventStaff.filter(s => s.FromBus === null).length,
        guestsNeedBusFromEvent: this.event!.EventGuests.filter(g => g.FromBus === null).length,
        totalBuses: (this.buses || []).length
      };
    }
  },
  methods: {
    async handleDelete(busID: number) {
      const busIndex = this.buses.findIndex(b => b.busID === busID);
      this.buses.splice(busIndex, 1);
      await this.deleteBus(busID);
    }
  },
  watch: {
    selectedPassenger () {
      this.showProfile = true;
    }
  },
})
