
import Vue from 'vue'
import Events from "./pages/Events/Events.vue";
import Email from "./pages/Email";
import Notes from "./pages/Notes";
import Profile from "./pages/Profile";
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { Props } from './types'
import Loading from 'shared/components/Loading.vue';

export default Vue.extend<{}, {}, {}, Props>({
  name: 'Single',
  components: {
    Events,
    Email,
    Notes,
    Profile,
    ScrollablePanel,
    Loading
  },
  props: {
    staffId: {},
    regionId: {},
    staff: {},
    loading: { type: Boolean },
    selectingStaff: { type: Boolean }
  },
  data () {
    return {
      selectedComponent: 'Profile'
    }
  },
  computed: {
  staffNotFound () {
    return !this.staff && !this.loading && !this.selectingStaff
  },
  loadingData () {
    return this.selectingStaff || this.loading
  }
  }
})
