
import { Vue, Component, Prop } from 'vue-property-decorator';
import SimpleForm, { Error, Field } from 'vue-simpleform';
import ContactParser from 'contact-parser';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UICounter from 'shared/ui/forms/Counter.vue';
import * as RadioGroup from 'shared/radio-group';
import { addressFromHouse } from '../shared/utils';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import UIInput from 'shared/ui/forms/Input.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import AssignableEventStaff from '../../../shared/AssignableEventStaff';
import FancySelect from 'shared/ui/forms/FancySelect';
import isNil from 'lodash/isNil';
import { EditHouseInput, GetHousesForEventQuery, HouseForEventFragment } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>
type Staff = HouseForEventFragment['Staff']

library.add(faTimes)

@Component({
	name: 'EditHouse',
	components: {
		UIButton,
		UICounter,
		PhoneInput,
		UIRadio,
		UITextarea,
		...RadioGroup,
		SimpleForm,
		Error,
		Field,
		UIInput,
    FancySelect
	}
})
export default class extends Vue {
	@Prop()
	editing!: boolean

	@Prop()
	editHouse!: (eventId: number, house: EditHouseInput, originalHouse: House) => void;

	@Prop()
	house!: House;

	@Prop()
	eventId!: number;

  @Prop() eventStaff!: AssignableEventStaff[];

	extendedHouse = { ...this.house, address: addressFromHouse(this.house), assignedStaff: this.staffFromHouse() };

	close () {
		this.$emit('update:editing', false);
	}

  staffFromHouse() {
    return this.house.Staff ? this.eventStaff.find(e => e.eventStaffId === this.house.Staff!.eventStaffId) : null;
  }

  staffToHouse(staff: AssignableEventStaff): Staff | null {
    return isNil(staff) ? null : {
      __typename: 'EventStaff',
      eventStaffId: staff.eventStaffId,
      Staff: {
        __typename: 'Staff',
        staffID: staff.staffId,
        fullName: staff.fullName,
        gender: staff.gender
      }
    };
  }

  isStaffOnThisHouse(staff: AssignableEventStaff) {
    return !!!staff.currentHouses.find(h => h === this.house.houseId);
  }

	validate (value: {[key: string]:any}) {
		const errors: {[key:string]: string} = {};

		if (!value.address) {
			errors.address = 'Address is a required field';
		}
		return errors;
	}

	edit ({ values, errors }:any) {
		if (errors) return;
		this.close();

		const parser = new ContactParser();
		const splitAddress = parser.parse(values.address);
		if (splitAddress.city && splitAddress.city.trim().indexOf(' ') > 0) {
			const [city, province] = splitAddress.city.split(' ');
			if (city.toLowerCase() !== 'new') {
				splitAddress.city = city;
				splitAddress.province = province;
			}
		}

    const input = {
			houseId: this.house.houseId,
			street: splitAddress.address || splitAddress.name,
			street2: splitAddress.address ? splitAddress.name : null,
			city: splitAddress.city,
			state: (splitAddress.province || '').substring(0, 2),
			zipCode: splitAddress.postal,
			description: values.description || '',
			gender: !values.gender ? null : values.gender,
			phone: values.phone,
			capacity: +(values.capacity || 0),
      staffId: values.assignedStaff ? values.assignedStaff.staffId : null
		};

		this.editHouse(this.eventId, input, {...this.house, Staff: this.staffToHouse(values.assignedStaff)});
	}
}
