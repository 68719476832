

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';

@Component({
  components: {
    EventSubTypeLookup
  }
})
export default class eventSubTypeIdPicker extends Vue {
  @Prop() value!: number;
}
