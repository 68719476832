import StaffPopup from './StaffPopup.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { RegionsMap, Staff } from '../../types';
import { withRouter } from 'vue-component-router';
import { wrapComponent } from 'shared/apollo-hoc';
import { useAddStaffToRegionMutation } from 'shared/generated/graphql-types';

type AddStaffToRegionProps = Pick<Props, 'addStaffToRegion' | 'addingStaff'>;

const addStaffToRegionEnhancer = wrapComponent<Props, AddStaffToRegionProps>((props) => {
  const { loading, mutate } = useAddStaffToRegionMutation();

  return computed(() => ({
    addingStaff: loading.value,
    addStaffToRegion: ({ regionId, staffId, sourceRegionId }) =>
      mutate(
        { regionId, staffId, sourceRegionId },
        {
          optimisticResponse: {
            addStaffToRegion: {
              ...(props.duplicate.original as Staff),
              AdvisorRegions: [
                ...(props.duplicate.original as Staff).AdvisorRegions,
                {
                  __typename: 'AdvisorRegion',
                  AdvisorRegionId: -1,
                  dateUpdated: null,
                  dateCreated: null,
                  active: true,
                  regionId,
                  Region: {
                    __typename: 'Region',
                    regionId,
                    regionName: RegionsMap[regionId]
                  }
                }
              ]
            }
          }
        }
      )
  }));
});

export default compose(addStaffToRegionEnhancer, withRouter)(StaffPopup);
