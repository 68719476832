
import { RouterLink } from 'vue-component-router';
import { Vue, Prop, Component } from "vue-property-decorator";
import Loading from 'shared/components/Loading.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import FetchMoreOptions from '../shared/FetchMoreOptions';
import { CompactCommunityMember as CommunityMember } from '../shared/types';
import ActiveFilters from '../shared/ActiveFilters';
import Avatar from 'shared/components/avatar';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';

@Component({
	name: 'CommunityMemberList',
	components: {
    BorderedList,
    InfiniteLoading,
		Loading,
		RouterLink,
		Avatar
	}
})
export default class extends Vue {
	@Prop() regionId!: number;
	@Prop() communityMembers!: CommunityMember[];
	@Prop() fetchMore!: (args: FetchMoreOptions, stateChanger: StateChanger) => void;
	@Prop() activeFilters!: ActiveFilters;
  @Prop() selectedCommunityMember!: number;
  @Prop() checkedCommunityMembers!: number[];

	limit: number = 60;
  busy: boolean = false
  localCheckedCommunityMembers = this.checkedCommunityMembers;

	async fetchMoreHandler ($state: StateChanger) {
      await this.fetchMore({ limit: this.limit, offset: this.communityMembers.length }, $state);
      $state.reset()
      $state.loaded()
	}

	formatName({ firstName, lastName }: CommunityMember) {
		return `${firstName} ${lastName}`
	}

	getGenderColor (gender: number) {
		if (!gender) {
			return '#00bcd4'
		} else {
			return '#cd8ece'
		}
	}

}
