
import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import { RouterLink } from 'vue-component-router';
import ChapterLookup from 'shared/components/ChapterLookup';
import StaffLookup from 'shared/components/StaffLookup';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import fullheight from 'shared/directives/fullheight';
import FormSection from 'shared/ui/forms/FormSection.vue';
import { Errors } from 'types/helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { TeenListFormObject, Data, Methods, Props } from './types';

library.add(faTimes);

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddNewList',
  components: {
    SimpleForm,
    Field,
    Error,
    RouterLink,
    ChapterLookup,
    StaffLookup,
    UIButton,
    UIInput,
    FormSection,
  },
  directives: {
    fullheight
  },
  props: {
    createTeenList: {},
    regionId: {},
    filters: {},
    limit: {},
    currentPage: {},
    me: {}
  },
  data() {
    return {
      formObject: {
        title: null,
        chapter: null,
        staff: this.me && {
          firstName: this.me.firstName,
          lastName: this.me.lastName,
          fullName: this.me.fullName,
          staffID: this.me.staffID,
          __typename: 'Staff'
        } || null,
      }
    }
  },
  methods: {
    validate(values) {
      const errors: Errors<TeenListFormObject> = {};

      if (!values.title) {
        errors.title = 'Please enter list name';
      }

      return errors;
    },
    async addNewTeenListHandler(args) {
      if ('errors' in args) return;

      if (args.setSubmitting) args.setSubmitting();
      await this.createTeenList(args);
      if (args.setSubmitted) args.setSubmitted();
      this.$emit('requestClose');
    }
  }
});
