import { render, staticRenderFns } from "./RegistrationAndTickets.vue?vue&type=template&id=47a88c2b&scoped=true&"
import script from "./RegistrationAndTickets.vue?vue&type=script&lang=ts&"
export * from "./RegistrationAndTickets.vue?vue&type=script&lang=ts&"
import style0 from "./RegistrationAndTickets.vue?vue&type=style&index=0&id=47a88c2b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a88c2b",
  null
  
)

export default component.exports