import { computed } from 'vue';
import { compose, withData, withProps } from 'vue-compose';
import ParentProfileComponent from './ParentProfile.vue';
import {
  createAddressEnhancer,
  updateEmailEnhancer,
  createEmailEnhancer,
  updatePhoneEnhancer,
  createPhoneEnhancer,
  updateAddressEnhancer,
  deletePhoneEnhancer
} from 'shared/enhancers/parentsEnhancer';
import { toCompactParent } from './utils';
import { FamilyChild, Props } from './types';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  Gender,
  MaritalStatus,
  ParentProfileFragment,
  ParentProfileFragmentDoc,
  useConnectTeenToParentMutation,
  useGetParentProfileQuery,
  useMarkParentConsentMutation
} from 'shared/generated/graphql-types';
import { currentUserEnhancer } from 'shared/enhancers/currentUserEnhancer';
import { changePasswordEnhancer } from 'shared/enhancers/changePasswordEnhancer';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const markParentConsentEnhancer = wrapComponent<Props, Pick<Props, 'markParentConsent'>>(() => {
  const { loading, mutate } = useMarkParentConsentMutation();

  return computed(() => ({
    markParentConsent: (parentId, data) => mutate({ data, parentId }),
    markingParentConsent: loading.value
  }));
});

type ParentProfileProps = Pick<Props, 'parent'> & {
  loadingParentProfile: boolean;
};
const getParentProfileEnhancer = wrapComponent<Props, ParentProfileProps>((props) => {
  const { loading, result } = useGetParentProfileQuery({
    personID: props.personID
  });

  return computed(() => ({
    parent: result.value?.singleParent ? toCompactParent(result.value.singleParent) : undefined,
    loadingParentProfile: loading.value
  }));
});

const connectTeenToParentEnhancer = wrapComponent<Props, Pick<Props, 'connectTeenToParent'>>(
  (props) => {
    const { mutate } = useConnectTeenToParentMutation();

    return computed(() => ({
      connectTeenToParent: ({ childId, parentId }, child) => {
        const childWithAllFields: FamilyChild = {
          firstName: child.firstName,
          lastName: child.lastName,
          personID: childId,
          dataOptOut: null,
          mediaConsentSigned: null,
          dataConsentSigned: null,
          origin: null,
          olamiId: null,
          teudatZehut: null,
          aliyahDate: null,
          Addresses: [],
          EmailAddresses: [],
          Phones: [],
          primaryFamilyId: -1,
          __typename: 'Person',
          gender: child.gender === Gender.Male ? 0 : 1
        };
        return mutate(
          {
            parentId,
            childId
          },
          {
            optimisticResponse: {
              connectTeenToParent: {
                __typename: 'Family',
                id: -1,
                status: MaritalStatus.Unknown,
                familyName: '',
                Father: null,
                Mother: null,
                Children: [childWithAllFields]
              }
            },
            update(client, { data }) {
              const parentProfileData = client.readFragment<ParentProfileFragment>({
                id: `${parentId}Person`,
                fragment: ParentProfileFragmentDoc,
                fragmentName: 'ParentProfile'
              });

              if (parentProfileData) {
                const children: ParentProfileFragment['Children'] = [];
                if (
                  !parentProfileData.Children ||
                  !parentProfileData.Children.length ||
                  !parentProfileData.Children.find((c) => c.personID === childId)
                ) {
                  children.push(childWithAllFields);
                }
                client.writeFragment<ParentProfileFragment>({
                  id: `${parentId}Person`,
                  fragment: ParentProfileFragmentDoc,
                  fragmentName: 'ParentProfile',
                  data: {
                    ...parentProfileData,
                    Children: children
                  }
                });
              }
            }
          }
        );
      }
    }));
  }
);

export const enhancer = compose(
  withData({
    showPasswordModal: {
      initialValue: false
    }
  }),
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  getParentProfileEnhancer,
  changePasswordEnhancer,
  updateEmailEnhancer,
  createEmailEnhancer,
  updatePhoneEnhancer,
  updateAddressEnhancer,
  createAddressEnhancer,
  createPhoneEnhancer,
  connectTeenToParentEnhancer,
  currentUserEnhancer,
  markParentConsentEnhancer,
  deletePhoneEnhancer
);

export default enhancer(ParentProfileComponent);
