
import Vue from 'vue';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import { ErrorBag } from 'shared/util/types';
import UIButton from 'shared/ui/buttons/Button.vue';
import { Data, Methods, Props } from './types';
import ParentLookup from 'shared/components/ParentLookup';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddParentToFamily',
  components: {
    SimpleForm,
    Field,
    Error,
    UIButton,
    ParentLookup
  },
  props: {
    gender: {},
    familyId: {},
    regionId: {},
    addingParentToFamily: {},
    addParentToFamily: {},
  },
  data () {
    return {
      formData: {
        parent: null
      }
    };
  },
  methods: {
    validate (values) {
      let errors: ErrorBag<typeof values> = {};

      if(!values.parent) {
        errors.parent = 'Please select a parent';
      }

      return errors;
    },
    async submitHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      this.$emit('addingParentToFamily', true);
      try {
        await this.addParentToFamily({
          personId: args.values.parent!.personId,
          familyId: this.familyId,
          gender: this.gender
        });
      } catch (e) {
        
      }
      this.$emit('addingParentToFamily', false);
      if (args.setSubmitted) args.setSubmitted();
    }
  }
})
