<template>
  <table :class="`${className}`">
      <slot name="thead"></slot>
      <slot name="tbody">
    </slot>
  </table>
</template>
<script>
export default {
  props: {
    className: {
      type: String
	},
	headerData: Array,
	data: Array
  },
	computed: {
		rowData () {
			return this.data || [
				['Black', '#000000'],
				['White', '#ffffff']
			];
		},
		rowHeaderData () {
			return this.headerData || [
				'Name', 'Value'
			];
		}
	}
};
</script>

<style scoped>
  th {
  	color: #999999;
  	margin-bottom: 20px;
  	font-weight: 600;
  	font-size: 13px;
    cursor: pointer;
    border-bottom:2px solid #f9f9f9;
  }
  td {
    border-bottom:2px solid #f9f9f9;
  }
  td, th {
    padding: 10px 15px;
  }
  .table--firstHighlight td {
    color: #999;
    font-size: 12px;
  }
  .table--firstHighlight td:first-child {
    font-size: 13px;
    color: #000;
  }
</style>
