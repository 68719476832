
import Vue from "vue";
import Loading from 'shared/components/Loading.vue';
import { Data, Props, Methods } from './types';
import { RouterLink } from 'vue-component-router';
import AddJFEOrderPayment from './components/AddJFEOrderPayment';
import EditJFEOrderPayment from "./components/EditJFEOrderPayment";
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { library } from '@fortawesome/fontawesome-svg-core';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from "shared/ui/buttons/Button.vue";

library.add(faPencil, faTrash);

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'JFESingleOrder',
  components: {
    Loading,
    RouterLink,
    AddJFEOrderPayment,
    Modal,
    UIButton,
    EditJFEOrderPayment
  },
  props: {
    order: {},
    loading: {},
    orderId: {},
    client: {},
    setParentId: {},
    setTeenId: {},
    deletingPayment: {},
    deletePayment: {}
  },
  data() {
    return {
      showAddPayment: false,
      showDeletePaymentModal: false,
      showEditPaymentModal: false,
      selectedPayment: null
    }
  },
  methods: {
    viewParentProfile(parentId) {
      this.setParentId(parentId);
    },
    viewTeenProfile(teenId) {
      this.setTeenId(teenId);
    }
  }
});
