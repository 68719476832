
import Vue from 'vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { ReportFields } from './types';
import FileSaver from 'file-saver';
import { library } from '@fortawesome/fontawesome-svg-core';
import ReportForm from 'shared/components/ReportForm';
import pick from 'lodash/pick';
import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons/faFileExcel';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons/faFileCsv';
import { faCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import { Props } from './types'
import { b64toBlob } from 'shared/util';
import Modal from 'shared/ui/modal/Modal.vue';
import { Parameter, ReportFormat } from 'shared/generated/graphql-types';


interface Data {
    fileType: ReportFormat,
    formIsDirty: boolean,
    showCtrl: boolean,
    newReport: ReportFields
}

library.add(faChevronDown,faChevronUp,faFileExcel,faDownload,faFileWord,faHistory,faFilePdf,faFileCsv,faFileAlt,faCamera,faStar)

function fileExtension(fileType: ReportFormat) {
    return {
        [ReportFormat.Excel]: 'xlsx',
        [ReportFormat.Word]: 'docx',
        [ReportFormat.Pdf]: 'pdf',
        [ReportFormat.Csv]: 'csv',
        [ReportFormat.Text]: 'csv',
    }[fileType]
}

export default Vue.extend<Data, {}, {}, Props>({
    name: 'ReportItem',
    props: {
        report: {},
        starred: {},
        toggleStar: {},
        generateReport: {},
        generating: {},
        regionId: {},
        staffId: {},
        me: {}
    },
    components: {
        UIButton,
        UIInput,
        ReportForm,
        Modal
    },
    data () {
        return {
            showErrorModal: false,
            fileType: ReportFormat.Excel,
            selectedEvent: null,
            formIsDirty: false,
            showCtrl: false,
            newReport: this.initialReport(),
            title: this.report.name
        }
    },
    computed: {
        error () {
            if (this.report.parameters.length === 0 || !this.formIsDirty) return false;
            let filledFields = Object.keys(omitBy(this.newReport, isNil)).filter( x => this.report.parameters.includes(x))
            return !(Object.keys(filledFields).length === this.report.parameters.length);
        },
        currentAdvisorRegionId () {
          return this.me && this.regionId &&
            this.me.AdvisorRegions.find(a => a.Region.regionId === this.regionId)!.AdvisorRegionId
        }
    },
    methods: {
        capitalize,
        camelCase,
        initialReport () {
            return Object.assign(Object.values(Parameter).reduce((parameters, key) => ({...parameters, [key]: null}), {}), { regionID: this.regionId, SuperPersonID: this.staffId })
        },
        async generateHandler() {
            try {
              this.formIsDirty = true;
              if (this.error) return

              const parameters = pick(this.newReport, this.report.parameters)

              const { id: reportName } = this.report;

              const { data: { generateReport: b64 } } = await this.generateReport({
                  Parameters: Object.entries(parameters).map(([parameter, value]) => {
                    return {
                      parameter: parameter as Parameter,
                      value: value ? String(value) : value
                    }
                  }),
                  ReportFormat: this.fileType,
                  ReportName: reportName,
                  AdvisorRegionId: this.currentAdvisorRegionId
              });
              FileSaver.saveAs(b64toBlob(b64), `${this.title}.${fileExtension(this.fileType)}`);
            }
            catch (e) {
              this.showErrorModal = true
            }
        },
        sendEmailHandler () {
          this.showErrorModal = false;
          const parameters = pick(this.newReport, this.report.parameters)

          // TODO introduce chatwoot equivelent
          // or some other way of getting this info to us
          // or or, we don't actually care about it
          if (process.env.NODE_ENV === 'production') {
            this.$intercom.showNewMessage(`Error occurred while generating report ( ${this.report.name} - ${JSON.stringify(parameters)} )`)
          }
        }
    }
})
