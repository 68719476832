
import Vue from 'vue'
import UISearch from 'shared/ui/forms/Search.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Pagination from 'shared/ui/pagination/Pagination.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ReportItem from 'shared/components/ReportItem';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Collapsible from 'shared/collapsible';
import sortBy from 'lodash/sortBy';
import Loading from 'shared/components/Loading.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import { faHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons/faFileExcel';
import { faFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons/faFileCsv';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { Report, Props } from './types';
import fullheight from 'shared/directives/fullheight';
import { extractCategories } from './utils';

library.add(faStar, faCamera, faHistory, faDownload, faFileExcel, faFileWord, faFilePdf, faFileCsv, faFileAlt)

export default Vue.extend<{},{},{},Props>({
   name: 'Reports',
   components: {
		ScrollablePanel,
		PanelsSidebar,
		BorderedList,
		Collapsible,
		PanelsGrid,
		Pagination,
		ReportItem,
		UISearch,
      Loading
   },
   directives: {
      fullheight
   },
   props: {
      reports: {},
      isLoading: {},
      regionId: {},
      staffId: {},
      advisorRegionId: {},
      starred: {},
      recent: {},
      toggleStar: {}
   },
   data () {
      return {
         filter: null,
         term: '',
         currentPage: 1,
         limit: 30
      }
   },
   computed: {
      total () {
         return this.filteredReports.length;
      },
      totalPages () {
         return Math.ceil(this.filteredReports.length / this.limit)
      },
      categories () {
         return extractCategories(this.reports)
      },
      filteredReports () {
         const filters: Array<(r: Report) => boolean>  = []

         switch (this.filter) {
            case null:
               break;
            case 'starred':
               filters.push((r) => this.starred.includes(r.id))
               break;
            case 'recent':
               filters.push((r) => this.recent.includes(r.id))
               break;
            default:
               filters.push(({category, subcategory}) => `${category}_${subcategory}` === this.filter)
         }

         if (this.term) {
            this.currentPage = 1;
            filters.push((report) =>`${report.name} ${report.description}`.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0)
         }

         return sortBy(filters.reduce((reports, filter) => reports.filter(filter), this.reports), 'name')
      }
   }
})
