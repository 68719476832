import range from 'lodash/range';
import chunk from 'lodash/chunk';
import last from 'lodash/last';
import Vue, { CreateElement } from 'vue';

interface Props {
	month: number,
	year: number,
	isSixWeeks: boolean,
}

interface Computed {
	date: Date,
	weeks: Day[][]
}

const formatDate = (date: Date) =>
	`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

const totalDays = (year: number, month: number) => new Date(year, month, 0).getDate();

function getLastDate (weeks: Day[][]) {
	const lastWeek = last(weeks);

	if (lastWeek) {
		const lastDay = last(lastWeek);

		if (lastDay) return lastDay.date;
	}
}

export interface Day {
	day: number;
	date: string;
	thisMonth: boolean;
}

export default Vue.extend<{}, {}, Computed, Props>({
	name: 'Calendar',
	props: {
		month: {},
		year: {},
		isSixWeeks: {},
	},
	created () {
		const updateDates = () =>
			this.$emit('update:dates', { startDate: this.weeks[0][0].date, endDate: getLastDate(this.weeks) });

		this.$watch(() => ({
			month: this.month,
			year: this.year
		}), updateDates);

		updateDates();
	},
	computed: {
		date (): Date {
			return new Date(this.year, this.month - 1, 1);
		},
		weeks (): Day[][] {
			const totalDaysThisMonth = totalDays(this.year, this.month);
			const totalDaysLastMonth = totalDays(this.year, this.month - 1);
			const dayThisMonthStarts = this.date.getDay();
			const dayThisMonthEnds = new Date(
				this.year,
				this.month - 1,
				totalDaysThisMonth
			).getDay();

			const daysFromLastMonth = range(
				totalDaysLastMonth - (this.isSixWeeks ? ((dayThisMonthStarts > 0) ? dayThisMonthStarts : 7) : dayThisMonthStarts) + 1,
				totalDaysLastMonth + 1
			).map(x => ({
				day: x,
				date: formatDate(new Date(this.year, this.month - 2, x)),
				thisMonth: false
			}));

			const daysFromThisMonth = range(1, totalDaysThisMonth + 1).map(x => ({
				day: x,
				date: formatDate(new Date(this.year, this.month - 1, x)),
				thisMonth: true
			}));

			const daysFromNextMonth = range(1, (this.isSixWeeks && (dayThisMonthStarts > 0 || (dayThisMonthStarts === 0 && totalDaysThisMonth === 28)) ? 14 : 7) - dayThisMonthEnds).map(x => ({
				day: x,
				date: formatDate(new Date(this.year, this.month, x)),
				thisMonth: false
			}));

			const daysInCalendar = [
				...daysFromLastMonth,
				...daysFromThisMonth,
				...daysFromNextMonth
			];

			return chunk(daysInCalendar, 7);
		}
	},
	render (h: CreateElement) {
		const $vnode = this.$scopedSlots.default && this.$scopedSlots.default({
			weeks: this.weeks,
			date: this.date
		});

		return h('div', $vnode);
	}
});
