<template>
  <div v-if="loading" class="loader-container">{{loadingText}}<span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></div>
  <component v-else :is="tag" v-on="$listeners" v-bind="$attrs"><slot></slot></component>
</template>
<script>
export default {
  name: 'UIButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Please wait'
    },
    tag: {
        type: String,
        default: 'button'
    }
  }
};
</script>

<style lang="scss" scoped>
span,
a {
  line-height: 2
}
  button,
  span,
  a {
    font-size: 12px;
    text-align: center;
    color: white;
    padding: 5px 25px;
    min-height: 37px;
    background-color: #ea7d1c;
    display: inline-block;
    font-weight: 600;
    box-shadow: none;
    transition: .2s;
    cursor: pointer;
    border-radius: 4px;

    &:hover:not([disabled]),
    &:active:not([disabled]),
    &.active:not([disabled]) {
      background-color: #FE9940;
    }

    &.inverted {
      background-color: transparent;
      border: 1px solid #8f94a8;
      color: #8f94a8;
      font-weight: 400;

      &:hover,
      &:active,
      &.active {
        border-color: #222;
        background-color: #333;
        color: white;
      }
    }

    &.full {
      width: 100%;
    }

    &.stripped {
      background: none;
      color: #ea7d1c;
      border: none;

      &:hover,
      &:active,
      &.active {
        background: none;
      }
    }

    &.red {
      background: #fb5353
    }
    &.green {
      background: #8bc34a;
    }
  }
  button:disabled {
    opacity: .4;
  }

@keyframes blink {50% { color: transparent }}
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }
.loader__dot {
  animation: 1s blink infinite;
  font-size: 18px;
  line-height: 22px;
  padding: 0;
  min-height: 24px;
}
.loader-container {
    font-size: 12px;
    text-align: center;
    color: white;
    padding: 5px 25px;
    min-height: 37px;
    background-color: #ea7d1c;
    display: inline-block;
    font-weight: 600;
    box-shadow: none;
    transition: .2s;
    border-radius: 4px;
}
</style>
