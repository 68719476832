
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import AutoSaveField from 'shared/components/AutoSaveField';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import {
    CompactAdvisorRegion
} from '../../../../../shared/CompactAdvisorRegion';
import { UpdateFellowInput } from "shared/generated/graphql-types";

@Component({
    name: "GlaubachFellowItem",
    components: {
        AutoSaveField,
        UITextarea
    }
})

export default class extends Vue {
    @Prop() glaubachFellow!: CompactAdvisorRegion;
    @Prop() updateFellow!: (input: UpdateFellowInput) => void;
    glaubachFellowCopy: CompactAdvisorRegion = Object.assign({}, this.glaubachFellow)

    async update(args: SaveEventArgs) {
      const { id, community }: any = args.transformed;
      await this.updateFellow({id, community})
    }
}
