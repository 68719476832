import { render, staticRenderFns } from "./ScholarshipItem.vue?vue&type=template&id=39677608&scoped=true&"
import script from "./ScholarshipItem.vue?vue&type=script&lang=ts&"
export * from "./ScholarshipItem.vue?vue&type=script&lang=ts&"
import style0 from "./ScholarshipItem.vue?vue&type=style&index=0&id=39677608&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39677608",
  null
  
)

export default component.exports