
import Vue from 'vue'
import FancySelect from 'shared/ui/forms/FancySelect';
import { Props } from './types';

export default Vue.extend<{}, {}, {}, Props>({
  name: 'InteractionTypeLookup',
  components: {
    FancySelect
  },
  props: {
    loading: {},
    disabled: {},
    value: {},
    interactionTypes: {}
  }
})
