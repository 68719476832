
import Vue from 'vue';
import { Notification } from 'store/notifications/notifications'

interface Props {
	removeNotification: () => void
	notification: Notification
}

export default Vue.extend<{}, {}, {}, Props>({
	props: {
		removeNotification: {},
		notification: {}
	},
	created () {
		setTimeout(() => this.removeNotification(), this.notification.duration);
	}
})
