
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Vue, Component, Prop } from 'vue-property-decorator';
library.add(faTrash, faArrows, faPencil);

@Component({
  components: {}
})
export default class Row extends Vue {
    @Prop({ default: '' }) rowContent!: string;
    @Prop({ default: '' }) rowStyle!: string;
    @Prop({ default: '' }) rowType!: string;
};
