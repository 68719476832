
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import FancySelect from 'shared/ui/forms/FancySelect';
import debounce from 'lodash/debounce';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FamilyLookup',
  components: {
    FancySelect
  },
  props: {
    regionId: {},
    families: {},
    term: {},
    familiesLoading: {},
    value: {},
    placeholder: { default: 'Type to search families' }
  },
  data () {
    return {
      searchHandler: debounce((search) => {
        this.$emit('term', search);
      }, 500)
    };
  },
  computed: {
    noOptions () {
      return !!(this.families.length === 0 && this.term);
    }
  },
  methods: {
    optionLabel(family) {
      return family.familyName;
    },
    handleInput (event) {
      this.$emit('input', event);
      this.$emit('blur', event);
    }
  }
});
