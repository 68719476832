import { render, staticRenderFns } from "./Note.vue?vue&type=template&id=799af308&scoped=true&"
import script from "./Note.vue?vue&type=script&lang=ts&"
export * from "./Note.vue?vue&type=script&lang=ts&"
import style0 from "./Note.vue?vue&type=style&index=0&id=799af308&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799af308",
  null
  
)

export default component.exports