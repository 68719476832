
import Vue from'vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import uniqBy from 'lodash/uniqBy'
import { School } from './'

type Data = {
	cachedSchools: Record<number, School>
}

type Props = {
	term: string;
	setTerm: (term: string) => void;
	clearTerm: () => void;
	regionId: number;
	disabled: boolean;
	clearable: boolean;
	schools: School[];
	loading: boolean;
	value: number | number[] | School | School[] | null;
	multiple: boolean;
}

type Computed = {
	valueAsArray: School[];
	schoolOptions: School[];
}

type Methods = {
	handleInput: (value: School[]) => void;
	handleBlur: (value: School[]) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
	name: 'SchoolLookup',
	props: {
		term: {},
		setTerm: {},
		clearTerm: {},
		regionId: {},
		disabled: {},
		clearable: {default: true},
		schools: {default: () => []},
		loading: {},
		value: {},
		multiple: {}
	},
	data() {
		return {
			cachedSchools: {}
		}
	},
	components: {
		FancySelect
	},
	computed: {
		schoolOptions(): School[] {
			return uniqBy(this.schools.concat(Object.keys(this.cachedSchools).map(k => this.cachedSchools[Number(k)])), 'schoolID')
		},
		valueAsArray(): School[] {
			if (!this.value)  return []

			const value: Array<number | School> = Array.isArray(this.value) ? this.value : [this.value]

			return value.map(school => typeof school === 'object'
				? school
				: this.schoolOptions.find(x => x.schoolID === school)!
			).filter(v => v)
		}
	},
	methods: {
		handleInput(value: School[]) {
			if (Array.isArray(value)) {
				value.forEach(s => {
					this.cachedSchools[s.schoolID] = s
				})
			}
			this.$emit('input', value)
		},
		handleBlur(value: School[]) {
			this.$emit('blur', value)
		}
	}
});
