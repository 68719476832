
import Vue from 'vue';
import { Props } from './types';
import EventTrackLookup from 'shared/components/EventTrackLookup';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import UISelect from 'shared/ui/forms/FancySelect';

library.add(faTimes);

interface Methods {
  convertEmails: (v: string | string[]) => string;
  isHasErrors: (key: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, Props & { regionId: number }>({
  name: "Options",
  components: {
    EventTrackLookup,
    UICheckbox,
    UIInput,
    UISelect
  },
  props: {
    getData: {},
    displayErrors: {},
    setData: {},
    step: {},
    steps: {},
    regionId: Number
  },
  methods: {
    convertEmails: function(v: string | string[]) {
      if (v instanceof Array) {
        v = v.join(",");
      }
      return v
        .split(",")
        .filter(i => i)
        .map(i => i.trim())
        .join(",");
    },
    isHasErrors(key) {
      return this.step.validations && this.step.validations[key];
    }
  },
  computed: {
    registrationRequired() {
      return this.getData("registrationRequired");
    }
  }
});
