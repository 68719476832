import { render, staticRenderFns } from "./Staff.vue?vue&type=template&id=3f075d9a&scoped=true&"
import script from "./Staff.vue?vue&type=script&lang=ts&"
export * from "./Staff.vue?vue&type=script&lang=ts&"
import style0 from "./Staff.vue?vue&type=style&index=0&id=3f075d9a&prod&scoped=true&lang=css&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f075d9a",
  null
  
)

export default component.exports