
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Lock-ui.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { seasonOptions } from '../shared/seasonHelper';
import UISelect from 'shared/ui/forms/FancySelect';
import UISearch from 'shared/ui/forms/Search.vue';
import List from './components/List.vue';
import { Route, MatchFirst } from 'vue-component-router';
import Single from './components/Single';
import ParentProfile from 'shared/components/ParentProfile';
import TeenProfile from 'shared/components/TeenProfile';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'JFEOrdersListWrapper',
  components: {
    Loading,
    PanelsGrid,
    PanelsSidebar,
    ScrollablePanel,
    UISelect,
    UISearch,
    List,
    Route,
    MatchFirst,
    Single,
    ParentProfile,
    TeenProfile
  },
  directives: {
    fullheight
  },
  data() {
    return {
      profileClose: false,
      seasonOptions: seasonOptions(),
      currentParentId: null,
      currentTeenId: null
    };
  },
  props: {
    initialLoading: {},
    path: {},
    loading: {},
    orders: {},
    activeFilters: {},
    setFilters: {},
    clearFilters: {},
    fetchMore: {},
    currentOrderId: {}
  },
  computed: {
    displaySpinner() {
      return this.loading;
    }
  },
  methods: {
    closeProfile() {
      this.profileClose = !this.profileClose;
    },
    async seasonChange(season: string) {
      await this.setFilters({ season });
    },
    setParentId(parentId) {
      this.currentTeenId = null;
      this.currentParentId = parentId;
    },
    setTeenId(teenId) {
      this.currentParentId = null;
      this.currentTeenId = teenId;
    },
  }
});
