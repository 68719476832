
import DateTimezone from 'date-timezone';
import UIButton from 'shared/ui/buttons/Button.vue';
// TODO switch this out for the newer select wrapper
import FancySelect from 'shared/ui/forms/FancySelect';
import StaffLookup from 'shared/components/StaffLookup';
import InteractionTypeLookup from 'shared/components/InteractionTypeLookup';
import DatePicker from 'shared/components/DatePicker.vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import Vue from 'vue';
import { InteractionFormObject, Data, Props, Methods } from './types';
import { Errors } from 'types/helpers';
import UITextarea from 'shared/ui/forms/Textarea.vue';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'InteractionForm',
  props: {
    currentStaff:{},
    interaction: {},
    personId: {},
    addInteraction: {},
    leadId: {},
    eventId: {},
    eventDate: {},
    eventTimezone: {},
    editInteraction: {}
  },
  components: {
    UIButton,
    FancySelect,
    StaffLookup,
    Field,
    Error,
    DatePicker,
    SimpleForm,
    UITextarea,
    InteractionTypeLookup
  },
  data() {
    let dateObj = new Date();
    if(this.eventDate) {
      DateTimezone.setGlobalTimezone(this.eventTimezone!);
      dateObj = new DateTimezone.DateTimezone(this.eventDate);
    }

    const date = {
      year: dateObj.getFullYear(),
      month: dateObj.getMonth() + 1,
      day: dateObj.getDate()
    }
    return {
      initialValue: this.interaction || { staff: this.currentStaff, date, type: null, note: null, personId: this.personId || null }
    }
  },
  methods: {
    async handleSubmit(callbackArgs: SubmitCallbackArgs<InteractionFormObject>) {
      if('errors' in callbackArgs) return;
      if(!this.interaction) {
        await this.addInteraction(callbackArgs.values);
      }
      else {
        await this.editInteraction(this.interaction, callbackArgs.values);
      }
      this.$emit('submitted', true);
    },
    validate (values: InteractionFormObject) {
      let errors: Errors<InteractionFormObject> = {};

      if (!values.staff) {
        errors['staff'] = "A staff member must be selected";
      }

      if(!values.type) {
        errors['type'] = "A type must be selected";
      }

      if(!values.date) {
        errors['date'] = "A date must be selected";
      }
      return errors;
    }
  }
})
