
import { Vue, Component, Prop } from 'vue-property-decorator';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import Avatar from 'shared/components/avatar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import Truncate from 'shared/components/TextTruncate.vue';
import { ScholarshipFragmentFragment as Scholarship} from 'shared/generated/graphql-types';

library.add(faCheck, faRedo, faTimes)

@Component({
  components: {
    UIInput,
    Avatar,
    UICheckbox,
    UIButton,
    Truncate
  }
})
export default class ScholarshipItem extends Vue {
  @Prop() registration!: Scholarship;
  @Prop() selectedTeen!: number;
  @Prop() historyMode!: boolean;
  @Prop() currencySymbol!: string;

  historyModeOpen: boolean = this.registration.Teen.personID === this.selectedTeen && this.historyMode ? true : false;
  deleting: boolean = false;
  approving: boolean = false;
  scholarshipGrant: number | null = this.registration.scholarshipRequest
  disableNotification: boolean = false;
  disableTeenGrantNotification: boolean = false;
  disableTeenDenyNotification: boolean = false;

  historyModeHnadler() {
    this.historyModeOpen = !this.historyModeOpen;
    this.historyModeOpen ? this.$emit('historyMode', true) : this.$emit('historyMode', false);
  }
  selectTeenHander() {
    this.$emit('selectedTeen', this.registration.Teen.personID);
    this.historyModeOpen ? this.$emit('historyMode', true) : this.$emit('historyMode', false);
  }
  deleteHandler () {
      this.deleting = true;
      this.approving = false;
  }
  approveHandler () {
      this.deleting = false;
      this.approving = true;
  }
  cancel () {
      this.deleting = false;
      this.approving = false;
  }
  showHistoryMode (id: number) {
    return this.selectedTeen === id && this.historyMode
  }
}

