
import Vue from 'vue';
import EmergencyContactItem from './components/EmergencyContact';
import Loading from 'shared/components/Loading.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard';
import { Props } from './types'

library.add(faClipboard)

interface Data {
  addingNewEmergencyContact: boolean
}

export default Vue.extend<Data, {}, {}, Props>({
  name: 'EmergencyContacts',
	components: {
    EmergencyContactItem,
    Loading
	},
  props: {
    fetchingEmergencyContacts: {},
    personId: {},
    emergencyContacts: {}
  },
  data() {
    return {
      addingNewEmergencyContact: false
    }
  }
})
