<template>
	<UISelect
		label="name"
		:value="value"
		@input="$emit('input', $event)"
		:options="schools"
		:loading="loading"
		@search="setTerm"
		:placeholder="placeholder || `Type to Search`"
		:debounce="debounce"
		@blur="$emit('blur', $event)"
	>
		<template #option="option">
			<strong>{{option.name}}</strong>
			<br /> {{option.city}}, {{option.state}}
		</template>
	</UISelect>
</template>
<script>
import Vue from 'vue';
import UISelect from 'shared/ui/forms/FancySelect';

export default Vue.extend({
	name: 'PostHighSchoolLookup',
	props: {
		term: String,
		setTerm: Function,
		clearTerm: Function,
		schools: Array,
		loading: Boolean,
		value: Object,
		type: String,
		debounce: Number,
    placeholder: String
	},
	components: {
		UISelect
	}
});
</script>
