

import { CompactCommunityMember } from './shared/CompactCommunityMember'
import { Vue, Prop, Component } from "vue-property-decorator";
import Summary from "./pages/Summary";
import EmailHistory from "./pages/EmailHistory";
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';

@Component({
  name: "Single",
  components: {
	Summary,
  EmailHistory,
	ScrollablePanel,
	Loading
  },
  directives: {
	fullheight
  }
})

export default class extends Vue {
  @Prop() personId!: string;
  @Prop() regionId!: number;
  @Prop() communityMember!: CompactCommunityMember;
  @Prop() selectingCommunityMember!: boolean;
  @Prop() loading!: boolean;

  selectedComponent: string = 'Summary';

  get communityMemberNotFound () {
    return !this.communityMember && !this.loading && !this.selectingCommunityMember
  }
  get loadingData () {
    return this.selectingCommunityMember || this.loading
  }
}
