import ordinal from 'ordinal-numbers';
import { SimpleDate, SimpleTime } from 'shared/util/types';

export function formatDate(FirstEvent: SimpleDate, LastEvent: SimpleDate) {
  const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  if (FirstEvent && LastEvent) {
    return `${months[FirstEvent.month]} ${ordinal(FirstEvent.day)}, ${FirstEvent.year} -
      ${months[LastEvent.month]} ${ordinal(LastEvent.day)}, ${LastEvent.year}
    `;
  }
}

export function formatTime (StartTime: SimpleTime, EndTime: SimpleTime) {
  if (StartTime && EndTime) {
    return `${StartTime.hours}:${StartTime.minutes} ${StartTime.ampm} - ${EndTime.hours}:${EndTime.minutes} ${EndTime.ampm} `;
  }
}

export function formatDateAndTime(date: SimpleDate, time: SimpleTime) {
  const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (date && time) {
    return `${months[date.month]} ${ordinal(date.day)}, ${date.year} ${time.hours}:${time.minutes} ${time.ampm}`;
  }
}

export function isEventTYA(props: { getData: (step: string, key: string) => any }) {
  return props.getData('options', 'isTYAEvent') || (props.getData('options', 'eventTrack') || {}).id === 24 || false;
}
