<template>
  <div class="search" :class="{ 'inverted' : inverted, 'collapsible' : collapsible, 'open' : collapsibleTgl }">
    <UIInput v-bind="$attrs" :value="internalValue" @input="onInput" type="text" ref="search" :placeholder="placeholder"/>
    <font-awesome-icon :icon="['far','search']" class="icon" @click="collapseTgl()" v-if="icon !== 'none'"/>
    <span v-if="collapsible && collapsibleTgl" @click="collapseTgl()" class="close">x</span>
  </div>
</template>

<script>
import UIInput from './Input.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import debounce from 'lodash/debounce';

library.add(faSearch)

export default {
  name: 'UISearch',
  data () {
    return {
      internalValue: this.value,
      debounceInput: null,
      collapsibleTgl: false
    }
  },
  methods: {
    collapseTgl: function() {
      this.collapsibleTgl === true ? this.collapsibleTgl = false : this.collapsibleTgl = true;
      this.$refs.search.$el.focus();
    },
    onInput (event) {
      this.internalValue = event;
    }
  },
  components: {
    UIInput
  },
  created () {
    this.debounceInput = this.debounce ?
      debounce(() => { this.$emit('input', this.internalValue); }, this.debounce) :
      () => { this.$emit('input', this.internalValue); }
  },
  props: {
    value: {},
    placeholder: {
      type: String,
      default: 'Type to search'
    },
    inverted: {
      type: Boolean,
      default: false
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'search'
    },
    debounce: {
      type: Number
    }
  },
  watch: {
    internalValue () {
      this.debounceInput();
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
}
.search input {
  padding-left: 30px;
}
.search.inverted input {
  padding-left: 12px;
  padding-right: 30px;
}
.icon {
  position: absolute;
  top: 10px;
  left:8px;
  color: #ea7d1c;
}
.search.inverted .icon {
  left: auto;
  right: 10px;
}

// Collapsible search specific styles
.search.collapsible {
  transition: all .3s ease-in-out;
  max-width: 0;
}
.search.collapsible.open {
  max-width: 800px;
}
.search.collapsible .icon {
  cursor: pointer;
}
.search.collapsible input {
  transition: all .6s ease-in-out;
  border-color: transparent;
}
.search.collapsible.open input {
  border-color: #efefef;
}
.close {
  position: absolute;
  color: #ea7d1c;
  top: 12px;
  font-weight: bold;
  right: 10px;
  cursor: pointer;
}

</style>
