
import Vue from 'vue';
import UIButton from "shared/ui/buttons/Button.vue";
import UIInput from 'shared/ui/forms/Input.vue';
import Datepicker from 'shared/components/DatePicker.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import tz from 'timezone/loaded';
import DateTimezone from 'date-timezone';
import * as RadioGroup from 'shared/radio-group';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import { formatDate, formatAMPMTo24, getDate, getTime, getZoomTimeZone, convertTo24HourFormat, startDateBeforeEndDate } from 'shared/util';
import { Data, Methods, Computed, Props, ModifyRecurrenceFields, Event } from './types';
import { toISOString, startTimeBeforeEndTime } from './util';
import { calculateEventDuration, generateCustomDates } from '../../../../../shared/util';
import { SimpleDate, ErrorBag } from 'shared/util/types';
import ordinal from 'ordinal-numbers';
import { EventDates, ModifySeriesRecurrenceInput, RecurrenceType, Repeat, UpdateZoomMeetingDocument, UpdateZoomMeetingMutation, UpdateZoomOccurrenceDocument, UpdateZoomOccurrenceMutation } from 'shared/generated/graphql-types';
import { useApolloClient } from '@vue/apollo-composable';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ModifyRecurrence',
  components: {
    UIButton,
    UISelect,
    UIRadio,
    UISwitch,
    Datepicker,
    Timepicker,
    ...RadioGroup,
    SimpleForm,
    Field,
    Error,
    UIInput
  },
  props: {
    series: {},
    modifyRecurrence: {},
    dates: {},
    filters: {},
    handleLoading: {}
  },
  data() {
    return {
      formData: {
        isCustomDates: false,
        repeat: Repeat.CustomRecurrence,
        startEvent: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        customDates: null,
        RepeatInterval: 1,
        RepeatType: 'week',
        RepeatWeekDays: null,
        RepeatMonthDay: null,
        RepeatEndType: 'date',
        RepeatEndTimes: null
      }
    };
  },
  created() {
    const startEvent = this.eventToStartFromOptions[0];
    this.formData = {
      isCustomDates: false,
      repeat: Repeat.CustomRecurrence,
      startEvent,
      startDate: getDate(startEvent, 'startDate'),
      endDate: null,
      startTime: getTime(startEvent, 'startDate'),
      endTime: getTime(startEvent, 'endDate'),
      customDates: this.getCustomDates(new Date().toISOString()),
      RepeatInterval: 1,
      RepeatType: 'week',
      RepeatWeekDays: null,
      RepeatMonthDay: null,
      RepeatEndType: 'date',
      RepeatEndTimes: null
    };
  },
  computed: {
    eventToStartFromOptions() {
      const eventsWithFormattedDate = this.series.Events.map(e => ({
          ...e,
          formattedDate: formatDate(e.startDate, e.TimeZone, `%A %b %-d, %Y`).replace(/\d+/, i => ordinal(+i))
      }));

      const upcomingEvents = eventsWithFormattedDate.filter(x => new Date(x.startDate) > new Date());

      if (upcomingEvents.length) {
        return upcomingEvents.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return +dateA - +dateB;
        });
      }

      return [eventsWithFormattedDate[eventsWithFormattedDate.length - 1]];
    }
  },
  methods: {
    handleStartEventChange(event, setValue) {
      setValue('startEvent', event);
      setValue('startDate', getDate(event, 'startDate'));
      setValue('startTime', getTime(event, 'startDate'));
      setValue('endTime', getTime(event, 'endDate'));
      setValue('customDates', this.getCustomDates(event.startDate));
    },
    isCustomDatesHandler(event, setValue) {
      if (event) {
        setValue('repeat', Repeat.CustomDates);
      } else {
        setValue('repeat', Repeat.CustomRecurrence);
      }
    },
    validate(values) {
      let errors: ErrorBag<ModifyRecurrenceFields> = {};
      if(!values.startTime) {
        errors.startTime = 'Please select a start time';
      }
      if(!values.endTime) {
        errors.endTime = 'Please select an end time';
      }
      if(!values.repeat) {
        errors.repeat = 'Please select a value for the recurrence';
      }
      if(!values.startEvent) {
        errors.startEvent = 'Please select an event to start the modification from';
      }
      if(values.startTime && values.endTime && values.startEvent && values.endDate &&
        startTimeBeforeEndTime(values.startEvent!.TimeZone, values.endDate!, values.startTime!, values.endTime!) !== -1) {
          errors.startTime = 'The start time has to be before the end time';
      }
      if (values && values.repeat === 'custom_recurrence') {
        if (!values || !values.RepeatInterval || values.RepeatInterval < 1) {
          errors.RepeatInterval = 'Repeat interval should be greater than 0';
        }
        if (!values || !values.RepeatType) {
          errors.RepeatType = 'Please select recurrence type';
        }
        if (values && values.RepeatType === 'week' && !values.RepeatWeekDays) {
          errors.RepeatWeekDays = 'Please select days of week';
        }
        if (values && values.RepeatType === 'month') {
          if (values && !values.RepeatMonthDay) {
            errors.RepeatMonthDay = 'Please enter date of month';
          } else if (values && values.RepeatMonthDay! > 31) {
            errors.RepeatMonthDay = `Day can't be greater than 31`;
          } else if (values && values.RepeatMonthDay! < 1) {
            errors.RepeatMonthDay = 'Day should be greater than 0';
          }
        }
        if (!values || !values.RepeatEndType) {
          errors.RepeatEndType = 'Please select event end type';
        }
        if (values && values.RepeatEndType === 'occurrence' && values.RepeatEndTimes! < 1) {
          errors.RepeatEndTimes = 'Occurrences count should be greater than 0';
        }
        if (values && values.RepeatEndType === 'date') {
          if (values.startEvent && values.endDate && !startDateBeforeEndDate(getDate(values.startEvent, 'startDate'), values.endDate)) {
            errors.endDate = 'The end date has to be after start date';
          }
          if (!values.endDate) {
            errors.endDate = 'Please select an end date';
          }
        }
      }
      if (values && values.repeat === 'custom_dates') {
        if (!values || !values.customDates || !(values.customDates && JSON.parse(values.customDates).length > 0)) {
          errors.customDates = 'Please select at least one date';
        }
      }
      return errors;
    },
    async submit(args) {
      if('errors' in args) return;
      args.setSubmitting!();

      const { client } = useApolloClient()

      this.handleLoading(true);
      const { startEvent, endDate, endTime, startDate, startTime, repeat, customDates } = args.values;
      const { TimeZone } = this.series.Events[0];
      DateTimezone.setGlobalTimezone(TimeZone);

      const modifyRecurrenceInput: ModifySeriesRecurrenceInput = {
        seriesId: this.series.seriesID,
        startEventId: startEvent!.eventId,
        endDate: toISOString(startEvent!, startDate!, endTime!),
        startTime: { hours: formatAMPMTo24(+startTime!.hours, startTime!.ampm)!, minutes: +startTime!.minutes, seconds: 0 },
        endTime: { hours: formatAMPMTo24(+endTime!.hours, endTime!.ampm)!, minutes: +endTime!.minutes, seconds: 0 },
        repeat: repeat!,
        dates: []
      };
      let customDatesParsed: SimpleDate[] = [];

      if (repeat === Repeat.CustomDates) {
        customDatesParsed = JSON.parse(customDates!);
      } else if (repeat === Repeat.CustomRecurrence) {
        customDatesParsed = generateCustomDates({
          FirstEvent: startDate,
          LastEvent: endDate,
          RepeatInterval: args.values.RepeatInterval,
          RepeatType: args.values.RepeatType,
          RepeatWeekDays: args.values.RepeatWeekDays,
          RepeatMonthDay: args.values.RepeatMonthDay,
          RepeatEndType: args.values.RepeatEndType,
          RepeatEndTimes: args.values.RepeatEndTimes
        });
      }
      modifyRecurrenceInput.dates = customDatesParsed
        .filter((customDate) => {
          return new Date(startEvent!.startDate).getTime() !== new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(startTime),
              +startTime!.minutes,
            ).getTime();
        })
        .map((customDate, index) => {
          const date: EventDates = {
            start: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(startTime),
              +startTime!.minutes,
            ).toISOString(),
            end: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(endTime),
              +endTime!.minutes,
            ).toISOString(),
          };
          return date;
        });
      const result = await this.modifyRecurrence(modifyRecurrenceInput);

      const events: Event[] = result?.data?.modifySeriesRecurrence.Events.slice() || [];
      events.sort((a, b) => {
        const dateA: Date = new Date(a.startDate);
        const dateB: Date = new Date(b.startDate);
        return +dateA - +dateB;
      });
      if (events[0].isVirtual && events[0].zoomMeetingId) {
        let zoomStartDate = events[0].startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(events[0].TimeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(events[0].TimeZone);
          zoomStartDate = tz(tz(events[0].startDate as string), '%FT%T', zoomTimeZone);
        }
        const duration = calculateEventDuration(events[0].startDate as string, events[0].endDate as string)

        // reset zoom meeting recurrence
        await client.mutate<UpdateZoomMeetingMutation>({
          mutation: UpdateZoomMeetingDocument,
          variables: {
            id: events[0].zoomMeetingId,
            input: {
              start_time: zoomStartDate!.replace(/\.000Z/, ''),
              duration,
              timezone: zoomTimeZone,
              topic: events[0].eventName,
              type: 2
            }
          }
        });

        // create zoom meeting recurrence
        await client.mutate<UpdateZoomMeetingMutation>({
          mutation: UpdateZoomMeetingDocument,
          variables: {
            id: events[0].zoomMeetingId,
            input: {
              start_time: zoomStartDate!.replace(/\.000Z/, ''),
              duration,
              timezone: zoomTimeZone,
              topic: events[0].eventName,
              type: 8,
              recurrence: {
                repeat_interval: 1,
                type: RecurrenceType.Daily,
                end_times: events.length || 0
              }
            }
          }
        });

        // sync occurrence ids with events
        const occurrenceIds: number[] = [];
        await client.mutate<UpdateZoomOccurrenceMutation>({
          mutation: UpdateZoomOccurrenceDocument,
          variables: {
            OccurencyDatasInput: events.map((event, index) => {
              const eventOccurrency = new Date(events[0].startDate);
              eventOccurrency.setUTCDate(eventOccurrency.getUTCDate() + index);
              occurrenceIds.push(eventOccurrency.getTime());
              return {
                eventId: event.eventId,
                OccurencyId: eventOccurrency.toISOString()
              }
            })
          }
        })

        // change zoom meetings dates
        for (let index = (events.length - 1); index >= 0; index--) {
          if (getZoomTimeZone(events[index].TimeZone) !== 'UTC') {
            zoomTimeZone = getZoomTimeZone(events[index].TimeZone);
            zoomStartDate = tz(tz(events[index].startDate as string), '%FT%T', zoomTimeZone);
          }
          await client.mutate<UpdateZoomMeetingMutation>({
            mutation: UpdateZoomMeetingDocument,
            variables: {
              id: events[0].zoomMeetingId,
              input: {
                start_time: zoomStartDate,
                duration,
                occurrence_id: occurrenceIds[index]
              }
            }
          });
        }
      }

      this.handleLoading(false);
      args.setSubmitted!();
      this.$emit('submitted');
    },
    getCustomDates(startDate) {
      return JSON.stringify(
        this.series.Events
          .filter(x => new Date(x.startDate) > new Date(startDate))
          .map(x => getDate(x, 'startDate'))
      );
    }
  }
});
