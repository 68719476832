
import Vue from 'vue';
import TeenProfile from 'shared/components/TeenProfile';
import UISearch from 'shared/ui/forms/Search.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Loading.vue';
import Single from './components/Single';
import List from './components/List.vue';
import FiltersPanel from './components/FiltersPanel.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { Data, Methods, Computed, Props } from './types';
import { hasNumber } from 'shared/util';
import { seasonOptions } from '../shared/seasonHelper';
import UISelect from 'shared/ui/forms/FancySelect';

library.add(faIndent, faOutdent, faFilter, faPlusCircle);
// XTODO tests
// data, methods, computed, props
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'JFEListWrapper',
  components: {
    PanelsGrid,
    PanelsSidebar,
    UISearch,
    Loading,
    List,
    Single,
    ScrollablePanel,
    TeenProfile,
    FiltersPanel,
    UISelect
  },
  directives: {
    fullheight
  },
  props: {
    router: {},
    teens: {},
    path: {},
    currentTeenId: {},
    loading: {},
    initialLoading: {},
    fetchMore: {},
    activeFilters: {},
    setFilters: {},
  },
  data() {
    return {
      profileClose: false,
      filtersOpen: false,
      seasonOptions: seasonOptions()
    };
  },
  computed: {
    displaySpinner() {
      return this.loading;
    },
    showFilterPanel () {
      return (!this.singleTeenRoute || this.filtersOpen);
    },
    singleTeenRoute () {
      return hasNumber(this.router.location.pathname);
    }
  },
  methods: {
    closeProfile () {
      this.profileClose = !this.profileClose;
    },
    async termChange (term: string) {
      await this.setFilters({ term });
    },
    async seasonChange (season: string) {
      await this.setFilters({ season });
    }
  }
})
