
import { Route, MatchFirst } from 'vue-component-router';
import EventCalendar from './EventCalendar';
import Single from './SingleEvent';
import Series from './Series';
import EditEvent from './EditEvent';

export default {
	props: ['path', 'user', 'regionId'],
	name: 'Events',
	components: { Route, EventCalendar, Single, EditEvent, MatchFirst, Series }
};
