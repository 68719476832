
import Vue from 'vue';
import UIInput from 'shared/ui/forms/Input.vue';
import Datepicker from 'shared/components/DatePicker.vue';
import DateRangePicker from 'shared/components/DateRangePicker.vue';
import { SimpleDate } from 'shared/util/types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { EventTicket } from 'shared/generated/graphql-types';

library.add(faTimes,faDollarSign)

export default Vue.extend({
  name: 'Ticket',
  components: {
    UIInput,
    Datepicker,
    DateRangePicker
  },
  data () {
    return {
      ticket: this.value
    }
  },
  props: {
    value: {
      type: Object as () => EventTicket
    }
  },
  watch: {
    value: {
      handler (value) {
        this.ticket = {price: 0, deposit: 0, ...(value ? value : {})};
      },
      immediate: true
    }
  },
  methods: {
    datesSelected(event: { start: SimpleDate, end: SimpleDate } | null){
      if (event) {
        this.ticket.startDate = event.start;
        this.ticket.endDate = event.end;
      } else {
        this.ticket.startDate = null;
        this.ticket.endDate = null;
      }
      this.$emit('change', this.ticket);
    }
  }
})
