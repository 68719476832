<template>
	<Collapsible>
		<template #default="{open, toggle}">
			<div class="filter-group" :class="{ show: open }">
				<header @click="toggle">
					<span>{{header}}</span>
					<span class="clear-filter" @click="clearFilters()" v-if="clear">clear all</span>
					<span class="arrow"></span>
				</header>
				<transition name="slide">
				<div class="body" v-if="open" :class="{ show : open }">
					<slot></slot>
				</div>
				</transition>
			</div>
		</template>
	</Collapsible>
</template>
<script>
import Collapsible from 'shared/collapsible'

export default {
  name: 'FilterGroup',
	props: {
		header: {
			type: String
		},
		clear: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Collapsible
	}
};
</script>
<style scoped>

	.filter-group {
		margin-top: 10px;
		padding-bottom: 10px;
	}
	header {
		cursor: pointer;
		overflow: hidden;
		position: relative;
		font-size: 14px;
		padding: 10px 5px 10px 0;
		font-weight: bold;
		display: flex;
		align-items: center;
	}
	.clear-all {
		font-size: 10px;
		margin-left: auto;
		color: white;
		font-weight: 400;
		background: #ea7d1d;
		padding: 1px 5px;
		border-radius: 4px;
		margin-right: 15px;
	}
	.arrow {
		position: absolute;
		right: 5px;
		top: 18px;
	}
	.show .arrow {
		transform: rotate(-180deg);
		top: 12px;
	}
	.arrow:before {
		content:"";
		border: 5px solid transparent;
		border-top-color: #999;
		display: block;
		width: 8px;
		height:8px;
		top: 0;
		right: 0;
	}
	.body {
		max-height: 2000px;
	}
	.slide-enter-active, 
	.slide-leave-active {
  		transition: max-height .3s;
	}
	.slide-enter, 
	.slide-leave-to {
	max-height: 0;
	}
</style>
