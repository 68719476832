
import { Vue, Component, Prop } from 'vue-property-decorator';
import Step from '../../shared/Step.vue';
import { Steps } from '../../../shared/MultistepForm';
import Details from '../shared/Details.vue';
import School from '../shared/School.vue';
import StaffAndChapter from '../shared/StaffAndChapter.vue';
import Thumbnail from '../shared/Thumbnail.vue';
import Options from '../shared/Options.vue';
import RegistrationAndTickets from '../shared/RegistrationAndTickets.vue';
import { FormState } from '../../../shared/types';
import { GetRegionDataQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']

@Component({
  components: {
		Step,
    Details,
    School,
    StaffAndChapter,
    Thumbnail,
    Options,
    RegistrationAndTickets
  }
})
export default class Shabbaton extends Vue {
  @Prop() steps!: Steps
  @Prop() complete!: (step: string) => void
  @Prop() toggleActive!: (step: string) => void
  @Prop() activatable!: (step: string) => boolean
  @Prop() finalStep!: (step: string) => boolean
  @Prop() firstStep!: (step: string) => boolean
  @Prop() setData!: (step: string, key: string, value: any) => void
  @Prop() getData!: (step: string, key: string) => any
  @Prop() region!: Region;
  @Prop() seriesId!: number;
  @Prop({type: String}) state!: FormState
  @Prop() openDescriptionTab!: () => void

  getOuterData(step: string, key: string) {
    return this.getData(step, key);
  }
}
