

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import { ServerSideFilterOption } from 'shared/components/Filters/FilterOption';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';
import identity from 'lodash/identity';
import uniqBy from 'lodash/uniqBy';
import TeenAdvisorsLookup from 'shared/components/TeenAdvisorsLookup';
import { ActiveFiltersType } from '../shared/ActiveFilters';
import { GetFilterOptionsQuery } from 'shared/generated/graphql-types';

@Component({
  components: {
    UISelect,
    TeenAdvisorsLookup
  }
})
export default class FiltersPanel extends Vue {
  @Prop() activeFilters!: ActiveFiltersType;
  @Prop() filterOptions!: GetFilterOptionsQuery;
  @Prop() regionId!: number;

  advisor: any = this.activeFilters.advisorId || null;

	filters: { [key: string]: ServerSideFilterOption[] } = {
    Chapter: this.activeFilters.filters.Chapter || []
  }
	get localActivefilters () {
    return {
      filters: omitBy(pickBy(this.filters, identity), (x) => isArray(x) && !x.length),
      advisorId: this.advisor && this.advisor.staffID,
      }
	}
	get chapterOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.chapterOptions || [])
			.filter(co => co.key !== null && co.displayValue !== null)
			.map(co => ({
				key: co.key!,
				displayValue: co.displayValue!
			})), ['displayValue']), 'key');
  }
	@Watch ('filters', { deep: true })
	filtersWatcher () {
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('advisor', { deep: true })
	advisorWatcher (advisor: any) {
    this.$emit('filters', this.localActivefilters)
  }
}
