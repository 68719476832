
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import CommunityMemberProfile from 'shared/components/CommunityMemberProfile';
import { StateChanger } from 'vue-infinite-loading';
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { Route, RouterLink, MatchFirst } from 'vue-component-router';
import { hasNumber } from 'shared/util';
import UISearch from 'shared/ui/forms/Search.vue';
import UIButton from "shared/ui/buttons/Button.vue";
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Loading.vue';
import Single from './Single';
import List from './components/List.vue';
import ActiveFilters from './shared/ActiveFilters';
import FetchMoreOptions from './shared/FetchMoreOptions';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import Modal from 'shared/ui/modal/Modal.vue';
import SelectDuplicatesFields from 'shared/components/SelectDuplicatesFields/fetchFirst';
import FiltersPanel from './components/FiltersPanel.vue';
import { CommunityMember } from './shared/types';
import { SortType } from 'shared/generated/graphql-types';

library.add(faIndent, faOutdent, faSlidersH, faPlusCircle, faFilter)

@Component({
	name: 'CommunityMemberListWrapper',
	components: {
		PanelsGrid,
      PanelsSidebar,
      FiltersPanel,
		UISearch,
		Loading,
		Route,
		RouterLink,
		MatchFirst,
		UIButton,
		List,
		ActionBar,
		Single,
		ScrollablePanel,
    CommunityMemberProfile,
    Modal,
    SelectDuplicatesFields,
	},
	directives: {
		fullheight
	}
})
export default class extends Vue {

  @Prop() path!: string;
  @Prop() regionId!: number;
  @Prop() router!: { location: { pathname: string } };
  @Prop({ type: Boolean }) initialLoading!: boolean;
  @Prop({ type: Boolean }) loading!: boolean;
  @Prop() communityMembers!: CommunityMember[];
  @Prop() fetchMore!: (args: FetchMoreOptions, stateChanger: StateChanger) => void;
  @Prop() activeFilters!: ActiveFilters;
	@Prop() setFilters!: (args: Partial<ActiveFilters>, cb?: () => void) => void;
  @Prop({type: String}) currentCommunityMemberId!: string | null;
  @Prop() total!: number;

	filtersOpen: boolean = false;
	showCommunityMemberProfile: boolean = false;
  mergingCommunityMembers: boolean = false;
  checkedCommunityMembers: number[] = [];
  keyForFiltersPanel: number = 0

	toggleCommunityMemberProfile(){
		this.showCommunityMemberProfile = !this.showCommunityMemberProfile
	}
	termChange (term: string) {
		this.setFilters({ term });
  }
  clearFilters () {
    this.setFilters({
			term: '',
      showDeceased: false,
			filters: {},
			sortBy: { name: SortType.Name, ascending: true }
    });
    this.keyForFiltersPanel++
  }
  get showFilterPanel () {
    return !this.addingNewCommunityMember && (!this.singleCommunityMemberRoute || this.filtersOpen)
  }
  get singleCommunityMemberRoute () {
    return hasNumber(this.router.location.pathname)
  }
	get displaySpinner () {
		return this.loading
	}
	get addingNewCommunityMember() {
    	return this.router.location.pathname.indexOf('AddNewCommunityMember') > -1;
  }
}
