import { Vue, Component, Prop, Provide } from 'vue-property-decorator'
import { CreateElement, VNode } from 'vue';
import VisualProgressList from './ProgressList.vue'

@Component
export class ListItem extends Vue {
  @Prop() done!: boolean
  @Prop() label!: string
}

interface Item {
  done: boolean;
  label: string;
  content: VNode[]
}

@Component({
  provide () {
    return {
      addItem: this.addItem,
      removeItem: this.removeItem
    }
  }
})
export class ProgressList extends Vue {
  render (h: CreateElement) {
    const vNodes = this.$slots.default || []
  
    const items = vNodes.filter(x =>
      x.componentOptions
      && x.componentOptions.tag === 'ListItem'
      && x.componentOptions.propsData
    ).map((x, index) => ({
      done: (x.componentOptions!.propsData! as any).done,
      name: (x.componentOptions!.propsData! as any).label,
      content: x.componentOptions!.children
    }))

    return h(VisualProgressList, {
      props: {
        items
      }
    }, items.map((x, i) => h('template', {
      slot: `content_${i}`
    }, x.content)))
  }
}