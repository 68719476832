
import Vue from 'vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import {faDollarSign} from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import {library} from '@fortawesome/fontawesome-svg-core';
import { AddPaymentInput, AddPaymentMutation, CcType, PaymentSource, PaymentType } from 'shared/generated/graphql-types';

library.add(faDollarSign);

interface Data {
  PaymentType: typeof PaymentType;
  PaymentSource: typeof PaymentSource;
  CreditCardType: typeof CcType;
  suppressEmail: boolean;
}

interface Methods {
  handleSubmit: () => Promise<void>;
  setField: (field: string, value: any) => void;
}

interface Computed {
  paymentPayload: AddPaymentInput;
  valid: boolean;
}

interface Props {
  registrationID: number;
  addPayment: (vars: AddPaymentInput) => Promise<AddPaymentMutation> | void;
  adding: boolean;
  paymentInput: AddPaymentInput;
  isModal: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AddPayment',
  components: {
    UIButton,
    UIInput,
    UICheckbox
  },
  props: {
    registrationID: {},
    addPayment: {},
    adding: {},
    paymentInput: {},
    isModal: {},
  },
  data() {
    return {
      PaymentType: PaymentType,
      PaymentSource: PaymentSource,
      CreditCardType: CcType,
      suppressEmail: false,
    }
  },
  computed: {
    paymentPayload () {
      return {
        amount: Number(this.paymentInput.amount) || 0,
        type: this.paymentInput.type,
        CCType: this.paymentInput.type === PaymentType.Cc ? this.paymentInput.CCType : null,
        note: this.paymentInput.note,
        authorizationToken: this.paymentInput.type === PaymentType.Cc ? this.paymentInput.authorizationToken : null,
        checkNumber: this.paymentInput.type === PaymentType.Check ? this.paymentInput.checkNumber : null,
        source: this.paymentInput.type === PaymentType.Cc ? this.paymentInput.source : null,
        paymentDate: new Date(),
        suppressEmail: this.suppressEmail
      };
    },
    valid (): boolean {
      const {amount, type, source, checkNumber} = this.paymentInput;

      const basic = amount > 0 && !!type;

      switch (type) {
        case PaymentType.Cc:
          return !!(basic && type !== null && source);
        case PaymentType.Check:
          return basic && (checkNumber || '').trim().length > 0;
        default:
          return basic;
      }
    }
  },
  methods: {
    async handleSubmit () {
      await this.addPayment(this.paymentPayload);
      this.$emit('submit')
    },
    setField (field: string, value: any) {
      this.$emit('paymentInput', {
        ...this.paymentInput,
        [field]: value
      });
    }
  }
})
