
import Avatar from 'shared/components/avatar';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import TeenViewModel from 'shared/components/SelectDuplicatesFields/shared/TeenViewModel';
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import SelectDuplicatesFields from 'shared/components/SelectDuplicatesFields';
import map from 'lodash/map';
import { GetTeenDuplicatesQueryVariables, MergeTeenDuplicatesMutationVariables, NonDuplicatesMutationVariables } from 'shared/generated/graphql-types';
import { Chapter } from 'shared/types';

@Component({
  name: "TeenDuplicates",
  components: {
      BorderedList,
      UIButton,
      Modal,
      Avatar,
      SelectDuplicatesFields
  },
  methods: {
    map
  }
})

export default class extends Vue {
  @Prop() teenDuplicates!: TeenViewModel[];
  @Prop() selectedTeen!: number;
  @Prop() nonDuplicates!: (args: NonDuplicatesMutationVariables) => void;
  @Prop() markingAsSeperate!: boolean;
  @Prop() regionId!: number;
  @Prop() chapters!: Chapter[]
  @Prop() searchTerm!: string;

  checkedTeens: TeenViewModel[] =[];
  showMergeScreen: boolean = false;
  showMarkAsSeperate: boolean = false;

  selectedTeenHandler (teen: TeenViewModel) {
    if (this.selectedTeen === teen.personId) {
      this.$emit('selectTeen', null)
    }
    else {
      this.$emit('selectTeen', teen.personId)
    }
  }
  showMarkAsSeperateHandler () {
    this.showMarkAsSeperate = true;
  }
  clearCheckedTeens(){
    this.checkedTeens.splice(0);
  }
	modalHandler() {
		if (this.checkedTeens.length > 1) {
			this.showMergeScreen = true;
		}
  }
	markAsSeperateHandler() {
		if (this.checkedTeens.length > 1) {
		   const ids = this.checkedTeens.map(x => x.personId);
		   this.nonDuplicates({ ids })
		}
    this.showMarkAsSeperate = false;
	}
}

