
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { RouterLink } from 'vue-component-router';
import Avatar from 'shared/components/avatar';
import ActiveFilters from '../shared/ActiveFilters';
import FetchMoreOptions from '../shared/FetchMoreOptions';
import Loading from 'shared/components/Loading.vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { GetStaffQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type AdvisorRegion = ArrayElement<GetStaffQuery['staff']>

interface Props {
    staff: AdvisorRegion[];
}
interface Methods {
    extractStaffID: (s: AdvisorRegion) => number;
}
@Component({
  name: "List",
  components: {
    RouterLink,
    BorderedList,
    InfiniteLoading,
    Avatar,
    Loading
  },
  filters: {
      formatStaff({ Staff: { firstName, lastName } }: AdvisorRegion) {
          return `${firstName} ${lastName}`
      }
  }
})

export default class extends Vue {
  @Prop() regionId!: number;
  @Prop() staff!: AdvisorRegion[];
  @Prop() fetchMore!: (args: FetchMoreOptions, stateChanger: StateChanger) => void;
  @Prop() activeFilters!: ActiveFilters;
  @Prop({type: Number}) currentStaffId!: number | null;
  @Prop() checkedStaff!: AdvisorRegion[];

  limit: number = 60;
  stringifiedCheckedStaff: string[] = this.checkedStaff.map(s => JSON.stringify(s));

  get localCheckedStaff(): AdvisorRegion[] {
    return this.stringifiedCheckedStaff.map(s => JSON.parse(s));
  }
  async fetchMoreHandler ($state: StateChanger) {
        await this.fetchMore({ limit: this.limit, offset: this.staff && this.staff.length }, $state);
        $state.reset();
        $state.loaded()
  }
    extractStaffID(s: AdvisorRegion) {
            return s.Staff.staffID;
    }
    formatStaff({ Staff: { firstName, lastName } }: AdvisorRegion) {
          return `${firstName} ${lastName}`
    }
    @Watch('checkedStaff')
    checkedStaffWatcher () {
      if (!this.checkedStaff.length) this.stringifiedCheckedStaff = [];
      this.stringifiedCheckedStaff = this.checkedStaff.map(s => JSON.stringify(s));
    }
}

