import Housing from './Housing.vue';
import { computed } from 'vue';
import { compose, withData } from 'vue-compose';
import { setHouseEnhancer } from './setHouseEnhancer';
import { unsetHouseEnhancer } from './unsetHouseEnhancer';
import { editHouseEnhancer } from './editHouseEnhancer';
import { editHouseNotesEnhancer } from './editHouseNotesEnhancer';
import { removeHouseEnhancer } from './removeHouseEnhancer';
import { updateHouseRegistrationEnhancer } from './updateHouseRegistrationEnhancer';
import AssignableEventStaff from './shared/AssignableEventStaff';
import isNil from 'lodash/isNil';
import {
  CreateHouseInput,
  GetEventQuery,
  GetHousesForEventDocument,
  GetHousesForEventQuery,
  HotelInput,
  useAddHotelMutation,
  useAddNewHouseMutation,
  useGetEventQuery,
  useGetHousesForEventQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

type TProps = {
  eventId: string;
};

type TChildProps = {
  event?: GetEventQuery['event'] | null;
};

const getEventEnhancer = wrapComponent<TProps, TChildProps>((props) => {
  const { result } = useGetEventQuery(computed(() => ({ eventId: Number(props.eventId) })));

  return computed(() => ({
    event: result.value?.event
  }));
});
interface Props {
  eventId: number;
}

interface AddHouseProps {
  addNewHouse: (eventId: number, house: CreateHouseInput, staff?: AssignableEventStaff) => void;
}
const addNewHouseEnhancer = wrapComponent<Props, AddHouseProps>((props) => {
  const { mutate } = useAddNewHouseMutation();

  return computed(() => ({
    addNewHouse: (eventId, house, staff) =>
      mutate(
        {
          eventId,
          house: {
            ...house,
            staffId: isNil(staff) ? undefined : staff.staffId
          }
        },
        {
          optimisticResponse: {
            addNewHouse: {
              __typename: 'House',
              capacity: house.capacity || null,
              city: house.city || null,
              description: house.description || null,
              gender: house.gender || null,
              phone: house.phone || null,
              street: house.street || null,
              state: house.state || null,
              street2: house.street2 || null,
              zipCode: house.zipCode || null,
              notes: '', // really this *should* be coming from the create form...
              registrationCount: 0,
              EventStaff: [],
              Registrations: [],
              EventGuests: [],
              houseId: Math.round(Math.random() * -1000000),
              Staff: isNil(staff)
                ? null
                : {
                    __typename: 'EventStaff',
                    eventStaffId: staff.eventStaffId,
                    Staff: {
                      __typename: 'Staff',
                      staffID: staff.staffId,
                      fullName: staff.fullName,
                      gender: staff.gender
                    }
                  }
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetHousesForEventQuery>({
              query: GetHousesForEventDocument,
              variables: { eventId: props.eventId }
            });

            if (data && updateData?.addNewHouse) {
              const houses = data.houses.slice();
              houses.push(updateData.addNewHouse);
              proxy.writeQuery<GetHousesForEventQuery>({
                query: GetHousesForEventDocument,
                variables: { eventId: props.eventId },
                data: {
                  houses
                }
              });
            }
          }
        }
      )
  }));
});

interface AddHotelProps {
  addHotel: (eventId: number, hotel: HotelInput) => void;
}
const addHotelEnhancer = wrapComponent<Props, AddHotelProps>((props) => {
  const { mutate } = useAddHotelMutation();

  return computed(() => ({
    addHotel: (eventId, hotel) =>
      mutate(
        { eventId, hotel },
        {
          optimisticResponse: {
            addHotel: hotel.rooms.map((r) => ({
              city: hotel.city || null,
              state: hotel.state || null,
              street: hotel.street || null,
              street2: hotel.street2 || null,
              zipCode: hotel.zipCode || null,
              description: r.description || null,
              capacity: r.capacity || null,
              gender: r.gender || null,
              __typename: 'House' as 'House',
              notes: '',
              phone: null,
              eventId,
              registrationCount: 0,
              EventStaff: [],
              Registrations: [],
              EventGuests: [],
              createdAt: +new Date(),
              houseId: Math.round(Math.random() * -1000000),
              Staff: null
            }))
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetHousesForEventQuery>({
              query: GetHousesForEventDocument,
              variables: { eventId: props.eventId }
            });

            if (data && updateData?.addHotel) {
              const houses = data.houses.slice();
              houses.push(...updateData.addHotel);
              proxy.writeQuery<GetHousesForEventQuery>({
                query: GetHousesForEventDocument,
                variables: { eventId: props.eventId },
                data: {
                  houses
                }
              });
            }
          }
        }
      )
  }));
});

interface WithLoading {
  loadingHouses: boolean;
  houses: GetHousesForEventQuery['houses'];
}

export default compose(
  getEventEnhancer,
  wrapComponent<Props, WithLoading>((props) => {
    const { loading, result } = useGetHousesForEventQuery(
      computed(() => ({ eventId: props.eventId }))
    );

    return computed(() => ({
      loadingHouses: loading.value,
      houses: result.value?.houses || []
    }));
  }),
  addNewHouseEnhancer,
  addHotelEnhancer,
  setHouseEnhancer,
  unsetHouseEnhancer,
  editHouseEnhancer,
  editHouseNotesEnhancer,
  removeHouseEnhancer,
  updateHouseRegistrationEnhancer,
  withData({
    addingNewHouse: {
      initialValue: false
    },
    gridView: {
      initialValue: false
    },
    term: {
      initialValue: ''
    },
    status: {
      initialValue: null
    },
    gender: {
      initialValue: null
    }
  })
)(Housing);
