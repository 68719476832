import QuickAddTeen from './QuickAddTeen.vue';
import { withApollo } from 'shared/components/graphql';
import { compose } from 'vue-compose';
import { withRouter } from 'vue-component-router';
import addTeenEnhancer from 'shared/components/AddTeenEnhancer';
import { CompactTeen as CompactTeenType } from 'shared/components/TeenLookup';
export type CompactTeen = CompactTeenType;
export default compose(
	withApollo,
  addTeenEnhancer,
	withRouter
)(QuickAddTeen);
