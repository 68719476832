import { render, staticRenderFns } from "./CreateFamilyForm.vue?vue&type=template&id=4f5aeb02&scoped=true&"
import script from "./CreateFamilyForm.vue?vue&type=script&lang=ts&"
export * from "./CreateFamilyForm.vue?vue&type=script&lang=ts&"
import style0 from "./CreateFamilyForm.vue?vue&type=style&index=0&id=4f5aeb02&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5aeb02",
  null
  
)

export default component.exports