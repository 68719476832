
import UISelect from 'shared/ui/forms/FancySelect';
import Vue from 'vue';
import orderBy from 'lodash/orderBy';
import { Chapter } from 'shared/types';

export default Vue.extend({
	name: 'ChapterLookup',
	props: {
		regionId: {},
		chapters: { type: Array as () => Chapter[] },
		loading: Boolean,
		disabled: Boolean,
		value: {
			type: [Object as () => number | Chapter | null, Number, Array as () => Chapter[]]
		},
		multiple: Boolean
	},
	computed: {
		options(): Chapter[] {
			return orderBy(this.chapters, ['chapterName'], ['asc'])
		},
		valueAsArray(): Chapter[] {
			if (!this.value) return []

			const value: Array<number | Chapter> = Array.isArray(this.value) ? this.value : [this.value]

			return value.map(chapter => typeof chapter === 'object'
				? chapter
				: this.options.find(x => x.chapterId === chapter)!
			).filter(v => v)
		}
	},
	components: {
		UISelect
	}
})
