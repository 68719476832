
import Vue from 'vue';
import { Route, RouterLink } from 'vue-component-router';
import Dashboard from './pages/Dashboard';
import Busing from './pages/Busing';
import Guests from './pages/Guests';
import Housing from './pages/Housing';
import Registrations from './pages/Registrations';
import Scholarships from './pages/Scholarships';
import CustomQuestionsCollectionPage from './pages/CustomQuestionsCollections';
import Staff from './pages/Staff';
import Attendances from './pages/Attendances';
import RecordAttendees from './pages/RecordAttendees';
import Loading from 'shared/components/Loading.vue';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { library } from '@fortawesome/fontawesome-svg-core';
import RequiresPermission from 'shared/components/RequiresPermission';
import { isEventStarted } from 'shared/util';
import { CurrentUserQuery, GetEventQuery, PermissionEnum, StatusType } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { Me } from 'shared/types';

type Event = GetEventQuery['event']
type AdvisorRegion = ArrayElement<CurrentUserQuery['me']['AdvisorRegions']>

library.add(faPencil, faEdit);

interface Data {
  PermissionEnum: typeof PermissionEnum
}

interface Props {
  url: string;
  eventId: string;
  user: Me;
  currentUser: AdvisorRegion;
  event: Event;
  regionId: number;
  loading: boolean;
}

interface Computed {
  showRecordAttendees: boolean;
  registrationRequired: boolean;
  completedRegistrations: number;
  pendingRegistrations: number;
}

interface Methods {}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SingleEvent',
  components: {
    Route,
    RouterLink,
    Dashboard,
    Busing,
    Guests,
    Housing,
    Registrations,
    Scholarships,
    CustomQuestionsCollectionPage,
    Staff,
    Attendances,
    RecordAttendees,
    Loading,
    RequiresPermission
  },
  data() {
    return {
      PermissionEnum: PermissionEnum
    };
  },
  props: {
    url: {},
    eventId: {},
    user: {},
    currentUser: {},
    event: {},
    regionId: {},
    loading: {}
  },
  computed: {
    showRecordAttendees() {
      if (!this.event) return false;
      return !this.registrationRequired && isEventStarted(this.event.startDate, this.event.TimeZone);
    },
    registrationRequired() {
      return !!this.event && !!this.event!.registrationRequired;
    },
    completedRegistrations() {
      return this.event.Registrations.filter((r) => r.status !== StatusType.RefundRequired && r.status !== StatusType.Pending).length;
    },
    pendingRegistrations() {
      return this.event.Registrations.filter((r) => r.status === StatusType.Pending).length
    }
  }
});
