import AddPayment from './AddPayment.vue';
import { computed } from 'vue';
import { withData, compose } from 'vue-compose';
import {
  AddPaymentInput,
  AddPaymentMutation,
  useAddPaymentMutation
} from 'shared/generated/graphql-types';
import { MutateResult } from '@vue/apollo-composable';
import { wrapComponent } from 'shared/apollo-hoc';

interface Props {
  registrationID: number;
}

interface AddPaymentProps {
  addPayment: (vars: AddPaymentInput) => MutateResult<AddPaymentMutation> | void;
  adding: boolean;
}

const addPaymentEnhancer = wrapComponent<Props, AddPaymentProps>((props) => {
  const { loading, mutate } = useAddPaymentMutation();

  return computed(() => ({
    adding: loading.value,
    addPayment: (input) => mutate({ input, registrationId: props.registrationID })
  }));
});

export default compose(
  withData({
    paymentInput: {
      initialValue: {}
    }
  }),
  addPaymentEnhancer
)(AddPayment);
