

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import ChapterLookup from 'shared/components/ChapterLookup';

@Component({
  components: {
    ChapterLookup
  }
})
export default class chapterIdPicker extends Vue {
  @Prop() value!: number;
  @Prop() regionId!: number;
}
