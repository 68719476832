<template>
  <div class="modal-mask" v-if="show" @click="handleModalClose">
    <div class="modal-wrapper">
      <div class="modal-container" :style="{width: width, height: height}">
        <div v-if="title" class="modal-header">
          <h3>{{title}}</h3>
        </div>
        <div class="modal-body">
          <slot :$listeners="listeners" :requestClose="requestClose"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean
    },
    title: {
      type: String
    },
    requestClose: {
      type: Function
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  watch: {
    show (show) {
      if (show) {
        document.body.parentElement.classList.add('noscroll');
      }
      else {
        document.body.parentElement.classList.remove('noscroll');
      }
    }
  },
  data () {
    return {
      listeners: {}
    }
  },
  created () {
    this.listeners = {
      ...this.$listeners,
      input: e => this.$emit('input', e)
    }
  },
  methods: {
    handleModalClose(e) {
      if (e.target && e.target.classList && (e.target.classList.contains('modal-wrapper') || e.target.classList.contains('modal-mask'))) {
        return this.requestClose && this.requestClose();
      }
    }
  }
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  display: block;
  transition: opacity .3s ease;
  overflow: auto;
  padding-top: 100px;
}
.modal-wrapper {
  display: block;
  vertical-align: middle;
}
.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
  transition: all .3s ease;
}
.modal-header h3 {
    margin-top: 0;
  vertical-align: middle;
}

.modal-body {
    margin: 20px 0;
}
</style>
