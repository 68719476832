

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TeenProfile from 'shared/components/TeenProfile';
import UIButton from "shared/ui/buttons/Button.vue";
import UISearch from 'shared/ui/forms/Search.vue';
import Pagination from 'shared/components/Pagination';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import Loading from 'shared/components/Lock-ui.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import MissingSchools from './components/MissingSchools';
import FiltersPanel from './components/FiltersPanel.vue';
import { ActiveFiltersType } from './shared/ActiveFilters';
import FetchMoreOptions from './shared/FetchMoreOptions';
import CompactTeen from './shared/CompactTeen';
import * as RadioGroup from 'shared/radio-group';
import UIRadio from 'shared/ui/forms/Radio.vue';
import { GetFilterOptionsQuery, SortType } from 'shared/generated/graphql-types';

library.add(faIndent, faOutdent)

@Component({
  components: {
    PanelsSidebar,
    UIButton,
    FiltersPanel,
    MissingSchools,
		PanelsGrid,
    Pagination,
    ScrollablePanel,
    ...RadioGroup,
    UIRadio,
    TeenProfile,
    Loading,
    UISearch,
    FilterGroup
  },
  directives: {
    fullheight
  }
})
export default class Layout extends Vue {
  @Prop() filterOptions!: GetFilterOptionsQuery;
  @Prop() setFilters!: (filters: Partial<ActiveFiltersType>, cb?: () => void) => void;
  @Prop() setLimit!: (limit: number) => void;
  @Prop() resetFilters!: () => void;
  @Prop() country!: string;
  @Prop() regionId!: number;
  @Prop() teens!: CompactTeen[];
  @Prop() fetchMore!: (args: FetchMoreOptions) => void;
  @Prop() limit!: number;
  @Prop() total!: number;
  @Prop() loading!: boolean;
  @Prop() initialLoading!: boolean;
  @Prop() activeFilters!: ActiveFiltersType;

  isProfileClosed: boolean = false;
  selectingTeen: boolean = false;
  selectedTeen: number | null = null;
  key: number = 0;
  SortType: typeof SortType = SortType;

  sortHandler (sortBy: SortType) {
    this.setFilters({ sortBy: { name: sortBy, ascending: !this.ascending } });
  }
  get ascending () {
    return !!this.activeFilters.sortBy.ascending;
  }
  get totalPages () {
    if (this.limit === this.total) return this.total / this.limit
    return Math.ceil(this.total / this.limit)
  }
	get displaySpinner () {
		return this.loading || this.initialLoading
	}
	closeProfile(){
    this.isProfileClosed = !this.isProfileClosed
  }
	async setPage (page: number) {
    this.selectedTeen =  null;
		await this.fetchMore({limit: this.limit, offset: (page - 1) * this.limit});
  }
	queryChange (query: string) {
		this.setFilters({...this.activeFilters, query });
  }
	@Watch ('activeFilters', { deep: true })
	filtersWatcher () {
      this.selectedTeen =  null;
      this.key++
  }
	@Watch ('selectedTeen')
	selectedTeenWatcher (selectedTeen: boolean) {
      this.isProfileClosed =  false;
	}
}
