
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { faFont } from '@fortawesome/pro-solid-svg-icons/faFont';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faCaretSquareDown } from '@fortawesome/pro-solid-svg-icons/faCaretSquareDown';
import { faArrowsH } from '@fortawesome/pro-solid-svg-icons/faArrowsH';
import { faArrowsV } from '@fortawesome/pro-solid-svg-icons/faArrowsV';
import { library } from '@fortawesome/fontawesome-svg-core';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import * as RadioGroup from 'shared/radio-group';
import draggable from "vuedraggable";
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import Loading from 'shared/components/Loading.vue';
import { DraggableList, Template, TemplateSettingObject, TemplateType } from '../../types';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { validateEmail } from 'shared/util';
import { Region } from 'shared/types';
import { CreateTemplateInput, IdType, SendTestEmailMutationVariables, UpdateTemplateInput } from 'shared/generated/graphql-types';

library.add(faFont, faImage, faCaretSquareDown, faArrowsH, faArrowsV);

@Component({
    components: {
      UIButton,
      draggable,
      UIInput,
      SimpleForm,
      Field,
      Error,
      Loading,
      UIRadio,
      Modal,
      ScrollablePanel,
      ...RadioGroup
    },
    directives: {
      fullheight
    },
    methods: {
      validateEmail,
    }
})
export default class SideBar extends Vue {
    @Prop() source!: string;
    @Prop() sendTestEmail!: (args: SendTestEmailMutationVariables) => boolean;
    @Prop() createTemplate!: (args: CreateTemplateInput) => any;
    @Prop() updateTemplate!: (args: UpdateTemplateInput) => Template;
    @Prop() template!: NonNullable<Template>;
    @Prop() overrides!: NonNullable<Template>[];
    @Prop({ default: Number }) templateId!: number;
    @Prop() selectedRow!: DraggableList | null;
    @Prop() region!: Region;
    @Prop() eventId!: number;
    @Prop() highestRowId!: number;
    @Prop() chapterId!: number;
    @Prop() templateType!: TemplateType;
    @Prop() sendingTestEmail!: boolean;
    @Prop() creatingTemplate!: boolean;
    @Prop() updatingTemplate!: boolean;
    @Prop() editingDivider!: boolean;
    @Prop() editingButton!: boolean;
    @Prop() fetchingTemplate!: boolean;
    @Prop() templateValid!: boolean;
    @Prop() convertedFile!: { file ? : File, base64 ? : string };

    id: number | null = null;
    destinationEmail: string | null = null;
    invalidSettings: boolean = false;

    nav: string = 'content';

    formObject = {
      destinationEmail: null,
      id: null,
      fromAddress: this.template.fromAddress,
      fromName: this.template.fromName,
      replyToAddress: this.template.replyToAddress,
      subject: this.template.subject,
      ...this.emailVariableKeys.reduce((o, k) => ({...o, [k as string]: null}), {})
    };

    get elements() {
        return [{
                icon: 'font',
                text: 'Text',
                rowContent: '<p>Enter Text Here</p>',
                rowStyle: '',
                rowId: (this.highestRowId || 0) + 1
            },
            {
                icon: 'image',
                text: 'Image',
                rowContent: `<img src="data:image/png;base64, ${this.convertedFile.base64}" />`,
                rowStyle: '',
                rowId: (this.highestRowId || 0) + 2
            },
            {
                icon: 'caret-square-down',
                text: 'Button',
                rowContent: `<a href="#"
                    style="
                    font-size: 12px;
                    text-align: center;
                    color: white;
                    padding: 5px 25px;
                    min-height: 30px;
                    background-color: #ea7d1c;
                    display: inline-block;
                    font-weight: 600;
                    box-shadow: none;
                    transition: .2s;
                    line-height: 30px;
                    cursor: pointer;
                    border-radius: 4px;"
                    > Custom Button </a>`,
                rowStyle: '',
                buttonText: 'Custom Button',
                buttonColor: '#ea7d1c',
                buttonLink: '',
                buttonPosition: 'left',
                rowId: (this.highestRowId || 0) + 3
            },
            {
                icon: 'arrows-h',
                text: 'Divider',
                dividerColor: '#cccc',
                dividerHeight: '20',
                rowContent: `<hr style='display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;' />`,
                rowStyle: 'height: 20px;',
                rowId: (this.highestRowId || 0) + 4
            },
            {
                icon: 'arrows-v',
                rowContent: '<br />',
                rowStyle: '',
                text: 'Spacer',
                rowId: (this.highestRowId || 0) + 5
            },
        ]
    }

    get baseTemplate(): NonNullable<Template> {
      let baseTemplate: NonNullable<Template> = this.overrides.find((o) => !!o.regionId && !!!o.chapterId && !!!o.eventId) as NonNullable<Template>;
      if (!baseTemplate) {
          baseTemplate = this.overrides.find((o) => !!!o.regionId && !!!o.chapterId && !!!o.eventId) as NonNullable<Template>;
      }
      return baseTemplate;
    }

    getOverrideName ({ eventId, Event, regionId, chapterId, Chapter, Region }: NonNullable<Template>) {
      if (eventId) return Event!.eventName
      if (chapterId) return Chapter!.chapterName
      if (regionId) return Region!.regionName
      return 'Base'
    }
    validate(values: Template & { [key: string]: string, destinationEmail: string, idType: IdType }) {
        let errors: {
            [key: string]: string
        } = {};
        if (this.nav === 'testing') {
            if (this.templateType.idType && !values.id) {
                errors['id'] = `Please select ${this.templateType.idType} Id`;
            }
            if (!values.destinationEmail || !validateEmail(values.destinationEmail)) {
                errors['destinationEmail'] = 'The Email is Invalid';
            }
            if (this.templateType.emailVariables.length) {
              for (let k of this.emailVariableKeys) {
                if(k && !values[k]) {
                  errors[k] = `${this.templateType.emailVariables.find(e => e.key === k)!.description} is required`;
                }
              }
            }
            return errors;
        } else {
            return errors;
        }
    }

    get emailVariableKeys() {
      return (this.templateType.emailVariables || []).map(e => e.key);
    }
    clearFields() {
        this.emailVariablesFields = {};
        this.id = null;
        this.destinationEmail = null;
    }
    async updateOrCreateTemplate(callbackArgs: SubmitCallbackArgs<TemplateSettingObject>) {
        if ('errors' in callbackArgs) return;

        const { fromAddress, fromName, replyToAddress, subject } = callbackArgs.values;
        const { category } = this.templateType;
        const {regionId, eventId, chapterId,  body, templateTypeId, templateTypeKey, templateId } = this.template;

        if (!fromAddress || !fromName || !subject) {
            this.invalidSettings = true;
            this.nav = 'options';
            return;
        }

        if (templateId && (regionId || eventId || chapterId)) {
            this.updateTemplate({
                templateId,
                subject,
                body,
                fromAddress,
                fromName,
                templateTypeId,
                templateTypeKey,
                category,
                replyToAddress,
                chapterId: chapterId || this.chapterId,
                eventId: eventId || this.eventId,
                regionId: regionId || this.region.regionId
            });
        } else {
            const { data: { createTemplate } } = await this.createTemplate({
                body,
                templateTypeId,
                templateTypeKey,
                category,
                chapterId: chapterId || this.chapterId,
                eventId: eventId || this.eventId,
                regionId: regionId || this.region.regionId,
                fromName,
                fromAddress,
                replyToAddress,
                subject
            });
            if (createTemplate && createTemplate.templateId) {
              this.$emit('selectedOverrideTemplateId', createTemplate.templateId)
            }
        }
    }
    get handler() {
        return this.nav === 'testing' ? this.sendTestEmailHandler : this.updateOrCreateTemplate
    }
    get textForTestEmail() {
        const idType: IdType | null = this.templateType.idType
        switch (idType) {
            case 'Registration':
                return `You can find registration IDs in the registrations table on an event, or the financial balances table.
                    The email will be filled in with details from that registration.`;
            case 'Attendance':
                return `You can find attendance IDs in the attendance table on an event.
                    The email will be filled in with details from that registration.`;
            case 'Teen':
                return `You can find teen IDs on the teens table.
		                The email will be filled in with details from that teen.`;
            case 'Parent':
                return `You can find parent IDs on the parents table.
                    The email will be filled in with details from that parent.`;
            case 'CommunityMember':
                return `You can find community member IDs on the community members table.
                    The emai will be filled with details from that community member.`;
        }
    }
    async sendTestEmailHandler(callbackArgs: SubmitCallbackArgs<TemplateSettingObject>) {
        if ('errors' in callbackArgs) return;
        const { id, templateType, destinationEmail, emailVariableKeys } = this;
        const data = await this.sendTestEmail({
            email: destinationEmail,
            ...(id ? { id: +id } : {}),
            ...(templateType.emailType ? { type: templateType.emailType } : {}),
            // using `any` here as I am not sure how to get the typings for templateType.emailVariables as they are dynamic
            ...(emailVariableKeys.length ? { variables: JSON.stringify(emailVariableKeys.reduce((o, k) => ({...o, [k as string]: (callbackArgs as any)[k as string]}), {})) } : {})
        });
        if (data) {
            this.clearFields()
        }
    }

    useDefaults(values: TemplateSettingObject) {
        if (!values.fromAddress) {values.fromAddress = this.baseTemplate.fromAddress!}
        if (!values.fromName) {values.fromName = this.baseTemplate.fromName!}
        if (!values.subject) {values.subject = this.baseTemplate.subject!}
    }

    setFocus(values: TemplateSettingObject) {
        if (!values.fromAddress) {
          ((this.$refs.fromAddress as Vue).$el as HTMLInputElement).focus();
          return;
        }
        if (!values.fromName) {
          ((this.$refs.fromName as Vue).$el as HTMLInputElement).focus();
          return;
        }
        if (!values.subject) {
          ((this.$refs.subject as Vue).$el as HTMLInputElement).focus();
          return;
        }
    }
}
