import EventTypeLookup from './EventTypeLookup.vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { ArrayElement } from 'shared/util/types';
import { GetEventTypesQuery, useGetEventTypesQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';

export type EventType = ArrayElement<GetEventTypesQuery['eventTypes']>

type ChildProps = {
  loading: boolean
  eventTypes: EventType[]
}

export default wrapComponent<{}, ChildProps>((props) => {
  const { loading,  result } = useGetEventTypesQuery()

  return computed(() => ({
    eventTypes: result.value?.eventTypes || [],
    loading: loading.value
  }))
})(EventTypeLookup)
