import { render, staticRenderFns } from "./GlobalSearch.vue?vue&type=template&id=222889ad&scoped=true&"
import script from "./GlobalSearch.vue?vue&type=script&lang=ts&"
export * from "./GlobalSearch.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalSearch.vue?vue&type=style&index=0&id=222889ad&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "222889ad",
  null
  
)

export default component.exports