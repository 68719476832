
import Vue from 'vue';
import VueMultiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { RouterLink } from 'vue-component-router';
import { dateFormat } from 'shared/util';
import debounce from 'lodash/debounce';
import Avatar from 'shared/components/avatar';

library.add(faSearch, faCalendarAlt, faMapMarkerAlt)

export default Vue.extend({
  name: 'GlobalSearch',
  props: {
    term: String,
    setTerm: Function,
    clearTerm: Function,
    regionId: Number,
    loading: Boolean,
    results: Array,
    debounce: Number
  },
  data() {
    return {
      debounceLoading: false,
      debounceTerm: debounce((event) => {
        this.setTerm(event);
      }, 300)
    }
  },
  components: {
    VueMultiselect,
    RouterLink,
    Avatar
  },
  methods: {
    dateFormat
  },
  computed: {
    options () {
      const { term, results, loading } = this;
      this.debounceLoading = false;

      if (!term || !results || loading) {
        return [];
      }

      return Object.entries(results.reduce((group: Record<string, any[]>, item: any) => {
        const type = item.__typename === 'Family' ? 'Families' : item.__typename + 's';
        group[type] = group[type] || [];

        group[type].push(item);

        return group;
      }, {})).map(([type, items]) => ({type, items}))
    }
  }
});
