
import Vue from 'vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { library } from '@fortawesome/fontawesome-svg-core';
import UISelect from 'shared/ui/forms/FancySelect';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import { asUTCWithZerotime } from 'shared/util';
import { EditPaymentInput, GetRegistrationQuery, PaymentSource, PaymentType } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Payment = ArrayElement<GetRegistrationQuery['registration']['Payments']>

library.add(faDollarSign);

type Errors<T> = { [k in keyof T]?: string };
interface EnumAsObject {
  label: string;
  value: string;
  paymentCopy?: any;
}

interface Data {
  PaymentType: typeof PaymentType;
  suppressEmail: boolean;
  paymentCopy: any;
}

interface Props {
  editing: boolean;
	payment: Payment;
	registrationId: number;
	editPayment: (input: EditPaymentInput) => void;
}

interface Computed {
  paymentTypes: {
    label: any;
    value: string;
  }[];
  paymentSources: {
    label: any;
    value: string;
  }[];
}

interface Methods {
  enumToList: (_enum: Record<string, string>, filter?: (x: any) => boolean) => {
    label: any;
    value: string;
  }[];
  enumToObject: (enumString: string | null) => EnumAsObject | null | string;
  handleSubmit: () => Promise<void>;
  submit: (callbackArg: SubmitCallbackArgs<any>) => Promise<void>;
  validate: (values: any) => Errors<any>
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EditPayment',
  components: {
		UIButton,
		UIInput,
    UICheckbox,
    SimpleForm,
    UISelect,
    Field,
    Error
	},
  props: {
    editing: {},
    payment: {},
    registrationId: {},
    editPayment: {}
  },
  data() {
    return {
      PaymentType: PaymentType,
      suppressEmail: false,
      paymentCopy: {
        ...omit(this.payment, ['registrationID', '__typename', 'CCType', 'suppressEmail']),
        type: this.enumToObject(this.payment.type),
        source: this.enumToObject(this.payment.source)
      }
    }
  },
  computed: {
    paymentTypes () {
      return this.enumToList(PaymentType)
    },
    paymentSources () {
      return this.enumToList(PaymentSource)
    }
  },
  methods: {
    enumToList (_enum: Record<string, string>, filter: (x: any) => boolean = (x: any) => true) {
      return  Object.keys(_enum).filter(x => Number.isNaN(+x) && filter(x)).map(x => ({
          label: _enum[x] === PaymentType.Cc ? 'Credit Card' : capitalize(_enum[x]),
          value: _enum[x]
        }))
    },
    enumToObject (enumString: string | null): EnumAsObject | null | string {
      if (!enumString) return enumString
      return {
        label: enumString === PaymentType.Cc ? 'Credit Card' : capitalize(enumString),
        value: enumString
      }
    },
    async handleSubmit () {
      this.$emit('submit')
    },
    async submit(callbackArg: SubmitCallbackArgs<any>) {
      if('errors' in callbackArg) return;
      const { values } = callbackArg;
      await this.editPayment({
        ...omit(values, ['registrationID', '__typename']),
        amount: +values.amount,
        type: values.type && values.type.value || null,
        source: values.source ? values.source.value : null,
        paymentDate: asUTCWithZerotime(values.paymentDate)
      } as EditPaymentInput)
      this.handleSubmit();
    },
    validate(values: any) {
      const errors: Errors<any> = {};

      if (values.amount <= 0) {
        errors['amount'] = "please set amount";
      }
      if (!values.type || !values.type.value) {
        errors['type'] = "please set payment type";
      }
      if (values.type && values.type.value === PaymentType.Cc) {
        if (!!!(values.source && values.source.value)) {
          errors['source'] = "please set source";
        }
      }
      if (values.type && values.type.value === PaymentType.Check) {
        if (!!!(values.checkNumber || '').trim().length) {
          errors['checkNumber'] = "please set check number";
        }
      }
      return errors;
    }
  }
})
