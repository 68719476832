
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import ParentProfile from 'shared/components/ParentProfile';
import { Route, RouterLink, MatchFirst } from 'vue-component-router';
import { hasNumber } from 'shared/util';
import UISearch from 'shared/ui/forms/Search.vue';
import UIButton from "shared/ui/buttons/Button.vue";
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Loading.vue';
import Single from './Single';
import AddNewFamily from '../AddNewFamily';
import List from './components/List.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import Modal from 'shared/ui/modal/Modal.vue';
import SelectDuplicatesFields from 'shared/components/SelectDuplicatesFields/fetchFirst';
import FiltersPanel from './components/FiltersPanel.vue';
import { SortType } from 'shared/generated/graphql-types';

library.add(faIndent, faOutdent, faSlidersH, faPlusCircle, faFilter);
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FamilyListWrapper',
  components: {
      PanelsGrid,
      PanelsSidebar,
      FiltersPanel,
      UISearch,
      Loading,
      Route,
      RouterLink,
      MatchFirst,
      AddNewFamily,
      UIButton,
      List,
      ActionBar,
      Single,
      ScrollablePanel,
      ParentProfile,
      Modal,
      SelectDuplicatesFields,
	},
	directives: {
		fullheight
	},
  props: {
    path: {},
    regionId: {},
    router: {},
    loading: {},
    initialLoading: {},
    families: {},
    fetchMore: {},
    activeFilters: {},
    setFilters: {},
    currentFamilyId: {},
    total: {}
  },
  data () {
    return {
      filtersOpen: false,
      showParentProfile: false,
      keyForFiltersPanel: 0,
      currentParentId: null
    };
  },
  methods: {
    setParentId(id) {
      this.currentParentId = id;
    },
    toggleParentProfile () {
      this.showParentProfile = !this.showParentProfile;
    },
    termChange (term) {
      this.setFilters({ term });
    },
    clearFilters () {
      this.setFilters({
        term: '',
        sortBy: { name: SortType.Name, ascending: true }
      });
      this.keyForFiltersPanel++;
    }
  },
  computed: {
    showFilterPanel () {
      return !this.addingNewFamily && (!this.singleFamilyRoute || this.filtersOpen);
    },
    singleFamilyRoute () {
      return hasNumber(this.router.location.pathname);
    },
    displaySpinner () {
      return this.loading;
    },
    addingNewFamily() {
      return this.router.location.pathname.indexOf('AddNewFamily') > -1;
    }
  },
  watch: {
    currentFamilyId: {
      handler () {
        this.currentParentId = null;
      }
    }
  }
});
