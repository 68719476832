
import Vue from "vue";
import UIInput from 'shared/ui/forms/Input.vue';

interface Props {
    value: {
        minLength: number
        maxLength: number
    }
}

interface Methods {
    update: (propName: keyof Props["value"], propValue: any) => void;
}

export default Vue.extend<{}, Methods, {}, Props>({
    name: "TextStringConfiguration",
    components: {
        UIInput
    },
    props: {
        value: {},
    },
    methods: {
        update(propName, propValue) {
            let obj: Props['value'] = {
                ...this.value
            }
            obj[propName] = Number.parseInt(propValue);
            this.$emit('input', obj);
        }
    },
});
