
import ToggleButtonWrapper from 'shared/components/ToggleButtonWrapper.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faPaste } from '@fortawesome/pro-regular-svg-icons/faPaste';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ApolloClient } from '@apollo/client';
import FileSaver from 'file-saver';
import { b64toBlob } from 'shared/util';
import config from '../../../../../../../../../config';
import UIButton from 'shared/ui/buttons/Button.vue';
import ClipboardCopy from 'shared/ui/clipboard/clipboard.vue';
import { getParentsArray } from 'shared/util/families';
import { Gender, GetDigitallySignedWaiversDocument, GetDigitallySignedWaiversQuery, GetDigitalWaiverLinkDocument, GetDigitalWaiverLinkQuery, GetRegistrationQuery, GetSignedWaiversQuery, GetWaiversQuery, ToggleNcsyWaiverMutationVariables, SubmitWaiverMutationVariables, UnSubmitWaiverMutationVariables } from 'shared/generated/graphql-types';

library.add(faFileAlt,faPaste)

type Registration = GetRegistrationQuery['registration']

@Component({
	name: 'Waivers',
	components: { ToggleButtonWrapper, UIButton, ClipboardCopy }
})
export default class extends Vue {

    @Prop() waivers!: GetWaiversQuery['waivers'];
    @Prop() eventId!: number;
    @Prop() eventHasNCSYWaiver!: boolean;
    @Prop() registration!: GetSignedWaiversQuery['registration'];
    @Prop() registrationID!: number;
    @Prop() fullRegistration!: Registration;
    @Prop() submitWaiver!: (vars: SubmitWaiverMutationVariables) => void;
    @Prop() unSubmitWaiver!: (vars: UnSubmitWaiverMutationVariables) => void;
    @Prop() toggleNCSYWaiver!: (vars: ToggleNcsyWaiverMutationVariables) => void;
    @Prop() client!: ApolloClient<any>

    digitalWaiverLinks: { text: string, link: string}[] = [];
    loadingDigitalWaiverLinks: boolean = false;
    @Watch('registration', { deep: true, immediate: true })
    async waiverLinksHandler() {
      if(!this.registration) {
        return;
      }
      this.loadingDigitalWaiverLinks = true;
      if(this.fullRegistration.Event!.Region.regionId === config.alumniRegionId){
        const link = (await this.client.query<GetDigitalWaiverLinkQuery>({
              query: GetDigitalWaiverLinkDocument,
              variables: {
                registrationId: this.registrationID,
                personId: this.fullRegistration.personID
              }
            })).data.getDigitalWaiverLink;
        this.digitalWaiverLinks = [{
          text: `Digital Waiver link for ${this.fullRegistration.Teen.Person.firstName} ${this.fullRegistration.Teen.Person.lastName}`,
          link
        }];
        this.loadingDigitalWaiverLinks = false;
        return;
      }
      const parents = getParentsArray(this.fullRegistration.Teen.Person);
      if(!parents.length) {
        this.digitalWaiverLinks = [];
        this.loadingDigitalWaiverLinks = false;
        return;
      }
      this.digitalWaiverLinks = await Promise.all(parents.map(
        async p => {
          return  {
            text: `Digital Waiver for ${p.name} (${p.gender === Gender.Male ? 'Father' : 'Mother'})`,
            link: (await this.client.query<GetDigitalWaiverLinkQuery>({
              query: GetDigitalWaiverLinkDocument,
              variables: {
                registrationId: this.registrationID,
                personId: p.parentId
              }
            })).data.getDigitalWaiverLink
          }
        }
      ));
      this.loadingDigitalWaiverLinks = false;
    }

    get wasNCSYWaiverSigned() {
        return this.registration.ncsyWaiverReceived ? true : false;
    }

    async downloadWaiver(){
      if (!this.registration) {
        return '_blank';
      }

      const result = await this.client.query<GetDigitallySignedWaiversQuery>({
        query: GetDigitallySignedWaiversDocument,
        variables: {
          registrationId: this.registrationID
          }
      });
      FileSaver.saveAs(b64toBlob(result.data.digitallySignedWaiver, 'application/pdf', 'data:application/pdf;base64,'), `${this.registrationID}.pdf`)
    }

    wasWaiverSigned(waiverID: number) {
        if (!this.registration || !this.registration.submittedWaivers){
            return false;
        }

        return this.registration.submittedWaivers.filter(w => w.id === waiverID).length ? true : false;
    }
    async waiverToggled({value} : {value: any}, {waiverID}: {waiverID: number}){
        if (value) {
            await this.submitWaiver({
                waiverID: waiverID,
                registrationID: this.registrationID
            });
        } else {
            await this.unSubmitWaiver({
                waiverID: waiverID,
                registrationID: this.registrationID
            });
        }
    }
    async ncsyWaiverToggled(){
        await this.toggleNCSYWaiver({registrationID: this.registrationID });
    }
};
