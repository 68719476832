
import { Vue, Prop, Component } from "vue-property-decorator";
import Pagination from 'shared/components/Pagination';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import UISelect from 'shared/ui/forms/Select.vue';
import Tables from 'shared/ui/tables/Tables.vue';
import UICheckbox from "shared/ui/forms/Checkbox.vue";
import Loading from 'shared/components/Loading.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
import { Filters } from './types';
import { getCurrentSeason } from 'shared/util';
import range from 'lodash/range';
import { Event } from './types'

interface Column {
  columnName: string,
  propertyName: string
}

@Component({
  name: "Events",
  components: {
  Tables,
  PanelsSidebar,
  UICheckbox,
  EventSubTypeLookup,
    Divider,
    PanelsGrid,
    UISearch,
    Loading,
    Pagination,
    ChapterLookup,
    UISelect
  }
})

export default class EventLookup extends Vue {
  @Prop() events!: Event[];
  @Prop() total!: number;
  @Prop() currentPage!: number;
  @Prop() limit!: number;
  @Prop() regionId!: number;
  @Prop() filters!: Filters;
  @Prop() isLoading!: boolean;
  @Prop() staffId!: number;
  @Prop() clearFilters!: ()=> void;

  term: string = '';
  columns: Column[] = [{columnName: 'Event Name', propertyName: 'eventName' }, {columnName: 'Start Date', propertyName: 'startDate'}, { columnName: 'Chapter', propertyName: 'chapterName'}, { columnName:'Attendees', propertyName: 'attendees' }, { columnName: 'Event Type', propertyName: 'eventType' }];

  get totalPages () {
    if (this.limit === this.total) return this.total / this.limit
    return Math.ceil(this.total / this.limit)
  }
  get seasons () {
    const currentSeason = Number(getCurrentSeason().split('-')[0]);
    return range(2011, currentSeason + 1).map(x => `${x}-${x + 1}`).reverse();
  }

  sortingHandler (col: Column) {
    this.setFilterHandler({ column: col.propertyName, ascending: !this.filters.sortColumn!.ascending }, 'sortColumn');
  }
  setFilterHandler (value: { column: string, ascending: boolean }, filter: string) {
     this.$emit('filters', {...this.filters, [filter]: value })
  }
  onPageChange(page: number) {
    this.$emit('currentPage', page);
  }
  onSelect({ eventName, eventId, startDate }: Partial<Event>) {
    this.$emit('selected', { eventName, eventId, startDate: this.dateFormat(startDate) })

  }
  dateFormat(date: string) {
      if (date) {
         return `${date.split('T')[0].split('-')[1]}/${date.split('T')[0].split('-')[2]}/${date.split('T')[0].split('-')[0]}`
      }
      return ''
  }
}

