

	import { Vue, Prop, Watch, Component } from "vue-property-decorator";
	import VueApexCharts from 'vue-apexcharts'
	import ScrollablePanel from 'shared/components/scrollable-panel.vue';
	import { CompactDashboardData, Grade, SchoolGroupedBySecondaryType } from '../types';
	import Loading from 'shared/components/Loading.vue';
	import ExpandableList from 'shared/components/ExpandableList/ExpandableList.vue';
	import fullheight from 'shared/directives/fullheight';
	import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
  import { dateFormat } from 'shared/util';
  import { RouterLink } from 'vue-component-router';
	import uniqBy from 'lodash/uniqBy';
	import uniq from 'lodash/uniq';

	@Component({
    name: 'DemographicData',
		components: {
      Loading,
      PanelsGrid,
      ExpandableList,
      ScrollablePanel,
      RouterLink,
      apexchart: VueApexCharts
		},
		methods: {
      uniqBy,
      dateFormat
		},
		directives: {
			fullheight
		}
	})
export default class extends Vue {
    @Prop() eventId!: number;
    @Prop() dashboardData!: CompactDashboardData | null;
    @Prop() registrationRequired!: boolean;
    @Prop() router!: { history: { push: (location: string) => void } };

    get demographicData () {
      return !!this.seriesGrade.length
      ||
      !!this.seriesGender.filter(Boolean).length
      ||
      !!this.seriesChapter.length
      ||
      !!this.seriesSchoolType.length
    }
    get registrationsOrAttendances () {
      if (this.registrationRequired) {
          return `registrations`
      }
      else {
         return `Attendances`
      }
    }
    get chaptersGroupedByName() {
        if (!this.dashboardData) return null
        return this.dashboardData.chaptersGroupedByName;
    }
    get schoolsGroupedBySecondaryType() {
        if (!this.dashboardData) return null
        return this.dashboardData.schoolsGroupedBySecondaryType;
    }
    get schoolsGroupedBySecondaryTypeSchools() {
      if (!this.dashboardData) return null
        return Object.keys(this.dashboardData.schoolsGroupedBySecondaryType).reduce(( acc, groupName) => {
          acc[groupName] = uniqBy(this.dashboardData!.schoolsGroupedBySecondaryType[groupName], 'id')
          return acc
        }, {} as SchoolGroupedBySecondaryType);
    }
    get grades(): Grade[] {
        if (!this.dashboardData) return []
        if (!this.dashboardData.grades || !this.dashboardData.grades.length) return [];
        return this.dashboardData.grades.map(x => ({
            ...x,
            grade: x.grade && !x.grade.includes('(Alumnus)') ? x.grade.split('(')[1].split(')')[0] : x.grade
        }))
    }
    get alumnus() {
        if (!this.dashboardData) return []
        if (!this.grades || !this.grades.length) return []
        return this.grades.filter((x: any) => x.grade.includes('(Alumnus)'));
    }
    get timezone() {
        if (!this.dashboardData) return null;
        return this.dashboardData.timeZone;
    }
    get gradesOptions() {
        const gradeOptions = uniqBy(this.grades, 'grade').filter(x => x.grade && !x.grade.includes('(Alumnus)'))
        const filteredGradesByLength = gradeOptions.map(x => this.grades.filter(g => g.grade === x.grade))
        let results = gradeOptions.map((x, index) => ({
            grade: x.grade,
            length: filteredGradesByLength[index].length,
            year: x.year
        })).sort((a, b) => {
          return (a as {year: number}).year - (b as {year: number}).year;
        })
        if (this.alumnus.length) {
          results.unshift({
            grade: 'Alumnus',
            length: this.alumnus.length,
              // @ts-ignore
              year: uniq(this.alumnus.map(x => x.year))
            });
        }
        return results
    }
    get seriesGrade() {
        return this.gradesOptions.map(x => x.length);
    }
    get seriesGender() {
        if (!this.dashboardData) return [];
        return [this.dashboardData.genderStatus && this.dashboardData.genderStatus.boys, this.dashboardData.genderStatus && this.dashboardData.genderStatus.girls];
    }
    get seriesChapter() {
        if (!this.chaptersGroupedByName || !Object.keys(this.chaptersGroupedByName).length) return []
        return Object.keys(this.chaptersGroupedByName).sort().map(x => this.chaptersGroupedByName![x].length)
    }
    get seriesSchoolType() {
        if (!this.schoolsGroupedBySecondaryType || !Object.keys(this.schoolsGroupedBySecondaryType).length) return []
        return Object.keys(this.schoolsGroupedBySecondaryType).map(x => Object.keys(this.schoolsGroupedBySecondaryType![x]).length)
    }
    get chartGradeOptions() {
        const labels = this.gradesOptions.map(x => x.grade);
        const that = this;
        return {
            labels,
            chart: {
              allowMultipleDataPointsSelection: true,
              events: {
                dataPointSelection (event: any, chartContext: any, { dataPointIndex }: any) {
                  const grade = that.gradesOptions.find(g => g.grade ===  labels[dataPointIndex])!
                  const gradeKey = that.registrationRequired ? 'graduationYear' : 'grade';
                  const filters = Array.isArray(grade.year) ? grade.year.map(x => `${gradeKey}=${x}`).join('&') : `${gradeKey}=${grade.year}`;

                  // prevent Apexcharts to throw error on route change
                  setTimeout(() => {
                    that.router.history.push(`/events/${that.eventId}/${that.registrationsOrAttendances}?${filters}`)
                  })
                }
              },
            },
            legend: {
                show: false
            },
            colors: ["#FFDB8E", "#8298D6", "#74C6CE", "#ea7d1c"],
            dataLabels: {
                formatter: function(val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex]
                }
            }
        }
    }
    get chartChapterOptions() {
        const labels = (this.chaptersGroupedByName ? Object.keys(this.chaptersGroupedByName) : []).sort();
        const that = this;
        return {
            labels,
            chart: {
              allowMultipleDataPointsSelection: true,
              events: {
                dataPointSelection (event: any, chartContext: any, { dataPointIndex }: any) {
                  const filter = (that.chaptersGroupedByName![labels[dataPointIndex]] || [{}])[0].chapterId;
                  // prevent Apexcharts to throw error on route change
                  setTimeout(() => {
                    that.router.history.push(`/events/${that.eventId}/${that.registrationsOrAttendances}?chapter=${filter}`)
                  })
                }
              },
            },
            legend: {
                show: false
            },
            colors: ["#FFDB8E", "#8298D6", "#74C6CE", "#ea7d1c"],
            dataLabels: {
                formatter: function(val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex]
                }
            }
        }
    }
    get chartGenderOptions() {
        const labels = ['Boys', 'Girls'];
        const that = this;
        return {
            labels,
            chart: {
              allowMultipleDataPointsSelection: true,
              events: {
                dataPointSelection (event: any, chartContext: any, { dataPointIndex }: any) {
                  // prevent Apexcharts to throw error on route change
                  setTimeout(() => {
                    that.router.history.push(`/events/${that.eventId}/${that.registrationsOrAttendances}?gender=${dataPointIndex}`)
                  })
                }
              },
            },
            legend: {
                show: false
            },
            colors: ["#00bcd4", "#cd8ece"],
            dataLabels: {
                formatter: function(val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex]
                }
            }
        }
    }
    get chartSchoolOptions() {
        const labels = this.schoolsGroupedBySecondaryType ? Object.keys(this.schoolsGroupedBySecondaryType) : [];
        const that = this;
        return {
            labels,
            legend: {
                show: false
            },
            colors: ["#FFDB8E", "#8298D6", "#74C6CE", "#ea7d1c"],
            dataLabels: {
                formatter: function(val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex]
                }
            }
        }
    }
    get eventIsActive () {
      const today = new Date();
      const eventDate = this.dashboardData && this.dashboardData.startDateLocal;
      if (!eventDate) return false;
      return new Date(eventDate) <= today;
    }

    teensByGrade(label: string) {
      const grade = this.gradesOptions.find(g => g.grade ===  label)!
      const gradeKey = this.registrationRequired ? 'graduationYear' : 'grade';
      const filters = Array.isArray(grade.year) ? grade.year.map(x => `${gradeKey}=${x}`).join('&') : `${gradeKey}=${grade.year}`;
      this.router.history.push(`/events/${this.eventId}/${this.registrationsOrAttendances}?${filters}`)
    }

    teensByChapter(label: string) {
      const filter = (this.chaptersGroupedByName![label] || [{}])[0].chapterId
      this.router.history.push(`/events/${this.eventId}/${this.registrationsOrAttendances}?chapter=${filter}`);
    }
    
    teensByGender(index: number) {
      this.router.history.push(`/events/${this.eventId}/${this.registrationsOrAttendances}?gender=${index}`)
    }
};
