
import Vue from 'vue';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import { FormShape, Data, Methods, Props } from './types';
import EmailInput from 'shared/components/EmailInput';
import UIInput from 'shared/ui/forms/Input.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import * as RadioGroup from 'shared/radio-group';
import UIRadio from 'shared/ui/forms/Radio.vue';
import { splitName, validateName, validateNumber } from 'shared/util';
import { ErrorBag } from 'shared/util/types';
import { EntityUnionEnum, Gender, MaritalStatus } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'QuickAddParent',
  components: {
    SimpleForm,
    Field,
    Error,
    EmailInput,
    UIInput,
    UICheckbox,
    PhoneInput,
    UIButton,
    ...RadioGroup,
    UIRadio,
  },
  props: {
    sourceGender: {},
    sourceMaritalStatus: {},
    addParent: {},
    addingParent: { default: false },
    modalMode: { default: false },
    associateHandler: { default() { return () => {} }},
    familyId: { default: null },
    externalCreate: { default: false },
    inputValues: { default: null },
    ownerType: { default: EntityUnionEnum.Parent }
  },
  data() {
    return {
      Gender,
      MaritalStatus,
      formShape: {
        gender: this.sourceGender || Gender.Male,
        email: null,
        fullName: null,
        cellPhone: null,
        landline: null,
        sendEmail: false,
        olamiId: null,
        maritalStatus: this.sourceMaritalStatus || MaritalStatus.Married
      },
      emailInputErrors: {}
    }
  },
  created() {
    this.formShape = {
      gender: this.inputValues?.gender || this.sourceGender || Gender.Male,
        email: this.inputValues?.email || null,
        fullName: this.inputValues?.firstName ? `${this.inputValues.firstName} ${this.inputValues.lastName}` : null,
        cellPhone: this.inputValues?.cellPhone || null,
        landline: this.inputValues?.homePhone || null,
        sendEmail: !!this.inputValues?.sendEmail,
        olamiId: this.inputValues?.olamiId || null,
        maritalStatus: this.inputValues?.maritalStatus || this.sourceMaritalStatus || MaritalStatus.Married
    }
  },
  methods: {
    async addParentHandler(args) {
      if ('errors' in args) return;
      
      if (args.setSubmitting) args.setSubmitting();
      try {
        const input = this.prepareInput(args.values)
        const result = await this.addParent(input);

        if (result?.data?.addParent) {
          this.$emit('created', {
            personId: result.data.addParent.personID,
            gender: args.values.gender,
            firstName: input.firstName,
            lastName: input.lastName,
            fullName: args.values.fullName,
            email: args.values.email,
            landline: args.values.landline,
            cellphone: args.values.cellPhone
          });
        }
        if (this.modalMode) {
          this.$emit('requestClose');
        } else {
          // TODO the button should be hidden or something
          // maybe the consumer needs to "add" the parent into the lookup
          // (can we even do that?, maybe based on update in enhancer)
          if (args.setSubmitted) args.setSubmitted();
        }
      } catch (e) {
        if (args.setSubmitted) args.setSubmitted();
      }
    },
    validate (values) {
      let errors: ErrorBag<FormShape> = {};
      if (this.emailInputErrors['email']) {
        errors['email'] = this.emailInputErrors['email']
      }
      if (!values.fullName || !validateName(values.fullName)) {
        errors['fullName'] = 'Please enter the parents first and last name';
      }
      if (values.gender === null) {
          errors['gender'] = 'Please select gender';
      }
      if (values.maritalStatus === null) {
          errors['maritalStatus'] = 'Please select marital status';
      }
      if (values.cellPhone && !validateNumber(values.cellPhone)) {
        errors['cellPhone'] = 'The Phone Number is invalid';
      }
      if (values.landline && !validateNumber(values.landline)) {
        errors['landline'] = 'The Phone Number is invalid';
      }

      this.$emit('updated', this.prepareInput(values), Object.keys(errors).length > 0);

      return errors;
    },
    prepareInput (values) {
      const {
        firstName,
        lastName,
      } = splitName(values.fullName!);

      return {
          firstName,
          lastName,
          email: values.email!,
          sendEmail: values.sendEmail,
          cellPhone: values.cellPhone,
          homePhone: values.landline,
          olamiId: values.olamiId,
          gender: values.gender,
          maritalStatus: values.maritalStatus,
          familyId: this.familyId
        }
    }
  }
});
