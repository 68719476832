
import { RouterLink } from 'vue-component-router';
import Vue from "vue";
import Loading from 'shared/components/Loading.vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import { Props as LayoutProps } from '../types';
import Avatar from 'shared/components/avatar';

interface Data {
  limit: number;
}

interface Methods {
  getGenderColor: (gender: number) => string;
  fetchMoreHandler: (state: StateChanger) => Promise<void>;
}

interface Props {
  orders: LayoutProps['orders'];
  fetchMore: LayoutProps['fetchMore'];
  activeFilters: LayoutProps['activeFilters'];
  selectedOrder: number;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'JFEOrdersList',
  components: {
    BorderedList,
    InfiniteLoading,
    Loading,
    RouterLink,
    Avatar
  },
  data() {
    return {
      limit: 60
    };
  },
  props: {
    orders: {},
    fetchMore: {},
    selectedOrder: {},
    activeFilters: {}
  },
  methods: {
    getGenderColor (gender) {
      if (!gender) {
        return '#00bcd4'
      } else {
        return '#cd8ece'
      }
    },
    async fetchMoreHandler(state: StateChanger) {
      await this.fetchMore({ limit: this.limit, offset: this.orders.length }, state);
      state.reset();
      state.loaded();
    }
  }
});

