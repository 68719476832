
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CcType, PaymentSource, PaymentType } from 'shared/generated/graphql-types';

library.add(faDollarSign);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AddJFEOrderPayment',
  components: {
    UIButton,
    UIInput,
    UICheckbox
  },
  props: {
    orderId: {},
    addPayment: {},
    adding: {},
    paymentInput: {},
    isModal: {},
  },
  data() {
    return {
      PaymentType: PaymentType,
      PaymentSource: PaymentSource,
      CreditCardType: CcType,
      suppressEmail: false,
    };
  },
  computed: {
    paymentPayload() {
      let ccFields: Partial<typeof this.paymentInput> = {
        CCType: null,
        authorizationToken: null,
        source: null,
      };
      let checkFields: Partial<typeof this.paymentInput> = {
        checkNumber: null,
      };

      if (this.paymentInput.type === PaymentType.Cc) {
        ccFields = {
          CCType: this.paymentInput.CCType,
          authorizationToken: this.paymentInput.authorizationToken,
          source: this.paymentInput.source,
        };
      }
      if (this.paymentInput.type === PaymentType.Check) {
        checkFields = {
          checkNumber: this.paymentInput.checkNumber,
        };
      }
      return {
        amount: Number(this.paymentInput.amount) || 0,
        type: this.paymentInput.type,
        note: this.paymentInput.note,
        paymentDate: new Date(),
        suppressEmail: this.suppressEmail,
        ...ccFields,
        ...checkFields,
      };
    },
    valid(): boolean {
      const { amount, type, source, checkNumber } = this.paymentInput;

      const basic = amount > 0 && !!type;

      switch (type) {
        case PaymentType.Cc:
          return !!(basic && type !== null && source);
        case PaymentType.Check:
          return basic && (checkNumber || '').trim().length > 0;
        default:
          return basic;
      }
    }
  },
  methods: {
    async handleSubmit() {
      await this.addPayment(this.paymentPayload);
      this.$emit('submit')
    },
    setField(field: string, value: any) {
      this.$emit('paymentInput', {
        ...this.paymentInput,
        [field]: value
      });
    }
  }
});
