
import Vue from 'vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import UIPassword from 'shared/ui/forms/Password.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import EmailInput from 'shared/components/EmailInput';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import Address from 'shared/components/Address/Address.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { ParentFieldsEnum } from 'shared/components/ParentProfile/types';
import { validateNumber, validateName, phoneFormat, phoneInternational, splitName } from 'shared/util';
import { Data, Methods, Computed, Props } from './types';
import { PersonType, PhoneType } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SingleParent',
  components: {
    AutoSaveField,
    UIButton,
    UIInput,
    UIPassword,
    Modal,
    Divider,
    EmailInput,
    PhoneInput,
    Address,
    UITextarea
  },
  props: {
    setParentId: {},
    parent: {},
    family: {},
    associateHandler: {},
    updatePerson: {},
    createAddress: {},
    updateAddress: {},
    deletePhone: {},
    updatePhone: {},
    createPhone: {},
    updateEmail: {},
    createEmail: {},
    changePassword: {},
    changingPassword: {}
  },
  data () {
    return {
      ParentFieldsEnum,
      parentCopy: this.parent,
      country: this.parent.country,
      personId: this.parent.personId,
      showPasswordModal: false,
      password: null
    };
  },
  methods: {
    validateNumber,
    validateName,
    phoneInternational,
    phoneFormat,
    viewProfile () {
      this.setParentId(this.personId);
    },
    updatePersonHandler (_, { fieldName, personId}) {
      const { fullName, gender, olamiId } = this.parentCopy;
      const { firstName, lastName } = splitName(fullName);
      this.updatePerson({
        personId,
        firstName,
        lastName,
        olamiId,
        gender,
        type: PersonType.Parent
      }, {
        personId,
        fieldName,
        value: JSON.stringify({
          firstName,
          lastName,
          olamiId,
          gender
        })
      });
    },
    createOrUpdate(args) {
      const { value, info: { handler, data } } = args.transformed;
      handler(value, data);
    },
    createAddressHandler(value, { fieldName, personId }){
      if (!value) return;
      this.createAddress({
          ...JSON.parse(value),
          personId,
        },
        { personId, fieldName, value }
      );
    },
    updateAddressHandler(value, { fieldName, personId }){
      this.updateAddress({
          ...JSON.parse(value),
          personId,
        },
        {
          personId,
          fieldName,
          value
        }
      );
    },
    updatePhoneHandler(value, { fieldId, fieldType, fieldName, personId }) {
      if (!value) {
        this.deletePhone(fieldId || -1)
      }
      else {
        this.updatePhone({
            id: fieldId!,
            type: fieldType!,
            phoneNumber: value,
            isActive: true,
            personId,
            doNotCall: false
          },
          { fieldName, value, personId }
        );
      }
    },
    createPhoneHandler(value, { fieldType, fieldName,  personId }) {
      if (!value) return;
      this.createPhone({
          type: fieldType!,
          phoneNumber: value,
          personId,
          doNotCall: false
        },
        { fieldName, value, personId }
      );
    },
    updateEmailHandler(value, { fieldId, fieldName,  personId }) {
      this.updateEmail({
          email: value,
          id: fieldId!,
          personId
        },
        { personId, fieldName, value }
      );
    },
    createEmailHandler(value, { fieldName,  personId }) {
      if (!value) return;
      this.createEmail({
          email: value,
          personId
        },
        { personId, fieldName, value }
      );
    },
    async passwordHandler() {
      if (this.password) {
        await this.changePassword({password: this.password, personId: this.parent.personId});
      }
      this.password = null;
      this.showPasswordModal = false;
    }
  },
  computed:{
    addressData() {
      return {
        handler: this.parentCopy.addressId ? this.updateAddressHandler : this.createAddressHandler,
        data: {
          fieldId: this.parentCopy.addressId,
          personId: this.personId,
          fieldName: ParentFieldsEnum.address
        }
      };
    },
    cellphoneData(){
      return {
        handler: this.parentCopy.cellphoneId ? this.updatePhoneHandler : this.createPhoneHandler,
        data: {
          fieldId: this.parentCopy.cellphoneId,
          fieldType: PhoneType.Mobile,
          personId: this.personId,
          fieldName: ParentFieldsEnum.cellphone
        }
      };
    },
    landlineData(){
      return {
        handler: this.parentCopy.landlineId ? this.updatePhoneHandler : this.createPhoneHandler,
        data: {
          fieldId: this.parentCopy.landlineId,
          fieldType: PhoneType.Landline,
          personId: this.personId,
          fieldName: ParentFieldsEnum.landline
        }
      };
    },
    emailData(){
      return {
        handler: this.parent.emailId ? this.updateEmailHandler : this.createEmailHandler,
        data: {
          fieldId: this.parent.emailId,
          personId: this.personId,
          fieldName: ParentFieldsEnum.email
        }
      };
    }
  }
});
