
import Vue from 'vue';
import values from 'lodash/values';
import FilterTypeDisplay from './FilterTypeDisplay.vue';
import set from 'lodash/fp/set';
import { Data, Methods, Computed, Props } from './types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

library.add(faTimes);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ServerSideFilters',
  components: {
    FilterTypeDisplay
  },
  props: {
    regionId: {},
    activeFilters: {},
    setFilters: {},
    clearAllFilters: {},
    filters: {},
  },
  data() {
    return {
      selectedFilterGroup: null,
      filtersOpen: false
    }
  },
  computed: {
    selectedFilters() {
      return this.activeFilters[(this.selectedFilterGroup || { filterGroup: '' }).filterGroup] || [];
    },
    filtersSelected () {
      return values(this.activeFilters).reduce((f, o) => f.concat(o), []).length;
    }
  },
  methods: {
    handleFiltersToggle (value) {
      this.filtersOpen = value
    },
    selectFilterType (filterGroup) {
      this.selectedFilterGroup = filterGroup;
    },
    deselectFilterType () {
      this.selectedFilterGroup = null;
    },
    selectionMade (filterOptions) {
      this.setFilters(set(this.selectedFilterGroup!.filterGroup, filterOptions, this.activeFilters));
    },
    displayLiveFiltersForType (filterType) {
      return (this.activeFilters[filterType] || []).map(f => f.displayValue).join(', ');
    }
  }
});
