<template>
<ul>
  <li v-for="(item, index) in items" :class="{ done: item.done }">
    <span class="mark">{{!item.done ? (index + 1) : '&#10003'}}</span>
    <span class="name">{{item.name}}</span>
    <div class="content"><slot :name="`content_${index}`"></slot></div>
  </li>
</ul>
</template>
<script>
export default {
	name: 'Modal',
	props: {
    items: {
      type: Array
    }
	}
};
</script>
<style lang="scss" scoped>
  ul {
    margin-left: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  li {
    padding-bottom: 30px;
    position: relative;

    &:before {
      content: "";
      border-left: 1px solid #999999;
      height: 100%;
      position: absolute;
      top: 0;
      left: -30px;
    }

    &:last-child:before {
      display: none;
    }
  }
  li.done {
    &:before {
      border-color: #ea7d1c;
    }
  }
  .mark {
    width: 20px;
    height: 20px;
    border: 1px solid #999999;
    border-radius: 50%;
    color: #999999;
    display: block;
    position: absolute;
    top: 0;
    left: -40px;
    background: white;
    text-align: center;
    font-size: 12px;
    padding-top: 1px;
    font-weight: 400;
  }
  .done .mark {
    background: #ea7d1c;
    color: white;
    border-color: #ea7d1c;
  }
  .current .mark {
    color: #ea7d1c;
    border-color: #ea7d1c;
  }
  .name {
    font-size: 1.1em;
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
  }
  .done .name,
  .current .name {
    color: #ea7d1c;
  }
</style>
