import { compose, withData, withProps, withHandlers } from 'vue-compose';
import { Props } from './types';
import Vue, { computed } from 'vue';
import EventLookup from './EventLookup.vue';
import { getCurrentSeason } from 'shared/util';
import { Event, Filters } from './types';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetEventsForReportQuery } from 'shared/generated/graphql-types';

export const filtersInit = (): Filters => ({
  sortColumn: {
    column: null,
    ascending: false
  },
  chapterId: null,
  searchByName: null,
  season: getCurrentSeason()
});

interface GetEventsProps {
  events: Event[];
  isLoading: boolean;
  total: number;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getEventsEnhancer = wrapComponent<Props, GetEventsProps>((props) => {
  const { loading, result } = useGetEventsForReportQuery(
    computed(() => ({
      limit: props.limit,
      offset: (props.currentPage - 1) * props.limit,
      filter: {
        showTorahHighSeries: props.showTorahHighSeries || null,
        ...props.filters,
        regionId: props.regionId,
        advisorId: props.staffId
      }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    isLoading: loading.value,
    events: result.value?.eventsLookup?.events || [],
    total: result.value?.eventsLookup?.total || 0
  }));
});

export const enhancer = compose(
  withData({
    filters: {
      initialValue: {
        sortColumn: {
          column: null,
          ascending: false
        },
        chapterId: null,
        searchByName: null,
        season: getCurrentSeason(),
        showMyEvents: false
      }
    },
    currentPage: {
      initialValue: 1
    }
  }),
  withHandlers({
    filters(arg: Filters) {
      this.$emit('currentPage', 1);
      this.$emit('filters', arg);
    }
  }),
  withProps<any, any>((props: Props) => ({
    clearFilters(this: Vue) {
      this.$emit('filters', filtersInit());
    },
    limit: 10,
    regionId: props.regionId || getCurrentRegion()
  })),
  getEventsEnhancer
);

export default enhancer(EventLookup);
