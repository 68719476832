
import Vue from 'vue';
import MultistepForm, { StepData, Steps, ValidationResult } from '../../../shared/MultistepForm';
import ShabbatonForm from './Form.vue';
import { ApolloClient } from '@apollo/client';
import set from 'lodash/set';
import DateTimezone from 'date-timezone';
import { ProgressList, ListItem } from 'shared/ui/lists/ProgressList';
import { FormState, EventScheduleInput } from '../../../shared/types';
import { convertTo24HourFormat } from 'shared/util';
import { ArrayElement, SimpleDate, SimpleTime } from 'shared/util/types';
import { shabbaton as shabbatonThumbnail, fridayNightLights as fridayNightLightsThumbnail, tya as tyaThumbnail, bgCubed as bgCubedThumbnail } from '../../../shared/DefaultEventImages';
import { CompactTeen } from 'shared/components/TeenLookup/shared/CompactTeen';
import {
  mapToEventTicketInputs,
  mapToAdditionalEventItemInputs,
  startTimeBeforeEndTimeParts,
  formatDate,
  formatTime,
  formatDateAndTime,
  THUMBNAIL_SIZE_LIMIT,
  THUMBNAIL_SIZE_LIMIT_BASE64,
  isFutureEvent
} from '../../../shared/util';
import { EventInput, GetRegionDataQuery, GetStaffQuery, GetZoomUsersQuery, ScheduleEventMutation } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']
type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']
type EventTicket = ArrayElement<ScheduleEventMutation['scheduleEvent']['EventTickets']>
type AdditionalEventItem = ArrayElement<ScheduleEventMutation['scheduleEvent']['AdditionalEventItems']>

interface Props {
  client: ApolloClient<any>;
  stepData: Steps;
  state: FormState;
  region: Region;
}

interface Methods {
  startTimeBeforeEndTimeParts: (data: StepData, firstDateSource: any, secondDateSource: any, firstTimeSource: any, secondTimeSource: any) => number;
  validate: (step: string, data: StepData, steps: Steps) => ValidationResult;
  handleChange: (step: string, key: string, value: any, form: any) => Promise<void>;
  submit: (steps: {[x: string]: any}) => void;
  createEvent: (steps: {[x:string]: any}) => EventScheduleInput;
  getEventFields: (steps: { [x:string]: any}, startDate: string, endDate: string) => EventInput;
  formatDate: (FirstEvent: SimpleDate, LastEvent: SimpleDate) => string;
  formatTime: (StartTime: SimpleTime, EndTime: SimpleTime) => string;
  formatDateAndTime: (date: SimpleDate, time: SimpleTime) => string;
  isThumbnailDefault: (thumbnail: string) => boolean;
  isFutureEvent: (startDate?: SimpleDate, startTime?: SimpleTime, timezone?: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, Props>({
  name: 'Shabbaton',
  components: {
    MultistepForm,
    ShabbatonForm,
    ProgressList,
    ListItem
  },
  props: {
    client: {},
    stepData: {type: Object},
    state: {type: String as () => FormState},
    region: {},
  },
  methods: {
    startTimeBeforeEndTimeParts,
    formatDate,
    formatTime,
    formatDateAndTime,
    isFutureEvent,
    validate (step, data, steps) {
      const errors = {}

      switch (step) {
        case 'eventSubType': {
          if (!data || !data.subType) {
            set(errors, `${step}.subType`, 'Please select a type for this event');
          }
          if (data && data.subType && data.subType.shabbat && !data.shabbatType) {
            set(errors, `${step}.shabbatType`, 'Please specify what kind of Shabbat event is being scheduled');
          }
          break;
        }
        case 'details': {
          if (!data || !data.name) {
            set(errors, 'details.name', 'Please enter a name for the event')
          }
          if (data && data.isVirtual) {
            if (data.streamSchedule) {
              if (!data.zoomUser) {
                set(errors, 'details.zoomUser', 'Please select Zoom User');
              }
              if (!data.zoomPasscode) {
                set(errors, 'details.zoomPasscode', 'Please enter passcode for the Zoom event');
              }
            }
          }
          if (!data || !data.StartTime) {
            set(errors, 'details.StartTime', 'Please pick a starting time for the event');
          }
          if (!data || !data.EndTime) {
            set(errors, 'details.EndTime', 'Please pick an ending time for the event');
          }
          if (!data || !data.FirstEvent) {
            set(errors, 'details.FirstEvent', 'Please pick a start date for the event');
          }
          if (!data || !data.LastEvent) {
            set(errors, 'details.LastEvent', 'Please pick an end date for the event');
          }
          if (!data || !data.TimeZone) {
            set(errors, 'details.TimeZone', 'Please select a timezone for this event');
          }
          if (data && this.startTimeBeforeEndTimeParts(data, data.FirstEvent, data.LastEvent, data.StartTime, data.EndTime) !== -1) {
            set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
          }
          if (data && data.FirstEvent && data.StartTime && data.TimeZone && !isFutureEvent(data.FirstEvent, data.StartTime, data.TimeZone) && data.teens && data.teens.length > 0) {
            set(errors, 'details.teens', `You can't pre-register teens for the past event`);
          }
          break;
        }
        case 'staffAndChapter': {
          if (!data || !data.primaryStaff) {
            set(errors, 'staffAndChapter.primaryStaff', 'Please select a primary staff member for the event');
          }
          break;
        }
        case 'registrationAndTickets': {
          if (!data || (data && !data.registrationRequired && !data.registrationAbsoluteRequired)) {
            break;
          }
          if (data && data.registrationRequired && (!data.tickets || (data.tickets && data.tickets.length === 0))) {
            set(errors, 'registrationAndTickets.tickets', 'Please add at least one ticket')
          }
          const detailsStep = (steps['details']!.data || {})
          if (data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, data.RegistrationCloseDate, detailsStep.FirstEvent, data.RegistrationCloseTime, detailsStep.StartTime) === 1) {
            set(errors, 'registrationAndTickets.registrationCloseDate', 'The registration close date has to be before the event start date');
          }

          if (data.tickets) {
            for(let index = 0; index < (data.tickets ||[]).length; index++) {
              const ticket: EventTicket | null = data.tickets[index];
              if (!ticket) {
                continue;
              }

              if (ticket.startDate && ticket.endDate && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, ticket.endDate, {minutes: "1", hours: "1", ampm: 'AM'}, {minutes: "1", hours: "1", ampm: 'AM'}) !== -1){
                set(errors, `${step}.tickets.${index}.startDate`, 'The start date needs to be before the end date');
              }
              if (ticket.startDate && data.RegistrationCloseDate && data.RegistrationCloseTime && this.startTimeBeforeEndTimeParts(detailsStep, ticket.startDate, data.RegistrationCloseDate, {minutes: "1", hours: "1", ampm: 'AM'}, data.RegistrationCloseTime) !== -1){
                set(errors, `${step}.tickets.${index}.startDateRegistrationCloseDate`, 'The start date needs to be before registration closes');
              }
              if (!ticket.Description || ticket.Description.trim().length < 1) {
                set(errors, `${step}.tickets.${index}.description`, 'Ticket needs a description');
              }
              if (!ticket.price && ticket.deposit) {
                set(errors, `${step}.tickets.${index}.deposit`, 'There can not be a deposit without a price');
              }
              if (ticket.price && +ticket.price < 0) {
                set(errors, `${step}.tickets.${index}.negativePrice`, 'The price can be a negative value');
              }
              if (+ticket.price < +ticket.deposit!) {
                set(errors, `${step}.tickets.${index}.price`, 'The deposit has to be less than or equal to the price');
              }
              if (ticket.quantity && ticket.quantity < 1) {
                set(errors, `${step}.tickets.${index}.quantity`, 'The quantity has to be left blank or above 0');
              }
            }
          }

          if (data.additionalEventItems) {
            for (let index = 0; index < (data.additionalEventItems ||[]).length; index++) {
              const additionalEventItem: AdditionalEventItem | null = data.additionalEventItems[index];
              if (!additionalEventItem) {
                continue;
              }

              if (!additionalEventItem.name || additionalEventItem.name.trim().length < 1) {
                set(errors, `${step}.additionalEventItems.${index}.name`, 'Name is required');
              }
              if (!additionalEventItem.price || additionalEventItem.price < 0) {
                set(errors, `${step}.additionalEventItems.${index}.price`, 'Price is required and can not be a negative value');
              }
            }
          }
          break;
        }
        case 'thumbnailAndDescription': {
          if (!data || (data && !data.thumbnail)) {
            break;
          }
          if (data.thumbnail && data.thumbnail.length > THUMBNAIL_SIZE_LIMIT_BASE64) {
            set(errors, `${step}.thumbnail`, `Image should be less then ${THUMBNAIL_SIZE_LIMIT} Mb`)
          }
          break;
        }
      }

      return errors
    },

    async handleChange(step, key, value, form){
      await Vue.nextTick();
      if (step === 'details' && key === 'FirstEvent'){
        if(!form.getData('details', 'LastEvent')){
          form.setData('details', 'LastEvent', value);
          await Vue.nextTick();
        }
      }

      if (step === 'eventSubType' && key === 'subType'){
        if (this.isThumbnailDefault(form.getData('thumbnailAndDescription', 'thumbnail'))){
          form.setData('thumbnailAndDescription', 'thumbnail', shabbatonThumbnail);
          await Vue.nextTick();
        }
        form.setData('registrationAndTickets', 'registrationAbsoluteRequired', false);
        await Vue.nextTick();
        form.setData('details', 'name', null);
        await Vue.nextTick();
        if (value){
          if (!['Friday Night Lights', 'Shabbat Shabang'].includes(value.description)){
            form.setData('registrationAndTickets', 'registrationAbsoluteRequired', true);
            await Vue.nextTick();
          }
          form.setData('staffAndChapter', 'allowChapters', false);
          await Vue.nextTick();
          form.setData('staffAndChapter', 'chapter', null);
          await Vue.nextTick();
          if (['Chapter Shabbaton', 'Friday Night Lights', 'Shabbat Shabang'].includes(value.description)){
            form.setData('staffAndChapter', 'allowChapters', true);
            await Vue.nextTick();
          }
        }
        if (this.isThumbnailDefault(form.getData('thumbnailAndDescription', 'thumbnail'))) {
          if (value.eventSubTypeId === 292) {
            form.setData('thumbnailAndDescription', 'thumbnail', fridayNightLightsThumbnail)
            await Vue.nextTick();
          } else {
            form.setData('thumbnailAndDescription', 'thumbnail', shabbatonThumbnail)
            await Vue.nextTick();
          }
        }
      }

      await Vue.nextTick();

      if ((step === 'eventSubType' && key === 'subType') || (step === 'staffAndChapter' && key === 'chapter')) {
        await Vue.nextTick();
        const eventSubType = form.getData('eventSubType', 'subType');
        if (eventSubType) {
          if (eventSubType.description.match(/(\w+) Regional/)){
            form.setData('details', 'name', `${eventSubType.description} ${new Date().getFullYear()}`);
            await Vue.nextTick();
          } else if (eventSubType.description === 'Chapter Shabbaton'){
            const chapter = form.getData('staffAndChapter', 'chapter');
            if (chapter) {
              form.setData('details', 'name', chapter.chapterName + ' Shabbaton');
              await Vue.nextTick();
            }
          }
        }
      }

      if (step === 'staffAndChapter' && key === 'chapter') {
        if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 414) {
          form.setData('thumbnailAndDescription', 'thumbnail', tyaThumbnail);
        } else if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 422) {
          form.setData('thumbnailAndDescription', 'thumbnail', bgCubedThumbnail);
        } else if(form.getData('thumbnailAndDescription', 'thumbnail') === tyaThumbnail || form.getData('thumbnailAndDescription', 'thumbnail') === bgCubedThumbnail) {
          form.setData('thumbnailAndDescription', 'thumbnail', shabbatonThumbnail);
        }
        await Vue.nextTick();
      }
    },

    submit (steps) {
      let requestObject = this.createEvent(steps);

      this.$emit('submit', requestObject);
    },

    createEvent(steps) {

      const {
        FirstEvent,
        LastEvent,
        StartTime,
        EndTime,
        TimeZone,
      } = steps.details

      DateTimezone.setGlobalTimezone(TimeZone);

      const StartDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const EndDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(EndTime),
        +EndTime.minutes,
      );

      const event = this.getEventFields(steps, StartDate.toISOString(), EndDate.toISOString());

      return { event };
    },

    getEventFields(steps, startDate, endDate) {
      const chapterID = (steps.staffAndChapter.chapter || {}).chapterId;
      const address = steps.details.Address || {};
      const fullStreamLink = steps.details.streamLink && !steps.details.streamSchedule
        ? steps.details.streamLink.match(/^http(s?):\/\//) ? steps.details.streamLink : 'https://' + steps.details.streamLink
        : null

      const {
        RegistrationCloseDate,
        RegistrationCloseTime,
        registrationRequired,
        registrationAbsoluteRequired
      } = steps.registrationAndTickets;

      let registrationCloseDate: string | null = null;
      if ((registrationRequired || registrationAbsoluteRequired) && RegistrationCloseDate) {
        registrationCloseDate = new DateTimezone.DateTimezone(
          RegistrationCloseDate.year,
          RegistrationCloseDate.month - 1,
          RegistrationCloseDate.day,
          convertTo24HourFormat(RegistrationCloseTime || { hours: 12, minutes: '00', ampm: 'AM' }),
          Number(RegistrationCloseTime && RegistrationCloseTime.minutes) || 0
        ).toISOString();
      }

      const eventFields: EventInput = {
        eventName: steps.details.name,
        EventSubTypeID: steps.eventSubType.subType.eventSubTypeId,
        chapterID,
        isRegionWide: !chapterID,
        schoolID: ((steps.school || {}).school || {}).schoolID,
        advisorID: steps.staffAndChapter.primaryStaff.staffID,
        AdvisorsParticipating: (steps.staffAndChapter.staff || []).map((s: Staff) => s.staffID),
        TeensParticipating: (steps.details.teens || []).map((t: CompactTeen) => t.personID),
        startDate: startDate,
        endDate: endDate,
        description: steps.thumbnailAndDescription.description,
        image: !this.isThumbnailDefault(steps.thumbnailAndDescription.thumbnail) ? steps.thumbnailAndDescription.thumbnail.replace(/data:image\/.*?;base64,/, '') : null,
        capacity: steps.options.capacity ? Number(steps.options.capacity) : null,
        trackId: (steps.options.eventTrack || {}).id || null,
        registrationRequired: steps.registrationAndTickets.registrationRequired || steps.registrationAndTickets.registrationAbsoluteRequired,
        requiresParentalApproval: steps.registrationAndTickets.requiresParentalApproval,
        registrationCloseDate: registrationCloseDate,
        scholarshipAvailable: steps.registrationAndTickets.canRequestScholarshipOnline,
        housingFlag: steps.registrationAndTickets.needHousing,
        registrationEnabled: steps.registrationAndTickets.canRegisterOnline,
        partialPaymentsAllowed: steps.registrationAndTickets.partialPayments,
        isSplitPaymentsAllowed: steps.registrationAndTickets.splitPayments,
        splitPaymentsMonths: steps.registrationAndTickets.splitPayments ? Number(steps.registrationAndTickets.splitPaymentsMonths) : undefined,
        Tickets: mapToEventTicketInputs(steps.registrationAndTickets.tickets),
        AdditionalEventItems: mapToAdditionalEventItemInputs(steps.registrationAndTickets.additionalEventItems),
        TimeZone: steps.details.TimeZone,
        ncsyWaiverRequired: steps.registrationAndTickets.ncsyWaiverRequired,
        requireManualRegistrationApproval: steps.registrationAndTickets.requiredManualRegistrationApproval,
        subtitle: steps.details.subtitle,
        internal: steps.options.hideFromWebAndMobile,
        address1: address.address,
        city: address.city,
        state: address.state,
        zip: address.zipCode,
        baseUrl: steps.options.isTYAEvent ? 2 : steps.options.isBGCubedEvent ? 4 : 0,
        isVirtual: !!steps.details.isVirtual,
        streamLink: fullStreamLink,
        zoomUserId: steps.details.streamSchedule && steps.details.zoomUser && (steps.details.zoomUser as ArrayElement<GetZoomUsersQuery['zoomUsers']>).id || null,
        zoomMeetingId: steps.details.streamSchedule && (steps.details.zoomMeetingId || '').replace(/ /g, '').replace(/-/g, '') || null,
        zoomPasscode: steps.details.streamSchedule && steps.details.zoomPasscode || null
      };

      return eventFields;
    },

    isThumbnailDefault(thumbnail) {
      return !thumbnail || thumbnail === shabbatonThumbnail || thumbnail === fridayNightLightsThumbnail;
    }
  }
})
