

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { directive as onClickaway } from 'vue-clickaway2';
import handlebars from 'handlebars';
import Sidebar from './components/SideBar';
import BuilderRow from './components/row.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import Editor from 'shared/components/Editor';
import Loading from 'shared/components/Loading.vue';
import { DraggableList, CloudinaryConfig, TemplateProps, EventProps } from './types';
import draggable from "vuedraggable";
import axios from 'axios';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { GetTemplateQuery, GetTemplateTypesQuery, PermissionEnum } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { Chapter, Me, Region } from 'shared/types';

type Template = GetTemplateQuery['template']
type TemplateType = ArrayElement<GetTemplateTypesQuery['templateTypes']>

interface TemplateGroup {
  [key: string]: string | TemplateType;
  recipient: string;
  templateType: TemplateType
}
@Component({
    components: {
        Sidebar,
        BuilderRow,
        draggable,
        ChapterLookup,
        Editor,
        ScrollablePanel,
        Loading
    },
    directives: {
      onClickaway,
      fullheight
    }
})
export default class EmailBuilder extends Vue {
    @Prop() source!: string;
    @Prop() region!: Region;
    @Prop() eventId!: number;
    @Prop() category!: number;
    @Prop() templateProps!: TemplateProps;
    @Prop() eventProps!: EventProps;
    @Prop() templateTypes!: TemplateType[];
    @Prop() user!: Me;
    @Prop() template!: Template;
    @Prop() overrides!: Template[];
    @Prop() fetchingTemplate!: boolean;
    @Prop() selectedTemplateTypeId!: number;
    @Prop() gettingTemplateById!: boolean;

    dragable: boolean = true;
    editorMode: boolean = false;
    editingDivider: boolean = false;
    editingButton: boolean = false;
    selectedRowId: number | null = null;
    isDragging: boolean = false;
    delayedDragging: boolean = false;
    selectedRecipient: string | null = null;
    selectedChapter: Chapter  | null = null;
    selectedTemplateGroup: TemplateType | TemplateGroup[] | null = null;
    content: DraggableList[] = [];
    convertedFile: { file ? : File, base64 ? : string } = {};
    indexOftheLastAddedElement: number | null = null;
    dynamicKey: number = 1;
    uploadingImage: boolean = false;
    cloudinary: CloudinaryConfig = {
       uploadPreset: 'bjjftxma',
       apiKey: '282799195721725',
       cloudName: 'ncsy',
       url: 'https://api.cloudinary.com/v1_1/ncsy/upload'
     }
    get userPermissions () {
      return ((this.user && this.user.AdvisorRegions || []).find(ar => ar.Region.regionId === this.region.regionId) || {claims: []}).claims
    }
    get overridesTemplates () {
      return this.overrides
    }
    get currentChapterId () {
      return (this.selectedChapter && this.selectedChapter.chapterId) || (this.eventProps && this.eventProps.chapterId) || this.templateProps.chapterId
    }
    get editedTemplate() {
        return {
            ...this.currentTemplate,
            body: this.content.map(x => x.rowContent).join('<!-- split Iceburg3 -->')

        }
    }
    get selectedTemplateType() {
        if (Array.isArray(this.selectedTemplateGroup)) {
          if(this.selectedRecipient) {
            return this.selectedTemplateGroup.find(x => x.recipient === this.selectedRecipient)!.templateType
          }
        } else {
          return this.selectedTemplateGroup
        }
  }
    get selectedTemplateTypeIsTYA () {
      if (this.eventProps && this.eventProps.isTYA) {
        return true;
      }
      if (Array.isArray(this.selectedTemplateGroup)) {
        return this.selectedTemplateGroup.filter(f => f.templateType.key!.includes('TorahYouthGroup')).length > 0;
      }

      return this.selectedTemplateGroup?.key!.includes('TorahYouthGroup') || false;
    }
    get selectedTemplateTypeIsBGCubed () {
      if (this.eventProps && this.eventProps.isBGCubed) {
        return true;
      }
      if (Array.isArray(this.selectedTemplateGroup)) {
        return this.selectedTemplateGroup.filter(f => f.templateType.key!.includes('BGCubed')).length > 0;
      }

      return this.selectedTemplateGroup?.key!.includes('BGCubed') || false;
    }
    get dragOptions() {
        return {
            disabled: !this.dragable,
            ghostClass: "ghost"
        };
    }
    get recipientOptions(): string[] {
        if (Array.isArray(this.selectedTemplateGroup) && this.selectedTemplateGroup.length) {
            return this.selectedTemplateGroup.map(t => t.recipient);
        }
        return []
    }
    get showContent() {
        return this.selectedTemplateGroup && (Array.isArray(this.selectedTemplateGroup) ? !!this.selectedRecipient : true)
    }
    get templateGroups() {
        const templateGroups: { [key: string]: TemplateGroup[] | TemplateType } = {};
        const userPermissionPredicate: (templateType: TemplateType) => boolean = (templateType: TemplateType) => includes(this.userPermissions, PermissionEnum.AccessAdvisorApplicationAdminSite) ? true : templateType.category !== 5;
        (filter(this.templateTypes, userPermissionPredicate)).map(t => {
            const parts = t.key!.replace('_Email', '').replace(/_/g, ' ').split(' ');
            if (['Teen', 'Region', 'Parent'].includes(parts[0])) {
                const recipient = parts.shift();
                if (!templateGroups[parts.join(' ')]) {
                    templateGroups[parts.join(' ')] = [];
                }

                (templateGroups[parts.join(' ')] as  TemplateGroup[]).push({
                    recipient: recipient!,
                    templateType: t
                });
            } else {
                templateGroups[parts.join(' ')] = t;
            }
        })
        return templateGroups
    }
    get templateValid() {
      return !this.content.some(row => (!!row.invalid))
    }
    get currentTemplate(): Template {
        return this.template;
    }

    async draggingEndHandler(event: any) {
        if (event.added) {
            const { newIndex, element, element: { text } } = event.added;
            this.indexOftheLastAddedElement = newIndex
            if (text === 'Image') {
                this.content.splice(this.indexOftheLastAddedElement!, 1)
                // @ts-ignore
                this.$refs.file.click()
                return
            }
            if (text === 'Text' || text === 'Button') {
                this.editRowHandler(newIndex)
            }
            this.isDragging = false
        }
    }
    uploadImageHandler({ target: { files: [file] } }: any) {
        this.convertedFile = {};
        if (!file) {
            return;
        }

        this.uploadToCloudinary(file);
        this.uploadingImage = true;
    }
    async uploadToCloudinary(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {

            if (typeof this.indexOftheLastAddedElement === 'number') {

                const formData = new FormData()
                formData.append('file', file);
                formData.append('upload_preset', this.cloudinary.uploadPreset);

                const {
                    data,
                    data: {
                        secure_url
                    }
                } = await axios.post(this.cloudinary.url, formData);
                this.content.splice(this.indexOftheLastAddedElement!, 0, {
                    rowId: Math.max(...this.content.map(x => x.rowId)) + 1,
                    rowContent: `<img src="${secure_url}" alt="${file.name}" />`,
                    rowType: 'text',
                    rowStyle: ''
                })
                this.convertedFile = {};
                this.uploadingImage = false;
            }
        };
        reader.onerror = (error) => {
            console.error('Error: ', error);
        };
    }
    setDividerStyle(value: string, attr: string) {
        const rowIndex = this.content.findIndex(x => x.rowId === this.selectedRowId)
        if (rowIndex > -1) {
            if (attr === 'height') {
                this.content[rowIndex].rowStyle = `height: ${value}px;`
                this.content[rowIndex].dividerHeight = value;
            }
            if (attr === 'color') {
                const attrIndex = this.content[rowIndex].rowContent.split(';').findIndex(x => x.includes('border-top'))
                const content = this.content[rowIndex].rowContent.split(';')
                content[attrIndex] = `border-top: 1px solid ${value}`;
                this.content[rowIndex].rowContent = content.join(';')
                this.content[rowIndex].dividerColor = value;
            }
        }
    }
    setButtonStyles(value: string, attr: string) {
        const rowIndex = this.content.findIndex(x => x.rowId === this.selectedRowId)
        if (rowIndex > -1) {
            if (attr === 'text') {
                const content = this.content[rowIndex].rowContent.split('> ');
                content[1] = `${value} </a>`
                this.content[rowIndex].rowContent = content.join('> ')
                this.content[rowIndex].buttonText = value;
            }
            if (attr === 'background-color') {
                const attrIndex = this.content[rowIndex].rowContent.split(';').findIndex(x => x.includes('background-color'))
                const content = this.content[rowIndex].rowContent.split(';')
                content[attrIndex] = `background-color: ${value};`;
                this.content[rowIndex].rowContent = content.join(';')
                this.content[rowIndex].buttonColor = value;
            }
            if (attr === 'link') {
                const attrIndex = this.content[rowIndex].rowContent.split(' ').findIndex(x => x.includes('href'))
                const content = this.content[rowIndex].rowContent.split(' ')
                content[attrIndex] = `href= "${value}" `;
                this.content[rowIndex].rowContent = content.join(' ')
                this.content[rowIndex].buttonLink = value;
            }
            if (attr === 'position') {
                if (value === 'left') {
                    this.content[rowIndex].rowStyle = 'float: left;'
                }
                if (value === 'right') {
                    this.content[rowIndex].rowStyle = 'float: right;'
                }
                if (value === 'middle') {
                    this.content[rowIndex].rowStyle = 'display: flex; justify-content: center;'
                }
                this.content[rowIndex].buttonPosition = value;
            }
        }
    }
    applyEditorChanges(data: string) {
        const index = this.content.findIndex(x => x.rowId === this.selectedRowId);
        if (index !== -1) {
            const row = {...this.content[index], invalid: !this.validateTemplate(data), rowContent: data};
            this.$set(this.content, index, row);
        };
    }
    removeRowHandler(event: Event, index: number) {
        this.content.splice(index, 1)
    }
    clickAwayHandler() {
        const selectedRow = this.content.find(x => x.rowId === this.selectedRowId);
        const elementType = selectedRow && selectedRow.text;

        if (elementType === 'Divider' && this.editingDivider) return
        if (elementType === 'Button' && this.editingButton) return

        else {
            this.editorMode = false;
            this.dragable = true;
            this.selectedRowId = null;
        }

    }
    editRowHandler(index: number) {
        const selectedRow = this.content[index];
        const elementType = selectedRow.text;
        this.selectedRowId = selectedRow.rowId;

        if (elementType === 'Divider') return this.editingDivider = !this.editingDivider;
        else if (elementType === 'Button') return this.editingButton = !this.editingButton;

        else {
            this.editorMode = true;
            this.editingDivider = false;
            this.dragable = false;
        }
    }
    onMove({ relatedContext, draggedContext }: any) {
        const relatedElement = relatedContext.element;
        const draggedElement = draggedContext.element;
        return (
            (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        );
    }
    validateTemplate(data: string) {
        let validation = true;
        try {
            const parsed = handlebars.parseWithoutProcessing(data);
            validation = !!parsed
        } catch(e) {
            validation = false;
        }
        return validation;
    }
    selectedTemplateGroupWatcher() {
        this.selectedRecipient = null;
        if (!Array.isArray(this.selectedTemplateGroup)) {
            this.$emit('selectedTemplateTypeId', this.selectedTemplateGroup && this.selectedTemplateGroup.templateTypeId)
        }
    }
    selectedRecipientWatcher(event: Event) {
        const selectedRecipient = (event.target as HTMLInputElement).value;
        if (selectedRecipient) {
            const selectedTemplateGroup = this.selectedTemplateGroup && Array.isArray(this.selectedTemplateGroup) && this.selectedTemplateGroup.find(x => x.recipient === selectedRecipient);
            this.$emit('selectedTemplateTypeId', selectedTemplateGroup && selectedTemplateGroup.templateType.templateTypeId)
        }
    }
    startDragging() {
        this.isDragging = true;
        this.delayedDragging = true;
        this.$nextTick(() => {
            this.delayedDragging = false;
        });
    }
    selectedOverrideTemplate(event: number) {
        this.$emit('templateProps', {chapterId: null, templateId: event})
    }
    
    @Watch('template')
    onTemplateChange() {
        this.content = [
            ...(
                this.template && this.template.body && this.template.body.split('<!-- split Iceburg3 -->').filter(Boolean)
                    .map((x: string, i: number) => ({
                        rowId: i + 1,
                        rowContent: x,
                        rowType: 'text',
                        rowStyle: '',
                        invalid: !this.validateTemplate(x)
                    })) || [])
        ];
    }
}

