
import Vue from 'vue'
import UIInput from 'shared/ui/forms/Input.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { AdditionalEventItem } from 'shared/generated/graphql-types';

library.add(faTimes);
export default Vue.extend({
  name: 'UIAdditionalEventItem',
  data () {
    return {
      additionalEventItem: this.value
    }
  },
  props: {
    value: {
      type: Object as () => AdditionalEventItem
    }
  },
  components: {
    UIInput
  },
  watch: {
    value (value: AdditionalEventItem) {
      this.additionalEventItem = value
    }
  }
})
