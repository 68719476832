
import Vue from 'vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { Data, Methods, Computed, Props } from './types';
import UIInput from 'shared/ui/forms/Input.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import EmailInput from 'shared/components/EmailInput';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import QuickAddParent from 'shared/components/QuickAddParent';
import {
  phoneInternational,
  validateName,
  validateNumber,
  splitName,
  phoneFormat
} from 'shared/util';
import { EntityUnionEnum, Gender, UpdateTeenField } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'UpdateParentInfo',
  components: {
    UIInput,
    Modal,
    PhoneInput,
    EmailInput,
    AutoSaveField,
    QuickAddParent
  },
  props: {
    teen: {},
    connectParentToTeen: {},
    connectingParentToTeen: {},
    updateTeen: {},
    updatingTeen: {},
    addParentToFamily: {},
    addingParentToFamily: {}
  },
  data() {
    return {
      Gender,
      EntityUnionEnum,
      updateTeenField: UpdateTeenField,
      teenCopy: Object.assign({}, this.teen),
      showAddFather: false,
      showAddMother: false
    };
  },
  computed: {
    isTeenHaveParentsInfo() {
      return Boolean(this.teen.mother || this.teen.father);
    }
  },
  methods: {
    validateName,
    validateNumber,
    phoneInternational,
    async connectParentToTeenHandler(parent) {
      if (parent.id)
        await this.connectParentToTeen(
          { parentId: parent.id, childId: this.teen.personId },
          parent
        );
    },
    phoneFormat(phoneNumber: string) {
      if (!phoneNumber) return;
      return phoneFormat(phoneNumber, this.teen.country || '');
    },
    splitName(name: string) {
      if (!name) {
        return { firstName: '', lastName: '' };
      }
      return splitName(name);
    },
    async update(args: SaveEventArgs<{ fieldName: UpdateTeenField; value: string }>) {
      // TODO: dont save if there is no value atleast until this command will support that - https://app.asana.com/0/82580073031913/1134617948128938.
      if (!args.transformed.value) return;
      await this.updateTeen({
        personId: this.teen.personId,
        fieldName: args.transformed.fieldName,
        value: String(args.transformed.value),
        viewModel: this.teenCopy
      });
    },
    async updateParentField(
      args: SaveEventArgs<{ fieldName: UpdateTeenField; value: string; parentId?: number }>
    ) {
      const { parentId, value } = args.transformed;
      if (!parentId) {
        return;
      }
      args.transformed.value = JSON.stringify({ parentId, value });
      return await this.update(args);
    },
    async handleAssociate(parent) {
      if (!this.teen.fatherId && !this.teen.motherId) {
        await this.connectParentToTeenHandler(parent);
      } else {
        await this.addParentToFamily({
          familyId: this.teen.primaryFamily?.id || this.teen.families[0].id,
          personId: parent.id,
          gender: parent.gender
        });
      }
      this.showAddFather = false;
      this.showAddMother = false;
    }
  },
  watch: {
    teen(newTeen, oldTeen) {
      if (newTeen.fatherId !== oldTeen.fatherId || newTeen.motherId !== oldTeen.motherId) {
        this.teenCopy = newTeen;
      }
    }
  }
});
