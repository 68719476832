
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import TeenViewModel from './shared/TeenViewModel';
import { RouterLink } from 'vue-component-router';
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { phoneFormat, getCurrentEmail } from 'shared/util';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import Loading from 'shared/components/Loading.vue';
import { ConflictResolutions } from './types';
import { Gender, GetTeenDuplicatesQueryVariables, GetTeensByIdQuery, MergeTeenDuplicatesMutationVariables, StatusType } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Family = ArrayElement<ArrayElement<GetTeensByIdQuery['teensByID']>['Person']['ChildOf']>

@Component({
  name: "SelectDuplicatesFields",
  components: {
      BorderedList,
      UIButton,
      Modal,
      Loading,
      RouterLink
  },
  methods: {
    phoneFormat
  }
})

export default class extends Vue {

  @Prop() teenDuplicates!: TeenViewModel[];
  @Prop() showMergeScreen!: boolean;
  @Prop() merging!: boolean;
  @Prop() mergeTeenDuplicates!: (args: MergeTeenDuplicatesMutationVariables) => void;
	@Prop() regionId!: number;
  @Prop() teenIds!: number[];

  isActive: boolean = false;
	error: boolean = false;
  mergeError: boolean = false;
  selectedFamilies: string[] = [];
  selectedTeenData: ConflictResolutions = {
		schoolId: undefined,
		cellphoneId: undefined,
		landlineId: undefined,
		emailId: undefined,
    addressId: undefined,
    gender: undefined,
    birthDate: undefined,
    graduationYear: undefined,
    vegetarian: undefined,
    isJewish: undefined,
    gapYearId: undefined,
    shanaBetId: undefined,
    collegeId: undefined,
    chapterId: undefined,
    medicines: undefined,
    firstName: undefined,
    lastName: undefined,
    familyIds: undefined
  };

  get sortedTeens() {
    return this.teenDuplicates.sort((a, b) => {
      const firstDateUpdated = new Date(a.dateUpdated).getTime();
      const secondDateUpdated = new Date(b.dateUpdated).getTime();
      if (firstDateUpdated === secondDateUpdated) {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
      return secondDateUpdated - firstDateUpdated;
    });
  }

  get remainingConflictsCount () {
    const resolvedConflicts = Object.keys(this.selectedTeenData).filter(x => typeof this.selectedTeenData[x] === 'boolean' || this.selectedTeenData[x]).length;
    const count = this.uniqueFieldsCount - resolvedConflicts
    return count > 0 ? count : 0;
  }

  get displayDateUpdated() {
    return this.teenDuplicates.filter(t => t.dateUpdated).length;
  }

  get displayNames() {
    return this.extractUniqueValues(this.teenDuplicates, 'name', 'name').length > 1
  }

  get uniqueValues() {
    return {
      uniqueAddresses: this.extractUniqueValues(this.teenDuplicates, 'address', 'address.fullAddress'),
      uniqueEmailsAddresses: this.extractUniqueValues(this.teenDuplicates, 'email', 'email.email'),
      uniqueSchools: this.extractUniqueValues(this.teenDuplicates, 'school', 'school.id'),
      uniqueCellphoneNumber: this.extractUniqueValues(this.teenDuplicates, 'cellphone', 'cellphone.phoneNumber'),
      uniqueLandlineNumber: this.extractUniqueValues(this.teenDuplicates, 'landline', 'landline.phoneNumber'),
      uniqueGender: this.extractUniqueValues(this.teenDuplicates, 'gender', 'gender'),
      uniqueBirthDates: this.extractUniqueValues(this.teenDuplicates, 'birthDate', 'birthDate'),
      uniqueGraduationYears: this.extractUniqueValues(this.teenDuplicates, 'graduationYear', 'graduationYear'),
      uniqueVegetarians: this.extractUniqueValues(this.teenDuplicates, 'vegetarian', 'vegetarian'),
      uniqueIsJewish: this.extractUniqueValues(this.teenDuplicates, 'isJewish', 'isJewish'),
      uniqueGapYears: this.extractUniqueValues(this.teenDuplicates, 'gapYear', 'gapYear.id'),
      uniqueShanaBets: this.extractUniqueValues(this.teenDuplicates, 'shanaBet', 'shanaBet.id'),
      uniqueColleges: this.extractUniqueValues(this.teenDuplicates, 'college', 'college.id'),
      uniqueChapters: this.extractUniqueValues(this.teenDuplicates, 'chapter', 'chapter.chapterId'),
      uniqueMedicines: this.extractUniqueValues(this.teenDuplicates, 'medicines', 'medicines'),
      uniqueFirstName: this.extractUniqueValues(this.teenDuplicates, 'firstName', 'firstName'),
      uniqueLastName: this.extractUniqueValues(this.teenDuplicates, 'lastName', 'lastName'),
      uniqueFamilies: this.extractUniqueValues(this.teenDuplicates, 'families', 'families', 'id')
    }
  }

  get uniqueFieldsCount() {
    const uniqueValues = this.uniqueValues

    return Object.keys(uniqueValues)
    .filter(key => uniqueValues[key as keyof typeof uniqueValues].length > 1).length
  }

	get targetTeen () {
    if (!this.teenDuplicates.length) return;
		const targetId = Math.max(...this.teenDuplicates.map(x=> x.personId));
    let targetTeen = this.teenDuplicates.find(x => x.personId === targetId)!;
    if(!targetTeen.hasProfilePicture) {
      targetTeen = (this.teenDuplicates.find(t => t.hasProfilePicture) || targetTeen)!;
    }
    return targetTeen;
	}

	get deleteIds () {
    if (!this.targetTeen) return [];
		return this.teenDuplicates.map(x => x.personId).filter(x => x !== this.targetTeen!.personId);
  }

  get isRegisteredOnSameEvent() {
    return this.teenDuplicates
      // flatten data into a single array, taking only the needed pieces of data
      .reduce((prev, curr) => {
        return prev.concat(curr.Registrations.map(r => ({ eventId: r.eventId, status: r.status })))
      }, [] as Array<{eventId: number, status: StatusType}>)
      // We don't care about cancelled registrations
      .filter(r => r.status !== StatusType.Cancelled)
      .find((r, idx, arr) =>
        arr.map(x => x.eventId).indexOf(r.eventId) !== idx // Make sure we skip the first registration for a given event
    );
  }
  formatBirthDate(date: string) {
    if (!date) {
      return 'Not Available'
    }
    const birthDate = new Date(date);
    return `${birthDate.getUTCMonth() + 1}/${birthDate.getUTCDate()}/${birthDate.getUTCFullYear()}`
  }
  get remainingTeens () {
    if (!this.targetTeen) return [];
		return filter(this.teenDuplicates, x => x.personId !== this.targetTeen!.personId);
  }
	async mergeTeensHandler() {
    if (!this.targetTeen) return;
    if (this.remainingConflictsCount > 0) {
      return this.error = true;
    }
		this.error = false;

    const {
      uniqueAddresses,
      uniqueCellphoneNumber,
      uniqueLandlineNumber,
      uniqueEmailsAddresses,
      uniqueSchools,
      uniqueGender,
      uniqueBirthDates,
      uniqueGraduationYears,
      uniqueVegetarians,
      uniqueIsJewish,
      uniqueGapYears,
      uniqueShanaBets,
      uniqueColleges,
      uniqueChapters,
      uniqueMedicines,
      uniqueFirstName,
      uniqueLastName,
      uniqueFamilies
    } = this.uniqueValues;

    const addressId = uniqueAddresses.length > 1 ? this.selectedTeenData.addressId : (this.targetTeen.address || (find(this.remainingTeens ,x => !!x.address) || {address: {id: null}}).address || { id: null }).id;

    const cellphoneId = uniqueCellphoneNumber.length > 1 ? this.selectedTeenData.cellphoneId : (this.targetTeen.cellphone || (find(this.remainingTeens ,x => !!x.cellphone) || {cellphone: {id: null}}).cellphone || { id: null }).id;

    const landlineId = uniqueLandlineNumber.length > 1 ? this.selectedTeenData.landlineId : (this.targetTeen.landline || (find(this.remainingTeens ,x => !!x.landline) || {landline: {id: null}}).landline || { id: null }).id;

    const emailId = uniqueEmailsAddresses.length > 1 ? this.selectedTeenData.emailId : (this.targetTeen.email || (find(this.remainingTeens ,x => !!x.email) || {email: {id: null}}).email || { id: null }).id;

    const schoolId = uniqueSchools.length > 1 ? this.extractId(this.selectedTeenData.schoolId!) : (this.targetTeen.school || (find(this.remainingTeens ,x => !!x.school) || {school: {id: null}}).school || { id: null }).id;

    const gender = uniqueGender.length > 1 ? this.selectedTeenData.gender : this.targetTeen.gender

    const birthDate = uniqueBirthDates.length > 1 ? this.selectedTeenData.birthDate : this.targetTeen.birthDate || (this.remainingTeens.find(x => !!x.birthDate) || {birthDate: null}).birthDate;

    const graduationYear = uniqueGraduationYears.length > 1 ? this.selectedTeenData.graduationYear : this.targetTeen.graduationYear || (this.remainingTeens.find(x => !!x.graduationYear) || {graduationYear: null}).graduationYear;

    const vegetarian = uniqueVegetarians.length > 1 ? this.selectedTeenData.vegetarian : this.targetTeen.vegetarian || (this.remainingTeens.find(x => x.vegetarian !== null) || {vegetarian: null}).vegetarian;

    const isJewish = uniqueIsJewish.length > 1 ? this.selectedTeenData.isJewish : this.targetTeen.isJewish || (this.remainingTeens.find(x => x.isJewish !== null) || {isJewish: null}).isJewish;

    const gapYearId = uniqueGapYears.length > 1 ? this.extractId(this.selectedTeenData.gapYearId!) : (this.targetTeen.gapYear || (find(this.remainingTeens ,x => !!x.gapYear) || {gapYear: {id: null}}).gapYear || { id: null }).id;

    const shanaBetId = uniqueShanaBets.length > 1 ? this.extractId(this.selectedTeenData.shanaBetId!) : (this.targetTeen.shanaBet || (find(this.remainingTeens ,x => !!x.shanaBet) || {shanaBet: {id: null}}).shanaBet || { id: null }).id;

    const collegeId = uniqueColleges.length > 1 ? this.extractId(this.selectedTeenData.collegeId!) : (this.targetTeen.college || (find(this.remainingTeens ,x => !!x.college) || {college: {id: null}}).college || { id: null }).id;

    const chapterId = uniqueChapters.length > 1 ? this.extractId(this.selectedTeenData.chapterId!) : (this.targetTeen.chapter || (find(this.remainingTeens ,x => !!x.chapter) || {chapter: {chapterId: null}}).chapter || { chapterId: null }).chapterId;

    const medicines = uniqueMedicines.length > 1 ? this.selectedTeenData.medicines : this.targetTeen.medicines || (this.remainingTeens.find(x => !!x.medicines) || {medicines: null}).medicines;

    let familyIds: number[] = [];

    if (uniqueFamilies.length > 1) {
      familyIds = this.extractIds(this.selectedTeenData.familyIds!)
    } else if (this.targetTeen.families.length > 0) {
      familyIds.push(this.targetTeen.families[0]!.id)
    } else if (find(this.remainingTeens, x=> x && x.families.length > 0)) {
      familyIds.push(find(this.remainingTeens, x=> x && x.families.length > 0)!.families[0]!.id)
    }

    const firstName = uniqueFirstName.length > 1 ? this.selectedTeenData.firstName : this.targetTeen.firstName;

    const lastName = uniqueLastName.length > 1 ? this.selectedTeenData.lastName : this.targetTeen.lastName;

	  const { personId: targetId } = this.targetTeen;
    const sourceIds = this.deleteIds;

    try {
		await this.mergeTeenDuplicates({
      input: {
        targetId,
        sourceIds,
        conflictResolutions: {
          schoolId,
          addressId,
          emailId,
          cellphoneId,
          landlineId,
          gender: (gender === 'Male' || gender === 'Female') ? gender === 'Male' ? Gender.Male : Gender.Female : undefined,
          birthDate,
          graduationYear,
          vegetarian,
          isJewish,
          gapYearId,
          shanaBetId,
          collegeId,
          chapterId,
          medicines,
          firstName,
          lastName,
          familyIds
          }
        }
      });
    } catch (e) {
      console.log(e);
      this.mergeError = true;
      return;
    }
    this.$emit('update:showMergeScreen', false);
    this.$emit('merged', targetId);
  }

  extractUniqueValues(array: any[], initialFilter: string, mapTo: string, innerMap?: string): any[] {
    return uniq(
      flatten(
        array.filter(a => typeof get(a, initialFilter) === 'boolean' ? this.hasBooleanValue(get(a, initialFilter) as boolean) : get(a, initialFilter))
        .map(a =>{
          if(isArray(get(a, mapTo))){
            return this.extractUniqueValues(get(a, mapTo)!, innerMap!, innerMap!);
          }
          return typeof get(a, mapTo) === 'string' ? (get(a, mapTo) as string).trim().toLowerCase() : get(a, mapTo)
        })
      )
      .filter(this.allBooleanValid)
    );
  }

  hasBooleanValue(value: boolean | null | undefined) {
    return value !== null && value !== undefined
  }

  formatBooleanValue(value: boolean) {
    return value ? 'True' : 'False';
  }

  extractId(value: string){
    return +value.split(' ')[0]
  }

  extractIds(values: string[])
  {
    let ids = values.map((str) => parseInt(str, 10));
    return ids;
  }

  isSelectedFamily(value: any)
  {
    let isSelected = this.selectedTeenData.familyIds?.includes(value);
    return isSelected;
  }

  handleFamilyChange(value: any)
  {
    let familyIds = this.selectedTeenData.familyIds ?? [];
    if(familyIds.includes(value))
    {
      const index = familyIds.indexOf(value);
      if (index > -1)
      {
        familyIds.splice(index, 1);
      }
    }
    else
    {
      familyIds.push(value);
    }
    this.selectedTeenData.familyIds = familyIds;
  }

  formatFamily(family: Family) {
    const parents = []
    if (family.Father) { parents.push(family.Father)}
    if (family.Mother) { parents.push(family.Mother)}
    if (parents.length === 0) {
      return [`No Name On File`]
    }
    return parents.map(p => {
      const email = getCurrentEmail(p.EmailAddresses || []);
      return `${p.firstName} ${p.lastName} - ${email ? email.email : 'No Email Availble'}`
    })
  }

  allBooleanValid(value: any) {
    return typeof value === "boolean" ? this.hasBooleanValue(value) : value
  }

  formatDate(date: string) {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const newDate = new Date(date)
    return newDate.toLocaleDateString("en-US", options);
  }
}

