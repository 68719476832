
import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import { MutateResult, useApolloClient } from '@vue/apollo-composable';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import Datepicker from 'shared/components/DatePicker.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import Timepicker from 'shared/components/Timepicker.vue';
import DateTimezone from 'date-timezone';
import tz from 'timezone/loaded';
import { ArrayElement, SimpleDate } from 'shared/util/types';
import { Filters, Dates } from '../../types';
import { getZoomTimeZone } from 'shared/util';
import { calculateEventDuration } from '../../../../../shared/util';
import {
  AddEventToSeriesMutation,
  EventInput,
  GetSingleSeriesQuery,
  RecurrenceType,
  UpdateZoomMeetingDocument,
  UpdateZoomMeetingMutation,
  UpdateZoomOccurrenceDocument,
  UpdateZoomOccurrenceMutation,
} from 'shared/generated/graphql-types';

type Series = GetSingleSeriesQuery['singleSeries'];
type Event = ArrayElement<Series['Events']>;

interface FormData {
  newDate: SimpleDate | null;
  navigate?: boolean;
}

interface Props {
  series: Series;
  addEventToSeries: (
    event: EventInput,
    targetEvent: Event
  ) => MutateResult<AddEventToSeriesMutation>;
  router: { history: { push: (location: string) => void } };
  addingEvent: boolean;
  dates: Dates;
  filters: Filters;
  handleLoading: (value: boolean) => void;
}

interface Computed {
  disabledDates: Date[];
  targetEvent: Event;
  upcomingEvents: Event[];
  startDateTime: { hours: number; minutes: number };
  endDateTime: { hours: number; minutes: number };
}

interface Methods {
  validate: (values: FormData) => { [key: string]: string };
  addEventHandler: (
    callbackArgs: SubmitCallbackArgs<FormData>
  ) => Promise<void>;
}

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'AddEventToSeriesForm',
  components: {
    UIButton,
    UISelect,
    Datepicker,
    Timepicker,
    SimpleForm,
    Field,
    Error,
    UIInput,
  },
  props: {
    series: {},
    addEventToSeries: {},
    router: {},
    addingEvent: {},
    dates: {},
    filters: {},
    handleLoading: {},
  },
  computed: {
    disabledDates() {
      return this.series.Events.map((x) => new Date(x.startDate));
    },
    targetEvent() {
      const events: Event[] = this.series.Events;
      const upcomingEvents = this.upcomingEvents;
      if (upcomingEvents.length) {
        return upcomingEvents[0];
      }
      return this.series.Events[this.series.Events.length - 1];
    },
    upcomingEvents() {
      return this.series.Events.filter(
        (x) => new Date(x.startDate) > new Date()
      ).sort((a, b) => {
        const dateA: Date = new Date(a.startDate);
        const dateB: Date = new Date(b.startDate);
        return +dateA - +dateB;
      });
    },
    startDateTime() {
      DateTimezone.setGlobalTimezone(this.targetEvent.TimeZone);
      const startDate = new DateTimezone.DateTimezone(
        this.targetEvent.startDate
      );
      return {
        hours: startDate.getHours(),
        minutes: startDate.getMinutes(),
      };
    },
    endDateTime() {
      DateTimezone.setGlobalTimezone(this.targetEvent.TimeZone);
      const endDate = new DateTimezone.DateTimezone(this.targetEvent.endDate);
      return {
        hours: endDate.getHours(),
        minutes: endDate.getMinutes(),
      };
    },
  },
  methods: {
    validate(values) {
      let errors: { [key: string]: string } = {};
      if (!values.newDate) errors['newDate'] = 'Please select a date';
      return errors;
    },
    async addEventHandler(callbackArgs) {
      const { client } = useApolloClient()

      if ("errors" in callbackArgs) return;
      try {
        DateTimezone.setGlobalTimezone(this.targetEvent.TimeZone);
        const { year, month, day } = callbackArgs.values!.newDate!;
        const newStartDate = new DateTimezone.DateTimezone(
          year,
          month - 1,
          day,
          this.startDateTime.hours,
          this.startDateTime.minutes
        );
        const newEndDate = new DateTimezone.DateTimezone(
          year,
          month - 1,
          day,
          this.endDateTime.hours,
          this.endDateTime.minutes
        );

        if (!callbackArgs.values.navigate) this.$emit('submitted');

        this.handleLoading(true);

        const result = await this.addEventToSeries(
          {
            eventName: this.targetEvent.eventName,
            seriesId: this.targetEvent.seriesId,
            EventSubTypeID: this.targetEvent.EventSubType.eventSubTypeId,
            address1: this.targetEvent.address1,
            city: this.targetEvent.city,
            state: this.targetEvent.state,
            zip: this.targetEvent.zip,
            recurInterval: this.targetEvent.recurInterval,
            advisorID: (this.targetEvent.Owner || { staffID: null }).staffID,
            regionId: this.targetEvent.regionId,
            schoolID: (this.series.School || { schoolID: null }).schoolID,
            chapterID: this.targetEvent.chapterId,
            TimeZone: this.targetEvent.TimeZone,
            trackId: (this.targetEvent.EventTrack || { id: null }).id,
            isRegionWide: this.targetEvent.isRegionWide,
            internal: this.targetEvent.internal,
            baseUrl: this.targetEvent.baseUrl,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString(),
            description: this.targetEvent.description,
            subtitle: this.targetEvent.subtitle,
            //image: ?????,
            isVirtual: this.targetEvent.isVirtual || false,
            zoomMeetingId: this.targetEvent.zoomMeetingId || null,
            // These are registration based props, we can probably leave them off
            // registrationRequired: this.targetEvent.registrationRequired,
            // registrationCloseDate: this.targetEvent.registrationCloseDate,
            // scholarshipAvailable: this.targetEvent.scholarshipAvailable,
            // housingFlag: this.targetEvent.housingFlag,
            // registrationEnabled: this.targetEvent.registrationEnabled,
            // partialPaymentsAllowed: this.targetEvent.partialPaymentsAllowed,
            // isSplitPaymentsAllowed: this.targetEvent.isSplitPaymentsAllowed,
            // Tickets: this.targetEvent.Tickets,
            // AdditionalEventItems: this.targetEvent.AdditionalEventItems,
            // ncsyWaiverRequired?: this.targetEvent.ncsyWaiverRequired,
            // requireManualRegistrationApproval: this.targetEvent.requireManualRegistrationApproval,
            // splitPaymentsMonths: this.targetEvent.splitPaymentsMonths,
          },
          this.targetEvent
        );
        const scheduleEvent = result?.data?.scheduleEvent;
        const events: Event[] = this.upcomingEvents.slice();
        if (scheduleEvent) {
          events.push(scheduleEvent);
          events.sort((a, b) => {
            const dateA: Date = new Date(a.startDate);
            const dateB: Date = new Date(b.startDate);
            return +dateA - +dateB;
          });

          if (this.targetEvent.isVirtual && this.targetEvent.zoomMeetingId) {
            let zoomStartDate = events[0].startDate;
            let zoomTimeZone = 'UTC';
            if (getZoomTimeZone(events[0].TimeZone) !== 'UTC') {
              zoomTimeZone = getZoomTimeZone(events[0].TimeZone);
              zoomStartDate = tz(
                tz(events[0].startDate as string),
                '%FT%T',
                zoomTimeZone
              );
            }
            const duration = calculateEventDuration(
              events[0].startDate as string,
              events[0].endDate as string
            );

            // reset zoom meeting recurrence
            await client.mutate<UpdateZoomMeetingMutation>({
              mutation: UpdateZoomMeetingDocument,
              variables: {
                id: this.targetEvent.zoomMeetingId,
                input: {
                  start_time: zoomStartDate!.replace(/\.000Z/, ''),
                  duration,
                  timezone: zoomTimeZone,
                  topic: this.targetEvent.eventName,
                  type: 2
                }
              }
            });

            // create zoom meeting recurrence
            await client.mutate<UpdateZoomMeetingMutation>({
              mutation: UpdateZoomMeetingDocument,
              variables: {
                id: this.targetEvent.zoomMeetingId,
                input: {
                  start_time: zoomStartDate!.replace(/\.000Z/, ''),
                  duration,
                  timezone: zoomTimeZone,
                  topic: this.targetEvent.eventName,
                  type: 8,
                  recurrence: {
                    repeat_interval: 1,
                    type: RecurrenceType.Daily,
                    end_times: events.length || 0
                  }
                }
              }
            });

            // sync occurrence ids with events
            const occurrenceIds: number[] = [];
            await client
              .mutate<UpdateZoomOccurrenceMutation>({
                mutation: UpdateZoomOccurrenceDocument,
                variables: {
                  OccurencyDatasInput: events.map((event, index) => {
                    const eventOccurrency = new Date(events[0].startDate);
                    eventOccurrency.setUTCDate(
                      eventOccurrency.getUTCDate() + index
                    );
                    occurrenceIds.push(eventOccurrency.getTime());
                    return {
                      eventId: event.eventId,
                      OccurencyId: eventOccurrency.toISOString()
                    };
                  })
                }
              });

            // change zoom meetings dates
            const promises = events.map(async (event, index) => {
              if (getZoomTimeZone(event.TimeZone) !== 'UTC') {
                zoomTimeZone = getZoomTimeZone(event.TimeZone);
                zoomStartDate = tz(
                  tz(event.startDate as string),
                  '%FT%T',
                  zoomTimeZone
                );
              }
              return await client
                .mutate<UpdateZoomMeetingMutation>({
                  mutation: UpdateZoomMeetingDocument,
                  variables: {
                    id: this.targetEvent.zoomMeetingId,
                    input: {
                      start_time: zoomStartDate,
                      duration,
                      occurrence_id: occurrenceIds[index]
                    }
                  }
                });
            });
            await Promise.all(promises);

            this.handleLoading(false);
            if (callbackArgs.values && callbackArgs.values.navigate)
              this.router.history.push(`/events/${scheduleEvent!.eventId}`);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
});

