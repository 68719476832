
import Vue from 'vue';
import Bussing from './Bussing';
import MedicalNotes from './MedicalNotes.vue';
import HousingRequests from './HousingRequests.vue';
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import Waivers from './Waivers';
import Financials from './Financials';
import EmergencyContacts from 'shared/components/EmergencyContacts';
import ParentApproval from './ParentApproval';
import CustomQuestions from './CustomQuestions';
import Modal from 'shared/ui/modal/Modal.vue';
import Loading from 'shared/components/Loading.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import FileSaver from 'file-saver';
import { b64toBlob } from 'shared/util';
import RequiresPermission from 'shared/components/RequiresPermission';
import Popover from 'shared/ui/popover/Popover.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { CancelRegistrationMutationVariables, CompleteRegistrationMutationVariables, GetEventQuery, GetRegistrationQuery, MoveRegistrationToWaitListMutationVariables, PermissionEnum, ProduceInvoiceMutationVariables, PromoteRegistrationMutationVariables, ResendRegistrationEmailsMutationVariables, ResendWaiverEmailsMutationVariables, StatusType, UpdateTeenMutationVariables } from 'shared/generated/graphql-types';

type Registration = GetRegistrationQuery['registration']
type EmergencyContactsType = Registration['Teen']['EmergencyContacts']

library.add(faAngleDown);

interface Props {
  selectedRegistrationId: string;
  fetchingRegistration: boolean;
  completingRegistration: boolean;
  cancelingRegistration: boolean;
  movingRegistrationToWaitingList: boolean;
  promotingRegistrationToWaitingList: boolean;
  sendingRegistrationEmails: boolean;
  sendingWaiverEmails: boolean;
  producingInvoice: boolean;
  event: GetEventQuery['event'];
  registration: Registration;
  registrationLineItems: any[];
  updateHousingRequest: (request: number, value: string) => void;
  updateTeen: (args: Pick<UpdateTeenMutationVariables, 'personId' | 'value'>) => void;
  updateMedicalNote: (args: { registrationId: number; medicalNote: string }) => void;
  cancelRegistration: (args: CancelRegistrationMutationVariables) => Promise<void>;
  completeRegistration: (args: CompleteRegistrationMutationVariables) => Promise<void>;
  moveToWaitList: (args: MoveRegistrationToWaitListMutationVariables) => Promise<void>;
  promoteRegistration: (args: PromoteRegistrationMutationVariables) => Promise<void>;
  resendRegistrationEmails: (registrationId: ResendRegistrationEmailsMutationVariables) => Promise<void>;
  resendWaiverEmails: (registrationId: ResendWaiverEmailsMutationVariables) => Promise<void>;
  produceInvoice: (registrationId: ProduceInvoiceMutationVariables) => Promise<string>;
  claims: PermissionEnum[];
}

interface Computed {
  loading: boolean;
  actionsLoading: boolean;
  registrationID: number;
  emergencyContacts: EmergencyContactsType;
  isRegistrationCompleted: boolean;
  canRegistrationBeCompleted: boolean;
  isRegistrationCancelled: boolean;
  isRegistrationOnWaitingList: boolean;
  canRegistrationBePromoted: boolean;
  teenName: string;
}

interface Methods {
  completeRegistrations: () => Promise<void>;
  completeRegistrationsWithPromotionOverride: () => Promise<void>;
  cancelRegistrations: (suppressEmail: boolean) => Promise<void>;
  moveAllToWaitList: (suppressEmail: boolean) => Promise<void>;
  promoteAllRegistrations: (suppressEmail: boolean) => Promise<void>;
  resendRegistrationEmailsForAll: () => Promise<void>;
  resendWaiverEmailsForRegistration: () => Promise<void>;
  produceInvoices: () => Promise<void>;
}

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'RegistrationInfo',
  components: {
    Bussing,
    MedicalNotes,
    HousingRequests,
    Waivers,
    ParentApproval,
    Loading,
    Popover,
    EmergencyContacts,
    Financials,
    ActionBar,
    UIButton,
    Modal,
    RequiresPermission,
    CustomQuestions
  },
  props: {
    selectedRegistrationId: {},
    fetchingRegistration: {},
    completingRegistration: {},
    cancelingRegistration: {},
    movingRegistrationToWaitingList: {},
    promotingRegistrationToWaitingList: {},
    sendingRegistrationEmails: {},
    sendingWaiverEmails: {},
    producingInvoice: {},
    event: {},
    registration: {},
    registrationLineItems: {},
    updateHousingRequest: {},
    updateTeen: {},
    updateMedicalNote: {},
    cancelRegistration: {},
    completeRegistration: {},
    moveToWaitList: {},
    promoteRegistration: {},
    resendRegistrationEmails: {},
    resendWaiverEmails: {},
    produceInvoice: {},
    claims: {}
  },
  data() {
    return {
      PermissionEnum: PermissionEnum,
      StatusType: StatusType,
      addingNewEmergencyContact: false,
      actionWanted: false,
      showCancelRegistrationModal: false,
      showCompleteRegistrationModal: false,
      showMoveToWaitListModal: false,
      showPromoteRegistrationModal: false,
      showResendRegistrationEmailsModal: false,
      showResendWaiverEmailsModal: false,
      showProduceInvoicesModal: false
    };
  },
  computed: {
    loading() {
      return this.fetchingRegistration;
    },
    actionsLoading() {
      return (
        this.completingRegistration ||
        this.cancelingRegistration ||
        this.movingRegistrationToWaitingList ||
        this.sendingRegistrationEmails ||
        this.sendingWaiverEmails ||
        this.producingInvoice ||
        this.promotingRegistration
      );
    },
    registrationID() {
      return +this.selectedRegistrationId;
    },
    emergencyContacts() {
      return (
        (this.registration && this.registration.Teen && this.registration.Teen.EmergencyContacts) ||
        []
      );
    },
    canRegistrationBeCompleted() {
      return !this.isRegistrationCompleted && ((this.registration.EventTicket.deposit ?? 0) < 1 || (this.registration.balance * -1) + (this.registration.scholarshipGrant ?? 0) >= this.registration.EventTicket.deposit!);
    },
    isRegistrationCompleted() {
      return this.registration.status === StatusType.Completed;
    },
    isRegistrationCancelled() {
      return this.registration.status === StatusType.Cancelled;
    },
    isRegistrationOnWaitingList() {
      return this.registration.status === StatusType.Waiting;
    },
    canRegistrationBePromoted() {
      return (
        this.registration.status === StatusType.Waiting ||
        this.registration.status === StatusType.PendingApproval
      );
    },
    teenName () {
      return `${this.registration.Teen.Person.firstName} ${this.registration.Teen.Person.lastName}`;
    }
  },
  methods: {
    async completeRegistrations() {
      this.showCompleteRegistrationModal = false;
      await this.completeRegistration({ registrationId: this.registrationID });
    },
    async completeRegistrationsWithPromotionOverride() {
      this.showCompleteRegistrationModal = false;
      await this.completeRegistration({ registrationId: this.registrationID, skipPromotionCheck: true });
    },
    async cancelRegistrations(suppressEmail: boolean) {
      this.showCancelRegistrationModal = false;
      await this.cancelRegistration({ registrationId: this.registrationID, suppressEmail });
    },
    async moveAllToWaitList(suppressEmail: boolean) {
      this.showMoveToWaitListModal = false;
      await this.moveToWaitList({ registrationId: this.registrationID, suppressEmail });
    },
    async promoteAllRegistrations(suppressEmail: boolean) {
      this.showPromoteRegistrationModal = false;
      await this.promoteRegistration({ registrationId: this.registrationID, suppressEmail });
    },
    async resendRegistrationEmailsForAll() {
      this.showResendRegistrationEmailsModal = false;
      await this.resendRegistrationEmails({ registrationId: this.registrationID });
    },
    async resendWaiverEmailsForRegistration() {
      this.showResendWaiverEmailsModal = false;
      await this.resendWaiverEmails({ registrationId: this.registrationID });
    },
    async produceInvoices() {
      this.showProduceInvoicesModal = true;
      const result = {
        id: this.registrationID,
        invoice: await this.produceInvoice({ registrationId: this.registrationID })
      };

      FileSaver.saveAs(
        b64toBlob(result.invoice, 'application/pdf', 'data:application/pdf;base64,'),
        `invoice-${result.id}.pdf`
      );
      this.showProduceInvoicesModal = false;
    }
  },
  watch: {
    registration: {
      immediate: true,
      handler: function (registration) {
        if (registration) {
          this.$emit('update:registration', registration);
        }
      }
    }
  }
});
