
import { RouterLink } from 'vue-component-router';
import Vue from 'vue';
import Loading from 'shared/components/Loading.vue';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Avatar from 'shared/components/avatar';
import { getGrade } from 'shared/util';
import { Props as LayoutProps, Teen } from '../types';

interface Data {
  limit: number;
}

interface Methods {
  getGenderColor: (gender: number) => string;
  formatTeen: (teen: Teen) => string;
  getGrade: (graduationYear: number | null) => string | null;
  fetchMoreHandler: (state: StateChanger) => Promise<void>;
}
interface Props {
  teens: LayoutProps['teens'];
  fetchMore: LayoutProps['fetchMore'];
  activeFilters: LayoutProps['activeFilters'];
  selectedTeen: number;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'JFETeensList',
  components: {
    BorderedList,
    InfiniteLoading,
    Loading,
    RouterLink,
    Avatar
  },
  data() {
    return {
      limit: 60
    }
  },
  props: {
    teens: {},
    fetchMore: {},
    selectedTeen: {},
    activeFilters: {}
  },
  methods: {
    getGenderColor (gender: number) {
      if (!gender) {
        return '#00bcd4';
      } else {
        return '#cd8ece';
      }
    },
    formatTeen({ Person: { firstName, lastName }}: Teen) {
      return `${firstName} ${lastName}`;
    },
    getGrade (graduationYear: number | null) {
      if (!graduationYear) return null;
      return getGrade(graduationYear).grade;
    },
    async fetchMoreHandler (state: StateChanger) {
      await this.fetchMore({limit: this.limit, offset: this.teens.length}, state);
      state.reset();
      state.loaded();
    }
  }
})
