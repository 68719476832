
import { Vue, Prop, Component } from "vue-property-decorator";
import UIInput from 'shared/ui/forms/Input.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import Modal from 'shared/ui/modal/Modal.vue';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import UIButton from 'shared/ui/buttons/Button.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import * as RadioGroup from 'shared/radio-group';
import {
    CompactAdvisorRegion
} from '../../../shared/CompactAdvisorRegion';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { getCurrentSeason, validateName } from 'shared/util';
import ChapterLookup from 'shared/components/ChapterLookup';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { StaffCopy } from './types';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import GlaubachFellowItem from './components/GlaubachFellowItem';
import Editor from 'shared/components/Editor';
import range from "lodash/range";
import debounce from 'lodash/debounce';
import StaffInput from './StaffInput';
import { AddGlaubachSeasonMutation, CreateFellowInput, CurrentUserQuery } from "shared/generated/graphql-types";
import { ArrayElement } from "shared/util/types";

type AdvisorRegion = ArrayElement<CurrentUserQuery['me']['AdvisorRegions']>

library.add(faPencil);

function getStaffCopyForForm(staff: CompactAdvisorRegion): StaffCopy {
  const copy: StaffCopy = Object.assign({ isGlaubachFellow: !!(staff.glaubachFellows || []).length, glaubachPassword: '' }, staff);
  return copy;
}
@Component({
    name: "Profile",
    methods: {
      validateName,
    },
    components: {
        CollapsibleSection,
        UIInput,
        ChapterLookup,
        AutoSaveField,
        ...RadioGroup,
        UIRadio,
        UISelect,
        UIButton,
        Editor,
        Modal,
        UICheckbox,
        UITextarea,
        Divider,
        GlaubachFellowItem
    }
})

export default class extends Vue {
    @Prop() staff!: CompactAdvisorRegion;
    @Prop() updateGlaubachStaff!: (input: StaffInput, staff: StaffCopy) => Promise<void>;
    @Prop() addGlaubachSeason!: (input: CreateFellowInput) => Promise<{ data: AddGlaubachSeasonMutation }>;
    @Prop() addingGlaubachSeason!: boolean;
    @Prop() regionId!: number;
    @Prop() router!: { history: { replace: (arg: any) => void }; location: { pathname: string; search: string; } };
    @Prop() currentUser!: AdvisorRegion;

    staffCopy: StaffCopy = getStaffCopyForForm(this.staff);
    addingSeason: boolean = false;
    newGlaubachSeason: { season: string, community: string } = {
      season: '',
      community: ''
    };

    get debouncedUpdate () {
      return debounce(this.update, 3000);
    }
    async addGlaubachSeasonHandler () {
      const results = await this.addGlaubachSeason({ season: this.newGlaubachSeason.season, community: this.newGlaubachSeason.community, staffId: this.staffCopy.staffId });
      this.newGlaubachSeason.season = '';
      this.newGlaubachSeason.community = '';
      this.addingSeason = false;
      const glaubachFellows = this.staffCopy.glaubachFellows.slice();
      glaubachFellows.push(results!.data.addGlaubachSeason);
      this.staffCopy = { ...this.staffCopy, glaubachFellows }
    }
    get seasons () {
      const currentSeason = Number(getCurrentSeason().split('-')[0]);
      return range(2011, currentSeason + 1).map(x => `${x}-${x + 1}`).reverse();
    }
    async update(args: SaveEventArgs) {
      await this.updateGlaubachStaff(new StaffInput(
        { ...this.staffCopy,
          ...(args.name === 'chapter' ? { chapterId: +args.transformed } : {})
        },
        this.regionId
      ), this.staffCopy);
    }
}
