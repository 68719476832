


import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import { ServerSideFilterOption } from 'shared/components/Filters/FilterOption';
import { getGrade } from 'shared/util';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';
import identity from 'lodash/identity';
import range from 'lodash/range';
import uniqBy from 'lodash/uniqBy';
import { ActiveFiltersType } from '../shared/ActiveFilters';
import { getCurrentSeason } from 'shared/util';
import { GetFilterOptionsQuery } from 'shared/generated/graphql-types';

@Component({
  components: {
    UICheckbox,
    UISelect
  },
	methods: {
		getGrade
	}
})
export default class FiltersPanel extends Vue {
  @Prop() activeFilters!: ActiveFiltersType;
  @Prop() filterOptions!: GetFilterOptionsQuery;

	filters: { [key: string]: ServerSideFilterOption[] } = {
    Chapter: this.activeFilters.filters.Chapter || [],
    SchoolType: this.activeFilters.filters.SchoolType || [],
    School: this.activeFilters.filters.School || [],
    Grade: this.activeFilters.filters.Grade || []
  }

  relationshipImpact: boolean = !!this.activeFilters.relationshipImpact;
  eventImpact: boolean = !!this.activeFilters.eventImpact;
  reached: boolean = !!this.activeFilters.reached;
  season: string = this.activeFilters.season;

	get localActivefilters () {
    return {
      filters: omitBy(pickBy(this.filters, identity), (x) => isArray(x) && !x.length),
      relationshipImpact: this.relationshipImpact,
      eventImpact: this.eventImpact,
      reached: this.reached,
      season: this.season
    }
  }
	get gradeOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.gradeOptions || [])
			.filter(go => go.key !== null && go.key.length === 4)
			.map(go => getGrade(+(go.key!)))
			.filter(go => go.grade !== null && go.grade.length > 0 )
			.map(go => ({
				key: go.year ? String(go.year) : '',
				displayValue: go.grade
			})), ['displayValue'], ['desc']), 'key');
  }
	get chapterOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.chapterOptions || [])
			.filter(co => co.key !== null && co.displayValue !== null)
			.map(co => ({
				key: co.key!,
				displayValue: co.displayValue!
			})), ['displayValue']), 'key');
  }
  get seasons () {
    const currentSeason = Number(getCurrentSeason().split('-')[0]);
    return range(2011, currentSeason+1).map(x => `${x}-${x + 1}`).reverse();
  }
	get schoolTypeOptions(): ServerSideFilterOption[] {
		return uniqBy((this.filterOptions?.schoolTypeOptions || [])
			.filter(so => so.key !== null && so.displayValue !== null)
			.map(so => ({
				key: so.key!,
				displayValue: so.displayValue!
			})), 'key');
  }
	get schoolOptions(): ServerSideFilterOption[] {
		return uniqBy(orderBy((this.filterOptions?.schoolOptions || [])
			.filter(so => so.key !== null && so.displayValue !== null)
			.map(so => ({
				key: so.key!,
				displayValue: so.displayValue!
			})), ['displayValue']), 'key');
  }
  get orderedGradutationOptions() {
      const grades: number[] = this.gradeOptions.map(x => +x.key)
      const date = new Date();
      const schoolYear = date.getMonth() > 5 ? date.getFullYear() + 1 : date.getFullYear();
      const highSchoolRange = range(schoolYear, schoolYear + 4).reverse()
      const highSchoolFiltered = grades.filter(x => x <= highSchoolRange[0] && x >= highSchoolRange[highSchoolRange.length - 1])
      const elementaryRange = range(schoolYear + 4, schoolYear + 13).reverse()
      const elementaryFiltered = grades.filter(x => x <= elementaryRange[0] && x >= elementaryRange[elementaryRange.length - 1])
      const alumniRange = range(schoolYear - grades[grades.length - 1], schoolYear).reverse()
      const alumniFiltered = grades.filter(x => x <= alumniRange[0] && x >= alumniRange[alumniRange.length - 1])
      return uniqBy(highSchoolFiltered.concat(elementaryFiltered).concat(alumniFiltered).map(key => ({
          key: String(key),
          displayValue: getGrade(key).grade
      })), 'key');
    }
	@Watch ('filters', { deep: true })
	filtersWatcher () {
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('eventImpact')
	eventImpactWatcher (eventImpact: boolean) {
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('reached')
	reachedWatcher (reached: boolean) {
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('relationshipImpact')
	missingCollegeProgramWatcher (relationshipImpact: boolean) {
    this.$emit('filters', this.localActivefilters)
  }
	@Watch ('season')
	seasonWatcher (season: string) {
    this.$emit('filters', this.localActivefilters)
  }


}
