import PotentialPassenger from './shared/PotentialPassenger';
import {
  CreateBusInput,
  EditBusInput,
  GetBusesForEventQuery
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = GetBusesForEventQuery['event'];
export type Bus = ArrayElement<Event['Buses']>;
type Registration = ArrayElement<Event['Registrations']>;

export interface Props {
  addingNewBus: boolean;
  loadingBuses: boolean;
  buses: Bus[];
  eventId: number;
  event: Event | null;
  editBus: (bus: EditBusInput, originalBus: Bus) => void;
  createBus: (bus: CreateBusInput) => void;
  deleteBus: (busID: number) => void;
  direction: string | null;
  capacity: string | null;
  term: string;
  addToBus: (id: number, bus: Bus, type: string, p: PotentialPassenger) => void;
  removeFromBus: (id: number, bus: Bus, type: string, p: PotentialPassenger) => void;
}
export interface Stats {
  teensRegistered: number;
  guestsRegistered: number;
  staffRegistered: number;
  totalWithBuses: number;
  registrationsNeedBusToEvent: number;
  staffNeedBusToEvent: number;
  guestsNeedBusToEvent: number;
  registrationsNeedBusFromEvent: number;
  staffNeedBusFromEvent: number;
  guestsNeedBusFromEvent: number;
  totalBuses: number;
}
export const defaultStats: Stats = {
  teensRegistered: 0,
  guestsRegistered: 0,
  staffRegistered: 0,
  totalWithBuses: 0,
  registrationsNeedBusToEvent: 0,
  staffNeedBusToEvent: 0,
  guestsNeedBusToEvent: 0,
  registrationsNeedBusFromEvent: 0,
  staffNeedBusFromEvent: 0,
  guestsNeedBusFromEvent: 0,
  totalBuses: 0
};

export interface Computed {
  showTeenProfile: boolean;
  showStaffProfile: boolean;
  potentialPassengers: PotentialPassenger[];
  filteredBuses: Bus[];
  teensRegistered: number;
  guestsRegistered: number;
  staffRegistered: number;
  totalWithBuses: number;
  stats: Stats;
}

export interface Methods {
  handleDelete: (busID: number) => void;
}
