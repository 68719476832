
import UISearch from 'shared/ui/forms/Search.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIRange from 'shared/ui/forms/Range.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import SelectDuplicatesFields from 'shared/components/SelectDuplicatesFields/fetchFirst';
import TeenProfile from 'shared/components/TeenProfile';
import NoData from 'shared/components/NoData.vue';
import Vue from 'vue';
import FileSaver from 'file-saver';
import { ApolloClient, FetchResult } from '@apollo/client';
import { dateFormat, getCurrentEmail, getCurrentSeason } from 'shared/util';
import Pagination from 'shared/components/Pagination';
import ChapterLookup from 'shared/components/ChapterLookup';
import UISelect from 'shared/ui/forms/FancySelect';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Modal from 'shared/ui/modal/Modal.vue';
import Avatar from 'shared/components/avatar';
import range from 'lodash/range';
import trim from 'lodash/trim';
import has from 'lodash/has';
import toLower from 'lodash/toLower';
import Divider from 'shared/layout/divider/layout-divider.vue';
import Loading from 'shared/components/Lock-ui.vue';
import UIButton from "shared/ui/buttons/Button.vue";
import AddPayment from 'shared/components/AddPayment';
import { getOwedRegistrationCSVRows } from './utils';
import { getParents } from 'shared/util/families';
import { EmailParentResultEnum, EmailParentsMutation, GetOwedRegistrationsDocument, GetOwedRegistrationsQuery,  OwedRegistrationFilter } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';
import { Chapter } from 'shared/types';

type OwedRegistration = ArrayElement<GetOwedRegistrationsQuery['owedRegistrations']['registrations']>
type EmailAddress = ArrayElement<OwedRegistration['Teen']['Person']['EmailAddresses']>

interface Column {
  columnName: string,
  propertyName: string
}

interface Data {
  downloading: boolean;
  editingTeenProfile: boolean;
  profileClose: boolean;
  showEmailParentsConfirmation: boolean;
  registrationId: number | null;
  emailParentsError: boolean;
  emailParentsSuccess: boolean;
}

interface Props {
  owedRegistrations: OwedRegistration[];
  total: number;
  sendingEmail: boolean;
  max: number;
  min: number;
  currentPage: number;
  limit: number;
  regionId: number;
  selectedTeen: OwedRegistration | null;
  filters: OwedRegistrationFilter;
  selectedRegistrations: number[];
  emailParents: (registrationIds: number[]) => FetchResult<EmailParentsMutation>;
  isLoading: boolean;
  selectRegistration: (id: number) => void;
  unselectRegistration: (id: number) => void;
  selectTeen: (teen: OwedRegistration) => void;
  clearFilters: () => void;
  clearSelectedRegistrations: () => void;
  client: ApolloClient<any>;
}

interface Computed {
  seasons: string[];
  totalPages: number;
}

interface Methods {
  sortReports: (sortBy: string) => void;
  extractName: (registrationId: number) => string;
  setFilterHandler: (value: any, filter: string) => void;
  chapterHandler: (chapters: Chapter[]) => void;
  financialSelect: (teen: OwedRegistration) => void;
  isChecked: (id: number) => boolean;
  fetchMoreHandler: () => void;
  emailParentsHandler: (ids: number[]) => Promise<void>;
  hasParentEmails: (teen: OwedRegistration) => boolean;
  registrationsHandler: (registrationID: number, teenID: number) => void;
  onPageChange: (page: number) => void;
  closeProfile: () => void;
  getRegistrations: () => void;
  dateFormat: (ISOdate: string) => string | null;
  getFinancialReminderSentCount: (r: OwedRegistration) => number;
  emailParentsClose: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Financials',
  components: {
    AddPayment,
    PanelsSidebar,
    UIButton,
    Divider,
    PanelsGrid,
    BorderedList,
    ActionBar,
    UICheckbox,
    UISearch,
    Avatar,
    UIInput,
    UIRange,
    Modal,
    TeenProfile,
    Pagination,
    ChapterLookup,
    UISelect,
    ScrollablePanel,
    SelectDuplicatesFields,
    Loading,
    NoData
  },
  directives: {
    fullheight
  },
  data() {
    return {
      downloading: false,
      editingTeenProfile: false,
      profileClose: false,
      showEmailParentsConfirmation: false,
      emailParentsError: false,
      emailParentsSuccess: false,
      registrationId: null,
    }
  },
  props: {
    owedRegistrations: {},
    total: {},
    sendingEmail: {},
    max: {},
    min: {},
    currentPage: {},
    limit: {},
    regionId: {},
    selectedTeen: {},
    filters: {},
    selectedRegistrations: {},
    emailParents: {},
    isLoading: {},
    selectRegistration: {},
    unselectRegistration: {},
    selectTeen: {},
    clearFilters: {},
    clearSelectedRegistrations: {},
    client: {},
  },
  computed: {
    seasons() {
      const currentSeason = Number(getCurrentSeason().split('-')[0]);
      return range(2011, currentSeason + 1).map(x => `${x}-${x + 1}`).reverse();
    },
    totalPages() {
      if (this.limit === this.total) return this.total / this.limit;
      return Math.ceil(this.total / this.limit);
    }
  },
  methods: {
    dateFormat,
    getFinancialReminderSentCount (r) {
      if (!r || !has(r, 'Teen.Person.EmailAddresses[0]')) return 0
      const currentEmail = getCurrentEmail(r.Teen.Person.EmailAddresses) as EmailAddress | null;
      if (currentEmail && currentEmail.EmailHistory.length) {
        return currentEmail.EmailHistory.filter(eh => toLower(eh.type).includes('financial_balance_reminder')).length
      }
      return 0
    },
    sortReports(sortBy) {
      this.setFilterHandler({column: sortBy, ascending: !this.filters.sortColumn!.ascending}, 'sortColumn');
      this.$emit('currentPage', 1);
      this.$emit('selectedRegistrations', []);
    },
    extractName(registrationId) {
      if (!registrationId || typeof registrationId !== 'number') return '';
      const {Teen: {Person: {firstName, lastName}}} = this.owedRegistrations.find(x => x.registrationID === registrationId)!;
      return trim(`${firstName || ''} ${lastName || ''}`);
    },
    setFilterHandler(value, filter) {
      this.$emit('filters', {...this.filters, [filter]: value});
      this.$emit('currentPage', 1);
      this.$emit('selectedRegistrations', []);
    },
    chapterHandler(chapters) {
      this.setFilterHandler(chapters.map(c => c.chapterId), 'chapters');
      this.$emit('currentPage', 1);
      this.$emit('selectedRegistrations', []);
    },
    financialSelect(teen) {
      this.selectTeen(teen);
      this.profileClose = false;
    },
    isChecked(id) {
      return this.selectedRegistrations.includes(id)
    },
    async fetchMoreHandler() {
      await this.fetchMore({limit: 60, offset: this.owedRegistrations.length});
    },
    emailParentsClose() {
      this.showEmailParentsConfirmation = false;
      this.emailParentsError = false;
      this.emailParentsSuccess = false;
    },
    async emailParentsHandler(ids) {
      try {
        const results = await this.emailParents(ids)
        if (results.data?.emailParents) {
          if (results.data.emailParents.every((r => r.result === EmailParentResultEnum.Success))) {
            this.$emit('selectedRegistrations', []);
            this.emailParentsSuccess = true;
            this.emailParentsError = false;
          } else {
            const failedRegistrationIds = results.data.emailParents
              .filter(r => r.result === EmailParentResultEnum.Failed)
              .map(r => r.registrationId)
            this.emailParentsError = true;
            this.$emit('selectedRegistrations', failedRegistrationIds);
          }
        }
      } catch (e) {
        this.emailParentsError = true;
      }
    },
    hasParentEmails(teen: OwedRegistration) {
      const { father, mother } = getParents(teen.Teen.Person);
      return !!((mother && mother.email) || (father && father.email));
    },
    registrationsHandler(registrationID: number, teenID: number) {
      const registrationExist = this.selectedRegistrations.includes(registrationID);
      if (registrationExist) {
        this.unselectRegistration(registrationID)
      }
      else {
        this.selectRegistration(registrationID)
      }
    },
    onPageChange(page: number) {
      this.$emit('currentPage', page);
      this.$emit('selectedRegistrations', []);
    },
    closeProfile() {
      this.profileClose === true ? this.profileClose = false : this.profileClose = true;
    },
    async getRegistrations() {
      this.downloading = true;
      const result = await this.client.query<GetOwedRegistrationsQuery>({
        query: GetOwedRegistrationsDocument,
        variables: {
          filter: { ...this.filters, regionID: this.regionId }
        }
      });
      const headers = [
        'First Name',
        'Last Name',
        'Event',
        'Chapter',
        'Phone',
        'Email',
        'Father Phone',
        'Father Email',
        'Mother Phone',
        'Mother Email',
        'Balance',
        'Date',
        'Registration Link',
        'Private Registration Link'
      ].join(',');
      if(result && result.data) {
        const data = getOwedRegistrationCSVRows(result.data.owedRegistrations);
        FileSaver.saveAs(
          new Blob([`${headers}\n${data}`],
            {type: "text/plain;charset=utf-8"}),
          `Financial ${this.filters.season}.csv`
        );
      }
      this.downloading = false;
    }
  }
})
