
import Vue from 'vue';

interface Props {
  condition: boolean;
}

export default Vue.extend<{}, {}, {}, Props>({
  name: 'NoData',
  props: {
    condition: {required: true}
  }
})
