
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Loading from 'shared/components/Loading.vue';
import Sidebar from './components/Sidebar.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import SeriesDetails from './components/SeriesDetails';
import MonthView from './components/DraggableMonthView/MonthView.vue';
import AgendaView from './components/AgendaView';
import Calendar from 'shared/components/Calendar';
import EventItem from '../../../shared/Event.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import tz from 'timezone/loaded';
import UIButton from "shared/ui/buttons/Button.vue";
import { faChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import uniq from 'lodash/uniq';
import { formatDate, getZoomTimeZone } from 'shared/util';
import { DataComponent, MethodsComponent, ComputedComponent, PropsComponent, Event } from './types';
import DateTimezone from 'date-timezone';
import '@progress/kendo-ui/js/kendo.scheduler';
import '@progress/kendo-theme-default/dist/all.css'
import { SchedulerInstaller } from '@progress/kendo-scheduler-vue-wrapper';
import Modal from 'shared/ui/modal/Modal.vue';
import { RouterLink } from 'vue-component-router';
import { calculateEventDuration } from '../../../shared/util';
import { AttendanceStatus } from 'shared/generated/graphql-types';

Vue.use(SchedulerInstaller);

library.add(faChevronRight, faChevronLeft, faChevronDoubleLeft, faChevronDoubleRight, faPlusCircle, faMapMarkerAlt, faCalendarAlt, faTimes);

export default Vue.extend<DataComponent, MethodsComponent, ComputedComponent, PropsComponent>({
  name: 'Summary',
  components: {
    Loading,
    Sidebar,
    PanelsGrid,
    SeriesDetails,
    ScrollablePanel,
    RouterLink,
    Modal,
    UIButton,
    Calendar,
    AgendaView,
    MonthView
  },
  directives: {
    fullheight
  },
  inject: ['router'],
  props: {
    series: {},
    currentDate: {},
    regionId: {},
    setCurrentDate: {},
    events: {},
    dates: {},
    datesChanged: {},
    mode: {},
    setMode: {},
    filters: {},
    clearFilters: {},
    isLoading: {},
    currentPage: {},
    limit: {},
    total: {},
    editEvent: {},
    updateZoomMeeting: {},
    deleteEvent: {},
    deletingEvent: {},
    deleteZoomMeeting: {},
    deletingZoomMeeting: {},
    onDeletingChange: {}
  },
  data() {
    return {
      event: null,
      monthNames: ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      editModal: false,
      warningModal: false,
      widget: null,
      eventLoading: false,
      mutationObserver: null
    }
  },
  computed: {
    currentMonth() {
      return `${this.monthNames[this.currentDate.month - 1]} ${this.currentDate.year}`;
    },
    currentDateAsNativeDate() {
      return new DateTimezone.DateTimezone(this.currentDate.year, this.currentDate.month - 1, this.currentDate.day, 0, 0);
    },
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
    months() {
      if (this.events.length) {
        return uniq(this.events.map(e => formatDate(e.startDate, e.TimeZone, '%B')))
      }
      return []
    },
    filteredEvents() {
      if (this.filters.month) {
        const targetMonth = this.filters.month.toLowerCase();
        return this.events.filter(e => formatDate(e.startDate, e.TimeZone, '%B').toLowerCase() === targetMonth)
      }
      return this.events;
    },
    currentWeek() {
      const date = new Date(this.currentDate.year, this.currentDate.month - 1, this.currentDate.day);

      return Math.ceil((date.getDate() + 6 - date.getDay()) / 7) - 1
    },
    today() {
      const today = new Date();

      return {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate()
      }
    },
    transformedEvents() {
      return (this.events || []).map((event: Event) => {
        return {
          ...event,
          id: event.eventId,
          start: new Date(tz(tz(event.startDate), '%FT%T', event.TimeZone)),
          end: new Date(tz(tz(event.endDate), '%FT%T', event.TimeZone)),
          title: event.eventName
        };
      })
    },
    calendarKey() {
      return this.transformedEvents.map(e => e.start.toISOString()).join('');
    },
    teenAttended() {
      return AttendanceStatus.Attended;
    }
  },
  methods: {
    timePeriodFormat(time) {
      let start = time.split(' - ')[0];
      let end = time.split(' - ')[1];
      if(parseInt(start.split(':')[0]) > 12) {
        start = `${(parseInt(start.split(':')[0])-12)}:${start.split(':')[1]}`;
      }
      if(parseInt(end.split(':')[0]) > 12) {
        end = `${(parseInt(end.split(':')[0])-12)}:${end.split(':')[1]}`;
      }
      return `${start} - ${end}`;
    },

    normalizedEventTime (event) {
      if (!event) return
      return `${tz(tz(event.startDate), '%R%p', event.TimeZone)} - ${tz(tz(event.endDate), '%R%p', event.TimeZone)}`;
    },

    editEventHandler ({ event }: { event: Event }) {
      this.event = event;
      this.editModal = true;
    },

    widgetReady(widget) {
      widget.views['month'].eventHeight = 28;
      widget.view('month');
      this.widget = widget;
      const widgetRef: any = this.$refs.widgetRef;
      this.mutationObserver = new MutationObserver(() => {
        this.initEvents();
      });
      this.mutationObserver.observe(widgetRef.$el, {childList: true, subtree: true});
    },

    initEvents() {
      const widgetRef: any = this.$refs.widgetRef;
      if (widgetRef) {
        const elements: any = widgetRef.$el.querySelectorAll('.k-event');

        if (elements.length > 0) {
          if (this.mutationObserver) {
            this.mutationObserver.disconnect();
          }
          this.$children.forEach((vm) => {
            if (vm.hasOwnProperty('isSeries')) {
              vm.$destroy();
            }
          });

          elements.forEach((element: any) => {
            if (element.querySelectorAll('.popover').length === 0) {
              const event = this.widget.occurrenceByUid(element.dataset.uid);
              const eventComponent = Vue.extend({...EventItem, provide: {router: this.router}});
              const mountedEventItem = new eventComponent({
                parent: this,
                propsData: {event, isSeries: true, cancelEvent: () => {this.cancelEvent(event.eventId, event.zoomMeetingId, event.zoomOccurencyId)}}
              }).$mount();
              element.appendChild(mountedEventItem.$el)
            }
          });
          if (this.mutationObserver) {
            this.$nextTick(() => {
              this.mutationObserver.observe(widgetRef.$el, {childList: true, subtree: true});
            })
          }
        }
      }
    },

    onNavigate(event) {
      this.setCurrentDate({
        month: event.date.getMonth() + 1,
        year: event.date.getFullYear(),
        day: event.date.getDate()
      });
    },

    async onSave(event) {
      this.eventLoading = true;
      const {event: { eventId, start, end, zoomMeetingId, zoomOccurencyId, TimeZone }} = event;
      DateTimezone.setGlobalTimezone(TimeZone);
      const startDate = new DateTimezone.DateTimezone(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes()).toISOString();
      const endDate = new DateTimezone.DateTimezone(end.getFullYear(), end.getMonth(), end.getDate(), end.getHours(), end.getMinutes()).toISOString();
      await this.editEvent({
        eventID: eventId,
        startDate,
        endDate
      });
      if (zoomMeetingId) {
        let zoomStartDate = startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(TimeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(TimeZone);
          zoomStartDate = tz(tz(startDate), '%FT%T', zoomTimeZone);
        }
        await this.updateZoomMeeting(zoomMeetingId, {
          start_time: zoomStartDate,
          duration: calculateEventDuration(startDate, endDate),
          occurrence_id: new Date(zoomOccurencyId).getTime()
        });
      }
      this.initEvents();
      this.eventLoading = false;
    },

    async cancelEvent(eventId, zoomMeetingId, occurrence_id) {
      const event = this.events.find(e => e.eventId === eventId);
      if (event && (event!.Attendances.filter(a => a.status === this.teenAttended).length > 0 || event!.Registrations.length > 0)) {
        this.event = event;
        this.warningModal = true;
        return;
      }
      this.onDeletingChange(true);
      await this.deleteEvent(eventId);
      if (zoomMeetingId) {
        try {
          await this.deleteZoomMeeting(zoomMeetingId, occurrence_id);
        } catch(e) {
          Sentry.withScope((scope) => {
            scope.setExtra('error', e);
            Sentry.captureMessage('On remove from summary');
          });
        }
      }
      if (this.series.Events && this.series.Events.length > 0) {
        this.onDeletingChange(false);
        this.initEvents();
      } else {
        this.router.history.push('/events');
      }
    },

    onPageChange(page: number) {
      this.$emit('currentPage', page);
    },

    setFilterHandler(value: any, filter: string) {
      this.$emit('filters', {...this.filters, [filter]: value})
    },
    setLimit (limit: number) {
      this.$emit('limit', limit);
      this.onPageChange(1);
    },
    handleDateChange(date: Date) {
      this.setCurrentDate({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        day: date.getDate()
      });
    },

    async todayHandler() {
      this.eventLoading = true;
      await this.setCurrentDate(this.today);
      this.eventLoading = false;
    },

    handleLoading(value) {
      this.eventLoading = value;
    },

    handleMouseWheel(e: any) {
      // TODO - the calendar scrolls also, so not sure how to deal with that
      // TODO the year does not advance properly
      // const dir = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

      // if (dir === 1) {
      // 	this.previousMonthHandler()
      // }
      // else {
      // 	this.nextMonthHandler()
      // }
    },

    previousMonthHandler() {
      if (this.currentDate.month === 1) {
        this.setCurrentDate({
          year: this.currentDate.year - 1,
          month: 12,
          day: 1
        });
      } else {
        this.setCurrentDate({
          year: this.currentDate.year,
          month: this.currentDate.month - 1,
          day: 1
        });
      }
    },

    nextMonthHandler() {
      if (this.currentDate.month === 12) {
        this.setCurrentDate({
          year: this.currentDate.year + 1,
          month: 1,
          day: 1
        })
      } else {
        this.setCurrentDate({
          year: this.currentDate.year,
          month: this.currentDate.month + 1,
          day: 1
        });
      }
    },

    previousYearHandler() {
      this.setCurrentDate({
        year: this.currentDate.year - 1,
        month: this.currentDate.month,
        day: this.currentDate.day
      });
    },

    nextYearHandler() {
      this.setCurrentDate({
        year: this.currentDate.year + 1,
        month: this.currentDate.month,
        day: this.currentDate.day
      });
    }
  }
})
