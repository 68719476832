
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import Modal from 'shared/ui/modal/Modal.vue';
import EditRecurrence from '../EditRecurrence';
import AddEventToSeriesForm from '../AddEventToSeries';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faMars } from '@fortawesome/pro-regular-svg-icons/faMars';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faVenus } from '@fortawesome/pro-regular-svg-icons/faVenus';
import DateTimezone from 'date-timezone';
import { formatAMPM } from 'shared/util';
import qrcode from 'qrcode';
import FileSaver from 'file-saver';
import ClipboardCopy from 'shared/ui/clipboard/clipboard.vue';
import {
  fridayNightLights as fridayNightLightsThumbnail,
  jsu as jsuThumbnail,
  lnl as lnlThumbnail,
  other as otherThumbnail,
  shabbatMeal as shabbatMealThumbnail,
  shabbaton as shabbatonThumbnail,
  shabbatOneg as shabbatOnegThumbnail
} from '../../../../../shared/DefaultEventImages';
import { days } from '../../../utils';
import { Series } from '../../types'

library.add(faIndent, faOutdent, faMars, faVenus);

const shabbatTypeThumbnailsMap: { [x: number]: string } = {
  295: shabbatOnegThumbnail,
  304: shabbatMealThumbnail,
};

@Component({
  components: {
    UIButton,
    Modal,
    ScrollablePanel,
    AddEventToSeriesForm,
    EditRecurrence,
    ClipboardCopy
  },
  directives: {
    fullheight
  }
})
export default class SeriesDetails extends Vue {
  @Prop() series!: Series;
  @Prop() modifyingRecurrence!: boolean;
  @Prop() addingEvent!: boolean;
  @Prop() dates!: any;
  @Prop() filters!: any;
  @Prop() handleLoading!: (value: boolean) => void;
  profileClose: boolean = false;
  attendanceAppSeriesQrCode: string = '';

  get targetEvent() {
    return this.series.Events[this.series.Events.length - 1];
  }

  get thumbnail() {
    if (!this.targetEvent) return otherThumbnail;

    const {eventSubTypeId, type} = this.targetEvent.EventSubType;

    if (eventSubTypeId === 290) {
      return jsuThumbnail;
    }
    if (eventSubTypeId === 296) {
      return lnlThumbnail;
    }
    if (type === 'Shabbaton') {
      return eventSubTypeId === 292 ? fridayNightLightsThumbnail : shabbatonThumbnail;
    }
    if ([
        295, 304, 305, 325,
        326, 328, 329, 323,
        331, 324, 334, 327, 339
      ].includes(eventSubTypeId)
      || type === 'Shabbat'
      || type === 'Holiday') {
      return shabbatTypeThumbnailsMap[eventSubTypeId] || otherThumbnail;
    }

    return otherThumbnail;
  }

  get address() {
    if (!this.targetEvent) return;
    const {city, state, zip, address1, address2} = this.targetEvent;
    return `${address1 ? ` ${address1}` : ''}${address2 ? ` ${address2}` : ''}${city ? ` ${city}` : ''}${state ? ` ${state}` : ''}${zip ? ` ${zip}` : ''}`.trim();
  }

  get recurrence() {
    if (!this.targetEvent) {
      return '';
    }
    const intervalMap: { [k: string]: string } = {
      '': 'Custom',
      'custom': 'Custom',
      'weekly': 'Every Week',
      'biweekly': 'Every Every Two Weeks',
      'triweekly': 'Every Every Three Weeks',
      'monthly': 'Every Monthly'
    };

    const interval = intervalMap[this.targetEvent.recurInterval || ''];

    DateTimezone.setGlobalTimezone(this.targetEvent.TimeZone);
    const startDate = new DateTimezone.DateTimezone(this.targetEvent.startDate);
    const endDate = new DateTimezone.DateTimezone(this.targetEvent.endDate);

    const recurrenceDay = this.targetEvent.recurInterval && this.targetEvent.recurInterval !== 'custom'
      ? ` on ${days[startDate.getDay()]}`
      : '';
    return `${interval}${recurrenceDay} - ${formatAMPM(startDate)} to ${formatAMPM(endDate)}`;
  }


  downloadAttendanceAppSeriesQrCode () {
    FileSaver.saveAs(this.attendanceAppSeriesQrCode, `${this.series.seriesID}-series.png`);
  }
  @Watch('series', {immediate: true})
    async dashboardDataWatcher(newData: Series) {
      try {
        if (newData) {
          if (newData.attendanceAppSeriesLink) {
            this.attendanceAppSeriesQrCode = await qrcode.toDataURL(newData.attendanceAppSeriesLink as string, { scale: 8 }) || ''
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
};
