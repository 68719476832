
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import UISwitch from 'shared/ui/forms/Switch.vue';
import { RouterLink } from 'vue-component-router';
import { asUTCWithZerotime, splitName, validateEmail, validateNumber, validateName, getCurrentSeason } from 'shared/util';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import FormSection from 'shared/ui/forms/FormSection.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import EmailInput from 'shared/components/EmailInput';
import UISelect from 'shared/ui/forms/FancySelect';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import { Permission } from '../../types';
import RequiresPermission from 'shared/components/RequiresPermission';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import Avatar from 'shared/components/avatar';
import ProfilePhoto from 'shared/components/ProfilePhoto/ProfilePhoto.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { faCaretSquareDown } from '@fortawesome/pro-solid-svg-icons/faCaretSquareDown';
import range from "lodash/range";
import { library } from '@fortawesome/fontawesome-svg-core';
import { AddStaffInput, PermissionEnum } from "shared/generated/graphql-types";
import { AdvisorRegion, Chapter } from "shared/types";

library.add(faCaretSquareDown);

type AddNewStaffFormObject = {
  name: string,
  email: string,
  primaryPhone: string,
  secondaryPhone: string,
  gender: number,
  staffType: string,
  permissions: Permission[],
  birthDate: string
  chapter: Chapter | null
  bio: string;
  sendLoginEmail: boolean;
  glaubachSeason: string;
  glaubachCommunity: string;
};

@Component({
  name: "AddNewStaff",
  components: {
    UIInput,
    PhoneInput,
    UIToggle,
    UIRadio,
    UIButton,
    FormSection,
    ScrollablePanel,
    Avatar,
    ProfilePhoto,
    EmailInput,
    SimpleForm,
    UITextarea,
    Field,
    Error,
		UISelect,
    RouterLink,
    ChapterLookup,
    UISwitch,
    ...RadioGroup,
    RequiresPermission,
    UICheckbox
  },
  methods: {
    validateEmail,
    validateNumber,
    asUTCWithZerotime
  },
	directives: {
		fullheight
	}
})
export default class extends Vue {
  @Prop() addStaff!: (staff: AddStaffInput) => Promise<ApolloQueryResult<any>>;
  @Prop() regionId!: number;
  @Prop() redirectToProfile!: boolean;
  @Prop() currentStaffId!: string;
  @Prop() basePath!: string;
  @Prop() showDuplicatesError!: boolean;
  @Prop() router!: { history: { push: (location: string) => void } };
  @Prop() client!: ApolloClient<any>
  @Prop() rolesPermissionsMap!: { [k: string]: Permission[] };
  @Prop() permissions!: Permission[];
  @Prop() selectablePermissions!: Permission[];
  @Prop() returnUrl!: string;
  @Prop() currentUser!: AdvisorRegion;

  uploadedImage: string | null = null;
  uploading: boolean = false;
  emailInputErrors: { [k: string]: string; } = {};
  isGlaubachFellow = false;
  PermissionEnum = PermissionEnum;
  defaultValues = {
    glaubachSeason: null,
    glaubachCommunity: '',
    name: null,
    email: null,
    primaryPhone: null,
    secondaryPhone: null,
    gender: 0,
    staffType: 'Staff',
    permissions: [
      { id: 1, Name: 'ViewMedical' },
      { id: 2, Name: 'ViewPayment' },
      { id: 4, Name: 'AssignRoles' },
      { id: 5, Name: 'AddStaff' },
      { id: 6, Name: 'AccessSite' },
      { id: 7, Name: 'ViewScholarship' },
      { id: 9, Name: 'EditConsent' }
    ],
    birthDate: null,
    chapter: null
  }

  get seasons () {
    const currentSeason = Number(getCurrentSeason().split('-')[0]);
    return range(2011, currentSeason+1).map(x => `${x}-${x + 1}`).reverse();
  }

	async addNewStaffHandler(callbackArgs: SubmitCallbackArgs<AddNewStaffFormObject>) {
    if ('errors' in callbackArgs) return;

    const { name, email, primaryPhone, gender, staffType, secondaryPhone, birthDate,
            permissions, chapter, bio, sendLoginEmail, glaubachSeason, glaubachCommunity
    } = callbackArgs.values!;
    const { firstName, lastName } = splitName(name);

    if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();

		const result = await this.addStaff({
      staffType,
      email,
      primaryPhone,
      secondaryPhone,
      firstName,
      lastName,
      chapterId: chapter && chapter.chapterId || null,
      regionId: this.regionId,
			gender: !!gender,
      birthDate: asUTCWithZerotime(birthDate),
      bio,
      permissions: (permissions || []).map(p => ({ id: p.id, Name: p.Name })) || [],
      profilePhoto: this.uploadedImage ||  null,
      sendLoginEmail,
      ...(glaubachSeason ? {
        glaubachSeason,
        glaubachCommunity
      }: {})
    });
    if (this.redirectToProfile) {
        this.router.history.push(`${this.basePath.split('/:')[0]}/${result.data.addStaff.Staff.staffID}`);
    } else {
        this.router.history.push(this.returnUrl);
    }
  }

	validate (values: AddNewStaffFormObject) {

		let errors: { [key: string]: string } = {}
		const { email, primaryPhone, secondaryPhone, name } = values;

    if(!name || !validateName(name)) {
      errors['name'] = 'Please type first and last name';
    }
    if (!email || this.emailInputErrors['email']) {
        errors['email'] = this.emailInputErrors['email'] || 'Email is required'
    }
		if(primaryPhone && !validateNumber(primaryPhone)) {
			errors['primaryPhone'] = 'The Phone Number is invalid';
    }
		if(secondaryPhone && !validateNumber(secondaryPhone)) {
			errors['secondaryPhone'] = 'The Phone Number is invalid';
		}
		if(!values.staffType){
			errors['staffType'] = 'A role must be selected';
		}
    if (this.isGlaubachFellow && !values.glaubachSeason){
			errors['glaubachSeason'] = 'Season is requiered for Glaubach Fellow';
		}

		return errors
  }
  get profileImage() {
      if (this.uploadedImage) {
        return `data:image/png;base64, ${this.uploadedImage}`;
      }
      else {
        this.uploading = true;
        // if (this.teen.thumbnail) {
        //   this.uploading = false;
        //   return this.teen.thumbnail;
        // }
        return '';
      }
  }
  getGenderColor(gender: number) {
    if (!gender) {
      return '#00bcd4';
    } else {
      return '#cd8ece';
    }
  }
  async uploadedImageHandler(image: any[]) {
      const getImage = () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            this.uploadedImage = image && image.length ? image[0].base64 : null;
            if (!this.uploadedImage) reject('oyshh');
            resolve('yey');
          }, 2000);
        });
      };

      const getImageResults = await getImage();
      if (this.uploadedImage && getImageResults) {
        // await this.uploadTeenImageProfile({
        //   personId: this.personID,
        //   image: this.uploadedImage
        // });
      }
      this.uploading = false;
  }
  setPermissions(setValue: (key: string, value: any) => void, values: AddNewStaffFormObject) {
    setValue('permissions', this.rolesPermissionsMap[(values.staffType || '').replace(' ', '').toLowerCase()] || [])
  }

  toggleGlaubachFellow(event: boolean) {
    this.isGlaubachFellow = event;
  }

  togglePermission(args: { value: boolean, permission: Permission }, setValue: (key: string, value: any) => void, values: AddNewStaffFormObject) {
    if(args.value) {
      if(!values.permissions.find(p => p.Name === args.permission.Name)){
        values.permissions.push(args.permission);
      }
    } else {
      const index = values.permissions.findIndex(p => p.Name === args.permission.Name);
      if(index > -1) {
        values.permissions.splice(index, 1);
      }
    }

    setValue('permissions', values.permissions);
  }
}

