
import { Vue, Prop, Component } from "vue-property-decorator";
import AddPayment from 'shared/components/AddPayment';
import TicketChanger from './TicketChanger';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { library } from '@fortawesome/fontawesome-svg-core';
import UIButton from "shared/ui/buttons/Button.vue";
import EditPayment from './EditPayment';
import Modal from 'shared/ui/modal/Modal.vue';
import { dateFormat } from 'shared/util';
import { GetRegistrationQuery, LineItemFor } from "shared/generated/graphql-types";

type Registration = GetRegistrationQuery['registration']

library.add(faPencil,faCreditCard)

@Component({
    name: 'Financials',
    components: {
        AddPayment,
        TicketChanger,
        EditPayment,
        UIButton,
        Modal
    }
})
export default class extends Vue {
  @Prop() registration!: Registration;
  @Prop() deletePayment!: (paymentId: number) => void;
  @Prop() deletingPayment!: boolean;
    get currencySymbol () {
      return this.registration && this.registration.Event && this.registration.Event.Region.currencySymbol || '';
    }
    get scholarshipsAndBalance () {
      return this.registration.LineItems.filter(x => x.description === 'Balance' || x.description === 'Scholarship');
    }
    get lineItems () {
      return this.registration.LineItems.filter(x => x.description !== 'Balance' && x.description !== 'Scholarship').map(x => ({
        ...x,
        payment: x.for === LineItemFor.Payment ? this.registration.Payments.find(payment => payment.paymentID === x.trackById) : null,
        paymentDate: x.for === LineItemFor.Payment ? dateFormat((this.registration.Payments.find(payment => payment.paymentID === x.trackById) || { paymentDate: null }).paymentDate) : null
      }));
    }
    showTicketChanger: boolean = false;
    showAddPayment: boolean = false;
    selectedPayment: any = null;
    showDeletePaymentModal: boolean = false;
    showEditPaymentModal: boolean = false;

    amountColor($color:number) {
      return $color < 0? 'green': $color > 0? 'red' : ''
    }
};
