
import Vue from 'vue';
import { Data, Methods, Computed, Props } from './types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBus } from '@fortawesome/pro-regular-svg-icons/faBus';
import BusLookup from './BusLookup';

library.add(faBus)

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Bussing',
  components: {
    BusLookup
  },
  data() {
    return {
      mode: 'display',
      showBusChanger: false
    }
  },
	props: {
    eventId: {},
    registration: {},
    addToBus: {},
    removeFromBus: {}
	},
	computed: {
		toBusDisplay() {
      if(!this.registration.ToBus) {
        return '';
      }
			return `${this.registration.ToBus.meetingAddress} (${this.registration.ToBus.description})`;
		},
		fromBusDisplay() {
      if(!this.registration.FromBus) {
        return '';
      }
			return `${this.registration.FromBus.meetingAddress} (${this.registration.FromBus.description})`;
		}
  },
  methods: {
    async handleBusChange(direction, bus) {
      if(!bus){
        const busId = direction === 'R' ? this.registration.FromBus!.busID : this.registration.ToBus!.busID;
        return await this.removeFromBus(this.registration.registrationID, busId, direction);
      }
      return await this.addToBus(this.registration.registrationID, bus!.busId, direction, bus!);
    }
  }
})
