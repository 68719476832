import { MutateResult } from '@vue/apollo-composable';
import SideBar from './SideBar.vue';
import { withApollo } from 'shared/components/graphql';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  GetEventDashboardDataDocument,
  GetEventDashboardDataQuery,
  RecordImpressionsMutation,
  useRecordImpressionsMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface RecordImpressionsProps {
  recordImpressions: (
    args: { count: number; notes: string },
    id: number
  ) => MutateResult<RecordImpressionsMutation>;
  recordingImpression: boolean;
}

const recordImpressionsEnhancer = wrapComponent<Props, RecordImpressionsProps>((props) => {
  const { loading, mutate } = useRecordImpressionsMutation();

  return computed(() => ({
    recordImpressions: ({ count, notes = '' }, id) => {
      const {
        user: { staffID: staffId, fullName },
        eventId
      } = props;
      return mutate(
        { eventId, count, notes, staffId },
        {
          optimisticResponse: {
            recordImpressions: {
              id,
              eventId,
              count,
              notes,
              staffId,
              Staff: {
                staffID: staffId,
                fullName,
                __typename: 'Staff'
              },
              __typename: 'EventImpression'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventDashboardDataQuery>({
              query: GetEventDashboardDataDocument,
              variables: { eventId }
            });
            if (data && !data.event.EventImpressions.length && updateData?.recordImpressions) {
              const impressions = data.event.EventImpressions.slice();
              impressions.push(updateData.recordImpressions);

              proxy.writeQuery<GetEventDashboardDataQuery>({
                query: GetEventDashboardDataDocument,
                variables: { eventId },
                data: {
                  event: {
                    ...data.event,
                    EventImpressions: impressions
                  }
                }
              });
            }
          }
        }
      );
    },
    recordingImpression: loading.value
  }));
});

export default compose(recordImpressionsEnhancer, withApollo)(SideBar);
