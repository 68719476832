
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import Divider from 'shared/layout/divider/layout-divider.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import StaffLookup from 'shared/components/StaffLookup';
import UIInput from 'shared/ui/forms/Input.vue';
import { RadioGroup, RadioGroupItem } from 'shared/radio-group';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import SchoolLookup from 'shared/components/SchoolLookup';
import Address from 'shared/components/Address/Address.vue';
import fullheight from 'shared/directives/fullheight';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import EventTrackLookup from 'shared/components/EventTrackLookup';
import ZoomUsersLookup from 'shared/components/ZoomUsersLookup';
import DateTimezone from 'date-timezone';
import tz from 'timezone/loaded';
import { Data, Methods, Computed, Props, Event } from './types';
import { getDayOfWeek, days } from '../utils';
import { EditSeriesFields } from '../../types';
import { calculateEventDuration, generateZoomPasscode } from '../../../shared/util';
import ChapterLookup from 'shared/components/ChapterLookup';
import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
import { nonSelectableEventSubTypes } from 'shared/components/EventSubTypeLookup/util';
import Collapsible from 'shared/collapsible';
import { formatAMPMTo24, convertTo24HourFormat, compareDates, getTime, getDate, isFutureDate, getZoomTimeZone } from 'shared/util';
import find from 'lodash/find';
import { ErrorBag } from 'shared/util/types';
import { CreateZoomMeetingDocument, CreateZoomMeetingMutation, CreateZoomMeetingMutationVariables, DeleteZoomMeetingDocument, DeleteZoomMeetingMutation, EditSeriesInput, GetZoomMeetingDocument, GetZoomMeetingMutation, RecurrenceType, Repeat, UpdateZoomMeetingDocument, UpdateZoomMeetingMutation, UpdateZoomMeetingMutationVariables, UpdateZoomOccurrenceDocument, UpdateZoomOccurrenceMutation } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "EditSeries",
  components: {
    ScrollablePanel,
    EventTrackLookup,
    Collapsible,
    Timepicker,
    UISelect,
    SchoolLookup,
    SimpleForm,
    RadioGroup,
    RadioGroupItem,
    UITextarea,
    UICheckbox,
    UISwitch,
    UIButton,
    UIInput,
    Divider,
    Field,
    Error,
    Address,
    StaffLookup,
    ChapterLookup,
    EventSubTypeLookup,
    ZoomUsersLookup
  },
  directives: {
    fullheight
  },
  props: {
    series: {},
    editSeries: {},
    client: {},
    router: {}
  },
  data() {
    return {
      seriesFields: {
        eventName: '',
        startTime: null,
        endTime: null,
        school: null,
        primaryStaff: null,
        staff: [],
        address: null,
        eventTrack: null,
        internal: false,
        dayOfWeek: null,
        isRegionWide: false,
        chapter: null,
        eventSubTypeId: null,
        isTorahYouthGroup: false,
        isBGCubed: false,
        isVirtual: false,
        streamLink: null,
        zoomMeetingId: null,
        zoomUser: null,
        zoomPasscode: null,
      },
      days: days,
      streamSchedule: false
    }
  },
  created() {
    this.seriesFields = {
      eventName: this.targetEvent.eventName || '',
      startTime: this.startTime || null,
      endTime: this.endTime || null,
      school: this.school || null,
      primaryStaff: this.advisor || null,
      staff: this.targetEvent.EventStaff ? this.targetEvent.EventStaff.map(es => es.Staff) : [],
      address: this.address || null,
      eventTrack: this.eventTrack || null,
      internal: this.targetEvent.internal || false,
      dayOfWeek: !this.isCustomEvent && this.targetEvent.startDate && getDayOfWeek(this.targetEvent.startDate) || null,
      isRegionWide: this.targetEvent.isRegionWide || false,
      chapter: this.targetEvent.chapter,
      eventSubTypeId: this.targetEvent.EventSubType && this.targetEvent.EventSubType.eventSubTypeId,
      isTorahYouthGroup: this.targetEvent.baseUrl === 2,
      isBGCubed: this.targetEvent.baseUrl === 4,
      isVirtual: this.targetEvent.isVirtual,
      streamLink: this.targetEvent.streamLink,
      zoomMeetingId: this.targetEvent.zoomMeetingId,
      zoomUser: null,
      zoomPasscode: this.targetEvent.zoomPasscode
    };
  },
  computed: {
    isFutureEventsScheduled () {
      if (!this.series) return false
      return !!find(this.series.Events, (e) => e && isFutureDate(new Date(e.startDate)))
    },
    eventTrack() {
      if (!this.targetEvent.EventTrack) return null;
      return this.targetEvent.EventTrack!
    },
    targetEvent() {
      return this.series.Events[this.series.Events.length - 1];
    },
    startTime() {
      if (!this.targetEvent) return null;
      return getTime(this.targetEvent, 'startDate')
    },
    endTime() {
      if (!this.targetEvent) return null;
      return getTime(this.targetEvent, 'endDate')
    },
    startDate() {
      if (!this.targetEvent) return null;
      return getDate(this.targetEvent, 'startDate')
    },
    endDate() {
      if (!this.targetEvent) return null;
      return getDate(this.targetEvent, 'endDate')
    },
    school() {
      return this.series && this.series.School || null
    },
    advisor() {
      if (!this.targetEvent) return null;
      return this.targetEvent.Owner;
    },
    address() {
      if (!this.targetEvent) return null;
      const { city, state, zip, address1, address2 } = this.targetEvent;
      return {
        zipCode: zip,
        street: address1! || address2,
        city,
        state,
        country: this.series && this.series.Region && this.series.Region.country
      }
    },
    showEventSubType() {
      return !nonSelectableEventSubTypes.includes(this.targetEvent.EventSubType.type || '');
    },
    showSchool() {
      return this.targetEvent.EventSubType.eventSubTypeId !== 290;
    },
    isCustomEvent() {
      return !this.targetEvent.recurInterval || this.targetEvent.recurInterval === 'custom';
    }
  },
  methods: {
    async scheduleZoomHandler(checked, setValue) {
      this.streamSchedule = checked;
      if (checked) {
        setValue('zoomPasscode', generateZoomPasscode());
      } else {
        setValue('zoomPasscode', null);
        await Vue.nextTick();
        setValue('zoomUser', null);
      }
    },
    async isVirtualHandler(checked, setValue) {
      setValue('isVirtual', checked);
      await Vue.nextTick();
      if (checked) {
        if (this.getData('details', 'streamSchedule')) {
          setValue('zoomPasscode', generateZoomPasscode());
        }
      } else {
        setValue('zoomPasscode', null);
        await Vue.nextTick();
        setValue('zoomUser', null);
      }
    },
    startTimeBeforeEndTime (startTime, endTime) {
      if (!this.startDate || !this.endDate || !startTime || !endTime ) return 0;

      DateTimezone.setGlobalTimezone('UTC');

      const startDate = new DateTimezone.DateTimezone(
        this.startDate.year,
        this.startDate.month -1,
        this.startDate.day,
        convertTo24HourFormat(startTime),
        +startTime.minutes,
      );

      const endDate = new DateTimezone.DateTimezone(
        this.endDate.year,
        this.endDate.month -1,
        this.endDate.day,
        convertTo24HourFormat(endTime),
        +endTime.minutes,
      );
      return compareDates(startDate, endDate);
    },
    validate(value) {
      const errors: ErrorBag<EditSeriesFields> = {};

      if (this.showEventSubType && !value.eventSubTypeId) {
        errors['eventSubTypeId'] = 'Event Type is required';
      }
      if (!value.eventName || !value.eventName.trim()) {
        errors['eventName'] = 'Event Name is required';
      }
      if (!value.chapter && !value.isRegionWide) {
        errors['chapter'] = 'Chapter is required for non-region-wide events';
      }
      if (!this.isCustomEvent && !value.dayOfWeek) {
        errors['dayOfWeek'] = 'Day of Week is required';
      }
      if (!value.startTime) {
        errors['startTime'] = 'Start time is required';
      }
      if (!value.endTime) {
        errors['endTime'] = 'End time is required';
      }
      if (this.startTimeBeforeEndTime(value.startTime, value.endTime) === 1) {
        errors['startTime'] = 'The start time has to be before the end time';
      }
      if (value.isVirtual) {
        if (this.streamSchedule && !value.zoomMeetingId && !value.zoomUser) {
          errors['zoomUser'] = 'Please select Zoom User';
        }
        if ((this.streamSchedule || value.zoomMeetingId) && !value.zoomPasscode) {
          errors['zoomPasscode'] = 'Please enter passcode for the Zoom event';
        }
      }
      return errors;
    },
    async editSeriesHandler(args) {
      if ('errors' in args) return;

      const { values: {
        eventName,
        address,
        primaryStaff,
        staff,
        eventTrack,
        dayOfWeek,
        startTime,
        endTime,
        school,
        internal,
        isRegionWide,
        chapter,
        eventSubTypeId,
        isTorahYouthGroup,
        isBGCubed,
        isVirtual,
        zoomUser,
        zoomMeetingId,
        zoomPasscode,
        streamLink,
        ...rest
        }
      } = args;
      const { seriesID: seriesId, regionId } = this.series;
      if (args.setSubmitting) args.setSubmitting();

      let meeting: CreateZoomMeetingMutation['createZoomMeeting'] | null = null;
      if (zoomUser) {
        const seriesEvents = this.series.Events.filter(e => new Date(e.startDate).getTime() > new Date().getTime());
        seriesEvents.sort((a: Event, b: Event) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
        if (seriesEvents.length < 1) {
          seriesEvents.push(this.targetEvent);
        }
        let zoomStartDate = seriesEvents[0].startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(seriesEvents[0].TimeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(seriesEvents[0].TimeZone);
          zoomStartDate = tz(tz(seriesEvents[0].startDate as string), '%FT%T', zoomTimeZone);
        }
        const meetingInput: CreateZoomMeetingMutationVariables = {
          id: zoomUser.id,
          input: {
            start_time: zoomStartDate!.replace(/\.000Z/, ''),
            duration: calculateEventDuration(seriesEvents[0].startDate as string, seriesEvents[0].endDate as string),
            timezone: zoomTimeZone,
            topic: eventName,
            type: 8,
            password: zoomPasscode,
            recurrence: {
              repeat_interval: 1,
              type: RecurrenceType.Daily,
              end_times: seriesEvents.length || 0
            }
          }
        }
        const result = await this.client.mutate<CreateZoomMeetingMutation>({
          mutation: CreateZoomMeetingDocument,
          variables: meetingInput
        });
        meeting = result.data && result.data.createZoomMeeting || null;
        await this.client.mutate<UpdateZoomOccurrenceMutation>({
          mutation: UpdateZoomOccurrenceDocument,
          variables: {
            OccurencyDatasInput: seriesEvents.map((event, index) => {
              const eventOccurrency = new Date(seriesEvents[0].startDate);
              eventOccurrency.setUTCDate(eventOccurrency.getUTCDate() + index);
              return {
                eventId: event.eventId,
                OccurencyId: eventOccurrency.toISOString()
              }
            })
          }
        })
      }

      const editSeriesVariables: EditSeriesInput = {
        seriesId,
        regionId,
        ...rest,
        internal,
        isRegionWide,
        eventSubTypeId,
        isTorahYouthGroup,
        isBGCubed,
        chapterId: !isRegionWide ? chapter && chapter.chapterId : null,
        zip: address && address.zipCode,
        city: address && address.city,
        state: address && address.state,
        address1: address && address.street,
        name: eventName,
        advisorId: primaryStaff && primaryStaff.staffID,
        advisorsParticipating: staff.map(s => s.staffID),
        eventTrackId: eventTrack && eventTrack.id,
        dayOfWeek: dayOfWeek!,
        startTime: {
          hours: formatAMPMTo24(+startTime!.hours, startTime!.ampm)!,
          minutes: +startTime!.minutes
        },
        endTime:  {
          hours: formatAMPMTo24(+endTime!.hours, endTime!.ampm)!,
          minutes: +endTime!.minutes
        },
        schoolId: school && school.schoolID,
        isVirtual
      };

      if (isVirtual && this.targetEvent.zoomMeetingId && this.targetEvent.zoomPasscode !== zoomPasscode) {
        await this.client.mutate<UpdateZoomMeetingMutation>({
          mutation: UpdateZoomMeetingDocument,
          variables: {
            id: zoomMeetingId,
            input: {
              password: zoomPasscode
            }
          }
        })

        const result = await this.client.mutate<GetZoomMeetingMutation>({
          mutation: GetZoomMeetingDocument,
          variables: {
            id: zoomMeetingId,
          }
        })

        meeting = result.data && result.data.getZoomMeeting || null;
      }

      if (isVirtual) {
        if (meeting || zoomMeetingId) {
          editSeriesVariables.zoomMeetingId = meeting && meeting.id || zoomMeetingId || null;
          editSeriesVariables.zoomPasscode = meeting && meeting.password || null;
          editSeriesVariables.zoomPasscodeHashed = meeting && meeting.encrypted_password || null;
          editSeriesVariables.streamLink = null;
        } else {
          if (streamLink && !streamLink.match(/^http(s?):\/\//)) {
            editSeriesVariables.streamLink = 'https://' + streamLink;
          }
          editSeriesVariables.zoomMeetingId = null;
          editSeriesVariables.zoomPasscode = null;
          editSeriesVariables.zoomPasscodeHashed = null;
        }
      } else {
        if (zoomMeetingId) {
          try {
            await this.client.mutate<DeleteZoomMeetingMutation>({
              mutation: DeleteZoomMeetingDocument,
              variables: {
                id: zoomMeetingId
              }
            });
          } catch(e) {
            Sentry.withScope((scope) => {
              scope.setExtra('error', e);
              Sentry.captureMessage('On edit series');
            })
          }
        }
        editSeriesVariables.zoomMeetingId = null;
        editSeriesVariables.streamLink = null;
        editSeriesVariables.zoomPasscode = null;
        editSeriesVariables.zoomPasscodeHashed = null;
      }

      const updatedSeries = await this.editSeries(editSeriesVariables);

      if (isVirtual && (meeting || zoomMeetingId)) {
        const seriesEvents = (updatedSeries?.data?.editSeries.Events || []).filter(e => new Date(e.startDate).getTime() > new Date().getTime());
        seriesEvents.sort((a: Event, b: Event) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
        if (seriesEvents.length < 1) {
          seriesEvents.push(this.targetEvent);
        }
        if (this.isCustomEvent) {
          const zoomId = zoomMeetingId || (meeting && meeting.id);
          if (zoomId) {
            let zoomStartDate = seriesEvents[0].startDate;
            let zoomTimeZone = 'UTC';
            const duration = calculateEventDuration(seriesEvents[0].startDate as string, seriesEvents[0].endDate as string)
            for (let index = (seriesEvents.length - 1); index >= 0; index--) {
              if (getZoomTimeZone(seriesEvents[index].TimeZone) !== 'UTC') {
                zoomTimeZone = getZoomTimeZone(seriesEvents[index].TimeZone);
                zoomStartDate = tz(tz(seriesEvents[index].startDate as string), '%FT%T', zoomTimeZone);
              }
              await this.client.mutate<UpdateZoomMeetingMutation>({
                mutation: UpdateZoomMeetingDocument,
                variables: {
                  id: zoomId,
                  input: {
                    start_time: zoomStartDate,
                    duration,
                    occurrence_id: new Date(seriesEvents[index].zoomOccurencyId).getTime()
                  }
                }
              });
            }
          }
        } else {
          let zoomStartDate = seriesEvents[0].startDate;
          let zoomTimeZone = 'UTC';
          if (getZoomTimeZone(seriesEvents[0].TimeZone) !== 'UTC') {
            zoomTimeZone = getZoomTimeZone(seriesEvents[0].TimeZone);
            zoomStartDate = tz(tz(seriesEvents[0].startDate as string), '%FT%T', zoomTimeZone);
          }
          const updateMeetingInput: UpdateZoomMeetingMutationVariables = {
            id: meeting && meeting.id || zoomMeetingId!,
            input: {
              start_time: zoomStartDate.replace(/\.000Z/, ''),
              duration: calculateEventDuration(seriesEvents[0].startDate as string, seriesEvents[0].endDate as string),
              timezone: zoomTimeZone,
              topic: eventName,
              type: 8,
              recurrence: {
                end_date_time: seriesEvents[seriesEvents.length - 1].endDate.replace(/0\.000Z/, '1Z')
              }
            }
          }
          if (updateMeetingInput && (seriesEvents[0].recurInterval === Repeat.Weekly || seriesEvents[0].recurInterval === Repeat.Biweekly || seriesEvents[0].recurInterval === Repeat.Triweekly)) {
            updateMeetingInput.input.recurrence!.type = RecurrenceType.Weekly;
            updateMeetingInput.input.recurrence!.weekly_days = String(new Date(seriesEvents[0].startDate as string).getUTCDay() + 1);

            if (seriesEvents[0].recurInterval === Repeat.Biweekly) {
              updateMeetingInput!.input.recurrence!.repeat_interval = 2;
            } else if (seriesEvents[0].recurInterval === Repeat.Triweekly) {
              updateMeetingInput!.input.recurrence!.repeat_interval = 3;
            }
          } else if (seriesEvents[0].recurInterval === Repeat.Monthly) {
            updateMeetingInput!.input.recurrence!.type = RecurrenceType.Monthly;
            updateMeetingInput!.input.recurrence!.monthly_day = new Date(seriesEvents[0].startDate as string).getUTCDate();
          }
          await this.client.mutate<UpdateZoomMeetingMutation>({
            mutation: UpdateZoomMeetingDocument,
            variables: updateMeetingInput
          });
        }
      }

      if (args.setSubmitted) args.setSubmitted();
      this.router.history.push(`/events/series/${seriesId}/summary`);
    }
  }
})
