
import Vue from 'vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Loading from 'shared/components/Loading.vue';
import NoData from 'shared/components/NoData.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { RouterLink } from 'vue-component-router';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import Modal from 'shared/ui/modal/Modal.vue';
import RequiresPermission from 'shared/components/RequiresPermission';
import { Props, Data, Computed, Methods } from './types';
import { PermissionEnum } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'ListLookup',
  data() {
    return {
      PermissionEnum,
      listSelected: null
    };
  },
  components: {
    Loading,
    NoData,
    PanelsGrid,
    ScrollablePanel,
    RouterLink,
    BorderedList,
    PanelsSidebar,
    ChapterLookup,
    UISearch,
    UIButton,
    Modal,
    RequiresPermission
  },
  directives: {
    fullheight,
  },
  props: {
    filters: {},
    setFilter: {},
    clearFilters: {},
    currentPage: {},
    limit: {},
    teenLists: {},
    teenListsTotal: {},
    loading: {},
    submitting: {},
    me: {},
    regionId: {},
    personId: {}
  },
  methods: {
    selectList(list) {
      this.listSelected = list;
    }
  },
  computed: {
    currentAdvisorRegion() {
      return this.me && this.regionId &&
        this.me.AdvisorRegions.find(a => a.Region.regionId === this.regionId) || {
          claims: [],
          AdvisorRegionId: 0,
          Region: {
            regionName: '',
            regionId: 0,
            country: '',
            __typename: 'Region'
          },
          active: false,
          Role: null,
          __typename: 'AdvisorRegion'
        };
    },
  }
});
