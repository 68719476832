import Summary from './Summary.vue';
import { compose } from 'vue-compose';
import { withApollo } from 'shared/components/graphql';
import {
  updatePersonEnhancer,
  updateEmailEnhancer,
  updatePhoneEnhancer,
  deletePhoneEnhancer,
  updateAddressEnhancer,
  createEmailEnhancer,
  createPhoneEnhancer,
  createAddressEnhancer
} from 'shared/enhancers/communityMembersEnhancer';

export const enhancer = compose(
  withApollo,
  updatePersonEnhancer,
  updateEmailEnhancer,
  updatePhoneEnhancer,
  deletePhoneEnhancer,
  updateAddressEnhancer,
  createEmailEnhancer,
  createPhoneEnhancer,
  createAddressEnhancer,
);

export default enhancer(Summary);
