
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import Collapsible from 'shared/collapsible';

@Component({
	name: 'CollapsibleSection',
	components: {
		Collapsible
	}
})
export default class extends Vue {
	@Prop() header!: string;

	@Prop() simple!: boolean;

	@Prop() sectionNumber!: string;

	@Prop() startOpen!: boolean;

	@Prop() icon!: string;

	@Prop() noCollapse!: boolean;

	open = this.startOpen || false;
}
