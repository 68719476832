import { computed } from 'vue';
import { MutateResult } from '@vue/apollo-composable';
import EditSeriesContainer from './EditSeries.vue';
import { withApollo } from 'shared/components/graphql';
import { compose } from 'vue-compose';
import { withRouter } from 'vue-component-router';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  EditSeriesInput,
  EditSeriesMutation,
  useEditSeriesMutation
} from 'shared/generated/graphql-types';

interface EditSeriesFn {
  editSeries: (input: EditSeriesInput) => MutateResult<EditSeriesMutation>;
}

const editSeriesEnhancer = wrapComponent<{}, EditSeriesFn>(() => {
  const { mutate } = useEditSeriesMutation();

  return computed(() => ({
    editSeries: (input) => mutate({ input })
  }));
});

export default compose(withApollo, editSeriesEnhancer, withRouter)(EditSeriesContainer);
