<template>
	<VueMultiSelect
		v-bind="$attrs"
		:value="value"
		v-on="$listeners"
		@input="$emit('input', $event)"
		@select="$emit('blur', $event)"
	></VueMultiSelect>
</template>
<script>
import VueMultiSelect from 'vue-multiselect';

export default {
	inheritAttrs: false,
	name: 'UISelect',
	props: ['value'],
	components: { VueMultiSelect }
};
</script>

<style lang="scss" scoped>
</style>
