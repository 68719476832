import StaffProfile from './StaffProfile.vue';
import { compose, withData } from 'vue-compose';
import { toCompactStaff, calcStaffUpdate } from './utils';
import { Props, StaffViewModel } from './types';
import {
  UpdateStaffInput,
  UploadStaffImageProfileMutationVariables,
  useDeleteStaffProfileImageMutation,
  useGetStaffProfileQuery,
  useUpdateStaffMutation,
  useUploadStaffImageProfileMutation
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';

interface UpdateStaffProps {
  updateStaff: (staff: UpdateStaffInput, staffViewModel: StaffViewModel) => void;
  updatingStaff: boolean;
}
export const updateStaffEnhancer = wrapComponent<Props, UpdateStaffProps>((props) => {
  const { loading, mutate } = useUpdateStaffMutation();

  return computed(() => ({
    updateStaff: (staff, staffViewModel) =>
      mutate(
        { staff, regionId: props.regionId },
        {
          optimisticResponse: {
            updateStaff: {
              ...calcStaffUpdate(staffViewModel)!
            }
          }
        }
      ),
    updatingStaff: loading.value
  }));
});

interface StaffProfileProps {
  staff: StaffViewModel | null;
  loadingStaffProfile: boolean;
}
const getStaffProfileEnhancer = wrapComponent<Props, StaffProfileProps>((props) => {
  const { loading, result } = useGetStaffProfileQuery(
    computed(() => ({ staffID: +props.staffId, regionId: props.regionId }))
  );

  return computed(() => ({
    staff: result.value?.singleStaff ? toCompactStaff(result.value.singleStaff) : null,
    loadingStaffProfile: loading.value
  }));
});

interface UploadStaffImageProps {
  uploadStaffImageProfile: (args: UploadStaffImageProfileMutationVariables) => void;
  uploadingStaffImageProile: boolean;
}
const uploadStaffImageEnhancer = wrapComponent<Props, UploadStaffImageProps>((props) => {
  const { loading, mutate } = useUploadStaffImageProfileMutation();

  return computed(() => ({
    uploadStaffImageProfile: ({ image, staffId }) => mutate({ image, staffId }),
    uploadingStaffImageProile: loading.value
  }));
});

interface DeleteStaffProfileImageProp {
  deleteStaffProfileImage: (staffId: number, staffCopy: StaffViewModel) => void;
  deletingProfileImage: boolean;
}
const deleteStaffProfileImageEnhancer = wrapComponent<Props, DeleteStaffProfileImageProp>(
  (props) => {
    const { loading, mutate } = useDeleteStaffProfileImageMutation();

    return computed(() => ({
      deleteStaffProfileImage: (staffId, staffCopy) =>
        mutate(
          { staffId },
          {
            optimisticResponse: {
              deleteStaffProfileImage: {
                staffID: staffId,
                thumbnail: null,
                __typename: 'Staff'
              }
            }
          }
        ),
      deletingProfileImage: loading.value
    }));
  }
);

export const enhancer = compose(
  getStaffProfileEnhancer,
  updateStaffEnhancer,
  uploadStaffImageEnhancer,
  deleteStaffProfileImageEnhancer
);

export default enhancer(StaffProfile);
