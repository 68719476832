
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import EventFilter from './components/EventTableFilter.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Loading from 'shared/components/Lock-ui.vue';
import Pagination from 'shared/components/Pagination';
import fullheight from 'shared/directives/fullheight';
import { dateFormat } from 'shared/util';
import { navigateTo } from './util';
import Divider from 'shared/layout/divider/layout-divider.vue';
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import { EventFilterEventType, EventFilterInput, GetEventsTableQuery, MergeSeriesMutationVariables, RegionWideFilter } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = ArrayElement<NonNullable<GetEventsTableQuery['eventsTable']>['events']>
type EventGrouped = Event[] & { events: Event[] };

interface Data {
  checkedSeries: any[];
}

interface Props {
  user: number;
  currentRegion: number;
  events: Event[];
  filters: EventFilterInput;
  clearFilters: () => void;
  mergeSeries: (args: MergeSeriesMutationVariables, filters: EventFilterInput) => any;
  fetching: boolean;
  mergingSeries: boolean;
  currentPage: number;
  limit: number;
  total: number;
  router: { history: { push: (location: string) => void } };
}

interface Computed {
  isSeriesFilter: boolean;
  totalPages: number;
  eventsGrouped: EventGrouped[] | Event[];
}

interface Methods {
  dateFormat: (ISOdate: string) => string | null;
  navigateTo: (event: Event) => string;
  mergeSeriesHandler: () => Promise<void>;
  clearCheckedSeries: () => void;
  sortEvents: (sortBy: string) => void;
  setFilterHandler: (value: any, filter: string) => void;
  onPageChange: (page: number) => void;
}

const sortMap: { [key: string]: string } = {
  name: 'eventName',
  date: 'startDateLocal',
  chapter: 'chapter',
  type: 'EventSubType.description'
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'EventTable',
  components: {
    EventFilter,
    ScrollablePanel,
    PanelsSidebar,
    Divider,
    PanelsGrid,
    ActionBar,
    BorderedList,
    Pagination,
    Loading,
    RouterLink
  },
  data() {
    return {
      checkedSeries: []
    };
  },
  props: {
    user: {},
    currentRegion: {},
    events: {},
    filters: {},
    clearFilters: {},
    mergeSeries: {},
    fetching: {},
    mergingSeries: {},
    currentPage: {},
    limit: {},
    total: {},
    router: {}
  },
  computed: {
    isSeriesFilter() {
      return this.filters.EventType === EventFilterEventType.Series;
    },
    totalPages() {
      if (this.limit === this.total) return this.total / this.limit;
      return Math.ceil(this.total / this.limit);
    },
    eventsGrouped() {
      if (this.isSeriesFilter) {
        const group = groupBy(this.events, 'seriesId');
        const events = Object.keys(group).map((g) => ({ ...group[g][0], events: group[g] }));
        if (this.filters.orderBy && this.filters.orderBy.field) {
          // @ts-ignore
          return orderBy(
            events,
            ['name', 'chapter', 'type'].includes(this.filters.orderBy!.field as string)
              ? [(event: Event) => get(event, sortMap[this.filters.orderBy!.field!]).toLowerCase()]
              : sortMap[this.filters.orderBy!.field! as string],
            this.filters.orderBy.asc ? 'asc' : 'desc'
          ) as EventGrouped[];
        } else {
          return events;
        }
      } else {
        return this.events;
      }
    }
  },
  directives: {
    fullheight
  },
  methods: {
    dateFormat,
    navigateTo,
    async mergeSeriesHandler() {
      const [remainingID, ...deleteIDs] = this.checkedSeries;
      try {
        await this.mergeSeries(
          {
            remainingID,
            deleteIDs
          },
          this.filters
        );
        this.checkedSeries = [];
      } catch (e) {
        this.checkedSeries = [];
      }
    },
    clearCheckedSeries() {
      this.checkedSeries = [];
    },
    sortEvents(sortBy: string) {
      this.setFilterHandler(
        {
          field: sortBy,
          asc: this.filters.orderBy!.field === sortBy ? !this.filters.orderBy!.asc : true
        },
        'orderBy'
      );
    },
    setFilterHandler(value: any, filter: string) {
      this.$emit('filters', {
        ...this.filters,
        ...(this.filters.regionWide === RegionWideFilter.Only && filter !== RegionWideFilter.Only
          ? { regionWide: RegionWideFilter.Show }
          : {}),
        [filter]: value
      });
      if (filter) this.$emit('currentPage', 1);
    },
    onPageChange(page: number) {
      this.$emit('currentPage', page);
    }
  },
  watch: {
    'filters.EventType': {
      handler: function (val) {
        this.checkedSeries = [];
      }
    }
  }
});
