
import Vue from 'vue';
import { FilterOption, FilterOptionGroup } from './types';
import { toggleInArray } from 'shared/util';

interface Props {
  selectedFilters: FilterOption[];
  filterGroup: FilterOptionGroup;
}
interface Computed {
  activeKeys: Array<FilterOption['key']>;
}
interface Methods {
  isActive: (filter: FilterOption) => boolean;
  select: (filter: FilterOption) => void;
  selectAll: () => void;
  clearAll: () => void;
}

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'FilterTypeDisplay',
  props: {
    selectedFilters: {},
    filterGroup: {}
  },
  computed: {
    activeKeys() {
      return this.selectedFilters.map(f => f.key);
    }
  },
  methods: {
    isActive (filter:FilterOption) {
      return this.activeKeys.includes(filter.key);
    },
    select (filter: FilterOption) {
      const selectedFilters = [...this.selectedFilters]
      const f = selectedFilters.find(x => x.key === filter.key) || filter;

      toggleInArray(selectedFilters, f);
      this.$emit('selectionMade', selectedFilters);
    },
    selectAll () {
      const selectedFilters = [...this.selectedFilters]

      for (const f of this.filterGroup.options) {
        if (!this.isActive(f)) {
          selectedFilters.push(f);
        }
      }
      this.$emit('selectionMade', selectedFilters);
    },
    clearAll () {
      const selectedFilters = [...this.selectedFilters]

      selectedFilters.splice(0);
      this.$emit('selectionMade', selectedFilters);
    }
  }
})
