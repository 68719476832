
import Collapsible from 'shared/collapsible';
import Vue from 'vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import { RouterLink } from 'vue-component-router';

interface Props {
  label: string;
  name: string;
  stepNum: string;
}

interface Data {
  entrances: number;
  isConfirm: boolean;
  isCanConfirm: boolean;
}

interface Computed {
  displayErrors: boolean;
}

interface Methods {
  completedOrVerified: (name: string) => boolean;
  getData: (field: string) => any;
  setData:(field: string, value: any) => void;
  handleClick: () => void;
  nextStep: () => void;
  cancelHandler: () => void;
  saveHandler: () => void;
  isConfirmCondition: (condition: boolean) => boolean;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'Step',
  components: {
    Collapsible,
    UIButton,
    RouterLink
  },
  props: {
    label: {},
    name: {},
    stepNum: {}
  },
  data() {
    return {
      entrances: 0,
      isConfirm: false,
      isCanConfirm: false
    }
  },
  computed: {
    displayErrors() {
      return !(this.$parent!.firstStep(this.name) && this.entrances === 0);
    }
  },
  methods: {
    completedOrVerified(name: string) {
      return this.$parent!.steps[name].completed || (this.entrances > 0 && !this.$parent!.steps[name].validations);
    },
    getData(field: string) {
      return this.$parent!.getData(this.name, field);
    },
    setData(field: string, value: any) {
      ++this.entrances;
      return this.$parent!.setData(this.name, field, value);
    },
    handleClick() {
      if (this.$scopedSlots.confirmation && this.isCanConfirm) {
        this.isConfirm = true;
        return;
      }
      this.nextStep();
    },
    nextStep() {
      if (!this.$parent!.finalStep(this.name)) {
        this.$parent!.complete(this.name);
      } else {
        this.$parent!.complete(this.name, false);
      }
    },
    cancelHandler() {
      this.isConfirm = false;
    },
    saveHandler() {
      this.isConfirm = false;
      this.nextStep();
    },
    isConfirmCondition(condition) {
      this.isCanConfirm = condition;
      return condition;
    }
  }
})
