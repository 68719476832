<template>
  <label class="checkbox" :class="{active : value, withIcon: hasIconSlot}">
    <input type="checkbox" :checked="value" :name="name" @change="onChange"/>
    <div class="icon"  v-if="hasIconSlot()"><slot name="icon"></slot></div>
    <span class="check" v-else><font-awesome-icon :icon="['far','check']" /></span>
    <span class="text">{{labelText}}</span>
  </label>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';

library.add(faCheck)


export default {
  name: 'UICheckbox',
  props: {
    value: Boolean,
    name: String,
    labelText: String
  },
	methods: {
		onChange ($event) {
			this.$emit('input', $event.target.checked);
			this.$emit('blur', $event.target.checked);
    },
    hasIconSlot() {
      return !!this.$slots.icon
    }
	}
};
</script>

<style lang="scss" scoped>
  .checkbox {
    display: flex;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    border: 1px solid #ddd;
    padding: 10px 20px;
    align-items: center;
  }
  .checkbox.active,
  .checkbox[value="true"] {
    border: 2px solid #ea7d1c;
  }
  .checkbox input {
    visibility: hidden;
    position: absolute;
    left: -999px;
  }
  .text {
    font-weight: 400;
    color: #999;
    text-transform: none;
  }
  .checkbox[value="true"] .text,
  .checkbox input:checked ~ .text {
    color: #ea7d1c;
    font-weight: 600;
  }
  .check {
    display: none;
    color: #ea7d1c;
  }
  .checkbox[value="true"] .check,
  .checkbox input:checked ~ .check {
    display: inline-block;
    margin-right: 5px;
  }
  .icon {
    font-size: 24px;
    color: #999;
    display: block;
    margin: 0 10px 0px 0px;
    text-align: center;
  }
  .checkbox input:checked ~ .icon{
    color: #ea7d1c;
  }
  .withIcon .text {
    font-size: 11px;
  }
</style>
