

import { Vue, Prop, Component } from "vue-property-decorator";
import { dateFormat } from 'shared/util';
import Loading from 'shared/components/Loading.vue';
import { Registration } from './types';
import sumBy from 'lodash/sumBy';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';

@Component({
	name: "TeenTimeline",
	components: {
    Loading,
    ScrollablePanel
	},
	methods: {
		dateFormat
	}
})
export default class TeenTimeline extends Vue {
  @Prop() personID!: number;
  @Prop() isLoading!: boolean;
	@Prop() scholarshipHistory!: Registration[];

  get totalRecivied () {
    const filteredScholarshipGrant = this.scholarshipHistory.filter(r => r.scholarshipGrant);
    return sumBy(filteredScholarshipGrant, r => r.scholarshipGrant!)
  }
}

