<template>
  <label class="switch" :style="{width:width + 'px'}">
    <input type="checkbox" :checked="value" @input="toggle($event)" />
    <span class="slider round"></span>
    <span class="text">{{ displayText }}</span>
  </label>
</template>
<script>
export default {
	name: 'UISwitch',
  props: {
		value: {
			type: Boolean
		},
    width: {
      type: String,
      default: '70'
    },
		onText: {
      default: 'Yes',
			type: String
		},
    offText: {
      default: 'No',
			type: String
		}
	},
	computed: {
		displayText() {
			return this.value ? this.onText : this.offText;
		}
	},
	methods: {
		toggle($event) {
			this.$emit('input', $event.target.checked);
			this.$emit('change', $event.target.checked);
		}
	}
};
</script>

<style lang="scss" scoped>
  .switch {
    position: relative;
    display: inline-block;
    min-width: 50px;
    height: 24px;
  }

.switch input {
  visibility: hidden;
  position: absolute;
  left: -9999;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: #dfe3e4;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 32px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #8fc841;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8fc841;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: auto;
  right: 29px;
}
.text {
  position: absolute;
  color: #666;
  left: 30px;
  top: 4px;
}
input:checked ~ .text {
  left: 10px;
  color: white;
}

</style>
