<template>
    <input v-bind="$attrs" :value="localValue" v-on="listeners" :placeholder="placeholder" :style="{width:width}" autocomplete="hack"/>
</template>
<script>
import { isIsoDate, filterNonPrintable } from 'shared/util';

export default {
  name: 'UIInput',
  props: {
    value: {
      type: String | Number
    },
    placeholder: {
      type: String
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    localValue () {
      if (isIsoDate(this.value)) {
        return this.value.split('T')[0]
      }
      return this.value;
    }
  },
  created () {
    this.listeners = {
      ...this.$listeners,
      input: e => this.$emit('input', filterNonPrintable(e.target.value))
    };
  }
};
</script>

<style lang="scss" scoped>
input {
  height: 36px;
  display: block;
  border: 1px solid #f3f3f3;
  padding: 8px 12px;
  font-size: 14px;
  background: #f3f3f3;
  box-shadow: 0 0 0 #EAEAEA;
  width: 100%;
  transition: all .3s ease-in-out;
  border-radius: 4px;

  &:hover {
    border-color: #c9d4bd;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 5px #d1ecda;
    border-color: #c9d4bd;
    background: white;
  }
  &:disabled {
    cursor: not-allowed;
    background: #f8f8f8;
  }
}

input::-webkit-input-placeholder { color: #ccc;}
input::-webkit-input-placeholder { color: #ccc;}
input::-moz-placeholder { color: #ccc;}
input:-ms-input-placeholder { color: #ccc;}
</style>
