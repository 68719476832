var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"update-parent-info"},[(_vm.teen.fatherId)?_c('div',{staticClass:"parent"},[_vm._m(0),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Name")]),_c('AutoSaveField',{attrs:{"name":"fatherName","entity":_vm.teenCopy,"transform":(c) => ({
              parentId: _vm.teenCopy.fatherId,
              value: JSON.stringify(_vm.splitName(c)),
              fieldName: _vm.updateTeenField.FatherName
            }),"validators":[{ name: 'fullName', validator: (name) => !name || _vm.validateName(name) }]},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c('UIInput',_vm._g(_vm._b({attrs:{"data-test":"fatherNameField","type":"text","placeholder":"Father Name"}},'UIInput',$props,false),$listeners)),(hasError('fullName'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter the first and last name")]):_vm._e()]}}],null,false,2897301649)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Email Address")]),_c('AutoSaveField',{attrs:{"name":"fatherEmail","entity":_vm.teenCopy,"transform":(c) => ({
              parentId: _vm.teenCopy.fatherId,
              value: c,
              fieldName: _vm.updateTeenField.FatherEmail
            })},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError, addErrors }){return [_c('EmailInput',_vm._g(_vm._b({attrs:{"ownerType":"father","placeholder":"Father Email","narrow":true,"ownerId":_vm.teenCopy.fatherId,"showErrors":true},on:{"populate":($event) => _vm.connectParentToTeenHandler($event.values)}},'EmailInput',$props,false),$listeners))]}}],null,false,1032748495)})],1)]),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Cellphone Number")]),_c('AutoSaveField',{attrs:{"name":"fatherCellPhone","entity":_vm.teenCopy,"toForm":(c) => (_vm.validateNumber(c) ? _vm.phoneFormat(c) : ''),"toEntity":_vm.phoneInternational,"transform":(c) => ({
              parentId: _vm.teenCopy.fatherId,
              value: c,
              fieldName: _vm.updateTeenField.FatherCellPhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _vm.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c('PhoneInput',_vm._g(_vm._b({attrs:{"type":"tel","name":"fatherCellPhone","placeholder":"Father CellPhone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}],null,false,502147886)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Homephone Number")]),_c('AutoSaveField',{attrs:{"name":"fatherHomePhone","entity":_vm.teenCopy,"toForm":(c) => (_vm.validateNumber(c) ? _vm.phoneFormat(c) : ''),"toEntity":_vm.phoneInternational,"transform":(c) => ({
              parentId: _vm.teenCopy.fatherId,
              value: c,
              fieldName: _vm.updateTeenField.FatherHomePhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _vm.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c('PhoneInput',_vm._g(_vm._b({attrs:{"type":"tel","name":"fatherHomePhone","placeholder":"Father Home Phone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}],null,false,2980524839)})],1)])]):_c('div',[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"male","fixed-width":""}})],1),_vm._v(" No Father - "),_c('a',{staticClass:"parent-link",on:{"click":function($event){_vm.showAddFather = true}}},[_vm._v("Add Father")]),_c('Modal',{attrs:{"show":_vm.showAddFather,"width":"400px","requestClose":() => (_vm.showAddFather = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"parent-modal"},[_c('QuickAddParent',{attrs:{"name":"Father","associateHandler":_vm.handleAssociate,"familyId":_vm.teen.primaryFamily.id,"sourceGender":_vm.Gender.Male,"sourceMaritalStatus":_vm.teen.primaryFamily.status,"ownerType":_vm.EntityUnionEnum.Father,"modalMode":true,"addingParent":_vm.addingParentToFamily || _vm.connectingParentToTeen},on:{"requestClose":requestClose}})],1)]}}])})],1),(_vm.teen.motherId)?_c('div',{staticClass:"parent"},[_vm._m(1),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Name")]),_c('AutoSaveField',{attrs:{"name":"motherName","entity":_vm.teenCopy,"transform":(c) => ({
              parentId: _vm.teenCopy.motherId,
              value: JSON.stringify(_vm.splitName(c)),
              fieldName: _vm.updateTeenField.MotherName
            }),"validators":[{ name: 'fullName', validator: (name) => !name || _vm.validateName(name) }]},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c('UIInput',_vm._g(_vm._b({attrs:{"data-test":"motherNameField","type":"text","placeholder":"Mother Name"}},'UIInput',$props,false),$listeners)),(hasError('fullName'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter the first and last name")]):_vm._e()]}}],null,false,4013920401)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Email Address")]),_c('AutoSaveField',{attrs:{"name":"motherEmail","entity":_vm.teenCopy,"transform":(c) => ({
              parentId: _vm.teenCopy.motherId,
              value: c,
              fieldName: _vm.updateTeenField.MotherEmail
            })},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError, addErrors }){return [_c('EmailInput',_vm._g(_vm._b({attrs:{"ownerType":"mother","placeholder":"Mother Email","narrow":true,"left":true,"ownerId":_vm.teenCopy.motherId,"showErrors":true,"validateOnBlur":true},on:{"populate":($event) => _vm.connectParentToTeenHandler($event.values)}},'EmailInput',$props,false),$listeners))]}}],null,false,4179655199)})],1)]),_c('div',{staticClass:"form-row bigger"},[_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Cellphone Number")]),_c('AutoSaveField',{attrs:{"name":"motherCellPhone","entity":_vm.teenCopy,"toForm":(c) => (_vm.validateNumber(c) ? _vm.phoneFormat(c) : ''),"toEntity":_vm.phoneInternational,"transform":(c) => ({
              parentId: _vm.teenCopy.motherId,
              value: c,
              fieldName: _vm.updateTeenField.MotherCellPhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _vm.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c('PhoneInput',_vm._g(_vm._b({attrs:{"type":"tel","name":"motherCellPhone","placeholder":"Mother CellPhone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}],null,false,4125346350)})],1),_c('div',{staticClass:"form-col"},[_c('label',[_vm._v("Homephone Number")]),_c('AutoSaveField',{attrs:{"name":"motherHomePhone","entity":_vm.teenCopy,"toForm":(c) => (_vm.validateNumber(c) ? _vm.phoneFormat(c) : ''),"toEntity":_vm.phoneInternational,"transform":(c) => ({
              parentId: _vm.teenCopy.motherId,
              value: c,
              fieldName: _vm.updateTeenField.MotherHomePhone
            }),"validators":[
            { name: 'validateNumber', validator: (value) => _vm.validateNumber(value, true) }
          ]},on:{"update:entity":function($event){_vm.teenCopy=$event},"save":_vm.updateParentField},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c('PhoneInput',_vm._g(_vm._b({attrs:{"type":"tel","name":"motherHomePhone","placeholder":"Mother Home Phone"}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}],null,false,1990766695)})],1)])]):_c('div',[_c('span',{staticClass:"icn"},[_c('font-awesome-icon',{attrs:{"icon":"female","fixed-width":""}})],1),_vm._v(" No Mother - "),_c('a',{staticClass:"parent-link",on:{"click":function($event){_vm.showAddMother = true}}},[_vm._v("Add Mother")]),_c('Modal',{attrs:{"show":_vm.showAddMother,"width":"400px","requestClose":() => (_vm.showAddMother = false)},scopedSlots:_vm._u([{key:"default",fn:function({ requestClose }){return [_c('div',{staticClass:"parent-modal"},[_c('QuickAddParent',{attrs:{"name":"Mother","associateHandler":_vm.handleAssociate,"familyId":_vm.teen.primaryFamily.id,"sourceGender":_vm.Gender.Female,"sourceMaritalStatus":_vm.teen.primaryFamily.status,"ownerType":_vm.EntityUnionEnum.Mother,"modalMode":true,"addingParent":_vm.addingParentToFamily || _vm.connectingParentToTeen},on:{"requestClose":requestClose}})],1)]}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Father")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('strong',[_vm._v("Mother")])])
}]

export { render, staticRenderFns }