
import Vue from 'vue';
import { Props } from './types';
import ImageSelector from 'shared/components/ImageSelector.vue';

interface Methods {
  isHasErrors: (key: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, Props>({
  name: 'Thumbnail',
  components: {
    ImageSelector
  },
  props: {
    getData: {},
    displayErrors: {},
    setData: {},
    step: {},
    steps: {}
  },
  methods: {
    isHasErrors(key) {
      return this.step.validations && this.step.validations[key];
    }
  }
})
