

import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import range from 'lodash/range';
import PostHighSchoolLookup from 'shared/components/PostHighSchoolLookup';
import { getCurrentSeason } from 'shared/util';

@Component({
  components: {
    PostHighSchoolLookup
  }
})
export default class IsraelProgramIdPicker extends Vue {
  @Prop() value!: number;
}
