<template>
  <div class="range">
    <span class="min">{{symbol}}{{min}}</span>
    <span class="max">{{symbol}}{{current}}</span>
    <input v-bind="$attrs" :value="value" type="range" @input="onInput" :min="min" :max="max" :step="steps" ref="range">
  </div>
</template>
<script>
export default {
  name: 'UIRange',
  props: ['value', 'min', 'max', 'steps', 'symbol'],
  data ()  {
		return {
			current: this.value,
      currentPos: 0
		};
  },
  methods: {
    onInput ($event) {
		  this.$emit('input', Number($event.target.value));
      this.$emit('blur');
      this.current = Number($event.target.value);
	  }
  }
};
</script>

<style lang="scss" scoped>
  .range {
    position:relative;
    margin-top: 20px;

    &:before {
      content:"";
      width: 25px;
      height: 25px;
      position:absolute;
      border-radius: 50%;
      background: #e6e6e6;
      left: 2px;
      top: -2px;
    }
    &:after {
      content:"";
      width: 25px;
      height: 25px;
      position:absolute;
      border-radius: 50%;
      background: #e6e6e6;
      right: -2px;
      top: -2px;
      z-index: -1;
    }
  }
  .current {
    position: absolute;
    top: -23px;
    font-size: 12px;
    font-weight: bold;
    left: 50%;
    background: white;
    padding: 0 5px;
    z-index: 0;
  }
  .max, .min {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    top: -23px;
  }
  .min {left: 10px;}
  .max {right: 5px;}

  input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  position: relative;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #ea7d1c;
  cursor: pointer;
  margin-top: -9px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: none; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #ea7d1c;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #ea7d1c;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: none;
  background: #e6e6e6;
  border-radius: 5px;
  border: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #e6e6e6;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: none;
  background: #e6e6e6;
  border-radius: 5px;
  border: none;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #e6e6e6;
  border: none;
  border-radius: 5px;
  box-shadow: none;
}
input[type=range]:focus::-ms-fill-lower {
  background: #e6e6e6;
}
input[type=range]::-ms-fill-upper {
  background: #e6e6e6;
  border: none;
  border-radius: 5px;
  box-shadow: none;
}
input[type=range]:focus::-ms-fill-upper {
  background: #e6e6e6;
}
</style>
