import Single from './SingleEvent.vue';
import { computed } from 'vue';
import { compose } from 'vue-compose';
import currentUser from 'shared/components/CurrentUserEnhancer';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetEventQuery, useGetEventQuery } from 'shared/generated/graphql-types';

export type TProps = {
  eventId: string;
  regionId: number;
};

export interface TChildProps {
  event?: GetEventQuery['event'] | null;
  loading: boolean;
}

export default compose(
  currentUser,
  wrapComponent<TProps, TChildProps>((props) => {
    const { loading, result } = useGetEventQuery(
      computed(() => ({ eventId: Number(props.eventId) }))
    );

    return computed(() => ({
      event: result.value?.event,
      loading: loading.value
    }));
  })
)(Single);
