
  import Vue from 'vue';
  import SimpleForm, { Error, Field } from 'vue-simpleform';
  import { RouterLink } from 'vue-component-router';
  import {
    getCurrentSeason,
    getGrade,
    graduationYears,
    splitName,
    validateEmail,
    validateName,
    validateNumber
  } from 'shared/util';
  import * as RadioGroup from 'shared/radio-group';
  import * as Sentry from '@sentry/browser';
  import UIButton from 'shared/ui/buttons/Button.vue';
  import UIInput from 'shared/ui/forms/Input.vue';
  import UIToggle from 'shared/ui/forms/Toggle.vue';
  import Address from 'shared/components/Address/Address.vue';
  import UICheckbox from 'shared/ui/forms/Checkbox.vue';
  import UIRadio from 'shared/ui/forms/Radio.vue';
  import UISwitch from 'shared/ui/forms/Switch.vue';
  import UITextarea from 'shared/ui/forms/Textarea.vue';
  import FormSection from 'shared/ui/forms/FormSection.vue';
  import RegionLookup from 'shared/components/RegionLookup';
  import ChapterLookup from 'shared/components/ChapterLookup';
  import SynagogueLookup from 'shared/components/SynagogueLookup';
  import SchoolLookup from 'shared/components/SchoolLookup';
  import StaffLookup from 'shared/components/StaffLookup';
  import Modal from 'shared/ui/modal/Modal.vue';
  import ProfilePhoto from 'shared/components/ImageSelector.vue';
  import PostHighSchoolLookup from 'shared/components/PostHighSchoolLookup';
  import ScrollablePanel from 'shared/components/scrollable-panel.vue';
  import Divider from 'shared/layout/divider/layout-divider.vue';
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
  import UISelect from 'shared/ui/forms/FancySelect';
  import range from 'lodash/range';
  import PhoneInput from 'shared/ui/forms/Phone.vue';
  import EmailInput from 'shared/components/EmailInput';
  import fullheight from 'shared/directives/fullheight';
  import { toCompactTeen } from 'shared/components/TeenLookup/utils';
  import { Data, Computed, Methods, Props } from './types';
import SimpleCreateFamilyForm from 'shared/components/SimpleCreateFamilyForm';
import FamilyLookup from 'shared/components/FamilyLookup';
import { CreateTeenInput } from 'shared/generated/graphql-types';

  library.add(faCheck);

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: "QuickAddTeen",
    components: {
      Address,
      UIInput,
      UIToggle,
      ScrollablePanel,
      UICheckbox,
      UIRadio,
      UIButton,
      SimpleForm,
      UITextarea,
      Field,
      Divider,
      Error,
      RouterLink,
      UISwitch,
      ProfilePhoto,
      ChapterLookup,
      PostHighSchoolLookup,
      UISelect,
      PhoneInput,
      SynagogueLookup,
      RegionLookup,
      SchoolLookup,
      StaffLookup,
      EmailInput,
      Modal,
      FormSection,
      ...RadioGroup,
      FamilyLookup,
      SimpleCreateFamilyForm
    },
    directives: {
      fullheight,
      fixed: {
        inserted: function (el) {
          const posTop = String(el.getBoundingClientRect().top);
          const posLeft = String(el.getBoundingClientRect().left);
          el.style.top = `${posTop}px`;
          el.style.left = `${posLeft}px`;
          el.style.position = `fixed`;
        }
      }
    },
    props: {
      addTeen: {},
      regionId: {},
      chapterId: {},
      schoolId: {},
      familyId: {},
      name: {default: 'Teen'},
      defaultCountry: {},
      client: {},
      addFamily: {},
      skipDb: {}
    },
    data() {
      return {
        addClose: false,
        emailInputErrors: {},
        creatingFamily: false,
        formShape: {
          college: null, shanaBet: null, collegeStartYear: null, shanaBetStartYear: null, gapYearProgram: null, gapYearStartYear: null, gender: null, fullName: null,
          region: this.regionId, chapter: this.chapterId, address: {city: null, state: null, street: null, zipCode: null, country: this.defaultCountry },
          synagogue: null, email: null, graduationYear: null, school: this.schoolId, isJewish: true,
          teudatZehut: null, cellphone: null, landline: null, aliyahDate: null, olamiId: null, family: null
        }
      }
    },
    computed: {
      graduationYears() {
        return graduationYears().map(x => x.grade) as {year: number; grade: string}[];
      },
      calendarYears() {
        const currentSeason = Number(getCurrentSeason().split('-')[0]);
        return range(2007, currentSeason + 2).reverse();
      },
      fullName() {
        const {firstName, lastName} = this.currentDuplicatePerson.data || {firstName: '', lastName: ''};
        if (!firstName) return '';
        return `${firstName} ${lastName || ''}`
      }
    },
    methods: {
      getGrade,
      validateEmail,
      validateNumber,
      async addNewTeenHandler(callbackArgs) {
        if ('errors' in callbackArgs) return;
        const {firstName, lastName} = splitName(callbackArgs.values.fullName!);

        const {
          email, gender, college, gapYearProgram, shanaBet, gapYearStartYear,
          collegeStartYear, shanaBetStartYear, cellphone, landline, graduationYear,
          region, chapter, school, address, isJewish, family
        } = callbackArgs.values;

        if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();
        const input: CreateTeenInput = {
          firstName,
          lastName: lastName,
          regionId: typeof region === 'number' ? region : region && region.regionId || this.regionId,
          email,
          shanaBetId: shanaBet && shanaBet.schoolID || null,
          shanaBetStartYear,
          gapYearProgramId: gapYearProgram && gapYearProgram.schoolID || null,
          gapYearStartYear,
          collegeId: college && college.schoolID || null,
          collegeStartYear,
          gender: gender!,
          street: address ? address.street : null,
          city: address ? address.city : null,
          state: address ? address.state : null,
          country: address ? address.country : null,
          zip: address ? address.zipCode : null,
          cellNumber: cellphone || null,
          homeNumber: landline || null,
          schoolID: typeof school === 'number' ? school : (school && school.schoolID || this.schoolId || null),
          chapterId: typeof chapter === 'number' ? chapter : (chapter && chapter.chapterId || this.chapterId || null),
          graduationYear: graduationYear && graduationYear.year ? +graduationYear.year : null,
          isJewish,
          familyId: family && family.id || null
        };
        if (this.skipDb) {
          this.$emit('formFilled', input);
          this.$emit('requestClose');
        } else {
          const result = await this.addTeen(input);
          const personId = result.data && result.data.addTeen && result.data.addTeen.personID;
          if (personId) {
            this.$emit('created', toCompactTeen(result.data.addTeen));
            this.$emit('requestClose');
          }
          else {
            Sentry.withScope((scope) => {
              scope.setExtra('result', result.data);
              Sentry.captureMessage('there is no $personId - after addTeen - attached addTeen results');
            });
          }
        }
      },
      validate(values) {
        let errors: { [key: string]: string } = {};

        if (!values.fullName || !validateName(values.fullName)) {
          errors['fullName'] = 'Please enter the teen’s first and last name';
        }
        if (this.emailInputErrors['email']) {
          errors['email'] = this.emailInputErrors['email']
        }
        if (values.gender === null) {
          errors['gender'] = 'Please select gender';
        }
        if (!values.chapter) {
          errors['chapter'] = "Please select the teen's chapter";
        }
        if (values.cellphone && !validateNumber(values.cellphone)) {
          errors['cellphone'] = 'The Phone Number is invalid';
        }
        if (values.landline && !validateNumber(values.landline)) {
          errors['landline'] = 'The Phone Number is invalid';
        }
        return errors;
      },
      ToggleDetails() {
        this.addClose === true ? this.addClose = false : this.addClose = true;
      },
       async populateParentFields(field, {
          cellphone,
          landline,
          fullName,
          email
        }, setValue) {
        if (cellphone) setValue(`${field.toLowerCase()}CellPhone`, cellphone);
        if (landline) setValue(`${field.toLowerCase()}HomePhone`, landline);
        if (fullName) setValue(`${field.toLowerCase()}Name`, fullName)
        if (email) setValue(`${field.toLowerCase()}Email`, email)
      }
    }
  })
