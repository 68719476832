
import Vue from 'vue';
import FamilySelector from 'shared/components/FamilySelector';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import ParentLookup from 'shared/components/ParentLookup';
import SimpleForm, { Field, Error } from 'vue-simpleform';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import { ErrorBag } from 'shared/util/types';
import UIRadio from 'shared/ui/forms/Radio.vue';
import * as RadioGroup from 'shared/radio-group';
import { Data, Methods, Props } from './types';
import QuickAddParent from 'shared/components/QuickAddParent';
import { EntityUnionEnum, Gender, MaritalStatus } from 'shared/generated/graphql-types';

// d m c p
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddToFamily',
  components: {
    FamilySelector,
    UIToggle,
    ParentLookup,
    SimpleForm,
    Field,
    Error,
    UICheckbox,
    UIButton,
    UIRadio,
    ...RadioGroup,
    QuickAddParent
  },
  props: {
    regionId: {},
    childId: {},
    createFamily: {},
    createFamilyForTeen: {},
    addParent: {}
  },
  data () {
    return {
      Gender,
      MaritalStatus,
      EntityUnionEnum,
      formShape: {
        father: null,
        newFather: null,
        mother: null,
        newMother: null,
        markAsPrimary: true,
        maritalStatus: MaritalStatus.Married
      },
      creatingFather: false,
      creatingMother: false,
      newFatherInvalid: null,
      newMotherInvalid: null
    };
  },
  methods: {
    async submitHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      if (this.childId) {
        await this.createFamilyForTeen({
            input: {
            fatherId: args.values.father ? args.values.father.personId : null,
            motherId: args.values.mother ? args.values.mother.personId : null,
            maritalStatus: args.values.maritalStatus,
            childrenIds: [this.childId],
            childId: this.childId,
            regionId: this.regionId
          }
        }, args.values.father, args.values.mother);
      } else {
        let newFather, newMother
        if (this.creatingFather && args.values.newFather) {
          const result = await this.addParent(args.values.newFather)
          newFather = result?.data?.addParent
        }
        if (this.creatingMother && args.values.newMother) {
          const result = await this.addParent(args.values.newMother)
          newMother = result?.data?.addParent
        }
        const result = await this.createFamily({
            input: {
            fatherId: newFather ? newFather.personID : args.values.father ? args.values.father.personId : null,
            motherId: newMother ? newMother.personID : args.values.mother ? args.values.mother.personId : null,
            maritalStatus: args.values.maritalStatus,
            regionId: this.regionId
          }
        });
        this.$emit('created', result?.data?.createFamily);
      }
      if (args.setSubmitted) args.setSubmitted();
    },
    validate (values) {
      let errors: ErrorBag<typeof values> = {};

      if (!this.creatingFather && !this.creatingMother && !values.father && !values.mother) {
        errors.father = 'Please select at least one parent.';
        errors.mother = 'Please select at least one parent.';
      }

      if (this.creatingFather && values.newFather && this.newFatherInvalid) {
        errors.newFather = 'Father data is invalid'
      }
      
      if (this.creatingMother && values.newMother && this.newMotherInvalid) {
        errors.newMother = 'Mother data is invalid'
      }

      return errors;
    }
  }
});
