<template>
<div v-bar>
  <div style="overflow-y: auto"><slot /></div>
</div>
</template>
<script>
export default {
  name: 'ScrollablePanel'
}
</script>
<style>

.vb > .vb-dragger {
    z-index: 5;
    width: 12px;
    right: -5px;
}

.vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0,0,0,0);
    transform: rotate3d(0,0,0,0);
    -webkit-transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgba(0, 0, 0, 0.03);
    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(0, 0, 0, 0.05);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color:rgba(0, 0, 0, 0.05);
    height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(0, 0, 0, 0.05);
    height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(0, 0, 0, 0.05);
}
/* resolves the padding misfit when used inside the sidebar */
.sidebar > .vb {
    margin: 0 -20px;
}

/* disabled state */
.vb-invisible.disabled,
.vb-visible.disabled {
    overflow: visible !important;
}
.vb-invisible.disabled .vb-content,
.vb-visible.disabled.disabled .vb-content{
    overflow: visible !important;
}
.vb-invisible.disabled .vb-dragger,
.vb-visible.disabled .vb-dragger {
    display: none !important;
}
</style>
