import JFETeens from './JFETeens.vue';
import { ActiveFilters } from '../shared/types';
import { thisSchoolYear } from '../shared/seasonHelper';
import { withRouter } from 'vue-component-router';
import Vue, { computed, VueConstructor } from 'vue';
import { normalizeProps, getOptions } from 'shared/util';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { useGetJfeTeensQuery } from 'shared/generated/graphql-types';

const withFilters = (Component: VueConstructor) => {
	const props = normalizeProps(getOptions(Component).props);
	const { activeFilters, setFilters, ...propsToUse } = props;

	return Vue.extend<{ activeFilters: ActiveFilters }, { setFilters: (args: Partial<ActiveFilters>, cb?: () => void) => void }, {}, {}>({
		name: `${Component.name}WithFilters`,
		props: propsToUse,
		data() {
			return {
				activeFilters: {
          term: '',
          hasAllergyPlan: false,
          hasIEPPlan: false,
          season: thisSchoolYear()
				}
			};
		},
		methods: {
      setFilters(args: Partial<ActiveFilters>, cb?: () => void) {
        this.activeFilters = { ...this.activeFilters, ...args };
        if (cb) {
          cb();
        }
      }
		},
		render(h) {
			return h(Component, {
				props: {
					...this.$props,
					activeFilters: this.activeFilters,
					setFilters: this.setFilters
				},
				on: this.$listeners
			});
		}
	});
};

const getTeensEnhancer = wrapComponent<Pick<Props, 'activeFilters'>, Pick<Props, 'teens' | 'loading' | 'initialLoading' | 'fetchMore'>>((props) => {
  const { fetchMore, loading, result} = useGetJfeTeensQuery(computed(() => ({
    limit: 60,
    filter: {
      query: props.activeFilters && props.activeFilters.term,
      hasAllergyPlan: props.activeFilters && props.activeFilters.hasAllergyPlan,
      hasIEPPlan: props.activeFilters && props.activeFilters.hasIEPPlan,
      season: props.activeFilters && props.activeFilters.season,
    }
  })), {fetchPolicy: 'network-only'})

  return computed(() => ({
    initialLoading: loading.value && !result.value?.jfeTeens,
    loading: loading.value,
    teens: result.value?.jfeTeens || [],
    fetchMore: ({limit, offset}, stateChanger) => fetchMore({
      variables: {
        limit,
        offset,
        filter: {
          query: props.activeFilters && props.activeFilters.term,
          hasAllergyPlan: props.activeFilters && props.activeFilters.hasAllergyPlan,
          hasIEPPlan: props.activeFilters && props.activeFilters.hasIEPPlan,
          season: props.activeFilters && props.activeFilters.season,
        }
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return previousResult;
        }
        if (!fetchMoreResult.jfeTeens.length || fetchMoreResult.jfeTeens.length < limit) {
          stateChanger.complete();

          if (!fetchMoreResult.jfeTeens.length) {
            return previousResult;
          }
        }

        const ids = previousResult.jfeTeens.map(x => x.personID);
        const newData = fetchMoreResult.jfeTeens.filter(x => !ids.includes(x.personID));

        return {
          jfeTeens: [
            ...previousResult.jfeTeens,
            ...newData
          ]
        };
      }
    }),
  }))
})

export default compose(
  withFilters,
  getTeensEnhancer,
  withRouter,
)(JFETeens);
