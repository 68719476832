import AddNewStaff from './AddNewStaff.vue';
import { compose, withData } from 'vue-compose';
import { withApollo } from 'shared/components/graphql';
import { withRouter } from 'vue-component-router';
import currentUser from 'shared/components/CurrentUserEnhancer';
import { Permission } from '../../types';
import { wrapComponent } from 'shared/apollo-hoc';
import {
	AddStaffInput,
  GetStaffDocument,
  GetStaffQuery,
  useAddStaffMutation,
  useGetPermissionsQuery,
  useGetRolesQuery
} from 'shared/generated/graphql-types';
import { computed } from 'vue';

interface TProps {
  regionId: number;
  path: string;
  currentStaffId: string;
  rolesPermissionsMap: { [k: string]: Permission[] };
  addStaff: (staff: AddStaffInput) => void;
  permissions: Permission[];
  selectablePermissions: Permission[];
}

const addStaffEnhancer = wrapComponent<TProps, Pick<TProps, 'addStaff'>>((props) => {
  const { mutate } = useAddStaffMutation();

  return computed(() => ({
    addStaff: (staff) =>
      mutate(
        { staff },
        {
          update(proxy, { data: updateData }) {
            const data = proxy.readQuery<GetStaffQuery>({
              query: GetStaffDocument,
              variables: {
                filter: {
                  regionId: props.regionId,
                  active: true
                }
              }
            });

            if (data?.staff && updateData?.addStaff) {
              const staff = data.staff.slice();
              staff.push(updateData.addStaff);

              proxy.writeQuery<GetStaffQuery>({
                query: GetStaffDocument,
                variables: {
                  filter: {
                    regionId: props.regionId,
                    active: true
                  }
                },
                data: {
                  staff
                }
              });
            }
          }
        }
      )
  }));
});

const getRolesEnhancer = wrapComponent<TProps, Pick<TProps, 'rolesPermissionsMap'>>(() => {
  const { result } = useGetRolesQuery();

  return computed(() => ({
    rolesPermissionsMap: result.value?.roles
      ? result.value.roles.reduce(
          (o, k) => ({
            ...o,
            [k.Name as string]: k.Permissions.map((p) => ({ id: p.id, Name: p.Name }))
          }),
          {}
        )
      : {}
  }));
});

const getPermissionsEnhancer = wrapComponent<
  TProps,
  Pick<TProps, 'permissions' | 'selectablePermissions'>
>(() => {
  const { result } = useGetPermissionsQuery();
  return computed(() => {
    const data = (result.value?.permissions || []).map((p) => ({
      id: p.id,
      Name: p.Name,
      description: p.description
    }));
    return {
      permissions: data,
      selectablePermissions: data.filter((p) => p.Name !== 'GlaubachFellow')
    };
  });
});

export default compose(
  withApollo,
  currentUser,
  addStaffEnhancer,
  getRolesEnhancer,
  getPermissionsEnhancer,
  withData({
    showDuplicatesError: {
      initialValue: false
    }
  }),
  withRouter
)(AddNewStaff);
