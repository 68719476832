import { MutateResult } from '@vue/apollo-composable';
import { EntityUnionViewModel } from 'shared/components/EmailInput/shared/EntityUnionViewModel';
import {
  ConnectTeenToParentMutation,
  ConnectTeenToParentMutationVariables,
  ConsentDataInput,
  CreateAddress,
  CreateEmail,
  CreatePhone,
  FamilyWithChildrenFragment,
  GetParentProfileQuery,
  ResetPasswordMutationVariables,
  UpdateAddress,
  UpdateEmail,
  UpdatePhone
} from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

export type Parent = GetParentProfileQuery['singleParent'];
export type Address = ArrayElement<Parent['Addresses']>;
export type Child = ArrayElement<Parent['Children']>;
export type EmailAddress = ArrayElement<Parent['EmailAddresses']>;
export type Phone = ArrayElement<Parent['Phones']>;
export type FamilyChild = ArrayElement<FamilyWithChildrenFragment['Children']>

export interface Props {
  changePassword: (args: ResetPasswordMutationVariables) => void;
  updateEmail: (args: UpdateEmail, optimisticResponseData?: ParentUpdateArgs) => void;
  createEmail: (args: CreateEmail, optimisticResponseData?: ParentUpdateArgs) => void;
  updatePhone: (args: UpdatePhone, optimisticResponseData?: ParentUpdateArgs) => void;
  createPhone: (args: CreatePhone, optimisticResponseData?: ParentUpdateArgs) => void;
  updateAddress: (args: UpdateAddress, optimisticResponseData?: ParentUpdateArgs) => void;
  createAddress: (args: CreateAddress, optimisticResponseData?: ParentUpdateArgs) => void;
  personID: number;
  changingPassword: boolean;
  showPasswordModal: boolean;
  parent: ParentViewModel;
  markParentConsent: (parentId: number, data: ConsentDataInput) => void;
  connectTeenToParent: (
    args: ConnectTeenToParentMutationVariables,
    child: EntityUnionViewModel
  ) => MutateResult<ConnectTeenToParentMutation>;
}
export enum ParentFieldsEnum {
  email = 'email',
  landline = 'landline',
  cellphone = 'cellphone',
  address = 'address',
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  gender = 'gender',
  olamiId = 'olamiId'
}
export interface ParentUpdateArgs {
  personId: number;
  fieldName: ParentFieldsEnum;
  value: string | null;
}
export interface ParentViewModel {
  address: Address | null;
  personId: number;
  landline: Phone | null;
  cellphone: Phone | null;
  formattedCellphone: string | null;
  formattedLandline: string | null;
  formattedAddress: string;
  email: string | null;
  emails: EmailAddress[];
  emailId: number | null;
  firstName: string | null;
  lastName: string | null;
  olamiId: string | null;
  fullName: string;
  dataOptOut: boolean | null;
  gender: number;
  children: ViewModelChildren[];
  original: Parent;
}
export interface ViewModelChildren {
  landline: string | null;
  cellphone: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}
