import { render, staticRenderFns } from "./MedicalNotes.vue?vue&type=template&id=5b8a2852&scoped=true&"
import script from "./MedicalNotes.vue?vue&type=script&lang=ts&"
export * from "./MedicalNotes.vue?vue&type=script&lang=ts&"
import style0 from "./MedicalNotes.vue?vue&type=style&index=0&id=5b8a2852&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8a2852",
  null
  
)

export default component.exports