
import { Vue, Component, Prop } from 'vue-property-decorator';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import ContactParser from 'contact-parser';
import Hotel, { Room } from '../shared/Hotel';
import UIRadio from 'shared/ui/forms/Radio.vue';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import * as RadioGroup from 'shared/radio-group';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import UICounter from 'shared/ui/forms/Counter.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import AssignableEventStaff from '../shared/AssignableEventStaff';
import { CreateHouseInput, HotelInput } from 'shared/generated/graphql-types';

library.add(faTimes)

@Component({
	name: 'AddHouseForm',
	components: {
		UIButton,
		PhoneInput,
		UIInput,
		UIToggle,
		UICounter,
		UIRadio,
		...RadioGroup,
    FancySelect
	}
})
export default class extends Vue {
	@Prop({
        required: true
    })
	addNewHouse!: (eventId: number, house: CreateHouseInput, staff?: AssignableEventStaff) => void

	@Prop({
		required: true
	})
	addHotel!: (eventId: number, hotel: HotelInput) => void;

	@Prop()
	eventId!: number;

  @Prop() eventStaff!: AssignableEventStaff[];

	addingHotel: boolean = true;
	address: string | null = null;
	gender: 'M' | 'F' | null = null;
	phoneNumber: string | null = null;
	capacity: number = 1;
	description: string | null = null;

	hotelName: string | null = null;
  hotelRoomCapacity: number = 1;
  hotelMaleRoomCount: number | null = null;
  hotelFemaleRoomCount: number | null = null;
  hotelUnspecifiedRoomCount: number | null = null;
  assignedStaff: AssignableEventStaff | null = null;

  get rooms() {
    let roomNumber = 1;
    const males: Room[] = new Array(this.hotelMaleRoomCount || 0)
    .fill(undefined)
    .map(_ => ({gender: 'M', capacity: this.hotelRoomCapacity, description: `Room ${roomNumber++}`} as Room));
    const females: Room[] = new Array(this.hotelFemaleRoomCount || 0)
    .fill(undefined)
    .map(_ => ({gender: 'F', capacity: this.hotelRoomCapacity, description: `Room ${roomNumber++}`} as Room));
    const unspecified: Room[] = new Array(this.hotelUnspecifiedRoomCount || 0)
    .fill(undefined)
    .map(_ => ({gender: null, capacity: this.hotelRoomCapacity, description: `Room ${roomNumber++}`} as Room));

    return [
      ...males,
      ...females,
      ...unspecified
    ];
  }

	close () {
		this.$emit('addingNewHouse', false);
	}

  get valid() {
    if(this.addingHotel && this.hotelName && (this.hotelMaleRoomCount || this.hotelFemaleRoomCount || this.hotelUnspecifiedRoomCount)) {
      return true;
    }
    if(!this.addingHotel && this.address) {
      return true;
    }
    return false;
  }

	addNew () {
		if(!this.valid) return;

		this.close();

		const parser = new ContactParser();
		const splitAddress = parser.parse(!this.addingHotel ? this.address : this.hotelName);

		if(!this.addingHotel){
			this.addNewHouse(this.eventId, {
				street: splitAddress.address || splitAddress.name,
				street2: splitAddress.address ? splitAddress.name : undefined,
				city: splitAddress.city,
				state: (splitAddress.province || '').substring(0, 2),
				zipCode: splitAddress.postal,
				description: this.description || '',
				gender: this.gender,
				phone: this.phoneNumber,
				capacity: +this.capacity
			}, this.assignedStaff || undefined);
		} else {
			this.addHotel(this.eventId, {
				street: splitAddress.address || splitAddress.name,
				street2: splitAddress.address ? splitAddress.name : undefined,
				city: splitAddress.city,
				state: (splitAddress.province || '').substring(0, 2),
				zipCode: splitAddress.postal,
				rooms: this.rooms
			});
		}
	}

  assignStaff(s: AssignableEventStaff) {
    this.assignedStaff = s;
  }
}
