
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import UIRadio from 'shared/ui/forms/Radio.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import * as RadioGroup from 'shared/radio-group';
import SimpleForm, { Field, Error } from 'vue-simpleform'
import UISwitch from 'shared/ui/forms/Switch.vue';
import { CompactAdvisorRegion } from '../../../shared/CompactAdvisorRegion';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import Loading from 'shared/components/Loading.vue';
import MetadataComponent from 'shared/components/MetaData';
import {
    validateName,
    validateNumber,
    validateEmail,
    asUTCWithZerotime,
    phoneFormat,
    getCurrentSeason
} from 'shared/util';
import provinces from 'provinces';
import Address from 'shared/components/Address/Address.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import EmailInput from 'shared/components/EmailInput';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import { StaffCopy } from './types';
import { Permission } from '../../types';
import StaffInput from './StaffInput';
import QueryString from 'query-string';
import has from 'lodash/has';
import toLower from 'lodash/toLower';
import RequiresPermission from 'shared/components/RequiresPermission';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import range from "lodash/range";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { AddGlaubachSeasonMutation, CreateFellowInput, CurrentUserQuery, PermissionEnum, UpdateStaffInput, UpdateStaffMutation } from "shared/generated/graphql-types";
import { ArrayElement } from "shared/util/types";

type AdvisorRegion = ArrayElement<CurrentUserQuery['me']['AdvisorRegions']>
type MetaData = ArrayElement<UpdateStaffMutation['updateStaff']['MetaData']>

library.add(faCheck)

function getStaffCopyForForm(staff: CompactAdvisorRegion): StaffCopy {
  const copy: StaffCopy = Object.assign({ isGlaubachFellow: !!(staff.glaubachFellows || []).length }, staff);
  copy.permissions = copy.permissions.filter(p => p.Name !== 'GlaubachFellow');

  return copy;
}
@Component({
    name: "Profile",
    methods: {
      validateName,
      validateNumber,
      validateEmail,
      asUTCWithZerotime,
      phoneFormat
    },
    components: {
        CollapsibleSection,
        UIInput,
        ChapterLookup,
        EmailInput,
        UISwitch,
        UIToggle,
        UIRadio,
        UISelect,
        Loading,
        UIButton,
        SimpleForm,
        UITextarea,
        PhoneInput,
        Field,
        Divider,
        Error,
        Address,
        AutoSaveField,
        ...RadioGroup,
        Metadata: MetadataComponent,
        RequiresPermission,
        UICheckbox
    }
})

export default class extends Vue {
    @Prop() staff!: CompactAdvisorRegion;
    @Prop() updateStaff!: (input: UpdateStaffInput, staff: StaffCopy) => void;
    @Prop() regionId!: number;
    @Prop() rolesPermissionsMap!: { [k: string]: Permission[] };
    @Prop() permissions!: Permission[];
    @Prop() selectablePermissions!: Permission[];
    @Prop() router!: { history: { replace: (arg: any) => void }; location: { pathname: string; search: string; } };
    @Prop() currentUser!: AdvisorRegion;
    @Prop() addGlaubachSeason!: (input: CreateFellowInput) => { data: AddGlaubachSeasonMutation };
    @Prop() addingGlaubachSeason!: boolean;

    created() {
        this.states = provinces.filter(x => ['CA', 'US'].includes(x.country)).map(x => x.name).sort();
    }

    PermissionEnum = PermissionEnum;
    newGlaubachSeasonAdded: boolean = false;
    entityType = 'advisor';
    staffCopy: StaffCopy = getStaffCopyForForm(this.staff);
    staffType = ['Staff', 'Advisor', 'Tech Staff', 'Junior Staff'];
    originalPermissions = (this.staff.permissions || []).map(p => ({ id: p.id, Name: p.Name}));
    newGlaubachSeason: { season: string, community: string } = {
      season: '',
      community: ''
    }
    get isGlaubachSeasonValid () {
      return (!this.staffCopy.glaubachFellows.length && !!this.newGlaubachSeason.season) || this.staffCopy.glaubachFellows.length > 0
    }
    get seasons () {
      const currentSeason = Number(getCurrentSeason().split('-')[0]);
      return range(2011, currentSeason+1).map(x => `${x}-${x + 1}`).reverse();
    }
    get isGlaubachFellow () {
      return !!this.staffCopy.isGlaubachFellow
    }
    get isManageGlaubachFellows () {
      return !!(this.currentUser && this.currentUser.claims.find(p => p === PermissionEnum.ManageGlaubachFellows))
    }
    get showGlaubachFellowCheckbox () {
      return this.isManageGlaubachFellows && this.staffCopy.staffType === 'Advisor'
    }
    get disableGlaubachFellowCheckbox () {
      return this.staffCopy.glaubachFellows.length > 0
    }
    get expandPermissions () {
      const str = has(this.router, 'location.search') ? this.router.location.search : ''
      if (str) {
        const parsedQueryString = QueryString.parse(str, {parseNumbers: true})
        return typeof parsedQueryString.expand === 'string' && toLower(parsedQueryString.expand) === 'permissions'
      }
      else {
        return false
      }
    }
    async addGlaubachSeasonHandler () {
      if (this.newGlaubachSeasonAdded) return
      const results = await this.addGlaubachSeason({season: this.newGlaubachSeason.season, community: this.newGlaubachSeason.community, staffId: this.staffCopy.staffId })
      this.newGlaubachSeasonAdded = true;
      this.staffCopy = getStaffCopyForForm(this.staff);
    }


    async update(args: SaveEventArgs) {
      if (args.name === 'staffType') this.setPermissions();

      const staff = new StaffInput(
        { ...this.staffCopy,
          ...(args.name === 'chapter' ? { chapterId: +args.transformed } : {})
        },
        this.regionId,
        [],
        []
      );

      await this.updateStaff(staff, this.staffCopy);
    }

    async updatePermission(args: SaveEventArgs<{ value: boolean, permissionName: string }>) {
      const newPermissions = [];
      const removedPermissions = [];
      if(args.transformed.value) {
        if(!this.staffCopy.permissions.find(p => p.Name === args.transformed.permissionName)) {
          const newPerm = this.permissions.find(p => p.Name === args.transformed.permissionName);
          this.staffCopy.permissions.push(newPerm!);
          newPermissions.push(newPerm);
        }
      } else {
        const index = this.staffCopy.permissions.findIndex(p => p.Name === args.transformed.permissionName);
        if(index > -1) {
          const removedPerm = this.staffCopy.permissions[index];
          removedPermissions.push(removedPerm);
          this.staffCopy.permissions.splice(index, 1);
        }
      }
      this.originalPermissions = this.staffCopy.permissions;

      const input = new StaffInput(
        this.staffCopy,
        this.regionId,
        newPermissions,
        removedPermissions,
      );

      await this.updateStaff(input, this.staffCopy);
    }

    get metaDictionary(): {
        [key: string]: MetaData
    } {
        return this.staffCopy.MetaData.reduce((dict: {
            [key: string]: MetaData
        }, obj: MetaData) => {
            dict[obj.MetaKey] = obj;
            return dict;
        }, {});
    }

    getMetaObject(key: string, defaultValue ? : MetaData) {
        return this.metaDictionary[key] || defaultValue || {
            MetaID: 0,
            MetaValue: ''
        };
    }

    setPermissions() {
        this.staffCopy.permissions = this.rolesPermissionsMap[(this.staffCopy.staffType || '').replace(' ', '').toLowerCase()] || [];
    }

    updateMetaData(key: string, value: any) {
      const stringValue = typeof value === 'boolean' ? value.toString() : value;
      const keyIdx = this.staffCopy.MetaData.findIndex((d) => (d.MetaKey === key));
      if (keyIdx > -1) {
        this.staffCopy = {
          ...this.staffCopy,
          MetaData: this.staffCopy.MetaData.map((d) => {
            if (d.MetaKey === key) {
              return {...d, MetaValue: stringValue};
            }
            return d;
          })
        }
      } else {
        this.staffCopy = {
          ...this.staffCopy,
          MetaData: [...this.staffCopy.MetaData, { MetaID: 0, MetaKey: key, MetaValue: stringValue } as MetaData]
        }
      }
    }
}
