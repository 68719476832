
import Vue from 'vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import { Data, Methods, Props } from './types';
import { LeadStatus } from 'shared/generated/graphql-types';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'LeadStatusLookup',
  components: { FancySelect },
  data() {
    return {
      options: [
        { id: LeadStatus.Lead, label: 'Lead' },
        { id: LeadStatus.NotInterested, label: 'Not Interested' },
        { id: LeadStatus.Interested, label: 'Interested' },
        { id: LeadStatus.Complete, label: 'Complete' }
      ]
    }
  },
  props: {
    value: {},
    setLeadStatus: {},
    leadId: {},
    isLoading: {}
  },
  methods: {
    async setStatus(event) {
      await this.setLeadStatus(this.leadId, event.id);
    },
    getOption() {
      return this.options.find((o) => this.value === o.id) || 
        { id: LeadStatus.Lead, label: 'Lead' };
    }
  }
})
