
import Vue from 'vue';
import MultistepForm, { StepData, Steps, ValidationResult } from '../../../shared/MultistepForm';
import JSUForm from './Form.vue';
import { ApolloClient } from '@apollo/client';
import set from 'lodash/set';
import DateTimezone from 'date-timezone';
import { ProgressList, ListItem } from 'shared/ui/lists/ProgressList';
import { FormState, EventScheduleInput } from '../../../shared/types';
import { startDateBeforeEndDate, convertTo24HourFormat } from 'shared/util';
import { ArrayElement, SimpleDate, SimpleTime } from 'shared/util/types';
import { jsu, tya as tyaThumbnail, bgCubed as bgCubedThumbnail } from '../../../shared/DefaultEventImages';
import { CompactTeen } from 'shared/components/TeenLookup/shared/CompactTeen';
import has from 'lodash/has';
import {
  repeatTypes,
  Intervals,
  generateCustomDates,
  startTimeBeforeEndTime,
  formatRepeat,
  formatDate,
  formatTime,
  THUMBNAIL_SIZE_LIMIT,
  THUMBNAIL_SIZE_LIMIT_BASE64,
  isFutureEvent,
  repeatInterval,
  startTimeBeforeEndTime as validateTime
} from '../../../shared/util';
import { EventDates, GetRegionDataQuery, GetSchoolByRegionAndTermQuery, GetStaffQuery, GetZoomUsersQuery, SchoolByIdDocument, SchoolByIdQuery } from 'shared/generated/graphql-types';

type Region = GetRegionDataQuery['region']
type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']
type SchoolLookupSchool = ArrayElement<GetSchoolByRegionAndTermQuery['schoolLookup']>

interface Props {
  client: ApolloClient<any>;
  stepData: Steps;
  state: FormState;
  region: Region;
}

interface Methods {
  has: <T>(object: T, path: string) => boolean;
  validate: (step: string, data: StepData) => ValidationResult;
  repeatInterval: (props: StepData) => string
  startTimeBeforeEndTime: (data: StepData) => number;
  startDateBeforeEndDate: (startDate: SimpleDate, endDate: SimpleDate) => boolean;
  formatRepeat: (interval: Intervals, startDate: SimpleDate, endDate: SimpleDate) => string;
  submit: (steps: {[x: string]: any}) => void;
  handleChange: (step: string, key: string, value: SchoolLookupSchool, form: any) => Promise<void>;
  formatDate: (FirstEvent: SimpleDate, LastEvent: SimpleDate) => string;
  formatTime: (StartTime: SimpleTime, EndTime: SimpleTime) => string;
  isThumbnailDefault: (thumbnail: string) => boolean;
  isFutureEvent: (startDate?: SimpleDate, startTime?: SimpleTime, timezone?: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, Props>({
  name: 'JSU',
  components: {
    MultistepForm,
    JSUForm,
    ProgressList,
    ListItem
  },
  props: {
    client: {},
    stepData: {type: Object},
    state: {type: String as () => FormState},
    region: {},
  },
  methods: {
    startTimeBeforeEndTime,
    startDateBeforeEndDate,
    formatRepeat,
    formatDate,
    formatTime,
    isFutureEvent,
    repeatInterval,
    has,
    validate (step, data) {
      const errors = {}

      switch (step) {
        case 'school': {
          if (!data || !data.school) {
            set(errors, 'school.school', 'Please enter a school')
          }
          break;
        }
        case 'details': {
          if (!data || !data.name) {
            set(errors, 'details.name', 'Please enter a name for the event')
          }
          if (data && data.isVirtual) {
            if (data.streamSchedule) {
              if (!data.zoomUser) {
                set(errors, 'details.zoomUser', 'Please select Zoom User');
              }
              if (!data.zoomPasscode) {
                set(errors, 'details.zoomPasscode', 'Please enter passcode for the Zoom event');
              }
            }
          }
          if (data && data.Repeat !== 'custom_dates') {
            if (!data || !data.FirstEvent) {
              set(errors, 'details.FirstEvent', 'Please pick a date for the first event in the series');
            }
            if (!data || !data.LastEvent) {
              set(errors, 'details.LastEvent', 'Please pick a date for the last event in the series');
            }
          }
          if (!data || !data.StartTime) {
            set(errors, 'details.StartTime', 'Please pick a starting time for the event');
          }
          if (!data || !data.EndTime) {
            set(errors, 'details.EndTime', 'Please pick an ending time for the event');
          }
          if(!data || !data.TimeZone) {
            set(errors, 'details.TimeZone', 'Please select a timezone for this event');
          }
          if (validateTime(data) !== -1) {
            set(errors, 'details.startTimeBeforeEndTime', 'The start time has to be before the end time');
          }
          if (data && data.Repeat === 'custom_recurrence') {
            if (!data || !data.RepeatInterval || data.RepeatInterval < 1) {
              set(errors, 'details.RepeatInterval', 'Repeat interval should be greater than 0');
            }
            if (!data || !data.RepeatType) {
              set(errors, 'details.RepeatType', 'Please select recurrence type');
            }
            if (data && data.RepeatType === 'week' && !data.RepeatWeekDays) {
              set(errors, 'details.RepeatWeekDays', 'Please select days of week');
            }
            if (data && data.RepeatType === 'month') {
              if (data.RepeatMonthDay > 31) {
                set(errors, 'details.RepeatMonthDay', 'Day should be less than 31');
              } else if (data.RepeatMonthDay < 1) {
                set(errors, 'details.RepeatMonthDay', 'Day should be greater than 0');
              } else if (!data.RepeatMonthDay) {
                set(errors, 'details.RepeatMonthDay', 'Please enter date of month');
              }
            }
            if (!data || !data.RepeatEndType) {
              set(errors, 'details.RepeatEndType', 'Please select event end type');
            }
            if (data && data.RepeatEndType === 'date') {
              if (!this.startDateBeforeEndDate(data.FirstEvent, data.LastEvent)) {
                set(errors, 'details.LastEvent', 'The start date has to be before the end date');
              }
            }
            if (data && data.RepeatEndType === 'occurrence' && data.RepeatEndTimes < 1) {
              set(errors, 'details.RepeatEndTimes', 'Occurrences count should be greater than 0');
            }
          }
          if (data && data.Repeat === 'custom_dates') {
            if (!data || !data.CustomDates || !(data.CustomDates && JSON.parse(data.CustomDates).length > 0)) {
              set(errors, 'details.CustomDates', 'Please select at least one date');
            }
          }
          break;
        }
        case 'staffAndChapter': {
          if (!data || !data.primaryStaff) {
            set(errors, 'staffAndChapter.primaryStaff', 'Please select a primary staff member for the series');
          }
          break;
        }
        case 'thumbnailAndDescription': {
          if (!data || (data && !data.thumbnail)) {
            break;
          }
          if (data.thumbnail && data.thumbnail.length > THUMBNAIL_SIZE_LIMIT_BASE64) {
            set(errors, `${step}.thumbnail`, `Image should be less then ${THUMBNAIL_SIZE_LIMIT} Mb`)
          }
          break;
        }
      }

      return errors
    },

    submit (steps) {
      const {
        StartTime,
        EndTime,
        Repeat,
        TimeZone,
        Address,
        streamSchedule,
        streamLink
      } = steps.details;

      let {
        FirstEvent,
        LastEvent
      } = steps.details;

      DateTimezone.setGlobalTimezone(TimeZone);

      let customDates = [];

      if (Repeat === 'custom_recurrence') {
        customDates = generateCustomDates(steps.details);
      } else if (Repeat === 'custom_dates') {
        customDates = JSON.parse(steps.details.CustomDates);
        FirstEvent = customDates[0];
        LastEvent = customDates[customDates.length - 1];
      }

      const StartDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const EndDate = new DateTimezone.DateTimezone(
        FirstEvent.year,
        FirstEvent.month - 1,
        FirstEvent.day,
        convertTo24HourFormat(EndTime),
        +EndTime.minutes,
      );

      const LastDate = new DateTimezone.DateTimezone(
        LastEvent.year,
        LastEvent.month - 1,
        LastEvent.day,
        convertTo24HourFormat(StartTime),
        +StartTime.minutes,
      );

      const address = Address || {};

      const fullStreamLink = streamLink && !streamSchedule
        ? streamLink.match(/^http(s?):\/\//) ? streamLink : 'https://' + streamLink
        : null

      const series: EventScheduleInput = {
        event: {
          eventName: steps.details.name,
          subtitle: steps.details.subtitle,
          EventSubTypeID: 290,
          chapterID: steps.staffAndChapter && steps.staffAndChapter.chapter && steps.staffAndChapter.chapter.chapterId || null,
          schoolID: steps.school.school.schoolID,
          advisorID: steps.staffAndChapter.primaryStaff.staffID,
          AdvisorsParticipating: (steps.staffAndChapter.staff || []).map((s: Staff) => s.staffID),
          TeensParticipating: (steps.staffAndChapter.teens || []).map((t: CompactTeen) => t.personID),
          startDate: StartDate.toISOString(),
          endDate: EndDate.toISOString(),
          description: steps.thumbnailAndDescription.description,
          image: !this.isThumbnailDefault(steps.thumbnailAndDescription.thumbnail) ? steps.thumbnailAndDescription.thumbnail.replace(/data:image\/.*?;base64,/, '') : null,
          capacity: steps.options.capacity ? Number(steps.options.capacity) : null,
          trackId: (steps.options.eventTrack || {}).id || null,
          internal: steps.options.hideFromWebAndMobile,
          TimeZone,
          address1: address.address,
          city: address.city,
          state: address.state,
          zip: address.zipCode,
          baseUrl: steps.options.isTYAEvent ? 2 : steps.options.isBGCubedEvent ? 4 : 0,
          isVirtual: !!steps.details.isVirtual,
          streamLink: fullStreamLink,
          zoomUserId: streamSchedule && steps.details.zoomUser && (steps.details.zoomUser as ArrayElement<GetZoomUsersQuery['zoomUsers']>).id || null,
          zoomMeetingId: streamSchedule && (steps.details.zoomMeetingId || '').replace(/ /g, '').replace(/-/g, '') || null,
          zoomPasscode: streamSchedule && steps.details.zoomPasscode || null
        },
        series: {
          EndDate: LastDate.toISOString(),
          Repeat,
          seriesId: steps.details.series && steps.details.series.seriesID || null
        },
        RepeatConfig: {
          type: repeatTypes[steps.details.RepeatType],
          repeat_interval: steps.details.RepeatInterval
        },
        dates: customDates.map((customDate: SimpleDate, index: number) => {
          const date: EventDates = {
            start: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(StartTime),
              +StartTime.minutes,
            ).toISOString(),
            end: new DateTimezone.DateTimezone(
              customDate.year,
              customDate.month - 1,
              customDate.day,
              convertTo24HourFormat(EndTime),
              +EndTime.minutes,
            ).toISOString(),
          }
          if (steps.details.zoomUser) {
            if (Repeat === 'custom_recurrence') {
              date.zoomOccurrencyId = new DateTimezone.DateTimezone(
                customDate.year,
                customDate.month - 1,
                customDate.day,
                convertTo24HourFormat(StartTime),
                +StartTime.minutes,
              ).getTime();
            }
            if (Repeat === 'custom_dates') {
              date.zoomOccurrencyId = new DateTimezone.DateTimezone(
                FirstEvent.year,
                FirstEvent.month - 1,
                FirstEvent.day + index,
                convertTo24HourFormat(StartTime),
                +StartTime.minutes,
              ).getTime();
            }
          }
          return date;
        })
      };
      if (steps.details.RepeatType === 'week') {
        series!.RepeatConfig!.weekly_days = steps.details.RepeatWeekDays;
      } else if (steps.details.RepeatType === 'month') {
        series!.RepeatConfig!.monthly_day = steps.details.RepeatMonthDay
      }
      if (steps.details.RepeatEndType === 'date') {
        series!.RepeatConfig!.end_date_time = LastDate.toISOString();
      } else {
        series!.RepeatConfig!.end_times = steps.details.RepeatEndTimes;
      }
      this.$emit('submit', series);
    },

    async handleChange (step, key, value, form) {
      if (step === 'school' && key === 'school' && value) {
        const {
          data: { school: { name, city, state } },
        } = await this.client.query<SchoolByIdQuery>({
          query: SchoolByIdDocument,
          variables: {
            schoolID: value.schoolID
          }
        });

        form.setData('details', 'name', name);

        await Vue.nextTick();

        form.setData('details', 'Address', {
          city,
          state,
        });

        await Vue.nextTick();
      }

      await Vue.nextTick();

      if(step === 'details' && key === 'FirstEvent'){
        if(!form.getData('details', 'LastEvent')){
          form.setData('details', 'LastEvent', value);
          await Vue.nextTick();
        }
      }

      if (step === 'details' && key === 'CustomDates' && form.getData('details', 'Repeat') === 'custom_dates') {
        const dates = JSON.parse(form.getData('details', 'CustomDates'));
        if (dates && dates.length > 0) {
          form.setData('details', 'FirstEvent', dates[0]);
          await Vue.nextTick();
          form.setData('details', 'LastEvent', dates[dates.length - 1]);
          await Vue.nextTick();
        }
      }

      if(step === 'staffAndChapter' && key === 'chapter') {
        if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 414) {
          form.setData('thumbnailAndDescription', 'thumbnail', tyaThumbnail);
        } else if (form.getData('staffAndChapter', 'chapter') && form.getData('staffAndChapter', 'chapter').chapterID == 422) {
          form.setData('thumbnailAndDescription', 'thumbnail', bgCubedThumbnail);
        } else if(form.getData('thumbnailAndDescription', 'thumbnail') === tyaThumbnail || form.getData('thumbnailAndDescription', 'thumbnail') === bgCubedThumbnail) {
          form.setData('thumbnailAndDescription', 'thumbnail', jsu);
        }
        await Vue.nextTick();
      }
    },

    isThumbnailDefault (thumbnail) {
      return !thumbnail || thumbnail === jsu;
    }
  }
})
