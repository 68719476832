
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { RouterLink } from 'vue-component-router';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import Loading from 'shared/components/Loading.vue';
import CollapsibleSection from 'shared/ui/forms/CollapsibleSection.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import { phoneFormat, dateFormat } from 'shared/util';
import { Methods, Props, Data } from './types';
import FileSaver from 'file-saver';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';
import JSZip from 'jszip';
import { CombinedVueInstance } from 'vue/types/vue';
import ClipboardCopy from 'shared/ui/clipboard/clipboard.vue';
import { GetFamilyPicturesDocument, GetFamilyPicturesQuery, GetJfePaymentsPageLinkDocument, GetJfePaymentsPageLinkQuery, JewishStatus } from "shared/generated/graphql-types";

library.add(faFileDownload);

type thisType = CombinedVueInstance<Vue, Data, Methods, {}, Props>;

export default Vue.extend<Data, Methods, {}, Props>({
  name: "Single",
  components: {
    ScrollablePanel,
    Loading,
    UIInput,
    CollapsibleSection,
    RouterLink,
    ClipboardCopy
  },
  directives: {
    fullheight
  },
  props: {
    parentId: {},
    parent: {},
    loading: {},
    client: {}
  },
  data () {
    return {
      loadingPaymentsPageLink: false,
      paymentsPageLink: null
    }
  },
  computed: {
    parentNotFound () {
      return !this.parent && !this.loading
    },
    loadingData () {
      return this.loading
    }
  },
  methods: {
    formatParent({ firstName, lastName, hebrewFirstName }) {
      return `${firstName} ${lastName}${hebrewFirstName ? ` (${hebrewFirstName})` : ''}`;
    },
    formatGender ({ gender }) {
      if(!gender) {
        return 'Male';
      }
      return 'Female';
    },
    formatJewishStatus({ jewishStatus }) {
      switch(jewishStatus) {
        case JewishStatus.NotJewish:
          return 'Not Jewish';
        case JewishStatus.BornToJewishParents:
          return 'Born To Jewish Parents';
        case JewishStatus.BornToAJewishFather:
          return 'Born To A Jewish Father';
        case JewishStatus.BornToAJewishMother:
          return 'Born To A Jewish Mother';
        case JewishStatus.Converted:
          return 'Converted';
      }
      return '';
    },
    phoneFormat,
    dateFormat,
    formatMediaConsent({ mediaConsentSigned, mediaOptOut }) {
      if(mediaConsentSigned) {
        return 'Yes'
      }
      if(mediaOptOut) {
        return 'No'
      }
      return '';
    },
    async getFamilyPictures() {
      const { data: { familyPictures: { data, contentType, fileName } } } = await this.client.query<GetFamilyPicturesQuery>({
        query: GetFamilyPicturesDocument,
        variables: {
          personId: this.parent.personID
        }
      });

      const result = await JSZip.loadAsync(data!, { base64: true });
      const blob = await result.generateAsync({ type: 'blob' });
      FileSaver.saveAs(blob, fileName!);
    }
  },
  watch: {
    parent: {
      deep: true,
      async handler(this: thisType) {
        if (this.parent) {
          this.loadingPaymentsPageLink = true;
          const link = (await this.client.query<GetJfePaymentsPageLinkQuery>({
                  query: GetJfePaymentsPageLinkDocument,
                  variables: {
                    personId: this.parent.personID
                  }
                })).data.getJFEPaymentsPageLink;

          this.paymentsPageLink = {
            text: `Payments Page Link`,
            link
          };
          this.loadingPaymentsPageLink = false;
        }
      }
    }
  }
})
