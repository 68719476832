
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { CompactCommunityMember } from '../../shared/CompactCommunityMember';
import Tables from 'shared/ui/tables/Tables.vue';
import { GetEmailHistoryQuery } from "shared/generated/graphql-types";

type EmailHistory = GetEmailHistoryQuery['emailHistory']

@Component({
  name: "EmailHistory",
  components: {
    Tables
  }
})

export default class extends Vue {

@Prop() data!: { emailHistory: EmailHistory[] }

@Prop() communityMember!: CompactCommunityMember

@Prop() regionId!: number;

activityLevelsMap = {
    '-1': 'None',
    '0': 'Processed',
    '1': 'Dropped',
    '2': 'Deferred',
    '3': 'Delivered',
    '4': 'Bounced',
    '5': 'Opened',
    '6': 'Clicked',
    '7': 'Unsubscribe',
    '8': 'Reported as Spam'
  };
}

