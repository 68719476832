
import { Vue, Component, Prop } from 'vue-property-decorator';
import UISelect from 'shared/ui/forms/FancySelect';
import { Series } from './'

@Component({
	components: { UISelect }
})
export default class SeriesLookup extends Vue {
		@Prop() term!: string;
    @Prop() regionId!: number;
    @Prop() series!: Series[];
    @Prop() eventSubTypeId!: number;
    @Prop() eventSubTypeDescription!: string;
    @Prop() season!: string;
    @Prop() loading!: boolean;
		@Prop({type: [Number, Object]}) value!: number | Series;

		get valueAsObject () {
			if (typeof this.value === 'object') return this.value;
      if (this.series) {
			return (this.series || []).find(x => x.seriesID === this.value);
      }
		}

    handleSearch(value: string) {
      this.$emit('term', value)
    }
};
