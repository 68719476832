
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import { RouterLink } from 'vue-component-router';
import { validateNumber, splitName, getCurrentSeason, validateName, validateEmail, graduationYears, getGrade } from 'shared/util';
import * as RadioGroup from 'shared/radio-group';
import * as CheckboxArray from 'shared/checkbox-array';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import Address from 'shared/components/Address/Address.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import FancyCheckbox from 'shared/ui/forms/FancyCheckbox.vue';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import FormSection from 'shared/ui/forms/FormSection.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import SynagogueLookup from 'shared/components/SynagogueLookup';
import SchoolLookup from 'shared/components/SchoolLookup';
import StaffLookup from 'shared/components/StaffLookup';
import Modal from 'shared/ui/modal/Modal.vue';
import ProfilePhoto from 'shared/components/ImageSelector.vue';
import AddNewTeenFormObject from 'shared/components/AddTeenEnhancer/AddNewTeenFormObject';
import PostHighSchoolLookup from 'shared/components/PostHighSchoolLookup';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import UISelect from 'shared/ui/forms/FancySelect';
import range from 'lodash/range';
import fullheight from 'shared/directives/fullheight';
import EmailInput from 'shared/components/EmailInput';
import FamilyLookup from 'shared/components/FamilyLookup';
import { EntityUnionViewModel as DuplicateViewModel } from 'shared/components/EmailInput/shared/EntityUnionViewModel';
import SimpleCreateFamilyForm from 'shared/components/SimpleCreateFamilyForm';
import { AddTeenMutation, CreateTeenInput, Family } from "shared/generated/graphql-types";
import { MutateResult } from "@vue/apollo-composable";
import { ApolloError } from "@apollo/client";

library.add(faCheck, faCaretLeft)

@Component({
    name: "AddNewTeen",
    components: {
        Address,
        UIInput,
        UIToggle,
        ScrollablePanel,
        EmailInput,
        PhoneInput,
        FamilyLookup,
        UIRadio,
        UIButton,
        UICheckbox,
        SimpleForm,
        UITextarea,
        Field,
        Divider,
        Error,
        RouterLink,
        UISwitch,
        ProfilePhoto,
        ChapterLookup,
        PostHighSchoolLookup,
        UISelect,
        SynagogueLookup,
        FancyCheckbox,
        ...CheckboxArray,
        SchoolLookup,
        StaffLookup,
        Modal,
        FormSection,
        ...RadioGroup,
        SimpleCreateFamilyForm
    },
    directives: {
        fullheight
    },
    methods: {
        getGrade,
        validateNumber
    },
})
export default class extends Vue {
    @Prop() path!: string;
    @Prop() addTeen!: (addTeenArgs: CreateTeenInput) => MutateResult<AddTeenMutation>;
    @Prop() addingTeen!: boolean;
    @Prop() addTeenError!: ApolloError | null;
    @Prop() regionId!: number;
    @Prop() defaultCountry!: string;
    @Prop() returnUrl!: string;
    @Prop() router!: {
        history: {
            push: (location: string) => void
        }
    };
    @Prop() redirectToProfile!: boolean;
    @Prop() basePath!: string;

    checkboxes: string[] = [];
    selectedEmails: string[] = [];
    creatingFamily = false;
    emailInputErrors: { [k: string]: string; } = {};

    formShape: AddNewTeenFormObject = {
      college: null, shanaBet: null, collegeStartYear: null, shanaBetStartYear: null,
      gapYearProgram: null, gapYearStartYear: null, gender: null, fullName: null,
      chapter: null, address: {city: null, state: null, street: null, zipCode: null, country: this.defaultCountry },
      email: null, cellphone: null, landline: null,
      graduationYear: null, school: null,
      isJewish: true, region: this.regionId, teudatZehut: null, aliyahDate: null,
      olamiId: null, family: null
    };

    async handleFamilyCreated(event: Family, setValue: (key: string, value: any) => Promise<void>) {
      await setValue('family', event);
      this.creatingFamily = false;
    }

    async scrollTo(field: string) {
      if (document.getElementById(field)) {
        document.getElementById(field)!.scrollIntoView({
          block: "center",
          inline: "nearest",
          behavior: 'smooth'
        });
        await Vue.nextTick();
      }
    }
    get graduationYears() {
        return graduationYears().map(x => x.grade);
    }
    get calendarYears() {
        const currentSeason = Number(getCurrentSeason().split('-')[0]);
        return range(2007, currentSeason + 6).reverse();
    }
    async addNewTeenHandler(callbackArgs: SubmitCallbackArgs<AddNewTeenFormObject>) {
        if ('errors' in callbackArgs) return;
        const {
            firstName,
            lastName
        } = splitName(callbackArgs.values.fullName!);

        const {
            email,
            gender,
            college,
            gapYearProgram,
            shanaBet,
            gapYearStartYear,
            collegeStartYear,
            shanaBetStartYear,
            cellphone,
            landline,
            graduationYear,
            chapter,
            school,
            address,
            olamiId,
            isJewish,
            family,
            teudatZehut,
            aliyahDate
        } = callbackArgs.values;

        if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();
        try {
          const result = await this.addTeen({
            firstName,
            lastName,
            regionId: this.regionId,
            email,
            shanaBetId: shanaBet && shanaBet.schoolID || null,
            shanaBetStartYear,
            gapYearProgramId: gapYearProgram && gapYearProgram.schoolID || null,
            gapYearStartYear,
            collegeId: college && college.schoolID || null,
            collegeStartYear,
            gender: gender!,
            street: address ? address.street : null,
            city: address ? address.city : null,
            state: address ? address.state : null,
            country: address ? address.country : null,
            zip: address ? address.zipCode : null,
            cellNumber: cellphone || null,
            homeNumber: landline || null,
            schoolID: typeof school === 'number' ? school || null : school && school.schoolID || null,
            chapterId: typeof chapter === 'number' ? chapter || null : chapter && chapter.chapterId || null,
            graduationYear: graduationYear && graduationYear.year ? +graduationYear.year : null,
            olamiId,
            isJewish,
            isAlumni: this.checkboxes.includes('isAlumni'),
            vegetarian: this.checkboxes.includes('vegetarian'),
            shomerShabbat: this.checkboxes.includes('shomerShabbat'),
            isAffiliated: this.checkboxes.includes('isUnaffiliated'),
            regionalBoardMember: this.checkboxes.includes('regionalBoardMember'),
            familyId: family && family.id || null,
            teudatZehut,
            aliyahDate
          });

          if (this.redirectToProfile) {
              this.router.history.push(`${this.basePath.split('/:')[0]}/${result?.data?.addTeen.personID}`);
          } else {
              this.router.history.push(this.returnUrl);
          }
        } catch (e) {
          if (callbackArgs.setSubmitted) callbackArgs.setSubmitted();
        }
    }
    get fullName() {
        const {
            firstName,
            lastName
        } = this.currentDuplicatePerson.data || {
            firstName: '',
            lastName: ''
        };
        if (!firstName) return ''
        return `${firstName} ${lastName || ''}`
    }

    populateFamilyField({ primaryFamily }: DuplicateViewModel, setValue: Function) {
        if (primaryFamily) setValue('family', primaryFamily);
    }
    validate(values: AddNewTeenFormObject) {
        let errors: {
            [key: string]: string
        } = {};

        if (!values.fullName || !validateName(values.fullName)) {
            this.scrollTo('fullName');
            errors['fullName'] = 'Please enter the teen’s first and last name';
        }
        if (values.gender === null) {
            this.scrollTo('gender');
            errors['gender'] = 'Please select gender';
        }
        if (!values.chapter) {
            this.scrollTo('chapter');
            errors['chapter'] = "Please select the teen's chapter";
        }
        if (this.regionId === 30 && !values.teudatZehut) {
            this.scrollTo('teudatZehut');
            errors['teudatZehut'] = "Teudat Zehut is a required field";
        }
        if (values.cellphone && !validateNumber(values.cellphone)) {
            this.scrollTo('cellphone');
            errors['cellphone'] = 'The Phone Number is invalid';
        }
        if (values.landline && !validateNumber(values.landline)) {
            this.scrollTo('landline');
            errors['landline'] = 'The Phone Number is invalid';
        }
        if (values.email && this.emailInputErrors['email']) {
            errors['email'] = this.emailInputErrors['email'] || 'Email is required'
        }

        return errors;
    }

}

