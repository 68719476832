import Profile from './Profile.vue';
import { computed } from 'vue';
import { compose, withData, withProps } from 'vue-compose';
import { useApolloClient } from '@vue/apollo-composable';
import isNull from 'lodash/isNull';
import { Props } from './types';
import { calcTeenUpdate } from './utils';
import { addParentToFamilyEnhancer } from 'shared/components/AddParentToFamily';
import { markFamilyAsPrimaryEnhancerForProfile } from 'shared/components/markFamilyAsPrimaryForTeenEnhancer';
import { connectParentToTeenEnhancer } from 'shared/enhancers/connectParentToTeenEnhancer';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { useUpdateTeenMutation } from 'shared/generated/graphql-types';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const updateTeenEnhancer = wrapComponent<Props, Pick<Props, 'updateTeen'>>((props) => {
  const { mutate } = useUpdateTeenMutation();
  const { client } = useApolloClient();

  return computed(() => ({
    updateTeen: (args) =>
      mutate(
        {
          personId: args.personId,
          fieldName: args.fieldName,
          value: isNull(args.value) ? args.value : `${args.value}`
        },
        {
          optimisticResponse: {
            updateTeen: calcTeenUpdate(props.teen.original, args, client)
          }
        }
      )
  }));
});

export default compose(
  updateTeenEnhancer,
  connectParentToTeenEnhancer,
  markFamilyAsPrimaryEnhancerForProfile,
  withProps(() => ({
    regionId: getCurrentRegion()
  })),
  addParentToFamilyEnhancer,
  withData({
    addingNote: {
      initialValue: false
    },
    markingFamilyAsPrimary: {
      initialValue: false
    },
    addingToAdditionalFamily: {
      initialValue: false
    }
  })
)(Profile);
