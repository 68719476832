
import { RouterLink } from 'vue-component-router';
import FancyRadio from 'shared/ui/forms/FancyRadio.vue';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as RadioGroup from 'shared/radio-group';
import JSU from './components/JSU';
import LNL from './components/LNL';
import Other from './components/Other';
import ShabbatHoliday from './components/ShabbatHoliday';
import Shabbaton from './components/Shabbaton';
import Loading from 'shared/components/Loading.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import UIButton from "shared/ui/buttons/Button.vue";
import Modal from 'shared/ui/modal/Modal.vue';
import { DataComponent, ComputedComponent, MethodsComponent, PropsComponent } from "./types";
import { AttendanceStatus } from 'shared/generated/graphql-types';

library.add(faCaretLeft,faTrash);

export default Vue.extend<DataComponent, MethodsComponent, ComputedComponent, PropsComponent>({
  components: {
    ...RadioGroup,
    FancyRadio,
    ScrollablePanel,
    UIButton,
    JSU,
    LNL,
    Other,
    ShabbatHoliday,
    Shabbaton,
    Modal,
    RouterLink,
    Loading
  },
  directives: {
    fullheight
  },
  props: {
    event: {},
    eventType: {},
    stepData: {},
    state: {},
    region: {},
    returnPath: {},
    loading: {},
    eventId: {},
    chapterId: {},
    deletingEvent: {},
    deleteEvent: {},
    deletingZoomMeeting: {},
    deleteZoomMeeting: {},
    router: {},
  },
  data() {
    return {
      main: 'Details',
      showCancelEventModal: false,
      showWarningModal: false
    }
  },
  computed: {
    teensAttended() {
      return this.event.Attendances.filter(a => a.status === AttendanceStatus.Attended).length
    },
    teensRegistered() {
      return this.event.Registrations.length
    },
    isCanRemove() {
      return this.teensAttended === 0 && this.teensRegistered === 0;
    }
  },
  methods: {
    async deleteEventHandler() {
      await this.deleteEvent(this.eventId);
      if (this.event.zoomMeetingId) {
        try {
          await this.deleteZoomMeeting(this.event.zoomMeetingId);
        } catch(e) {
          Sentry.withScope((scope) => {
            scope.setExtra('error', e);
            Sentry.captureMessage('On edit event');
          })
        }
      }
      this.router.history.push('/events')
    },
    openDescriptionTab() {
      this.main = 'Description';
    }
  }
})
