
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator';
import Calendar from 'shared/components/Calendar';
import EventFilter from './components/EventFilter.vue';
import MonthView from './components/MonthView.vue';
import DayView from './components/DayView.vue';
import AgendaView from './components/Agenda';
import { Dates } from './types';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { ArrayElement, SimpleDate } from 'shared/util/types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { RouterLink } from 'vue-component-router';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { EventFilterInput, GetEventsQuery, RegionWideFilter } from 'shared/generated/graphql-types';

type Event = ArrayElement<GetEventsQuery['events']>

library.add(faChevronRight,faChevronLeft,faChevronDoubleLeft, faChevronDoubleRight, faPlusCircle)

@Component({
	name: 'EventCalendar',
	components: {
	Calendar,
	EventFilter,
	AgendaView,
	MonthView,
	DayView,
	ScrollablePanel,
	PanelsSidebar,
	Divider,
	PanelsGrid,
	RouterLink,
	},
    directives: {
      fullheight
    }
})
export default class extends Vue {
	@Prop() currentDate!: SimpleDate;
	@Prop() currentRegion!: number;
	@Prop() setCurrentDate!: (date: SimpleDate) => void;
	@Prop() events!: Event[];
	@Prop() dates!: Dates;
	@Prop() datesChanged!: (dates: Dates) => void;
	@Prop({type: String, default: 'month'}) mode!: 'day' | 'month' | 'agenda';
	@Prop() setMode!: (mode: string) => void;
	@Prop() user!: number;
	@Prop() filters!: EventFilterInput;
	@Prop() clearFilters!: ()=> void;
	@Prop() loading!: boolean;
	@Prop() router!: { history: { push: (location: string) => void } };

	monthNames: string[] = ["January", "February", "March", "April", "May", "June",
  		"July", "August", "September", "October", "November", "December"
];

	scrolling: false | 'up' | 'down' = false;
	scrollCount = 0;
	scrollTimer: NodeJS.Timer | null = null;

	get currentWeek () {
		const date = new Date(this.currentDate.year, this.currentDate.month - 1, this.currentDate.day);

		return Math.ceil((date.getDate() + 6 - date.getDay()) /7) - 1
	}

	get today () {
		const today = new Date();

		return {
			year: today.getFullYear(),
			month: today.getMonth() + 1,
			day: today.getDate()
		}
	}

	setFilterHandler (value: any, filter: string) {
     this.$emit('filters', {
       ...this.filters,
       ...(this.filters.regionWide === RegionWideFilter.Only && filter !== RegionWideFilter.Only ? {  regionWide: RegionWideFilter.Show } : {}),
       [filter]: value
       })
  }
	handleDateChange (date: Date) {
		this.setCurrentDate({
			month: date.getMonth() + 1,
			year: date.getFullYear(),
			day: date.getDate()
		});
	}

	handleMouseWheel (e: WheelEvent) {
		this.scrollTimer && clearTimeout(this.scrollTimer ? Number(this.scrollTimer) : undefined)

		const dir = e.deltaY < 0 ? 'up' : 'down'

		if (dir !== this.scrolling) {
			this.scrolling = dir
			this.scrollCount = 0
		}
		else {
			this.scrollCount += 1
		}

		if (this.scrollCount % 10 === 0) {
			if (dir === 'up') this.handlePrevious()
			if (dir === 'down') this.handleNext()
		}

		this.scrollTimer = setTimeout(() => {
			this.scrolling = false;
			this.scrollCount = 0
		}, 300)
	}
	handlePrevious(){
		if(this.mode=='month' || this.mode === 'agenda'){
			this.handleDateChange(new Date(this.currentDate.year, this.currentDate.month - 2, 1))
		}
		else if(this.mode=='day'){
			this.handleDateChange(new Date(this.currentDate.year, this.currentDate.month-1, this.currentDate.day-1))
		}
	}
	handleNext(){
		 if(this.mode=='month' || this.mode === 'agenda'){
			this.handleDateChange(new Date(this.currentDate.year, this.currentDate.month, 1))
		}
		else if(this.mode=='day'){
			this.handleDateChange(new Date(this.currentDate.year, this.currentDate.month-1, this.currentDate.day+1))
		}
	}

}

