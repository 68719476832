<template>
  <label class="radio" :class="{active : selected}">
    <input type="radio" :checked="selected" :name="name" @click="set"/>
    <span class="text">{{labelText}}</span>
  </label>
</template>

<script>
export default {
  name: 'UICheckbox',
  props: {
    set: Function,
    selected: Boolean,
    name: String,
    labelText: String
  }
};
</script>

<style lang="scss" scoped>
  .checkbox {
    display: flex;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #ddd;
    padding: 10px 20px;
    align-items: center;
  }
  .checkbox.active {
    border: 2px solid #ea7d1c;
  }
  .checkbox input {
    visibility: hidden;
    position: absolute;
    left: -999px;
  }
  .text {
    font-weight: 400;
    color: #999;
    text-transform: none;
  }
  .checkbox input:checked ~ .text {
    color: #ea7d1c;
    font-weight: 600;
  }

</style>
