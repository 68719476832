
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import Avatar from 'shared/components/avatar';
import startCase from 'lodash/startCase';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faMale } from '@fortawesome/pro-solid-svg-icons/faMale';
import { faFemale } from '@fortawesome/pro-solid-svg-icons/faFemale';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo';
import { faSchool } from '@fortawesome/pro-solid-svg-icons/faSchool';
import { faGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons/faDatabase';
import { faImages } from '@fortawesome/pro-solid-svg-icons/faImages';
import { dateFormat, phoneFormat } from 'shared/util';
import { CompactTeen } from 'shared/components/TeenLookup';
import { Gender } from "shared/generated/graphql-types";
library.add(faUserFriends, faDatabase,faImages,faPhone, faEnvelope, faMobileAlt, faGift, faHome,faMale, faFemale, faKey, faRedo, faSchool, faGraduationCap)
@Component({
  name: "TeenInfo",
  components: {
  Avatar,
  },
  methods: {
    startCase,
    phoneFormat,
    dateFormat
  }
})

export default class TeenInfo extends Vue {
	@Prop() teen!: CompactTeen
   Gender = Gender;

   getGenderColor (gender: any) {
		if (gender === Gender.Male) {
			return '#00bcd4'
		} else {
			return '#cd8ece'
		}
	}
}

