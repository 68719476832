
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import Filters from 'shared/components/Filters/Filters.vue';
import { FilterOption, FilterOptionGroup } from 'shared/components/Filters/FilterOption';
import { CompactTeen } from '../../shared/CompactTeen';
import uniqBy from 'lodash/uniqBy';
import UISearch from 'shared/ui/forms/Search.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import Tables from 'shared/ui/tables/Tables.vue';
import get from 'lodash/get';
import { AttendanceStatus } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type Event = ArrayElement<CompactTeen['events']>

library.add(faSlidersH);
interface Column {
  columnName: string;
  propertyName: string;
}

interface Data {
  term: string;
  filtersOpen: boolean;
  activeFilters: { [key: string]: FilterOption[] };
  ascending: boolean;
  sortColumn: string;
  currentColumn: Column | null;
  columns: Column[];
}

interface Props {
  teen: CompactTeen;
}

interface Computed {
  summerProgramLabels: any;
  eventCategoryOptions: FilterOption[];
  eventTypeOptions: FilterOption[];
  seasonsOptions: FilterOption[];
  filters: FilterOptionGroup[];
  search: Event[];
}

interface Methods {
  workAround: (column: Column) => { propertyName: string; columnName: string };
  sortTable: (col: Column | null, array: Event[]) => Event[];
  dateFormat: (date: string) => string;
  getEventStatus: (event: any) => string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Events',
  components: {
    UISearch,
    Filters,
    Tables,
    RouterLink
  },
  data() {
    return {
      term: '',
      filtersOpen: false,
      activeFilters: {},
      ascending: true,
      sortColumn: 'startDate',
      currentColumn: {
        columnName: 'Date',
        propertyName: 'startDate'
      },
      columns: [
        { columnName: 'Event', propertyName: 'eventName' },
        { columnName: 'Type', propertyName: 'EventSubType.description' },
        { columnName: 'Date', propertyName: 'startDate' },
        { columnName: 'Balance', propertyName: 'balance' },
        { columnName: 'Status', propertyName: 'status' }
      ]
    };
  },
  props: {
    teen: {}
  },
  computed: {
    summerProgramLabels() {
      return {
        Cancelled: 'Cancelled',
        Completed: 'Accepted',
        HasScholarship: 'HasScholarship',
        PendingApproval: 'Incomplete Application',
        RefundNeeded: 'Refund Needed',
        Unpaid: 'Unpaid',
        WaitingForDeposit: 'WaitingForDeposit',
        WaitingList: 'Applied',
        Waiting: 'Applied'
      };
    },
    eventCategoryOptions() {
      return [
        { key: '0', displayValue: 'Walk In', predicate: (e: any) => !e.registrationRequired },
        {
          key: '1',
          displayValue: 'Registration Required',
          predicate: (e: any) => e.registrationRequired
        },
        { key: '3', displayValue: 'Series', predicate: (e: any) => e.seriesId }
      ];
    },
    eventTypeOptions() {
      return uniqBy(
        this.teen.events.map((e) => e?.EventSubType),
        'description'
      ).map((est) => ({
        key: String(est?.eventSubTypeId || ''),
        displayValue: est?.description || '',
        predicate: (e: any) => e?.EventSubType && e.EventSubType.eventSubTypeId === est?.eventSubTypeId
      }));
    },
    seasonsOptions() {
      return uniqBy(
        this.teen.events.map((e) => e).filter((e) => e?.season),
        'season'
      ).map((e) => ({
        key: e?.season || '',
        displayValue: e?.season || '',
        predicate: (x: Event) => x?.season === e?.season
      }));
    },
    filters() {
      return [
        {
          name: 'Event Category',
          displayName: 'Event Category',
          options: this.eventCategoryOptions
        },
        { name: 'Seasons', displayName: 'Seasons', options: this.seasonsOptions },
        { name: 'Event Type', displayName: 'Event Type', options: this.eventTypeOptions }
      ];
    },
    search() {
      let result = ((this.teen || { events: [] }).events || []).filter(
        (event: Event) =>
          `${event?.eventName}`.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0
      );
      const filterPredicateGroups = Object.keys(this.activeFilters)
        .map((f) => ({ filterType: f, predicates: this.activeFilters[f].map((t) => t.predicate) }))
        .filter((fpg) => fpg.predicates.length);
      if (filterPredicateGroups.length) {
        result = result.filter((r) =>
          filterPredicateGroups.some((fpg) => fpg.predicates.some((p) => p(r)))
        );
      }
      return this.sortTable(this.currentColumn, result);
    }
  },
  methods: {
    workAround(column: Column) {
      return {
        ...column,
        propertyName: column.propertyName + '|' + Math.floor(Math.random() * -10000 + 1)
      };
    },
    sortTable(col: Column | null, array: Event[]) {
      if (!col) return array;
      let column = { ...col, propertyName: col.propertyName && col.propertyName.split('|')[0] };
      if (this.sortColumn == column.propertyName) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = column.propertyName;
      }
      var ascending = this.ascending;
      return array.sort((a, b) => {
        if (get(a, column['propertyName']) > get(b, column['propertyName'])) {
          return ascending ? 1 : -1;
        } else if (get(a, column['propertyName']) < get(b, column['propertyName'])) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
    dateFormat(date: string) {
      if (date) {
        return `${date.split('T')[0].split('-')[1]}/${date.split('T')[0].split('-')[2]}/${
          date.split('T')[0].split('-')[0]
        }`;
      }
      return '';
    },
    getEventStatus(event: any) {
      if (
        event.EventSubType &&
        event.EventSubType.EventType &&
        event.EventSubType.EventType.eventTypeId === 28
      ) {
        return this.summerProgramLabels[event.status];
      }
      if (!event || !event.status) return 'N/A';
      if (event.registrationRequired) return event.status;
      if (event.status === AttendanceStatus.SignedUp) return 'Pre-Registered';
      if (event.status === AttendanceStatus.Attended) return AttendanceStatus.Attended;
    }
  }
});
