
import Vue from 'vue';
import UISearch from 'shared/ui/forms/Search.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import * as RadioGroup from 'shared/radio-group';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';

library.add(faPlusCircle)

export default Vue.extend({
  name: 'Sidebar',
  props: {
    scholarshipsLength: Number,
    status: String,
    search: String,
    regionId: Number,
    currencySymbol: String,
    scholarshipsApprovedSum: Number,
    clear: Function,
    chapterValue: Object
  },
  components: {
    UISearch,
    FilterGroup,
    UIButton,
    UIRadio,
    ChapterLookup,
    PanelsSidebar,
    ...RadioGroup,
    ScrollablePanel
	},
	directives: {
		fullheight
  }
});
