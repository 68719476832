
import Vue from 'vue';
import UISelect from 'shared/ui/forms/FancySelect';
import { EventTrack } from './'

export default Vue.extend({
  name: 'EventTrackLookup',
  components: { UISelect },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    eventTracks: {
      type: Array as () => EventTrack[]
    },
    value: {
      type: [
        Object as () => number | EventTrack | null,
        Number,
        Array as () => EventTrack[]
      ]
    },
    multiple: {
      type: Boolean
    }
  },
  computed: {
    valueAsArray(): { id: number | null; name: string }[] | null {
      if (!this.value) return [{ id: null, name: 'Not part of a track' }];

      const value: Array<number | EventTrack> = Array.isArray(this.value)
        ? this.value
        : [this.value];

      return value
        .map((track) =>
          typeof track === 'object' && track
            ? track
            : (this.eventTracks || []).find((x: EventTrack) => x.id === track)!
        )
        .filter((et) => et);
    }
  }
});
