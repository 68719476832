
import Vue from 'vue';
import SimpleForm, { Error, Field } from 'vue-simpleform';
import UISelect from 'shared/ui/forms/FancySelect';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import PhoneInput from "shared/ui/forms/Phone.vue";
import { ErrorBag } from 'shared/util/types';
import { PhoneType } from 'shared/generated/graphql-types';
import { validateNumber } from 'shared/util';
import { Props, Data, Methods, FormShape } from './types';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddPhoneForm',
  components: {
    SimpleForm,
    Error,
    Field,
    UISelect,
    UICheckbox,
    UIButton,
    PhoneInput
  },
  props: {
    personId: {},
    requestClose: {},
    addPhone: {},
    addingPhone: {},
    selectedPhoneNumber: {}
  },
  data() {
    return {
      PhoneType,
      formObject: {
        phoneNumber: null,
        type: null,
        doNotCall: false
      }
    }
  },
  methods: {
    validate(values) {
      const errors: ErrorBag<FormShape> = {};
      if (!values.phoneNumber) {
        errors.phoneNumber = 'A valid Phone number is required';
      }
      if(!values.type) {
        errors.type = 'Phone type is required';
      }
      return errors;
    },
    async submitHandler(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      await this.addPhone({
        personId: this.personId,
        phoneNumber: args.values.phoneNumber!,
        type: args.values.type!,
        doNotCall: args.values.doNotCall
      });
      if (args.setSubmitted) args.setSubmitted();
      this.requestClose();
    }
  }
})
