import { Event } from './types'

export function navigateTo (event: Event) {
  if (event.seriesId) {
    return `/events/series/${event.seriesId}/summary`;
  }
  else {
    return `/events/${event.eventId}/dashboard`;
  }
}
