import { compose, withData, withProps, acceptProps } from 'vue-compose';
import Vue, { Component as VueComponent, computed } from 'vue';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import EventCalendar from './EventCalendar.vue';
import { normalizeProps, getOptions } from 'shared/util/index';
import { ArrayElement, SimpleDate } from 'shared/util/types';
import { WithDatesProps, StateToProps } from './types';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  EventFilterInput,
  GetEventsQuery,
  RegionWideFilter,
  useGetEventsQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

export const filtersInit = (user: number): EventFilterInput => ({
  advisorID: [user],
  chapterId: undefined,
  EventSubTypeID: undefined,
  EventType: undefined,
  seriesID: undefined,
  trackId: undefined,
  term: undefined,
  regionWide: RegionWideFilter.Show
});

const withDates = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  props.persistedDate = {};

  const ComponentWithDate = Vue.extend({
    name: 'DateContainer',
    props: omit(props, [
      'dates',
      'datesChanged',
      'currentDate',
      'setCurrentDate',
      'mode',
      'setMode'
    ]),
    data() {
      const persistedDate: SimpleDate = this.persistedDate;

      const today = persistedDate
        ? new Date(persistedDate.year, persistedDate.month - 1, persistedDate.day)
        : new Date();

      return {
        dates: {
          startDate: null,
          endDate: null
        },
        currentDate: {
          month: today.getMonth() + 1,
          year: today.getFullYear(),
          day: today.getDate()
        },
        mode: 'month'
      };
    },
    methods: {
      datesChanged(dates: any) {
        Object.assign(this.dates, dates);
      },
      setCurrentDate(date: SimpleDate) {
        this.currentDate = date;
      },
      setMode(mode: string) {
        this.mode = mode;
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          dates: this.dates,
          datesChanged: this.datesChanged,
          currentDate: this.currentDate,
          setCurrentDate: this.setCurrentDate,
          mode: this.mode,
          setMode: this.setMode
        }
      });
    }
  });

  return ComponentWithDate;
};

const withFiltersFromStorage = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  const ComponentWithPersistedFilters = Vue.extend({
    name: 'CalendarPersistance',
    props: omit(props, [
      'dates',
      'datesChanged',
      'currentDate',
      'setCurrentDate',
      'mode',
      'setMode',
      'persistState'
    ]),
    methods: {
      persistState(filters: EventFilterInput, date: SimpleDate) {
        localStorage.calendarPersistance = JSON.stringify({
          persistedFilters: {
            ...filters,
            ...(has(filters, 'regionWide') ? { regionWide: RegionWideFilter.Show } : {})
          },
          persistedDate: date
        });
      }
    },
    render(h) {
      const persistedState = localStorage.getItem('calendarPersistance') || '{}';

      const { persistedFilters, persistedDate } = JSON.parse(persistedState);

      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props,
          persistedDate,
          persistedFilters,
          persistState: this.persistState
        }
      });
    }
  });

  return ComponentWithPersistedFilters;
};

const persistFilters = (Component: VueComponent): VueComponent => {
  const props = normalizeProps(getOptions(Component).props);

  props.persistState = {};

  const EnhancedComponent = Vue.extend({
    name: 'CalendarPersistance',
    props,
    watch: {
      currentDate() {
        this.persistState(this.filters, this.currentDate);
      },
      filters() {
        this.persistState(this.filters, this.currentDate);
      }
    },
    render(h) {
      return h(Component, {
        on: this.$listeners,
        props: {
          ...this.$props
        }
      });
    }
  });

  return EnhancedComponent;
};

type Props = WithDatesProps & StateToProps & { filters: EventFilterInput };

interface EventProps {
  events: ReadonlyArray<ArrayElement<GetEventsQuery['events']>>;
  loading: boolean;
}

const getEventsEnhancer = wrapComponent<Props, EventProps>((props) => {
  const { loading, result } = useGetEventsQuery(
    computed(() => ({
      filter: {
        regionId: props.currentRegion,
        advisorID: props.filters.advisorID,
        EventSubTypeID: props.filters.EventSubTypeID,
        chapterId: props.filters.chapterId,
        EventType: props.filters.EventType,
        seriesID: props.filters.seriesID,
        trackId: props.filters.trackId ? props.filters.trackId.filter(Boolean) : undefined,
        term: props.filters.term,
        regionWide: props.filters.regionWide,
        range: {
          startDate: props.dates.startDate,
          endDate: props.dates.endDate
        }
      }
    })),
    { fetchPolicy: 'network-only', enabled: computed(() => !!props.dates.startDate) }
  );

  return computed(() => ({
    events: result.value?.events && !loading.value ? Object.freeze(result.value.events) : [],
    loading: loading.value
  }));
});

interface PersistedState {
  persistedFilters: EventFilterInput;
  persistedDate: SimpleDate;
}

export const enhancer = compose(
  withFiltersFromStorage,
  withData<PersistedState & { user: number }, any>({
    filters: {
      initialValue: (props) =>
        props.persistedFilters ? props.persistedFilters : filtersInit(props.user)
    }
  }),
  acceptProps(['persistedDate', 'persistedFilters', 'persistState']),
  withProps<any, any>((props: any) => ({
    clearFilters(this: Vue, state?: object) {
      this.$emit('filters', {
        advisorID: null,
        chapterId: undefined,
        EventSubTypeID: undefined,
        EventType: undefined,
        seriesID: undefined,
        trackId: undefined,
        term: undefined,
        regionWide: RegionWideFilter.Show,
        ...(!isEmpty(state) ? state : {})
      });
    },
    currentRegion: getCurrentRegion()
  })),
  withDates,
  persistFilters,
  getEventsEnhancer
);

export default enhancer(EventCalendar);
