
  import Vue from "vue";
  import SimpleForm from "vue-simpleform";
  import { RouterLink } from "vue-component-router";
  import UIButton from "shared/ui/buttons/Button.vue";
  import UIInput from "shared/ui/forms/Input.vue";
  import ScrollablePanel from "shared/components/scrollable-panel.vue";
  import Loading from "shared/components/Loading.vue";
  import NoData from "shared/components/NoData.vue";
  import fullheight from "shared/directives/fullheight";
  import FormSection from "shared/ui/forms/FormSection.vue";
  import UISelect from "shared/ui/forms/FancySelect";
  import UITextarea from "shared/ui/forms/Textarea.vue";
  import PanelsGrid from "shared/components/structure/panelsGrid.vue";
  import TeenProfile from "shared/components/TeenProfile";
  import Avatar from "shared/components/avatar";
  import Modal from "shared/ui/modal/Modal.vue";
  import FancySelect from "shared/ui/forms/FancySelect";
  import LeadStatusLookup from "./components/LeadStatusLookup";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
  import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
  import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
  import InteractionForm from "shared/components/InteractionForm";
  import RequestTeenSheet from "shared/components/TeenSheet";
  import TeenLookup from "shared/components/TeenLookup";
  import GoalForm from "../GoalForm";
  import { Props, Data, Methods, Computed } from "./types";
  import { goalDisplay } from "shared/components/GoalLookup/utils";
  import { LeadStatus } from "shared/generated/graphql-types";

  library.add(faPlusCircle, faCaretLeft, faTrash);

  export default Vue.extend<Data, Methods, Computed, Props>({
    name: "SingleGoal",
    components: {
      SimpleForm,
      Modal,
      Loading,
      NoData,
      FancySelect,
      PanelsGrid,
      Avatar,
      RouterLink,
      TeenProfile,
      UIButton,
      UIInput,
      UITextarea,
      UISelect,
      FormSection,
      ScrollablePanel,
      RequestTeenSheet,
      TeenLookup,
      InteractionForm,
      LeadStatusLookup,
      GoalForm
    },
    directives: {
      fullheight,
    },
    data() {
      return {
        status: false,
        addingNewLead: false,
        interaction: false,
        profileClose: true,
        teenSelected: null,
        leadSelected: null,
        teenIdForInteraction: null,
        leadIdForInteraction: null,
        LeadStatus,
        addingNewInteraction: false,
        addingTeenFailed: false,
        showRemoveLeadModal: false,
        showEditGoalModal: false,
        leadIdToRemove: null
      };
    },
    props: {
      id: {},
      goalLoading: {},
      goal: {},
      regionId: {},
      markAsLead: {},
      markingAsLead: {},
      removingLead: {},
      removeLead: {},
      me: {}
    },
    computed: {
      mappedGoal() {
        return this.goal
          ? {
              ...this.goal,
              Leads: (this.goal!.Leads || []).map((l) => {
                return {
                  ...l,
                  Person: {
                    ...l.Person,
                    Teen: l.Person.Teen
                      ? {
                      ...l.Person.Teen,
                      Interactions: l.Person.Teen!.Interactions.slice().sort((a, b) => {
                        return new Date(a.date) < new Date(b.date) ? 1 : -1;
                      })
                    }
                    : null
                  }
                };
              })
            }
          : null;
      },
      teenIds() {
        return (this.goal && this.goal.Leads || []).map((l) => l.Person.personID);
      }
    },
    methods: {
      goalDisplay,
      closeProfile() {
        this.profileClose === true
          ? (this.profileClose = false)
          : (this.profileClose = true);
      },
      getGenderColor(gender: number) {
        return gender ? "#cd8ece" : "#00bcd4";
      },
      async quickMarkAsLeadHandler(teen) {
        // TODO fix typings of TeenLookup...
        try {
          await this.markAsLead(teen.personID, this.goal!.id);
        } catch (e) {
          this.onAddingTeenFailed(true);
        }
      },
      isLead(personId) {
        return !!(this.goal!.Leads || []).find(l => l.Person.personID === personId);
      },
      async markAsLeadHandler(personId) {
        await this.markAsLead(personId, this.goal!.id);
      },
      onAddingTeenFailed(flag) {
        this.addingTeenFailed = flag;
      },
      openInteractionModal(leadId, personId) {
        this.leadIdForInteraction = leadId;
        this.teenIdForInteraction = personId;
        this.addingNewInteraction = true;
      },
      closeInteractionModal() {
        this.teenIdForInteraction = null;
        this.leadIdForInteraction = null;
        this.addingNewInteraction = false;
      },
      toggleRemoveLeadModal(leadId) {
        this.showRemoveLeadModal = !!leadId;
        this.leadIdToRemove = leadId;
      },
      async removeLeadHandler() {
        await this.removeLead(this.leadIdToRemove!, this.goal!.id);
        this.toggleRemoveLeadModal(null);
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      }
    },
  });
