
import Vue from "vue";
import SimpleForm, { Field, Error } from 'vue-simpleform';
import { RouterLink } from 'vue-component-router';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import fullheight from 'shared/directives/fullheight';
import FormSection from 'shared/ui/forms/FormSection.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import StaffLookup from 'shared/components/StaffLookup';
import SchoolLookup, { School } from 'shared/components/SchoolLookup';
import SchoolTypeLookup from 'shared/components/SchoolTypeLookup';
import InteractionTypeLookup from 'shared/components/InteractionTypeLookup';
import EventTrackLookup from 'shared/components/EventTrackLookup';
import EventTypeLookup from 'shared/components/EventTypeLookup';
import EventSubTypeLookup from 'shared/components/EventSubTypeLookup';
import DatePicker from 'shared/components/DatePicker.vue';
import { Props, Methods, Data, GoalFormObject, SchoolType, EventTrack, EventType, EventSubType } from './types';
import { startDateBeforeEndDate } from 'shared/util';
import { Errors } from 'types/helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { GoalTypeCategory, InteractionType, SecondaryInstitutionType } from "shared/generated/graphql-types";

library.add(faTimes);

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'GoalForm',
  components: {
    SimpleForm,
    Field,
    Error,
    RouterLink,
    UIButton,
    UIInput,
    UITextarea,
    UISelect,
    FormSection,
    ChapterLookup,
    StaffLookup,
    SchoolLookup,
    SchoolTypeLookup,
    InteractionTypeLookup,
    EventTrackLookup,
    EventTypeLookup,
    EventSubTypeLookup,
    DatePicker
  },
  directives: {
    fullheight
  },
  props: {
    goalTypes: {},
    createGoal: {},
    editGoal: {},
    goal: {},
    filters: {},
    limit: {},
    currentPage: {},
    regionId: {},
    me: {},
    isEdit: { default: false }
  },
  data() {
    return {
      formObject: {
        goalType: null,
        target: null,
        chapter: null,
        staff: this.me && {
          firstName: this.me.firstName,
          lastName: this.me.lastName,
          fullName: this.me.fullName,
          staffID: this.me.staffID,
          __typename: 'Staff'
        } || null,
        note: null,
        description: null,
        school: null,
        schoolType: null,
        interactionType: null,
        eventTrack: null,
        eventType: null,
        eventSubType: null,
        startDate: null,
        endDate: null
      },
      GoalTypeCategory,
      filterOutSchoolTypes: [
        SecondaryInstitutionType.FourYear,
        SecondaryInstitutionType.TwoYear,
        SecondaryInstitutionType.Seminary,
        SecondaryInstitutionType.GapYearIsrael,
        SecondaryInstitutionType.Program,
        SecondaryInstitutionType.GapYearNonIsrael,
        SecondaryInstitutionType.Idf,
        SecondaryInstitutionType.UsMilitary,
        SecondaryInstitutionType.Other,
        SecondaryInstitutionType.JewishDaySchool
      ]
    }
  },
  created() {
    if (this.isEdit && this.goal) {
      const startDate = this.goal.startDate ? new Date(this.goal.startDate) : null;
      const endDate = this.goal.endDate ? new Date(this.goal.endDate) : null;
      this.formObject = {
        goalId: this.goal.id,
        goalType: this.goal.GoalType,
        target: this.goal.target,
        chapter: this.goal.Chapter,
        staff: this.goal.Staff || this.me,
        description: this.goal.description,
        startDate: startDate
          ? {
            year: startDate.getUTCFullYear(),
            month: startDate.getUTCMonth() + 1,
            day: startDate.getUTCDate()
          }
          : null,
          endDate: endDate
            ? {
              year: endDate.getUTCFullYear(),
              month: endDate.getUTCMonth() + 1,
              day: endDate.getUTCDate()
            }
            : null
      };

      if (this.goal.entity) {
        if (this.isShowSchoolType(this.goal.GoalType)) {
          this.formObject.schoolType = this.goal.entity as SchoolType;
        }
        if (this.isShowSchool(this.goal.GoalType)) {
          this.formObject.school = this.goal.entity as School;
        }
        if (this.isShowInteractionType(this.goal.GoalType)) {
          this.formObject.interactionType = this.goal.entity as InteractionType;
        }
        if (this.isShowEventTrack(this.goal.GoalType)) {
          this.formObject.eventTrack = this.goal.entity as EventTrack;
        }
        if (this.isShowEventType(this.goal.GoalType)) {
          this.formObject.eventType = this.goal.entity as EventType;
        }
        if (this.isShowEventSubType(this.goal.GoalType)) {
          this.formObject.eventSubType = this.goal.entity as EventSubType;
        }
      }
    }
  },
  methods: {
    validate(values) {
      const errors: Errors<GoalFormObject> = {};
      if (!values.goalType) {
        errors.goalType = 'Please select a Goal Type';
      }
      if (!values.target) {
        errors.target = 'Please select a target';
      }
      if (values.goalType && (values.goalType.category === GoalTypeCategory.InteractionsBySchoolType || values.goalType.category === GoalTypeCategory.SummerRecruitmentBySchoolType) && !values.schoolType) {
        errors.schoolType = 'Please select a School Type';
      }
      if (values.goalType && (values.goalType.category === GoalTypeCategory.InteractionsBySchool || values.goalType.category === GoalTypeCategory.SummerRecruitmentBySchool) && !values.school) {
        errors.school = 'Please select a School';
      }
      if (values.goalType && values.goalType.category === GoalTypeCategory.InteractionsByInteractionType && !values.interactionType) {
        errors.interactionType = 'Please select a Interaction Type';
      }
      if (values.goalType && values.goalType.category === GoalTypeCategory.EventAttendanceByTrack && !values.eventTrack) {
        errors.eventTrack = 'Please select a Event Track';
      }
      if (values.goalType && values.goalType.category === GoalTypeCategory.EventAttendanceByType && !values.eventType) {
        errors.eventType = 'Please select a Event Type';
      }
      if (values.goalType && (values.goalType.category === GoalTypeCategory.EventAttendanceBySubType || values.goalType.category === GoalTypeCategory.SummerRecruitmentBySubType) && !values.eventSubType) {
        errors.eventSubType = 'Please select a Event Sub Type';
      }
      if (values.startDate && !values.endDate) {
        errors.endDate = 'Please select end date';
      }
      if (values.endDate && !values.startDate) {
        errors.startDate = 'Please select start date';
      }
      if (startDateBeforeEndDate(values.endDate, values.startDate)) {
        this.$set(errors, 'startDateBeforeEndDate', 'The start date has to be before the end date');
      }
      return errors;
    },
    async submitGoalHandler(args) {
      if ('errors' in args) return;

      if (args.setSubmitting) args.setSubmitting();

      if (this.isEdit) {
        await this.editGoal(args);
      } else {
        await this.createGoal(args);
      }
      if (args.setSubmitted) args.setSubmitted();
      this.$emit('requestClose');
    },
    isShowSchoolType(goalType) {
      return goalType && (goalType.category === GoalTypeCategory.InteractionsBySchoolType || goalType.category === GoalTypeCategory.SummerRecruitmentBySchoolType);
    },
    isShowSchool(goalType) {
      return goalType && (goalType.category === GoalTypeCategory.InteractionsBySchool || goalType.category === GoalTypeCategory.SummerRecruitmentBySchool)
    },
    isShowInteractionType(goalType) {
      return goalType && goalType.category === GoalTypeCategory.InteractionsByInteractionType
    },
    isShowEventTrack(goalType) {
      return goalType && goalType.category === GoalTypeCategory.EventAttendanceByTrack
    },
    isShowEventType(goalType) {
      return goalType && goalType.category === GoalTypeCategory.EventAttendanceByType
    },
    isShowEventSubType(goalType) {
      return goalType && (goalType.category === GoalTypeCategory.EventAttendanceBySubType || goalType.category === GoalTypeCategory.SummerRecruitmentBySubType)
    },
  }
});
