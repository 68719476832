
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import Loading from 'shared/components/Loading.vue';
import Avatar from 'shared/components/avatar';
import Divider from 'shared/layout/divider/layout-divider.vue';
import ProfilePhoto from 'shared/components/ProfilePhoto/ProfilePhoto.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons/faMobileAlt';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faMale } from '@fortawesome/pro-solid-svg-icons/faMale';
import { faFemale } from '@fortawesome/pro-solid-svg-icons/faFemale';
import UISwitch from 'shared/ui/forms/Switch.vue';
import copy from 'copy-text-to-clipboard';
import { asUTCWithZerotime, phoneInternational, validateEmail, dateFormat, phoneFormat, validateNumber } from 'shared/util';
import SaveEventArgs from 'shared/components/AutoSaveField/SaveEventArgs';
import AddressLookup from 'shared/components/Address/Address.vue';
import Address from './Address';
import UIInput from 'shared/ui/forms/Input.vue';
import AutoSaveField from 'shared/components/AutoSaveField';
import EmailInput from 'shared/components/EmailInput';
import UITextarea from 'shared/ui/forms/Textarea.vue';
import { StaffFieldsEnum, StaffViewModel, Address as AddressType, UpdateStaff } from './types';
import PhoneInput from 'shared/ui/forms/Phone.vue';
import { UpdateStaffInput, UploadStaffImageProfileMutationVariables } from 'shared/generated/graphql-types';

library.add(faPhone, faEnvelope, faMobileAlt, faGift, faHome,faMale, faFemale)

@Component({
	name: "StaffProfile",
	components: {
    ProfilePhoto,
    AutoSaveField,
    EmailInput,
    Divider,
    UIInput,
    AddressLookup,
    UITextarea,
    UISwitch,
    Loading,
    Avatar,
    UIButton,
    Modal,
    ScrollablePanel,
    PhoneInput,
    FilterGroup
	},
	directives: {
		fullheight
	},
	methods: {
    validateEmail,
    asUTCWithZerotime,
    phoneInternational,
    dateFormat,
    validateNumber
	}
})
export default class StaffProfile extends Vue {
    @Prop() staffId!: number;
    @Prop() regionId!: number;
    @Prop() staff!: StaffViewModel;
    @Prop() loadingStaffProfile!: boolean;
    @Prop() uploadingStaffImageProile!: boolean;
    @Prop() uploadStaffImageProfile!: (args: UploadStaffImageProfileMutationVariables) => void;
    @Prop() deletingProfileImage!: boolean;
    @Prop() deleteStaffProfileImage!: (staffId: number, optimisticResponse: StaffViewModel) => void;
    @Prop() updateStaff!: (updateStaffArgs: UpdateStaffInput, staff: StaffViewModel) => void;

    uploadedImage: string | null = null;
    uploading: boolean = false;
    staffCopy = Object.assign({}, { ...this.staff });
    updateStaffField = StaffFieldsEnum
    editingMode: boolean = false;
    showDeleteProfileImageModal: boolean = false;

    phoneFormat(phoneNumber: string){
      if(!phoneNumber) return;
      return phoneFormat(phoneNumber, this.staff.country || '');
    }

    update(args: SaveEventArgs <{ fieldName: StaffFieldsEnum, value: string }> ) {
        const fieldName: StaffFieldsEnum = args.transformed.fieldName;
        const transformedValue = args.transformed.value;
        const staffAddress = args.name === 'address' ? new Address(JSON.parse(transformedValue)) : null;
        const staff = { ...this.staff, ...(staffAddress ? (staffAddress as AddressType) : { [args.name]: transformedValue }) }
        this.updateStaff(new UpdateStaff(staff), staff)
    }

    copyToClipboard (string: string) {
      if (this.editingMode || !string) return
      copy(string)
    }
		async uploadedImageHandler(image: any[]) {
				const getImage = () => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							this.uploadedImage = image && image.length ? image[0].base64 : null;
							if (!this.uploadedImage) reject('oyshh');
							resolve('yey')
						}, 2000);
					})
				};

				const getImageResults = await getImage();
				if (this.uploadedImage && getImageResults) {
          try {
            await this.uploadStaffImageProfile({
                staffId: this.staffId,
                image: this.uploadedImage
                })
            this.uploading = false;
          }
          catch (e) {
            this.uploading = false;
            console.log(e)
          }
				}
    }
    get isGlaubachFellow () {
      return !!(this.staff.original.Staff.GlaubachFellows || []).length
    }
    @Watch('staff', {
        deep: true
    })
    staffViewModelWatcher() {
        if (this.staff) this.staffCopy = Object.assign({}, {
            ...this.staff
        });
    }
    getGenderColor(gender: number) {
      if (!gender) {
        return '#00bcd4';
      } else {
        return '#cd8ece';
      }
    }
    get profileImage() {
      if (this.uploadedImage) {
        this.uploading = false;
        return `data:image/png;base64, ${this.uploadedImage}`;
      }
      else {
        if (this.staff.thumbnail) {
          return this.staff.thumbnail;
        }
        this.uploading = false;
        return '';
      }
    }
    async deleteProfileImageHandler () {
      await this.deleteStaffProfileImage(this.staffId, this.staff);
      this.uploadedImage = '';
      this.showDeleteProfileImageModal = false;
    }
}

