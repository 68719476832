
import UIButton from 'shared/ui/buttons/Button.vue';
import UITextrea from 'shared/ui/forms/Textarea.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import StaffLookup from 'shared/components/StaffLookup';
import DatePicker from 'shared/components/DatePicker.vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import Vue from 'vue';
import startCase from 'lodash/startCase';
import { NoteFormObject } from '../types';
import { NoteCategory } from 'shared/generated/graphql-types';

export default Vue.extend({
  name: 'NoteForm',
  props: ['currentStaff','note'],
  components: {
    UITextrea,
    UIButton,
    FancySelect,
    StaffLookup,
    Field,
    Error,
    DatePicker,
    SimpleForm
  },
  created () {
    this.noteCategories = Object.keys(NoteCategory).map(x => ({
        name: startCase(x),
        value: x
    }))

    const date = new Date();

    const today = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    }

    this.initialValue = this.note ||
        {staff: this.currentStaff, date: today, category: null, note: null}
  },
  methods: {
      handleSubmit(callbackArgs: SubmitCallbackArgs<NoteFormObject>) {
            if('errors' in callbackArgs) return;

            this.$emit('submit', callbackArgs.values);
        },
        validate (values: NoteFormObject) {
            let errors: { [key: string]: string} = {};

            if (!values.staff) {
                errors['staff'] = "A staff member must be selected";
            }

            if(!values.category) {
                errors['category'] = "A category must be selected";
            }

            if(!values.date) {
                errors['date'] = "A date must be selected";
            }

            if(!values.note) {
                errors['note'] = "A note must be provided";
            }

            return errors;
        }
  }
})
