
import { Vue, Prop, Component } from 'vue-property-decorator'
import { Route, RouterLink } from 'vue-component-router';
import UIButton from 'shared/ui/buttons/Button.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import ChapterForm from './chapter-form.vue';
import Loading from 'shared/components/Loading.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { ChapterSummary, Chapter, Staff } from './types';
import fullheight from 'shared/directives/fullheight';

@Component({
    name: 'Chapters',
    components: {
      ChapterForm,
      UISearch,
      UIButton,
      Route,
      RouterLink,
      PanelsSidebar,
      PanelsGrid,
      Loading,
      BorderedList,
      ScrollablePanel
    },
    directives: {
      fullheight
    }
})
export default class extends Vue {
  @Prop() isLoading!: boolean;

  @Prop() chapters!: ChapterSummary[];

  @Prop() staff!: Staff[];

  @Prop() currentChapter!: Chapter;

  @Prop() currentChapterId!: number;
  @Prop() regionID!: number;

  @Prop() updateChapter!: () => void;

  filter: string | null = null;

  get filteredChapters () {
    const { filter, chapters } = this;

    if (!filter) return chapters

    return chapters.filter(c => `${c.name}`.toLowerCase().indexOf(filter.toLowerCase()) >= 0 )
  }
}
