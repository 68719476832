
import Vue from "vue";
import draggable from "vuedraggable";
import UIButton from "shared/ui/buttons/Button.vue";
import ScrollablePanel from "shared/components/scrollable-panel.vue";
import Loading from "shared/components/Loading.vue";
import NoData from "shared/components/NoData.vue";
import fullheight from "shared/directives/fullheight";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { Props, Data, Methods, Computed } from "./types";
import QuestionEditor from "./components/Question";
import { defaultQuestion, ValidationRules, Validations, Question } from "./components/Question/types";
import DeleteCollectionModal from '../shared/DeleteCollectionModal';
import { b64ToCSVBlob } from "shared/util";
import FileSaver from "file-saver";
import { GetAnswersReportByEventIdDocument, GetAnswersReportByEventIdQuery, QuestionType } from "shared/generated/graphql-types";
import { useApolloClient } from "@vue/apollo-composable";


library.add(faPlus, faCaretLeft, faTrash, faMinus, faBars);
const commonRules = {
  isRequired: (value: any) => !!value,
};

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "CustomQuestionsCollectionItem",
  components: {
    Loading,
    NoData,
    UIButton,
    ScrollablePanel,
    QuestionEditor,
    draggable,
    DeleteCollectionModal
  },
  directives: {
    fullheight,
  },
  data() {
    return {
      newQuestions: [],
      saving: false,
      deleting: false,
    };
  },
  props: {
    id: {},
    eventId: {},
    eventName: {},
    name: {},
    timestamp: {},
    questions: {},
    questionsAmount: {},
    loadingCustomQuestionsCollection: {},
    regionId: {},
    saveQuestion: {},
    savingQuestion: {},
    deleteQuestion: {},
    deletingQuestion: {},
    removeCustomQuestionsCollection: {},
    path: {},
    router: {}
  },
  created() {
    this.newQuestions = JSON.parse(JSON.stringify(this.questions));
    for (let index = 0; index < this.newQuestions.length; index++) {
      this.newQuestions[index].original = { ...this.newQuestions[index] };
    }
    this.updateOrder();
  },
  watch: {
    questions() {
      if (!this.saving) {
        this.newQuestions = JSON.parse(JSON.stringify(this.questions));
        for (let index = 0; index < this.newQuestions.length; index++) {
          this.newQuestions[index].original = { ...this.newQuestions[index] };
        }
        this.updateOrder();
      }
    },
  },
  computed: {
    validations() {
      var rules: ValidationRules[] = [
        {
          property: "text",
          rules: [
            {
              isValid: commonRules.isRequired,
              message: "Question's text is required.",
            },
          ],
        },
        {
          property: "config",
          rules: [
            {
              isValid: (_value, question) => {
                if (question.type === QuestionType.RadioGroup) {
                  return question.config.options.length > 1
                }
                return true;
              },
              message: "Please provide at least two options.",
            },
            {
              isValid: (_value, question) => {
                if (question.type === QuestionType.Select) {
                  return question.config.options.length > 0
                }
                return true;
              },
              message: "Please provide any option.",
            }
          ],
        },
      ];
      return this.newQuestions.map((question) => {
        const validations: Validations = {};
        rules.forEach(
          ({ property, rules }) => {
            validations[property] = rules
              .filter((rule) => !rule.isValid(question[property], question))
              .map((violatedRule) => violatedRule.message)
          }
        )
        return validations;
      });
    },
    isValid() {
      for (const validationResult of this.validations) {
        for (const prop in validationResult) {
          if (validationResult[prop as keyof Question]?.length) {
            return false;
          }
        }
      }
      return true;
    }
  },
  methods: {
    async downloadAnswersReport() {
      const { client } = useApolloClient();

      const result = await client.query<GetAnswersReportByEventIdQuery>({
        query: GetAnswersReportByEventIdDocument,
        variables: { eventId: this.eventId }
      });
      if (result && result.data) {
        let blob = await b64ToCSVBlob(result.data.customQuestionsCollectionAnswersReport);
        FileSaver.saveAs(blob, `${this.eventId}_answers_report.csv`);
      }
    },
    addQuestion() {
      const question = {
        ...defaultQuestion(),
        order: this.newQuestions.length,
      };
      this.newQuestions.push(question);
    },
    async removeQuestion(question) {
      try {
        if (question.id) {
          await this.deleteQuestion(question.id);
        }
        this.newQuestions = this.newQuestions.filter((x) => x !== question);
        this.updateOrder();
      } catch { }
    },
    updateOrder() {
      this.newQuestions.forEach((question, index) => {
        if (question.order != index) {
          question.order = index;
          question.isSaved = false;
        }
      });
    },
    async saveQuestions() {
      if (!this.isValid) {
        return;
      }
      this.saving = true;
      for (let index = 0; index < this.newQuestions.length; index++) {
        var savedQuestion = await this.saveQuestion(this.newQuestions[index], this.id);
        savedQuestion.original = { ...savedQuestion }
        this.newQuestions.splice(index, 1, savedQuestion)
        savedQuestion.isSaved = true;
      }
      this.saving = false;
    },
    async tryDelete() {
      if (this.questions.length > 0) {
        this.deleting = true;
      } else {
        await this.removeCustomQuestionsCollection(this.id);
      }
    },
    async handleDeletion(args) {
      if ('errors' in args) return;
      if (args.setSubmitting) args.setSubmitting();
      await this.removeCustomQuestionsCollection(this.id);
      if (args.setSubmitted) args.setSubmitted();
      this.deleting = false;
    }
  },
});
