import { computed } from 'vue';
import Dashboard from './Dashboard.vue';
import { compose } from 'vue-compose';
import { CompactDashboardData, Props } from './types';
import { toCompactDashboardData } from './util';
import { withRouter } from 'vue-component-router';
import { wrapComponent } from 'shared/apollo-hoc';
import {
  useGetEventDashboardDataQuery,
  useGetRecentAndStarredQuery,
  useReportsQuery,
  useToggleStarReportMutation
} from 'shared/generated/graphql-types';

interface TChildProps {
  dashboardData: CompactDashboardData | null;
  registrationRequired: boolean;
  fetchingDashboardData: boolean;
}

const getEventDashboardDataEnahnacer = wrapComponent<Props, TChildProps>((props) => {
  const { loading, result } = useGetEventDashboardDataQuery(
    computed(() => ({ eventId: Number(props.eventId) }))
  );

  return computed(() => ({
    dashboardData: toCompactDashboardData(result.value?.event || null),
    registrationRequired: !!result.value?.event.registrationRequired,
    fetchingDashboardData: loading.value
  }));
});

type ReportProps = Pick<Props, 'reports' | 'fetchingReports'>;
const getReportsEnhancer = wrapComponent<Props, ReportProps>((props) => {
  const { loading, result } = useReportsQuery({
    enabled: computed(() => !!props.recent.length || !!props.starred.length)
  });

  return computed(() => ({
    fetchingReports: loading.value,
    reports: result.value?.reports
  }));
});

interface RecentAndStarredProps {
  starred: string[];
  recent: string[];
  fetchingMetaData: boolean;
}
const getMetaDataEnhancer = wrapComponent<Props, RecentAndStarredProps>((props) => {
  const { loading, result } = useGetRecentAndStarredQuery(
    computed(() => ({ staffID: props.user.staffID, regionId: props.userRegionId })),
    {
      enabled: computed(() => props.registrationRequired)
    }
  );

  return computed(() => ({
    recent: result.value?.singleStaff?.recentReports || [],
    starred: result.value?.singleStaff?.starredReports || [],
    fetchingMetaData: loading.value
  }));
});

interface ToggleStarReportProps {
  toggleStar: (reportId: string) => void;
}
const toggleReportEnhancer = wrapComponent<
  Pick<Props, 'user' | 'userRegionId' | 'starred'>,
  ToggleStarReportProps
>((props) => {
  const { mutate } = useToggleStarReportMutation();

  return computed(() => ({
    toggleStar: (reportId) => {
      const starred = [...((props && props.starred) || [])];
      const index = starred.indexOf(reportId);
      const advisorID = props.user.AdvisorRegions.find(
        (a) => a.Region.regionId === props.userRegionId
      )!.AdvisorRegionId;

      if (index === -1) {
        starred.push(reportId);
      } else {
        starred.splice(index, 1);
      }
      mutate(
        { advisorID, reportID: reportId },
        {
          optimisticResponse: {
            toggleStarReport: {
              __typename: 'AdvisorRegion',
              AdvisorRegionId: advisorID,
              starredReports: starred
            }
          }
        }
      );
    }
  }));
});

export default compose(
  getEventDashboardDataEnahnacer,
  getMetaDataEnhancer,
  toggleReportEnhancer,
  getReportsEnhancer,
  withRouter
)(Dashboard);
