import RecordAttendees from './RecordAttendees.vue';
import { ApolloQueryResult, NetworkStatus } from '@apollo/client';
import { compose, withData } from 'vue-compose';
import { withRouter } from 'vue-component-router';
import Vue, { computed, VueConstructor } from 'vue';
import { normalizeProps, getOptions } from 'shared/util';
import {
  AddAttendeesMutation,
  AttendanceStatus,
  GetEventDocument,
  GetEventQuery,
  GetTeensForAttendanceQuery,
  RemoveAttendeesMutation,
  SortType,
  TeenFilterInput,
  useAddAttendeesMutation,
  useBulkAddAttendeesMutation,
  useBulkRemoveAttendeesMutation,
  useGetTeensForAttendanceQuery,
  useRemoveAttendeesMutation
} from 'shared/generated/graphql-types';
import { MutateResult } from '@vue/apollo-composable';
import { wrapComponent } from 'shared/apollo-hoc';
import { ArrayElement } from 'shared/util/types';

type Event = GetEventQuery['event'];
type Teen = ArrayElement<GetTeensForAttendanceQuery['teens']['teens']>;

interface Props {
  event: Event;
  isAlumni: boolean;
  bulkAdding: boolean;
  bulkRemoving: boolean;
}

interface AddAttendeesProps {
  addAttendees: (teen: Teen) => MutateResult<AddAttendeesMutation>;
  addingAttende: boolean;
}

const addAttendeesEnhancer = wrapComponent<Props, AddAttendeesProps>((props) => {
  const { loading, mutate } = useAddAttendeesMutation();

  return computed(() => ({
    addAttendees: (teen) =>
      mutate(
        { eventId: props.event.eventId, personId: teen.personID },
        {
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventQuery>({
              query: GetEventDocument,
              variables: { eventId: props.event.eventId }
            });

            if (data && updateData?.addAttendees) {
              const attendances = data.event.Attendances.slice();

              attendances.push({
                attendanceId: updateData.addAttendees.attendanceId,
                status: AttendanceStatus.Attended,
                Teen: teen,
                __typename: 'Attendance'
              });

              proxy.writeQuery<GetEventQuery>({
                query: GetEventDocument,
                variables: { eventId: props.event.eventId },
                data: {
                  event: {
                    ...data.event,
                    Attendances: attendances
                  }
                }
              });
            }
          }
        }
      ),
    addingAttende: loading.value
  }));
});

interface BulkAddAttendeesProps {
  bulkAdd: (filter: Filters) => void;
  bulkAdding: boolean;
}

const bulkAddAttendeesEnhancer = wrapComponent<Props, BulkAddAttendeesProps>((props) => {
  const { loading, mutate } = useBulkAddAttendeesMutation();

  return computed(() => ({
    bulkAdding: loading.value,
    bulkAdd: (filter) =>
      mutate(
        { eventId: props.event.eventId, filter: convertFilters(filter, props.event) },
        {
          update: (proxy, { data: updateData }) => {
            const eventId = props.event.eventId;

            const data = proxy.readQuery<GetEventQuery>({
              query: GetEventDocument,
              variables: { eventId }
            });

            if (data && updateData?.bulkAddAttendees) {
              const attendances = data.event.Attendances.slice();

              updateData.bulkAddAttendees.forEach((x) =>
                attendances.push({
                  attendanceId: x.attendanceId,
                  status: AttendanceStatus.Attended,
                  Teen: x.Teen,
                  __typename: 'Attendance'
                })
              );

              proxy.writeQuery<GetEventQuery>({
                query: GetEventDocument,
                variables: { eventId },
                data: {
                  event: {
                    ...data.event,
                    Attendances: attendances
                  }
                }
              });
            }
          }
        }
      )
  }));
});

interface BulkRemoveAttendeesProps {
  bulkRemove: (filters: Filters) => void;
  bulkRemoving: boolean;
}

const bulkRemoveAttendeesEnhancer = wrapComponent<Props, BulkRemoveAttendeesProps>((props) => {
  const { loading, mutate } = useBulkRemoveAttendeesMutation();

  return computed(() => ({
    bulkRemoving: loading.value,
    bulkRemove: (filters) =>
      mutate(
        { eventId: props.event.eventId, filter: convertFilters(filters, props.event) },
        {
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventQuery>({
              query: GetEventDocument,
              variables: { eventId: props.event.eventId }
            });
            if (data && updateData?.bulkRemoveAttendees) {
              const attendances = data.event.Attendances.slice();

              updateData.bulkRemoveAttendees.forEach((x) => {
                const teenIndex = attendances.findIndex((a) => a.attendanceId === x.attendanceId);

                if (teenIndex > -1) {
                  attendances.splice(teenIndex, 1);
                }
              });

              proxy.writeQuery<GetEventQuery>({
                query: GetEventDocument,
                variables: { eventId: props.event.eventId },
                data: {
                  event: {
                    ...data.event,
                    Attendances: attendances
                  }
                }
              });
            }
          }
        }
      )
  }));
});

interface RemoveAttendeesProps {
  removeAttendees: (attendanceId: number) => MutateResult<RemoveAttendeesMutation>;
  removingAttende: boolean;
}

const removeAttendeesEnhancer = wrapComponent<Props, RemoveAttendeesProps>((props) => {
  const { loading, mutate } = useRemoveAttendeesMutation();

  return computed(() => ({
    removingAttende: loading.value,
    removeAttendees: (attendanceId) =>
      mutate(
        { attendanceId },
        {
          update: (proxy) => {
            const data = proxy.readQuery<GetEventQuery>({
              query: GetEventDocument,
              variables: { eventId: props.event.eventId }
            });

            if (data) {
              const attendances = data.event.Attendances.slice();
              const teenIndex = attendances.findIndex((a) => a.attendanceId === attendanceId);
              if (teenIndex > -1) {
                // @ts-ignore
                if (attendances[teenIndex].signUpDate) {
                  attendances[teenIndex].status = AttendanceStatus.SignedUp;
                } else {
                  attendances.splice(teenIndex, 1);
                }
              }
              proxy.writeQuery<GetEventQuery>({
                query: GetEventDocument,
                variables: { eventId: props.event.eventId },
                data: {
                  event: {
                    ...data.event,
                    Attendances: attendances
                  }
                }
              });
            }
          }
        }
      )
  }));
});

interface Props {
  event: Event;
  filters: Filters;
}

interface TeensForAttendance {
  teens: Teen[];
  isLoading: boolean;
  loadingMore: boolean;
  fetchMore: (vars: {
    limit: number;
    offset: number;
  }) => Promise<ApolloQueryResult<GetTeensForAttendanceQuery>> | undefined;
}

const convertFilters = (filters: Filters, event: Event): TeenFilterInput => ({
  sortBy: {
    name: SortType.Name,
    ascending: true
  },
  ...(filters.regionId ? { regionId: filters.regionId } : {}),
  ...(filters.attendedOtherEventsInSeries ? { attendedSeries: true } : {}),
  ...(filters.attendedOtherEventsInSeriesCurrentSeason
    ? { attendedSeriesCurrentSeason: true }
    : {}),
  ...(filters.chapterIds ? { chapterIds: filters.chapterIds } : {}),
  ...(filters.schoolIds ? { schoolIds: filters.schoolIds } : {}),
  eventId: event.eventId,
  seriesId: event.seriesId,
  registeredAttendeesEvent: filters.registeredAttendeesEvent,
  graduationYear: filters.graduationYear ? { eq: String(filters.graduationYear) } : undefined,
  isAlumni: filters.showAlumni,
  query: filters.term,
  ignoreEmail: true,
  ...getAttendedStatusFilter(filters.attendedStatus, event.eventId, event.season, event.seriesId),
  limitToMostRecentDays: 365
});
function getAttendedStatusFilter(
  attendedStatus: Filters['attendedStatus'],
  eventId: number,
  season: string,
  seriesId?: number | null
) {
  switch (attendedStatus) {
    case 'attended':
      return { attendedEvent: true };
    case 'nonAttended':
      return { didNotAttenedEvent: true };
    case null:
    case undefined:
      return {};
  }
}
const getTeensForAttendanceEnhancer = wrapComponent<Props, TeensForAttendance>((props) => {
  const { fetchMore, result, networkStatus } = useGetTeensForAttendanceQuery(
    computed(() => ({ filter: convertFilters(props.filters, props.event), limit: 60 })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    fetchMore: ({ limit, offset }) =>
      fetchMore({
        variables: { limit, offset, filter: convertFilters(props.filters, props.event) },
        updateQuery(previousResult, { fetchMoreResult }) {
          if (!fetchMoreResult?.teens) {
            return previousResult;
          }

          return {
            teens: {
              __typename: 'TeenPage',
              teens: [...previousResult.teens.teens, ...fetchMoreResult.teens.teens]
            }
          };
        }
      }),
    isLoading: networkStatus.value === NetworkStatus.loading,
    loadingMore: networkStatus.value === NetworkStatus.fetchMore,
    teens: result.value?.teens.teens || []
  }));
});

export interface Filters {
  term: string | null;
  seriesId?: number | null;
  eventId?: number | null;
  regionId: number | null;
  schoolIds: number[];
  chapterIds: number[];
  graduationYear: string | null;
  showAlumni: boolean;
  registeredAttendeesEvent: boolean | null;
  attendedStatus?: 'attended' | 'nonAttended' | null;
  sortBy?: {
    name: SortType.Name;
    ascending: boolean;
  };
  attendedOtherEventsInSeries: boolean | null;
  attendedOtherEventsInSeriesCurrentSeason: boolean | null;
}

const defaultFilters: Filters = {
  term: null,
  regionId: null,
  chapterIds: [],
  schoolIds: [],
  graduationYear: null,
  registeredAttendeesEvent: null,
  showAlumni: false,
  sortBy: {
    name: SortType.Name,
    ascending: false
  },
  attendedStatus: null,
  attendedOtherEventsInSeries: null,
  attendedOtherEventsInSeriesCurrentSeason: null
};

const withFilters = (Component: VueConstructor) => {
  const props = normalizeProps(getOptions(Component).props);
  const { filters, setFilters, ...propsToUse } = props;

  return Vue.extend({
    name: `${Component.name}WithFilters`,
    props: {
      ...propsToUse,
      event: {
        type: Object as () => Event
      }
    },
    data() {
      return {
        filters: Object.assign({}, defaultFilters, {
          regionId: this.event.regionId,
          chapterIds: this.event.chapter && this.event.chapter.chapterId ? [this.event.chapter.chapterId] : null,
          schoolIds: this.event.School && this.event.School.schoolID ? [this.event.School.schoolID] : null
        })
      };
    },
    methods: {
      setFilters(filters: Partial<Filters>, overwrite: boolean = false) {
        if (overwrite) {
          if (
            filters.attendedOtherEventsInSeries === false &&
            filters.attendedOtherEventsInSeriesCurrentSeason === false
          ) {
            this.filters = {
              ...defaultFilters,
              ...filters,
              ...{
                regionId: this.event.regionId,
                chapterIds: this.event.chapter && this.event.chapter.chapterId ? [this.event.chapter.chapterId] : [],
                schoolIds: this.event.School && this.event.School.schoolID ? [this.event.School.schoolID] : [],
                attendedOtherEventsInSeriesCurrentSeason: filters.attendedOtherEventsInSeries
                  ? false
                  : filters.attendedOtherEventsInSeriesCurrentSeason,
                attendedOtherEventsInSeries: filters.attendedOtherEventsInSeriesCurrentSeason
                  ? false
                  : filters.attendedOtherEventsInSeries
              }
            };
          } else {
            this.filters = { ...defaultFilters, ...filters };
          }
        } else {
          this.filters = { ...this.filters, ...filters };
        }
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          filters: this.filters,
          router: this.router,
          setFilters: this.setFilters
        }
      });
    }
  });
};

export default compose(
  addAttendeesEnhancer,
  removeAttendeesEnhancer,
  bulkRemoveAttendeesEnhancer,
  bulkAddAttendeesEnhancer,
  compose(
    withFilters,
    getTeensForAttendanceEnhancer,
    withData({
      addingOrRemovingAll: {
        initialValue: false
      },
      showAddTeenForm: {
        initialValue: false
      },
      addOrRemove: {
        initialValue: ''
      }
    })
  ),
  withRouter
)(RecordAttendees);
