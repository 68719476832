<template>
	<div class="form-section">
		<div class="container" v-if="header">
			<header>
				{{header}}
			</header>
		</div>
		<div class="body">
			<slot name="sectionBody"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'formSection',
	props: [
		'header'
	]
}
</script>

<style scoped>

.form-section{
	padding: 10px 20px;
	background: #FFFFFF;
    margin-top: 20px;
	border-radius: 2px;
}
.container {
	position: relative;

}

header {
		cursor: pointer;
		overflow: hidden;
		font-size: 22px;
		
		padding: 20px 30px 20px 0;
		line-height: 0px;
		font-weight: 300;
		margin-top: -1px;
		margin-bottom: 20px;
}
</style>
