import { Vue, Prop, Component } from "vue-property-decorator";
import get from 'lodash/get';
import set from 'lodash/fp/set';
import every from 'lodash/every';
import values from 'lodash/values';
import identity from 'lodash/identity';
import { CreateElement } from "vue";
import SaveEventArgs from "shared/components/AutoSaveField/SaveEventArgs";

function eventOrValue(e: Event | string) {
	if (e instanceof Event) {
		if ((e.target as HTMLSelectElement).options) {
			const selectedOption = (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex];

			// `any` is the only cast that wil work
			return (selectedOption as any)._value !== undefined ? (selectedOption as any)._value : selectedOption.value;
		}
		return (e.target as HTMLInputElement).value;
	}

	return e;
}

type ObjectOrString = object | string;

@Component({
	name: 'AutoSaveField'
})
export default class extends Vue {
	@Prop() name!: string;
	@Prop() entity!: object;
	@Prop() validators!: [{ name: string, validator: (val: ObjectOrString) => boolean | Promise<boolean> }];
	@Prop({ default: identity }) toForm!: (a: ObjectOrString) => ObjectOrString;
	@Prop({ default: identity }) toEntity!: (a: ObjectOrString) => ObjectOrString;

	@Prop({ default: identity }) transform!: (a: ObjectOrString) => ObjectOrString;
	@Prop({ default: get }) extract!: (a: object, b?: string) => ObjectOrString;
	@Prop({ default: set }) insert!: (name: string, value: any, entity: object) => object;

	errors: { [k: string]: boolean } = {};
	async handleInput(event: Event | string) {
		const toEntity = this.toEntity || identity;
		const insert = this.insert || set;
		const transform = this.transform || identity;
		const value = toEntity(eventOrValue(event));

		this.$emit('update:entity', insert(this.name, value, this.entity));

		this.errors = await this.validate(value);

		const args: SaveEventArgs = {
			name: this.name,
			value,
			transformed: transform(value),
			update: (update: object) => this.$emit('update:entity', update),
			addErrors: (errors: { [k: string]: boolean }) => {
				this.errors = { ...this.errors, ...errors };
			}
		};
		if (every(values(this.errors))) {
			this.$emit('save', args);
		}
	}
	async validate(value: ObjectOrString) {
		return (this.validators || []).reduce(
			async (errors, x) => ({ ...await errors, [x.name]: await x.validator(value)}),
			Promise.resolve(this.errors)
		);
	}
	hasError(error: string) {
		return this.errors[error] === false;
  }
  addErrors (errors: { [k: string]: boolean }) {
    this.errors = { ...this.errors, ...errors };
  }

	render(h: CreateElement) {
		// For some reason, default doesn't seem to be working in storybook ¯\_(ツ)_/¯
		const extract = this.extract || get;
		const toForm = this.toForm || identity;

		const $vnodes = this.$scopedSlots.default && this.$scopedSlots.default({
			hasError: this.hasError,
      handleInput: this.handleInput,
      addErrors: this.addErrors,
			$props: {
        value: toForm(extract(this.entity, this.name)),
        name: this.name
			},
			$listeners: {
				blur: this.handleInput
			}
		});

		if ($vnodes) {
			return $vnodes[0] && !$vnodes[1] ? $vnodes[0] : h('div', $vnodes);
		}
		return h('div');
	}
}
