
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { CompactAdvisorRegion } from '../../../shared/CompactAdvisorRegion';
import { RouterLink } from 'vue-component-router';
import UISearch from 'shared/ui/forms/Search.vue';
import Filters from 'shared/components/Filters/Filters.vue';
import { FilterOption, FilterOptionGroup } from 'shared/components/Filters/FilterOption';
import uniqBy from 'lodash/uniqBy';
import Tables from 'shared/ui/tables/Tables.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { ArrayElement } from "shared/util/types";
import { StaffQuery } from "shared/generated/graphql-types";

type EventStaff = ArrayElement<NonNullable<StaffQuery['singleStaff']>['Staff']['EventStaff']>

library.add(faSlidersH)

const devGraphql = require('../../../../../../../config.ts').devGraphql;

@Component({
  name: "Events",
  components: {
	UISearch,
	Filters,
	Tables,
	RouterLink
  }
})

export default class extends Vue {
    @Prop() staff!: CompactAdvisorRegion;

    @Prop() regionId!: number;

	filtersOpen: boolean = false;

	activeFilters: {[key: string]: FilterOption[]} = {}

	term: string = '';

	get eventCategoryOptions (): FilterOption[] {
		return [
			{ key: '0', displayValue: 'Walk In',               predicate: (es: any) => !es.Event.registrationRequired },
			{ key: '1', displayValue: 'Registration Required', predicate: (es: any) => es.Event.registrationRequired  },
			{ key: '3', displayValue: 'Series',                predicate: (es: any) => es.Event.seriesId              }
		]
	}

	get eventTypeOptions (): FilterOption[] {
		return uniqBy(this.staff.eventStaff.map(e => e.Event.EventSubType), 'description')
		.map(est => ({
			key: String(est.eventSubTypeId),
			displayValue: est.description || '',
			predicate: (es: EventStaff) => es.Event.EventSubType.eventSubTypeId === est.eventSubTypeId
		}));
	}

	get seasonsOptions ():  FilterOption[] {
		return uniqBy(this.staff.eventStaff.map(e => e.Event).filter(e => e.season), 'season')
		.map(e => ({
			key: e.season,
			displayValue: e.season,
			predicate: (es: EventStaff) => es.Event.season === e.season
		}));
	}

	get filters (): FilterOptionGroup[] {
		return [
			{ name: 'Event Category', displayName: 'Event Category', options: this.eventCategoryOptions },
			{ name: 'Seasons',        displayName: 'Seasons',        options: this.seasonsOptions       },
			{ name: 'Event Type',     displayName: 'Event Type',     options: this.eventTypeOptions     },
		];
	}

	get search () {
		let result = this.staff.eventStaff.filter(
						e =>
			`${e.Event.eventName}`.toLowerCase().indexOf((this.term || '').toLowerCase()) >= 0
		);
			const filterPredicateGroups = Object.keys(this.activeFilters)
				.map(f => ({ filterType: f, predicates: this.activeFilters[f].map(t => t.predicate) }))
				.filter(fpg => fpg.predicates.length);
			if (filterPredicateGroups.length) {
				result = result.filter(r =>
					filterPredicateGroups.some(fpg =>
						fpg.predicates.some(p => p(r))
					)
				);
			}
		return result
	}

     dateFormat(date: string) {
         if (date) {
            return `${date.split('T')[0].split('-')[1]}/${date.split('T')[0].split('-')[2]}/${date.split('T')[0].split('-')[0]}`
         }
         return ''
     }

	getImageUrl (eventId: number) {
		return `http://${devGraphql}/eventimage/${eventId}`
	}
}

