<template>
  <div class="password" :class="{ 'showPassword' : showPassword }">
    <input v-bind="$attrs" :type="(showPassword) ? 'text' : 'password'" v-on="listeners" :placeholder="placeholder" />
    <span class="icon"><font-awesome-icon icon="eye" @click="togglePassword()" /><span @click="togglePassword()"></span></span>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { filterNonPrintable } from 'shared/util'

library.add(faEye)

export default {
  name: 'UIPassword',
  data () {
    return {
      showPassword: false
    }
  },
  methods: {
    togglePassword: function() {
      this.showPassword === true ? this.showPassword = false : this.showPassword = true;
    }
  },
  created () {
    this.listeners = {
      ...this.$listeners,
      input: (e) => this.$emit('input', filterNonPrintable(e.target.value))
    }
  },
  props: {
    value: {},
    placeholder: {
      type: String,
      default: 'Password'
    }
  }
};
</script>

<style lang="scss" scoped>

input {
  cursor: pointer;
    height: 36px;
    display: block;
    border: 1px solid #f3f3f3;
    padding: 8px 12px;
    font-size: 14px;
    background: #f3f3f3;
    box-shadow: 0 0 0 #EAEAEA;
    width: 100%;
    transition: all .3s ease-in-out;
    border-radius: 4px;

    &:hover {
      border-color: #ddd;
      box-shadow: none;
    }

    &:focus {
      border-color: #ea7d1c;
      box-shadow: none;
    }
}
input::-webkit-input-placeholder { color: #ccc;}
input::-webkit-input-placeholder { color: #ccc;}
input::-moz-placeholder { color: #ccc;}
input:-ms-input-placeholder { color: #ccc;}
.password {
  position: relative;
}
.icon {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  display: block;
}
.showPassword .icon span {
    position: absolute;
    width: 26px;
    height: 3px;
    background: rgba(0,0,0,.4);
    transform: rotate(145deg);
    top: 7px;
    left: -4px;
}
</style>
