import { compose, withData, withProps, withHandlers } from 'vue-compose';
import Vue, { computed } from 'vue';
import Financials from './Financials.vue';
import { withApollo } from 'shared/components/graphql';
import { getCurrentSeason } from 'shared/util';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import {
  EmailParentsMutation,
  GetOwedRegistrationsQuery,
  OwedRegistrationFilter,
  useEmailParentsMutation,
  useGetOwedRegistrationsQuery
} from 'shared/generated/graphql-types';
import { wrapComponent } from 'shared/apollo-hoc';
import { ArrayElement } from 'shared/util/types';
import { MutateResult } from '@vue/apollo-composable';

type OwedRegistration = ArrayElement<
  GetOwedRegistrationsQuery['owedRegistrations']['registrations']
>;

export const filtersInit = (): OwedRegistrationFilter => ({
  includeAlumni: false,
  waitingList: false,
  minRange: 1,
  maxRange: 200,
  event: null,
  chapters: [],
  searchByName: null,
  season: getCurrentSeason(),
  sortColumn: { column: 'date', ascending: false }
});
interface Props {
  regionId?: number | null;
  currentPage: number;
  limit: number;
  filters: OwedRegistrationFilter;
}

interface OwedRegistrations {
  owedRegistrations?: OwedRegistration[];
  isLoading: boolean;
  total?: number;
  min?: number;
  max?: number;
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const getOwedRegistrationsEnhancer = wrapComponent<Props, OwedRegistrations>((props) => {
  const { loading, result } = useGetOwedRegistrationsQuery(
    computed(() => ({
      limit: props.limit,
      offset: (props.currentPage - 1) * props.limit,
      filter: { ...props.filters, regionID: props.regionId }
    })),
    { fetchPolicy: 'network-only' }
  );

  return computed(() => ({
    owedRegistrations: result.value?.owedRegistrations.registrations || [],
    total: result.value?.owedRegistrations.total,
    max: result.value?.owedRegistrations.max,
    min: result.value?.owedRegistrations.min,
    isLoading: loading.value
  }));
});

type EmailParentProps = {
  emailParents: (registrationIds: number[]) => MutateResult<EmailParentsMutation>;
  sendingEmail: boolean;
};

const emailParentsEnhancer = wrapComponent<Props, EmailParentProps>((props) => {
  const { loading, mutate } = useEmailParentsMutation();

  return computed(() => ({
    emailParents: (registrationIds) => mutate({ registrationIds }),
    sendingEmail: loading.value
  }));
});

export const enhancer = compose(
  withData({
    filters: {
      initialValue: {
        includeAlumni: false,
        waitingList: false,
        minRange: 1,
        maxRange: 200,
        event: null,
        chapters: [],
        searchByName: null,
        season: getCurrentSeason(),
        sortColumn: { column: 'date', ascending: false }
      } as OwedRegistrationFilter
    },
    selectedTeen: {
      initialValue: null
    },
    selectedRegistrations: {
      initialValue: []
    },
    currentPage: {
      initialValue: 1
    }
  }),
  withHandlers({
    filters(arg: any) {
      this.$emit('currentPage', 1);
      this.$emit('filters', arg);
    }
  }),
  withProps<any, any>((props: any) => ({
    regionId: getCurrentRegion(),
    selectRegistration(this: Vue, id: number) {
      this.$emit('selectedRegistrations', [...props.selectedRegistrations, id]);
    },
    unselectRegistration(this: Vue, id: number) {
      const index = props.selectedRegistrations.findIndex((r: number) => r === id);
      const newList = [...props.selectedRegistrations];
      newList.splice(index, 1);
      this.$emit('selectedRegistrations', newList);
    },
    selectTeen(this: Vue, teen: OwedRegistration) {
      this.$emit('selectedTeen', teen);
    },
    clearFilters(this: Vue) {
      this.$emit('filters', filtersInit());
      this.$emit('currentPage', 1);
    },
    clearSelectedRegistrations(this: Vue) {
      this.$emit('selectedRegistrations', []);
    },
    limit: 60
  })),
  withApollo,
  getOwedRegistrationsEnhancer,
  emailParentsEnhancer
);

export default enhancer(Financials);
