
import Vue from 'vue';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { library } from '@fortawesome/fontawesome-svg-core';
import Modal from 'shared/ui/modal/Modal.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import UIButton from 'shared/ui/buttons/Button.vue';
import InteractionForm from 'shared/components/InteractionForm';
import GoalLookup from 'shared/components/GoalLookup';
import ListsLookup from 'shared/components/ListsLookup';
import { Props, Data, Methods } from './types';

library.add(faPlus)

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'AddInteraction',
  components: {
    Modal,
    FancySelect,
    UIButton,
    InteractionForm,
    GoalLookup,
    ListsLookup
  },
  props: {
    personId: {},
    regionId: {},
    leadId: {},
    markAsLead: {},
    addToTeenList: {},
    eventId: {},
    eventDate: {},
    eventTimezone: {}
  },
  data() {
    return {
      open: false,
      addingNewInteraction: false,
      showMarkAsLeadModal: false,
      showAddToListModal: false,
      addingToTeenList: false,
      markingAsLead: false,
      showErrorModal: false,
      errorMessage: null
    }
  },
  methods: {
    async markAsLeadHandler(goal) {
      try {
        this.markingAsLead = true;
        await this.markAsLead(this.personId, goal.id);
      } catch (e) {
        this.showErrorModal = true;
        this.errorMessage = 'Marking teen as lead failed';
      }
      this.markingAsLead = false;
      this.showMarkAsLeadModal = false;
    },
    async addToListHandler(list) {
      try {
        this.addingToTeenList = true;
        await this.addToTeenList(list.id, this.personId);
      } catch (e) {
        this.showErrorModal = true;
        this.errorMessage = 'Adding teen to list failed';
      }
      this.addingToTeenList = false;
      this.showAddToListModal = false;
    }
  }
});
