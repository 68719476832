
import Vue from 'vue';
import { Props, Computed, Data, Methods } from './types';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import List from './components/List.vue';
import { Route, MatchFirst } from 'vue-component-router';
import Single from './components/Single';
import Loading from 'shared/components/Loading.vue';
import fullheight from 'shared/directives/fullheight';
import ParentProfile from 'shared/components/ParentProfile';
import TeenProfile from 'shared/components/TeenProfile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';

library.add(faOutdent, faIndent);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CleanupListLayout',
  components: {
    PanelsGrid,
    PanelsSidebar,
    ScrollablePanel,
    List,
    Route,
    MatchFirst,
    Single,
    Loading,
    ParentProfile,
    TeenProfile
  },
  directives: {
    fullheight
  },
  props: {
    path: {},
    loading: {},
    initialLoading: {},
    phones: {},
    fetchMore: {},
    currentPhoneNumber: {},
    total: {}
  },
  data() {
    return {
      profileClose: true,
      currentParentId: null,
      currentTeenId: null
    };
  },
  computed: {
    displaySpinner() {
      return this.loading;
    },
  },
  methods: {
    closeProfile() {
      this.profileClose = !this.profileClose;
    },
    setParentId(parentId) {
      this.currentTeenId = null;
      this.currentParentId = parentId;
      this.profileClose = false;
    },
    setTeenId(teenId) {
      this.currentParentId = null;
      this.currentTeenId = teenId;
      this.profileClose = false;
    },
  },
  watch: {
    currentPhoneNumber() {
      this.profileClose = true;
      this.currentParentId = null;
      this.currentTeenId = null;
    }
  }
});
