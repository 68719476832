
import { Vue, Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import flatten from 'lodash/flatten';
import UISelect from 'shared/ui/forms/FancySelect';

@Component({
  components: {
    UISelect
  }
})
export default class ShabbatHolidayLookup extends Vue {
  @Prop() shabbatType!: string;
  @Prop() value!: any;

  options = [];
  season = this.getSeason();
  terms = [
    new RegExp('.*? Day of '),
    new RegExp('Chol Hamoed '),
    new RegExp("Shvi'i Shel "),
    new RegExp('Acharon Shel '),
    new RegExp('Fast of ')
  ];
  nonEventHolidays = [
    'Rosh Chodesh',
    'Tzom Gedaliah',
    'Asarah B\'Tevet',
    'Fast of Esther',
    'Shushan Purim',
    'Yom Hashoah',
    'Yom Yerushalayim',
    'Yom Hazikaron',
    'Yom HaAtzmaut',
    'Fast of Shiva Asar B\'Tamuz',
    "Tu B'Av",
    'YOM_HASHOAH',
    'YOM_HAZIKARON',
    'YOM_HAATZMAUT',
    'YOM_YERUSHALAYIM',
    'LAG_BAOMER',
    'TU_BAV',
    'TZOM_GEDALIAH',
    'ASARAH_BTEVET',
    'SHIVA_ASARA_BETAMUZ',
    'TAANIS_ESTHER',
    'SHUSHAN_PURIM'
  ];

  getSeason (){
    const date = new Date();
    return date.getMonth() < 7 ? date.getFullYear() : date.getFullYear() + 1;
  }

  normalize(occasion: string) {
    switch (occasion) {
      case 'ROSH_HASHANA_I':
      case 'ROSH_HASHANA_II':
        return 'Rosh Hashana';
      case 'YOM_KIPPUR':
        return 'Yom Kippur';
      case 'SUCCOS_I':
      case 'SUCCOS_II':
      case 'SUCCOS_III':
      case 'SUCCOS_IV':
      case 'SUCCOS_V':
      case 'SUCCOS_VI':
        return 'Sukkot';
      case 'HOSHANA_RABBA':
        return 'Hashana Rabba';
      case 'SHMINI_ATZERES_I':
        return 'Shmini Atzeres';
      case 'SHMINI_ATZERES_II':
        return 'Simchas Torah';
      case 'CHANUKA_I':
      case 'CHANUKA_II':
      case 'CHANUKA_III':
      case 'CHANUKA_IV':
      case 'CHANUKA_V':
      case 'CHANUKA_VI':
      case 'CHANUKA_VII':
      case 'CHANUKA_VIII':
        return 'Chanuka';
      case 'PESACH_I':
      case 'PESACH_II':
      case 'PESACH_III':
      case 'PESACH_IV':
      case 'PESACH_V':
      case 'PESACH_VI':
      case 'PESACH_VII':
      case 'PESACH_VIII':
        return 'Pesach';
      case 'SHAVUOS_I':
      case 'SHAVUOS_II':
        return 'Shavuot';
      case 'TISHA_BAV':
        return "Tisha B'Av";
      case 'PURIM':
        return 'Purim';
      case 'TU_BESHVAT':
        return "Tu B'Shvat"
      default:
        return occasion;
    }
  }

  optionsFromZmanimApi: any[] = [];

  async created() {
    this.optionsFromZmanimApi = await this.getOptionsFromZmanimApi();
  }

  async getOptionsFromZmanimApi() {
    const data = await axios.get(`https://db.ou.org/zmanim/getCalendarData.php?callback=&mode=drange&dateBegin=8/01/${this.season - 1}&dateEnd=7/30/${this.season}&lat=0&lng=0&timezone=America/New_York`);
    if(data && data.data){
      let holidays = flatten(data.data.days
          .filter((d: any) => d.parsha || d.holidays)
          .map((d: any) => {
            if(d.parsha && (!d.holidays || d.holidays[0].holiday.holidayName === 'Rosh Chodesh')){
              return {
                id: d.parsha,
                day: d,
                normalizedId: this.normalize(d.parsha),
                name: `${this.normalize(d.parsha)} (${d.engDateString})`,
                shabbat: true
              };
            }
            if(d.holidays) {
              return d.holidays.map((h: any) => {
                if (this.nonEventHolidays.indexOf(h.holiday.holidayName) !== -1){
                  return;
                }
                return {
                  id: h.holiday.holidayName,
                  normalizedId: this.normalize(h.holiday.holidayName),
                  name: `${this.normalize(h.holiday.holidayName)} (${d.engDateString})`,
                  day: d,
                  shabbat: false
                }
              })
              .filter((h: any) => h);
            }
          })
      )
      .map((d: any) => ({...d, eventSubTypeId: this.eventTypeFromOccasion(d.normalizedId)}));
      return holidays;
    }

    return [];
  }

  eventTypeFromOccasion (occasion: string) {
    this.terms.reduce((occasion, regex) => occasion.replace(regex, ''), occasion);

    occasion = this.normalize(occasion);
    switch (occasion) {
      case 'Shabbat':
        switch (this.shabbatType){
          case 'Shabbat Oneg':
            return 295;
          case 'Shabbat Meal':
            return 304;
          case 'Shabbat Minyan':
            return 305;
        }
      case 'Sukkot':
      case 'Hashana Rabba':
        return 325;
      case 'Shmini Atzeres':
      case 'Simchas Torah':
        return 326;
      case 'Rosh Hashana':
        return 328;
      case 'Yom Kippur':
        return 329;
      case 'Chanuka':
        return 323;
      case 'Purim':
        return 331;
      case 'Pesach':
        return 324;
      case 'Shavuot':
        return 334;
      case "Tu B'Shvat":
        return 327;
      case "Tisha B'Av":
        return 339;
    }
  }
}
