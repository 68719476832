import trim from 'lodash/trim';
import { Gender } from 'shared/generated/graphql-types';
import { getCurrentEmail, getCurrentCellNumber, getCurrentLandlineNumber, formatAddressToString, getCurrentAddress, notEmpty, } from 'shared/util';
import { CompactParent, Parent } from './types';


function getGender (gender: number): Gender {
  if (gender === 1) {
    return Gender.Female;
  }
  return Gender.Male;
}

export function toCompactParent (parent: Parent | null): CompactParent | null {
  if (!parent) return null;
  const landLine = getCurrentLandlineNumber(parent.Phones || []);
  const cell = getCurrentCellNumber(parent.Phones || []);
  const currentAddress = getCurrentAddress(parent.Addresses || []);
  return {
    personId: parent.personID,
    gender: getGender(parent.gender),
    firstName: parent.firstName || '',
    lastName: parent.lastName || '',
    fullName: trim(`${parent.firstName || ''} ${parent.lastName || ''}`),
    landline: landLine ? landLine.phoneNumber : null,
    cellphone: cell ? cell.phoneNumber : null,
    email: (getCurrentEmail(parent.EmailAddresses) || { email: '' }).email,
    address: currentAddress,
    formattedAddress: formatAddressToString(currentAddress),
    source: parent
  };
}

export function toCompactParents (parents: Parent[]): CompactParent[] {
  if (parents && parents.length) {
    return parents.map(toCompactParent).filter(notEmpty);
  }
  return [];
}
