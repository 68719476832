<template>
	<div class="clipboard-container">
		<span @click="handleCopy(copyData)">{{label}}</span>
		<div class="clipboard-container--msg success" v-if="success">
      <font-awesome-icon icon="check" fixed-width class="icon"/> Copied to clipboard
    </div>
		<div class="clipboard-container--msg" v-if="!success" >Click to copy</div>
	</div>
</template>
<script>
import copy from 'copy-text-to-clipboard';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCheck)

export default {
  name: 'ClipboardCopy',
  components: {
	  UIButton,
	  UIInput
  },
  data () {
	  return {
		  success: false,
	  }
  },
  methods: {
	  handleCopy(data) {
      const copyResult = copy(data)
      if (copyResult) {
        this.success = true
        setTimeout(() => {
          this.success = false
        }, 1000);
      } else {
        console.error('Copy failed')
      }
	  }
  },
  props: ['label','copyData']
};
</script>

<style lang="scss" scoped>
  .clipboard-container {
    display: inline;
    position: relative;

  span {
      cursor: pointer;
    }
  }
  .icon {
    color: #ccc;
    font-size: 10px;
  }
  .clipboard-container:hover .clipboard-container--msg{
    display: block;
  }
  .clipboard-container--msg {
    display: none;
    background: #fff;
    color: #222;
    padding: 4px 10px;
    font-size: 10px;
    border-radius: 2px;
    position: absolute;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    left: calc(100% - 50px);
    top: 20px;
    transition: all .3s ease-in-out;
    white-space: nowrap;

    &.success {
      background: #efffeb;
    }

    &:before {
      content: "";
      border: 4px solid transparent;
      border-bottom-color: #ddd;
      position: absolute;
      left: 50%;
      top: -8px;
    }
    &:after {
      content: "";
      border: 4px solid transparent;
      border-bottom-color: #fff;
      position: absolute;
      left: 50%;
      top: -7px;
    }
  }


</style>
