import Vue, { CreateElement } from "vue";
import Loading from 'shared/components/Loading.vue';

interface Props {
  showEmptyTag?: boolean;
  tag?: string;
  isAllowed?: boolean;
  loading?: boolean;
}
export default Vue.extend<{}, {}, {}, Props>({
  name: 'AllowComponent',
  props: {
    showEmptyTag: {
      required: false
    },
    tag: {
      required: false
    },
    isAllowed: {},
    loading: {},
  },
  render(h: CreateElement) {
    if (this.loading) {
      return h(Loading);
    } else if (this.isAllowed) {
      return h('div', this.$scopedSlots.default && this.$scopedSlots.default({}));
    } else if (this.$scopedSlots.restricted) {
      return h('div', this.$scopedSlots.restricted({}));
    } else if (this.showEmptyTag) {
      return h('div', this.tag || '');
    }

    return h('div', 'Forbidden');
  }
});
