
import Vue from 'vue';
import DateTimezone from 'date-timezone';
import { SimpleDate } from 'shared/util/types';

interface Data {
  innerValue: {
    start: SimpleDate | DateTimezone.DateTimezone | null;
    end: SimpleDate | DateTimezone.DateTimezone | null;
  } | null;
}

interface Props {
  range: {
    start: SimpleDate | DateTimezone.DateTimezone | null;
    end: SimpleDate | DateTimezone.DateTimezone | null;
  } | null;
  clearable: boolean;
}

interface Methods {
  calculateInitialSelectedValue: () => {
    start: SimpleDate | DateTimezone.DateTimezone | null;
    end: SimpleDate | DateTimezone.DateTimezone | null;
  } | null;
  convertToSimpleDateRange: (event: { start: Date; end: Date }) => void;
  simpleDateToDateTimezone: (
    date: SimpleDate | DateTimezone.DateTimezone | null
  ) => SimpleDate | DateTimezone.DateTimezone | null;
  clearRange: () => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'DateRangePicker',
  props: {
    range: {
      default: null
    },
    clearable: { type: Boolean, default: false }
  },
  data() {
    return {
      innerValue: this.calculateInitialSelectedValue() || null
    }
  },
  methods: {
    calculateInitialSelectedValue() {
      if (this.range) {
        return {
          start: this.simpleDateToDateTimezone(this.range.start),
          end: this.simpleDateToDateTimezone(this.range.end)
        };
      } else {
        return {
          start: null,
          end: null
        };
      }
    },
    convertToSimpleDateRange(event) {
      if (event) {
        const converted = {
          start: event.start
            ? {
                year: event.start.getFullYear(),
                month: event.start.getMonth() + 1,
                day: event.start.getDate()
              }
            : null,
          end: event.end
            ? {
                year: event.end.getFullYear(),
                month: event.end.getMonth() + 1,
                day: event.end.getDate()
              }
            : null
        };

        this.$emit('input', converted);
      }
    },
    simpleDateToDateTimezone(date: SimpleDate | DateTimezone.DateTimezone | null) {
      if (!date) {
        return null;
      }

      if ('toISOString' in date) {
        return date;
      }
      return new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0);
    },
    clearRange() {
      this.$emit('input', null);
    }
  }
});
