
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import UISearch from 'shared/ui/forms/Search.vue';
import Filters from 'shared/components/Filters/Filters.vue';
import TeenDuplicates from './TeenDuplicates';
import ChapterLookup from 'shared/components/ChapterLookup';
import { FilterOption } from 'shared/components/Filters/FilterOption';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { getCurrentEmail, getCurrentLandlineNumber, getCurrentCellNumber, getCurrentAddress, formatAddressToString } from 'shared/util';
import TeenViewModel from 'shared/components/SelectDuplicatesFields/shared/TeenViewModel';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Loading.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import Pagination from 'shared/components/Pagination';
import * as CheckboxArray from 'shared/checkbox-array';
import TeenProfile from 'shared/components/TeenProfile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { TeenDuplicates as TeenDuplicatesType } from "./types";
import { Gender, Origin } from "shared/generated/graphql-types";
import { Chapter } from "shared/types";

library.add(faIndent, faOutdent, faFilter, faPlusCircle);

@Component({
  name: "Duplicates",
  components: {
    TeenDuplicates,
    FilterGroup,
    UICheckbox,
    BorderedList,
    TeenProfile,
    UISearch,
    Filters,
    PanelsSidebar,
    UIInput,
    ScrollablePanel,
    PanelsGrid,
    Loading,
    Pagination,
    ChapterLookup,
    ...CheckboxArray
  },
  directives: {
    fullheight
  }
})

export default class extends Vue {
  @Prop() teenDuplicates!: TeenDuplicatesType;
  @Prop() total!: number;
  @Prop() teensPerPage!: number;
  @Prop() currentPage!: number;
  @Prop() regionId!: number;
  @Prop() loading!: boolean;
  @Prop() chapters!: Chapter[]
  @Prop() origins!: Origin[]
  @Prop() setChapters!: (args: Chapter[])=> void
  @Prop() setOrigins!: (args: Origin[])=> void
  @Prop() searchTerm!: string;

  term: string = '';
  selectedTeen: number | null = null;
  showTeenSideBar: boolean = true;

  get teenDuplicateGroups() {
    return groupBy(this.teenViewModel, 'name');
  }

  get groupsTotal() {
    return this.total
  }

  get groupsTotalPages() {
    return Math.ceil(this.groupsTotal / this.teensPerPage);
  }

  // @ts-ignore
  get teenViewModel(): TeenViewModel[] {
    // @ts-ignore
    return this.teenDuplicates.map(teen => {
      return {
        name:                 `${teen.firstName} ${teen.lastName}`,
        personId:             teen.personId,
        regionId:             teen.regionId,
        firstName:            teen.firstName,
        lastName:             teen.lastName,
        dateCreated:          teen.Teen.dateCreated,
        dateUpdated:          teen.Teen.dateUpdated,
        origin:               teen.Teen.Person.origin || Origin.Unknown,
        thumbnail:            teen.Teen.thumbnail,
        address:              { fullAddress: formatAddressToString(getCurrentAddress(teen.Teen.Person.Addresses || [])), id: getCurrentAddress(teen.Teen.Person.Addresses).id },
        school:               teen.Teen.School ? { schoolName: teen.Teen.School.name, id: teen.Teen.School.schoolID } : null,
        landline:             getCurrentLandlineNumber(teen.Teen.Person.Phones || []),
        cellphone:            getCurrentCellNumber(teen.Teen.Person.Phones || []),
        email:                getCurrentEmail(teen.Teen.Person.EmailAddresses || []),
        gender: teen.Teen.Person.gender === 0 ? Gender.Male : Gender.Female,
        birthDate: teen.Teen.birthDate,
        graduationYear: teen.Teen.graduationYear,
        vegetarian: teen.Teen.vegetarian,
        isJewish: teen.Teen.Person.isJewish,
        gapYear: teen.Teen.GapYearProgram ? { schoolName: teen.Teen.GapYearProgram.name, id: teen.Teen.GapYearProgram.schoolID } : null,
        shanaBet: teen.Teen.ShanaBet ? { schoolName: teen.Teen.ShanaBet.name, id: teen.Teen.ShanaBet.schoolID } : null,
        college: teen.Teen.College ? { schoolName: teen.Teen.College.name, id: teen.Teen.College.schoolID } : null,
        chapter: teen.Teen.Chapter,
        hasProfilePicture: !!teen.Teen.hasProfilePicture,
        medicines: teen.Teen.medicines,
        families: teen.Teen.Person.ChildOf,
        Registrations: teen.Teen.Registrations
      }
    })
  }

  get queryVariables() {
    const chapters = map(this.chapters, (c) => c.chapterId);

    return {
        chapters,
        origins: this.origins,
        regionId: this.regionId,
        name: this.searchTerm,
        limit: this.teensPerPage,
        offset: (this.currentPage - 1) * this.teensPerPage,
      }
  }

  chapterFilterHanlder (chapters: Chapter[]) {
    this.setChapters(chapters)
  }

  originFilterHanlder (origins: Origin[]) {
    this.setOrigins(origins)
  }
  onPageChange(page: number) {
    this.$emit('currentPage', page);
  }
  handleFilters() {
     this.$emit('currentPage', 1);
  }

  handleSearchTermChange() {
    this.$emit('searchTerm', this.term);
    this.$emit('currentPage', 1);
  }

  handleTeenSelect(teenId: number) {
    this.selectedTeen = teenId
  }
}
