
import Popover from 'vue-popover';
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { dayOfWeekAndDate } from 'shared/util';
import { getEventPopoverPosition, normalizedEventTime } from '../../../../../shared/util';
import { Props, Computed, Methods } from './types';
import { AttendanceStatus } from 'shared/generated/graphql-types';

library.add(faListOl, faTimes, faEdit);

const Dimensions = Vue.extend<{ dimensions: DOMRect | ClientRect | null }, {}, {}, {}>({
  mounted () {
    this.dimensions = this.$el.getBoundingClientRect();
  },
  data () {
    return {
      dimensions: null
    }
  },
  render (h) {
    const $vnodes = this.$scopedSlots.default && this.$scopedSlots.default({
      dimensions: this.dimensions
    });
    if ($vnodes) {
      return $vnodes[0];
    }
    return h('div');
  }
});

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'EventItem',
  components: {
    RouterLink,
    Popover,
    Dimensions
  },
  props: {
    event: {},
    dates: {},
    filters: {},
    cancelEvent: {}
  },
  computed: {
    attendancesCount() {
      return (this.event.Attendances || []).filter(a => a.status === AttendanceStatus.Attended).length || 0
    }
  },
  methods: {
    dayOfWeekAndDate,
    normalizedEventTime,
    getClass (dimensions: DOMRect) {
      if (!dimensions) return 'under';

      const { right, bottom } = dimensions;

      const position = [];

      if ((dimensions.x + dimensions.width ) > window.innerWidth) {
        position.push('left');
      }
      if ((bottom + dimensions.height) > window.innerHeight) {
        position.push('over');
      }

      return position.length > 0 ? position.join(' ') : 'under';
    },
    getPosition (dimensions: DOMRect) {
      if (!dimensions) return {};

      const eventPos = this.$el.getBoundingClientRect();

      const klass = this.getClass(dimensions);

      return getEventPopoverPosition(eventPos, dimensions, klass)
    }
  }
})
