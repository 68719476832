
import Vue from 'vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import Avatar from 'shared/components/avatar';
import VueMultiselect from 'vue-multiselect';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import { CompactTeen } from './shared/CompactTeen';
import TeenInfo from './components/TeenInfo.vue';
import trim from 'lodash/trim';

interface Data {
  localValue: CompactTeen | CompactTeen[] | null;
  searchHandler: (event: string) => void;
}

interface Props {
  showTeenInfo: boolean;
  teens: CompactTeen[];
  searching: boolean;
  isAlumni: boolean;
  term: string;
  value: CompactTeen | CompactTeen[];
  deselect: boolean;
  clearable: boolean;
  multipleWithInfo: boolean;
  placeholder: string;
  disabled: boolean;
  clearOnSelect: boolean;
  filterIds: number[];
  regionId: number;
}

interface Methods {
  startCase: (string: string) => string;
  formatTeen: (teen: CompactTeen) => string;
  removeHandler: (removeTeen: CompactTeen) => void;
  handleInput: (event: CompactTeen[]) => void;
}

interface Computed {
  noOptions: boolean;
  filteredTeens: CompactTeen[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TeenLookup',
  components: {
    FancySelect,
    Avatar,
    VueMultiselect,
    TeenInfo
  },
  props: {
    showTeenInfo: {
      default: false,
      type: Boolean
    },
    teens: {
      type: Array
    },
    searching: { type: Boolean },
    isAlumni: { type: Boolean },
    term: { type: String },
    value: {},
    deselect: { default: false, type: Boolean },
    clearable: { default: true, type: Boolean },
    multipleWithInfo: { default: false, type: Boolean },
    placeholder: { default: 'Type to search teens', type: String },
    disabled: { default: false, type: Boolean },
    clearOnSelect: { default: false },
    filterIds: {},
    regionId: { type: Number }
  },
  data() {
    return {
      localValue: Array.isArray(this.value) ? this.value || [] : this.value || null,
      searchHandler: debounce((search) => {
        this.$emit('term', search);
      }, 500)
    }
  },
  computed: {
    noOptions () {
      return !!(this.teens.length === 0 && this.term);
    },
    filteredTeens() {
      return this.teens.filter(t => {
        return !this.filterIds || !this.filterIds.includes(t.personID);
      });
    }
  },
  methods: {
    startCase,
    formatTeen (teen: CompactTeen) {
      if (teen.Person) {
        return `${teen.Person.firstName} ${teen.Person.lastName}`;
      } else if(teen.fullName) {
        return teen.fullName;
      } else {
        return trim(`${teen.firstName || ''} ${teen.lastName || ''}`)
      }
    },
    removeHandler (removeTeen: CompactTeen) {
      this.localValue = (this.localValue as CompactTeen[] || []).filter((t: CompactTeen) => t.personId !== removeTeen.personID)
      this.$emit('input', this.localValue);
      this.$emit('blur', this.localValue);
      this.$emit('change', this.localValue);
    },
    handleInput (event) {
      this.$emit('input', event);
      this.$emit('blur', event);
      this.$emit('change', event);
    }
  },
  watch: {
    value(value: CompactTeen[]) {
      if (value) {
        this.localValue = value
      }
    },
  }
})

