
import Vue from 'vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { CompactDashboardData, Report } from './types';
import Loading from 'shared/components/Loading.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Sidebar from './components/SideBar';
import PaymentData from './components/PaymentData.vue';
import TicketsData from './components/TicketsData.vue';
import DemographicData from './components/DemographicData.vue';
import ReportsData from './components/ReportsData.vue';
import {
  jsu as jsuThumbnail,
  other as otherThumbnail,
  bgCubed as bgCubedThumbnail,
  tya as tyaThumbnail,
  pivot as pivotThumbnail
} from '../../../shared/DefaultEventImages';
import { GetEventQuery } from 'shared/generated/graphql-types';
import { Me } from 'shared/types';

type Event = GetEventQuery['event']

interface Props {
  eventId: number;
  dashboardData: CompactDashboardData | null;
  registrationRequired: boolean;
  reports: Report[];
  recent: string[];
  starred: string[];
  user: Me;
  regionId: number;
  userRegionId: number;
  fetchingDashboardData: boolean;
  fetchingReports: boolean;
  fetchingMetaData: boolean;
  toggleStar: (reportId: string) => void;
  router: { history: { push: (location: string) => void } };
  fullEventData: Event;
}

interface Computed {
  loading: boolean;
  advisorRegionId?: number;
  thumbnail: string;
}

export default Vue.extend<{}, {}, Computed, Props>({
  name: 'Dashboard',
  components: {
    Loading,
    PanelsGrid,
    Sidebar,
    PaymentData,
    TicketsData,
    DemographicData,
    ReportsData,
    ScrollablePanel
  },
  directives: {
    fullheight
  },
  props: {
    eventId: {},
    dashboardData: {},
    registrationRequired: {},
    reports: {},
    recent: {},
    starred: {},
    user: {},
    regionId: {},
    userRegionId: {},
    fetchingDashboardData: {},
    fetchingReports: {},
    fetchingMetaData: {},
    toggleStar: {},
    router: {},
    fullEventData: {}
  },
  computed: {
    loading() {
      return this.fetchingDashboardData;
    },
    advisorRegionId() {
      const advisorRegion = this.user.AdvisorRegions.find(ar => ar.Region.regionId === this.userRegionId)
      return advisorRegion && advisorRegion.AdvisorRegionId
    },
    thumbnail() {
      if (this.fullEventData?.EventTrack) {
        if (this.fullEventData?.EventTrack.id === 24) {
          return jsuThumbnail;
        } else if (this.fullEventData?.EventTrack.id === 31) {
          return pivotThumbnail;
        }
      } else if (this.fullEventData?.baseUrl === 2) {
        return tyaThumbnail;
      } else if (this.fullEventData?.baseUrl === 4) {
        return bgCubedThumbnail;
      }
      return otherThumbnail;
    }
  }
});
