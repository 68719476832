
import Vue from "vue";
import UISwitch from 'shared/ui/forms/Switch.vue';
import UISelect from 'shared/ui/forms/FancySelect';

interface Data {
    showErrors: boolean
}

interface Props {
    value: {
        isMultiSelect: boolean,
        options: string[]
    }
}

interface Methods {
    update: (propName: keyof Props["value"], propValue: any) => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
    name: "SelectConfiguration",
    components: {
        UISwitch,
        UISelect
    },
    data() {
        return {
            showErrors: false
        }
    },
    props: {
        value: {},
    },
    methods: {
        update(propName, propValue) {
            this.showErrors = true;
            let obj: Props['value'] = {
                ...this.value
            }
            obj[propName] = propValue;
            this.$emit('input', obj);
        }
    },
});
