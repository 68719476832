import { render, staticRenderFns } from "./clipboard.vue?vue&type=template&id=12f65b11&scoped=true&"
import script from "./clipboard.vue?vue&type=script&lang=js&"
export * from "./clipboard.vue?vue&type=script&lang=js&"
import style0 from "./clipboard.vue?vue&type=style&index=0&id=12f65b11&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f65b11",
  null
  
)

export default component.exports