
import Vue from 'vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import * as RadioGroup from 'shared/radio-group';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAlphaDown } from '@fortawesome/pro-solid-svg-icons/faSortAlphaDown';
import { faSortAlphaUp } from '@fortawesome/pro-solid-svg-icons/faSortAlphaUp';
import { ActiveFilters } from '../shared/types';
import fullheight from 'shared/directives/fullheight';
import { MaritalStatus, SortBy, SortType } from 'shared/generated/graphql-types';

library.add(faSortAlphaDown,faSortAlphaUp);

interface Props {
  activeFilters: ActiveFilters;
}
interface Data {
  SortType: typeof SortType;
  MaritalStatus: typeof MaritalStatus;
  sortByName: SortType;
  ascending: boolean;
  selectedMaritalStatus: MaritalStatus | null;
}
interface Computed {
  localActiveFilters: { sortBy: SortBy };
}

interface Methods {
  emitFiltersUpdate: () => void;
}
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'FiltersPanel',
  components: {
    ScrollablePanel,
    ...RadioGroup,
    UIRadio
  },
  directives: {
    fullheight
  },
  props: {
    activeFilters: {}
  },
  data () {
    return {
      SortType,
      MaritalStatus,
      sortByName: this.activeFilters.sortBy.name!,
      ascending: !!this.activeFilters.sortBy.ascending,
      selectedMaritalStatus: null
    };
  },
  computed: {
    localActiveFilters () {
      return {
        sortBy: { name: this.sortByName, ascending: this.ascending },
        maritalStatus: this.selectedMaritalStatus
      };
    }
  },
  methods: {
    emitFiltersUpdate() {
      this.$emit('filters', this.localActiveFilters);
    }
  },
  watch: {
    sortByName: {
      handler() {
        this.emitFiltersUpdate();
      }
    },
    ascending: {
      handler() {
        this.emitFiltersUpdate();
      }
    },
    selectedMaritalStatus:{
      handler() {
        this.emitFiltersUpdate();
      }
    }
  }
});
