
import { Vue, Prop, Component } from 'vue-property-decorator';
import UIInput from 'shared/ui/forms/Input.vue';
import { directive as onClickaway } from 'vue-clickaway2';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { GetHousesForEventQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type House = ArrayElement<GetHousesForEventQuery['houses']>

library.add(faCheck, faTimes)

@Component({
	name: 'HousingRequest',
	components: {
		UIInput
	},
	directives: {
		onClickaway
	}
})

export default class extends Vue {
	@Prop() request!: string;
	@Prop() house!: House;
	@Prop() addToHouse!: () => void;
	@Prop() hasParticipant!: boolean;
	clicking: boolean = false;
	editing: boolean = false;
	newRequest: string = ''

	clickHandler() {
		this.clicking = !this.clicking
	}

	checkHandler() {
		this.editing = false;
		this.clicking = !this.clicking;
		this.$emit('newRequest', this.newRequest);
	}

	cancelHandler() {
		this.editing = false;
		this.clicking = !this.clicking;
	}
};

