
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import UIInput from 'shared/ui/forms/Input.vue';
import UIButton from "shared/ui/buttons/Button.vue";
import PhoneInput from 'shared/ui/forms/Phone.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import { directive as onClickaway } from 'vue-clickaway2';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard';
import Loading from 'shared/components/Loading.vue';
import every from 'lodash/every';
import values from 'lodash/values';
import { validateName, validateNumber, phoneFormat, toEntity } from 'shared/util';
import AutoSaveField from 'shared/components/AutoSaveField';
import { EmergencyContact } from '../../types';
import { AddEmergencyContactMutationVariables, DeleteEmergencyContactMutationVariables, EditEmergencyContactMutationVariables } from 'shared/generated/graphql-types';

library.add(faPencil, faTimes, faTrash, faClipboard)

@Component({
	components: {
		UIInput,
    PhoneInput,
    AutoSaveField,
    Loading,
    Modal,
    UIButton
	},
	directives: {
		onClickaway
	},
  methods: {
    validateNumber,
    validateName,
    phoneFormat,
    toEntity
  }
})
export default class EmergencyContacts extends Vue {
  @Prop({ default: false }) addingNewEmergencyContact!: boolean;
  @Prop() fetchingEmergencyContacts!: boolean;
  @Prop() personId!: number;
  @Prop({ default: () => ({ name: null, phoneNumber: null, relationship: null }) }) emergencyContact!: EmergencyContact;
  @Prop() addEmergencyContact!: (args: AddEmergencyContactMutationVariables) => void;
  @Prop() editEmergencyContact!: (args: EditEmergencyContactMutationVariables) => void;
  @Prop() deleteEmergencyContact!: (args: DeleteEmergencyContactMutationVariables) => void;
    emergencyContactCopy: EmergencyContact = this.emergencyContact;
    removingEmergencyContact: boolean = false;
    editing: boolean = !!this.addingNewEmergencyContact;

  async update() {
      if (every(values(this.emergencyContactCopy))) {
          if (this.addingNewEmergencyContact) {

            const { name, phoneNumber, relationship } = this.emergencyContactCopy;
            await this.addEmergencyContact({
                data: {
                    name,
                    phoneNumber,
                    relationship,
                    teenId: this.personId
                }
          });
          this.$emit('update:addingNewEmergencyContact', false);
          }
          else {
            const { name, phoneNumber, relationship, emergencyContactId, personId: teenId } = this.emergencyContactCopy;
            await this.editEmergencyContact({
              data: {
                name,
                phoneNumber,
                relationship,
                teenId
              },
              emergencyContactId
            });
          }
      }
  }

  async deleteEmergencyContactHandler(emergencyContactId: number) {
      await this.deleteEmergencyContact({ emergencyContactId: this.emergencyContact.emergencyContactId });
  }
@Watch('emergencyContact', { immediate: true })
emergencyContactWatcher (newValue: EmergencyContact) {
  if (newValue) {
    this.emergencyContactCopy = newValue;
  }
}

}
