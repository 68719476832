
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import TeenProfile from 'shared/components/TeenProfile';
import { StateChanger } from 'vue-infinite-loading';
import Vue from 'vue';
import { Route, RouterLink, MatchFirst } from 'vue-component-router';
import { hasNumber } from 'shared/util';
import UISearch from 'shared/ui/forms/Search.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import Loading from 'shared/components/Loading.vue';
import FiltersPanel from './components/FiltersPanel.vue';
import Single from './Single';
import AddNewTeen from '../AddNewTeen';
import List from './components/List.vue';
import ActiveFilters from './shared/ActiveFilters';
import FetchMoreOptions from './shared/FetchMoreOptions';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import Modal from 'shared/ui/modal/Modal.vue';
import SelectDuplicatesFields from 'shared/components/SelectDuplicatesFields/fetchFirst';
import { GetFilterOptionsQuery, GetTeenDuplicatesQueryVariables, TeensListDocument, TeensListQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

library.add(faIndent, faOutdent, faFilter, faPlusCircle);

interface Data {
  selectingTeen: boolean;
  filtersOpen: boolean;
  profileClose: boolean;
  showMergeScreen: boolean;
  checkedTeens: number[];
  keyForFiltersPanel: number;
}

interface Props {
  path: string;
  regionId: number;
  country: string;
  router: { location: { pathname: string }; history: { replace: (location: string) => void } };
  initialLoading: boolean;
  loading: boolean;
  teens: ArrayElement<TeensListQuery['teens']['teens']>;
  total: number;
  filterOptions: GetFilterOptionsQuery | undefined;
  fetchingFilterOptions: boolean;
  fetchMore: (args: FetchMoreOptions, stateChanger: StateChanger) => void;
  activeFilters: ActiveFilters;
  setFilters: (args: Partial<ActiveFilters>, cb?: () => void) => void;
  currentTeenId: string | null;
}

interface Computed {
  teenListQuery: any;
  teenListVaribalesQuery: Pick<GetTeenDuplicatesQueryVariables, 'regionId' | 'chapters' | 'limit'>;
  showFilterPanel: boolean;
  singleTeenRoute: boolean;
  displaySpinner: boolean;
  addingNewTeen: boolean;
}

interface Methods {
  hasNumber: (myString: string) => boolean;
  termChange: (term: string) => Promise<void>;
  clearCheckedTeens: () => void;
  closeProfile: () => void;
  clearFilters: () => void;
  onMerged: (targetId: number) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TeenListWrapper',
  components: {
    PanelsGrid,
    PanelsSidebar,
    UISearch,
    Loading,
    Route,
    RouterLink,
    MatchFirst,
    AddNewTeen,
    UIButton,
    List,
    ActionBar,
    Single,
    ScrollablePanel,
    FiltersPanel,
    TeenProfile,
    Modal,
    SelectDuplicatesFields
  },
  directives: {
    fullheight
  },
  data() {
    return {
      selectingTeen: false,
      filtersOpen: false,
      profileClose: false,
      showMergeScreen: false,
      checkedTeens: [],
      keyForFiltersPanel: 0
    };
  },
  props: {
    path: {},
    regionId: {},
    country: {},
    router: {},
    loading: {},
    initialLoading: {},
    teens: {},
    total: {},
    filterOptions: {},
    fetchingFilterOptions: {},
    fetchMore: {},
    activeFilters: {},
    setFilters: {},
    currentTeenId: {}
  },
  computed: {
    teenListQuery() {
      return TeensListDocument;
    },
    teenListVaribalesQuery() {
      return {
        limit: 60,
        filter: {
          regionId: this.regionId,
          isAlumni: this.activeFilters.isAlumni,
          query: this.activeFilters.term,
          sortBy: this.activeFilters.sortBy,
          interests: this.activeFilters.interests,
          filters: this.activeFilters.filters
        },
        regionId: this.regionId
      };
    },
    showFilterPanel() {
      return !this.addingNewTeen && (!this.singleTeenRoute || this.filtersOpen);
    },
    singleTeenRoute() {
      return hasNumber(this.router.location.pathname);
    },
    displaySpinner() {
      return (
        this.loading
      );
    },
    addingNewTeen() {
      return this.router.location.pathname.indexOf('AddNewTeen') > -1;
    }
  },
  methods: {
    hasNumber,
    async termChange(term: string) {
      await this.setFilters({ term });
    },

    clearCheckedTeens() {
      this.checkedTeens.splice(0);
    },
    closeProfile() {
      this.profileClose === true ? (this.profileClose = false) : (this.profileClose = true);
    },
    clearFilters() {
      this.setFilters({
        filters: {},
        interests: [],
        isAlumni: false
      });
      this.keyForFiltersPanel++;
    },
    onMerged(targetId) {
      this.clearCheckedTeens();
      this.router.history.replace(`/teens/${targetId}`);
    }
  }
});
