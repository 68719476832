
import UISelect from 'shared/ui/forms/FancySelect';
import Vue from 'vue';
import { GetZoomUsersQuery } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type ZoomUser = ArrayElement<GetZoomUsersQuery['zoomUsers']>

export default Vue.extend({
  name: 'ZoomUsersLookup',
  props: {
    regionId: {},
    users: {type: Array as () => ZoomUser[]},
    loading: Boolean,
    zoomUsers: {},
    disabled: Boolean,
    value: {
      type: [Object as () => number | ZoomUser | null, Number, Array as () => ZoomUser[]]
    },
    multiple: Boolean
  },
  computed: {
    options(): ZoomUser[] {
      if (!this.users) return [];
      const users  = this.users
        .map(u => {
          if (!(u.first_name.trim() && u.last_name.trim())) {
            return {...u, full_name: u.email};
          }
          return u;
        })
        .sort((a, b) => ((a.last_name + a.first_name).toLowerCase() > (b.last_name + b.first_name).toLowerCase() ? 1 : -1));

      return users;
    },
    valueAsArray(): ZoomUser[] {
      if (!this.value) return [];

      const value: Array<number | ZoomUser> = Array.isArray(this.value) ? this.value : [this.value];

      return value.map(user => typeof user === 'object'
        ? user
        : this.options.find(x => x.id === user.toString())!
      ).filter(v => v);
    }
  },
  components: {
    UISelect
  }
})
