import EditEventView from './EditEvent.vue';
import Vue, { computed, CreateElement } from 'vue';
import tz from 'timezone/loaded';
import { compose, acceptProps, withProps } from 'vue-compose';
import { wrapComponent } from 'shared/apollo-hoc';
import AllowComponent from 'shared/components/AllowComponent';
import NoData from 'shared/components/NoData.vue';
import { PropsEdit, Data, Methods, Computed, Props } from './types';
import getStepData from './getStepData';
import { withRouter } from 'vue-component-router';
import { calculateEventDuration, isFutureEvent } from '../shared/util';
import { getZoomTimeZone } from 'shared/util';
import config from '../../../../../config';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { CreateZoomMeetingDocument, CreateZoomMeetingMutation, CreateZoomMeetingMutationVariables, DeleteZoomMeetingMutationVariables, EditEventDescriptionDocument, EditEventDescriptionMutation, EditEventDescriptionMutationVariables, EditEventDocument, EditEventInput, EditEventMutation, EditEventMutationVariables, GetZoomMeetingDocument, GetZoomMeetingMutation, UpdateZoomMeetingDocument, UpdateZoomMeetingMutation, UpdateZoomMeetingMutationVariables, useConvertEventToSeriesMutation, useDeleteAdditionalEventItemMutation, useDeleteEventMutation, useDeleteEventTicketMutation, useDeleteZoomMeetingMutation, useGetEventForEditQuery, useGetRegionDataQuery } from 'shared/generated/graphql-types';
import { useApolloClient } from '@vue/apollo-composable';

type ZoomMeeting = CreateZoomMeetingMutation['createZoomMeeting']

interface DeleteEventProps {
  deleteEvent: (eventId: number) => void;
  deletingEvent: boolean;
}

interface DeleteMeetingProps {
  deleteZoomMeeting: (zoomMeetingId: string, zoomOccurencyId?: string) => void;
  deletingZoomMeeting: boolean;
}

const store = createStore()
const { getCurrentRegion } = useRegionStore(store)

const deleteEventEnhancer = wrapComponent<PropsEdit, DeleteEventProps>(() => {
  const { loading, mutate } = useDeleteEventMutation()

  return computed(() => ({
    deleteEvent: (eventId) => mutate({ eventId }, {
      optimisticResponse: {
        deleteEvent: eventId
      }
    }),
    deletingEvent: loading.value
  }))
})

const deleteZoomMeetingEnhancer = wrapComponent<PropsEdit, DeleteMeetingProps>((props) => {
  const {loading, mutate} = useDeleteZoomMeetingMutation()

  return computed(() => ({
    deleteZoomMeeting: (zoomMeetingId, zoomOccurencyId) => {
      const { event } = props;
      const variables: DeleteZoomMeetingMutationVariables = {
        id: zoomMeetingId,
        occurrence_id: null
      };
      if (zoomOccurencyId) {
        variables.occurrence_id = new Date(zoomOccurencyId as string).getTime();
      }
      if (event!.seriesId && event!.zoomOccurencyId) {
        variables.occurrence_id = new Date(event!.zoomOccurencyId as string).getTime();
      }
      return mutate(
        {...variables},
        {
        optimisticResponse: {
          deleteZoomMeeting: zoomMeetingId
        }
      }
      );
    },
    deletingZoomMeeting: loading.value
  }))
})

const convertEventToSeries = wrapComponent<{}, Pick<Props, 'convertEventToSeries'>>(() => {
  const {mutate} = useConvertEventToSeriesMutation()

  return computed(() => ({
    convertEventToSeries: (event, series) => mutate({event, series})
  }))
})

export const EditEventContainer = Vue.extend<Data, Methods, Computed, Props>({
  components: {
    EditEvent: EditEventView
  },
  name: 'EditEventContainer',
  props: {
    event: {},
    region: {},
    returnPath: {},
    deletingEvent: {},
    deleteEvent: {},
    deletingZoomMeeting: {},
    deleteZoomMeeting: {},
    convertEventToSeries: {},
    router: {},
    eventLoading: {},
    regionLoading: {},
    deleteEventTicket: {},
    deleteAdditionalEventItem: {},
  },
  data() {
    return {
      state: 'idle',
      stepData: getStepData(this.eventType, this.event),
    };
  },
  created () {
    this.stepData = getStepData(this.eventType, this.event);
  },
  computed: {
    eventType() {
      if (this.eventLoading) {
        return '';
      }
      const { eventSubTypeId, type } = this.event && this.event.EventSubType || { eventSubTypeId: null, type: '' };

      if (eventSubTypeId === 290) {
        return 'JSU';
      }
      if (eventSubTypeId === 296) {
        return 'LNL';
      }
      if (type === 'Shabbaton') {
        return 'Shabbaton';
      }
      if ([
          295, 304, 305, 325,
          326, 328, 329, 323,
          331, 324, 334, 327, 339
        ].includes(eventSubTypeId)
        || type === 'Shabbat'
        || type === 'Holiday') {
        return 'ShabbatHoliday';
      }

      return 'Other';
    }
  },
  watch: {
    eventType(value: string) {
      if (value) {
        this.stepData = getStepData(this.eventType, this.event);
      }
    }
  },
  methods: {
    onStepData (stepData) {
      this.stepData = stepData;
    },
    async handleSubmit(payload) {
      const { client } = useApolloClient();

      this.state = 'submitting';
      const eventId = this.event.eventId;

      await Promise.all((payload.ticketIdsToDelete || []).map(i => this.deleteEventTicket(i, eventId)));
      await Promise.all((payload.additionalEventItemIdsToDelete || []).map(i => this.deleteAdditionalEventItem(i, eventId)));

      let meeting: ZoomMeeting | null = null;
      const eventData: EditEventInput = payload.event;

      if (payload.event.image === `${config.graphql}/eventimage/${eventId}`) {
        delete payload.event.image;
        delete eventData.image;
      }

      if (this.stepData && this.stepData.details.data && !isFutureEvent(this.stepData.details.data.FirstEvent, this.stepData.details.data.StartTime, this.stepData.details.data.TimeZone)) {
        eventData.TeensParticipating = null;
      }

      if (!eventData.isVirtual) {
        eventData.zoomMeetingId = null;
        eventData.zoomUserId = null;
        eventData.zoomPasscode = null;
        eventData.zoomPasscodeHashed = null;
        eventData.streamLink = null;
      }

      if (eventData.zoomUserId && !eventData.zoomMeetingId) {
        let zoomStartDate = eventData.startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(eventData.timeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(eventData.timeZone);
          zoomStartDate = tz(tz(eventData.startDate as string), '%FT%T', zoomTimeZone);
        }
        const meetingInput: CreateZoomMeetingMutationVariables = {
          id: eventData.zoomUserId as string,
          input: {
            start_time: zoomStartDate,
            duration: calculateEventDuration(eventData.startDate as string, eventData.endDate as string),
            password: eventData.zoomPasscode,
            timezone: getZoomTimeZone(eventData.timeZone),
            topic: eventData.eventName
          }
        };
        const result = await client.mutate<CreateZoomMeetingMutation>({
          mutation: CreateZoomMeetingDocument,
          variables: meetingInput
        });
        meeting = result.data && result.data.createZoomMeeting || null;
      }

      if (eventData.zoomMeetingId) {
        let zoomStartDate = eventData.startDate;
        let zoomTimeZone = 'UTC';
        if (getZoomTimeZone(eventData.timeZone) !== 'UTC') {
          zoomTimeZone = getZoomTimeZone(eventData.timeZone);
          zoomStartDate = tz(tz(eventData.startDate as string), '%FT%T', zoomTimeZone);
        }
        const meetingInput: UpdateZoomMeetingMutationVariables = {
          id: eventData.zoomMeetingId,
          input: {
            start_time: zoomStartDate,
            duration: calculateEventDuration(eventData.startDate as string, eventData.endDate as string),
            password: eventData.zoomPasscode,
            timezone: zoomTimeZone,
            topic: eventData.eventName
          }
        };

        if (this.event.seriesId && this.event.zoomOccurencyId) {
          meetingInput.input.occurrence_id = new Date(this.event.zoomOccurencyId as string).getTime();
        }

        await client.mutate<UpdateZoomMeetingMutation>({
          mutation: UpdateZoomMeetingDocument,
          variables: meetingInput
        });

        const result = await client.mutate<GetZoomMeetingMutation>({
          mutation: GetZoomMeetingDocument,
          variables: {
            id: this.event.zoomMeetingId,
          }
        })
  
        meeting = result.data && result.data.getZoomMeeting || null;
      }

      if (this.event.zoomMeetingId && !eventData.zoomMeetingId) {
        await this.deleteZoomMeeting(
          this.event.zoomMeetingId,
          this.event.seriesId ? this.event.zoomOccurencyId : null
        );
      }

      if (meeting && meeting.id) {
        payload.event.zoomMeetingId = meeting.id;
        payload.event.zoomPasscode = meeting.password;
        payload.event.zoomPasscodeHashed = meeting.encrypted_password;
      }

      if (payload.series) {
        await this.convertEventToSeries(
          {
            eventID: eventId,
            ...payload.event
          },
          payload.series
        );
      } else {
        await client.mutate<EditEventMutation>({
          mutation: EditEventDocument,
          variables: {
            eventId,
            event: {
              ...payload.event,
              isActive: true,
              regionID: this.region.regionId,
              seriesID: this.event.seriesId
            }
          } as EditEventMutationVariables
        });
      }

      this.state = 'submitted';
      if (this.event.seriesId) {
        this.router.history.push(`/events/series/${this.event.seriesId}/summary`);
      } else {
        this.router.history.push(`/events/${eventId}/dashboard`);
      }
      setTimeout(() => {
        this.state = 'idle';
      }, 1000);
    },
    async handleSubmitDescription(description) {
      const { client } = useApolloClient();

      this.state = 'submitting';

      const result = await client.mutate<EditEventDescriptionMutation>({
        mutation: EditEventDescriptionDocument,
        variables: {
          eventId: this.event.eventId,
          description
        } as EditEventDescriptionMutationVariables
      });

      if (result.data && result.data.editEventDescription) {
        this.stepData = getStepData(this.eventType, result.data.editEventDescription);
      }

      this.state = 'submitted';
      setTimeout(() => {
        this.state = 'idle';
      }, 1000);
    }
  },
  render(h: CreateElement) {
    return h(AllowComponent, {
      props: {
        isAllowed: (this.event && this.event.regionId) === (this.region && this.region.regionId),
        loading: this.eventLoading || this.regionLoading,
      },
      scopedSlots: {
        default: () => {
          return [h(EditEventView, {
            props: {
              stepData: this.stepData,
              eventType: this.eventType,
              state: this.state,
              region: this.region,
              router: this.router,
              deleteEvent: this.deleteEvent,
              deletingEvent: this.deletingEvent,
              deletingZoomMeeting: this.deletingZoomMeeting,
              deleteZoomMeeting: this.deleteZoomMeeting,
              returnPath: `${this.returnPath}/${(this.event || {}).eventId}`,
              loading: this.eventLoading || this.regionLoading || !this.stepData,
              event: this.event,
              eventId: this.event && this.event.eventId,
              chapterId: this.event && this.event.chapter && this.event.chapter.chapterId
            },
            on: {
              stepData: this.onStepData,
              submit: this.handleSubmit,
              submitDescription: this.handleSubmitDescription
            }
          })];
        },
        restricted: () => {
          return [h(NoData, {
            props: {
              condition: true
            },
            scopedSlots: {
              default: () => {
                return [h('div', this.event ? 'Event is in a different region.' : 'Event not found.')];
              }
            }
          })];
        }
      }
    });
  }
})

export const enhancer = compose(
  withProps<{ regionId: number | null }, {}>((props) => ({
    regionId: props.regionId || getCurrentRegion()
  })),
  wrapComponent<{ regionId: number }, {}>((props) => {
    const {loading, result} = useGetRegionDataQuery(computed(() => ({
      regionId: props.regionId
    })))

    return computed(() => ({
      regionLoading: loading.value,
      region: result.value?.region || null
    }))
  }),
  wrapComponent<{eventId: number}, Pick<PropsEdit, 'eventLoading' | 'event'>>((props) => {
    const {loading, result} = useGetEventForEditQuery(computed(() => ({ eventId: Number(props.eventId)})), {fetchPolicy: 'network-only'})

    return computed(() => ({
      event: result.value?.event || null,
      eventLoading: loading.value
    }))
  }),
  wrapComponent<{}, Pick<PropsEdit, 'deleteEventTicket'>>(() => {
    const {mutate} = useDeleteEventTicketMutation()

    return computed(() => ({
      deleteEventTicket: (eventTicketId, eventId) => mutate({eventId, eventTicketId}),
    }))
  }),
  wrapComponent<{}, Pick<PropsEdit, 'deleteAdditionalEventItem'>>(() => {
    const { mutate } = useDeleteAdditionalEventItemMutation()

    return computed(() => ({
      deleteAdditionalEventItem: (additionalEventItemId, eventId) =>mutate({additionalEventItemId, eventId}),
    }))
  }),
  convertEventToSeries,
  deleteZoomMeetingEnhancer,
  deleteEventEnhancer,
  acceptProps(['regionId', 'eventId']),
  withRouter
);

export default enhancer(EditEventContainer);
