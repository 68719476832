
import UISelect from 'shared/ui/forms/FancySelect';
import Vue from 'vue';
import { Methods, Computed, Props } from './types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange';
import { faLongArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faLongArrowAltRight';
import { faLongArrowAltLeft } from '@fortawesome/pro-solid-svg-icons/faLongArrowAltLeft';
library.add(faExchange, faLongArrowAltRight, faLongArrowAltLeft);

export default Vue.extend<{}, Methods, Computed, Props>({
  name: 'BusLookup',
  components: {
    UISelect
  },
  props: {
    buses: {},
    loading: {},
    value: {}
  },
  computed: {
    resolvedValue() {
      if (!this.value) {
        return null;
      }
      return typeof this.value === 'number' ? (this.buses.find(b => b.busId === this.value) || null) : this.value;
    }
  },
  methods: {
    display(bus) {
      return `${bus.meetingAddress} (${bus.description})`
    }
  }
})
