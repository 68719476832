
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import BorderedList from 'shared/ui/lists/BorderedList.vue';
import Modal from 'shared/ui/modal/Modal.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import FancySelect from 'shared/ui/forms/FancySelect';
import NoteViewModel  from '../../shared/NoteViewModel';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform'
import TeenViewModel from '../../shared/TeenViewModel';
import UpdatePersonNoteArgs from './UpdatePersonNoteArgs';
import TeenLookup from 'shared/components/TeenLookup';
import UITextrea from 'shared/ui/forms/Textarea.vue';
import DatePicker from 'shared/components/DatePicker.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import startCase from 'lodash/startCase';
import DateTimezone from 'date-timezone';
import { NoteCategory } from "shared/generated/graphql-types";

library.add(faPencil, faTrash)

@Component({
  name: "Note",
  components: {
    FancySelect,
    UITextrea,
    BorderedList,
	SimpleForm,
	TeenLookup,
	UIButton,
    UIInput,
    Modal,
    Field,
    DatePicker,
	Error,
  }
})
export default class extends Vue {
    @Prop() note!: NoteViewModel;

    @Prop() staffId!: number;

    @Prop() deletePersonNote!: (noteId: number, regionId: number) => void;

    @Prop() updatePersonNote!: (updateNoteArgs: UpdatePersonNoteArgs) => void;

    @Prop() dateFormat!: (date: Date) => Date;

	@Prop() editingNote!: boolean;

    @Prop() regionId!: number;

    created () {
    this.noteCategories = Object.keys(NoteCategory).map(x => ({
        name: startCase(x),
        value: x
    }))
	}
    getDateObject(date: string){
        return {
            year: +date.substring(0,4),
            month: +date.substring(5,7),
            day: +date.substring(8,10)-1
        }
    }
    get modifiedNote(){
      return {
        ...this.note,
        date: this.getDateObject(this.note.date),
        category: {
          name: startCase(this.note.category),
          value: this.note.category
        }
      }
    }
	deleting: boolean = false;

	isActive = false;

	validate (values: NoteViewModel) {
		let errors: { [key: string]: string} = {};

		if (!values.teen) {
			errors['teen'] = "A teen must be selected";
		}
		if (!values.category) {
			errors['category'] = "A category must be selected";
		}
		if (!values.date) {
			errors['date'] = "A date must be selected";
		}
		if (!values.note) {
			errors['note'] = "A note must be provided";
		}

		return errors;
	}

    editingNoteHandler (callbackArgs: SubmitCallbackArgs<any>) {
		if ('errors' in callbackArgs) return;
        const { teen: { Person: { personID, firstName, lastName } }, note, date, category: { value: category }, chapterId } = callbackArgs.values;
        const contactDate = new DateTimezone.DateTimezone(date.year, date.month-1, date.day+1,12,0).toISOString()

        this.updatePersonNote({
            staffId: this.staffId,
            teenId: personID,
            note: note || '',
            firstName,
            lastName,
            contactDate,
            category,
            chapterId,
			noteId: this.note.noteId,
			regionId: this.regionId
        });
        this.$emit('editingNote', false)
	}

	teenLabel(arg: TeenViewModel | null) {
    if(!arg) {
      return '';
    }
    const { Person: { firstName, lastName } } = arg;
    return `${firstName} ${lastName}`;
  }
}

