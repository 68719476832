
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import { Props } from './types';
import StateLookup from 'shared/components/StateLookup.vue';
import ZoomUsersLookup from 'shared/components/ZoomUsersLookup';
import TimezoneDropdown from 'shared/components/TimezoneDropdown.vue';
import Datepicker from 'shared/components/DatePicker.vue';
import Timepicker from 'shared/components/Timepicker.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import UISwitch from 'shared/ui/forms/Switch.vue';
import UICheckbox from 'shared/ui/forms/Checkbox.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import { generateZoomPasscode, diffDays } from '../../../shared/util';
import { SimpleDate } from 'shared/util/types';

type ExtendedProps = Props & { hideDate: boolean; seriesId: number | null; };

interface Methods {
  diffDays: (firstEvent?: SimpleDate, lastEvent?: SimpleDate) => number | null;
  scheduleZoomHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  isVirtualHandler: (checked: boolean, setData: (name: string, value: any) => void) => Promise<void>;
  isHasErrors: (key: string) => boolean;
}

export default Vue.extend<{}, Methods, {}, ExtendedProps>({
  name: 'Details',
  components: {
    RouterLink,
    Timepicker,
    Datepicker,
    UIInput,
    UISwitch,
    UICheckbox,
    Divider,
    StateLookup,
    TimezoneDropdown,
    ZoomUsersLookup
  },
  props: {
    getData: {},
    displayErrors: {},
    setData: {},
    step: {},
    steps: {},
    seriesId: {},
    hideDate: {}
  },
  methods: {
    diffDays,
    async scheduleZoomHandler(checked, setData) {
      setData('streamSchedule', checked);
      await Vue.nextTick();
      if (checked) {
        setData('zoomPasscode', generateZoomPasscode());
      } else {
        setData('zoomPasscode', null);
        await Vue.nextTick();
        setData('zoomUser', null);
      }
    },
    async isVirtualHandler(checked, setData) {
      setData('isVirtual', checked);
      await Vue.nextTick();
      if (checked) {
        if (this.getData('streamSchedule')) {
          setData('zoomPasscode', generateZoomPasscode());
        }
      } else {
        setData('zoomPasscode', null);
        await Vue.nextTick();
        setData('zoomUser', null);
      }
    },
    isHasErrors(key) {
      return this.step.validations && this.step.validations[key];
    }
  }
})
