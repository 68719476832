
import Vue from 'vue';
import { DuplicatePhoneHolderFamilyFragment } from 'shared/generated/graphql-types';
import { RouterLink } from 'vue-component-router';

interface Props {
  family: DuplicatePhoneHolderFamilyFragment;
}

export default Vue.extend<{}, {}, {}, Props>({
  name: 'FamilyDisplay',
  components: {
    RouterLink
  },
  props: {
    family: {}
  }
});
