
import Vue from 'vue';
import Summary from "./pages/Summary";
import EmailHistory from "./pages/EmailHistory";
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import { Data, Props } from './types';

export default Vue.extend<Data, {}, {}, Props>({
  name: 'SingleParent',
  components: {
    Summary,
    ScrollablePanel,
    EmailHistory,
  },
  props: {
    parent: {},
    family: {},
    regionId: {},
    familyId: {},
    setParentId: {},
    router: {},
    associateHandler: {}
  },
  data () {
    return {
      selectedComponent: 'Summary'
    };
  }
});
