
import { Vue, Component, Prop } from "vue-property-decorator";
import FileSelector from "./FileSelector";

@Component({
  name: "ImageSelector",
  components: {
    FileSelector,
  },
})
export default class extends Vue {
  @Prop() value!: string;
  uploading: Boolean = false;
  convertedFiles: { file: File; base64: string; base64src: string }[] = [];

  upload(files: File | File[]) {
    this.uploading = true;
    this.convertedFiles = [];
    if (!files) {
      this.uploading = false;
      return;
    }
    if ("length" in files) {
      // XTODO add a test for this behavior (no idea if possible)
      Object.values(files).map(this.getBase64);
    } else {
      this.getBase64(files);
    }
    this.$emit("input", this.convertedFiles);
    this.$emit("blur", this.convertedFiles);
    this.uploading = false;
  }

  getBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.convertedFiles.push({
        file,
        base64src: reader.result as string,
        base64: (reader.result as string).replace(
          /data:image\/.*?;base64,/,
          ""
        ),
      });
      this.$emit("fileRead", reader.result);
    };
    reader.onerror = (error) => {
      console.error("Error: ", error);
    };
  }
}
