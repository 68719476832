
import UISelect from 'shared/ui/forms/FancySelect';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventType } from './'

@Component({
	components: { UISelect }
})
export default class EventTypeLookup extends Vue {
		@Prop() eventTypes!:  EventType[];
		@Prop() loading!:  boolean;
		@Prop({type: [Number, Object, Array]}) value!: number | EventType | EventType[];
		@Prop() multiple!: boolean;
		@Prop({default: true}) clearable!: boolean;

    get options() {
      return this.eventTypes
    }

    get valueAsArray (): EventType[] {
          if (!this.value) return []

          const value: Array<number | EventType> = Array.isArray(this.value) ? this.value : [this.value]

          return value.map(eventType => typeof eventType === 'object'
              ? eventType
              : this.options.find((x: EventType) => x.eventTypeId === eventType)!
          ).filter(v => v)
      }
};
