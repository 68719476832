
import ActionBar from 'shared/ui/tooltip/ListActions.vue';
import { Route, RouterLink, MatchFirst } from 'vue-component-router';
import List from './components/List.vue';
import Single from './Single';
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import UISearch from 'shared/ui/forms/Search.vue';
import UIButton from "shared/ui/buttons/Button.vue";
import StaffProfile from 'shared/components/StaffProfile';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import { CompactAdvisorRegion } from './shared/CompactAdvisorRegion';
import Loading from 'shared/components/Loading.vue';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import fullheight from 'shared/directives/fullheight';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faOutdent } from '@fortawesome/pro-regular-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import Modal from 'shared/ui/modal/Modal.vue';
import RequiresPermission from 'shared/components/RequiresPermission';
import FiltersPanel from './components/FiltersPanel.vue';
import ActiveFilters from './shared/ActiveFilters';
import { StateChanger } from 'vue-infinite-loading';
import FetchMoreOptions from './shared/FetchMoreOptions';
import { hasNumber } from 'shared/util';
import { CurrentUserQuery, GetFilterOptionsQuery, GetPaginatedGlaubachFellowsQuery, MergeStaffMutationVariables, PermissionEnum, SortType } from 'shared/generated/graphql-types';
import { ArrayElement } from 'shared/util/types';

type AdvisorRegion = ArrayElement<CurrentUserQuery['me']['AdvisorRegions']>
type Staff = ArrayElement<GetPaginatedGlaubachFellowsQuery['getPaginatedGlaubachFellows']['glaubachFellows']>

library.add(faIndent, faOutdent, faSlidersH, faPlusCircle, faFilter)

@Component({
  name: "Layout",
  components: {
    Route,
		List,
		ActionBar,
		FiltersPanel,
		Single,
		UISearch,
		UIButton,
		RouterLink,
		MatchFirst,
		StaffProfile,
		PanelsGrid,
		PanelsSidebar,
		Loading,
		ScrollablePanel,
    Modal,
    RequiresPermission
	},
	directives: {
		fullheight
	}
  })

export default class extends Vue {
		@Prop() path!: string;
    @Prop() regionId!: number;
    @Prop({ type: Boolean }) initialLoading!: boolean;
    @Prop({ type: Boolean }) loading!: boolean;
		@Prop() staff!: Staff[];
		@Prop() router!: { history: { push: (location: string) => void }, location: { pathname: string } };
		@Prop({type: Object}) currentStaff!: CompactAdvisorRegion | null;
    @Prop() loadingSingle!: boolean;
    @Prop() activeFilters!: ActiveFilters;
    @Prop() currentStaffId!: string;
    @Prop() mergeStaff!: (vars: MergeStaffMutationVariables) => void;
    @Prop() currentUser!: AdvisorRegion;
    @Prop() filterOptions!: GetFilterOptionsQuery;
    @Prop() total!: number;
    @Prop() fetchMore!: (args: FetchMoreOptions, stateChanger: StateChanger) => void;
    @Prop() setFilters!: (args: Partial<ActiveFilters>, cb?: () => void) => void;

    selectingStaff: boolean = false;
		filtersOpen: boolean = false;
    profileClose: boolean = false;
    checkedStaff: Staff[] = [];
    confirmMerge: boolean = false;
    keyForFiltersPanel: number = 0;
    permissionEnum = PermissionEnum;

    get showFilterPanel () {
      return !this.addingNewStaff && (!this.singleStaffRoute || this.filtersOpen)
    }
    get addingNewStaff() {
        if (!this.router) return false;
        return this.router && this.router.location.pathname.indexOf('AddNewStaff') > -1;
    }
    get singleStaffRoute () {
      if (!this.router) return false;
      return hasNumber(this.router.location.pathname)
    }
    get displaySpinner () {
      return this.loading
    }
		closeProfile(){
			this.profileClose === true ? this.profileClose = false : this.profileClose = true;
    }
	   termChange (term: string) {
	   	this.setFilters({ term });
     }
		clearFilters () {
			this.setFilters({
				term: '',
        filters: {},
				sortBy: { name: SortType.Name, ascending: true }
			});
			this.keyForFiltersPanel++
		}

    async mergeStaffHandler(){
      const ids = this.checkedStaff.map(s => s.staffID);
      const [remainingID, ...deleteIDs] = ids
      await this.mergeStaff({
        remainingID,
        deleteIDs,
        regionId: this.regionId
      });
      this.checkedStaff = [];
    }

    clearCheckedStaff(){
      this.checkedStaff.splice(0);
    }
}

