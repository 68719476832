
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import UISearch from 'shared/ui/forms/Search.vue';
import UIInput from 'shared/ui/forms/Input.vue';
import FilterGroup from 'shared/ui/forms/FilterGroup.vue';
import UIButton from 'shared/ui/buttons/Button.vue';
import UIRadio from 'shared/ui/forms/Radio.vue';
import ChapterLookup from 'shared/components/ChapterLookup';
import * as RadioGroup from 'shared/radio-group';
import ScrollablePanel from 'shared/components/scrollable-panel.vue';
import Divider from 'shared/layout/divider/layout-divider.vue';
import fullheight from 'shared/directives/fullheight';
import PanelsGrid from 'shared/components/structure/panelsGrid.vue';
import PanelsSidebar from 'shared/components/structure/panelsSidebar.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import UIToggle from 'shared/ui/forms/Toggle.vue';
import UISelect from 'shared/ui/forms/FancySelect';
import { SeriesEventsFilterInput } from "shared/generated/graphql-types";

library.add(faPlusCircle)
@Component({
  components: {
    UISearch,
    FilterGroup,
    PanelsGrid,
    UIButton,
    UIToggle,
    Divider,
    UISelect,
    UIRadio,
    ChapterLookup,
    UIInput,
    PanelsSidebar,
    ...RadioGroup,
    ScrollablePanel
	},
	directives: {
		fullheight
	},
})
export default class SideBar extends Vue {
    @Prop() setFilterHandler!: (value: any, filter: string) => void;
    @Prop() filters!: SeriesEventsFilterInput;
    @Prop() seasons!: string[];
    @Prop() months!: string[];
    @Prop() clearFilters!: () => void;
    upcoming: boolean = false;
}


