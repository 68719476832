
import UISelect from 'shared/ui/forms/FancySelect';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SynagoguesCompactQuery } from 'shared/generated/graphql-types';

@Component({
	components: { UISelect }
})
export default class SynagogueLookup extends Vue {
		@Prop() synagogues!: SynagoguesCompactQuery['synagogues']
		@Prop() loading!: boolean
		@Prop() value!: Object
		@Prop() customLabel!: Object
		@Prop() states!: string
		@Prop() country!: string 

	get Synagogues(): SynagoguesCompactQuery['synagogues'] {
		if (this.states && this.country == 'US' && this.synagogues){
			return this.synagogues.filter(s => s.state?this.states.includes(s.state):'')
		}
		if (this.country == 'CA' && this.data && this.data.synagogues) {
			return this.synagogues.filter(s => s.country ? this.country == s.country:'')
		}	 
		return []
	}
};
