
import Vue from 'vue'
import isEqual from 'lodash/isEqual';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { library } from '@fortawesome/fontawesome-svg-core';
import { asUTCWithZerotime } from 'shared/util';
import { SimpleDate } from 'shared/util/types';

interface Data {
  innerValue: Date | Date[] | null;
}

interface Computed {
  maxDate: Date | null;
  minDate: Date | null;
}

interface Methods {
  parseDate: (value: string | SimpleDate | null) => SimpleDate | SimpleDate[] | null;
  emit: (date: Date | Date[]) => void;
  toDate: (value: SimpleDate | SimpleDate[] | null) => Date | Date[] | null;
  pad: (n: number) => string;
  date: () => Date | Date[] | null;
}

interface Props {
  value: string | SimpleDate | null;
  placeholder: string;
  allowedDates: { maxDate?: SimpleDate, minDate?: SimpleDate };
  disableDates: Date[];
  mode: DatePickerMode;
}

type DatePickerMode = 'single' | 'multiple';

library.add(faCalendar);

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'DatePickerWrapper',
  inheritAttrs: false,
  props: {
    value: { type: [Object, String] },
    placeholder: {},
    allowedDates: {},
    disableDates: {},
    mode: { default: 'single' }
  },
  data() {
    return {
      innerValue: this.date() || null
    }
  },
  computed: {
    maxDate() {
      return this.toDate(this.allowedDates && this.allowedDates.maxDate!) as Date;
    },
    minDate() {
      return this.toDate(this.allowedDates && this.allowedDates.minDate!) as Date;
    },
  },
  watch: {
    value(value) {
      this.innerValue = this.date();
    }
  },
  methods: {
    parseDate(value) {
      if (typeof value === 'string') {
        return JSON.parse(value);
      }
      if (!value) {
        return null;
      }
      return value;
    },
    date() {
      return this.toDate(this.parseDate(this.value));
    },
    emit(date) {
      if (!date) {
        this.$emit('blur', null);
        this.$emit('input', null);
        return;
      }
      let output;
      if (Array.isArray(date)) {
        output = date.map((d) => {
          const utc = asUTCWithZerotime(d, false) as Date;
          return {
            year: utc.getFullYear(),
            month: utc.getMonth() + 1,
            day: utc.getDate(),
          }
        });
        output = JSON.stringify(output);
      } else {
        const d = asUTCWithZerotime(date, false) as Date;
        output = {
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
        }
      }
      if (!isEqual(this.innerValue, date)) {
        this.$emit('input', output);
        this.$emit('blur', output);
        this.innerValue = date;
      }
    },
    toDate(value) {
      if (Array.isArray(value)) {
        return value.map(d => new Date(d.year, d.month - 1, d.day));
      } else if (value) {
        return new Date(value.year, value.month - 1, value.day);
      } else {
        return null;
      }
    },
    pad(n) {
      return String(n).padStart(2, '0');
    }
  }
})
