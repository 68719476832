import { compose, withProps } from 'vue-compose';
import autocomplete from 'shared/components/autocomplete';
import { getCurrentSeason } from 'shared/util';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import GlobalSearch from './GlobalSearch.vue';
import { wrapComponent } from 'shared/apollo-hoc';
import { computed } from 'vue';
import {
  GetGlobalSearchResultQuery,
  useGetGlobalSearchResultQuery
} from 'shared/generated/graphql-types';

interface StateProps {
  regionId: number | null | undefined;
  term: string | null | undefined;
}

interface Results {
  loading: boolean;
  results: GetGlobalSearchResultQuery['globalSearch'];
}

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

const GlobalSearchAutoComplete = autocomplete(
  wrapComponent<StateProps, Results>((props) => {
    const variables = computed(() => ({
      regionId: props.regionId,
      term: props.term,
      season: getCurrentSeason()
    }));
    const { result, loading } = useGetGlobalSearchResultQuery(variables, {
      fetchPolicy: 'network-only',
      enabled: computed(() => !!props.term)
    });

    return computed(() => ({
      loading: loading.value,
      results: result.value?.globalSearch || []
    }));
  })
)(GlobalSearch);

export default compose(
  withProps(() => ({
    regionId: getCurrentRegion()
  }))
)(GlobalSearchAutoComplete);
