
export default {
	name: 'Loading',
  props: {
    loader: {
      type: String,
      default: 'bounce'
    }
  }
}
